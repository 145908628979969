import React from "react";
// import constants from '../../config/constants';
// import QbHelpers from '../../helpers/QbHelpers';
import LS_SERVICE from '../../utils/localStorage';
import PropTypes from 'prop-types';
import axios from "axios";
import {
    CALL_RATING,
    HOMECARE_CALL_RECORD_RATING,
    VONAGE_CALL_API,
    VONAGE_CALL_NOTIFY,
    USERTYPES
} from "../../utils/constant";
import * as qs from 'query-string';
import { withRouter } from "react-router-dom";
import { callService, callDriverService, vonageExpandedScreenService } from '../../utils/rxjs-sharing';


var _ = require('lodash');

class VideoConferencing extends React.Component {

    state = {
        users: {
            current_page: 0,
            per_page: 100,
            total_entries: 0,
            items: []
        },
        audioMute: false,
        callPatientBtn: true,
        callNotificationMsg: '',
        callModalOpen: false,
        isListenersBinded: false,
        canEndCall: false,
        vcSetupDone: false,
        booking_id: null,
        incoming_call: {
            session_id: null,
            token: null
        },
        isCallUserCalled: false,
        showVideoFrame: false,
    };



    static propTypes = {
        onChange: PropTypes.func
    };

    constructor(props) {
        super(props);
        // This should be in constructor only
        callDriverService.init({ driver: 'vonage' });
        this.callUser = this.callUser.bind(this);
        this.endCall = this.endCall.bind(this);
        this.resetCallAudio = this.resetCallAudio.bind(this);
    }

    receiveInParent(data) {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange({
                callPatientBtn: data.callPatientBtn,
                callNotificationMsg: data.callNotificationMsg,
                canEndCall: data.canEndCall,
                hungupExistingCall: data.hungupExistingCall
            });
        }
    }

    resetCallAudio() {
        // this.setState({
        //     audioMute: false
        // });
        // setTimeout(() => {
        //     if (!this.state.vcSetupDone) {
        //         this.makeDoctorLogin();
        //     }
        // }, 1000);
    }

    componentDidMount() {
        window.InncVonage.setVCState(this, this.props.ishomecare ? HOMECARE_CALL_RECORD_RATING : CALL_RATING, axios);
        this.setState({
            booking_id: this.props.booking_id
        });
        this.checkFloatingScreen();
        this.makeDoctorLogin();


        const incoming_call = qs.parse(this.props.location.search);
        if (typeof incoming_call.session_id != 'undefined') {
            this.props.history.push({
                pathname: "/doctor-consult",
                state: {
                    booking_id: this.props.booking_id
                }
            });
        }
        this.setState({
            incoming_call: {
                session_id: incoming_call.session_id || null,
                token: incoming_call.token || null
            }
        }, () => {
            if (this.state.incoming_call.session_id != null) {
                setTimeout(() => {
                    this.callUser();
                }, 2000);
            }
        });

        this.subscription = callService.status().subscribe(result => {
            if (result && result.start && result.video_call_driver != 3) {
                LS_SERVICE.set('is_homecarecall', result.is_homecarecall);
                if (result.is_homecarecall)
                    LS_SERVICE.set('homecare_booking_id', result.booking_id);

                // window.InncVonage.setCallRatingApi(CALL_RATING);
                window.InncVonage.setCallRatingApi(result.is_homecarecall ? HOMECARE_CALL_RECORD_RATING : CALL_RATING);
                this.callUser();
            }
            if (result && result.end && result.video_call_driver != 3) {
                this.endCall({
                    notify: (typeof result.notify != 'undefined') ? result.notify : true
                });
            }
            if (result && result.message && result.video_call_driver != 3) {
                this.updateVideoCallMsg({
                    message: result.message
                });
            }
        });

        this.vonageSubscription = vonageExpandedScreenService.status().subscribe(result => {
            if (result.type === 'endCall') {
                this.endCall({ notify: true });
            }
            if (result.type === 'mute_unmute') {
                this.muteOrUnmute();
            }
        });
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
        this.vonageSubscription.unsubscribe();
    }

    checkFloatingScreen() {
        const patient = document.getElementById('patient_call_widget');
        if (patient != null) {
            const pid = document.getElementById('patient_call_widget').getAttribute('data-pid');
            const cpid = document.getElementById('floating_vc_dialog').getAttribute('data-pid');
            if (pid != null && cpid != null) {
                if (pid !== cpid) {
                    document.getElementById('floating_vc_dialog').classList.remove("hidden");
                    return;
                } else {
                    document.getElementById('floating_vc_dialog').classList.add("hidden");
                    return;
                }
            }
        }
    }

    updateVideoCallMsg(data) {
        let hungupExistingCall = false;
        if (data.tag && data.tag === 'already_in_call_hungup') {
            hungupExistingCall = true;
        }
        this.setState({
            callPatientBtn: data.btnStatus,
            callNotificationMsg: data.message,
            canEndCall: data.canEndCall,
        });
        this.receiveInParent({
            callPatientBtn: data.btnStatus,
            callNotificationMsg: data.message,
            canEndCall: data.canEndCall,
            hungupExistingCall: hungupExistingCall
        });
    }

    getOtSessionToken() {
        var $this = this;
        const is_homecarecall = LS_SERVICE.get('is_homecarecall');
        const is_doctor = LS_SERVICE.get('user_type') == USERTYPES.doctor ? true : false;
        // const doctor = LS_SERVICE.get('doctor');

        return new Promise((resolve, reject) => {

            const PARAMS = {
                source: 'web',
                booking_id: +this.state.booking_id,
                is_homecare: is_homecarecall,
                caller_id: LS_SERVICE.get('staff_id'),
                caller_type: is_doctor ? 2 : 1 // (1 - Nurse and 2 - Doctor)
            }

            axios.post(VONAGE_CALL_API, PARAMS)
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    $this.updateVideoCallMsg({
                        btnStatus: true,
                        message: 'Session cannot be started, please refresh page to start video call'
                    });
                    resolve({});
                });
        });
    }

    /**
     * Make doctor login
     */
    async makeDoctorLogin() {
        // && !_.isEmpty(QbHelpers.patientInfo)
        // && QbHelpers.patientInfo.number === this.props.patient.number

        const vonagePatientInfo = window.InncVonage.data.patientInfo;
        if (window.InncVonage.data.call_started
            && !_.isEmpty(vonagePatientInfo)
            && vonagePatientInfo.number === this.props.patient.number) {

            this.setState({
                audioMute: window.InncVonage.data.mute,
                showVideoFrame: true
                // canEndCall: true
            });
            this.updateVideoCallMsg({
                canEndCall: true
            });

            document.getElementById('js-call-controls').classList.remove("hidden");
            window.InncVonage.rebindUiComponents();
            return;
        }

        /**
         * Operations to perform if ongoing call patient doesn't match current patient
         */
        if (window.InncVonage.data.call_started
            && !_.isEmpty(vonagePatientInfo)
            && vonagePatientInfo.number !== this.props.patient.number) {
            document.getElementById('completed_consult_btn').setAttribute("disabled", "disabled");
        }

        window.InncVonage.data.patientInfo = this.props.patient;
        this.updateVideoCallMsg({
            btnStatus: false
        });
        return false;
    }

    /**
     * Start Vonage Video Call
     * @param {*} item
     */
    async callUser() {
        var tokdata;
        var callType = 'outgoing';

        if (this.state.incoming_call.session_id != null) {
            tokdata = {
                booking_id: this.state.booking_id,
                patient_id: this.props.patient.id,
                session_id: this.state.incoming_call.session_id,
                token: this.state.incoming_call.token
            };
            this.setState({
                incoming_call: {
                    session_id: null,
                    token: null
                }
            });
            callType = 'incoming';
        } else {

            if (this.state.isCallUserCalled) {
                return;
            }

            this.setState({
                isCallUserCalled: true
            });
            const opentok = await this.getOtSessionToken();
            if (typeof opentok.data == 'undefined') {
                return;
            }
            if (typeof opentok.data.message != 'undefined') {
                this.updateVideoCallMsg({
                    btnStatus: false,
                    message: opentok.data.message,
                    canEndCall: false
                });
                return;
            }
            tokdata = {
                booking_id: this.state.booking_id,
                patient_id: this.props.patient.id,
                ...opentok.data
            };
        }

        // console.log('>> tokdata', tokdata);

        // if (this.props.patient.number === null) {
        //     return;
        // }

        this.setState({ showVideoFrame: true });

        if (!window.navigator.onLine) {
            this.updateVideoCallMsg({
                btnStatus: false,
                message: "Internet connection not available to connect the video call",
            });
            return;
        }
        if (window.InncVonage.data.call_started) {
            this.updateVideoCallMsg({
                btnStatus: false,
                message: "You're already in call with another patient. Please disconnect to start a new call",
                canEndCall: false,
                tag: 'already_in_call_hungup'
            });
            return;
        }
        this.updateVideoCallMsg({
            btnStatus: true,
            canEndCall: true
        });
        document.getElementById('js-call-controls').classList.remove("hidden");
        window.InncVonage.start(tokdata);
        if (callType == 'outgoing') {
            this.ring();
        }
        this.notifyForCall(2);
        setTimeout(() => {
            this.setState({
                isCallUserCalled: false
            });
        }, 2000);
    }

    ring() {
        document.getElementById("callingSignal").play();
    }

    stopring() {
        document.getElementById("callingSignal").pause();
        document.getElementById("endCallSignal").play();
    }

    /**
     * End call with patient
     */
    endCall(inputs) {
        if (inputs.notify) {
            this.notifyForCall(4);
        }
        this.setState({ showVideoFrame: false });
        window.InncVonage.disconnect();
        this.updateVideoCallMsg({
            btnStatus: false,
            audioMute: false,
            canEndCall: false
        });
        this.setState({
            audioMute: false
        });
        this.stopring();
        // console.log(this.props)
        // this.props.location.state.incoming_call = {}
        // console.log(this.props);
        // this.props.history.push({
        //     pathname: "/doctor-consult",
        //     state: {
        //         booking_id: this.state.booking_id
        //     },
        // });
        // delete this.props.incoming_call;

        LS_SERVICE.delete('is_homecarecall');
        LS_SERVICE.delete('homecare_booking_id');
    }

    notifyForCall(type) {
        // let url = VONAGE_CALL_NOTIFY.replace("{bookingId}", this.state.booking_id).replace("{type}", type);

        const is_homecarecall = LS_SERVICE.get('is_homecarecall');
        // let url = is_homecarecall ? VONAGE_CALL_NOTIFY({ bookingId: this.state.booking_id, type, is_homecare: true }) : VONAGE_CALL_NOTIFY({ bookingId: this.state.booking_id, type });
        // axios.get(url)
        //     .then(function (response) {
        //     })
        //     .catch(function (error) {
        //     });

        // let url = VONAGE_CALL_NOTIFY({ bookingId: this.state.booking_id, type, is_homecare: is_homecarecall })
        // axios.get(url)

        // let url = VONAGE_CALL_NOTIFY({ bookingId: this.state.booking_id, type: type });
        // console.log('>>>> VONAGE_CALL_NOTIFY', VONAGE_CALL_NOTIFY);
        axios.post(VONAGE_CALL_NOTIFY, { source: 'web', booking_id: this.state.booking_id, is_homecare: is_homecarecall, type: type })
        // .then(function (response) {
        // })
        // .catch(function (error) {
        // });
    }

    /**
     * Mute or unmute audio during call
     */
    muteOrUnmute() {
        const newMuteState = window.InncVonage.muteOrUnmute();
        this.setState({
            audioMute: newMuteState
        });
    }

    expandVideoScreen() {
        window.InncVonage.expandScreen({ audioMute: this.state.audioMute });
    }

    hideModalCall() {
        this.setState({
            callModalOpen: false
        });
    }

    createCallLog(data) {
        const is_homecarecall = LS_SERVICE.get('is_homecarecall');
        // const is_doctor = LS_SERVICE.get('is_doctor');
        // const is_doctor = LS_SERVICE.get('user_type') == USERTYPES.doctor ? true : false;

        // axios.post(CALL_RATING, data)
        //     .then(function (response) {
        //     })
        //     .catch(function (error) {
        //     });
        axios.post(is_homecarecall ? HOMECARE_CALL_RECORD_RATING : CALL_RATING, data)
        // .then(function (response) {
        // })
        // .catch(function (error) {
        // });
    }

    /**
     * Component response view 
     */
    render() {
        const audioState = this.state.audioMute ? 'Unmute' : 'Mute';
        const audioClass = this.state.audioMute ? 'active' : '';
        const muteImg = this.state.audioMute ? 'mic-muted.png' : 'mic.png';
        const callModalClass = this.state.callModalOpen ? '' : 'hidden';
        return (
            <div className={"col-12 doctor-video " + (this.state.showVideoFrame ? '' : 'hidden')}>

                <div className="video-call-widget">

                    {/* Video Interface for puclisher and subscribers */}
                    <div className='position-relative full-wh-elem'>

                        {/* Recording icon */}
                        {/* <span id="recording-icon-1" className="video-recording hidden" title="Chat video is being recorded">
                            <i className="red-icon"></i>
                            REC
                        </span> */}

                        {/* Video Screen(Subscriber/Patient) will be displayed here */}
                        <div id="consult_subscriber_container" title="Patient video screen">
                            <div id="consult_subscriber"></div>
                        </div>

                        {/* Video Screen(Publisher/Doctor) will be displayed here */}
                        <div className="publisher-elem position-absolute" title="Doctor video screen" id="consult_publisher_container">
                            <div id="consult_publisher"></div>
                        </div>

                        {/* Call Controls */}
                        <div className="call-controls hidden" id="js-call-controls">

                            {/* For Mute / Unmute */}
                            <button onClick={() => this.muteOrUnmute()} type="button" className={audioClass + " control-btn"} title={audioState + " Microphone"}>
                                <img src={"/assets/images/" + muteImg} alt="mic-icon" />
                            </button>

                            {/* For Ending call */}
                            <button onClick={() => this.endCall({ notify: true })} type="button" className="control-btn" title="End Call">
                                <img src="/assets/images/endcall.png" alt="mic-icon" />
                            </button>

                            {/* For Exapanding video screen */}
                            <button onClick={() => this.expandVideoScreen()} type="button" className="control-btn float-right" title="Expand screen">
                                <img src="/assets/images/expand.png" alt="mic-icon" />
                            </button>

                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

export default withRouter(VideoConferencing);