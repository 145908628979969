import React, { Component } from 'react';
import Axios from 'axios';
import qs from 'qs';
// import { ReactSketchCanvas } from "react-sketch-canvas";
import Sketchpad from '../../utils/sketchpad';
import LS_SERVICE from '../../utils/localStorage';
import SoapFlow from "../../utils/soap";
import {
    ASSESSMENT_API,
    ASSESSMENT_DELETE,
    ASSESSMENT_LIST,
    ASSESSMENT_STATUS_UPDATE,
    ASSESSMENT_STORE,
    FAVOURITE_DIAGNOSIS,
    HANDLEVALIDANTMESSAGES,
    IMAGE_EXT,
    IMAGE_LIBRARY,
    IS_URL,
    ISALLOWEDITSOAP,
    LOADER,
    LOADER_BLACK,
    PATIENT_DATA,
    SEARCH_DIAGNOSIS,
    SOAP_CONFIGURATION_LIST,
    TOGGLE_PRACTICE
} from '../../utils/constant';
import { jQueryLabels, AntSelectLabels, FixedCta } from '../../utils/jQueryLabels';
import { Modal, Form, notification, Select, Switch, Tabs, Button } from 'antd';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import icImagereference from '../../assets/images/ic-imagereference.png';
import icDrawing from '../../assets/images/ic-drawing.png';
import icEraser from '../../assets/images/ic-eraser.png';
import icundo from '../../assets/images/ic-undo.png';
import icredo from '../../assets/images/ic-redo.png';
import icbrush from '../../assets/images/ic-brush.png';
import AsideLeft from '../../components/aside/asideleft';
import SubHeader from '../../components/subheader/subheader';
import API_SERVICE from '../../utils/apiService';
import { Link } from 'react-router-dom';
import AssessmentForm from "../../components/assessments/assessment-form";
import AssessmentTable from "../../components/assessments/assessment-table";
import MedicineTable from "../../components/drugs/medicine-table";
import MedicineForm from "../../components/drugs/medicine-form";
import { headerService$, practiceService } from '../../utils/rxjs-sharing';

const { Option } = Select;
const { TabPane } = Tabs;
const TYPE = ['Primary', 'Secondary'];
const STAGE = ['Provisional', 'Final'];
const STATUS = ['Active', 'Cured'];

class AssessmentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            patient: null,
            lineWidth: 1,
            lineColor: 'black',
            formModeEdit: false,
            undo: 15,
            previousDiagnosis: [],
            assessmentData: [],
            addedAssessmentList: [],
            constants: [],
            assessDataLoaded: false,
            formIsFetching: false,
            diagnosis_name: '',
            stage: 0,
            type: 0,
            status: 0,
            image_uploaded: '',
            image_comment: '',
            image_comment_temp: '',
            imageReferenceModal: false,
            diagnosisList: [],
            diagnosis_selected: {},
            selectedImg: '',
            assess_template_list: [],
            assessTemplateListModal: false,
            sketch2background: '',
            delete_text: '',
            delete_data: null,
            deleteConfirmModal: false,
            spin_loading: false,
            diagnosis_notes: '',
            assessmentImages: [],
            isPracticing: false,
            isPracticingModal: false,
            allowEdit:true,
            vaccination_chart:false
        }

        this.assessFormRef = React.createRef();
        this.sketchpad1 = null;
        this.sketchpad2 = null;
        this._sketch1 = React.createRef();
        this._sketch2 = React.createRef();
        this.urlSketch1 = '';
        this.urlSketch2 = '';
    }

    static getDerivedStateFromProps(props, state) {
        Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
        return true;
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const { hospital_id, patient_id, reference_id } = params;

        SoapFlow.hospital(hospital_id)
        const vaccination_chart = SoapFlow.showElement('paediatric', 'vaccination_chart');
        this.setState({vaccination_chart: vaccination_chart});

        const isPracticingHospitalId = LS_SERVICE.get('practicing')?.hospital_id;

        if (+isPracticingHospitalId === +hospital_id) {
            this.setState({
                isPracticing: true
            })
        }

        const patient = {
            hospital_id,
            patient_id,
            qms_token_id: reference_id
        }
        let hospitalConfigData = LS_SERVICE.get('hospitalConfigData')??[];
        hospitalConfigData = hospitalConfigData.find(item=>item.hospital_id==hospital_id);

        let allowEdit = hospitalConfigData?ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'button'):true;

        this.setState({
            hospital_id,
            patient_id,
            reference_id,
            patient,
            allowEdit
        }, () => {
            if (LS_SERVICE.has('hospitalConfigData')) {
                const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
                let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
                headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' }); // IMPORTANT TO HIT AS PER THE VIEW
                this.handleAssessmentData();
                this.fetchDiagnosisData();
                this.fetchImages();
                this.handleSoapFlow();
                jQueryLabels();
                FixedCta();
            }
        });

        // if (this.props.location.state !== undefined) {
        //     this.setState({
        //         patient: this.props?.location?.state?.patient
        //     }, () => {
        //         this.handleAssessmentData();
        //         this.fetchDiagnosisData();
        //         this.fetchImages();
        //         jQueryLabels();
        //         FixedCta();
        //     })
        // } else {
        //     this.getPatientData();
        //     jQueryLabels();
        //     FixedCta();
        // }
    }

    autoHeight = (e) => {
        e.target.style.height = e.target.scrollHeight + "px";
    }

    getPatientData = _ => {
        const { match: { params } } = this.props;
        const { reference_id } = params;
        var reference_type = LS_SERVICE.get('reference_type');
        Axios.get(PATIENT_DATA({ reference_id, reference_type }))
            .then(success => {
                const patient = {
                    ...success.data.data.results,
                    qms_token_id: success.data.data.results.qmsToken.qms_token_id
                }
                this.setState({
                    patient
                }, () => {
                    this.handleAssessmentData();
                    // this.fetchDiagnosisData();
                    // this.fetchImages()
                })
            })
            .catch(err => console.log(err))
    }

    handleSoapFlow = _ => {
        if (!LS_SERVICE.has('goToSoapPatientPageList') || LS_SERVICE.get('goToSoapPatientPageList').length === 0) {
            this.handleSoapInit()
        }
    }

    handleSoapInit = async _ => {

        const soapList = await API_SERVICE.post(SOAP_CONFIGURATION_LIST, {
            hospital_id: this.state.patient.hospital_id,
            staff_id: LS_SERVICE.get('staff_id'),
            speciality_id: LS_SERVICE.get('speciality_id') || null
        });

        const pageList = [];
        const newList = [];

        const newSoapList = Object.values(soapList).map((data) => {
            return data.sort((a, b) => a.display_ordering - b.display_ordering)
        });

        Object.values(newSoapList).flat().forEach((en) => {
            if (en.display === 'Y') {
                if (!pageList.includes((en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name)) {
                    pageList.push((en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name);
                    newList.push({
                        'url': en.alternate_url_format,
                        'parent_module': en.parent_module,
                        'module': (en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name
                    })
                }
            }
        });

        LS_SERVICE.set('goToSoapPatientUrl', newList);
        LS_SERVICE.set('goToSoapPatientPageList', pageList);
    }

    handleAssClearForm = e => {
        this.setState({
            diagnosis_name: '',
            stage: 0,
            type: 0,
            status: 0,
            image_uploaded: '',
            image_comment: '',
            formModeEdit: false,
            diagnosisList: [],
            diagnosis_notes: '',
        });

        this.assessFormRef.current.setFieldsValue({
            diagnosis_name: ''
        })
    }

    handleAssessmentData = async () => {
        const { patient } = this.state;
        const { qms_token_id } = patient;

        const assessDataPromise = await Axios.get(ASSESSMENT_API({ reference_type: LS_SERVICE.get('reference_type'), reference_id: qms_token_id }))

        if (assessDataPromise.data) {
            let { data } = assessDataPromise.data;
            let assessmentTempArray = [];
            let addedAssessmentList = [];
            if (data.details || data.details.length > 0) {
                data.details.map((data, i) => {
                    var tempAssessmentObj = {
                        description: data?.diagnosisInfo?.diagnose_description,
                        diagnosis_id: data?.diagnosisId,
                        icd_code: data?.diagnosisInfo?.icd_code,
                        id: data.id,
                        image_comment: data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].comment : null,
                        image_uploaded: data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].imagePath : null,
                        stage: data.stage,
                        type: data.type,
                        status: data.status,
                        diagnosis_notes: data.diagnosisNotes,
                    }
                    assessmentTempArray.push(tempAssessmentObj);
                    addedAssessmentList.push(tempAssessmentObj.diagnosis_id)
                });
            }
            var constants = {
                assessmentStage: STAGE,
                assessmentType: TYPE
            };

            this.setState({
                previousDiagnosis: data.activeDiagnosis,
                assessmentData: assessmentTempArray,
                addedAssessmentList: addedAssessmentList,
                constants: constants,
                assessDataLoaded: true
            })
        }
    }

    handleStatusSwitch = async (e, i, data) => {
        if(!this.state.allowEdit)
            return;

        const { id, status } = data;
        let { assessmentData } = this.state;

        const switchPromise = await Axios.get(ASSESSMENT_STATUS_UPDATE({ id, status: e ? 0 : 1 }))

        if (switchPromise) {
            // this.handleAssessmentData();
            assessmentData[i].status = e ? 0 : 1;
            this.setState({ assessmentData });

            const { data } = switchPromise;

            notification.success({
                message: data.message,
                placement: 'topRight'
            })
        }
    }

    fetchDiagnosisData = async search => {
        this.setState({ diagnosisList: [], formIsFetching: !this.state.formIsFetching });

        const diagnosisDataPromise = await Axios.post(SEARCH_DIAGNOSIS, qs.stringify({ search }))

        if (diagnosisDataPromise.data) {
            let { data } = diagnosisDataPromise;
            this.setState({
                diagnosisList: data,
                formIsFetching: !this.state.formIsFetching
            });
        }
    }

    fetchImages = _ => {
        Axios.get(IMAGE_LIBRARY)
            .then(success => {
                const { data } = success.data;
                this.setState({
                    assessmentImages: data.assessmentImages
                })
            })
            .catch(err => console.log(err))

    }

    handleOnDiagnosisChange = (diagnosis_name, props) => {
        const diagnosis_selected = {}
        Object.assign(diagnosis_selected, props.dataid);
        this.setState({
            diagnosis_name,
            diagnosis_selected
        }, () => this.handleAssessmentList())

        AntSelectLabels();
    }

    handleAssessmentList = async _ => {
        const { id } = this.state.diagnosis_selected;

        const PARAMS = {
            diagnosis: id,
            hospital_id: LS_SERVICE.get('slot_hospital_id'),
            speciality_id: LS_SERVICE.get("speciality_id")
        }

        const assessListPromise = await Axios.post(ASSESSMENT_LIST, qs.stringify(PARAMS))

        if (assessListPromise.data.status) {
            this.setState({
                assess_template_list: assessListPromise.data.data.templates,
            }, () => {
                const { assess_template_list, formModeEdit } = this.state;
                if (assess_template_list.length > 0 && !formModeEdit) {
                    this.handleModalPopup(null, 'assessTemplateListModal')
                }
            })
        }
    }

    handleFavourClick = async (e, data) => {
        e.stopPropagation();
        const favouriteDiagPromise = await Axios.post(FAVOURITE_DIAGNOSIS, { id: data.id });

        // const searchKeys = document.querySelector('.ant-select-selection-item .desc') !== null ? document.querySelector('.ant-select-selection-item .desc').innerText : document.querySelector('#diagnosis_name').value;

        const elem = document.querySelector('#diagnosis_name').parentElement?.nextSibling?.querySelector('.desc');
        let searchKeys = elem !== undefined ? elem.innerText : document.querySelector('#diagnosis_name').value;
        searchKeys = searchKeys.replace(/[^\w\s]/gi, ' ');

        if (favouriteDiagPromise.data) {

            this.fetchDiagnosisData(searchKeys);

            notification.success({
                message: 'Favourite Updated',
                placement: 'topRight'
            })
        }
    }

    handleOnChange = e => {
        let { name, value } = e.currentTarget;

        if (name === "image_comment_temp") {
            value = value.charAt(0).toUpperCase() + value.slice(1);
        }

        this.setState({
            [name]: value
        })
    }

    onselectDiagnosisNotesChange = (e) => {
        this.setState({ diagnosis_notes: e.target.value })
    }

    clearEdit = () => {
        this.setState({
            editData: null,
            editIndex: -1
        })
    }


    updateAssessmentData = async (data) => {
        /* data.selected=true;
         let assessmentData = this.state.assessmentData;
         let addedAssessmentList=[];
         var index=data.i;*/
        if (data.i != -1)
            data.assessment_id = data.id
        data.reference_type = LS_SERVICE.get('reference_type');
        data.reference_id = this.state.patient.qms_token_id;

        const assFormPromise = await Axios.post(ASSESSMENT_STORE, qs.stringify(data))
        this.setState({ editData: null, editIndex: -1 })
        if (assFormPromise.data) {

            this.handleAssessmentData();
            notification.success({
                message: assFormPromise.data.message,
                placement: 'topRight'
            })
            /*this.setState({
                formModeEdit: false,
                isProcessing: false
            })*/
        }
    }

    /*handleAssessmentFormSubmit = async e => {

        this.setState({
            isProcessing: !this.state.isProcessing
        })

        const { stage, type, status, image_comment, image_uploaded, patient, diagnosis_selected, diagnosis_notes } = this.state;
        const { qms_token_id } = patient;
        const { id } = diagnosis_selected;

        const isUrl = IS_URL(image_uploaded);

        const PARAMS = {
            diagnosis_id: id,
            stage,
            type,
            status,
            reference_type: LS_SERVICE.get('reference_type'),
            reference_id: qms_token_id,
            assessment_id: id,
            image_uploaded: isUrl ? -1 : image_uploaded,
            image_comment,
            diagnosis_notes: diagnosis_notes
        }

        const assFormPromise = await Axios.post(ASSESSMENT_STORE, qs.stringify(PARAMS))

        if (assFormPromise.data) {
            this.handleAssClearForm();
            this.handleAssessmentData();
            notification.success({
                message: assFormPromise.data.message,
                placement: 'topRight'
            })
            this.setState({
                formModeEdit: false,
                isProcessing: false
            })
        }

    }*/

    handleEdit = (data, i) => {

        const { isPracticing } = this.state;

        if (!isPracticing) {
            this.handleModalPopup(null, 'isPracticingModal');
            return;
        }

        this.setState({
            actionText: "Edit",
            editData: this.state.assessmentData[i],
            editIndex: i
        }, () => {
            const tabId = document.getElementById('create-assessment-details');
            window.scrollTo({ top: (tabId.offsetTop - 10), behavior: 'smooth' });
        })
        AntSelectLabels();
    }


    handleModalPopup = (e, popupName, data = null, actionType = '', objId = null, patientDocs) => {
        if (e !== null)
            e.preventDefault();

        this.setState({
            [popupName]: !this.state[popupName]
        }, () => {
            const { imageReferenceModal, deleteConfirmModal } = this.state;

            // if (imageReferenceModal) {
            //     this.sketchpad1 = new Sketchpad({
            //         element: '#' + this._sketch1.current.id,
            //         width: 960,
            //         height: 320,
            //     });
            // }

            /*if (deleteConfirmModal) {
                // this.setState({ actionType, objId });
                // For Assessment
                if (actionType === 'deleteAssessment') {
                    this.setState({ delete_text: data.diagnosisInfo.diagnose_description, delete_data: data,deleteActionType:actionType,deleteObjId:objId,deleteMessage: 'Are you sure you want to delete assessment?' });
                }

               

            }*/

            if (deleteConfirmModal) {
                if (data !== null) {
                    this.setState({
                        delete_text: data.data.description,
                        delete_data: data.data
                    })
                }
            }

            /*if (image !== null) {
                this.sketchpad2 = new Sketchpad({
                    element: '#' + this._sketch2.current.id,
                    width: 960,
                    height: 500,
                });
                this.sketchpad2.penSize = 2;
                const img = Object.entries(image)[0];
                this.setState({
                    selectedImg: img[0]
                }, () => {
                    this.drawBackgroundCanvas(this._sketch2, img[1])
                })
            }*/
        })
    }

    drawBackgroundCanvas = (sketchRef, imgSrc) => {
        var canvasWidth = sketchRef.current.width;
        var canvasHeight = sketchRef.current.height;

        // Draw Image on the Canvas
        var canvas = sketchRef.current;
        var ctx = canvas.getContext('2d');
        var img = new Image();
        img.src = imgSrc;
        var imageWidth;
        var imageHeight;
        img.onload = function () {
            if (this.width > canvasWidth && this.height > canvasHeight) {
                imageHeight = canvasHeight;
                imageWidth = this.width * (imageHeight / this.height);
            }
            else if (this.width > canvasWidth && canvasHeight >= this.height) {
                imageWidth = canvasWidth;
                imageHeight = this.height * (canvasWidth / this.width);
            }
            else if (canvasWidth >= this.width && this.height > canvasHeight) {
                imageHeight = canvasHeight;
                imageWidth = this.width * (imageHeight / this.height);
            }
            else if (canvasWidth >= this.width && canvasHeight >= this.height) {
                imageWidth = this.width;
                imageHeight = this.height;
            }

            var posX = (canvasWidth - imageWidth) / 2;
            img.setAttribute('crossorigin', 'anonymous');
            ctx.drawImage(this, posX, 0, imageWidth, imageHeight);
        };
    }

    onPen = () => {
        this.sketchpad1.penSize = 2;
        this.sketchpad1.color = '#000'
    }

    onEraser = () => {
        this.sketchpad1.penSize = 10;
        this.sketchpad1.color = '#fff';
    }

    onUndo = () => {
        this.sketchpad1.undo();
    }

    onRedo = () => {
        this.sketchpad1.redo();
    }

    onBrush = () => {
        this.sketchpad1.penSize = 8;
        this.sketchpad1.color = '#000';
    }

    handleFreeHandImageSave = e => {
        e.preventDefault();

        const { image_comment_temp } = this.state;

        this.setState({
            image_uploaded: this._sketch1.current.toDataURL(),
            image_comment: image_comment_temp,
            image_comment_temp: ''
        })

        this.handleModalPopup(null, 'imageReferenceModal');
    }

    handleImageEditSubmit = e => {
        e.preventDefault();
        const { image_comment_temp } = this.state;

        this.setState({
            image_uploaded: this._sketch2.current.toDataURL(),
            image_comment: image_comment_temp,
            image_comment_temp: ''
        }, () => {
            if (this.sketchpad1 !== null) { this.sketchpad1.clear() }
        });

        this.handleModalPopup(null, 'imageEditModal');
    }

    handleAssessmentEdit = (e, data) => {
        e.preventDefault();
        this.assessFormRef.current.setFieldsValue({
            diagnosis_name: data.diagnosisInfo.diagnose_description
        });
        this.setState({
            // formModeEdit: !this.state.formModeEdit,
            formModeEdit: true,
            diagnosis_selected: data,
            diagnosis_name: data.diagnosisInfo.diagnose_description,
            stage: data.stage,
            status: data.status,
            type: data.type,
            image_uploaded: data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].imagePath : '',
            image_comment: data.VisitAssessmentImage.length > 0 ? data.VisitAssessmentImage[0].comment : '',
            diagnosis_notes: data.diagnosisNotes !== null ? data.diagnosisNotes : ''
        })

        AntSelectLabels();
    }

    handleDeletePopSubmit = e => {
        const { delete_data } = this.state;

        this.setState({
            spin_loading: true
        })

        Axios.get(ASSESSMENT_DELETE({ id: delete_data.id }))
            .then(success => {
                this.handleAssessmentData();
                this.setState({
                    spin_loading: false
                })
                this.handleModalPopup(null, 'deleteConfirmModal');

                this.assessFormRef.current.FormClear();
            })
            .catch(err => console.log(err))

    }

    handleAssessmentContinue = e => {
        e.preventDefault();
        const { patient } = this.state;
        const { patient_id, qms_token_id, hospital_id } = patient;

        const isTodayVisitArr = LS_SERVICE.has('isTodayVisit') ? LS_SERVICE.get('isTodayVisit') : [];
        const pageListArr = LS_SERVICE.get('goToSoapPatientPageList');
        let index = pageListArr.indexOf('diagnosis');
        index++;
        const REFERENCE_TYPE = LS_SERVICE.get('reference_type');
        if (index < pageListArr.length && !isTodayVisitArr.includes(qms_token_id)) {
            if(this.state.vaccination_chart == true){
                this.props.history.push({
                    pathname: `/patient/todays-visit/plan/vaccination-record/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
                    state: { patient }
                })
            }else{
                let soapurl = LS_SERVICE.get('goToSoapPatientUrl')[index].url;
                soapurl = soapurl.replace("HOSPITALID", hospital_id);
                soapurl = soapurl.replace("REFID", qms_token_id);
                soapurl = soapurl.replace("REFTYPE", REFERENCE_TYPE);
                soapurl = soapurl.replace("PATID", patient_id);
                this.props.history.push({
                    pathname: soapurl,
                    state: { patient }
                })
            }
        } else {
            this.props.history.push({
                // pathname: `/patient/todays-visit/plan/prescription/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
                pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
                state: { patient }
            })
            LS_SERVICE.set('blockname', 'assessment');
        }
    }

    handleTabSwitch = key => {
        if (key === "1") {
            setTimeout(() => {
                this.sketchpad1 = new Sketchpad({
                    element: '#' + this._sketch1.current.id,
                    width: 960,
                    height: 320,
                });
            }, 0);
        }
    }

    handleTemplateRadioClick = (e, templateid) => {
        // const { patient } = this.state;

        this.setState({
            radioTemplateSelectedId: templateid
        })
        // this.props.history.push({ 
        //     pathname: "/template/select/" + REFERENCE_TYPE + "/" + patient?.qms_token_id + '/' + templateid, 
        //     state: { patient } 
        // });   
    }

    handleTemplateRadioSubmit = (templateId) => {
        const { patient, isPracticing } = this.state;
        const { patient_id, qms_token_id, hospital_id } = patient;
        const REFERENCE_TYPE = LS_SERVICE.get('reference_type');
        if (!isPracticing) {
            this.handleModalPopup(null, 'isPracticingModal');
            return;
        }

        if (templateId) {
            this.props.history.push({
                pathname: `/template/select/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}/${templateId}`,
                state: { patient }
            });
        }
    }

    enablePractice = async e => {
        e.preventDefault();
        practiceService.init({ initiater: 'SOAP Pages', status: 'start' });
        const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
          hospital_id: this.state.hospital_id,
          practice_session: 'start'
        });
    
        let is_practicing;
        if (practice.data && practice.data.is_practicing === 'Y') {
          is_practicing = true;
          LS_SERVICE.set('practicing', {
            status: true,
            hospital_id: this.state.hospital_id
          });
        } else {
          LS_SERVICE.delete('practicing');
          is_practicing = false;
        }
        this.setState({
            isPracticing: is_practicing,
        }, () => {
            this.handleModalPopup(null, 'isPracticingModal');
        });
    }

    handlePracticeModal = data => {
        this.handleModalPopup(null, data);
    }

    render() {

        const { diagnosis_selected, patient, assess_template_list, isPracticing } = this.state;
        const REFERENCE_TYPE = LS_SERVICE.get('reference_type');
        return (
            <>
                {/* Page sidebar */}
                {(patient != undefined && patient.patient_id != undefined) ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}

                {/* Page content */}
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            {/* SOAP Bar */}

                            {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ?
                                <SubHeader patient={patient} />
                                : null}
                            <div className="col-12 mb-4">
                                <div className="float-left">
                                    <h1 className="page-title mt-2 mb-0">Assessment</h1>
                                </div>
                                {this.state.allowEdit?
                                    <div className="float-right">
                                        {isPracticing ? (
                                            <Link to={{
                                                pathname: `/template/select/${patient?.hospital_id}/${patient?.patient_id}/${REFERENCE_TYPE}/${patient?.qms_token_id}`,
                                                state: { patient }
                                            }} className="btn btn-outline-primary" id="from-template">FROM CARE PROTOCOL</Link>
                                        ) : (
                                            <a className="btn btn-outline-primary" id="from-template" href="#!" onClick={e => this.handleModalPopup(e, 'isPracticingModal')}>FROM CARE PROTOCOL</a>
                                        )}
                                    </div>
                                    :null}
                            </div>
                        </div>
                        <div className="row">
                            {this.state.assessDataLoaded ?
                                <AssessmentForm ref={this.assessFormRef} patient={this.state.patient} formType={"soap-full"} actionText={this.state.actionText} updateData={this.updateAssessmentData} previousDiagnosis={this.state.previousDiagnosis}
                                                autofocus={false} editData={this.state.editData} editIndex={this.state.editIndex} addedAssessmentList={this.state.addedAssessmentList}
                                                handleTemplateRadioSubmit={this.handleTemplateRadioSubmit} clearEdit={this.clearEdit} isPracticing={isPracticing} practiceModal={this.handlePracticeModal} allowEdit={this.state.allowEdit}
                                />
                                : null}
                            {/* <TemplateAssesment /> */}
                        </div>
                        <div className="row mt-4">
                            <div className="col-12" id="assessment-table-container">
                                {this.state.assessDataLoaded ?
                                    <AssessmentTable assessmentData={this.state.assessmentData} viewType={"soap-full"} statusSwitch={this.handleStatusSwitch}
                                                     showAction={this.state.allowEdit?true:false} showSelectCheck={false} constants={this.state.constants} updateSelection={this.handleSelectCheck}
                                                     handlePopUp={this.handleModalPopup} handleEdit={this.handleEdit} isPracticing={isPracticing} practiceModal={this.handlePracticeModal} allowEdit={this.state.allowEdit}
                                    />
                                    : null}
                            </div>
                        </div>
                        <div className="fixed-cta">
                            <a href="#" className="btn btn-primary px-4 assessment-continue-button"
                               onClick={this.handleAssessmentContinue}>CONTINUE</a>
                        </div>
                    </div>
                </div>


                {/** IMAGE EDIT MODAL */}
                {/*<Modal
                    className="image_annotation_dialog"
                    title={false}
                    visible={this.state.imageEditModal}
                    onCancel={(e) => this.handleModalPopup(e, 'imageEditModal')}
                    footer={false}
                >
                    <form onSubmit={this.handleImageEditSubmit}>
                        <div className="form-group">
                            <canvas id="sketch2" ref={this._sketch2}></canvas>
                        </div>
                        <div className="form-group">
                            <label htmlFor="library-comments" className="sr-only">Comments</label>
                            <input type="text" className="form-control image-library-comment" maxLength="50" name="image_comment_temp"
                                value={this.state.image_comment_temp} placeholder="Comments" onChange={this.handleOnChange} />
                        </div>
                        <div className="form-group text-center">
                            <button typle="primary" type="submit" className="btn btn-primary px-5 save-library-image text-uppercase">
                                Add
                            </button>
                        </div>
                    </form>
                </Modal>*/}

                {/** ASSESSMENT LIST MODAL */}
                <Modal
                    // className="image_annotation_dialog"
                    title={false}
                    visible={this.state.assessTemplateListModal}
                    closeIcon={false}
                    onCancel={(e) => this.handleModalPopup(e, 'assessTemplateListModal')}
                    closable={false}
                    width="600px"
                    footer={
                        <div className="col-12 text-center">
                            <button type="button" className="btn btn-outline-secondary mr-3"
                                    onClick={e => this.handleModalPopup(e, 'assessTemplateListModal')}>CANCEL</button>
                            <button type="button" className="btn btn-primary px-4" onClick={this.handleTemplateRadioSubmit}>CONFIRM</button>
                        </div>
                    }
                >
                    <div className="row">
                        <div className="col-12">There are templates associated with the diagnosis</div>
                        <div className="col-10"><strong>{this.state.diagnosis_selected.description}</strong></div>
                        <div className="col-2 text-right text-secondary">{diagnosis_selected.icd_code}</div>
                        <div className="col-12 px-3 pt-2">

                            <div className="row emrfrm">
                                <p className="mb-2"><strong>Please select the one you like to use.</strong></p>
                                {assess_template_list.length > 0 ? (
                                    assess_template_list.map((atl, i) => {
                                        return (
                                            <div className="col-12 border-bottom pl-0 pb-2 mb-3" key={i}>
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input type="radio" className="custom-control-input" name="template_id" id={`template_id_${atl.template_id}`}
                                                           defaultValue={atl.template_id} checked={atl.template_id === this.state.radioTemplateSelectedId}
                                                           onChange={e => this.handleTemplateRadioClick(e, atl.template_id)} />
                                                    <label className="custom-control-label text-basic" htmlFor={`template_id_${atl.template_id}`}>{atl.name}</label>
                                                </div>
                                                <div className="float-right">
                                                    <a href="#" className="nostyle-link">
                                                        <i className={atl.is_favorite ? 'icon_star favourite' : 'icon_star'}></i>
                                                    </a>
                                                </div>
                                            </div>

                                        )
                                    })) : null}

                            </div>
                        </div>
                    </div>
                </Modal>

                {/* || DELETE MODAL */}
                <Modal
                    visible={this.state.deleteConfirmModal}
                    onCancel={(e) => this.handleModalPopup(e, 'deleteConfirmModal')}
                    style={{ top: 40 }}
                    closable={false}
                    footer={[
                        <div className="text-center">
                            <button className="btn btn-outline-secondary px-5 mr-2 cancel-delete-history"
                                    onClick={e => this.handleModalPopup(e, 'deleteConfirmModal')}>CANCEL</button>
                            <button className="btn btn-primary px-5 submit-delete-history"
                                    disabled={this.state.spin_loading}
                                    onClick={e => this.handleDeletePopSubmit(e)}>
                                DELETE{this.state.spin_loading ? LOADER : ''}</button>
                        </div>
                    ]}
                >
                    <div className="row">
                        <div className="col-12 text-center confirm-message">
                            <h6 className="mb-0">Are you sure you want to delete assessment for {this.state.delete_text}?</h6>
                        </div>
                    </div>
                </Modal>

                {/** || isPracticing Modal */}
                <Modal
                    title={false}
                    closable={false}
                    visible={this.state.isPracticingModal}
                    footer={false}
                >
                    <div className="row">
                        <div className="col-12 my-3 text-center">
                            <h6>Do you want to Start Practice?</h6>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <div className="btn btn-outline-secondary px-5" onClick={e => this.setState({ isPracticingModal: false })}>No</div>
                        <div className="btn btn-primary px-5" onClick={this.enablePractice}>Yes</div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default AssessmentComponent;
