export function ipdListExistingOrders(requestdata) {
  var final_data = {
    message: requestdata.message,
  }
  final_data.data = []
  for (var data of requestdata.data) {
    // if(data.serviceCategoryCode!="REF")
    // {
    final_data.data.push({
      id: data.id,
      service_name: data.service_name,
      sub_department_name: data.sub_department_name,
      department_name: data.department_name,
      priority: data.priority,
      status: data.status && data.status != null && data.status != '' ? data.status : 'Ordered',
      statuscode: data.statuscode,
      stopMedication: data.stopMedication,
      doses: data.doses,
      dosage: data.dosage,
      dosage_name: data.dosage_name,
      form_name: data.form_name,
      number_of_days: data.number_of_days,
      route: data.route,
      frequency: data.frequency,
      daily_frequency_name: data.daily_frequency_name,
      daily_frequency: data.daily_frequency,
      serviceCategoryCode: data.serviceCategoryCode,
      ordered_by: data.ordered_by,
      ordered_Location: data.ordered_Location,
      enteredDate: data.enteredDate,
      modifiedDate: data.modifiedDate,
      iV_Infusion: data.iV_Infusion,
      schedule_name: data.schedule_name,
      schedules: data.schedules ? data.schedules.split(',').map((i) => Number(i)) : [],
      dose_for_schedule_applicable: data.dose_for_schedule_applicable,
      taperdose_sn: data.taperdose_sn,
      timeslots: data?.timeslots ? data.timeslots.filter((d) => d.taperdose_sn == data.taperdose_sn) : [],
      orderInstructions: data.orderInstructions,
      remarks: data.remarks,
      taper_row: data.taper_row,
      statusId: data.statusId,
      orderId: data.orderId,
      orderNo: (((data.serviceCategoryCode == "REF") ? "Referrals " : (data.serviceCategoryCode != "PH") ? "Test - " : "") + data.orderNo),
      orderInvestigationId:data.orderInvestigationId,
      serviceId: data.serviceId,
      storeId: data.storeId,
      batchId: data.batchId,
      duration: data.duration,
      strength: data.strength,
      issuedQty: data.issuedQty,
      active_medicine: data.active_medicine,
      discharge_medicine: data.discharge_medicine,
      high_alert_medicine: data.high_alert_medicine,
      quantity: data.quantity,
      consumableorder: data.consumableorder,
      investigationStatus: data.investigationStatus,
      departmentType: data.departmentType,
      imageURL: data.imageURL,
      uom_first_code: data.uom_first_code,
      uom_first_description: data.uom_first_description,
      uom_first_id: data.uom_first_id,
      uom_second_code: data.uom_second_code,
      uom_second_description: data.uom_second_description,
      uom_second_id: data.uom_second_id,
      is_IV_addictive: data.is_IV_addictive,
      show_additive: data.show_additive,
      iV_drug_name: data.iV_drug_name,
      additive_volume: data.additive_volume,
      additive_volume_form: data.additive_volume_form,
      additive_infuse_over_hrs: data.additive_infuse_over_hrs,
      additive_infuse_over_hrs_form: data.additive_infuse_over_hrs_form,
      additive_rate: data.additive_rate,
      additive_rate_form: data.additive_rate_form,
      startMediDate: data.startmedicationdatetime,
      startmedicationdatetime: data.startmedicationdatetime,
      substituteitemid: data.substituteitemid,
      substituteissued: data.substituteissued,
      substituteitemname: data.substituteitemname,
      endMediDate: data.endmedicationdatetime,
      doctorName: data.doctorName,
      orderRemarks: data.orderRemarks,
      convertedQty: data.convertedQty,
      readyfordelivery: data.readyfordelivery,
      taperdose: data.taperdose || false,
      controlled_substance_narcotic_alert_medicine:data.controlled_substance_narcotic_alert_medicine ||false,
      form_id: data.form_id,
      factor2: data.factor2,
      actualdrugid: data.actualdrugid,
      actualdrugname: data.actualdrugname,
      route_id: data.route_id,
      strength_id: data.strength_id,
      frequency_name: data.frequency_name,
      days_in_week: data.days_in_week,
      days_in_week_text: data.days_in_week_text,
      dosage_form_text: data.dosage_form_text,
      instruction_id: data.instruction_id,
      dosage_form: data.dosage_form,
      drug_fixed_qty: data.drug_fixed_qty,
      priority_id: data.priority_id,
      give_stat_dose_now: data.give_stat_dose_now,
      Retain_drug: data.Retain_drug,
      brought_from_home: data.brought_from_home,
      endmedicationdatetime: data.endmedicationdatetime,
      frequency_id: data.frequency_id,
      cancel_reason: data.cancel_reason,
      attachedimageURL: data.attachedimageURL,
      confidential_service: data.confidential_service,
      grossAmount: data?.grossAmount ? parseFloat(data.grossAmount).toFixed(2) : 0.00,
      unit: data?.unit || null,
      addendum :data?.addendum || null,
      addendumBy:data?.addendumBy ||null,
      addendumTime:data?.addendumTime ||null,
      isMarConsuption: data.isMarConsuption,
      holdReason: data?.holdReason,
      TatOutsourceLab : data?.TatOutsourceLab || null,
      requisition_comments: data.requisition_comments || null,
    })
    // }
  }

  return {
    status: true,
    message: requestdata.message,
    data: final_data
  };
}