import React, { Component } from 'react';
import LOGO from '../../assets/images/logo-myhealthcare.svg';
import OtpInput from 'react-otp-input';
import qs from 'qs';
// import "./otp.scss";
import { HOSPITAL_CONFIG, LOADER_RED, OTP_LOGIN, OTP_VERIFY, SYNC_TOKEN, USERTYPES } from '../../utils/constant';
import Axios from 'axios';
import LS_SERVICE from '../../utils/localStorage';
import { notification } from 'antd';
import { headerService$, vcOpdEnabledSubjectService } from '../../utils/rxjs-sharing';


class OtpComponent extends Component {

    state = {
        otp: '',
        syncTokenProcessing: false,
        staff_id: '',
        staff_name: '',
        status: '',
        token: '',
        user_type: '',
        otpDisabled: false,
        verifyOtpBtnDisabled: false,
        isInputSecure:true,
        loginType: ''
    }

    constructor(props) {
        super(props);
        if (LS_SERVICE.has('is_authenticated') && LS_SERVICE.get('is_authenticated')) {
            this.props.history.push('/dashboard');
        }
    }

    componentDidMount() { 
        this.setState({
            loginType:this.props.location.state
        })
    }

    componentWillUnmount() {
        this.setState({
            syncTokenProcessing: false
        })
    }

    handleLoginOtpClick = (event) => {
        event.preventDefault();

        this.setState({
            showMobile: !this.state.showMobile
        })
    }
    togglePinVisiblity = () => {
        const { isInputSecure } = this.state;
        this.setState({ isInputSecure: !isInputSecure });
          
       };

    sumbitFormMN = () => {
        const mobile = LS_SERVICE.get('loginMobile')

        this.setState({
            otpDisabled: true
        })

        Axios.post(OTP_LOGIN, qs.stringify({ mobile ,loginType:this.state.loginType }))
            .then(success => {
                let data = success.data;
                notification.destroy();
                notification.info({
                    message: data.message,
                    placement: 'topRight'
                });
                this.setState({
                    otpDisabled: false
                })
            })
            .catch(err => console.log(err))
    }

    handleOnSubmit = (event) => {
        event.preventDefault();

        const { otp } = this.state;
        this.setState({
            verifyOtpBtnDisabled: true
        });
        notification.destroy();
        let mobileNo = LS_SERVICE.get('loginMobile');
        let isResetting = LS_SERVICE.get('reset_user_pin');
        if (mobileNo !== '' && mobileNo !== null) {
            Axios.post(OTP_VERIFY, qs.stringify({ mobile: LS_SERVICE.get('loginMobile'), otp ,loginType:this.state.loginType }))
                .then(success => {
                    this.setState({
                        verifyOtpBtnDisabled: false
                    });

                    let {
                        message,
                        staff_id,
                        staff_name,
                        status,
                        token,
                        user_type } = success.data;

                    if (status && isResetting !== null && isResetting) {
                        LS_SERVICE.delete('reset_user_pin');
                        this.props.history.push('/set-pin',this.state.loginType);
                    }

                    if (status && isResetting === null) {
                        this.setState({
                            staff_id,
                            staff_name,
                            token
                        })

                        LS_SERVICE.set('token', token);
                        LS_SERVICE.set('staff_name', staff_name);
                        LS_SERVICE.set('staff_id', staff_id);
                        LS_SERVICE.set('user_type', user_type);
                        LS_SERVICE.delete('loginMobile');
                        this.handleSyncToken(staff_id);

                        notification.info({
                            message,
                            placement: 'topRight'
                        });
                    }

                })
                .catch(err => {
                    console.log(err);
                    const error = err.response?.data;
                    notification.error({
                        message: error?.message,
                        placement: 'topRight'
                    });
                    this.setState({
                        verifyOtpBtnDisabled: false
                    });
                })
        }
    }

    handleSyncToken = () => {

        const { staff_id } = this.state;

        this.setState({
            syncTokenProcessing: true
        })

        Axios.post(SYNC_TOKEN, qs.stringify({ staff_id }))
            .then(success => {
                const result = success.data;
                if (result.status) {
                    LS_SERVICE.set('staff_image', result.staff.profile_picture);
                    LS_SERVICE.set('is_authenticated', true);

                    if (result.staff.role_id == USERTYPES.doctor) {
                        LS_SERVICE.set('doctor', {
                            name: ('Dr ' + result.staff.first_name +' '+ (result.staff.last_name != null ? result.staff.last_name : '')),
                            code: result.staff.code
                        });

                        if (result.pending_patients && result.pending_patients.length > 0) {
                            LS_SERVICE.set(
                                'pending_patients',
                                result.pending_patients
                            );
                        } else {
                            LS_SERVICE.delete('pending_patients');
                        }
                        if (result.staff.doctor_speciality != undefined && result.staff.doctor_speciality.length > 0) {
                            LS_SERVICE.set('speciality_id', result.staff.doctor_speciality[0]);
                        } else {
                            LS_SERVICE.delete('speciality_id');
                        }

                        if (result.slots && result.slots.length > 0) {
                            LS_SERVICE.set(
                                'slot_hospital_id',
                                result.slots[0]['id']
                            );
                            LS_SERVICE.set(
                                'slot_hospital_master',
                                result.slots[0]['own_master']
                            );
                            LS_SERVICE.set('slot_hospitals', result.slots);
                        } else {
                            LS_SERVICE.delete('slot_hospital_id');
                        }
                        
                        if (result.slots && result.slots.length == 1) {
                            this.props.history.push('/patient-queue/' + result.slots[0].id);
                        } else {
                            this.props.history.push('dashboard');
                        }
                    }

                    if (result.staff.role_id == USERTYPES.nurse) {
                        // set selected nurse doctor ids
                        if(result?.nurse_doctors.length > 0){
                            LS_SERVICE.set('nurse_doctor_id', result?.nurse_doctors);
                        }
                        
                        //Check if nurse logged in and set doctor speciality
                        if (result.doctor && result.doctor.length > 0) {
                            let allDoctors = result.doctor;
                            var doctorSpecialties = [];
                            allDoctors.forEach((v, k) => {
                                var obj = {
                                    "id": v.id,
                                    "specialtiesIds": Object.keys(v.specialityDetail)
                                };
                                doctorSpecialties.push(obj);
                            });
                            LS_SERVICE.set('doctor_speciality_id', doctorSpecialties);
                        }

                        if (result.hospital && result.hospital.length > 0) {
                            LS_SERVICE.set(
                                'slot_hospital_id',
                                result.hospital[0]['hospital_id']
                            );
                            LS_SERVICE.set(
                                'slot_hospital_master',
                                result.hospital[0]['own_master']
                            );
                            LS_SERVICE.set('slot_hospitals', result.hospital);
                        } else {
                            LS_SERVICE.delete('slot_hospital_id');
                        }
                        if (result.doctor && result.doctor.length > 0) {
                            LS_SERVICE.set('nurse_doctors', result.doctor);
                        } else {
                            // LS_SERVICE.delete('nurse_doctors');
                            LS_SERVICE.set('nurse_doctors', []);
                            LS_SERVICE.set('nurse_homecare', true)
                        }
                        this.props.history.push('/patient-queue/' + result.hospital[0]['hospital_id']);
                        LS_SERVICE.set('nurse_doctors', result.doctor);
                        const isVcopdEnabled = result.is_vc_opd_enabled ? result.is_vc_opd_enabled : 0;
                        LS_SERVICE.set('nurse_is_vc_opd_enabled', isVcopdEnabled);
                        vcOpdEnabledSubjectService.init({
                            isVcOpdEnabled: isVcopdEnabled
                        });

                        // if (result.hospital && result.hospital.length > 0) {
                        //     LS_SERVICE.set(
                        //         'slot_hospital_id',
                        //         result.hospital[0]['hospital_id']
                        //     );
                        //     LS_SERVICE.set(
                        //         'slot_hospital_master',
                        //         result.hospital[0]['own_master']
                        //     );
                        //     LS_SERVICE.set('slot_hospitals', result.hospital);
                        // } else {
                        //     LS_SERVICE.delete('slot_hospital_id');
                        // }
                        // if (result.doctor && result.doctor.length > 0) {
                        //     LS_SERVICE.set('nurse_doctors', result.doctor);
                        // } else {
                        //     // LS_SERVICE.delete('nurse_doctors');
                        //     LS_SERVICE.set('nurse_doctors', []);
                        //     LS_SERVICE.set('nurse_homecare', true)
                        // }
                        // this.props.history.push('/patient-queue/' + result.hospital[0]['hospital_id']);
                    }

                    headerService$.showHeader({ showHeader: 'true' });
                }
            })
            .catch(err => console.log(err))
    }

    handleBasicOrSoap = slot_hospitals => {

        slot_hospitals.forEach(async sh => {
            const PARAMS = {
                hospital_id: sh.id,
                speciality_id: LS_SERVICE.get('speciality_id'),
                staff_id: LS_SERVICE.get('staff_id')
            }

            Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
            var success;
            if(LS_SERVICE.get("hospitalConfigDataResponse")?.data){
                success = LS_SERVICE.get("hospitalConfigDataResponse");
            }
            else{
                success = await Axios.post(HOSPITAL_CONFIG, qs.stringify(PARAMS));
                LS_SERVICE.set("hospitalConfigDataResponse", {data: success.data})
            }
                // .then(success => {
                    const { hospital_id, opd_layout, pres_modification, allow_hours, duplicate_order } = success.data.data;
                    const HOS_DATA = {
                        hospital_id,
                        opd_layout,
                        pres_modification,
                        allow_hours,
                        duplicate_order
                    }
                    let data = [];
                    if (LS_SERVICE.has('hospitalConfigData')) {
                        data = LS_SERVICE.get('hospitalConfigData');
                        data = [...data, HOS_DATA]
                    } else {
                        data = [...data, HOS_DATA];
                    }
                    LS_SERVICE.set('hospitalConfigData', data);
                // })
        })
    }

    handleChange = otp => this.setState({ otp });

    render() {
        const { isInputSecure } = this.state;

        return (
            <>
                <div className="container-fluid">
                    <div className="row justify-content-center full-height">
                        <div className="col-auto d-flex align-self-center">
                            <div className="text-center d-flex align-items-center">

                                <div className="text-center d-none" id="js-sync-widget">
                                    <p className="alert alert-danger d-none js-sync-flash-msg"></p>
                                    <p id="js-staff-info"></p>
                                    {LOADER_RED}
                                    <p>Please hold on for couple of seconds while we sync your hospital(s) and appointments.</p>
                                </div>

                                <form className="otp-form" id="verifyOtpForm" onSubmit={this.handleOnSubmit}>
                                    <img className="img-logo otp-logo" src={LOGO} alt="logo" />
                                    <h5 className="text-center mt-5">Enter the OTP received on the registered mobile number</h5>
                                    <div className="form-group pt-5 password_attribute">
                                        <div className="otpinp_wrapper">
                                            <OtpInput
                                                type={isInputSecure ? "text" : "password"}
                                                isInputSecure={this.state.isInputSecure}
                                                value={this.state.otp}
                                                onChange={this.handleChange}
                                                shouldAutoFocus={true}
                                                isInputNum={true}
                                                numInputs={4}
                                                hasErrored={true}
                                            />
                                            <div className={"show_password " + (isInputSecure ? "" : "on")} onClick={this.togglePinVisiblity}><i class="icon_view"></i></div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-0">
                                        <p className="alert alert-danger d-none js-flash-msg"></p>
                                        <button type="submit" disabled={this.state.verifyOtpBtnDisabled} className="btn btn-primary btn-submit otp-btn px-5 mt-4 operational-btns">
                                            SUBMIT
                                        </button>
                                        <p className="pt-3 m-0">
                                            <button type="button" onClick={this.sumbitFormMN} disabled={this.state.otpDisabled} className="Resend-otp btn-link btn-resend operational-btns js-resend-otp textis-primary">Resend OTP</button>
                                        </p>
                                        <p id="js-resend-message"></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default OtpComponent;