import React, { Component } from 'react'
import Axios from 'axios';
import LS_SERVICE from '../../../utils/localStorage';
import { Link } from 'react-router-dom'
import { Form, Select, Modal } from 'antd';
import PDFImg from '../../../assets/images/ic-pdf.png';
import {
  LOADER_RED,
  PATIENT_PRESCRIPTION_PDF
} from '../../../utils/constant';
import moment from 'moment';
import qs from 'qs';

const { Option } = Select;

export default class PatientPrescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEncounterData: props.selectedEncounterData,
      prescriptionData : null,
      prescriptionDoctorData : null,
      loader: true,
      showRxModal: false,
      rxPdfPath: null,
    }
  }

  async componentDidMount() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    await this.getPatientPrescriptionData();
    
  }

  async getPatientPrescriptionData() {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      encounter_Id: selectedEncounterData.encounter_id
    }
    await Axios.post(PATIENT_PRESCRIPTION_PDF, qs.stringify(PARAMS))
    .then(async res => {
      console.log(res.data.data);
      let prescriptionData =  res.status ? res?.data?.data?.visit_details : null;
      let prescriptionDoctorData =  res.status ? res?.data?.data?.doctor_details : null;
      await this.setState({
        prescriptionData: prescriptionData,
        prescriptionDoctorData: prescriptionDoctorData,
        loader: false
      });
    })
  }

  PatientPrescriptionLoad() {
    try {
      const { prescriptionDoctorData } = this.state;
      if ((!this.state.prescriptionData || this.state.prescriptionData.length === 0)) {
        let htmlData = (
          <tr>
            <td className="inner_table text-center" colSpan={3}>No Records Found.</td>
          </tr>
        )
        return htmlData;
      }
      const { prescriptionData } = this.state;
      let htmlData = (
        <tr id={prescriptionData.encounter_Id}>
          <td>Dr. {prescriptionDoctorData.first_name}</td>
          <td>{moment(prescriptionData.createdAt).format('DD MMM YYYY')}</td>
          <td><a onClick={() => { this.setState({ rxPdfPath: prescriptionData.visitPatientPdf, showRxModal: true }); }}><img src={PDFImg} alt="view" width={15} /></a></td>
        </tr>
      )
      return htmlData;
    } catch (err) {
      console.log('PatientEncounterListLoad: ', err);
    }
  }

  
  render() {
    const { loader } = this.state;
    return (
      <>
        { loader ? 
          <div className='row mt-3'>
            <div className="col-12  text-center mt-4">{LOADER_RED}
            </div>
          </div>
          :
          <div className='row mt-3'>
            <div className='col-12'>
              <table className='table table-bordered'>
                  <thead>
                      <tr>
                          <th width="40%" className='px-1 py-2' style={{verticalAlign:'top'}}>Doctor Name</th>
                          <th width="20%" className='px-1 py-2' style={{verticalAlign:'top'}}>Appointment Date</th>
                          <th width="20%" className='px-1 py-2' style={{verticalAlign:'top'}}>Prescription Pdf</th>
                      </tr>
                  </thead>
                  <tbody>
                    {this.PatientPrescriptionLoad()}
                  </tbody>
              </table>
            </div>
          </div>
        }

        <Modal
          title="View Prescription"
          visible={this.state.showRxModal}
          footer={false}
          width={850}
          onCancel={() => { this.setState({ showRxModal: false, rxPdfPath: null }) }}
          style={{ top: 20 }}
        >
          <div className="row">
              <div className="col-12 my-3 text-center">
                  {this.state.rxPdfPath != null ? <iframe src={this.state.rxPdfPath} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                      {LOADER_RED}
                  </iframe> : null}
              </div>
          </div>
        </Modal>
      </>
    )
  }
}
