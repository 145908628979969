import React, { Component, Fragment } from 'react';
import { Modal, Select, Tooltip, Input, Image, notification, Form, Checkbox } from 'antd';
import Axios from 'axios';
import axios from 'axios';
import qs from 'qs';
import LS_SERVICE from '../../utils/localStorage';
import { SAVE_NOTES, GET_DOCTOR_NOTES, UPDATE_DOCTOR_NOTES, LOADER_RED, TEXT_DELETE } from '../../utils/constant';
import {  doctorNotesService } from '../../utils/rxjs-sharing';
const { TextArea } = Input;


class NotesSaveModel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notesProps: props,
            saveNotesModel: false,
            content: props.content,
            input_type: props.input_type,
            model_type: props.model_type,
            viewNotesModel: false,
            text_list: [],
            view_content: [],
            selectedTextSet: [],
            isDefault: 'N',
            viewNo: 0,
            editNotesModel:false,
            editData:{}
        };
        this.wordFormRef = React.createRef();
        this.textEditFormRef = React.createRef();
        this.handleApplyText = this.handleApplyText.bind(this);
    }

    componentDidMount() {
        this.subscription = doctorNotesService.status().subscribe(async data => {
            await this.setState({ 
                content:data.content,
                model_type:data.model_type,
                input_type:data.input_type
             })
             if (data.model_type == 'view_model') {
                this.viewModel();
              } else {
                this.content();
             }
          })
       
    }

    componentWillUnmount() {
        this.subscription?.unsubscribe();
    }


    content() {
        this.setState({
            saveNotesModel: true
        })
    }

    async viewModel() {
        const { input_type } = this.state;
        let doctor_id = LS_SERVICE.get('staff_id');
        const res = await Axios.get(GET_DOCTOR_NOTES({ doctor_id: doctor_id, input_type: input_type }));
        if (res.data.status == "success") {
            await this.setState({
                text_list: res.data.data,
                viewNotesModel: true
            })
        }
    }


    async saveText() {
        const { content, input_type, isDefault } = this.state;
        const { text_name, text_name_checkbox } = this.wordFormRef.current.getFieldValue();
        let doctor_id = LS_SERVICE.get('staff_id');

        if(content == ''){
            notification.error({
                message: 'Input  Field Cannot Be Empty',
                placement: 'topRight'
            });
            return
        }

        let params = {
            content: content,
            title: text_name,
            doctor_id: doctor_id,
            type: input_type,
            default: isDefault
        }

        await axios.post(SAVE_NOTES, params).then(success => {
            if (success.data) {
                if(success.data.status == false){
                    notification.error({
                        message: success.data.message,
                        placement: 'topRight'
                    });
                }else{
                notification.success({
                    message: 'Notes Stored Successfully',
                    placement: 'topRight'
                });
                this.setState({saveNotesModel:false})
                }
            } else {
                notification.error({
                    message: 'Something Went Wrong',
                    placement: 'topRight'
                });
            }
        });

        this.wordFormRef.current.setFieldsValue({
            text_name : '',
        });

        this.props.resetModel()
    }

    handleModalPopup = (e, popupName) => {
        e.preventDefault();
        const { view_content } = this.state;

        // if (view_content.length > 0) {
        //     this.setState({ view_content: [] })
        //     return
        // }
        if(this.state[popupName] && popupName != 'editNotesModel'){
            this.props.resetNotesModel();
        }        

        this.setState({
            [popupName]: !this.state[popupName]
        }, () => {
            if (popupName == 'saveNotesModel') {
                this.wordFormRef.current.setFieldsValue({
                    text_name : '',
                });

            }              
        })
        if (popupName== 'saveNotesModel'){
            this.props.resetModel()
        }
    }

    handleClearText = (e) => {
        e.preventDefault();
        this.wordFormRef.current.setFieldValue('text_name', '');
    }

    openView = (textdetails) => {
        let viewData = [];
        // viewData = this.state.view_content;
        viewData.push(textdetails);

        this.setState({
            view_content: viewData,
        });
    }

    textViewDetails(e, textdetails) {
        e.preventDefault();
        let no = textdetails.id;
        if (this.state.viewNo === no) {
            this.setState({ viewNo: 0 });
        } else {
            this.setState({ viewNo: no });
            this.openView(textdetails);
        }
    }

    async selectedTextset(e, data, i) {
        const { checked } = e.currentTarget;
        let selectedTextData = this.state.selectedTextSet;
        if (checked == true) {
            data.is_selected = 'Y';
            selectedTextData.push(data);

        } else {
            selectedTextData = selectedTextData.filter((item) => item.id !== data.id);
        }
        await this.setState({ selectedTextSet: selectedTextData });
    }

    handleApplyText() {
        const { selectedTextSet } = this.state;
        this.props.saveTextBoxData(selectedTextSet);
        this.setState({viewNotesModel:false})
    }

    defaultText(e) {
        const { checked } = e.currentTarget;
        let flag = checked == true ? 'Y' : 'N';
        this.setState({
            isDefault: flag
        })
    }

    autoHeight = (e) => {
        if (e) {
          e.target.style.height = "auto";
          e.target.style.height = e.target.scrollHeight + "px";
        }
      }

    async changeDefault(e, val) {
        e.preventDefault()
        const { checked } = e.currentTarget;
        const { text_list } = this.state;
        let updateData = [];
        let previousDefault = text_list.filter(data => data.default == 'Y');
        if (previousDefault.length > 0) {
            previousDefault[0].default = 'N';
            updateData.push(previousDefault[0]);
        }
        if (checked == true) {
            val.default = 'Y';
            updateData.push(val);
        }
        let params = {
            data: updateData,
        }


        await axios.post(UPDATE_DOCTOR_NOTES, params).then(success => {
            if (success.data) {
                this.viewModel();
                notification.success({
                    message: 'Updated Successfully',
                    placement: 'topRight'
                });
            } else {
                notification.error({
                    message: 'Something Went Wrong',
                    placement: 'topRight'
                });
            }
        });
    }

    textView() {
        return (
            <Fragment>
                {this.state.view_content.length > 0 ?
                    <div className="row">
                        <div className="col-12 table-responsive">
                            <table className="table table-default">
                                <thead>
                                    <tr>
                                        {/* <th>TEXT BLOCK NAME</th> */}
                                        {/* <th>CONTENT</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.view_content.length > 0 ?
                                            this.state.view_content.map((Obj, index) => {
                                                return (
                                                    <tr key={index}>
                                                        {/* <td>{Obj.title}</td> */}
                                                        <td style={{whiteSpace: 'pre-line'}}>{Obj.content}</td>
                                                    </tr>

                                                )
                                            }) : <tr><td colSpan={6}>No Content </td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="col-12 text-center mt-4">
                                <button type="button" className="btn btn-primary text-uppercase confirm-copy" disabled={this.state.isConfirm === false ? "true" : ""} onClick={this.handleConfirmPrescription}>CONFIRM</button>
                            </div> */}
                    </div>
                    : null}
            </Fragment>
        )
    }

    async handleEditDelete(e, data, type) {
        let modifiedData = data;
        let params = {};
        if (type == 'delete') {
            params.deleted_id = modifiedData.id;
            await axios.post(TEXT_DELETE, params).then(success => {
                if (success.data) {
                    this.viewModel();
                    notification.success({
                        message: 'Deleted Successfully',
                        placement: 'topRight'
                    });
                } else {
                    notification.error({
                        message: 'Something Went Wrong',
                        placement: 'topRight'
                    });
                }
            });

        } else {
            this.editData(data);

        }
    }

    editData(data){
        let content = data.content;
        let text_name = data.title; 
     // let edit_default =data.default == 'Y' ? true : false
        this.setState({editNotesModel:true , editData : data},
        ()=>{
            this.textEditFormRef.current.setFieldsValue({
                text_name : text_name,
                text_content : content
            });

        })
    }

    async updateText(e){
        const { editData } =this.state;
        const {text_name , text_content} = this.textEditFormRef.current.getFieldValue();
        let paramData = editData;
        paramData.content =  text_content;
        paramData.title = text_name;
        let updateData =[];
        updateData.push(paramData);
        let params = {
            data: updateData,
        }

        await axios.post(UPDATE_DOCTOR_NOTES, params).then(success => {
            if (success.data) {
                this.viewModel();
                notification.success({
                    message: 'Updated Successfully',
                    placement: 'topRight'
                });
            } else {
                notification.error({
                    message: 'Something Went Wrong',
                    placement: 'topRight'
                });
            }
            this.setState({editNotesModel:false})
        });
    }

    handleClearTextEdit(){
        this.textEditFormRef.current.setFieldsValue({
            text_name : '',
            text_content : ''
        });

    }

    render() {
        return (
            <Fragment>
                <Modal
                    title="Create Text Block"
                    visible={this.state.saveNotesModel}
                    onCancel={(e) => this.handleModalPopup(e, 'saveNotesModel')}
                    footer={false}
                    closable={true}
                >
                    <Form
                        ref={this.wordFormRef}
                        className='emrfrm'
                        layout="vertical"
                        onFinish={(e) => this.saveText(e)}
                    >
                        
                        <div className="col-12">
                            <div className="antInputItem hasdata">
                                <Form.Item
                                    name="text_name"
                                    rules={[
                                        { required: true, message: "Please enter words" },
                                        { max: 150 , message: 'Text block name must be less than 150 characters' },
                                    ]}
                                    label="Text Block Name"
                                >
                                    <Input autoFocus={true}
                                        name="text_name"
                                    />
                                </Form.Item>
                            </div>
                          
                        </div>
                        {LS_SERVICE.get('reference_type') != 2 ?
                        <div className='col-12'>
                            <div className="custom-control custom-checkbox custom-control-inline">
                                <input type="checkbox" className="custom-control-input" id="text_name_checkbox" name="text_name_checkbox" onClick={e => this.defaultText(e)} />
                                <label className="custom-control-label  cursor-pointer" htmlFor="text_name_checkbox">
                                    DEFAULT
                                </label>
                            </div>
                        </div>
                        :null}
                        <div className="col-12 text-center mt-3">
                            <button type="button" className="btn btn-outline-secondary px-3  mr-2 text-uppercase" onClick={(e) => this.handleClearText(e)}>CLEAR</button>
                            <button type="Submit" className="btn btn-primary px-3 ml-2 text-uppercase" >CONFIRM</button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    title="Select Text Block"
                    visible={this.state.viewNotesModel}
                    onCancel={(e) => this.handleModalPopup(e, 'viewNotesModel')}
                    footer={false}
                    style={{ top: '10vh' }}
                    width="940px"
                    className="emrfrm"
                >
                    <div className="row">
                        <div className="col-12 table-responsive">
                            <table className="table table-default">
                                <thead>
                                    <tr>
                                        <th style={{ width: "45px" }}>&nbsp;</th>
                                        <th>TEXT BLOCK NAME</th>
                                        <th>VIEW</th>
                                        {LS_SERVICE.get('reference_type') != 2 ? <th>DEFAULT</th> : null}
                                        <th>ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.text_list.length > 0 ?
                                            this.state.text_list.map((Obj, index) => {
                                                return (<Fragment>
                                                    <tr key={index}>
                                                        <td>
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id={"text_" + Obj.id} name="select_prescription"
                                                                    onClick={e => this.selectedTextset(e, Obj, index)}
                                                                />
                                                                <label className="custom-control-label" htmlFor={"visit_" + index}>&nbsp;</label>
                                                            </div>
                                                        </td>
                                                        {/* <td>{moment(Obj.createdAt).format('D MMM, YYYY')}</td> */}
                                                        <td>{Obj.title}</td>
                                                        <td><a href="#" onClick={(e) => this.textViewDetails(e, Obj)}><i className="icon_view"></i></a></td>
                                                        {LS_SERVICE.get('reference_type') != 2 ?
                                                        <td>
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" style={{ position: 'absolute', right: '40px' }} id={"text_" + Obj.id} name="select_prescription"
                                                                    checked={Obj.default == 'Y' ? true : false} onClick={e => this.changeDefault(e, Obj)}
                                                                />
                                                                <label className="custom-control-label" htmlFor={"visit_" + index}>&nbsp;</label>
                                                            </div>
                                                        </td>
                                                        :null}
                                                        <td>
                                                            <a className="nostyle-link mr-2" onClick={e => this.handleEditDelete(e, Obj, 'edit')}><i className="icon-edit"></i></a>
                                                            <a className="nostyle-link mr-2" onClick={(e) => this.handleEditDelete(e, Obj, 'delete')}><i className="icon_delete"></i></a>
                                                        </td>
                                                    </tr>
                                                    <tr className="view_details" style={this.state.viewNo === Obj.id ? { display: 'table-row' } : { display: 'none' }}>
                                                        <td colSpan="6" className="inner_table">
                                                            <div className="text-center">{this.state.view_content.length === 0 ? LOADER_RED : null}</div>
                                                            {this.textView(Obj.id)}
                                                        </td>
                                                    </tr>


                                                </Fragment>)
                                            }) : <tr><td colSpan={6}>No Text </td></tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                        <div className="col-12 text-center mt-4">
                            <button type="button" className="btn btn-primary text-uppercase confirm-copy" disabled={this.state.selectedTextSet?.length  == 0  ? true : false} onClick={this.handleApplyText}>CONFIRM</button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title="Edit Text Block"
                    visible={this.state.editNotesModel}
                    onCancel={(e) => this.handleModalPopup(e, 'editNotesModel')}
                    footer={false}
                    width="940px"
                    closable={true}
                >
                    <Form
                        ref={this.textEditFormRef}
                        className='emrfrm'
                        layout="vertical"
                        onFinish={(e) => this.updateText(e)}
                    >
                        <div className="col-12">
                            <div className="antInputItem hasdata">
                                <Form.Item
                                    name="text_name"
                                    rules={[
                                        { required: true, message: "Please enter words" },
                                        { max: 150 , message: 'Text block name must be less than 150 characters' },
                                    ]}
                                    label="Text Block Name"
                                > 
                                    <Input
                                        autoFocus={true}
                                        name="text_name"
                                    />
                                </Form.Item>
                            </div>
                         </div>
                            <div className="col-12"  style={{marginTop:"31px"}}>
                         <div className="antInputItem hasdata">
                                <Form.Item
                                    name="text_content"
                                    rules={[
                                        { required: true, message: "Please enter words" },
                                    ]}
                                    label="Text Content"
                                >
                                    <TextArea 
                                        // autoFocus={false}
                                        name="text_content"
                                        onInput={(e) => this.autoHeight(e)}
                                        autoSize="true"
                                    />
                                </Form.Item>
                            </div>                         
                        </div>
                        {/* <div className='col-12'>
                            <div className="custom-control custom-checkbox custom-control-inline">
                                <input type="checkbox" className="custom-control-input" id="text_name_checkbox" name="text_name_checkbox" onClick={e => this.defaultText(e)} />
                                <label className="custom-control-label  cursor-pointer" htmlFor="text_name_checkbox">
                                    DEFAULT
                                </label>
                            </div>
                        </div> */}
                        <div className="col-12 text-center mt-3">
                            <button type="button" className="btn btn-outline-secondary px-3  mr-2 text-uppercase" onClick={(e) => this.handleClearTextEdit(e)}>CLEAR</button>
                            <button type="Submit" className="btn btn-primary px-3 ml-2 text-uppercase" >UPDATE</button>
                        </div>
                    </Form>
                </Modal>
            </Fragment>
        )
    }
}


export default NotesSaveModel;