import React from "react";
import { Line, Scatter, Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  DURATION_TYPE_SINGULAR,
  HOMECARE_REFERENCE_TYPE,
  LOADER_RED,
  PDF_CONFIG_VITALS_GRAPH_UPDATE,
  VITAL_ARRAY,
  IS_ENDOCRINOLOGY,
  USERTYPES
} from "../../utils/constant";
import LS_SERVICE from "../../utils/localStorage";
import "react-datepicker/dist/react-datepicker.css";
import Axios from "axios";
import qs from "qs";
import moment from "moment";
import apple from "../../assets/images/lunch.svg";
import Icofont from "react-icofont";
import { notification } from "antd";

class ChartNew extends React.Component {
  constructor(props) {
    super(props);

    const PDF_PRINT_OBJ = {};
    if (props.chartGraphSetting.length > 0) {
      props.chartGraphSetting.split(",").forEach((ppa) => {
        PDF_PRINT_OBJ[ppa] = true;
      });
    }

    this.state = {
      chartData: props.chartData,
      data: props.chartData[DURATION_TYPE_SINGULAR[props.chartsTab]],
      chartType: props.chartsType,
      chartsTab: props.chartsTab,
      dataLoaded: props.dataLoaded,
      reference_id: props.reference_id,
      type: props.type,
      constants: JSON.parse(JSON.stringify(props.constants)),
      noData: false,
      all_print: PDF_PRINT_OBJ,
      chartChecked: props.chartGraphSetting,
      all_print_checked: false,
      bloodSugarChartData: props.bloodSugarChartData,
      vitalsRangeWithId: props.vitalsRangeWithId,
      bloodSugarInsulinChartCheck: false,
      bloodSugarInsulinChartID: "bloodSugarInsulinChartID",
      // graphChecked: props.chartGraphSetting.split(",").map(e => {  }),
      // all_print: (PDF_PRINT_ARRAY.includes('V001') && PDF_PRINT_ARRAY.includes('V002') && PDF_PRINT_ARRAY.includes('V003') && PDF_PRINT_ARRAY.includes('V004') &&
      //     PDF_PRINT_ARRAY.includes('V005') && PDF_PRINT_ARRAY.includes('V007') && PDF_PRINT_ARRAY.includes('V008') && PDF_PRINT_ARRAY.includes('V009') &&
      //     PDF_PRINT_ARRAY.includes('V010') && PDF_PRINT_ARRAY.includes('V011')),
      // V001_print: PDF_PRINT_ARRAY.includes('V001'),
      // V002_print: PDF_PRINT_ARRAY.includes('V002'),
      // V003_print: PDF_PRINT_ARRAY.includes('V003'),
      // V004_print: PDF_PRINT_ARRAY.includes('V004'),
      // V005_print: PDF_PRINT_ARRAY.includes('V005'),
      // V007_print: PDF_PRINT_ARRAY.includes('V007'),
      // V008_print: PDF_PRINT_ARRAY.includes('V008'),
      // V009_print: PDF_PRINT_ARRAY.includes('V009'),
      // V010_print: PDF_PRINT_ARRAY.includes('V010'),
      // V011_print: PDF_PRINT_ARRAY.includes('V011'),
      chartDataMapped: {},
      chartBloodSugarInsulinData: {},
      chartBloodSugarData: {},
      showOnlyTicked: props.showOnlyTicked,
      checkBoxDisable: props.checkBoxDisable,
      showVitalType: props.showVitalType || "",
      vitalsConstant: [],
      zoomGraph: null,
      oneChartDataFound: false,
      chartDataInsulinFound: false,
      finalBloodSugData: [],
      bloodSugarMinTarget: 0,
      bloodSugarMaxTarget: 0,
      glucoseLowRange: {},
      glucoseWithinRange: {},
      glucoseHighRange: {},
      glucoseTotalValObj: {},
      bloodSugarGlucoseChartCheck: false,
      bloodSugarChartID: "bloodSugarGlucoseChart",
      bloodSugarMinTarget: 0,
      bloodSugarMaxTarget: 0,
      dateTimeChart: {},
      bsTimeLabels: [
        "03:00", "03:15", "03:30", "03:45", "04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45", "06:00", "06:15", "06:30", "06:45", "07:00", "07:15", "07:30", "07:45", "08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45", "22:00", "22:15", "22:30", "22:45", "23:00", "23:15", "23:30", "23:45", "00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45", "02:00", "02:15", "02:30", "02:45", "03:00" 
      ],

      chartDayTimeBSTableFormat: false,
      cgmDataAvailable: false,
      isNurseLoggined:props.isNurseLoggined
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      chartData: nextProps.chartData,
      bloodSugarChartData: nextProps.bloodSugarChartData,
      data: nextProps.chartData[DURATION_TYPE_SINGULAR[nextProps.chartsTab]],
      chartType: nextProps.chartsType,
      chartsTab: nextProps.chartsTab,
      dataLoaded: nextProps.dataLoaded,
      showOnlyTicked: nextProps.showOnlyTicked,
      checkBoxDisable: nextProps.checkBoxDisable,
      showVitalType: nextProps.showVitalType,
    };
  }

  componentDidMount() {
    let vitalsConstant = this.state.constants
      ? this.state.showVitalType == "homecare" && this.state.constants.vital_groups["VG003"]
        ? this.state.constants.vital_groups["VG003"].vital_sign
        : this.state.constants.vitals_details
      : [];

    vitalsConstant.sort(function (a, b) {
      return a.id == "V008" 
                  ? -1    // Move it top the list
                  : 0;   // Keep it the same
    });

    vitalsConstant.sort(function (a, b) {
      return a.id == "V005" || a.id == "V006" 
                 ? -1    // Move it top the list
                 : 0;   // Keep it the same
    });

    vitalsConstant.sort(function (a, b) {
      return a.id == "V010" || a.id == "V011" || a.id == "V013" || a.id == "V014"
                 ? -1    // Move it top the list
                 : 0;   // Keep it the same
    });

    this.setState(
      {
        vitalsConstant,
      },
      async () => {
        await this.handleDynamicDataCreation();
        await this.createBloodSugarChart();
        this.handleAllPrintCheckedFn();
      }
    );
  }

  getBloodSugarDataset(dataArr) {
    let bsDataArr = [],
      median = [],
      perc_20 = [],
      perc_80 = [];

    if (dataArr) {
      if (this.state.bsTimeLabels.length > 0) {
        let count = 0;
        for (let time of this.state.bsTimeLabels) {
          let flagMedian = false;
          let flagPerc20 = false;
          let flagPerc80 = false;

          for (let item of dataArr) {
            Object.keys(item).map((key, index) => {
              const dataArr = item[key].data;
              if (dataArr && dataArr.length > 0) {
                for (let dateItem of dataArr) {
                  if (time === key) {
                    bsDataArr.push({ x: time, y: dateItem });
                  }
                }
              }
            });

            if (item[time] && item[time] !== undefined) {
              if (item[time].median) {
                median.push(item[time].median);
                flagMedian = true;
              }
              if (item[time].perc_80) {
                perc_80.push(item[time].perc_80);
                flagPerc80 = true;
              }
              if (item[time].perc_20) {
                perc_20.push(item[time].perc_20);
                flagPerc20 = true;
              }
            }
          }

          if (flagMedian) {
            flagMedian = false;
          } else {
            median.push(null);
          }

          if (flagPerc80) {
            flagMedian = false;
          } else {
            perc_80.push(null);
          }

          if (flagPerc20) {
            flagMedian = false;
          } else {
            perc_20.push(null);
          }
          count++;
        }
      }
    }

    let i = 0,
      j = 0,
      k = 0;
    for (let element of median) {
      if (element === "REMOVE") {
          delete median[i];
      }
      i++;
    }

    for (let element of perc_80) {
      if (element === "REMOVE") {
         delete perc_80[j];
      }
      j++;
    }

    for (let element of perc_20) {
      if (element === "REMOVE") {
        delete perc_20[k];
      }
      k++;
    }


    let glucoseLowRangeObj = { glucoseVal1: [], glucoseVal2: [], glucoseVal3: [], glucoseVal4: [], glucoseVal5: [], totalVal: 0 };
    let glucoseWithinRangeObj = { glucoseVal1: [], glucoseVal2: [], glucoseVal3: [], glucoseVal4: [], glucoseVal5: [], totalVal: 0 };
    let glucoseHighRangeObj = { glucoseVal1: [], glucoseVal2: [], glucoseVal3: [], glucoseVal4: [], glucoseVal5: [], totalVal: 0 };

    let glucoseTotalValObj = { obj1: [], obj2: [], obj3: [], obj4: [], obj5: []};

    let { bloodSugarMaxTarget, bloodSugarMinTarget } = this.state;

    /**
     * Will use it later: for glucose range table
     */
    // for (let item of bsDataArr) {
    //   let time = item.x.split(":");
    //   //console.log("time--------", time);
    //   const hour = +time[0];
    //   if (hour >= 3 && hour <= 8) {
    //     if (item.y > bloodSugarMaxTarget) {
    //       glucoseHighRangeObj.glucoseVal1.push(item.y);          
    //     } else if (item.y >= bloodSugarMinTarget && item.y <= bloodSugarMaxTarget) {
    //       glucoseWithinRangeObj.glucoseVal1.push(item.y);          
    //     } else if (item.y < bloodSugarMinTarget) {
    //       glucoseLowRangeObj.glucoseVal1.push(item.y);         
    //     }        
    //     glucoseTotalValObj.obj1.push(item.y);
    //   } else if (hour >= 8 && hour <= 12) {
    //     if (item.y > bloodSugarMaxTarget) {
    //       glucoseHighRangeObj.glucoseVal2.push(item.y);
    //     } else if (item.y >= bloodSugarMinTarget && item.y <= bloodSugarMaxTarget) {
    //       glucoseWithinRangeObj.glucoseVal2.push(item.y);
    //     } else if (item.y < bloodSugarMinTarget) {
    //       glucoseLowRangeObj.glucoseVal2.push(item.y);
    //     }        
    //     glucoseTotalValObj.obj2.push(item.y);
    //   } else if (hour >= 12 && hour <= 18) {
    //     if (item.y > bloodSugarMaxTarget) {
    //       glucoseHighRangeObj.glucoseVal3.push(item.y);
    //       glucoseHighRangeObj.totalVal = glucoseLowRangeObj.totalVal + item.y;
    //     } else if (item.y >= bloodSugarMinTarget && item.y <= bloodSugarMaxTarget) {
    //       glucoseWithinRangeObj.glucoseVal3.push(item.y);
    //       glucoseWithinRangeObj.totalVal = glucoseLowRangeObj.totalVal + item.y;
    //     } else if (item.y < bloodSugarMinTarget) {
    //       glucoseLowRangeObj.glucoseVal3.push(item.y);
    //       glucoseLowRangeObj.totalVal = glucoseLowRangeObj.totalVal + item.y;
    //     }
    //     glucoseTotalValObj.obj3.push(item.y);
    //   } else if (hour >= 18 && hour <= 22) {
    //     if (item.y > bloodSugarMaxTarget) {
    //       glucoseHighRangeObj.glucoseVal4.push(item.y);
    //       glucoseHighRangeObj.totalVal = glucoseLowRangeObj.totalVal + item.y;
    //     } else if (item.y >= bloodSugarMinTarget && item.y <= bloodSugarMaxTarget) {
    //       glucoseWithinRangeObj.glucoseVal4.push(item.y);
    //       glucoseWithinRangeObj.totalVal = glucoseLowRangeObj.totalVal + item.y;
    //     } else if (item.y < bloodSugarMinTarget) {
    //       glucoseLowRangeObj.glucoseVal4.push(item.y);
    //       glucoseLowRangeObj.totalVal = glucoseLowRangeObj.totalVal + item.y;
    //     }
    //     glucoseTotalValObj.obj4.push(item.y);
    //   } else if ((hour >= 22 && hour <= 23) || (hour >= 0 && hour <= 3)) {
    //     if (item.y > bloodSugarMaxTarget) {
    //       glucoseHighRangeObj.glucoseVal5.push(item.y);
    //     } else if (item.y >= bloodSugarMinTarget && item.y <= bloodSugarMaxTarget) {
    //       glucoseWithinRangeObj.glucoseVal5.push(item.y);
    //     } else if (item.y < bloodSugarMinTarget) {
    //       glucoseLowRangeObj.glucoseVal5.push(item.y);
    //       glucoseLowRangeObj.totalVal = glucoseLowRangeObj.totalVal + item.y;
    //     }
    //     glucoseTotalValObj.obj5.push(item.y);
    //   }
    // }

    let glucoseLowRangeArr = [];
    let glucoseWithinRangeArr = [];
    let glucoseHighRangeArr = [];

    let glucoseTotalValArr = [];

    

    //for range 3 am to am for low, high and within range

     for (let item of bsDataArr) {
      let time = item.x.split(":");
      const hour = +time[0];

      if (item.y > bloodSugarMaxTarget) {
        glucoseHighRangeArr.push(item.y);          
      } else if (item.y >= bloodSugarMinTarget && item.y <= bloodSugarMaxTarget) {
        glucoseWithinRangeArr.push(item.y);          
      } else if (item.y < bloodSugarMinTarget) {
        glucoseLowRangeArr.push(item.y);         
      }
      glucoseTotalValArr.push(item.y);      
    }

    //for old table

    // this.setState({ glucoseLowRange: glucoseLowRangeObj });
    // this.setState({ glucoseWithinRange: glucoseWithinRangeObj });
    // this.setState({ glucoseHighRange: glucoseHighRangeObj });
    // this.setState({ glucoseTotalValObj: glucoseTotalValObj });

    this.setState({ glucoseLowRange: glucoseLowRangeArr });
    this.setState({ glucoseWithinRange: glucoseWithinRangeArr });
    this.setState({ glucoseHighRange: glucoseHighRangeArr });
    this.setState({ glucoseTotalValObj: glucoseTotalValArr });

    // console.log("glucoseLowRangeObj______", glucoseLowRangeArr);
    // console.log("glucoseWithinRangeObj______", glucoseWithinRangeArr);
    // console.log("glucoseHighRangeObj______", glucoseHighRangeArr);
    // console.log("glucoseTotalValObj______", glucoseTotalValArr);

    return {
      bsDataArr: bsDataArr,
      median: median, //__median,
      perc_20: perc_20,
      perc_80: perc_80,
    };
  }

  // h 10, wi = 6, l = 8 

  // 10/26
  // [97] 10

  getGluscosePercentage(valueArr, totalVal) {
    if (valueArr && valueArr.length > 0) {
      // const val = valueArr.reduce((total, currentVal) => {
      //   return total + currentVal;
      // });
      const percentage = (valueArr.length * 100) / totalVal.length;
      return Math.round(percentage);
    }
  }

  getGlucosePercentageStyle(glucoseData, type = "", totalVal) {
    var glucosePercentage;
    if (glucoseData && glucoseData.length > 0) {
      glucosePercentage = this.getGluscosePercentage(glucoseData, totalVal);
    }
    else{
      glucosePercentage = 0;
    }
      //low range
      if (type === "low") {
        if (glucosePercentage === 0) {
          return (
            <span className="text-dark legend_code low">
              <span className="text-align-center">0%</span>
            </span>
          );
        } else if (glucosePercentage > 0) {
          return (
            <span className="text-dark legend_code high">
              <span className="text-align-center">{glucosePercentage}%</span>
            </span>
          );
        }
        //within_target range
      } else if (type === "within_target") {
        if (glucosePercentage >= 50) {
          return (
            <span className="text-dark legend_code low">
              <span className="text-align-center">{glucosePercentage}%</span>
            </span>
          );
        } else if (glucosePercentage >= 20 && glucosePercentage < 50) {
          return (
            <span className="text-dark legend_code moderate">
              <span className="text-align-center">{glucosePercentage}%</span>
            </span>
          );
        } else if (glucosePercentage == 0) {
          return (
            <span className="text-dark legend_code high">
              <span className="text-align-center">0%</span>
            </span>
          );
        } else if (glucosePercentage < 20) {
          return (
            <span className="text-dark legend_code high">
              <span className="text-align-center">{glucosePercentage}%</span>
            </span>
          );
        }
        //high range
      } else if (type === "high") {
        if (glucosePercentage >= 50) {
          return (
            <span className="text-dark legend_code high">
              <span className="text-align-center">{glucosePercentage}%</span>
            </span>
          );
        } else if (glucosePercentage >= 20 && glucosePercentage < 50) {
          return (
            <span className="text-dark legend_code moderate">
              <span className="text-align-center">{glucosePercentage}%</span>
            </span>
          );
        } else if (glucosePercentage == 0) {
          return (
            <span className="text-dark legend_code low">
              <span className="text-align-center">0%</span>
            </span>
          );
        } else if (glucosePercentage < 20) {
          return (
            <span className="text-dark legend_code low">
              <span className="text-align-center">{glucosePercentage}%</span>
            </span>
          );
        }
        
      }
  }
  //Added for zoom graph
  zoomChart = (e) => {
    e.preventDefault();
    const { zoomClass } = this.state;
    this.setState({
      zoomClass: !this.state.zoomClass,
    });
  };

  getGlucosePercentageHtml() {
    return (
      <>
        {/* <div className="row mt-0 mb-0">
          <div className="col-12 table">
            <table className="table icons ">
              <tbody className="">
                <tr>
                  <th style={{ width: 22 + "%" }}></th>
                  <th style={{ width: 18 + "%" }}>
                    {" "}
                    <img src={apple} alt="apple" />
                  </th>
                  <th style={{ width: 24 + "%" }}>
                    {" "}
                    <img src={apple} alt="apple" />
                  </th>
                  <th style={{ width: 19 + "%" }}>
                    {" "}
                    <img src={apple} alt="apple" />
                  </th>
                  <th style={{ width: 18 + "%" }}>
                    {" "}
                    <i class="icofont-bed graph-font pr-1"></i>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}
        {/* <div className="row mt-0">
          <div className="col-12 table table-responsive">
            <table className="table legend ">
              <tbody className="insullin_legend_new">
                <tr>
                  <th style={{ width: 7 + "%" }}>Low Glucose</th>
                  <td style={{ width: 17 + "%" }} className="text-center">                  
                    {this.getGlucosePercentageStyle(this.state.glucoseLowRange.glucoseVal1, "low", this.state.glucoseTotalValObj.obj1)}
                  </td>
                  <td style={{ width: 17 + "%" }} className="text-center">
                    {this.getGlucosePercentageStyle(this.state.glucoseLowRange.glucoseVal2, "low", this.state.glucoseTotalValObj.obj2)}
                  </td>
                  <td style={{ width: 23 + "%" }} className="text-center">
                    {this.getGlucosePercentageStyle(this.state.glucoseLowRange.glucoseVal3, "low", this.state.glucoseTotalValObj.obj3)}
                  </td>
                  <td style={{ width: 18 + "%" }} className="text-center">
                    {this.getGlucosePercentageStyle(this.state.glucoseLowRange.glucoseVal4, "low", this.state.glucoseTotalValObj.obj4)}
                  </td>
                  <td style={{ width: 20 + "%" }} className="text-center">
                    {this.getGlucosePercentageStyle(this.state.glucoseLowRange.glucoseVal5, "low", this.state.glucoseTotalValObj.obj5)}
                  </td>
                </tr>

                <tr>
                  <th>Within Target Range</th>
                  <td style={{ width: 14 + "%" }} className="text-center">
                    {this.getGlucosePercentageStyle(this.state.glucoseWithinRange.glucoseVal1, "within_target", this.state.glucoseTotalValObj.obj1)}
                  </td>
                  <td className="text-center">
                    {this.getGlucosePercentageStyle(this.state.glucoseWithinRange.glucoseVal2, "within_target", this.state.glucoseTotalValObj.obj2)}
                  </td>
                  <td className="text-center">
                    {this.getGlucosePercentageStyle(this.state.glucoseWithinRange.glucoseVal3, "within_target", this.state.glucoseTotalValObj.obj3)}
                  </td>
                  <td className="text-center">
                    {this.getGlucosePercentageStyle(this.state.glucoseWithinRange.glucoseVal4, "within_target", this.state.glucoseTotalValObj.obj4)}
                  </td>
                  <td className="text-center">
                    {this.getGlucosePercentageStyle(this.state.glucoseWithinRange.glucoseVal5, "within_target", this.state.glucoseTotalValObj.obj5)}
                  </td>
                </tr>

                <tr>
                  <th>High Glucose</th>
                  <td style={{ width: 14 + "%" }} className="text-center">
                    {this.getGlucosePercentageStyle(this.state.glucoseHighRange.glucoseVal1, "high", this.state.glucoseTotalValObj.obj1)}
                  </td>
                  <td className="text-center">{this.getGlucosePercentageStyle(this.state.glucoseHighRange.glucoseVal2, "high", this.state.glucoseTotalValObj.obj2)}</td>
                  <td className="text-center">{this.getGlucosePercentageStyle(this.state.glucoseHighRange.glucoseVal3, "high", this.state.glucoseTotalValObj.obj3)}</td>
                  <td className="text-center">{this.getGlucosePercentageStyle(this.state.glucoseHighRange.glucoseVal4, "high", this.state.glucoseTotalValObj.obj4)}</td>
                  <td className="text-center">{this.getGlucosePercentageStyle(this.state.glucoseHighRange.glucoseVal5, "high", this.state.glucoseTotalValObj.obj5)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}
        <div className="row" style={{margin: "0px 9px 0px 30px"}}>
          <div className="col-12 table table-responsive" style={{marginBottom: "0px"}}>
            <table className="table legend blood__sugarChart" style={{marginBottom: "0px", marginTop: 0 + "px"}}>
              <tbody className="insullin_legend_new">
                <tr>
                <th style={{ width: 33.33 + "%" }}><span className="glucose">Low Glucose</span> <span className="ml-4">{this.getGlucosePercentageStyle(this.state.glucoseLowRange, "low", this.state.glucoseTotalValObj)}</span></th>
                                    
                <th style={{ width: 33.33 + "%" }}><span className="glucose">Within Target</span> <span className="ml-4">{this.getGlucosePercentageStyle(this.state.glucoseWithinRange, "within_target", this.state.glucoseTotalValObj)}</span></th>
                                   
                <th style={{ width: 33.33 + "%" }}><span className="glucose">High Glucose</span> <span className="ml-4">{this.getGlucosePercentageStyle(this.state.glucoseHighRange, "high", this.state.glucoseTotalValObj)}</span></th>
                                                      
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row insullin_legend_new chartCanvas2 control">
          <div className="col-auto  pr-5 pl-0">
            <span className="legend_code_bottom low text-center"></span>
            <span className="font good">Good Control</span>
          </div>
          <div className="col-auto  pr-5">
            <span className="legend_code_bottom moderate"></span>
            <span className="font moderates">Moderate Control</span>
          </div>
          <div className="col-auto  pr-5">
            <span className="legend_code_bottom high"></span><span className="font attention">Needs Attention</span>
          </div>
          {/* <div className="col-auto pr-5">
            <span className="middle-align">
              <img src={apple} alt="apple" />
            </span>
            <span> MEAL</span>
          </div>
          <div className="col-auto pr-5">
            <span className="middle-align">
              <i class="icofont-bed graph-font pr-1"></i>
            </span>
            <span>BEDTIME</span>
          </div>          */}
        
          <div className="col-12 mt-1 pl-0 ">
            <ul className="legend_unit_label text-left left-pad">
              <li>
                Target Range (mg/dL) : <span className="target-blue"></span>High {this.state.bloodSugarMaxTarget} <span className="target-eighty"></span>Low {this.state.bloodSugarMinTarget}
              </li>
            </ul>
            {/* <span className="font range">Target Range: {this.state.bloodSugarMinTarget}-{this.state.bloodSugarMaxTarget} mg/dl</span> */}
          </div>
          {/* <div className="col-auto pr-5">
            <span className="target-blue">&nbsp;&nbsp;</span>Target Range Min: {this.state.bloodSugarMinTarget} mg/dL
            <span className="target-eighty"></span>Target Range Max: {this.state.bloodSugarMaxTarget} mg/dL
          </div> */}
        </div>

      </>);
  }

  updatedBloodSugarChart(dataSet) {
    var bsData = [];
    var valueArray = [];
    if (dataSet) {
      valueArray = Object.values(dataSet);
    }

    valueArray.map((value, i) => {
      if (value !== "" && value != "NaN") {
        if (DURATION_TYPE_SINGULAR[this.state.chartsTab] == "day") {
          bsData = this.getBloodSugarDataset(dataSet.day);
        } else if (DURATION_TYPE_SINGULAR[this.state.chartsTab] == "week") {
          bsData = this.getBloodSugarDataset(dataSet.week);
        } else if (DURATION_TYPE_SINGULAR[this.state.chartsTab] == "month") {
          bsData = this.getBloodSugarDataset(dataSet.month);
        } else {
          bsData = this.getBloodSugarDataset(dataSet.year);
        }
      }
    });
    return bsData;
  }

  createbloodSugarRange(value, range) {
    let rangeArr = [];
    if (range > 0) {
      for (let i = 0; i < range; i++) {
        rangeArr.push(value);
      }
    }
    return rangeArr;
  }

  getVitalRangeFromVitalsConstants(vitalsRange, vital) {
    let range;
    if (vitalsRange && Object.keys(vitalsRange).length > 0) {
      for (let [key, value] of Object.entries(vitalsRange)) {
        if (key === vital) {
          range = value.normal;
          break;
        }
      }
    }
    return range;
  }

  async createBloodSugarChart() {
    const { bloodSugarChartData } = this.state;
    const vitalRange = this.getVitalRangeFromVitalsConstants(this.state.vitalsRangeWithId, "V011");

    let bloodSugarMinTarget = bloodSugarChartData?.bloodSugarRange[0]?.target_blood_sugar_min;
    let bloodSugarMaxTarget = bloodSugarChartData?.bloodSugarRange[0]?.target_blood_sugar_max;

    if (!bloodSugarMinTarget || !bloodSugarMaxTarget) {
      bloodSugarMinTarget = vitalRange?.min;
      bloodSugarMaxTarget = vitalRange?.max;
    }

    const bsMinTargetArr = this.createbloodSugarRange(bloodSugarMinTarget, 97);
    const bsMaxTargetArr = this.createbloodSugarRange(bloodSugarMaxTarget, 97);

    //blood sugar target range
    let chartData = {};

    this.setState({
      bloodSugarMinTarget: bloodSugarMinTarget,
      bloodSugarMaxTarget: bloodSugarMaxTarget
    }, () => {
      chartData = this.updatedBloodSugarChart(bloodSugarChartData);
      if(chartData.bsDataArr.length > 0 && ((LS_SERVICE.get('user_type') == USERTYPES.nurse) || (LS_SERVICE.get('speciality_id') && IS_ENDOCRINOLOGY.indexOf(LS_SERVICE.get('speciality_id')) != -1))){
        //set style format
        this.setState({ chartDayTimeBSTableFormat: true, cgmDataAvailable: true });
        const perc_80 = chartData.perc_80,
          perc_20 = chartData.perc_20,
          bsData = chartData.bsDataArr,
          median = chartData.median;

        let dateTimeChart = {
          labels: this.state.bsTimeLabels,
          labelsMin: this.state.bsTimeLabels,
          labelsMax: this.state.bsTimeLabels,

          datasets: [
            {
              label: "Median",
              //new option, type will default to bar as that what is used to create the scale
              type: "line",
              strokeColor: "rgba(220,220,220,1)",
              pointColor: "rgba(220,220,221,1)",
              pointStrokeColor: "#fff",
              pointHighlightFill: "#fff",
              pointHighlightStroke: "rgba(220,220,220,1)",
              fill: false,
              borderColor: "rgb(50, 111, 156)",
              fillBetweenSet: 1,
              pointRadius: 0,
              fillBetweenColor: "rgb(159, 197, 224,0.2)",
              data: median,
            },
            {
              label: "80 percentile",
              type: "line",
              //fillColor: "rgba(220,220,220,0.2)",
              strokeColor: "rgba(220,220,220,0)",
              pointColor: "rgba(220,220,221,1)",
              pointStrokeColor: "#fff",
              pointHighlightFill: "#fff",
              pointHighlightStroke: "rgba(220,220,220,1)",
              fill: "+1",
              borderColor: "rgba(0, 0, 179,0)",
              fillBetweenSet: 1,
              fillBetweenColor: "rgb(159, 197, 224,0.2)",
              pointRadius: 0,
              data: perc_80,
            },

            {
              label: "20 percentile",
              type: "line",
              // fillColor: "rgba(220,220,220,0.2)",
              strokeColor: "rgba(220,220,220,1)",
              pointColor: "rgba(220,220,221,1)",
              pointStrokeColor: "#fff",
              pointHighlightFill: "#fff",
              pointHighlightStroke: "rgba(220,220,220,1)",
              fill: false,
              borderColor: "rgba(0, 0, 179,0)",
              fillBetweenSet: 1,
              fillBetweenColor: "rgb(204, 230, 255,0.2)",
              pointRadius: 0,
              data: perc_20,
            },
            {
              label: "Max Target Range", //max target
              type: "line",
              strokeColor: "rgba(220,220,220,1)",
              pointColor: "rgba(220,220,221,1)",
              pointStrokeColor: "#fff",
              pointHighlightFill: "#fff",
              pointHighlightStroke: "rgba(220,220,220,1)",
              fill: false,
              borderColor: "rgba(121, 160, 190,1)",
              borderWidth: 2,
              fillBetweenSet: 1,
              fillBetweenColor: "rgb(204, 230, 255,0.2)",
              data: bsMaxTargetArr,
              pointRadius: 0,
              lineThickness: 4,
            },
            {
              label: "Min Target Range", //min target
              type: "line",
              strokeColor: "rgba(220,220,220,1)",
              pointColor: "rgba(220,220,221,1)",
              pointStrokeColor: "#fff",
              pointHighlightFill: "#fff",
              pointHighlightStroke: "rgba(220,220,220,1)",
              fill: false,
              borderColor: "rgba(182, 182, 182,1)",
              borderWidth: 2,
              fillBetweenSet: 1,
              fillBetweenColor: "rgb(204, 230, 255,0.2)",
              data: bsMinTargetArr,
              pointRadius: 0,
            },

            {
              label: "Blood Sugar",
              type: "scatter",
              fillColor: "rgba(220,20,220,0.2)",
              strokeColor: "rgba(220,20,220,1)",
              pointColor: "rgba(220,20,220,1)",
              pointStrokeColor: "#fff",
              pointHighlightFill: "#fff",
              pointHighlightStroke: "rgba(220,220,220,1)",
              data: bsData,
              pointRadius: 2,
            },
          ],
          
        };
        this.setState({ dateTimeChart });
      } else {
        this.setState({ cgmDataAvailable: false });
      }
    });
  }

  updatedVitalGraphData(dataSet) {
    var finalLabel = [];
    var dataLabel = [];
    var rawLabel = [];
    var finalData = [];
    var diffArr = [];
    var totalDiff = 0;
    var count = 0;
    var valueArray = [];
    var labelArray = [];

    if (dataSet) {
      valueArray = Object.values(dataSet);
      labelArray = Object.keys(dataSet);
    }

    valueArray.map((value, i) => {
      if (value !== "" && value != "NaN") {
        if (DURATION_TYPE_SINGULAR[this.state.chartsTab] == "day") finalLabel.push(moment(labelArray[i], "YYYY-MM-DD HH:mm:ss").format("h:mm A"));
        else finalLabel.push(moment(labelArray[i], "YYYY-MM-DD HH:mm:ss").format("D MMM, YYYY"));
        dataLabel.push(moment(labelArray[i], "YYYY-MM-DD HH:mm:ss").format("D MMM, YYYY | h:mm A"));
        rawLabel.push(labelArray[i]);
        finalData.push(value);
      }
    });
    finalData.map((value, i) => {
      if (i < finalData.length - 1) {
        var diff = parseFloat(finalData[i + 1]) - parseFloat(value);
        totalDiff += Math.abs(diff);
        diffArr.push(Math.abs(diff));
      }
    });
    diffArr = diffArr.filter((e) => e !== 0);
    diffArr = diffArr.sort(function (a, b) {
      return a - b;
    });
    let minY = 0;
    let maxY = 0;
    let stepY = 1;
    if (finalData.length > 0 && diffArr.length > 0) {
      let dataArray = [...finalData];
      let sortedData = dataArray.sort(function (a, b) {
        return a - b;
      });
      // let minCorrection = parseFloat(diffArr[0])>20?20:parseFloat(diffArr[0]);
      // let maxCorrection = parseFloat(diffArr[diffArr.length - 1])>20?20:parseFloat(diffArr[diffArr.length - 1]);

      stepY = totalDiff / diffArr.length;
      stepY = Math.round(stepY);
      stepY = stepY > 50 ? 50 : stepY;
      minY = parseFloat(sortedData[0]) - stepY;
      maxY = parseFloat(sortedData[sortedData.length - 1]) + stepY;
    }
    // minY = minY==0?parseFloat(finalData[0])-stepY:minY;
    minY = minY < 0 ? 0 : minY;
    if (diffArr.length == 0) minY = minY == 0 ? parseFloat(finalData[0]) - stepY : minY;
    maxY = maxY == 0 ? parseFloat(finalData[finalData.length - 1]) + stepY : maxY;

    return { label: finalLabel, data: finalData, rawLabel: rawLabel, dataLabel: dataLabel, minY: minY, maxY: maxY, stepY: stepY };
  }

  handleDynamicDataCreation = async (_) => {
    let { data, chartDataMapped, chartBloodSugarData, constants, chartBloodSugarInsulinData } = this.state;
    var vitals = constants.vitals_details;

    let one_chart_data_found = false;

    if (data) {
      vitals.map((vitalDetails, index) => {
        var dataSet = data[vitalDetails.id];
        var vital = vitalDetails.id;
        if (vital !== "V005" && vital !== "V006" && vital !== "V017") {
          /*const tempData = {
              labels: [],
              data: []
          };*/
          var chartData = this.updatedVitalGraphData(dataSet);
          const chartcDotsArr = this.createPointsWithDifferentColors(vital, chartData.data);

          if (chartData.data && chartData.data.length > 0) {
            one_chart_data_found = true;
          }

          chartDataMapped[vital] = {
            ["data" + vital]: {
              labels: chartData.label,
              datasets: [
                {
                  label: "",
                  backgroundColor: "rgb(102 102 102)",
                  borderColor: "rgb(102 102 102)",
                  pointBackgroundColor: chartcDotsArr,
                  pointBorderColor: chartcDotsArr,
                  borderWidth: 1,
                  fill: false,
                  spanGaps: true,
                  data: chartData.data,
                },
              ],
            },
            ["options" + vital]: {
              animation: {
                duration: 0
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  font: {
                    size: 0,
                  },
                },
              },
              title: {
                display: false,
                text: vitalDetails.display_name,
                fontSize: 15,
              },
              elements: {
                line: {
                  tension: 0,
                },
              },
              legend: {
                position: "bottom",
                display: false,
                labels: {
                  fontSize: 11,
                  boxWidth: 6,
                  //fontColor: '#333333',
                  // usePointStyle: true,
                },
              },

              scales: {
                x: {
                  display: true,
                  /* scaleLabel: {
                    display: false,
                    labelString: '',
                  }, */
                  ticks: {
                    font: {
                      size: 11
                    }
                  }
                },
                y: {
                  position: "left",
                  display: true,
                  /* scaleLabel: {
                    display: false,
                    labelString: "",
                  }, */
                  suggestedMin: chartData.minY,
                  suggestedMax: chartData.maxY,
                  ticks: {
                    font: {
                      size: 11
                    },
                    precision: vitalDetails.decimal_value,
                    stepSize: chartData.stepY,
                  },
                  /* afterBuildTicks: function (axis, ticks) {
                    const totalTicks = 10;
                    let density = axis.options.ticks.stepSize;
                    var firstTick = ticks[ticks.length - 1];
                    // var lastTick = ticks[0];
                    var tempA = axis.chart.config.data.datasets[0].data;
                    var dataset = [...tempA];
                    dataset = dataset.sort(function (a, b) {
                      return a - b;
                    });
                    var lastTick = dataset[dataset.length - 1];
                    var tickArr = [];
                    var tempHighest = parseFloat(firstTick) + totalTicks * density;
                    if (lastTick > tempHighest) {
                      density = (lastTick - firstTick) / 10;
                      density = Math.ceil(density);
                    }
                    for (let i = firstTick; i <= lastTick && tickArr.length < totalTicks; i = i + density) {
                      tickArr.push(i);
                    }
                    lastTick = tickArr[tickArr.length - 1] + density;
                    tickArr.push(lastTick);
                    axis.options.ticks.max = lastTick;
                    return tickArr;
                  } */
                },
              },
            },
          };
        } else if (vital === "V005") {
          var chartData = this.updatedVitalGraphData(dataSet);
          var chartData2 = this.updatedVitalGraphData(data["V006"]);

          if ((chartData.data && chartData.data.length > 0) || (chartData2.data && chartData2.data.length > 0)) {
            one_chart_data_found = true;
          }
          var labels = [...chartData.rawLabel, ...chartData2.rawLabel];
          labels = [...new Set(labels)].sort();
          var dataSystolic = [];
          var dataDiastolic = [];

          labels.map((item) => {
            let indexSystolic = chartData2.rawLabel.indexOf(item);
            dataSystolic.push(indexSystolic == -1 ? null : chartData2.data[indexSystolic]);

            let indexDiastolic = chartData.rawLabel.indexOf(item);
            dataDiastolic.push(indexDiastolic == -1 ? null : chartData.data[indexDiastolic]);
          });

          const systolicDotsArr = this.createPointsWithDifferentColors("V006", dataSystolic);
          const diastolicDotsArr = this.createPointsWithDifferentColors("V005", dataDiastolic);

          let minY = chartData.minY < chartData2.minY ? chartData.minY : chartData2.minY;
          let maxY = chartData.maxY > chartData2.maxY ? chartData.maxY : chartData2.maxY;
          let stepY = chartData.stepY > chartData2.stepY ? chartData.stepY : chartData2.stepY;
          var dataLabel = [];
          labels.map((label, index) => {
            dataLabel.push(moment(label, "YYYY-MM-DD HH:mm:ss").format("D MMM, YYYY | h:mm A"));
            if (DURATION_TYPE_SINGULAR[this.state.chartsTab] == "day") labels[index] = moment(label, "YYYY-MM-DD HH:mm:ss").format("h:mm A");
            else labels[index] = moment(label, "YYYY-MM-DD HH:mm:ss").format("D MMM, YYYY");
          });
          chartDataMapped[vital] = {
            ["data" + vital]: {
              labels: labels,
              datasets: [
                {
                  label: " ",
                  backgroundColor: "rgb(102 102 102)",
                  borderColor: "rgb(102 102 102)",
                  pointBackgroundColor: systolicDotsArr,
                  pointBorderColor: systolicDotsArr,
                  borderWidth: 1,
                  fill: false,
                  spanGaps: true,
                  data: dataSystolic,
                  labelT: "Systolic",
                },
                {
                  label: "",
                  backgroundColor: "rgb(90 201 239)",
                  borderColor: "rgb(90 201 239)",
                  pointBackgroundColor: diastolicDotsArr,
                  pointBorderColor: diastolicDotsArr,
                  borderWidth: 1,
                  fill: false,
                  spanGaps: true,
                  data: dataDiastolic,
                  labelT: "Diastolic",
                },
              ],
            },
            ["options" + vital]: {
              animation: {
                duration: 0
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  font: {
                    size: 0,
                  },
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      let label = context.dataset.labelT + ": " + context.raw;
                      return label;
                    }
                  }
                }
              },
              title: {
                display: false,
                text: "Blood Pressure (mmHg)",
                fontSize: 15,
              },
              elements: {
                line: {
                  tension: 0,
                },
                point: {
                  radius: 3,
                },
              },
              layout: {
                padding: {
                  left: 0,
                  right: 0,
                  top: 10,
                  bottom: 0,
                },
              },
              legend: {
                position: "bottom",
                align: "center",
                display: true,
                labels: {
                  boxWidth: 0,

                  // fontColor: '#333333',
                  fontSize: 0,
                  // usePointStyle: true,
                },
              },
              scales: {
                x: {
                  display: true,
                  /* scaleLabel: {
                    display: false,
                    labelString: '',
                  }, */
                  ticks: {
                    font: {
                      size: 11
                    }
                  }
                },
                y: {
                  position: "left",
                  display: true,
                  /* scaleLabel: {
                    display: true,
                    labelString: "",
                  }, */
                  suggestedMin: minY,
                  suggestedMax: maxY,
                  reverse: false,
                  ticks: {
                    font: {
                      size: 11
                    },
                    precision: vitalDetails.decimal_value,
                    stepSize: stepY,
                  },
                  /* afterBuildTicks: function (axis, ticks) {
                    const totalTicks = 10;
                    let density = axis.options.ticks.stepSize;
                    var firstTick = ticks[ticks.length - 1];
                    // var lastTick = ticks[0];
                    var tempA = axis.chart.config.data.datasets[0].data;
                    var dataset = [...tempA];
                    dataset = dataset.sort(function (a, b) {
                      return a - b;
                    });
                    var tempB = axis.chart.config.data.datasets[1].data;
                    var dataset2 = [...tempB];
                    dataset2 = dataset2.sort(function (a, b) {
                      return a - b;
                    });
                    var lastTick =
                      parseFloat(dataset[dataset.length - 1]) > parseFloat(dataset2[dataset2.length - 1])
                        ? dataset[dataset.length - 1]
                        : dataset2[dataset2.length - 1];
                    var tickArr = [];
                    var tempHighest = parseFloat(firstTick) + totalTicks * density;
                    if (lastTick > tempHighest) {
                      density = (lastTick - firstTick) / 10;
                      density = Math.ceil(density);
                    }

                    for (let i = firstTick; i < lastTick && tickArr.length < totalTicks; i = i + density) {
                      tickArr.push(i);
                    }
                    lastTick = tickArr[tickArr.length - 1] + density;
                    tickArr.push(lastTick);
                    axis.options.ticks.max = lastTick;
                    return tickArr;
                  } */
                },
              },
            },
          };
        } else if (vital === "V017") {
          var dataSet = data[vitalDetails.id];
          var vital = vitalDetails.id;

          var chartData = this.updatedVitalGraphData(dataSet);
          const chartcDotsArr = this.createPointsWithDifferentColors(vital, chartData.data);

          chartDataMapped[vital] = {
            ["data" + vital]: {
              labels: chartData.label,
              datasets: [
                {
                  label: "",
                  backgroundColor: "rgb(102 102 102)",
                  borderColor: "rgb(102 102 102)",
                  pointBackgroundColor: chartcDotsArr,
                  pointBorderColor: chartcDotsArr,
                  borderWidth: 1,
                  fill: false,
                  spanGaps: true,
                  data: chartData.data,
                },
              ],
            },
            ["options" + vital]: {
              animation: {
                duration: 0
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  font: {
                    size: 0,
                  },
                },
              },
              title: {
                display: false,
                text: vitalDetails.display_name,
                fontSize: 15,
              },
              elements: {
                line: {
                  tension: 0,
                },
              },
              legend: {
                position: "bottom",
                display: false,
                labels: {
                  fontSize: 11,
                  boxWidth: 6,
                  //fontColor: '#333333',
                  // usePointStyle: true,
                },
              },
              scales: {
                xAxes: [
                  {
                    display: true,
                    scaleLabel: {
                      display: false,
                      labelString: "",
                    },
                    ticks: {
                      fontSize: 11,
                    },
                  },
                ],
                yAxes: {
                  position: "left",
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: "",
                  },
                  ticks: {
                    beginAtZero: true,
                    fontSize: 11,
                    precision: vitalDetails.decimal_value,
                  },
                },
              },
            },
          };
        }
      });

      vitals.map((vitalDetails, index) => {
        let vital = vitalDetails.id;
        if (vital === 'V010') {

          const chartDataBSFasting = this.updatedVitalGraphData(data['V010']);
          // const fastingDotsArr = this.createPointsWithDifferentColors('V010', chartDataBSFasting.data);

          const chartDataBSPPrandial = this.updatedVitalGraphData(data["V011"]);
          // const ppDotsArr = this.createPointsWithDifferentColors('V011', chartDataBSPPrandial.data);

          const chartDataBSRandom = this.updatedVitalGraphData(data["V013"]);
          // const randomDotsArr = this.createPointsWithDifferentColors('V013', chartDataBSRandom.data);

          const chartDataInsulin = this.updatedVitalGraphData(data["V014"]);
          // const insulinDotsArr = this.createPointsWithDifferentColors('V014', chartDataInsulin.data);

          if (
            (chartDataBSFasting.data && chartDataBSFasting.data.length > 0) ||
            (chartDataBSPPrandial.data && chartDataBSPPrandial.data.length > 0) ||
            (chartDataBSRandom.data && chartDataBSRandom.data.length > 0)
          ) {
            one_chart_data_found = true;
          }

          /*const bloodSugarLabels = ([...chartDataBSFasting.label, ...chartDataBSPPrandial.label, ...chartDataBSRandom.label, ...chartDataInsulin.label]).filter((value, index, arr) => {
            return arr.indexOf(value) === index;
          });*/

          let minY = Math.min(
            isNaN(chartDataBSFasting.minY) ? Infinity : chartDataBSFasting.minY,
            isNaN(chartDataBSPPrandial.minY) ? Infinity : chartDataBSPPrandial.minY,
            isNaN(chartDataBSRandom.minY) ? Infinity : chartDataBSRandom.minY /* , isNaN(chartDataInsulin.minY)?Infinity:chartDataInsulin.minY */
          );

          let maxY = Math.max(
            isNaN(chartDataBSFasting.maxY) ? -Infinity : chartDataBSFasting.maxY,
            isNaN(chartDataBSPPrandial.maxY) ? -Infinity : chartDataBSPPrandial.maxY,
            isNaN(chartDataBSRandom.maxY) ? -Infinity : chartDataBSRandom.maxY /* , isNaN(chartDataInsulin.maxY)?-Infinity:chartDataInsulin.maxY */
          );

          let stepY = Math.max(chartDataBSFasting.stepY, chartDataBSPPrandial.stepY, chartDataBSRandom.stepY /* , chartDataInsulin.stepY */);

          var bloodSugarLabels = [
            ...chartDataBSFasting.rawLabel,
            ...chartDataBSPPrandial.rawLabel,
            ...chartDataBSRandom.rawLabel /* , ...chartDataInsulin.rawLabel */,
          ];
          bloodSugarLabels = [...new Set(bloodSugarLabels)].sort();

          var dataBSFasting = [];
          var dataBSPPrandial = [];
          var dataBSRandom = [];
          var dataInsulin = [];

          var dataBSFastingLabel = [];
          var dataBSPPrandialLabel = [];
          var dataBSRandomLabel = [];
          var dataInsulinLabel = [];

          bloodSugarLabels.map((item) => {
            let indexFasting = chartDataBSFasting.rawLabel.indexOf(item);
            dataBSFasting.push(indexFasting == -1 ? null : chartDataBSFasting.data[indexFasting]);
            dataBSFastingLabel.push(indexFasting == -1 ? null : item);

            let indexPP = chartDataBSPPrandial.rawLabel.indexOf(item);
            dataBSPPrandial.push(indexPP == -1 ? null : chartDataBSPPrandial.data[indexPP]);
            dataBSPPrandialLabel.push(indexPP == -1 ? null : item);

            let indexRandom = chartDataBSRandom.rawLabel.indexOf(item);
            dataBSRandom.push(indexRandom == -1 ? null : chartDataBSRandom.data[indexRandom]);
            dataBSRandomLabel.push(indexRandom == -1 ? null : item);

            let indexInsulin = chartDataInsulin.rawLabel.indexOf(item);
            dataInsulin.push(indexInsulin == -1 ? null : chartDataInsulin.data[indexInsulin]);
            dataInsulinLabel.push(indexInsulin == -1 ? null : item);
          });

          let fastingDotsArr = this.createPointsWithDifferentColors("V010", dataBSFasting);
          let ppDotsArr = this.createPointsWithDifferentColors("V011", dataBSPPrandial);
          let randomDotsArr = this.createPointsWithDifferentColors("V013", dataBSRandom);
          //let insulinDotsArr = this.createPointsWithDifferentColors('V014', dataInsulin);

          let fastingDotsArrBackground = [];
          let ppDotsArrBackground = [];
          let randomDotsArrBackground = [];

          dataBSFasting.forEach((item, key) => {
            if (item != null) {
              let label = dataBSFastingLabel[key];
              let insulinRecorded = dataInsulinLabel.indexOf(label);
              if (insulinRecorded == -1) {
                fastingDotsArrBackground.push(fastingDotsArr[key]);
              } else {
                fastingDotsArrBackground.push("#fff");
              }
            } else {
              fastingDotsArrBackground.push(fastingDotsArr[key]);
            }
          });
          dataBSPPrandial.forEach((item, key) => {
            if (item != null) {
              let label = dataBSPPrandialLabel[key];
              let insulinRecorded = dataInsulinLabel.indexOf(label);
              if (insulinRecorded == -1) {
                ppDotsArrBackground.push(ppDotsArr[key]);
              } else {
                ppDotsArrBackground.push("#fff");
              }
            } else {
              ppDotsArrBackground.push(ppDotsArr[key]);
            }
          });

          dataBSRandom.forEach((item, key) => {
            if (item != null) {
              let label = dataBSRandomLabel[key];
              let insulinRecorded = dataInsulinLabel.indexOf(label);
              if (insulinRecorded == -1) {
                randomDotsArrBackground.push(randomDotsArr[key]);
              } else {
                randomDotsArrBackground.push("#fff");
              }
            } else {
              randomDotsArrBackground.push(randomDotsArr[key]);
            }
          });

          var dataLabel = [];
          bloodSugarLabels.map((label, index) => {
            dataLabel.push(moment(label, "YYYY-MM-DD HH:mm:ss").format("D MMM, YYYY | h:mm A"));
            if (DURATION_TYPE_SINGULAR[this.state.chartsTab] == "day")
              bloodSugarLabels[index] = moment(label, "YYYY-MM-DD HH:mm:ss").format("h:mm A");
            else bloodSugarLabels[index] = moment(label, "YYYY-MM-DD HH:mm:ss").format("D MMM, YYYY");
          });

          chartBloodSugarData[vital] = {
            ["data" + vital]: {
              labels: bloodSugarLabels,
              datasets: [
                {
                  label: bloodSugarLabels,
                  backgroundColor: "rgb(102 102 102)",
                  borderColor: "rgb(102 102 102)",
                  pointBackgroundColor: ppDotsArrBackground,
                  pointBorderColor: ppDotsArr,
                  borderWidth: 1,
                  pointBorderWidth: 2,
                  pointRadius: 3,
                  fill: false,
                  spanGaps: true,
                  data: dataBSPPrandial,
                  insulinData: dataInsulin,
                  labelT: "PP",
                },
                {
                  label: bloodSugarLabels,
                  backgroundColor: "rgb(255 82 235)",
                  borderColor: "rgb(255 82 235)",
                  pointBackgroundColor: fastingDotsArrBackground,
                  pointBorderColor: fastingDotsArr,
                  borderWidth: 1,
                  pointBorderWidth: 2,
                  pointRadius: 3,
                  fill: false,
                  spanGaps: true,
                  data: dataBSFasting,
                  insulinData: dataInsulin,
                  labelT: "Fasting",
                },
                {
                  label: bloodSugarLabels,
                  backgroundColor: "rgb(90 201 239)",
                  borderColor: "rgb(90 201 239)",
                  pointBackgroundColor: randomDotsArrBackground,
                  pointBorderColor: randomDotsArr,
                  borderWidth: 1,
                  pointBorderWidth: 2,
                  pointRadius: 3,
                  fill: false,
                  spanGaps: true,
                  data: dataBSRandom,
                  insulinData: dataInsulin,
                  labelT: "Random",
                },
                /* , {
                  label: 'Insulin',
                  backgroundColor: '#fff',
                  borderColor: '#fff',
                  pointBackgroundColor: [],
                  pointBorderColor: [],
                  borderWidth: 0,
                  fill: false,
                  spanGaps: true,
                  data: [],
                } */
              ],
            },
            ["options" + vital]: {
              animation: {
                duration: 0
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  font: {
                    size: 0,
                  },
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      let label = context.dataset.labelT + ": " + context.raw + " mg/dL";
                      return label;
                    },
                    afterLabel: function (context) {
                      if (context.dataset.insulinData[context.dataIndex]) {
                        let label = "Insulin: " + context.dataset.insulinData[context.dataIndex] + " Unit";
                        return label;
                      } else {
                        return null;
                      }
                    }

                  }
                },
              },
              title: {
                display: false,
                text: "Blood Sugar",
                fontSize: 15,
              },
              elements: {
                line: {
                  tension: 0,
                },
              },
              /* tooltips: {
                displayColors: true,
                callbacks: {
                  label: function (tooltipItem, data) {
                    let label = data.datasets[tooltipItem.datasetIndex].labelT + ": " + tooltipItem.yLabel + " mg/dL";

                    return label;
                  },
                  afterLabel: function (tooltipItem, data) {
                    if (data.datasets[tooltipItem.datasetIndex].insulinData[tooltipItem.index] != null) {
                      let label = "Insulin: " + data.datasets[tooltipItem.datasetIndex].insulinData[tooltipItem.index] + " Unit";
                      return label;
                    } else {
                      return null;
                    }
                  },
                },
              }, */
              layout: {
                padding: {
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                },
              },
              legend: {
                position: "bottom",
                align: "center",
                display: true,
                labels: {
                  boxWidth: 0,
                  //fontColor: '#333333',
                  fontSize: 0,
                  // usePointStyle: true,
                },
              },
              scales: {
                x: {
                  display: true,
                  /* scaleLabel: {
                    display: false,
                    labelString: '',
                  }, */
                  ticks: {
                    font: {
                      size: 11
                    }
                  }
                },
                y: {
                  position: "left",
                  display: true,
                  /* scaleLabel: {
                    display: true,
                    labelString: "",
                  }, */
                  suggestedMin: minY,
                  suggestedMax: maxY,
                  reverse: false,
                  ticks: {
                    font: {
                      size: 11
                    },
                    precision: vitalDetails.decimal_value,
                    stepSize: stepY,
                  },
                  /* afterBuildTicks: function (axis, ticks) {
                    const totalTicks = 10;
                    let density = axis.options.ticks.stepSize;

                    var firstTick = ticks[ticks.length - 1];
                    var lastTick = ticks[0];

                    var tempA = axis.chart.config.data.datasets[0].data;
                    var dataset = [...tempA];
                    dataset = dataset.sort(function (a, b) {
                      return a - b;
                    });
                    var tempB = axis.chart.config.data.datasets[1].data;
                    var dataset2 = [...tempB];
                    dataset2 = dataset2.sort(function (a, b) {
                      return a - b;
                    });
                    var tempC = axis.chart.config.data.datasets[2].data;
                    var dataset3 = [...tempC];
                    dataset3 = dataset3.sort(function (a, b) {
                      return a - b;
                    });

                    lastTick = Math.max(
                      parseFloat(dataset[dataset.length - 1]),
                      parseFloat(dataset2[dataset2.length - 1]),
                      parseFloat(dataset3[dataset3.length - 1])
                    );

                    var tickArr = [];
                    var tempHighest = parseFloat(firstTick) + totalTicks * density;

                    if (lastTick > tempHighest) {
                      density = (lastTick - firstTick) / 10;
                      density = Math.ceil(density);
                    }

                    for (let i = firstTick; i < lastTick && tickArr.length < totalTicks; i = i + density) {
                      tickArr.push(i);
                    }
                    lastTick = tickArr[tickArr.length - 1] + density;
                    tickArr.push(lastTick);
                    axis.options.ticks.max = lastTick;
                    return tickArr;
                  } */
                },
              },
            },
          };

        }
        //for blood sugar with insuline
        if (vital === 'V014') {
          let chartDataInsulinFound = false;
            const chartDataInsulin = this.updatedVitalGraphData(data['V014']); //insulin
            const chartDataBSFasting = this.updatedVitalGraphData(data['V010']);// fasting
            const chartDataBSPPrandial = this.updatedVitalGraphData(data['V011']);// prandial
            const chartDataBSRandom = this.updatedVitalGraphData(data['V013']); // random
          if (chartDataInsulin.data && chartDataInsulin.data.length > 0) {

          if (chartDataBSFasting.data && chartDataBSFasting.data.length > 0 && chartDataInsulin.data.length > 0  ) {
            chartDataInsulinFound = true;
            this.setState({
              chartDataInsulinFound
            })
          }else if(chartDataBSPPrandial.data && chartDataBSPPrandial.data.length > 0 && chartDataInsulin.data.length > 0){
            chartDataInsulinFound = true;
            this.setState({
              chartDataInsulinFound
            })
          }else if(chartDataBSRandom.data && chartDataBSRandom.data.length > 0 && chartDataInsulin.data.length > 0){
            this.setState({
              chartDataInsulinFound
            })
          }
          }
          if (chartDataInsulin && chartDataInsulin.data.length > 0) {
            //label basis of insuline
            var bloodSugarLabelsNew = [...chartDataInsulin.rawLabel];
            bloodSugarLabelsNew = [...new Set(bloodSugarLabelsNew)].sort();
            var dataLabel = [];
            bloodSugarLabelsNew.map((label, index) => {
              dataLabel.push(moment(label, 'YYYY-MM-DD HH:mm:ss').format('D MMM, YYYY | h:mm A'))
              if (DURATION_TYPE_SINGULAR[this.state.chartsTab] == 'day')
                bloodSugarLabelsNew[index] = moment(label, 'YYYY-MM-DD HH:mm:ss').format('h:mm A')
              else
                bloodSugarLabelsNew[index] = moment(label, 'YYYY-MM-DD HH:mm:ss').format('D MMM, YYYY')
            });

            let insulin_data = [];
            let InsulingMinMax = [];
            let dataSet;
            let matchRec = [];
            chartDataInsulin.dataLabel.forEach((item, key) => {
              let findexR = chartDataBSRandom.dataLabel.findIndex(x => x === item); //random sugar
              let findexFs = chartDataBSFasting.dataLabel.findIndex(x => x === item); // fasting
              let findexPP = chartDataBSPPrandial.dataLabel.findIndex(x => x === item); // prandial
              let insulinD = chartDataInsulin.dataLabel.findIndex(x => x === item); // insulin key

              if (findexR !== -1 && findexFs !== -1 && findexPP !== -1) {
                const valueOfRandomBlSug = parseInt(chartDataBSRandom.data[findexR]);
                const valueOfInsulin = parseInt(chartDataInsulin.data[insulinD]);
                insulin_data.push({ x: 0, y: valueOfRandomBlSug, r: valueOfInsulin, lb: 'Random Blood Sugar' });
                //for min and max
                dataSet = data['V013'];
                InsulingMinMax = this.updatedVitalGraphData(dataSet);
                matchRec.push({ 'V013': InsulingMinMax.data[findexR] });

              } else if (findexFs !== -1 && findexPP !== -1 && findexR == -1) {
                const valueOfRandomPP = parseInt(chartDataBSPPrandial.data[findexPP]);
                const valueOfInsulin = parseInt(chartDataInsulin.data[insulinD]);
                insulin_data.push({ x: 0, y: valueOfRandomPP, r: valueOfInsulin, lb: 'Blood Sugar Post Prandial' });
                //for min and max
                dataSet = data['V011'];
                InsulingMinMax = this.updatedVitalGraphData(dataSet);
                matchRec.push({ 'V011': InsulingMinMax.data[findexPP] });

              } else if (findexFs !== -1 && findexPP === -1 && findexR === -1) {
                const valueOfRandomPP = parseInt(chartDataBSFasting.data[findexFs]);
                const valueOfInsulin = parseInt(chartDataInsulin.data[insulinD]);
                insulin_data.push({ x: 0, y: valueOfRandomPP, r: valueOfInsulin, lb: 'Blood Sugar Fasting' });
                //for min and max
                dataSet = data['V010'];
                InsulingMinMax = this.updatedVitalGraphData(dataSet);
                matchRec.push({ 'V010': InsulingMinMax.data[findexFs] });

              } else if (findexPP !== -1 && (findexFs === -1 && findexR === -1)) {
                const valueOfRandomPP = parseInt(chartDataBSPPrandial.data[findexPP]);
                const valueOfInsulin = parseInt(chartDataInsulin.data[insulinD]);
                insulin_data.push({ x: 0, y: valueOfRandomPP, r: valueOfInsulin, lb: 'Blood Sugar Post Prandial' });
                //for min and max
                dataSet = data['V011'];
                InsulingMinMax = this.updatedVitalGraphData(dataSet);
                matchRec.push({ 'V011': InsulingMinMax.data[findexPP] });

              } else if (findexR !== -1 && (findexFs === -1 && findexPP === -1)) {
                const valueOfRandomPP = parseInt(chartDataBSRandom.data[findexR]);
                const valueOfInsulin = parseInt(chartDataInsulin.data[insulinD]);
                insulin_data.push({ x: 0, y: valueOfRandomPP, r: valueOfInsulin, lb: 'Random Blood Sugar' });
                //for min and max
                dataSet = data['V013'];
                InsulingMinMax = this.updatedVitalGraphData(dataSet);
                matchRec.push({ 'V013': InsulingMinMax.data[findexR] });

              } else {
                const valueOfRandomPP = parseInt(chartDataBSRandom.data[findexR]);
                const valueOfInsulin = parseInt(chartDataInsulin.data[insulinD]);
                insulin_data.push({ x: 0, y: valueOfRandomPP, r: valueOfInsulin, lb: 'Random Blood Sugar' });
                //for min and max
                dataSet = data['V013'];
                InsulingMinMax = this.updatedVitalGraphData(dataSet);
                matchRec.push({ 'V013': InsulingMinMax.data[findexR] });

              }
            })
            var backColorNew = [];
            for (const [key, value] of Object.entries(matchRec)) {
              if (value['V013']) {
                backColorNew.push(this.getVitalRangeColor(value['V013'], this.state.constants.vitals_range['V013']));
              }
              if (value['V010']) {
                backColorNew.push(this.getVitalRangeColor(value['V010'], this.state.constants.vitals_range['V010']));
              }
              if (value['V011']) {
                backColorNew.push(this.getVitalRangeColor(value['V011'], this.state.constants.vitals_range['V011']));
              }
            }
            //Blood Sugar Tracking 
            chartBloodSugarInsulinData = {
              data: {
                labels: bloodSugarLabelsNew,
                datasets: [{
                  responsive: true,
                  label: "Blood Sugar & Insulin",
                  data: insulin_data,
                  lineTension: 0,
                  fill: false,
                  borderColor: 'grey',
                  borderWidth: 1,
                  /* pointBorderColor: 'orange', */
                  pointBackgroundColor: backColorNew,
                  padding: 20,
                  tension: 0.1,
                  pointRadius: 12,
                  pointHoverRadius: 10,
                  pointHitRadius: 30,
                  pointBorderWidth: 0,
                  pointStyle: 'circle',
                  showLine: true
                }]
              },
              option: {
                animation: false,
                responsive: true,
                maintainAspectRatio: !this.state.showOnlyTicked ? false : true,
                spanGaps: true,
                scales: {
                  x: {
                    title: {
                      display: !this.state.showOnlyTicked ? true : false,
                      text: "",
                    },
                    ticks: {
                      //minRotation: 20, // angle in degrees
                      autoSkip: false,
                      font: {
                        size: 11
                      }
                    },
                    beginAtZero: false,
                    grid: {
                      display: false
                    }
                  },
                  y: {
                    beginAtZero: false,
                    suggestedMin: InsulingMinMax.minY,
                    suggestedMax: InsulingMinMax.maxY,
                    ticks: {
                      font: {
                        size: 11
                      },
                      padding: 15,
                      stepSize: InsulingMinMax.stepY,
                    },
                    /* afterBuildTicks: function (axis, ticks) {
                      const totalTicks = 10;
                      let density = axis.options.ticks.stepSize;
                      var firstTick = ticks[ticks.length - 1];
                      // var lastTick = ticks[0];
                      var tempA = axis.chart.config.data.datasets[0].data;
                      var dataset = [...tempA];
                      dataset = dataset.sort(function (a, b) { return a - b });
                      var lastTick = dataset[dataset.length - 1];
                      var tickArr = [];
                      var tempHighest = parseFloat(firstTick) + (totalTicks * density);
                      if (lastTick > tempHighest) {
                        density = (lastTick - firstTick) / 10;
                        density = Math.ceil(density)
                      }
                      for (let i = firstTick; i <= lastTick && tickArr.length < totalTicks; i = i + density) {
                        tickArr.push(i)
                      }
                      lastTick = tickArr[tickArr.length - 1] + density;
                      tickArr.push(lastTick);
                      axis.options.ticks.max = lastTick;
                      return tickArr;
                    }, */
                    grid: {
                      display: true,
                      drawBorder: false
                    }
                  }
                },
                legend: {
                  display: false,
                  position: 'top',
                  labels: {
                    boxWidth: 100,
                    fontColor: 'black'
                  }
                },
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        var label = context.raw.lb;

                        if (label) {
                          label += ' : ';
                        }
                        if (context.parsed.y !== null) {
                          label += context.parsed.y+ ' mg/dL';
                        }
                        return label;
                      },
                      afterLabel: function (context) {
                         if (context.raw.r) {
                          let label = "Insulin: " + context.raw.r + " Unit";
                          return label;
                        } else {
                          return null;
                        } 
                      }
                    }
                  },
                  datalabels: {
                    color: [
                      'white',
                    ],
                    font: {
                      weight: "bold"
                    },

                    formatter: function (value, context) {
                      return Math.round(value.r);
                    },
                    offset: 8,
                    padding: 6,
                  }
                }
              }
            }

            this.setState({
              chartBloodSugarInsulinData,
              chartDataInsulin
            })
          }
        }
      })
    }

    if (this.state.showOnlyTicked != undefined && this.state.showOnlyTicked == true && Object.entries(this.state.all_print).length == 0) {
      one_chart_data_found = false;
    }

    this.setState({
      chartDataMapped,
      chartBloodSugarData,
      oneChartDataFound: one_chart_data_found,
    });
  };

  handleAllPrintCheckedFn = (_) => {
    let { chartChecked, constants } = this.state;
    chartChecked = chartChecked.split(",");
    let chartCheckLength = chartChecked.length;

    let totalVitalLength = constants.vitals_details.length;
    if (this.state.cgmDataAvailable) {
      totalVitalLength = totalVitalLength + 1; //for bloodSugarGlucoseChartCheck
    } else if(chartChecked.indexOf(this.state.bloodSugarChartID) !== -1){
      chartCheckLength--;
    }

    if (this.state.chartDataInsulinFound) {
      totalVitalLength = totalVitalLength + 1;
    } else if(chartChecked.indexOf(this.state.bloodSugarInsulinChartID) !== -1){
      chartCheckLength--;
    }
    this.setState({
      all_print_checked: chartCheckLength === totalVitalLength,
    });
  };

  handleAllPrintCheck = (_) => {
    let { all_print_checked, constants } = this.state;

    const PDF_PRINT_OBJ = {};
    constants.vitals_details.forEach((vd) => {
      PDF_PRINT_OBJ[vd.id] = all_print_checked;
    });
    if (this.state.cgmDataAvailable) {
      PDF_PRINT_OBJ[this.state.bloodSugarChartID] = all_print_checked;
    }
    if (this.state.chartDataInsulinFound) {
      PDF_PRINT_OBJ[this.state.bloodSugarInsulinChartID] = all_print_checked;
    }
    this.setState(
      {
        all_print: PDF_PRINT_OBJ,
      },
      () => console.log(this.state.all_print)
    );
  };

  handleVitalGroupAPI = async (e, vital) => {
    const { reference_id, all_print, all_print_checked, bloodSugarGlucoseChartCheck, constants, showVitalType, bloodSugarInsulinChartCheck } = this.state;

    const checked = e.target.checked;
    let selected_vital_graphs = "";

    if (vital === "all_print") {
      selected_vital_graphs = !all_print_checked ? constants.vitals_details.map((e) => e.vital_code).toString() : "";

      if (selected_vital_graphs !== "") {
        if(this.state.cgmDataAvailable){
          selected_vital_graphs = selected_vital_graphs + "," + this.state.bloodSugarChartID;
          this.setState({bloodSugarGlucoseChartCheck: !this.state.bloodSugarGlucoseChartCheck});
        }
        if (this.state.chartDataInsulinFound) {
          selected_vital_graphs = selected_vital_graphs + "," + this.state.bloodSugarInsulinChartID;
          this.setState({bloodSugarInsulinChartCheck: !this.state.bloodSugarInsulinChartCheck});
        }
      } else {
        if(this.state.cgmDataAvailable){
          selected_vital_graphs = selected_vital_graphs.replace("," + this.state.bloodSugarChartID, "");
          this.setState({bloodSugarGlucoseChartCheck: !this.state.bloodSugarGlucoseChartCheck});
        }
        if (this.state.chartDataInsulinFound) {
          selected_vital_graphs = selected_vital_graphs.replace("," + this.state.bloodSugarInsulinChartID, "");
          this.setState({bloodSugarInsulinChartCheck: !this.state.bloodSugarInsulinChartCheck});
        }
      }
      this.setState(
        {
          all_print_checked: !this.state.all_print_checked
        },
        this.handleAllPrintCheck
      );
    } else {
      if (Object.keys(all_print).length > 0) {
        all_print[vital] = !all_print[vital];
      } else {
        all_print[vital] = true;
      }
      selected_vital_graphs = Object.keys(all_print)
        .filter((ap) => all_print[ap] === true)
        .toString();

      if (this.state.bloodSugarChartID === vital) {
        this.setState(
          {
            bloodSugarGlucoseChartCheck: checked,
          },
          () => {
            const index = selected_vital_graphs.indexOf(this.state.bloodSugarChartID);
            if (this.state.bloodSugarGlucoseChartCheck) {
              if (index === -1) {
                selected_vital_graphs = selected_vital_graphs + "," + this.state.bloodSugarChartID;
              }
            } else {
              if (index >= 0) {
                selected_vital_graphs = selected_vital_graphs.replace("," + this.state.bloodSugarChartID, "");
              }
            }
          }
        );
      }
      if (this.state.bloodSugarInsulinChartID === vital) {
        this.setState(
          {
            bloodSugarInsulinChartCheck: checked,
          },
          () => {
            const index = selected_vital_graphs.indexOf(this.state.bloodSugarInsulinChartID);
            if (this.state.bloodSugarInsulinChartCheck) {
              if (index === -1) {
                selected_vital_graphs = selected_vital_graphs + "," + this.state.bloodSugarInsulinChartID;
              }
            } else {
              if (index >= 0) {
                selected_vital_graphs = selected_vital_graphs.replace("," + this.state.bloodSugarInsulinChartID, "");
              }
            }
          }
        );
      }
    }

    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id,
      selected_vital_graphs,
    };

    const vitalGraphPromise = await Axios.post(PDF_CONFIG_VITALS_GRAPH_UPDATE, qs.stringify(PARAMS));

    if (vitalGraphPromise.data.status) {
      this.setState(
        {
          chartChecked: selected_vital_graphs,
        },
        () => {
          this.props.updateData(selected_vital_graphs);
          this.handleAllPrintCheckedFn();
        }
      );

      this.setState({
        dummy: !this.state.dummy,
      });
    }
  };

  handleVitalGrahpCheck = (e, vital) => {
    this.handleVitalGroupAPI(vital);
  };

  getVitalRangeColor = (value, range) => {
    if (value == "-") return "";

    if (range.critical.min == null && range.critical.max == null)
      //return '#008000';
      return "rgb(0 168 77)";

    let vitalRange = "";
    //normal range
    if (value >= range.normal.min && value <= range.normal.max)
      //vitalRange = '#008000'; //green
      vitalRange = "rgb(0 168 77)"; //green

    //abnormal range
    if ((value > range.critical.min && value < range.normal.min) || (value > range.normal.max && value < range.critical.max))
      //vitalRange = '#ffbf00'; //amber
      vitalRange = "rgb(245 153 28)"; //amber

    //critical range
    if ((range.critical.min != null && value <= range.critical.min) || (range.critical.max != null && value >= range.critical.max))
      //vitalRange = '#d24350'; //red
      vitalRange = "rgb(255 0 0)"; //red

    return vitalRange;
  };

  createPointsWithDifferentColors = (__id, data) => {
    let colorDots = [];
    this.state.vitalsConstant.map((vital) => {
      if (vital.id === __id) {
        data.map((val) => {
          colorDots.push(this.getVitalRangeColor(val, this.state.constants.vitals_range[vital.id]));
        });
      }
    });
    return colorDots;
  };


  renderBloodSugarInsline() {
    if (this.state.chartType === 'all') {
      if (!this.state.showOnlyTicked && this.state.chartDataInsulin && this.state.chartDataInsulin.data.length > 0) {
        // full graph
        return (
          <div className="chartCanvas">
            <div className={`graphs_chart${this.state.zoomClass ? " zoomChart" : ""}`}>
              <h4>Blood Sugar & Insulin Tracking</h4>
              <div
                className="zoomChartBtn"
                onClick={(e) => this.zoomChart(e)}
              ></div>

              {/* <div className="chartCanvasNew"> */}
                {!this.state.showOnlyTicked && !this.state.checkBoxDisable ? (
                  <div className="check_for_print float-right">
                    {!this.state.isNurseLoggined ? (
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        onChange={(e) => this.handleVitalGroupAPI(e, this.state.bloodSugarInsulinChartID)}
                        value={this.state.all_print[this.state.bloodSugarInsulinChartID]}
                        checked={this.state.all_print[this.state.bloodSugarInsulinChartID]}
                        className="custom-control-input pdf_print"
                        name={this.state.bloodSugarInsulinChartID + " _print"}
                        autocomplete="off"
                      />
                      <label className="custom-control-label text-basic" htmlFor={this.state.bloodSugarInsulinChartID + " _print"}>
                        &nbsp;
                    </label>
                    </div>
                    ):null}
                  </div>
                ) : null}
                <Line id="myCanvas" 
                  data={this.state.chartBloodSugarInsulinData['data']}
                  options={this.state.chartBloodSugarInsulinData['option']}
                  plugins={[ChartDataLabels]}
                />
              {/* </div> */}
            </div>
          </div>
        )
      } else { // view prescription
        if (this.state.all_print['bloodSugarInsulinChartID']) {
          return (
            <div className="chartCanvas">
              <h4>Blood Sugar & Insulin Tracking</h4>
              <div className="chartCanvasNew" >
                {!this.state.showOnlyTicked && !this.state.checkBoxDisable ? (
                  <div className="check_for_print float-right">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" id="insuline_checkbox_single" onChange={e => this.handleVitalGroupAPI(e, 'blood_insulin_graph')} value={this.state.blood_insulin_graph_checked} checked={this.state.blood_insulin_graph_checked}
                        className="custom-control-input pdf_print" name={'blood_insulin_graph' + ' _print'} autoComplete="off" />
                      <label className="custom-control-label text-basic" htmlFor={'blood_insulin_graph' + ' _print'}>&nbsp;</label>
                    </div>
                  </div>
                ) : null}
                <Line
                  data={this.state.chartBloodSugarInsulinData['data']}
                  options={this.state.chartBloodSugarInsulinData['option']}
                  plugins={[ChartDataLabels]}
                />
              </div>
            </div>
          )
        }
      }
    }
  }
  render() {
    const { oneChartDataFound, chartDataInsulinFound, cgmDataAvailable } = this.state;

    var no_right_col_graph;

    if(this.state.showOnlyTicked && this.state.all_print[this.state.bloodSugarChartID] && cgmDataAvailable){
      no_right_col_graph = 2;
    } else if(!this.state.showOnlyTicked && cgmDataAvailable) {
      no_right_col_graph = 2;
    } else {
      no_right_col_graph = 3;
    }

    var right_col_chart_drawn = 0;
    var right_col_chart_drawn_arr = [];

    return (
      <>
        {this.state.dataLoaded ? (
          oneChartDataFound || (cgmDataAvailable && !this.state.showOnlyTicked && this.state.chartType == 'all') || (this.state.showOnlyTicked && this.state.all_print[this.state.bloodSugarChartID] && cgmDataAvailable && this.state.chartType == 'all') ? (
            <div className="row">
              {this.state.chartType === "all" && !this.state.showOnlyTicked && !this.state.checkBoxDisable ? (
                <>
                  <div className="col-7 pl-2 check-primary">
                    {!this.state.isNurseLoggined ? (
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        onChange={(e) => this.handleVitalGroupAPI(e, "all_print")}
                        value={this.state.all_print_checked}
                        checked={this.state.all_print_checked}
                        className="custom-control-input"
                        id="all_print"
                        name="all_print"
                        autocomplete="off"
                      />
                      <label className="custom-control-label text-basic" htmlFor="all_print">
                        Select All to Print with Prescription
                      </label>
                    </div>
                    ): null}
                  </div>
                </>
              ) : null}
              <div
                className={
                  this.state.chartType === "all" && !this.state.showOnlyTicked && !this.state.checkBoxDisable
                    ? `col-5 pl-3 vital-graph-legends`
                    : `col-12 pl-3 vital-graph-legends`
                }
              >
                <ul className="legend_unit_label">
                  <li>
                    <span className="normal"></span>Normal
                  </li>
                  <li>
                    <span className="abnormal"></span>Abnormal
                  </li>
                  <li>
                    <span className="critical"></span>Critical
                  </li>
                </ul>
              </div>

              <div className="col-12 chart_width " style={{ marginTop: 20 }}>
                <div className="row">
                  {this.state.chartType === "all" && !this.state.showOnlyTicked && cgmDataAvailable ? (
                    <div className="col-12 col-md-8 fix-height-left" >
                      <div className="chart_width_iner padd">
                        <h4 className="mb-3">Day Time Blood Sugar Level</h4>
                        {!this.state.showOnlyTicked && !this.state.checkBoxDisable ? (
                          <div className="check_for_print float-right">
                            {!this.state.isNurseLoggined ? (
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                onChange={(e) => this.handleVitalGroupAPI(e, this.state.bloodSugarChartID)}
                                value={this.state.all_print[this.state.bloodSugarChartID]}
                                checked={this.state.all_print[this.state.bloodSugarChartID]}
                                className="custom-control-input pdf_print"
                                name={this.state.bloodSugarChartID + " _print"}
                                autocomplete="off"
                              />
                              <label className="custom-control-label text-basic" htmlFor={this.state.bloodSugarChartID + " _print"}>
                                &nbsp;
                              </label>
                            </div>):null}
                          </div>
                        ) : null}
                        <Line
                          data={this.state.dateTimeChart}
                          style={{ paddingLeft: 20 }}
                          options={{
                            animation: {
                              duration: 0
                            },
                            spanGaps: true,
                            plugins: {
                              legend: {
                                display: false,
                              },
                              tooltip: {
                                callbacks: {
                                  label: function (context) {
                                    let label = context.dataset.label + ": " + context.formattedValue + " mg/dL";
                                    return label;
                                  }
                                }
                              }
                            },

                            elements: {
                              line: {
                                tension: 0.4,
                              },
                            },
                            scales: {
                              x: {
                                ticks: {
                                  callback: function (val, index) {
                                    return index === 0 || index === 8 || index === 16 || index === 24 || index === 32 || index === 40 || index === 48 || index === 56 || index === 64 || index === 72 || index === 80 || index === 88 || index === 96 ? this.getLabelForValue(val) : "";
                                  },
                                  autoSkip: false
                                },
                                display: true,
                                position: "top",
                                grid: {
                                  drawBorder: false,
                                  color: function (context) {
                                     /* if (context.tick.value === 0 || context.tick.value === 20 || context.tick.value === 36 || context.tick.value === 60 || context.tick.value === 76 || context.tick.value === 96) {
                                       return "#b5babe";
                                     } */
                                  },
                                },
                              },
                              y: {
                                beginAtZero: true,
                                grid: {
                                  display: false,
                                },
                              },
                            },
                          }}
                          width={600}
                          height={268}
                        />
                        {this.state.chartDayTimeBSTableFormat && this.getGlucosePercentageHtml()}
                      </div>
                    </div>
                  ) : null}

                  {/*prescription*/}
                  {this.state.all_print[this.state.bloodSugarChartID] && this.state.showOnlyTicked && cgmDataAvailable ? (
                    <div className="col-12 col-md-8 fix-height-left">
                      <div className="chart_width_iner">
                        <h4 className="mb-3">Day Time Blood Sugar Level</h4>
                        {!this.state.showOnlyTicked && !this.state.checkBoxDisable ? (
                          <div className="check_for_print float-right">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                onChange={(e) => this.handleVitalGroupAPI(e, this.state.bloodSugarChartID)}
                                value={this.state.all_print[this.state.bloodSugarChartID]}
                                checked={this.state.all_print[this.state.bloodSugarChartID]}
                                className="custom-control-input pdf_print"
                                name={this.state.bloodSugarChartID + " _print"}
                                autocomplete="off"
                              />
                              <label className="custom-control-label text-basic" htmlFor={this.state.bloodSugarChartID + " _print"}>
                                &nbsp;
                              </label>
                            </div>
                          </div>
                        ) : null}
                        <Line
                          data={this.state.dateTimeChart}
                          style={{ paddingLeft: 20 }}
                          options={{
                            animation: {
                              duration: 0
                            },
                            spanGaps: true,
                            plugins: {
                              legend: {
                                display: false,
                              },
                              tooltip: {
                                callbacks: {
                                  label: function (context) {
                                    let label = context.dataset.label + ": " + context.formattedValue + " mg/dL";
                                    return label;
                                  }
                                }
                              }
                            },

                            elements: {
                              line: {
                                tension: 0.4,
                              },
                            },
                            scales: {
                              x: {
                                ticks: {
                                  callback: function (val, index) {
                                    return index === 0 || index === 8 || index === 16 || index === 24 || index === 32 || index === 40 || index === 48 || index === 56 || index === 64 || index === 72 || index === 80 || index === 88 || index === 96? this.getLabelForValue(val) : "";
                                  },
                                  autoSkip: false
                                },
                                display: true,
                                position: "top",
                                grid: {
                                  drawBorder: false,
                                  color: function (context) {
                                     /* if (context.tick.value === 0 || context.tick.value === 20 || context.tick.value === 36 || context.tick.value === 60 || context.tick.value === 76 || context.tick.value === 96) {
                                       return "#b5babe";
                                     } */
                                  },
                                },
                              },
                              y: {
                                beginAtZero: true,
                                grid: {
                                  display: false,
                                },
                              },
                            },
                          }}
                          width={600}
                          height={268}
                        />
                        {this.state.chartDayTimeBSTableFormat && this.getGlucosePercentageHtml()}
                      </div>
                    </div>
                  ) : null}

                  <div className={`col-12 fix-height-right ${this.state.showOnlyTicked && this.state.all_print[this.state.bloodSugarChartID] && cgmDataAvailable ? "col-md-4" : !this.state.showOnlyTicked && cgmDataAvailable ? "col-md-4" : "col-md-12"}`}>
                    {Object.keys(this.state.chartDataMapped).length > 0 ? this.state.vitalsConstant.map((vd, i) => {
                      if (this.state.chartType === "all") {
                        if (!this.state.showOnlyTicked) {
                          if (vd.id !== "V011" && vd.id !== "V013" && vd.id !== "V014"  && vd.id !== 'V006' && this.state.chartDataMapped[vd.id]["data" + vd.id].datasets[0].data && this.state.chartDataMapped[vd.id]["data" + vd.id].datasets[0].data.length > 0 && right_col_chart_drawn < no_right_col_graph) {
                            right_col_chart_drawn++;
                            right_col_chart_drawn_arr.push(vd.id);
                            return (
                              <div className={`w-100`}>
                                <div className="chart_width_iner font_rt1" key={i} style={{ marginBottom: 10 }}>
                                  <div className="chartCanvas" key={i}>
                                    <h4>
                                      {(vd.id == "V010" || vd.id === "V011" || vd.id === "V013" || vd.id === "V014") ? "Blood Sugar" : vd.id == "V005" ? "Blood Pressure" : vd.id == "V008" ? "Heart Rate" : vd.id == "V003" ? "BMI" : vd.display_name} 
                                    </h4>
                                    {!this.state.showOnlyTicked && !this.state.checkBoxDisable ? (
                                      <div className="check_for_print float-right">
                                        {!this.state.isNurseLoggined ? (
                                        <div className="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            onChange={(e) => this.handleVitalGroupAPI(e, vd.id)}
                                            value={this.state.all_print[vd.id]}
                                            checked={this.state.all_print[vd.id]}
                                            className="custom-control-input pdf_print"
                                            name={vd.id + " _print"}
                                            autocomplete="off"
                                          />
                                          <label className="custom-control-label text-basic" htmlFor={vd.id + " _print"}>
                                            &nbsp;
                                          </label>
                                        </div>):null}
                                      </div>
                                    ) : null}
                                    {vd.id !== "V010" && vd.id !== "V011" && vd.id !== "V013" && vd.id !== "V014" ? (
                                      <>
                                        <Line
                                          data={this.state.chartDataMapped[vd.id]["data" + vd.id]}
                                          options={this.state.chartDataMapped[vd.id]["options" + vd.id]}
                                        />
                                        {vd.id == "V005" ? (
                                          <ul className="legend_unit_label">
                                            <li>
                                              <span className="gray"></span>Systolic
                                            </li>
                                            <li>
                                              <span className="blue"></span>Diastolic
                                            </li>
                                          </ul>
                                        ) : null}
                                      </>
                                    ) : null}

                                    {(vd.id === "V010" || vd.id === "V011" || vd.id === "V013" || vd.id === "V014") && (
                                      <>
                                        <Line
                                          data={this.state.chartBloodSugarData["V010"]["data" + "V010"]}
                                          options={this.state.chartBloodSugarData["V010"]["options" + "V010"]}
                                        />
                                        <ul className="legend_unit_label">
                                          <li>
                                            <span className="gray"></span>PP
                                          </li>
                                          <li>
                                            <span className="pink"></span>Fasting
                                          </li>
                                          <li>
                                            <span className="blue"></span>Random
                                          </li>
                                          <li>
                                            <span className="white round"></span>Insulin
                                          </li>
                                        </ul>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        } 
                        else { //view presc
                          if (this.state.all_print[vd.id]) {
                            if (vd.id !== "V011" && vd.id !== "V013" && vd.id !== "V014"  && vd.id !== 'V006' && this.state.chartDataMapped[vd.id]["data" + vd.id].datasets[0].data && this.state.chartDataMapped[vd.id]["data" + vd.id].datasets[0].data.length > 0 && right_col_chart_drawn < no_right_col_graph) {
                              right_col_chart_drawn++;
                              right_col_chart_drawn_arr.push(vd.id);
                              return (
                                <div className={`w-100`}>
                                  <div className="chart_width_iner font_rt1" key={i} style={{ marginBottom: 10 }}>
                                    <div className="chartCanvas" key={i}>
                                      <h4>
                                        {(vd.id == "V010" || vd.id === "V011" || vd.id === "V013" || vd.id === "V014") ? "Blood Sugar" : vd.id == "V005" ? "Blood Pressure" : vd.id == "V008" ? "Heart Rate" : vd.id == "V003" ? "BMI" : vd.display_name} 
                                      </h4>
                                      {vd.id !== "V010" && vd.id !== "V011" && vd.id !== "V013" && vd.id !== "V014" ? (
                                      <>
                                        <Line
                                          data={this.state.chartDataMapped[vd.id]["data" + vd.id]}
                                          options={this.state.chartDataMapped[vd.id]["options" + vd.id]}
                                        />
                                        {vd.id == "V005" ? (
                                          <ul className="legend_unit_label">
                                            <li>
                                              <span className="gray"></span>Systolic
                                            </li>
                                            <li>
                                              <span className="blue"></span>Diastolic
                                            </li>
                                          </ul>
                                        ) : null}
                                      </>
                                    ) : null}

                                    {(vd.id === "V010" || vd.id === "V011" || vd.id === "V013" || vd.id === "V014") && (
                                      <>
                                        <Line
                                          data={this.state.chartBloodSugarData["V010"]["data" + "V010"]}
                                          options={this.state.chartBloodSugarData["V010"]["options" + "V010"]}
                                        />
                                        <ul className="legend_unit_label">
                                          <li>
                                            <span className="gray"></span>PP
                                          </li>
                                          <li>
                                            <span className="pink"></span>Fasting
                                          </li>
                                          <li>
                                            <span className="blue"></span>Random
                                          </li>
                                          <li>
                                            <span className="white round"></span>Insulin
                                          </li>
                                        </ul>
                                      </>
                                    )}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          }
                        }
                      }
                    }) : null}
                  </div>
                </div>
              </div>
              
              <div className={`chartCanvasWrapper w-100 ${this.state.chartType !== "all" ? "chartCanvasWrapperSmall" : ""}`}>
                {/* ----------------Blood Sugar Insulin ---------------------- */}
                {chartDataInsulinFound ? this.renderBloodSugarInsline() : null}

                {Object.keys(this.state.chartDataMapped).length > 0 ? this.state.vitalsConstant.map((vd, i) => {
                  //used for all single graph
                  if (this.state.chartType !== "all") {
                    if (this.state.chartType === vd.id) {
                      return (
                        <div className="chartCanvas" style={{ paddingLeft: 24 }} key={i}>
                          <h4>
                            {vd.id == "V005" ? "Blood Pressure" : vd.id == "V010" || vd.id === "V011" || vd.id === "V013" || vd.id === "V014" ? "Blood Sugar" : vd.display_name}
                          </h4>
                          {!this.state.checkBoxDisable ? (
                            <div className="check_for_print">
                              {!this.state.isNurseLoggined ? (
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  onChange={(e) => this.handleVitalGroupAPI(e, vd.id)}
                                  value={this.state.all_print[vd.id]}
                                  checked={this.state.all_print[vd.id]}
                                  className="custom-control-input pdf_print"
                                  name={vd.id + " _print"}
                                  autoComplete="off"
                                />
                                <label className="custom-control-label text-basic" htmlFor={vd.id + " _print"}>
                                  &nbsp;
                                </label>
                              </div>):null}
                            </div>
                          ) : null}
                          {vd.id !== "V010" && vd.id !== "V011" && vd.id !== "V013" && vd.id !== "V014" ? (
                            <>
                              <Line
                                data={this.state.chartDataMapped[vd.id]["data" + vd.id]}
                                options={this.state.chartDataMapped[vd.id]["options" + vd.id]}
                              />
                              {vd.id == "V005" ? (
                                <ul className="legend_unit_label">
                                  <li>
                                    <span className="gray"></span>Systolic
                                  </li>
                                  <li>
                                    <span className="blue"></span>Diastolic
                                  </li>
                                </ul>
                              ) : null}{" "}
                            </>
                          ) : null}

                          {(vd.id === "V010" || vd.id === "V011" || vd.id === "V013" || vd.id === "V014") && (
                            <>
                              <Line
                                data={this.state.chartBloodSugarData["V010"]["data" + "V010"]}
                                options={this.state.chartBloodSugarData["V010"]["options" + "V010"]}
                              />
                              <ul className="legend_unit_label">
                                <li>
                                  <span className="gray"></span>PP
                                </li>
                                <li>
                                  <span className="pink"></span>Fasting
                                </li>
                                <li>
                                  <span className="blue"></span>Random
                                </li>
                                <li>
                                  <span className="white round"></span>Insulin
                                </li>
                              </ul>
                            </>
                          )}
                        </div>
                      );
                    } 
                  } 
                  else {
                    //for all graph popup 
                    if (!this.state.showOnlyTicked) {
                      if (vd.id !== "V014" && vd.id !== "V013" && vd.id !== "V011" && vd.id !== "V010" && vd.id !== "V006" && vd.id !== "V005" && this.state.chartDataMapped[vd.id]["data" + vd.id].datasets[0].data && this.state.chartDataMapped[vd.id]["data" + vd.id].datasets[0].data.length > 0 && !right_col_chart_drawn_arr.includes(vd.id)) {
                        return (
                          <div className="chartCanvas" key={i}>
                            <h4>{vd.id !== "V005" ? vd.display_name : "Blood Pressure"}</h4>
                            {!this.state.showOnlyTicked && !this.state.checkBoxDisable ? (
                              <div className="check_for_print">
                                {!this.state.isNurseLoggined ? (
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => this.handleVitalGroupAPI(e, vd.id)}
                                    value={this.state.all_print[vd.id]}
                                    checked={this.state.all_print[vd.id]}
                                    className="custom-control-input pdf_print"
                                    name={vd.id + " _print"}
                                    autocomplete="off"
                                  />
                                  <label className="custom-control-label text-basic" htmlFor={vd.id + " _print"}>
                                    &nbsp;
                                    </label>
                                </div>):null}
                              </div>
                            ) : null}
                            <Line
                              data={this.state.chartDataMapped[vd.id]["data" + vd.id]}
                              options={this.state.chartDataMapped[vd.id]["options" + vd.id]}
                            />
                            {vd.id == "V005" ? (
                              <ul className="legend_unit_label chart-Chang">
                                <li>
                                  <span className="gray"></span>Systolic
                                  </li>
                                <li>
                                  <span className="blue"></span>Diastolic
                                  </li>
                              </ul>
                            ) : null}
                          </div>
                        );
                      }
                    } 
                    else {
                      // SHOW ONLY TICKED GRAPHS FOR VIEW INFORMATION PAGE
                      if (this.state.all_print[vd.id]) {
                        if (vd.id !== "V014" && vd.id !== "V013" && vd.id !== "V011" && vd.id !== "V010" && vd.id !== "V006" && vd.id !== "V005" && this.state.chartDataMapped[vd.id]["data" + vd.id].datasets[0].data && this.state.chartDataMapped[vd.id]["data" + vd.id].datasets[0].data.length > 0 && !right_col_chart_drawn_arr.includes(vd.id)) {
                          return (
                            <div className="chartCanvas" key={i}>
                              <h4>{vd.id !== "V005" ? vd.display_name : "Blood Pressure"}</h4>
                              {!this.state.showOnlyTicked && !this.state.checkBoxDisable ? (
                                <div className="check_for_print">
                                  {!this.state.isNurseLoggined ? (
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      onChange={(e) => this.handleVitalGroupAPI(e, vd.id)}
                                      value={this.state.all_print[vd.id]}
                                      checked={this.state.all_print[vd.id]}
                                      className="custom-control-input pdf_print"
                                      name={vd.id + " _print"}
                                      autocomplete="off"
                                    />
                                    <label className="custom-control-label text-basic" htmlFor={vd.id + " _print"}>
                                      &nbsp;
                                      </label>
                                  </div>
                                  ):null}
                                </div>
                              ) : null}
                              <Line
                                data={this.state.chartDataMapped[vd.id]["data" + vd.id]}
                                options={this.state.chartDataMapped[vd.id]["options" + vd.id]}
                              />
                              {vd.id == "V005" ? (
                                <ul className="legend_unit_label chart-Chang">
                                  <li>
                                    <span className="gray"></span>Systolic
                                    </li>
                                  <li>
                                    <span className="blue"></span>Diastolic
                                    </li>
                                </ul>
                              ) : null}
                            </div>
                          );
                        }
                      }
                    }
                  }
                }) : null}
              </div>
            </div>
          ) : (
            <div className="col d-flex justify-content-center align-self-center mt-2" style={{ paddingTop: "50px" }}></div>
          )
        ) : this.state.dataLoaded && this.state.data.date.length === 0 ? (
          <div className="col d-flex justify-content-center align-self-center mt-2">
            No Data
          </div>
        ) : ("")}
      </>
    );
  }
}

export default ChartNew;
