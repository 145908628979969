import React, { Component } from "react";
import Axios from 'axios';
import { notification } from 'antd';
import LS_SERVICE from '../../utils/localStorage';
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import {
  LOADER,
  LOADER_RED,
  KEYFINDINGS_FILTER_SEQUENCING_LIST,
  KEYFINDINGS_FILTER_SEQUENCING_SAVE
} from '../../utils/constant';

const DragHandleTop = sortableHandle(() => <div class="btn_sequence"></div>);
const DragHandle = sortableHandle(() => <div class="btn_sequence"></div>);

const SortableContainerTop = sortableContainer(({children}) => {
  return <td colSpan='4' style={{padding: '0', borderTop: '0', borderRight: '0', borderLeft: '0'}}>{children}</td>;
});

const SortableContainer = sortableContainer(({children}) => {
  return <td colSpan='4' style={{padding: '0', borderTop: '0', borderBottom: '0'}}>{children}</td>;
});

const SortableItem = sortableElement(({ value, index1, parent_value, parent_index, checkBoxChange }) => {
  return (
    <table className="table table_border table_key_findings_sequence" style={{marginBottom: '0px'}}>
      <tr>
        <td style={{width: '61px', fontWeight: '400', borderBottom: '0', borderTop: '0', borderLeft: '0'}}> {value.display_order != 0 ? value.display_order : ''}</td>
        <td style={{fontWeight: '400', borderBottom: '0', borderTop: '0'}}> {value.display_name} {value.display_value ? '(' + value.display_value + ')' : ''}</td>
        <td style={{width: '88px', borderBottom: '0', borderTop: '0'}}>
          <DragHandle />
        </td>
        <td style={{width: '125px', borderBottom: '0', borderTop: '0', borderRight: '0'}}>
          <div className="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" className="custom-control-input" id={`select_${parent_index}_${index1}`}
              name={`select_${parent_index}_${index1}`}
              /* disabled={this.renderDisabled(dt)} */
              /* value={dt.id} */
              checked={value.display_order != 0}
              onChange={e => checkBoxChange(e, value, parent_value)} />
            <label className="custom-control-label" htmlFor={`select_${parent_index}_${index1}`}></label>
          </div>
        </td>
      </tr>
    </table>
  )
});

const SortableItemTop = sortableElement(({ value, parent_index, checkBoxChangeChild, sortEnd, checkBoxChangeParent }) => {
  return (
    <table className="table table_border table_key_findings_sequence" style={{marginBottom: '0px'}}>
      <tr>
        <td style={{borderBottom: '0', borderTop: '0'}}></td>
        <td className="text-bold" style={{borderBottom: '0', borderTop: '0'}}>{value.name}</td>
        <td style={{width: '88px', borderBottom: '0', borderTop: '0'}}>
          <DragHandleTop />
        </td>
        <td style={{width: '125px', borderBottom: '0', borderTop: '0'}}>
          <div className="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" className="custom-control-input" id={`select_all_${parent_index}`}
              name={`select_all_${parent_index}`}
              /* disabled={this.renderDisabled(dt)} */
              /* value={dt.id} */
              checked={value.display_order != 0}
              onChange={e => checkBoxChangeParent(e, value)} />
            <label className="custom-control-label" htmlFor={`select_all_${parent_index}`}></label>
          </div>
        </td>
      </tr>
      <tr>
        <SortableContainer helperClass='sortableHelper' onSortEnd={sortEnd} lockAxis={'y'} lockToContainerEdges={false} disableAutoscroll={true} useDragHandle>
          {value.vital_sign.map((c, j) => {
            return <SortableItem
              key={j}
              value={c}
              index={j}
              index1={j}
              parent_index={parent_index}
              parent_value={value}
              checkBoxChange={checkBoxChangeChild}
              collection={parent_index}
            />
          }
          )}
        </SortableContainer>
      </tr>
    </table>
  )
});

class KeyFindingsFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      KeyFindingsConstants: Object.values(props.keyFindingsConstants),
      KeyFindingsChecked: [],
      keyFindingsLoaded: false,
      spin_loading: false
    };
  }

  async componentDidMount() {
    await this.loadKeyFindingFilterData();
    await this.reArrangeKeyFindings();
    await this.reOrderList();
  }

  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return true;
  }

  loadKeyFindingFilterData = async () => {
    let details= LS_SERVICE.get('call_patient_detail');

    const keyFindingFilterPromise = await Axios.get(
      KEYFINDINGS_FILTER_SEQUENCING_LIST({doctor_id:details.doctor_id})
    );

    if (keyFindingFilterPromise.data.status) {
      this.setState({
        KeyFindingsChecked: keyFindingFilterPromise.data.data,
        keyFindingsLoaded: true
      });
    }
  };

  // To rerrange the sequence if a key findings is made a vital sign in the masters
  reArrangeKeyFindings = async () => {
    let newList = [];
    let display_order_group = 1;
    let display_order = 1;
    this.state.KeyFindingsChecked.forEach((val, j) => {
      let findex = this.state.KeyFindingsConstants.findIndex(x => x.id === val.finding_group_id);
      let found_atleast_one = false;
      if (findex !== -1){
        val.vital_sign.forEach((val2, k) => {
          let findex2 = this.state.KeyFindingsConstants[findex].vital_sign.findIndex(x => x.vital_id === val2.vital_id);
          if (findex2 !== -1){
            found_atleast_one = true;
          }
        });
      }
      if(found_atleast_one){
        let newval = {
          finding_group_id: val.finding_group_id
        };
        newval.vital_sign = [];
        val.vital_sign.forEach((val2, k) => {
          let findex2 = this.state.KeyFindingsConstants[findex].vital_sign.findIndex(x => x.vital_id === val2.vital_id);
          if (findex2 !== -1){
            newval.vital_sign.push({
              vital_id: val2.vital_id, 
              display_order: display_order
            });
            display_order++;
          }
        });
        newList.push({...newval, 'display_order': display_order_group});
        display_order_group++;
      }
    });
    await this.setState({KeyFindingsChecked: newList});
  }

  reOrderList = async () => {
    let newList = [];

    this.state.KeyFindingsConstants.forEach((val, j) => {
      let findex = this.state.KeyFindingsChecked.findIndex(x => x.finding_group_id === val.id);
      if (findex === -1){
        val.vital_sign.forEach((val2, k) => {
          val.vital_sign[k].display_order = 0;
        });
        newList.push({...val, 'display_order': 0});
      } else {
        val.vital_sign.forEach((val2, k) => {
          let findex2 = this.state.KeyFindingsChecked[findex].vital_sign.findIndex(x => x.vital_id === val2.vital_id);
          if (findex2 === -1){
            val.vital_sign[k].display_order = 0;
          } else {
            val.vital_sign[k].display_order = this.state.KeyFindingsChecked[findex].vital_sign[findex2].display_order;
          }
        });
        val.vital_sign = val.vital_sign.sort((a, b) => {
          if(a.display_order == 0 )
            return 1;
          if(b.display_order == 0 )
            return -1;
          return a.display_order - b.display_order
        });
        newList.push({...val, 'display_order': this.state.KeyFindingsChecked[findex].display_order});
      }
    })
    newList = newList.sort((a, b) => {
      if (a.display_order == 0)
        return 1;
      if (b.display_order == 0)
        return -1;
      return a.display_order - b.display_order
    });
    
    await this.setState({KeyFindingsConstants: newList});
  }

  handleOnCheckBoxChange = async (e, data, group) => {
    let findex = this.state.KeyFindingsChecked.findIndex(x => x.finding_group_id === group.id);

    if (findex === -1){
      await this.setState(prevState => {
        let obj = {
          finding_group_id: group.id,
          vital_sign: [
            {
              vital_id: data.vital_id
            }
          ]
        };
        const newItems = [...prevState.KeyFindingsChecked, obj];
        return {KeyFindingsChecked: newItems}
      });
    } else {
      let findex2 = this.state.KeyFindingsChecked[findex].vital_sign.findIndex(x => x.vital_id === data.vital_id);
      if (findex2 === -1){
        await this.setState(prevState => {
          let prevItems = [...prevState.KeyFindingsChecked];
          let obj = {
            vital_id: data.vital_id
          };
          prevItems[findex].vital_sign.push(obj);
          return {KeyFindingsChecked: prevItems}
        });
      } else {
        await this.setState(prevState => {
          let prevItems = [...prevState.KeyFindingsChecked];
          prevItems[findex].vital_sign = prevItems[findex].vital_sign.filter((d, i) => i !== findex2)
          return {KeyFindingsChecked: prevItems}
        });
        if(this.state.KeyFindingsChecked[findex].vital_sign.length == 0){
          await this.setState(prevState => ({
            KeyFindingsChecked: prevState.KeyFindingsChecked.filter((d, i) => i !== findex)
          }));
        }
      }
    }
    await this.displayOrderList();
    this.reOrderList();
  }

  handleOnCheckBoxChangeParent = async (e, group) => {
    let findex = this.state.KeyFindingsChecked.findIndex(x => x.finding_group_id === group.id);
    if (findex === -1){
      await this.setState(prevState => {
        let obj = {
          finding_group_id: group.id
        };
        let vital_sign = [];
        group.vital_sign.forEach((val, j) => {
          vital_sign.push({vital_id: val.vital_id});
        })
        obj.vital_sign = vital_sign;
        const newItems = [...prevState.KeyFindingsChecked, obj];
        return {KeyFindingsChecked: newItems}
      });
    } else {
      await this.setState(prevState => ({
        KeyFindingsChecked: prevState.KeyFindingsChecked.filter((d, i) => i !== findex)
      }));
    }
    await this.displayOrderList();
    this.reOrderList();
  }

  handleOnCheckBoxChangeSelectAll = async (select_all) => {
    let newList = [];
    if(!select_all){
      this.state.KeyFindingsConstants.forEach((val, i) => {
        let obj = {
          finding_group_id: val.id
        };
        let vital_sign = [];
        val.vital_sign.forEach((val2, j) => {
          vital_sign.push({vital_id: val2.vital_id});
        })
        obj.vital_sign = vital_sign;
        newList.push(obj)
      });
    }
    await this.setState({KeyFindingsChecked: newList});
    await this.displayOrderList();
    this.reOrderList();
  }

  displayOrderList = async () => {
    let newList = [];
    let display_order_group = 1;
    let display_order = 1;
    this.state.KeyFindingsChecked.forEach((val, j) => {
      val.vital_sign.forEach((val2, k) => {
        val.vital_sign[k].display_order = display_order;
        display_order++;
      });
      newList.push({...val, 'display_order': display_order_group});
      display_order_group++;
    });
    await this.setState({KeyFindingsChecked: newList});
  }

  onSortEnd = async ({oldIndex, newIndex, collection}) => {
    await this.setState(prevState => {
      let prevItems = [...prevState.KeyFindingsConstants];
      let prevItemsChecked = [...prevState.KeyFindingsChecked];
      if(prevItemsChecked[collection]){
        prevItemsChecked[collection].vital_sign = arrayMove(
          prevState.KeyFindingsChecked[collection].vital_sign,
          oldIndex,
          newIndex,
        );
      }
      prevItems[collection].vital_sign = arrayMove(
        prevState.KeyFindingsConstants[collection].vital_sign,
        oldIndex,
        newIndex,
      );
      return {KeyFindingsConstants: prevItems, KeyFindingsChecked: prevItemsChecked}
    });
    await this.displayOrderList();
    this.reOrderList();
  };

  onSortEndTop = async ({oldIndex, newIndex}) => {
    await this.setState(prevState => {
      let prevItems = [...prevState.KeyFindingsConstants];
      let prevItemsChecked = [...prevState.KeyFindingsChecked];
      prevItemsChecked = arrayMove(
        prevState.KeyFindingsChecked,
        oldIndex,
        newIndex,
      );
      prevItems = arrayMove(
        prevState.KeyFindingsConstants,
        oldIndex,
        newIndex,
      );
      return {KeyFindingsConstants: prevItems, KeyFindingsChecked: prevItemsChecked}
    });
    await this.displayOrderList();
    this.reOrderList();
  };

  handleModalSubmit = async ( e ) => {

    const { spin_loading } = this.state;
    this.setState({
      spin_loading: !spin_loading
    });
    const PARAMS = {
      key_findings: this.state.KeyFindingsChecked
    };

    await Axios.post(KEYFINDINGS_FILTER_SEQUENCING_SAVE, PARAMS)
    .then(async success => {
      if (success?.data?.status == true) {
        const { spin_loading } = this.state;
        this.setState({
          spin_loading: !spin_loading
        });
        this.props.handleModalPopup(e, "chartParamerModel");
        this.props.updateParentState(this.state.KeyFindingsChecked);
      } else {
        notification.error({
          message: success?.data?.message,
          placement: 'topRight'
        });
      }
    }).catch(err => console.log(err));
  };

  render() {

    let select_all = true;

    this.state.KeyFindingsConstants.map((g, index) => {
      if(g.display_order != 0){
        g.vital_sign.map((vital) => {
          if(vital.display_order != undefined && vital.display_order == 0){
            select_all = false;
          }
        });
      } else {
        select_all = false;
      }
    })

    return (
      <React.Fragment>
        { !this.state.keyFindingsLoaded ? <div className={`w-100 align-items-center justify-content-center mt-4 d-flex`}>{LOADER_RED}</div> : 
        <>
          <table className="table table_border table_key_findings_sequence emrfrm">
            <tr>
              <td className="text-center">S NO.</td>
              <td>Parameters</td>
              <td className="text-center">Sequence</td>
              <td style={{width: '126px'}}>
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input type="checkbox" className="custom-control-input" id='select_all'
                    name='select_all'
                    /* disabled={this.renderDisabled(dt)} */
                    /* value={dt.id} */
                    checked={select_all}
                    onChange={e => this.handleOnCheckBoxChangeSelectAll(select_all)} />
                  <label className="custom-control-label" htmlFor='select_all'> Select All</label>
                </div>
              </td>
            </tr>
            <tr>
              <SortableContainerTop helperClass='sortableHelper' onSortEnd={this.onSortEndTop} lockAxis={'y'} lockToContainerEdges={false} useDragHandle> 
                {this.state.KeyFindingsConstants.map((g, index) => {
                  return ( <SortableItemTop
                    key={index}
                    value={g}
                    index={index}
                    parent_index={index}
                    checkBoxChangeChild={this.handleOnCheckBoxChange}
                    sortEnd={this.onSortEnd}
                    checkBoxChangeParent={this.handleOnCheckBoxChangeParent}
                    /* collection={index} */
                  />
                  )}
                )}
              </SortableContainerTop>
            </tr>
          </table>
          <div className="text-center justify-content-center">
            <button
              type="submit"
              className="btn btn-primary text-uppercase ml-2"
              disabled={this.state.spin_loading}
              onClick={(e) => this.handleModalSubmit(e)}
            >
              APPLY{this.state.spin_loading ? LOADER : ''}
            </button>
          </div> 
        </> }
      </React.Fragment>
    );
  }
}

export default KeyFindingsFilters;
