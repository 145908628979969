import React, { Component, createRef } from 'react';
import qs from 'qs';
import { withRouter } from "react-router-dom";
import AsideLeft from '../../../components/aside/asideleft';
import Axios from "axios";
import {
    ALLERGIES_N_CONDITIONS, ALLERGY_INTENSITY_IC, ALLERGY_INFORMED_BY, DURATION_TYPE, MEDICATION_ON, LOADER_RED, HANDLEVALIDANTMESSAGESPATIENTAGE,
    HOMECARE_REFERENCE_TYPE, OBJECTIVE_STORE, LOADER_BLACK, ALLERGIES_DROPDOWN, ALLERGY_TAB_STATUS, LOADER, INFORMED_BY, CONDITION_SEARCH, HOMECARE_DETAIL, ALLERGY_TAB_TYPE, HOMECARE_STORE_CURRENT_MEDICINE, HOMECARE_CURRENT_MEDICINE_GET, HC_GOBACK, CONDITION_ADD_MASTER, ALLERGY_ADD_MASTER
} from '../../../utils/constant';
import LS_SERVICE from '../../../utils/localStorage';
import { headerService$ } from '../../../utils/rxjs-sharing';
import { Form, Modal, Input, Radio, Button, Select, notification, AutoComplete } from 'antd';
import moment from 'moment';
import { AntSelectLabels, FixedCta, jQueryLabels, onlynumbers } from '../../../utils/jQueryLabels';
import icEdit from "../../../assets/images/ic-edit-copy.svg";

const { Option } = Select;

class HomecareAllergies extends Component {

    constructor(props) {
        super(props)

        this.state = {
            patient: null,
            booking_status_code: null,
            spin_loading: false,
            allergies: [],
            conditions: [],
            current_medicines: "",
            conditionModal: false,
            allergyModal: false,
            formIsFetching: false,
            allergy_food: [],
            allergy_other: [],
            allergyList: [],
            allergy_selected: '',
            allergy_selected_data: {},
            allergy_duration: '',
            allergy_durationType: '',
            allergy_active_tab: ALLERGY_TAB_STATUS[0],
            formModeEdit: false,
            condition_duration: "",
            condition_duration_type: '',
            duration: '',
            durationType: 3,
            condition_name: '',
            formFieldEntryId: null,
            condition_selected: {},
            allergyAlreadyExistName: '',
            allergyAlreadyExistModal: false,
            dataLoaded: false,
            showAddConditionButton: false,
            disableAddConditionButton: false,
            showAddAllergyButton: false,
            disableAddAllergyButton: false,
            condition_duration_type_required: false,
            allergy_durationType_required: false,
        }

        this.conditionFormRef = createRef();
        this.allergyFormRef = createRef();
    }

    componentDidMount() {
        Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");

        const { match: { params } } = this.props;
        const { hospital_id, patient_id, reference_id } = params;

        this.setState({
            hospital_id,
            patient_id,
            reference_id,
        }, () => {
            if (LS_SERVICE.has('hospitalConfigData')) {
                const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
                let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
                headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' }); // IMPORTANT TO HIT AS PER THE VIEW
            }
            this.getPatientData();
        });

        jQueryLabels();
        FixedCta();
        onlynumbers();
    }

    getPatientData = () => {
        const { reference_id } = this.state;

        Axios.get(HOMECARE_DETAIL({ booking_id: reference_id, is_called_patient: 0 }))
            .then(success => {
                const { appointment } = success.data;
                const { patient } = appointment;
                patient.age = moment().diff(patient.dob, 'years');

                this.setState({
                    patient: patient,
                    appointment_status: appointment.booking_status_code
                })
                this.getAllergies();
                this.handleAllergyDropDownData();
            })
    }

    getAllergies = () => {
        const { patient_id, reference_id } = this.state;

        const allergiesncontUri = Axios.get(ALLERGIES_N_CONDITIONS({ patient_id }))
        const currentMedUri = Axios.get(HOMECARE_CURRENT_MEDICINE_GET({ patient_id, booking_id: reference_id }))

        Axios.all([allergiesncontUri, currentMedUri])
            .then(res => {

                const { allergies,
                    conditions } = res[0].data.data?.details;

                const newAllergies = {
                    drug: [],
                    food: [],
                    others: []
                };
            if(allergies.patientAllergies.length > 0){
                allergies.patientAllergies.forEach(all => {
                    // if (newAllergies.hasOwnProperty(all.allergyType)) {
                    newAllergies[all.allergyType].push(all);
                    // } else {
                    //     newAllergies[all.allergyType] = [all];
                    // }
                })
            }

                const current_medicines = res[1].data.current_medicines?.current_medicines || "";

                this.setState({
                    allergies:allergies.patientAllergies,
                    allergies_grouped: newAllergies,
                    conditions:conditions.patientConditions,
                    current_medicines,
                    dataLoaded: true
                })
            })
    }

    handleAllergyDropDownData = async _ => {
        const foodPromise = await Axios.get(ALLERGIES_DROPDOWN({ type: 'food' }));
        const otherPromise = await Axios.get(ALLERGIES_DROPDOWN({ type: 'others' }));

        if (foodPromise.status) {
            this.setState({
                allergy_food: foodPromise.data.result
            })
        }

        if (otherPromise.status) {
            this.setState({
                allergy_other: otherPromise.data.result
            })
        }
    }

    // goBack = (event) => {
    //     event.preventDefault();
    //     this.props.history.goBack();
    // }

    handleModalPopup = async (e, popup, data = null) => {
        e !== null && e.preventDefault();

        await this.setState({
            [popup]: !this.state[popup],
            fromFieldId: -1
        });

        const { conditionModal, allergyModal, allergyAlreadyExistName, allergyAlreadyExistModal } = this.state;

        if (conditionModal) {
            this.setState({
                onMedication: "",
                medication: "0"
            })
            this.conditionFormRef.current.resetFields();
            this.conditionFormRef.current.setFieldsValue({
                condition_duration_type: "",
                onMedication: "",
                medication: "0",
                medicineName: ''
            })
        }

        if (allergyModal) {
            this.allergyFormRef.current.resetFields();
            this.allergyFormRef.current.setFieldsValue({
                allergy_selected: (data?.allergy_name) ? data.allergy_name : '',
                reaction: [],
                intensity: "",
                informedby: "1",
                allergy_durationType: ""
            });
        }

        if (data !== null) {
            if (allergyModal) {
                this.setState({
                    formModeEdit: true,
                    fromFieldId: data.id,
                    allergy_selected_data: data,
                    allergy_active_tab: data.allergyType.toUpperCase() === 'DRUG' ? 'DRUGS' : data.allergyType.toUpperCase(),
                    formFieldEntryId: data.allergyId,
                    allergy_duration: data.duration,
                    allergy_durationType: data.durationType,
                    allergy: data.allergy_name
                })

                if (allergyAlreadyExistName === "") {
                    this.allergyFormRef.current.setFieldsValue({
                        allergy_selected: data.allergy_name,
                        reaction: data.reaction ? data.reaction?.split(",") : [],
                        intensity: JSON.stringify(data.intensity),
                        informedby: JSON.stringify(data.informedBy),
                        allergy_duration: data.duration == null ? null : JSON.stringify(data.duration),
                        allergy_durationType: JSON.stringify(data.durationType)
                    });
                }
            }

            if (conditionModal) {
                this.setState({
                    actionText: "Update",
                    formModeEdit: true,
                    formFieldEntryId: data.conditionId,
                    fromFieldId: data.id,
                    onMedication: JSON.stringify(data.onMedication),
                    medication: JSON.stringify(data.onMedication),
                    medicineName: data.medicineName,
                    condition_duration_type: JSON.stringify(data.durationType),
                    condition: data.condition_name
                })

                this.conditionFormRef.current.resetFields();

                this.conditionFormRef.current.setFieldsValue({
                    condition_name: data.condition_name,
                    condition_duration: (data.duration > 0) ? data.duration : '',
                    condition_duration_type: JSON.stringify(data.durationType),
                    onMedication: JSON.stringify(data.onMedication),
                    medication: JSON.stringify(data.onMedication),
                    medication_name: data.medicineName
                })
            }

            if (allergyAlreadyExistModal) {
                this.setState({
                    formModeEdit: true,
                    fromFieldId: data.id,
                    formFieldEntryId: data.allergyId,
                    allergy_active_tab: data.allergyType.toUpperCase() === 'DRUG' ? 'DRUGS' : data.allergyType.toUpperCase(),
                }, () => this.handleAllergyAddSubmit())
            }

            setTimeout(function () {
                AntSelectLabels();
            }, 100);
        } else if (this.state.formModeEdit) {
            this.setState({
                formModeEdit: false,
            })
        }

        if (!conditionModal) {
            this.setState({
                condition: '',
                showAddConditionButton: false,
                disableAddConditionButton: false,
            });
        }

        if (!allergyModal) {
            this.setState({
                allergy: '',
                showAddAllergyButton: false,
                disableAddAllergyButton: false,
            });
        }
    }

    handleConditionsAddSubmit = async _ => {

        const { condition_name, condition_duration, condition_duration_type, onMedication, medicineName } = this.conditionFormRef.current.getFieldValue();
        const { formFieldEntryId, formModeEdit, condition_selected_data, fromFieldId, patient } = this.state;
        const { patient_id, reference_id } = patient;

        const PARAMS = {
            type: 'condition',
            objective_details: [{
                condition: formModeEdit ? formFieldEntryId : condition_selected_data.id,
                condition_name: formModeEdit ? condition_name : condition_selected_data.name,
                condition_duration,
                condition_duration_type,
                medication: onMedication,
                medicine_name: onMedication === '1' ? medicineName : "",
                id: fromFieldId
            }],
            reference_type: HOMECARE_REFERENCE_TYPE,
            hospital_id: LS_SERVICE.get("slot_hospital_id"),
            speciality_id: LS_SERVICE.get("speciality_id"),
            reference_id,
            patient_id
        }

        const conditionAddDataPromise = await Axios.post(OBJECTIVE_STORE, qs.stringify(PARAMS))

        if (conditionAddDataPromise.data.status) {
            this.handleExistingConditionData();
            this.handleModalPopup(null, 'conditionModal');

            notification.success({
                message: conditionAddDataPromise.data.message,
                placement: 'topRight'
            });

            this.conditionFormRef.current.resetFields();
        }
    }


    handleMaxLimit = type => {
        const { patient } = this.state;
        if(type == ''){
            type= 3;
        }

        if (patient !== null) {
            const sD = moment(patient.dob);
            const eD = moment();
            const maxVal = eD.diff(sD, DURATION_TYPE[type]);
            return maxVal;
        }
    }

    fetchConditionData = async search => {
        this.setState({
            conditionList: [],
            formIsFetching: !this.state.formIsFetching,
            condition: search,
        });

        const conditionDataPromise = await Axios.post(CONDITION_SEARCH, qs.stringify({ search }))

        if (conditionDataPromise.status) {
            let { result } = conditionDataPromise.data;
            this.setState({
                conditionList: result,
                formIsFetching: !this.state.formIsFetching,
                showAddConditionButton: (search.length > 2 && result.length == 0) ? true : false,
            });
        }
    }

    handleAddCondition = async () => {
        const conditionAddPromise = await Axios.post(CONDITION_ADD_MASTER, { text: this.state.condition });
        this.setState({
            disableAddConditionButton: true
        });

        if (conditionAddPromise.status == 200) {
            let conditionList = this.state.conditionList;
            conditionList.push(conditionAddPromise.data);
            this.setState({
                disableAddConditionButton: false,
                showAddConditionButton: false,
                conditionList: conditionList,
            })
            this.handleOnConditionChange(conditionAddPromise.data.id, { children: conditionAddPromise.data.name, value: String(conditionAddPromise.data.id), dataid: conditionAddPromise.data });
        }
    }

    handleAddAllergy = async (type) => {
        const { hospital_id } = this.state;
        const allergyAddPromise = await Axios.post(ALLERGY_ADD_MASTER, { hospital_id, type: type, text: this.state.allergy });
        this.setState({
            disableAddAllergyButton: true
        });

        if (allergyAddPromise.status == 200) {
            if (type === 'drug') {
                let allergyList = this.state.allergyList;
                allergyList.push(allergyAddPromise.data);
                this.setState({
                    disableAddAllergyButton: false,
                    showAddAllergyButton: false,
                    allergyList: allergyList,
                })
            }

            if (type === 'food') {
                let allergy_food = this.state.allergy_food;
                allergy_food.push(allergyAddPromise.data);
                this.setState({
                    disableAddAllergyButton: false,
                    showAddAllergyButton: false,
                    allergy_food: allergy_food,
                })
            }

            if (type === 'others') {
                let allergy_other = this.state.allergy_other;
                allergy_other.push(allergyAddPromise.data);
                this.setState({
                    disableAddAllergyButton: false,
                    showAddAllergyButton: false,
                    allergy_other: allergy_other,
                })
            }

            this.handleOnAllergyChange(allergyAddPromise.data.id, { children: allergyAddPromise.data.name, value: String(allergyAddPromise.data.id), dataid: allergyAddPromise.data });
        }
    }

    fetchAllergyData = async search => {
        this.setState({ allergyList: [], formIsFetching: !this.state.formIsFetching, allergy: search });

        const allergyDataPromise = await Axios.get(ALLERGIES_DROPDOWN({ search, type: 'drug' }))

        if (allergyDataPromise.status) {
            let { result } = allergyDataPromise.data;
            this.setState({ allergyList: result, formIsFetching: !this.state.formIsFetching, showAddAllergyButton: (search.length > 2 && result.length == 0) ? true : false, });
        }
    }

    fetchAllergyFoodData = async (search) => {
        await this.setState({
            allergy_food: [],
            formIsFetching: !this.state.formIsFetching,
            allergy: search
        });

        const allergyDataPromise = await Axios.get(ALLERGIES_DROPDOWN({ search, type: "food" }));

        if (allergyDataPromise.status) {
            let { result } = allergyDataPromise.data;
            this.setState({
                allergy_food: result,
                formIsFetching: !this.state.formIsFetching,
                showAddAllergyButton: (search.length > 2 && result.length == 0) ? true : false,
            });
        }
    };

    fetchAllergyOthersData = async (search) => {
        await this.setState({
            allergy_other: [],
            formIsFetching: !this.state.formIsFetching,
            allergy: search
        });

        const allergyDataPromise = await Axios.get(ALLERGIES_DROPDOWN({ search, type: "others" }));

        if (allergyDataPromise.status) {
            let { result } = allergyDataPromise.data;
            this.setState({
                allergy_other: result,
                formIsFetching: !this.state.formIsFetching,
                showAddAllergyButton: (search.length > 2 && result.length == 0) ? true : false,
            });
        }
    };

    handleAllergyTab = (e, TAB) => {
        e.preventDefault();

        this.setState({
            allergy_active_tab: TAB,
            allergy_selected: '',
            allergy_selected_data: {}
        }, () => {
            this.allergyFormRef.current.setFieldsValue({
                allergy_selected: '',
                reaction: [],
                intensity: "",
                informedby: "1"
            });
        })
    }

    radioChange = (event, state) => {
        this.setState({
            [state]: event.target.value
        })
    }

    handleOnReactionChange = (e) => {
        console.log(e.target.value)
    }

    handleAntFormOnChange = d => {
        let { name, value } = d.currentTarget.hasOwnProperty('name') ? d.currentTarget : d.target;

        this.setState({
            [name]: value
        })
    }

    handleOnChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value
        });

        if (name == 'condition_duration') {
            this.conditionFormRef.current.setFieldsValue({
                condition_duration_type: (name == 'condition_duration' && value != '' && value != null) ? "3" : "",
            });
        }
        if (name == 'duration') {
            this.allergyFormRef.current.setFieldsValue({
                allergy_durationType: (name == 'duration' && value != '' && value != null) ? "3" : ""
            });
        }
    }

    handleConditionAddSubmit = async _ => {

        const { condition_name, medication, medication_name, condition_duration, condition_duration_type } = this.conditionFormRef.current.getFieldValue();
        const { reference_id, patient_id, fromFieldId, condition_selected, formFieldEntryId, formModeEdit } = this.state;

        const PARAMS = {
            type: 'condition',
            objective_details: [{
                condition: formModeEdit ? formFieldEntryId : condition_selected.id,
                condition_name,
                condition_duration,
                condition_duration_type,
                medication,
                medicine_name: medication == 1 ? medication_name : "",
                id: fromFieldId
            }],
            reference_type: HOMECARE_REFERENCE_TYPE,
            hospital_id: LS_SERVICE.get("slot_hospital_id"),
            speciality_id: LS_SERVICE.get("speciality_id"),
            reference_id,
            patient_id
        }

        this.setState({
            spin_loading: !this.state.spin_loading
        })

        const conditionDataPromise = await Axios.post(OBJECTIVE_STORE, qs.stringify(PARAMS))

        if (conditionDataPromise.data.status) {
            const data = conditionDataPromise;
            this.getAllergies();
            this.handleModalPopup(null, 'conditionModal');
            notification.success({
                message: conditionDataPromise.data.message,
                placement: 'topRight'
            })
            this.setState({
                spin_loading: !this.state.spin_loading
            })
        }
    }

    handleOnConditionChange = (condition_name, props) => {
        const condition_selected = {}
        Object.assign(condition_selected, props.dataid);
        this.setState({
            condition_name,
            condition_selected,
            condition: props.dataid.name
        })

        this.conditionFormRef.current.setFieldsValue({
            condition_name: condition_name,
        });

        document.getElementById("condition_duration").focus();
        AntSelectLabels();
    }

    handleAllergyAddSubmit = async _ => {

        const { allergy_selected, reaction, intensity, informedby, allergy_duration, allergy_durationType } = this.allergyFormRef.current.getFieldValue();
        const { allergy_selected_data, allergy_active_tab, fromFieldId, reference_id, formModeEdit, formFieldEntryId, allergies, allergyAlreadyExistName, patient_id } = this.state;
        if(allergies.length >0){
        if (allergies.find(all => all?.allergy_name?.toLowerCase() === allergy_selected?.toLowerCase()) !== undefined && !formModeEdit) {
            // notification.info({
            //     message: 'Value already added, please try edit',
            //     placement: 'topRight'
            // })
            if (allergyAlreadyExistName === "") {
                const data = allergies.find(all => all?.allergy_name?.toLowerCase() === allergy_selected.toLowerCase());
                this.setState({
                    allergyAlreadyExistName: allergy_selected,
                    allergy_selected_data: { ...data }
                }, () => this.handleModalPopup(null, 'allergyAlreadyExistModal'))
                return;
            }
        }
         }

        if (reaction.toString().length > 250) {
            notification.error({
                message: 'Reaction limit exceeded',
                placement: 'topRight'
            })
            return;
        }

        const PARAMS = {
            type: 'allergy',
            objective_details: [[{
                allergy: formModeEdit ? formFieldEntryId : allergy_selected_data.id,
                allergy_name: allergy_selected_data.long,
                allergy_type: ALLERGY_TAB_TYPE[allergy_active_tab],
                informed_by: informedby,
                reaction: reaction.toString(),
                intensity,
                duration: allergy_duration,
                durationType: allergy_durationType,
                id: fromFieldId
            }]],
            reference_type: HOMECARE_REFERENCE_TYPE,
            hospital_id: LS_SERVICE.get("slot_hospital_id"),
            speciality_id: LS_SERVICE.get("speciality_id"),
            reference_id,
            patient_id
        }

        // if (formModeEdit) {
        //     PARAMS.objective_details[0][0].allergy = formFieldEntryId;
        // }

        this.setState({
            spin_loading: !this.state.spin_loading
        }, () => console.log(this.state.spin_loading))

        const allergyPromise = await Axios.post(OBJECTIVE_STORE, qs.stringify(PARAMS)).catch(err => {
            const res = err.response;
            if (res.status === 500) {
                notification.error({
                    message: err?.response?.data?.err,
                    placement: 'topRight'
                })
                this.setState({
                    spin_loading: !this.state.spin_loading
                })
            }
        })

        if (allergyPromise?.status) {
            this.getAllergies();
            this.handleModalPopup(null, 'allergyModal');
            const { data } = allergyPromise;
            notification.success({
                message: data.message,
                placement: 'topRight'
            })
            this.setState({
                spin_loading: !this.state.spin_loading,
                allergyAlreadyExistName: ''
            }, () => console.log(this.state.spin_loading))
        }
    }


    handleOnAllergyChange = (allergy_selected, props) => {
        const allergy_selected_data = {};
        Object.assign(allergy_selected_data, props.dataid)
        this.setState({
            allergy_selected,
            allergy_selected_data
        }, () => { AntSelectLabels(); })

        document.getElementById('reaction').focus();
    }

    handleCurrentMedicSubmit = _ => {
        const { current_medicines, reference_id, patient_id, is_doctor } = this.state;

        const PARAMS = {
            patient_id,
            booking_id: reference_id,
            current_medicines
        }

        Axios.post(HOMECARE_STORE_CURRENT_MEDICINE({ is_doctor }), PARAMS)
            .then((Response) => {
                const { current_medicines } = this.state;

                notification.success({
                    message: Response.data.message
                })

                this.setState({
                    current_medicines
                })
            })
            .catch((error) => {
                if (error && error.response && error.response.status === 401) {
                    LS_SERVICE.clear();
                    this.props.history.push("/");
                }
            });
    }

    render() {

        const { allergies, allergies_grouped, conditions, current_medicines, condition, conditionList, allergy_selected, allergyList, allergy_food, allergy_other, formModeEdit, dataLoaded, allergyDataAvailable, allergy } = this.state;

        return (
            <>
                <AsideLeft isHomecare={true} />

                <div className="content-wrapper">

                    <div className="container-fluid">
                        {/* <h5 className="py-2 text-dark">Allergies and Existing Conditions</h5> */}
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="float-left">
                                    <h1 className="page-title mb-0">Allergies &amp; Conditions</h1>
                                </div>
                                <div className="float-right">
                                    <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={e => HC_GOBACK(e, this.props, this.state)}>BACK TO PATIENT</button>
                                </div>
                            </div>
                            {dataLoaded && (
                                <div className="allergies-wrapper col-12 allergies">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="allergy-mapping card rounded-0">
                                                <div className="p-3 am-knownallergy">
                                                    <h6 className="text-uppercase text-dark">KNOWN ALLERGIES</h6>
                                                    {allergies.length > 0 ? (
                                                        <div className="known-allergies allergy-listwrapper">
                                                            {Object.keys(allergies_grouped)?.map(allergy => {
                                                                return (
                                                                    <div className="my-3">
                                                                        {allergies_grouped[allergy].length > 0 && (
                                                                            <h5 className="textis-primary text-capitalize">{allergy}</h5>
                                                                        )}
                                                                        {allergies_grouped[allergy].map(item => (
                                                                            <div className="allergies_list_item mt-3 border-bottom pb-3" key={item.id}>
                                                                                {(item?.source !== 'patient' && this.state.appointment_status !== 2) &&
                                                                                    <img className="edit-img allergy-img" onClick={e => this.handleModalPopup(e, 'allergyModal', item)} src={icEdit} alt="edit-icon" />}
                                                                                <div className="d-flex justify-content-between">
                                                                                    <p className="mb-0">{item.allergy_name}</p>
                                                                                    {/* <p className="text-danger mb-0">{ALLERGY_INFORMED_BY[item.informedBy]}</p> */}
                                                                                    <p className={item.informedBy == 1 ? 'text-danger  mb-0' : 'text-success mb-0'}>{ALLERGY_INFORMED_BY[item.informedBy]}</p>
                                                                                </div>
                                                                                <div className="d-flex justify-content-between">
                                                                                    <p className="text-muted mb-0">{item.reaction}</p>
                                                                                    <i className={`${ALLERGY_INTENSITY_IC[item.intensity]} float-right`} />
                                                                                </div>
                                                                                <p className={`flex-row font-weight-bold text-grey mb-1 ${item.comments !== null ? 'd-flex' : 'd-none'}`}>
                                                                                    <span className="d-block font-weight-light">Comments</span>: {item.comments}</p>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    ) : (
                                                        allergies === undefined ? (
                                                            <div className={`w-100 align-items-center justify-content-center mt-4 d-flex`}>
                                                                {LOADER_RED}
                                                            </div>
                                                        ) : (
                                                            <h5 className="d-flex justify-content-center mt-5">NO KNOWN ALLERGIES</h5>
                                                        )
                                                    )}

                                                </div>
                                                <div className="allergy-severity severity bg-grey border-top d-flex w-100 justify-content-around align-items-center py-2">
                                                    <p className="mb-0 text-dark">Severity</p>
                                                    <p className="mb-0 d-flex text-dark align-items-baseline">
                                                        <i className="icon-severity-low" /> Low
                                                    </p>
                                                    <p className="mb-0 d-flex text-dark align-items-baseline">
                                                        <i className="icon-severity-medium" /> Medium
                                                    </p>
                                                    <p className="mb-0 d-flex text-dark align-items-baseline">
                                                        <i className="icon-severity-high" /> High
                                                    </p>
                                                </div>
                                                {this.state.appointment_status !== 2 && (
                                                    <div className="allergy-button-wrapper">
                                                        <button className="btn btn-outline-secondary"
                                                            disabled={this.state.appointment_status === 2}
                                                            onClick={e => this.handleModalPopup(e, 'allergyModal')}>
                                                            ADD ALLERGY
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="allergy-mapping card rounded-0">
                                                <div className="p-3">
                                                    <h6 className="text-uppercase text-dark">EXISTING CONDITIONS</h6>
                                                    {conditions?.length > 0 ? (
                                                        <div className="basic-list-allergies allergy-listwrapper">
                                                            {conditions?.map(item => (
                                                                <div className="allergies_list_item mt-4 border-bottom pb-3" key={item.id}>
                                                                    <h5 className="text-primary">{item.condition_name}</h5>
                                                                    {(item?.source !== 'patient' && this.state.appointment_status !== 2) &&
                                                                        <img className="edit-img" onClick={e => this.handleModalPopup(e, 'conditionModal', item)} src={icEdit} alt="edit-icon" />}
                                                                    <div className="condition-item">
                                                                        <p className="d-flex flex-row text-grey mb-1">
                                                                            <span className="d-block font-weight-light" style={{ minWidth: '150px' }}>Duration</span>:
                                                                            <span className="ml-1 textis-black">{(item.duration) ? `${item.duration} ${DURATION_TYPE[item.durationType]}` : '-'}</span>
                                                                        </p>
                                                                        <p className="d-flex flex-row text-grey mb-1">
                                                                            <span className="d-block font-weight-light" style={{ minWidth: '150px' }}>On Medication</span>:
                                                                            <span className="ml-1 textis-black">{(MEDICATION_ON[item.onMedication]) ? MEDICATION_ON[item.onMedication] : '-'}</span>
                                                                        </p>
                                                                        <p className={`flex-row text-grey mb-1 ${item.onMedication === 1 ? 'd-flex' : 'd-none'}`}>
                                                                            <span className={`font-weight-light`} style={{ minWidth: '150px' }}>Medication Name</span>:
                                                                            <span className="ml-1 textis-black">{item.medicineName}</span>
                                                                        </p>
                                                                        <p className={`flex-row text-grey mb-1 ${item.comments !== null ? 'd-flex' : 'd-none'}`}>
                                                                            <span className="d-block font-weight-light" style={{ minWidth: '150px' }}>Comments</span>:
                                                                            <span className="ml-1 textis-black">{item.comments}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        conditions === undefined ? (
                                                            <div className={`w-100 align-items-center justify-content-center mt-4 d-flex`}>
                                                                {LOADER_RED}
                                                            </div>
                                                        ) : (
                                                            <h5 className="d-flex justify-content-center mt-5">NO EXISITING CONDITIONS</h5>
                                                        )
                                                    )}
                                                    {this.state.appointment_status !== 2 && (
                                                        <div className="allergy-button-wrapper">
                                                            <button className="btn btn-outline-secondary"
                                                                disabled={this.state.appointment_status === 2}
                                                                onClick={e => this.handleModalPopup(e, 'conditionModal')}>
                                                                ADD CONDITION
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="allergy-mapping card rounded-0">
                                                <div className="p-3">
                                                    <h6 className="text-uppercase text-dark">CURRENT MEDICATION</h6>
                                                    <textarea className="w-100 txt-hgt"
                                                        placeholder="Enter medication here"
                                                        name="current_medicines"
                                                        value={current_medicines}
                                                        onChange={this.handleOnChange} style={{ border: '0px' }}>
                                                    </textarea>
                                                    {this.state.appointment_status !== 2 && (
                                                        <div className="allergy-button-wrapper">
                                                            <button className="btn btn-outline-secondary"
                                                                disabled={current_medicines?.length === 0 || this.state.appointment_status === 2}
                                                                onClick={this.handleCurrentMedicSubmit}>
                                                                SAVE
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>


                    {!dataLoaded && (
                        <div className="col-12">
                            <div className="text-center">
                                {LOADER_RED}
                            </div>
                        </div>
                    )}
                </div>

                {/* || CONDITION MODAL */}
                <Modal
                    destroyOnClose={true}
                    title={`${formModeEdit ? 'Edit ' : 'Add '} Condition`}
                    visible={this.state.conditionModal}
                    onCancel={(e) => this.handleModalPopup(e, 'conditionModal')}
                    style={{ top: 40 }}
                    bodyStyle={{ paddingBottom: '10px' }}
                    className="emrfrm"
                    width="530px"
                    footer={[
                        <div className="text-center">
                            <Button form="conditionForm" type="primary" htmlType="submit" disabled={this.state.spin_loading}>
                                SAVE{this.state.spin_loading ? LOADER : ''}
                            </Button>
                        </div>
                    ]}
                >
                    <div className="row">
                        <div className="col-12">

                            <Form
                                id="conditionForm"
                                ref={this.conditionFormRef}
                                validateMessages={HANDLEVALIDANTMESSAGESPATIENTAGE}
                                onFinish={this.handleConditionAddSubmit}
                                layout="vertical"
                            >
                                <div className="antSelectItem mt-2">
                                    <div className="form-group add_buttons font-col">
                                        <Form.Item
                                            name="condition_name"
                                            label="Condition"
                                            rules={[{ required: true}]}
                                        >
                                            {(this.state.showAddConditionButton) ?
                                                <a className={"btn btn-outline-primary"} disabled={this.state.disableAddConditionButton} onClick={() => this.handleAddCondition()}>ADD</a>
                                                :
                                                null
                                            }
                                            <AutoComplete
                                                id="condition_name"
                                                name="condition_name"
                                                showSearch
                                                placeholder=" "
                                                showArrow={!this.state.showAddConditionButton}
                                                value={condition}
                                                notFoundContent={this.state.formIsFetching ? LOADER_BLACK : null}
                                                loading={this.state.formIsFetching}
                                                filterOption={false}
                                                onSearch={this.fetchConditionData}
                                                onSelect={this.handleOnConditionChange}
                                                style={{ width: "100%" }}
                                                disabled={this.state.formModeEdit}
                                            >
                                                {/* <Select
                                                showSearch
                                                autoFocus
                                                value={condition}
                                                notFoundContent={this.state.formIsFetching ? LOADER_BLACK : null}
                                                loading={this.state.formIsFetching}
                                                filterOption={false}
                                                onSearch={this.fetchConditionData}
                                                onChange={this.handleOnConditionChange}
                                                style={{ width: '100%' }}
                                                disabled={this.state.formModeEdit}
                                            > */}
                                                {conditionList !== undefined && conditionList.length > 0 ? conditionList.map(d => (
                                                    <Option key={d.id} dataid={d}>{d.name}</Option>
                                                )) : null}
                                                {/* </Select> */}
                                            </AutoComplete>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="antInputItem">
                                    <Form.Item
                                        name="condition_duration"
                                        label="Duration"
                                        className="onlynumbers"
                                        rules={[{
                                            // required: true,
                                            type: 'number',
                                            min: 0,
                                            max: this.handleMaxLimit(this.state.condition_duration_type),
                                            transform: function (value) {
                                                return value === "" || value === undefined ? value : +value
                                            }
                                        }]}>
                                        <Input maxLength="2" autoComplete="off" name="condition_duration" onChange={this.handleOnChange} />
                                    </Form.Item>
                                </div>
                                <Form.Item
                                    name="condition_duration_type"
                                    className="mt-3"
                                    rules={[{
                                        required: this.state.condition_duration_type_required,
                                        message: 'Please choose one!'
                                    }]}>
                                    <Radio.Group onChange={(e) => { this.radioChange(e, "condition_duration_type"); this.conditionFormRef.current.validateFields() }}>
                                        <Radio value="0">Days ago</Radio>
                                        <Radio value="1">Weeks ago</Radio>
                                        <Radio value="2">Months ago</Radio>
                                        <Radio value="3">Years ago</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    name="medication"
                                    label={<strong>On Medication?</strong>}
                                    className="mt-3"
                                    onChange={this.handleAntFormOnChange}
                                // rules={[{
                                //     required: true,
                                //     message: 'Please choose one!'
                                // }]}
                                >
                                    <Radio.Group name="medication">
                                        <Radio value="1">Yes</Radio>
                                        <Radio value="0">No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <span className="d-none">{this.state.medication}</span>
                                {this.state.medication !== undefined && this.state.medication !== "0" && this.state.medication !== "" && this.state.medication !== "null" ? (
                                    <div className="antInputItem">
                                        <Form.Item
                                            rules={[{
                                                required: true
                                            }]}
                                            name="medication_name"
                                            label="Medicine Name">
                                            <Input />
                                        </Form.Item>
                                    </div>
                                ) : null}
                            </Form>
                        </div>
                    </div>
                </Modal>

                {/* || ALLERGY MODAL */}
                <Modal
                    title={`${formModeEdit ? 'Edit ' : 'Add '} Allergies`}
                    visible={this.state.allergyModal}
                    onCancel={(e) => this.handleModalPopup(e, 'allergyModal')}
                    style={{ top: 40 }}
                    width="530px"
                    bodyStyle={{ paddingBottom: '10px' }}
                    className="emrfrm"
                    footer={[
                        <div className="text-center">
                            <Button form="allergyForm" type="primary" htmlType="submit" className="px-4" disabled={this.state.spin_loading}>
                                SAVE{this.state.spin_loading ? LOADER : ''}
                            </Button>
                        </div>
                    ]}
                >
                    <div className="row">
                        <div className="col-12">
                            <Form
                                id="allergyForm"
                                ref={this.allergyFormRef}
                                validateMessages={HANDLEVALIDANTMESSAGESPATIENTAGE}
                                onFinish={this.handleAllergyAddSubmit}
                                layout="vertical"
                            >
                                {!formModeEdit ? (
                                    <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <a className={`nav-link allergy-type ${this.state.allergy_active_tab === ALLERGY_TAB_STATUS[0] ? 'active' : ''}`}
                                                onClick={(e) => this.handleAllergyTab(e, ALLERGY_TAB_STATUS[0])}>Drugs</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link allergy-type ${this.state.allergy_active_tab === ALLERGY_TAB_STATUS[1] ? 'active' : ''}`}
                                                onClick={(e) => this.handleAllergyTab(e, ALLERGY_TAB_STATUS[1])}>Food</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link allergy-type ${this.state.allergy_active_tab === ALLERGY_TAB_STATUS[2] ? 'active' : ''}`}
                                                onClick={(e) => this.handleAllergyTab(e, ALLERGY_TAB_STATUS[2])}>Others</a>
                                        </li>
                                    </ul>
                                ) : ''}

                                {this.state.allergy_active_tab === ALLERGY_TAB_STATUS[0] ? (
                                    <div className="antSelectItem mt-4">
                                        <div className="form-group add_buttons font-col">
                                            {(this.state.showAddAllergyButton) ?
                                                <a className={"btn btn-outline-primary"} disabled={this.state.disableAddAllergyButton} onClick={() => this.handleAddAllergy('drug')}>ADD</a>
                                                :
                                                null
                                            }
                                            <Form.Item
                                                name="allergy_selected"
                                                label={this.state.allergy_active_tab.toLowerCase()}
                                                rules={[{ required: true ,whitespace: true ,message:'Drugs can not be empty'}]}
                                            >
                                                <AutoComplete
                                                    id="allergy_selected"
                                                    name="allergy_selected"
                                                    showSearch
                                                    showArrow={!this.state.showAddAllergyButton}
                                                    autoFocus
                                                    value={allergy}
                                                    notFoundContent={this.state.formIsFetching ? LOADER_BLACK : null}
                                                    loading={this.state.formIsFetching}
                                                    filterOption={false}
                                                    onSearch={this.fetchAllergyData}
                                                    onSelect={this.handleOnAllergyChange}
                                                    style={{ width: '100%' }}
                                                    disabled={this.state.formModeEdit}
                                                >
                                                    {allergyList !== undefined && allergyList.length > 0 ? allergyList.map(d => (
                                                        <Option key={d.id} value={d.short} dataid={d} label={d.code}>{d.long}</Option>
                                                    )) : null}
                                                </AutoComplete>
                                            </Form.Item>
                                        </div>
                                    </div>
                                ) : null}

                                {this.state.allergy_active_tab === ALLERGY_TAB_STATUS[1] ? (
                                    <div className="antSelectItem mt-4">
                                        <div className="form-group add_buttons font-col">
                                            {(this.state.showAddAllergyButton) ?
                                                <a className={"btn btn-outline-primary"} disabled={this.state.disableAddAllergyButton} onClick={() => this.handleAddAllergy('food')}>ADD</a>
                                                :
                                                null
                                            }
                                            <Form.Item
                                                name="allergy_selected"
                                                label={this.state.allergy_active_tab.toLowerCase()}
                                                rules={[{ required: true, whitespace: true, message:'Food can not be empty' }]}
                                            >
                                                <AutoComplete
                                                    id="allergy_selected"
                                                    name="allergy_selected"
                                                    showSearch
                                                    showArrow={!this.state.showAddAllergyButton}
                                                    autoFocus
                                                    defaultOpen
                                                    value={allergy_selected}
                                                    optionFilterProp="children"
                                                    notFoundContent={null}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    onSearch={this.fetchAllergyFoodData}
                                                    onSelect={this.handleOnAllergyChange}
                                                    style={{ width: '100%' }}
                                                    disabled={this.state.formModeEdit}
                                                >
                                                    {allergy_food !== undefined && allergy_food.length > 0 ? allergy_food.map(d => (
                                                        <Option key={d.id} value={d.short} dataid={d} label={d.code}>{d.long}</Option>
                                                    )) : null}
                                                </AutoComplete>
                                            </Form.Item>
                                        </div>
                                    </div>
                                ) : null}

                                {this.state.allergy_active_tab === ALLERGY_TAB_STATUS[2] ? (
                                    <div className="antSelectItem mt-4">
                                        <div className="form-group add_buttons font-col">
                                            {(this.state.showAddAllergyButton) ?
                                                <a className={"btn btn-outline-primary"} disabled={this.state.disableAddAllergyButton} onClick={() => this.handleAddAllergy('others')}>ADD</a>
                                                :
                                                null
                                            }
                                            <Form.Item
                                                name="allergy_selected"
                                                label={this.state.allergy_active_tab.toLowerCase()}
                                                rules={[{ required: true ,whitespace: true,message:'Others can not be empty'}]}
                                            >
                                                <AutoComplete
                                                    id="allergy_selected"
                                                    name="allergy_selected"
                                                    showSearch
                                                    showArrow={!this.state.showAddAllergyButton}
                                                    autoFocus
                                                    defaultOpen
                                                    value={allergy_selected}
                                                    optionFilterProp="children"
                                                    notFoundContent={null}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    onSearch={this.fetchAllergyOthersData}
                                                    onSelect={this.handleOnAllergyChange}
                                                    style={{ width: '100%' }}
                                                    disabled={this.state.formModeEdit}
                                                >
                                                    {allergy_other !== undefined && allergy_other.length > 0 ? allergy_other.map(d => (
                                                        <Option key={d.id} value={d.short} dataid={d} label={d.code}>{d.long}</Option>
                                                    )) : null}
                                                </AutoComplete>
                                            </Form.Item>
                                        </div>
                                    </div>
                                ) : null}

                                <div className="antSelectItem mt-4">
                                    <Form.Item
                                        name="reaction"
                                        onChange={e => this.handleOnReactionChange(e)}
                                        label="Reaction"
                                    // rules={[{
                                    //     required: true
                                    // }]}
                                    >
                                        <Select mode="tags" style={{ width: '100%' }} dropdownClassName="d-none"></Select>
                                    </Form.Item>
                                </div>
                                <Form.Item
                                    name="intensity"
                                    className="mt-2"
                                    label={<strong>Intensity</strong>}
                                    rules={[{
                                        required: false,
                                        message: 'Please choose one!'
                                    }]}>
                                    <Radio.Group>
                                        <Radio value="0" className="mr-4">Low <i className="icon-severity-low"></i></Radio>
                                        <Radio value="1" className="mr-4">Moderate <i className="icon-severity-medium"></i></Radio>
                                        <Radio value="2">High <i className="icon-severity-high"></i></Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <div className="antInputItem">
                                    <Form.Item
                                        name="allergy_duration"
                                        label="Duration"
                                        className="onlynumbers"
                                        rules={[{
                                            required: false,
                                            type: 'number',
                                            min: 0,
                                            max: this.handleMaxLimit(this.state.allergy_durationType),
                                            transform: function (value) {
                                                return value === "" || value === undefined ? value : +value
                                            }
                                        }]}>
                                        <Input maxLength="2" autoComplete="off" name="duration" onChange={this.handleOnChange} />
                                    </Form.Item>
                                </div>
                                <Form.Item
                                    name="allergy_durationType"
                                    rules={[{
                                        required: this.state.allergy_durationType_required,
                                        message: 'Please choose one!'
                                    }]}>
                                    <Radio.Group onChange={(e) => { this.radioChange(e, "allergy_durationType"); this.allergyFormRef.current.validateFields() }}>
                                        <Radio value="0">Days ago</Radio>
                                        <Radio value="1">Weeks ago</Radio>
                                        <Radio value="2">Months ago</Radio>
                                        <Radio value="3">Years ago</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item
                                    name="informedby"
                                    className="mt-3"
                                    label={<strong>Informed by? </strong>}
                                    rules={[{
                                        required: true,
                                        message: 'Please choose one!'
                                    }]}>
                                    <Radio.Group>
                                        <Radio value="0" className="mr-4">{INFORMED_BY[0]}</Radio>
                                        <Radio value="1">{INFORMED_BY[1]}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {/* <Form.Item>
                                    <Button type="primary" htmlType="submit" disabled={this.state.spin_loading}>
                                        SAVE{this.state.spin_loading ? LOADER : ''}
                                    </Button>
                                </Form.Item> */}
                            </Form>
                        </div>
                    </div>
                </Modal>


                {/* || ALLERGY ALREADY EXIST MODAL */}
                <Modal
                    visible={this.state.allergyAlreadyExistModal}
                    onCancel={(e) => this.handleModalPopup(e, 'allergyAlreadyExistModal')}
                    style={{ top: 40 }}
                    destroyOnClose={true}
                    footer={[
                        <div className="text-center">
                            <button className="btn btn-outline-secondary px-5 cancel-delete-history"
                                onClick={e => this.handleModalPopup(e, 'allergyAlreadyExistModal')}>NO</button>
                            &nbsp;
                            <button className="btn btn-primary px-5 submit-delete-history"
                                disabled={this.state.spin_loading}
                                onClick={e => this.handleModalPopup(e, 'allergyAlreadyExistModal', this.state.allergy_selected_data)}>
                                YES{this.state.spin_loading ? LOADER : ''}</button>
                        </div>
                    ]}
                >
                    <div className="row">
                        <div className="col-12 my-3 text-center confirm-message">
                            <h6>Allergy to {this.state.allergyAlreadyExistName} already added. Would you like to replace?</h6>
                        </div>
                    </div>
                </Modal>

            </>
        )
    }

}


export default withRouter(HomecareAllergies);

