import React, { Component } from "react";
import Axios from "axios";
import qs from "qs";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import searchIcon from "../../assets/images/search-ic.png";
import LS_SERVICE from "../../utils/localStorage";
import moment from "moment";
import {
  DETAIL_LIST_API,
  DURATION_TYPE_SMALL,
  KEY_FINDINGS_GRAPH,
  KEY_FINDING_STORE,
  LOADER_RED,
  PATIENT_DATA,
  SOAP_CONFIGURATION_LIST,
} from "../../utils/constant";
import { Pagination } from "antd";
import AsideLeft from "../../components/aside/asideleft";
import SubHeader from "../../components/subheader/subheader";
import API_SERVICE from "../../utils/apiService";
import { jQueryLabels } from "../../utils/jQueryLabels";
import { headerService$ } from "../../utils/rxjs-sharing";

import LineChartModal from "../../components/chart/line-chart";

class KeyFindingsComponent extends Component {
  constructor(props) {
    super(props);

    // if (props.location.state === undefined) {
    //     props.history.push("/dashboard");
    //     return;
    // }

    // const { patient } = props.history.location.state;

    this.state = {
      patient: null,
      currentTab: "radiology_pathology",
      currentPage: 1,
      allResults: [],
      Laboratory: [],
      radiology: [],
      loading: false,
      dateFormat: "DD/MM/YYYY",
      filterByValue: "3 Months",
      patientId: null,
      search_start_date: null,
      search_end_date: null,
      keyword: "",
      is_abnormal: false,
      moment_start_date: null,
      moment_end_date: null,
      abnormal: false,
      dummy: false,
      preset_ranges: {
        "Last 24 Hours": [moment().subtract(24, "hours"), moment()],
        "1 Week": [moment().subtract(1, "weeks"), moment()],
        "2 Weeks": [moment().subtract(2, "weeks"), moment()],
        "3 Weeks": [moment().subtract(3, "weeks"), moment()],
        "1 Month": [moment().subtract(1, "month"), moment()],
        "2 Months": [moment().subtract(2, "month"), moment()],
        "3 Months": [moment().subtract(3, "month"), moment()],
        "1 Year": [moment().subtract(1, "year"), moment()],
      },
      dateFilter: "3 Months",
      requestSent: false,
      chartDataLoaded: false,
      chartData: {
        labels: null,
        datasets: [
          {
            label: "",
            fill: false,
            lineTension: 0.5,
            backgroundColor: "#FF0000",
            borderColor: "#FF0000",
            borderWidth: 1,
            spanGaps: true,
            data: null,
          },
        ],
      },
      chartOption: {
        responsive: true,
        title: {
          display: false,
          text: "",
          fontSize: 15,
        },

        legend: {
          position: "bottom",
          display: false,
          labels: {
            boxWidth: 6,
            fontColor: "#333333",
            usePointStyle: true,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "",
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 5,
              },
            },
          ],
          yAxes: [
            {
              position: "left",
              display: true,
              scaleLabel: {
                display: true,
                labelString: "",
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 8,
              },
            },
          ],
        },
      },
      chartsTab: "3",
      graphData: null,
      chartModal: false,
      allergyCheckedData: {},
      pagination: null,
    };
  }

  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return true;
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const { hospital_id, patient_id, reference_id } = params;
    const patient = {
      hospital_id,
      patient_id,
      qms_token_id: reference_id,
    };
    this.setState(
      {
        hospital_id,
        patient_id,
        reference_id,
        patient,
      },
      () => {
        if (LS_SERVICE.has("hospitalConfigData")) {
          const hospitalConfigData = LS_SERVICE.get("hospitalConfigData");
          let hospitalConfigObj = hospitalConfigData.find(
            (obj) => obj.hospital_id == hospital_id
          );
          headerService$.soapConfig({
            soapConfig: hospitalConfigObj.opd_layout,
          }); // IMPORTANT TO HIT AS PER THE VIEW
          this.setInitialSearch();
          this.handleSoapFlow();
          jQueryLabels();
        }
      }
    );
    // if (this.props.location.state !== undefined) {
    //     this.setState({
    //         patient: this.props?.location?.state?.patient
    //     }, () => {
    //         this.setInitialSearch();
    //     })
    // } else {
    //     this.getPatientData()
    // }
  }

  // getPatientData = _ => {
  //     const { match: { params } } = this.props;
  //     const { reference_id } = params;
  //     var reference_type = await LS_SERVICE.get('reference_type');
  //     Axios.get(PATIENT_DATA({ reference_id, reference_type }))
  //         .then(success => {
  //             const patient = {
  //                 ...success.data.data.results,
  //                 qms_token_id: success.data.data.results.qmsToken.qms_token_id
  //             }
  //             this.setState({
  //                 patient
  //             }, () => {
  //                 this.setInitialSearch();
  //             })
  //         })
  //         .catch(err => console.log(err))
  // }

  handleSoapFlow = (_) => {
    if (
      !LS_SERVICE.has("goToSoapPatientPageList") ||
      LS_SERVICE.get("goToSoapPatientPageList").length === 0
    ) {
      this.handleSoapInit();
    }
  };

  handleSoapInit = async (_) => {
    const { patient } = this.state;
    const { hospital_id } = patient;

    const soapList = await API_SERVICE.post(SOAP_CONFIGURATION_LIST, {
      hospital_id: hospital_id,
      staff_id: LS_SERVICE.get("staff_id"),
      speciality_id: LS_SERVICE.get("speciality_id") || null,
    });

    const pageList = [];
    const newList = [];

    const newSoapList = Object.values(soapList).map((data) => {
      return data.sort((a, b) => a.display_ordering - b.display_ordering);
    });

    Object.values(newSoapList)
      .flat()
      .forEach((en) => {
        if (en.display === "Y") {
          if (
            !pageList.includes(
              en.parent_module === "subjective" ||
                en.parent_module === "objective"
                ? en.parent_module
                : en.name
            )
          ) {
            pageList.push(
              en.parent_module === "subjective" ||
                en.parent_module === "objective"
                ? en.parent_module
                : en.name
            );
            newList.push({
              url: en.alternate_url_format,
              parent_module: en.parent_module,
              module:
                en.parent_module === "subjective" ||
                en.parent_module === "objective"
                  ? en.parent_module
                  : en.name,
            });
          }
        }
      });

    LS_SERVICE.set("goToSoapPatientUrl", newList);
    LS_SERVICE.set("goToSoapPatientPageList", pageList);
  };

  setInitialSearch = () => {
    const start = moment().subtract(3, "months").toObject();
    const end = moment().toObject();
    this.setState(
      {
        currentTab: "radiology_pathology",
        search_start_date: new Date(start.years, start.months, start.date),
        search_end_date: new Date(end.years, end.months, end.date),
        moment_start_date: moment()
          .subtract(3, "months")
          .format(this.state.dateFormat),
        moment_end_date: moment().format(this.state.dateFormat),
        keyword: "",
        is_abnormal: false,
        filterByValue: "3 Months",
      },
      () => {
        this.searchResults();
      }
    );
  };

  searchResults = async () => {
    const { patient, keyword, is_abnormal, currentPage, currentTab } =
      this.state;
    const { patient_id } = patient;
    this.setState({
      loading: true,
      allResults: [],
      Laboratory: [],
      radiology: [],
      pagination: null,
      requestSent: false,
    });
    const startdate = this.state.moment_start_date;
    const enddate = this.state.moment_end_date;

    const PARAMS = {
      startdate,
      enddate,
      patient_id,
      search: keyword,
      is_abnormal,
      type: currentTab,
      page: currentPage,
    };

    const results = await Axios.get(DETAIL_LIST_API(PARAMS));

    if (results.data.data.hasOwnProperty("testList") === true) {
      // if (results.data.status) {
      this.setState({
        loading: false,
        requestSent: true,
        allResults: results.data.data.testList.appointments,
        pagination: results.data.data.testList.pagination,
      });
    }
  };

  dateFilter = (start, end, label) => {
    const crlabel = start.format("LL") + " - " + end.format("LL");
    this.setState(
      {
        filterByValue: label === "Custom Range" ? crlabel : label,
        search_start_date: new Date(start.years, start.months, start.date),
        search_end_date: new Date(end.years, end.months, end.date),
        is_abnormal: this.state.is_abnormal,
        keyword: this.state.keyword,
        moment_start_date: start.format(this.state.dateFormat),
        moment_end_date: end.format(this.state.dateFormat),
      },
      () => {
        this.searchResults();
      }
    );
  };

  tabChanged(tab) {
    this.setState(
      {
        currentTab: tab,
        currentPage: 1,
      },
      () => this.searchResults()
    );
  }

  handleResultCheckBoxClick = (e, data) => {
    let { allergyCheckedData } = this.state;
    if (allergyCheckedData.hasOwnProperty(data.test_booking_code)) {
      delete allergyCheckedData[data.test_booking_code];
    } else {
      allergyCheckedData = {
        ...allergyCheckedData,
        [data.test_booking_code]: data,
      };
    }

    this.setState({
      allergyCheckedData,
    });
  };

  listing() {
    if (this.state.requestSent) {
      return this.state.allResults.length > 0
        ? this.state.allResults.map((data, index) => {
            return (
              <div className="row mt-4 test-row" key={index}>
                <div className="col-4">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input test-checkbox"
                      id={`testname_${index}`}
                      name="testname"
                      onChange={(e) => this.handleResultCheckBoxClick(e, data)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`testname_${index}`}
                    >
                      <strong>{data.test_name}</strong>
                    </label>
                  </div>
                </div>
                <div className="col-8">
                  <div className="table-responsive">
                    <table className="w-100">
                      <tbody>
                        <tr>
                          <td width="15%">Ordering Physician</td>
                          <td width="40%">
                            : <strong>{data.details.ref_doctor_name}</strong>
                          </td>
                          <td width="9%">Test Date</td>
                          <td width="10%">
                            : <strong>{data.details.checkup_date}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-12">
                  <div className="table-responsive mt-4">
                    <table className="table table-bordered nob">
                      <thead>
                        <tr>
                          <td width="20%">Result</td>
                          <td width="40%">Reference Interval</td>
                          <td width="20%">Unit</td>
                          <td width="20%">Trend</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong className="text-danger">
                              {data.details.test_result}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {data.details.min_value}--{data.details.max_value}
                            </strong>
                          </td>
                          <td>{data.details.units}</td>
                          <td className="testGraphWidget">
                            <a
                              href="#"
                              className="active"
                              onClick={(e) => this.handleGraphClick(e, data)}
                            >
                              <img
                                src="http://dev.woodlands.opdemr.myhealthcare.co/assets/images/icon-chart.png"
                                alt="Chart"
                              />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            );
          })
        : null;
    }
  }

  handleModalPopup = (e, popup) => {
    if (e !== null) {
      e.preventDefault();
    }

    this.setState({
      [popup]: !this.state[popup],
    });
  };

  handleGraphClickTab = (e, type) => {
    const { graphData } = this.state;

    this.setState(
      {
        chartDataLoaded: false,
        chartsTab: type,
      },
      () => this.handleGraphClick(e, graphData)
    );
  };

  handleGraphClick = async (e, data) => {
    e.preventDefault();
    const { chartData, preset_ranges, patient, chartsTab } = this.state;
    const { patient_id } = patient;

    this.setState({
      graphData: data,
      chartModal: true,
    });

    const PARAMS = {
      start_date:
        chartsTab === "3"
          ? moment(preset_ranges["1 Year"][0]).format(this.state.dateFormat)
          : chartsTab === "2"
          ? moment(preset_ranges["1 Month"][0]).format(this.state.dateFormat)
          : chartsTab === "1"
          ? moment(preset_ranges["1 Week"][0]).format(this.state.dateFormat)
          : moment(preset_ranges["Last 24 Hours"][0]).format(
              this.state.dateFormat
            ),
      end_date:
        chartsTab === "3"
          ? moment(preset_ranges["1 Year"][1]).format(this.state.dateFormat)
          : chartsTab === "2"
          ? moment(preset_ranges["1 Month"][1]).format(this.state.dateFormat)
          : chartsTab === "1"
          ? moment(preset_ranges["1 Week"][1]).format(this.state.dateFormat)
          : moment(preset_ranges["Last 24 Hours"][1]).format(
              this.state.dateFormat
            ),
      test_id: data.test_id,
      patient_id,
      type: DURATION_TYPE_SMALL[chartsTab],
    };

    const chartDataPromise = await Axios.post(KEY_FINDINGS_GRAPH, PARAMS);

    if (chartDataPromise.data.status) {
      chartData.labels = [...chartDataPromise.data.dates];
      chartData.datasets[0].data = [...chartDataPromise.data.values];

      this.setState({
        chartDataLoaded: true,
        chartData,
      });
    }
  };

  handleAssessmentContinue = async () => {
    const { patient, allergyCheckedData } = this.state;
    const { qms_token_id } = patient;

    let key_findings = [];

    Object.keys(allergyCheckedData).forEach((e) => {
      allergyCheckedData[e].appointment_booking_date = moment(
        allergyCheckedData[e].appointment_booking_date
      ).format("yyyy-MM-DD");
      allergyCheckedData[e].details.test_result_date = moment(
        allergyCheckedData[e].details.test_result_date
      ).format("yyyy-MM-DD");
      allergyCheckedData[e].details.checkup_date = moment(
        allergyCheckedData[e].details.checkup_date
      ).format("yyyy-MM-DD");
      key_findings.push(allergyCheckedData[e]);
    });

    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: qms_token_id,
      key_findings,
    };
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type');
    const assessContinuePromise = await Axios.post(
      KEY_FINDING_STORE,
      qs.stringify(PARAMS)
    );

    if (assessContinuePromise.data) {
      const isTodayVisitArr = LS_SERVICE.has("isTodayVisit")
        ? LS_SERVICE.get("isTodayVisit")
        : [];
      const pageListArr = LS_SERVICE.get("goToSoapPatientPageList");
      let index = pageListArr.indexOf("subjective");
      index++;
      if (
        index < pageListArr.length &&
        !isTodayVisitArr.includes(qms_token_id)
      ) {
        let soapurl = LS_SERVICE.get("goToSoapPatientUrl")[index].url;
        soapurl = soapurl.replace("HOSPITALID", patient.hospital_id);
        soapurl = soapurl.replace("REFID", patient.qms_token_id);
        soapurl = soapurl.replace("REFTYPE", REFERENCE_TYPE);
        soapurl = soapurl.replace("PATID", patient.patient_id);
        this.props.history.push({
          pathname: soapurl,
          state: { patient },
        });
      } else {
        this.props.history.push({
          pathname: `/patient/todays-visit/assessment/${REFERENCE_TYPE}/${qms_token_id}`,
          state: { patient },
        });
      }
    }
  };

  handleAbnormalCheckbox = (e) => {
    const { name } = e.currentTarget;

    this.setState(
      {
        [name]: !this.state[name],
      },
      () => this.searchResults()
    );
  };

  navigateToPage = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      () => this.searchResults()
    );
  };

  render() {
    const { patient } = this.state;
    // const { qms_token_id } = patient
    return (
      <>
        {patient != undefined && patient.patient_id != undefined ? (
          <AsideLeft
            patient_id={patient.patient_id}
            reference_id={patient.qms_token_id}
          />
        ) : null}

        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              {patient != undefined &&
              patient.patient_id != undefined &&
              patient.patient_id != null ? (
                <SubHeader patient={patient} />
              ) : null}

              <div className="col-md-2">
                <h1 className="page-title mt-2 mb-0">Key Findings</h1>
              </div>
              <div className="col-md-8">
                <ul
                  className="nav nav-pills justify-content-center myhealthcare-tabs auto-width test-results-tabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      onClick={() => this.tabChanged("radiology_pathology")}
                      href="#all-tab"
                      className={
                        "nav-link " +
                        (this.state.currentTab === "radiology_pathology"
                          ? "active"
                          : "")
                      }
                      data-toggle="pill"
                      role="tab"
                      aria-controls="all-tab"
                      aria-selected="true"
                    >
                      All
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={() => this.tabChanged("pathology")}
                      href="#pathology-tab"
                      className={
                        "nav-link " +
                        (this.state.currentTab === "pathology" ? "active" : "")
                      }
                      data-toggle="pill"
                      role="tab"
                      aria-controls="pathology-tab"
                      aria-selected="false"
                    >
                      Laboratory
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={() => this.tabChanged("radiology")}
                      href="#radiology-tab"
                      className={
                        "nav-link " +
                        (this.state.currentTab === "radiology" ? "active" : "")
                      }
                      data-toggle="pill"
                      role="tab"
                      aria-controls="radiology-tab"
                      aria-selected="false"
                    >
                      Radiology &amp; others
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-2">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.searchResults();
                  }}
                  className="emrfrm"
                  id="reportSearchForm"
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <div className="form-label-group input-group">
                          <input
                            onChange={(e) =>
                              this.setState({ keyword: e.target.value })
                            }
                            value={this.state.keyword}
                            type="text"
                            id="search"
                            name="search"
                            className="form-control"
                            placeholder="Search by Reports"
                            autoComplete="off"
                          />
                          <span
                            onClick={() => this.searchResults()}
                            id="reportSpanSubmit"
                            className="input-group-addon bg-primary text-white"
                            style={{
                              cursor: "pointer",
                              borderRadius: " 0 4px 4px 0",
                              maxWidth: "116px",
                              padding: "5px 15px 0px 10px",
                              fontSize: "1.125rem",
                              color: "#fff",
                            }}
                          >
                            <img className="mr-2" src={searchIcon} alt="" />
                            Search
                          </span>
                          <label htmlFor="search">Search by Reports</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 hasdata">
                      <div className="form-group">
                        {this.state.search_start_date != null ? (
                          <DateRangePicker
                            onCallback={this.dateFilter}
                            initialSettings={{
                              startDate: this.state.search_start_date,
                              endDate: this.state.search_end_date,
                              ranges: this.state.preset_ranges,
                            }}
                          >
                            <button
                              type="button"
                              className="text-left form-control"
                            >
                              {this.state.filterByValue}
                            </button>
                          </DateRangePicker>
                        ) : null}
                        <label className="control-label" htmlFor="search">
                          Filter by duration
                        </label>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox mt-2 custom-control-inline">
                          <input
                            type="checkbox"
                            name="is_abnormal"
                            className="custom-control-input"
                            defaultValue={false}
                            checked={this.state.is_abnormal}
                            onChange={this.handleAbnormalCheckbox}
                            id="abnormal_filter"
                            autoComplete="off"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="abnormal_filter"
                          >
                            Abnormal parameters
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <div
                        className="btn btn-primary reset cursor-pointer"
                        onClick={this.setInitialSearch}
                      >
                        RESET
                      </div>
                    </div>
                  </div>
                </form>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="all-test"
                    role="tabpanel"
                  >
                    {this.state.loading ? (
                      this.state.allResults.length === 0 ? (
                        <div className="card bg-light mb-3">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12">
                                Test results not available for selected
                                duration!
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-lg-12 mb-5 text-center">
                          {LOADER_RED}
                        </div>
                      )
                    ) : (
                      this.listing()
                    )}

                    {this.state.requestSent &&
                    this.state.allResults.length == 0 ? (
                      <div className="card bg-light mb-3">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              Test results not available for selected duration!
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                {this.state.pagination != null &&
                this.state.pagination.total > 0 ? (
                  <Pagination
                    defaultCurrent={this.state.currentPage}
                    total={this.state.pagination.total}
                    onChange={this.navigateToPage}
                  />
                ) : null}
              </div>
            </div>
          </div>

          <div className="fixed-cta mt-5">
            <button
              type="button"
              onClick={this.handleAssessmentContinue}
              className="btn btn-primary px-4 nostyle-link"
            >
              CONTINUE
            </button>
          </div>
        </div>

        {/* || LINE CHART MODAL */}
        <LineChartModal />
      </>
    );
  }
}

export default KeyFindingsComponent;
