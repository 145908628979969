import React from "react";
import axios from "axios";
import { Collapse, Row, Col } from "antd";
import AsideLeft from "../../components/aside/asideleft";
import LS_SERVICE from "../../utils/localStorage";
import "./progress.scss";

import {
  PROGRESSNOTES,
  PROGRESSNOTESACCORDIAN,
  LOADER_RED,
  HC_GOBACK,
} from "../../utils/constant";
import moment from "moment";
const { Panel } = Collapse;


class ProgressNotes extends React.Component {
  state = {
    tab: "doctor",
    notesDataProcessing: true,
    notesDataLoaded: false,
    accordionDataLoaded: false,
    booking_id: "",
    accordion_title: [],
    accordion_title_loaded: false,
    docNotes: [],
    nurseNotes: [],
    hospital_id: null,
    patient_id: null,
    reference_id: null,
    defaultActiveKey: "0",
  };

  /*Constructor start*/
  constructor(props) {
    super(props);
    this.onCollapseClick = this.onCollapseClick.bind(this);
  }
  /*Constructor end*/


  /*Initial Function start*/
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    // console.warn("aaaa", params);
    const { hospital_id, patient_id, reference_id } = params;
    this.setState(
      {
        hospital_id: hospital_id,
        patient_id: patient_id,
        booking_id: reference_id,
        reference_id
      },
      () => {
        this.getAccordionData();
      }
    );
  }
  /*Initial Function start*/


  /*getAccordionData function For fetching Accordian title start */

  getAccordionData() {
    const { booking_id, hospital_id } = this.state;
    //console.log('sac', hospital_id);
    axios
      .get(PROGRESSNOTESACCORDIAN({ booking_id, hospital_id }))
      .then((res) => {
        //console.log('sachin',res.data.patient_package);
        let default_index = res.data.patient_package.findIndex(booking=> booking.bookig_id == this.state.reference_id);
        if(default_index != -1)
        {
          this.setState({
            defaultActiveKey : default_index,
          });
        }
        this.setState(
          {
            accordion_title: res.data.patient_package,
            accordion_title_loaded: true,
            accordionDataLoaded: true
          },
          () => {
            if (this.state.accordion_title.length > 0)
              this.getProgressNotes(this.state.accordion_title[this.state.defaultActiveKey].bookig_id)
          }
        );
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          LS_SERVICE.clear();
          this.props.history.push("/");
        }
      });
  };
  /*getAccordionData function For fetching Accordian title end */


  /*Function for fetching Doctor/Nurse Progress notes start*/

  getProgressNotes(booking_id) {
    //console.log(booking_id);
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");

    axios.get(PROGRESSNOTES({ booking_id })).then((res) => {

      let data = res.data.notes[0];
      this.setState({
        docNotes:
          data.doctorprogressnotes !== null ? data.doctorprogressnotes : [],
        nurseNotes:
          data.nurseprogressnotes !== null ? data.nurseprogressnotes : [],
        notesDataLoaded: true,
        notesDataProcessing: false,
      });
    });
  }
  /*Function for fetching Doctor/Nurse Progress notes end*/

  /*Function for goBack button start*/
  // goBack = (event) => {
  // event.preventDefault();
  // this.props.history.goBack();

  // const { hospital_id, patient_id, reference_id } = this.state;
  // const REFERENCE_TYPE = LS_SERVICE.get('reference_type');
  // this.props.history.push({
  //   pathname: `/patient/homecare/details/${hospital_id}/${patient.id}/${REFERENCE_TYPE}/${id}`,
  //   state: { isCallToday },
  // });
  // };
  /*Function for goBack button end*/


  /*Fetching Progress notes on click of Title start*/

  onCollapseClick(key) {
    if (key === undefined) {
      return;
    }
    this.setState({
      docNotes: [],
      nurseNotes: [],
      notesDataLoaded: false,
      notesDataProcessing: true,
      defaultActiveKey:key
    });
    this.getProgressNotes(this.state.accordion_title[key]["bookig_id"]);
  };
  /*Fetching Progress notes on click of Title start*/


  render() {
    const {
      docNotes,
      nurseNotes,
      accordion_title,
    } = this.state;

    const accordianRow = {

      border: '1px solid #cacaca',
      borderRadius: '5px',
      backgroundColor: '#eff0f0'
    }

    return (
      <>

        <AsideLeft isHomecare={true} />

        <div className="content-wrapper">

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="float-left">
                      <h1 className="page-title mb-0">Progress Notes</h1>
                    </div>
                    <div className="float-right">
                      <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={e => HC_GOBACK(e, this.props, this.state)}>BACK TO PATIENT</button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="patient-section progress-notes">

                      <Collapse
                        activeKey={[this.state.defaultActiveKey]}
                        onChange={this.onCollapseClick}
                        accordion
                        className="collapseStyle" style={{ color: '#fff' }}
                      >
                        {accordion_title?.length > 0
                          ? accordion_title.map((ele, i) => (
                            <Panel
                              key={i}
                              header={
                                ele.name +
                                " ( " +
                                (ele.start_date != ""
                                  ? moment(
                                    ele.start_date,
                                    "YYYY-MM-DD"
                                  ).format("D MMM YYYY")
                                  : "/") +
                                " - " +
                                (ele.end_date != ""
                                  ? moment(
                                    ele.end_date,
                                    "YYYY-MM-DD"
                                  ).format("D MMM YYYY")
                                  : "/") +
                                " )"
                              }
                              className="accordianstyle"
                            >
                              <div className="row">
                                <div
                                  className="col-6 pr-0"
                                  style={{
                                    maxHeight: "400px",
                                    overflow: "scroll",
                                  }}
                                >
                                  <div className="nurse-content" style={{ paddingRight: '1em' }}>
                                    <h6 className="mb-4">
                                      Progress Notes : Doctor
                                        </h6>
                                    {docNotes.length > 0 ? (
                                      docNotes.map((e, i) => (
                                        <>
                                          <div
                                            className="text-notes"
                                            key={i}
                                          >
                                            <div className="msg-notes mb-2 p-2" style={accordianRow}>
                                              <Row className="align-items-center">
                                                <Col span={14}>
                                                  <span className="text-dark font-weight-bold mb-1">
                                                    {e.notes_by_name}
                                                  </span>
                                                </Col>
                                                <Col span={10}>
                                                  <p className="text-right mb-0 h6"
                                                    style={{ color: '#666' }}>
                                                    {moment(e.date).format("D MMM Y | h:mm A")}
                                                  </p>
                                                </Col>
                                              </Row>
                                              <div className="row">
                                                <div className="col-12">
                                                  <pre className="m-0">{e.notes}</pre>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ))
                                    ) : docNotes.length === 0 &&
                                      !this.state.notesDataLoaded ? (
                                      <div
                                        className="col d-flex justify-content-center align-self-center mb-5"
                                        style={{
                                          visibility: `${!this.state.notesDataLoaded
                                            ? "visible"
                                            : "hidden"
                                            }`,
                                        }}
                                      >
                                        {LOADER_RED}
                                      </div>
                                    ) : (
                                      <div className="col mb-3 mx-auto card">
                                        <div className="d-flex justify-content-center card-body">
                                          <div className="row">
                                            <div className="col">
                                              <div
                                                className="text-center no-appointement m-0 card-title h5 fontWeightClass textis-primary">
                                                No Doctor notes available!
                                            </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="col-6 pr-0"
                                  style={{
                                    maxHeight: "400px",
                                    overflow: "scroll",
                                  }}
                                >
                                  <div className="nurse-content" style={{ paddingRight: '1em' }}>
                                    <h6 className="mb-4">
                                      Progress Notes : Nurse
                                        </h6>
                                    {nurseNotes.length > 0 ? (
                                      nurseNotes.map((e, i) => (
                                        <>
                                          <div
                                            className="text-notes"
                                            key={i}
                                          >
                                            <div className="msg-notes mb-2 p-2" style={accordianRow}>
                                              <Row>
                                                <Col span={14}>
                                                  <span className="text-dark font-weight-bold mb-1">
                                                    {e.notes_by_name}
                                                  </span>
                                                </Col>
                                                <Col span={10}>
                                                  <p className="text-right mb-0 h6"
                                                    style={{ color: '#666' }}>
                                                    {moment(e.date).format("D MMM Y | h:mm A")}
                                                  </p>
                                                </Col>
                                              </Row>
                                              <div className="row">
                                                <div className="col-12">
                                                  <pre className="m-0">{e.notes}</pre>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ))
                                    ) : nurseNotes.length === 0 &&
                                      !this.state.notesDataLoaded ? (
                                      <div
                                        className="col d-flex justify-content-center align-self-center mb-5"
                                        style={{
                                          visibility: `${!this.state.notesDataLoaded
                                            ? "visible"
                                            : "hidden"
                                            }`,
                                        }}
                                      >
                                        {LOADER_RED}
                                      </div>
                                    ) : (
                                      <div className="col mb-3 mx-auto card">
                                        <div className="d-flex justify-content-center card-body">
                                          <div className="row">
                                            <div className="col">
                                              <div
                                                className="text-center no-appointement m-0 card-title h5 fontWeightClass textis-primary">
                                                No Nurse notes available!
                                            </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Panel>
                          ))
                          : null
                        }

                      </Collapse>

                      {this.state.accordionDataLoaded && this.state.accordion_title.length === 0 && (
                        <div className="card bg-light5">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12 text-center">No Data available</div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div
                        className="col d-flex justify-content-center align-self-center mb-5"
                        style={{
                          visibility: `${!this.state.accordionDataLoaded
                            ? "visible"
                            : "hidden"
                            }`,
                        }}
                      >
                        {LOADER_RED}
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default ProgressNotes;