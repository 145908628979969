import Axios from 'axios';
import moment from 'moment';
import qs from "qs";
import React, { Component } from 'react'
import AsideLeft from '../../../components/aside/asideleft';
import {
  BMI_CALCULATE,
  GET_VITALS_RANGE_CLASS,
  HOMECARE_TEST_NOTES,
  HOSPITALDEFAULTS,
  IMAGE_EXT,
  LOADER_RED,
  OBJECTIVE_GETALL,
  VITALS_GETALL,
  VITALS_GRAPH,
  LOADER,
  HANDLEVALIDANTMESSAGES,
  VITALS_FILTER_SEQUENCING_LIST,
  OBJECTIVE_VITAL_STORE_SS,
  UPDATE_VITALS,
  HC_GOBACK,
  DISABLED_FUTURE_DATES,
  USERTYPES,
  PDF_CONFIG_VITALS_GRAPH, HOMECARE_REFERENCE_TYPE
} from '../../../utils/constant';
// import icJpg from '../../../assets/images/ic-jpg.png';
// import icPdf from '../../../assets/images/ic-pdf.png';
import icEdit from "../../../assets/images/ic-edit-copy.svg";
import LS_SERVICE from '../../../utils/localStorage';
import { Modal, Carousel, Button, Form, Input, Select, Radio, Popover, Badge, Pagination, DatePicker } from 'antd';
import { AntDisabledLabels, AntSelectLabels } from '../../../utils/jQueryLabels';
import Chart from '../../../components/chart/chart';
import ChartPastVisit from '../../../components/chart/chart-past-visit';
import "./vitals.scss";

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
class HomecareVitals extends Component {

  state = {
    details: null,
    appointment_status: null,
    visitVitals: null,
    constants: null,
    homecareVitals: null,
    vitalsRange: null,
    alerts: [],
    dataLoaded: false,
    viewDocumentModal: false,
    patient_docments: [],
    vitalModal: false,
    formModeEdit: false,
    vitalsType: '0',
    patientHeight: null,
    patientWeight: null,
    vitalGroups: [],
    selectedVitalGroups: [],
    displayVitals: [],
    vitalNotInRange: [],
    chartDataLoaded: false,
    chartGraphSettings: '',
    chartsTab: '2',
    chartsType: 'all',
    chartHeading: 'Vitals',
    chartsTabSet: false,
    editVitalsData: null,
    vitalsData: null,
    reasonModal: false,
    reasonChangeValue: 0,
    vitalEditId: null,
    pagination: null,
    currentPage: 1,
    records_per_page: 15,
    total_records: 0,
    total_pages: 1,
    range: [],
    bloodSugarChartData: null,
    vitalsRangeWithId: null
  }

  constructor(props) {
    super(props);

    this.carouselRef = React.createRef();
    this.vitalFormRef = React.createRef();
    this.reasonFormRef = React.createRef();

    this.paginate = this.paginate.bind(this);

    this.reasonChange = [
      {
        value: 0,
        reason: 'Incorrect reading entered by patient'
      }, {
        value: 1,
        reason: 'Incorrect reading due to faulty machine'
      }, {
        value: 2,
        reason: 'Others'
      }
    ]
  }

  componentDidMount() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");

    this.componentDidMountFn();
  }

  componentDidMountFn = () => {
    const { match: { params } } = this.props;
    const { patient_id, hospital_id, reference_id } = params;

    const getAllDataUri = Axios.get(VITALS_GETALL({ patient_id }) + '?page=1');
    const vitalGraphUri = Axios.get(VITALS_GRAPH({ patient_id }));
    const testNotesUri = Axios.get(HOMECARE_TEST_NOTES({ booking_id: reference_id, page: 1 }))
    const vitalFilterPromise = Axios.get(VITALS_FILTER_SEQUENCING_LIST);
    const vitalPDFVitalGraphUri = Axios.get(PDF_CONFIG_VITALS_GRAPH({ reference_type: HOMECARE_REFERENCE_TYPE, reference_id }));
    

    let PARAMS = [getAllDataUri, vitalGraphUri, testNotesUri, vitalFilterPromise];

    if (LS_SERVICE.get('user_type') != USERTYPES.nurse) {
      PARAMS.push(vitalPDFVitalGraphUri);
    }

    Axios.all([...PARAMS])
      .then(success => {
        const { data } = success[0].data;
        const { details, constants, pagination } = data;
        const dataGraph = success[1].data;
        const { alerts } = success[2].data;
        let vitalPDFVitalGraphData;
        if (LS_SERVICE.get('user_type') != USERTYPES.nurse) {
          vitalPDFVitalGraphData = success[4].data;
        }
        const allVitals = constants ? constants.vitals_details : null;
        var homecareVitals = [];
        if (success?.[3]?.data?.status) {
          for(var hcvitals of allVitals){
            var i = success?.[3]?.data?.data.map(e => e.vital_id).indexOf(hcvitals.vital_id);
            if(i >= 0){
              homecareVitals.push(hcvitals)
            }
          }
        }
        if(homecareVitals?.length == 0){
          homecareVitals = allVitals
        }
        if (LS_SERVICE.get('user_type') != USERTYPES.nurse) {
          this.setState({
            chartGraphSettings: vitalPDFVitalGraphData.data.details !== null ? vitalPDFVitalGraphData.data.details.graph_settings : '',
          })
        }

        this.setState({
          hospital_id,
          reference_id,
          patient_id,
          details,
          visitVitals: Object.values(details.visitVitals),
          currentPage: pagination.current_page,
          total_records: pagination.total,
          total_pages: pagination.total_pages,
          pagination: pagination,
          constants,
          homecareVitals,
          chartData: dataGraph.details,
          bloodSugarChartData: dataGraph.blood_sugar_data,
          chartDataLoaded: true,
          vitalsRange: Object.values(dataGraph.constants.range),
          vitalsRangeWithId: dataGraph.constants.range,
          alerts
        }, () => this.makeVitalsData(this.state.visitVitals))
      })
  }

  /* Pagination start*/
  paginate = (page) => {
    const getAllDataUri = Axios.get(VITALS_GETALL({ patient_id: this.state.patient_id }) + '?page=' + page);

    Axios.all([getAllDataUri])
      .then(success => {
        const { data } = success[0].data;
        const { details, constants, pagination } = data;

        this.setState({
          details,
          visitVitals: Object.values(details.visitVitals),
          currentPage: pagination.current_page,
          total_records: pagination.total,
          total_pages: pagination.total_pages,
          pagination: pagination,
        }, () => this.makeVitalsData(this.state.visitVitals))
      })
  }

  makeVitalsData = (visitVitals) => {
    let vitalDetails = [];

    // let { patientHeight, patientWeight } = this.state;

    let vital_data = {}

    this.state.homecareVitals.map((v, k) => {
      vitalDetails[v.id] = [];
      vital_data[v.id] = null;
    });

    // vitalDetails['date'] = [];

    if (Object.keys(visitVitals).length > 0) {
      let index = 0;
      //   let index2 = 0;
      // if (index < 1) {
      visitVitals.map((vV, k) => {
        // debugger;
        // if (index2 < 1) {
        Object.keys(vV).forEach(j => {
          if (vitalDetails[j]) vitalDetails[j][index] = vV[j];
          // if (j == 'V001' && patientHeight == null && patient.age > HOSPITALDEFAULTS.age && moment().diff(moment(temp_vitals.date[j]), 'months') < 2 && temp_vitals[j]) patientHeight = temp_vitals[j];
          // if (j == 'V002' && patientWeight == null && patient.age > HOSPITALDEFAULTS.age && moment().diff(moment(temp_vitals.date[j]), 'months') < 2 && temp_vitals[j]) patientWeight = temp_vitals[j];
        });
        //   index2++
        // }
        index++;
      });
      // }
    }

    let vitals_data = {};
    let newVitalEmptyArray = vitalDetails['date'] ? Array.from({ length: vitalDetails['date'].length }, i => '') : [];
    this.state.homecareVitals.map((v, k) => {
      vitals_data[v.id] = vitalDetails[v.id] && vitalDetails[v.id].length > 0 ? vitalDetails[v.id] : newVitalEmptyArray;
    });
    // vitals_data.date = vitalDetails['date'];

    // vitals groups
    let vitalGroupData = [];
    const doctorSpeciality = LS_SERVICE.get('speciality_id');
    let allVital = null;
    for (const vitalGroup of Object.values(this.state.constants.vital_groups)) {
      if (vitalGroup.speciality_id == doctorSpeciality) {
        var vitalSignIds = [];
        for (const vitalSign of Object.values(vitalGroup.vital_sign)) {
          vitalSignIds.push(vitalSign.vital_id);
        }
        vitalGroup.vitalSignIds = vitalSignIds.toString().replace(/(^,)|(,$)/g, "");
        vitalGroupData.push(vitalGroup);
      }
      if (vitalGroup.code == 'VG003') {
        var vitalSignIds2 = Object.values(vitalGroup.vital_sign).map(vs => vs.vital_id);
        vitalGroup.vitalSignIds = vitalSignIds2.toString().replace(/(^,)|(,$)/g, "");
        allVital = vitalGroup;
      }
    }
    if (vitalGroupData.length == 0)
      vitalGroupData.push(allVital);

    this.setState({
      selectedVitalGroups: vitalGroupData,
      vitalsType: vitalGroupData[0].id,
      displayVitals: vitalGroupData[0].vitalSignIds.split(','),
      vitals_data,
      // dataLoaded: true
    })
  }

  handleModalPopup = (e, popup, data, img) => {
    e !== null && e.preventDefault();

    if (typeof img?.images === 'object') {
      this.setState({
        patient_docments: img?.images
      }, () => {
        this.setState({
          [popup]: !this.state[popup]
        }, () => {
          const { viewDocumentModal } = this.state;

          if (viewDocumentModal) {
            this.carouselRef.current.goTo(img.index);
          }
        })
      })
    } else {
      this.setState({
        [popup]: !this.state[popup]
      }, () => {
        const { vitalModal, reasonModal } = this.state;

        if (!vitalModal) {
          this.state.homecareVitals.map((v, k) => {
            this.setState({ [v.id]: null });
          });

          if (this.vitalFormRef.current !== null) {
            this.vitalFormRef.current.resetFields();
          }
        }

        if (!reasonModal) {
          this.setState({
            reasonChangeValue: 0
          })

          if (this.reasonFormRef.current !== null) {
            this.reasonFormRef.current.resetFields();
          }
        }

        if (data && data !== null) {
          if (vitalModal) {
            this.vitalFormRef.current.resetFields();

            this.setState({
              vitalEditId: data.visit_vital_id
            })

            this.state.homecareVitals.forEach((v, k) => {
              this.setState({
                [v.id]: data[v.id]
              });
              this.vitalFormRef.current.setFieldsValue({
                [v.id]: data[v.id]
              })
            });
          }
        }
        else{
          if (vitalModal) {
            this.vitalFormRef.current.resetFields();

            this.setState({
              vitalEditId: null
            })
          }
        }

        AntDisabledLabels();
        AntSelectLabels();
      })
    }
  }

  handleDateRangeGraphData = async (reset = false) => {
    const { patient_id, range } = this.state;

    const PARAMS = {
      patient_id,
      start_date: !reset ? moment(range[0]._d).format('YYYY-MM-DD HH:mm:ss') : undefined,
      end_date: !reset ? moment(range[1]._d).format('YYYY-MM-DD HH:mm:ss') : undefined
    }

    const vitalGraphRangePromise = await Axios.get(VITALS_GRAPH(PARAMS))

    if (vitalGraphRangePromise.data.status === "success") {

      const { data } = vitalGraphRangePromise;

      this.setState({
        chartDataLoaded: false,
        chartData: data.details,
        bloodSugarChartData: data.blood_sugar_data,
        vitalsRange: Object.values(data.constants.range),
      }, () => this.setState({
        chartDataLoaded: true,
      }))
    }

  }

  handleOnChangeNumbers = (event, min, max) => {
    let { name, value } = event.currentTarget;
    let vitalNotInRange = this.state.vitalNotInRange;
    //value = value.replace(name !== 'V004' ? /\D/ : /[^0-9.]/g, "");
    var index = vitalNotInRange.indexOf(name);
    if (parseFloat(value) < min || parseFloat(value) > max) {
      if (index == -1)
        vitalNotInRange.push(name);
    }
    else {
      if (index != -1)
        vitalNotInRange.splice(index, 1);
    }

    this.setState({
      [name]: value,
      vitalNotInRange,
    }, () => {
      this.vitalFormRef.current.setFieldsValue({
        [name]: value
      })

      this.handleBmiCalculate();
    })
  }

  handleBmiCalculate = _ => {

    let { V001, V002, patientHeight, patientWeight } = this.state;

    V001 = V001 !== null ? V001 : patientHeight;
    V002 = V002 !== null ? V002 : patientWeight;

    if (V001 > 0 && V002 > 0) {
      let val = BMI_CALCULATE(V001, V002);
      this.setState({
        V003: val
      }, () => { AntSelectLabels() })

      this.vitalFormRef.current.setFieldsValue({
        V003: val
      }, () => { AntSelectLabels() })

      AntSelectLabels();
    } else {
      this.setState({
        V003: ''
      }, () => { AntSelectLabels() })

      this.vitalFormRef.current.setFieldsValue({
        V003: ''
      })

      AntSelectLabels();
    }
  }

  handleVitalModalButtonDisabled = _ => {
    if (this.state.constants && this.state.homecareVitals) {
      var vitalFieldsCount = this.state.homecareVitals.length;
      var emptyCount = 0;
      this.state.homecareVitals.map(function (v, k) {
        if (parseFloat(this.state[v.id]) == 0) emptyCount++;
      }, this);

      if (emptyCount > 0)
        return true;

      if (this.state.V005 != null && this.state.V005 != '' && (this.state.V006 == '' || this.state.V006 == null))
        return true;

      if (this.state.V006 != null && this.state.V006 != '' && (this.state.V005 == '' || this.state.V005 == null))
        return true;

      if (this.state.vitalNotInRange.length > 0)
        return true;

      return false;
    }
    else
      return false;
  }

  handleGraphClick = (e, type = '2') => {

    // if (this.state.range.length > 0)
    //   this.handleDateRangeGraphData(true);

    this.setState({
      // range: [],
      chartDataLoaded: false,
      chartsTab: type
    }, () => this.setState({ chartDataLoaded: true }))
  }

  handleVitalAddSubmit = async e => {
    if(this.state.vitalEditId && this.state.vitalEditId != null){

      const editVitalsData = this.vitalFormRef.current.getFieldsValue(true);

      this.setState({
        editVitalsData
      }, () => {
        this.handleModalPopup(null, 'vitalModal');
        this.handleModalPopup(null, 'reasonModal');
      })
    }
    else{
      const vitalsData = this.vitalFormRef.current.getFieldsValue(true);
      let role = LS_SERVICE.get("user_type") == USERTYPES.doctor ? 'Doctor' : LS_SERVICE.get("user_type") == USERTYPES.nurse ? 'Nurse' : LS_SERVICE.get("user_type") == USERTYPES.superadmin ? 'SuperAdmin' : LS_SERVICE.get("user_type") == USERTYPES.admin ? 'Admin' : null;
      const PARAMS = {
        vitals_data: vitalsData,
        reference_type: LS_SERVICE.get('reference_type'),
        reference_id: this.state.reference_id,
        patient_id: this.state.patient_id,
        speciality_id: LS_SERVICE.get('speciality_id'),
        hospital_id: LS_SERVICE.get('slot_hospital_id'),
        created_by_name: LS_SERVICE.get("profile")?.data?.full_name || "Dr. " + LS_SERVICE.get("staff_name") || null,
        created_by_role: role,
        updated_by_name: LS_SERVICE.get("profile")?.data?.full_name || "Dr. " + LS_SERVICE.get("staff_name") || null,
        updated_by_role: role,
      };
      const vitalAddPromise = await Axios.post(OBJECTIVE_VITAL_STORE_SS, qs.stringify(PARAMS));
      this.componentDidMountFn();
      this.handleModalPopup(null, 'vitalModal');
    }
  }

  handleAntRadioChange = e => {
    this.setState({
      reasonChangeValue: e.target.value,
    });
  };

  handleOnChange = e => {

    const { name, value } = e.target;

    this.setState({
      [name]: value
    })
  }

  handleReasonFormSubmit = e => {
    const { editVitalsData, vitalEditId, reasonChangeValue } = this.state;

    this.setState({
      spin_loading: !this.state.spin_loading
    })

    if (reasonChangeValue === 2) {
      this.reasonFormRef.current.getFieldsValue(true)
    }

    const PARAMS = {
      "visit_vital_id": vitalEditId,
      "vitals_data": editVitalsData,
      "reason": reasonChangeValue !== 2 ? this.reasonChange[reasonChangeValue].reason : this.reasonFormRef.current.getFieldsValue(true).reason
    }

    Axios.post(UPDATE_VITALS, PARAMS)
      .then(success => {
        this.setState({
          spin_loading: !this.state.spin_loading
        })
        this.handleModalPopup(null, 'reasonModal');
        this.componentDidMountFn();
      })
  }

  getSourceDisplayName(source, date, update_date) {
    let sourceName = source[0].toUpperCase() + source.substring(1);
    let time = moment(update_date).format('DD MMM, h:mm A');
    return sourceName;
    // return source == 'patient' && date && update_date && date != update_date ? sourceName + ' Update ' + time : sourceName;
  }

  handleRangeChange = dates => {
    // let range = [];
    // if (dates !== null)
    //   range = [...dates]
    this.setState({
      range: dates !== null ? dates : []
    }, () => {
      this.handleDateRangeGraphData(dates == null)
    })
  }

  handleHomecareDetails = data => {
    this.setState({
      appointment_status: data.appointment.booking_status_code,
      dataLoaded: true,
    })
  }

  chartStateUpdate = data => {
    this.setState({
      chartGraphSettings: data
    })
  }

  render() {

    const { dataLoaded, alerts, patient_docments } = this.state;

    return (
      <div>
        <AsideLeft isHomecare={true} getHomecareDetails={this.handleHomecareDetails} />
        <div className="content-wrapper" style={{ paddingBottom: '80px' }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mb-3">
                <div className="float-left">
                  <h1 className="page-title mb-0">Patient Vitals</h1>
                </div>
                <div className="float-right d-flex align-items-center">
                  <Badge className="mr-2 vr-badge" color={'#ffe6e8'} text={'Vitals entered by Patient'} />
                  <button type="button" className="btn top-chart-btn btn-sm"
                    onClick={(e) => this.handleModalPopup(e, 'chartModal')}>
                    <i className="icon-chart"></i>
                  </button>
                  <button 
                    className="btn btn-outline-primary small mr-2 px-2"
                    style={{ opacity: this.state.appointment_status === 2 ? '0.5' : '1' }}
                    disabled={this.state.appointment_status === 2}
                    onClick={e => this.handleModalPopup(e, 'vitalModal')}
                  >+ VITALS</button>
                  <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={e => HC_GOBACK(e, this.props, this.state)}>BACK TO PATIENT</button>
                </div>
              </div>

              {dataLoaded && (
                <div className="col-12 visible">

                  <div className="row">
                    <div className="col-md-12">
                      <div className="section-table patient-vital-table-container">
                        <table className="table table-bordered table table-responsive">
                          <thead>
                            <tr>
                              <th className="text-left" style={{ minWidth: '200px' }} rowSpan="2">Date</th>
                              {this.state.homecareVitals?.length > 0 && (
                                this.state.homecareVitals.map(vital => {
                                  if (vital.id !== 'V005' && vital.id !== 'V006' && vital.id !== 'V010' && vital.id !== 'V011' && vital.id !== 'V013') {
                                    return <th className="text-left" rowSpan="2" width="100">{vital.display_name} <br /> { vital.display_value ? '('+vital.display_value+')' : null }</th>
                                  } else if (vital.id === 'V005') {
                                    return <th className="text-left" rowSpan="2" width="100">BP <br /> ({vital.display_value})</th>
                                  } else if (vital.id === 'V010') {
                                    return <th className="text-center" colSpan="3" width="100">Blood Sugar ({vital.display_value})</th>
                                  }
                                })
                              )}
                              {this.state.appointment_status !== 2 && (
                                <th className="text-center" rowSpan="2">Edit</th>
                              )}
                            </tr>
                            <tr className="second-throw">
                              <th className="text-left" width="100">Fasting</th>
                              <th className="text-left" width="100">PP</th>
                              <th className="text-left" width="100">Random</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.visitVitals && Object.keys(this.state.visitVitals).length > 0 ? (
                              this.state.visitVitals.map(vV => {
                                return (
                                  <tr className={vV.source === 'patient' && 'bgis-pink'}>
                                    <td className="text-left">
                                      <span className="vital-time font-weight-semibold">
                                        {
                                          moment(vV['date']).format('D MMM YYYY | ')
                                        }
                                      </span>
                                      <span className="vital-time textis-primary font-weight-semibold">
                                        {
                                          moment(vV['date']).format('h:mm A')
                                        }
                                      </span>
                                      {vV['source'] == 'patient' && vV['update_date'] && moment(vV['date']).startOf('minute').format('YYYY-MM-DD HH:mm:ss') != moment(vV['update_date']).startOf('minute').format('YYYY-MM-DD HH:mm:ss') ?
                                        <Popover
                                          className="ml-1"
                                          content={
                                            <div>
                                              <p className="font-weight-bold mb-1">
                                                Updated at
                                                                                    </p>
                                              <p className="mb-1">
                                                {moment(vV['update_date']).format('D MMM YYYY | h:mm A')}
                                              </p>
                                            </div>
                                          }>
                                          <Badge status="error" />
                                        </Popover> : null}
                                      <br />
                                      <span className={"vital-source"}>
                                        {vV['source_name'] !== "" ? (
                                          <Popover content={vV['source_name']}                                                                            >
                                            {this.getSourceDisplayName(vV['source'], vV['date'], vV['update_date'])}
                                          </Popover>
                                        ) : (
                                          this.getSourceDisplayName(vV['source'], vV['date'], vV['update_date'])
                                        )}
                                      </span>

                                    </td>
                                    {this.state.homecareVitals?.length > 0 && (
                                      this.state.homecareVitals.map((vD, i) => {
                                        if (vD.id !== 'V005' && vD.id !== 'V006' && vD.id !== 'V010' && vD.id !== 'V011' && vD.id !== 'V013') {
                                          return <td
                                            className={`text-left vital-value`}>
                                            {vV[vD.id] ? (
                                              <span className={GET_VITALS_RANGE_CLASS(vV[vD.id], this.state.constants.vitals_range[vD.id])}>
                                                {vV[vD.id]}
                                                {vV?.edit_logs !== null && vV?.edit_logs[vD.id] && (
                                                  <Popover
                                                    className="ml-1"
                                                    content={
                                                      <div>
                                                        <p className="font-weight-bold mb-1">
                                                          Edited by
                                                      </p>
                                                        <p>
                                                          {vV?.edit_logs[vD.id].edited_by} {' '} {moment(vV?.edit_logs[vD.id].edited_at).format('D MMM YYYY | h:mm A')}
                                                        </p>
                                                        {(vV?.edit_logs[vD.id].previous_value.length > 0) && (
                                                          <>
                                                            <p className="font-weight-bold mb-1">
                                                              Previous value
                                                          </p>
                                                            <p className="m-0">
                                                              {vV?.edit_logs[vD.id].previous_value}
                                                            </p>
                                                          </>
                                                        )}
                                                        <p className="font-weight-bold mb-1">
                                                          Reason for change
                                                      </p>
                                                        <p>
                                                          {vV?.edit_logs[vD.id].reason}
                                                        </p>
                                                      </div>
                                                    }>
                                                    <Badge status="error" />
                                                  </Popover>
                                                )}
                                              </span>
                                            ) : (
                                              <span>
                                                -
                                              </span>
                                            )}
                                          </td>
                                        } else if (vD.id === 'V005') {
                                          return <td
                                            className={`text-left vital-value`} style={{ whiteSpace: 'nowrap' }}>
                                            {vV['V006'] ? (
                                              <span className={GET_VITALS_RANGE_CLASS(vV['V006'], this.state.constants.vitals_range['V006'])}>
                                                {vV['V006']}
                                                {vV?.edit_logs !== null && vV?.edit_logs['V006'] && (
                                                  <Popover
                                                    className="ml-1"
                                                    content={
                                                      <div>
                                                        <p className="font-weight-bold mb-1">
                                                          Edited by
                                                                                                    </p>
                                                        <p>
                                                          {vV?.edit_logs['V006'].edited_by} {' '} {moment(vV?.edit_logs['V006'].edited_at).format('D MMM YYYY | h:mm A')}
                                                        </p>
                                                        {vV?.edit_logs['V006'].previous_value.length > 0 && (
                                                          <>
                                                            <p className="font-weight-bold mb-1">
                                                              Previous value
                                                                                                            </p>
                                                            <p className="m-0">
                                                              {vV?.edit_logs['V006'].previous_value}
                                                            </p>
                                                          </>
                                                        )}
                                                        <p className="font-weight-bold mb-1">
                                                          Reason for change
                                                                                                    </p>
                                                        <p>
                                                          {vV?.edit_logs['V006'].reason}
                                                        </p>
                                                      </div>
                                                    }>
                                                    <Badge status="error" />
                                                  </Popover>
                                                )}
                                              </span>
                                            ) : (
                                              <span>
                                                -
                                              </span>
                                            )}
                                          /
                                          {vV[vD.id] ? (
                                              <span className={GET_VITALS_RANGE_CLASS(vV[vD.id], this.state.constants.vitals_range[vD.id])}>
                                                {vV[vD.id]}
                                                {vV?.edit_logs !== null && vV?.edit_logs[vD.id] && (
                                                  <Popover
                                                    className="ml-1"
                                                    content={
                                                      <div>
                                                        <p className="font-weight-bold mb-1">
                                                          Edited by
                                                                                                    </p>
                                                        <p>
                                                          {vV?.edit_logs[vD.id].edited_by} {' '} {moment(vV?.edit_logs[vD.id].edited_at).format('D MMM YYYY | h:mm A')}
                                                        </p>
                                                        {(vV?.edit_logs[vD.id].previous_value.length > 0) && (
                                                          <>
                                                            <p className="font-weight-bold mb-1">
                                                              Previous value
                                                                                                            </p>
                                                            <p className="m-0">
                                                              {vV?.edit_logs[vD.id].previous_value}
                                                            </p>
                                                          </>
                                                        )}
                                                        <p className="font-weight-bold mb-1">
                                                          Reason for change
                                                                                                    </p>
                                                        <p>
                                                          {vV?.edit_logs[vD.id].reason}
                                                        </p>
                                                      </div>
                                                    }>
                                                    <Badge status="error" />
                                                  </Popover>
                                                )}
                                              </span>
                                            ) : (
                                              <span>
                                                -
                                              </span>
                                            )}
                                          </td>
                                        } else if (vD.id === 'V010') {
                                          return (
                                            <>
                                              <td
                                                className={`text-left vital-value`}>
                                                {vV[vD.id] ? (
                                                  <span className={GET_VITALS_RANGE_CLASS(vV[vD.id], this.state.constants.vitals_range[vD.id])}>
                                                    {vV[vD.id]}
                                                    {vV?.edit_logs !== null && vV?.edit_logs[vD.id] && (
                                                      <Popover
                                                        className="ml-1"
                                                        content={
                                                          <div>
                                                            <p className="font-weight-bold mb-1">
                                                              Edited by
                                                                                                            </p>
                                                            <p className="m-0">
                                                              {vV?.edit_logs[vD.id].edited_by} {' '} {moment(vV?.edit_logs[vD.id].edited_at).format('D MMM YYYY | h:mm A')}
                                                            </p>
                                                            {(vV?.edit_logs[vD.id].previous_value.length > 0) && (
                                                              <>
                                                                <p className="font-weight-bold mb-1">
                                                                  Previous value
                                                                                                                    </p>
                                                                <p>
                                                                  {vV?.edit_logs[vD.id].previous_value}
                                                                </p>
                                                              </>
                                                            )}
                                                            <p className="font-weight-bold mb-1">
                                                              Reason for change
                                                                                                            </p>
                                                            <p className="m-0">
                                                              {vV?.edit_logs[vD.id].reason}
                                                            </p>
                                                          </div>
                                                        }>
                                                        <Badge status="error" />
                                                      </Popover>
                                                    )}
                                                  </span>
                                                ) : (
                                                  <span>
                                                    -
                                                  </span>
                                                )}
                                              </td>
                                              <td className={`text-left vital-value`}>
                                                {vV['V011'] ? (
                                                  <span className={GET_VITALS_RANGE_CLASS(vV['V011'], this.state.constants.vitals_range['V011'])}>
                                                    {vV['V011']}
                                                    {vV?.edit_logs !== null && vV?.edit_logs['V011'] && (
                                                      <Popover
                                                        className="ml-1"
                                                        content={
                                                          <div>
                                                            <p className="font-weight-bold mb-1">
                                                              Edited by
                                                                                                            </p>
                                                            <p className="m-0">
                                                              {vV?.edit_logs['V011'].edited_by} {' '} {moment(vV?.edit_logs['V011'].edited_at).format('D MMM YYYY | h:mm A')}
                                                            </p>
                                                            {vV?.edit_logs['V011'].previous_value.length > 0 && (
                                                              <>
                                                                <p className="font-weight-bold mb-1">
                                                                  Previous value
                                                                                                                    </p>
                                                                <p>
                                                                  {vV?.edit_logs['V011'].previous_value}
                                                                </p>
                                                              </>
                                                            )}
                                                            <p className="font-weight-bold mb-1">
                                                              Reason for change
                                                                                                            </p>
                                                            <p className="m-0">
                                                              {vV?.edit_logs['V011'].reason}
                                                            </p>
                                                          </div>
                                                        }>
                                                        <Badge status="error" />
                                                      </Popover>
                                                    )}
                                                  </span>
                                                ) : (
                                                  <span>
                                                    -
                                                  </span>
                                                )}
                                              </td>
                                              <td className={`text-left vital-value`}>
                                                {vV['V013'] ? (
                                                  <span className={GET_VITALS_RANGE_CLASS(vV['V013'], this.state.constants.vitals_range['V013'])}>
                                                    {vV['V013']}
                                                    {vV?.edit_logs !== null && vV?.edit_logs['V013'] && (
                                                      <Popover
                                                        className="ml-1"
                                                        content={
                                                          <div>
                                                            <p className="font-weight-bold mb-1">
                                                              Edited by
                                                                                                            </p>
                                                            <p className="m-0">
                                                              {vV?.edit_logs['V013'].edited_by} {' '} {moment(vV?.edit_logs['V013'].edited_at).format('D MMM YYYY | h:mm A')}
                                                            </p>
                                                            {vV?.edit_logs['V013'].previous_value.length > 0 && (
                                                              <>
                                                                <p className="font-weight-bold mb-1">
                                                                  Previous value
                                                                                                                    </p>
                                                                <p>
                                                                  {vV?.edit_logs['V013'].previous_value}
                                                                </p>
                                                              </>
                                                            )}
                                                            <p className="font-weight-bold mb-1">
                                                              Reason for change
                                                                                                            </p>
                                                            <p className="m-0">
                                                              {vV?.edit_logs['V013'].reason}
                                                            </p>
                                                          </div>
                                                        }>
                                                        <Badge status="error" />
                                                      </Popover>
                                                    )}
                                                  </span>
                                                ) : (
                                                  <span>
                                                    -
                                                  </span>
                                                )}
                                              </td>
                                            </>
                                          )
                                        }

                                      })
                                    )}
                                    {this.state.appointment_status !== 2 && (
                                      <td className="text-center">
                                        <button
                                          style={{ border: 'none', background: 'none', opacity: this.state.appointment_status === 2 ? '0.5' : '1' }}
                                          disabled={this.state.appointment_status === 2}
                                          onClick={e => this.handleModalPopup(e, 'vitalModal', vV)}
                                        >
                                          <img
                                            style={{ width: '15px', cursor: 'pointer' }}
                                            className="edit-img img-responsive"
                                            src={icEdit}
                                            alt="edit-icon" />
                                        </button>
                                      </td>
                                    )}
                                  </tr>
                                )
                              })) : (
                              <tr>
                                <td colSpan="12">
                                  No Data
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {dataLoaded && this.state.total_pages > 1 && this.state.pagination != null && this.state.pagination.total_pages > 0 ?
                      <div className="col-12 mb-5">
                        <Pagination onChange={this.paginate} defaultCurrent={this.state.currentPage} total={this.state.total_records} showSizeChanger={false} pageSize={15} />
                      </div> : null}
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-12">
                      <h6 className="mb-3">Notes For Doctor</h6>
                      <div className="bg-white-container w-100">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Patient Notes</th>
                              <th>Patient Documents</th>
                            </tr>
                          </thead>
                          <tbody>
                            {alerts?.length > 0 ? (
                              alerts.map(al => {
                                const testDoc = [...al.testdocument];
                                testDoc.forEach(td => td['type'] = (IMAGE_EXT(td.file).toLowerCase() !== 'pdf' ? 'img' : 'pdf'));
                                return (
                                  <tr>
                                    <td style={{ whiteSpace: 'nowrap' }} scope="row">
                                      {moment(al.date).format('D MMM YYYY | h:mm A')}
                                    </td>
                                    <td className="word-wrap">
                                      {al.patient_note_doctor}
                                    </td>
                                    <td>
                                      {testDoc.length > 0 ? (
                                        testDoc.map((td, index) => (
                                          <a href="#" className="show-rx-modal" key={index} onClick={e => this.handleModalPopup(e, 'viewDocumentModal', null, { index, images: testDoc })}>
                                            {/* <img className="mr-2 mb-1" alt='Doc IImg' src={td.type === 'img' ? icJpg : icPdf} /> */}
                                            <i className={`mr-2 mb-1 icon-${td.type === 'img' ? 'jpg' : 'pdf'}`}></i>
                                          </a>
                                        ))
                                      ) : 'nil'}
                                    </td>
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td colSpan="3">No Data</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!dataLoaded && (
                <div className="col-12">
                  <div className="text-center">
                    {LOADER_RED}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/** Document Viewer */}
        <Modal
          title={patient_docments?.length === 1 ? "Document" : "Documents"}
          visible={this.state.viewDocumentModal}
          footer={false}
          width={850}
          onCancel={e => this.handleModalPopup(e, 'viewDocumentModal')}
          style={{ top: 20 }}
        >
          <div className="pdf_slider">
            {
              patient_docments?.length > 0 ? (
                <Carousel arrows dots={false} ref={this.carouselRef}>
                  {
                    patient_docments?.map((file, index) => {
                      return (
                        file.type === 'img' ?
                          <figure key={index}><img className="mr-3" src={file.file} alt='uploaded Doc' /></figure>
                          :
                          <embed key={index} className="mr-3" src={file.file + "#toolbar=0&scrollbar=0&pagemode=none"} />
                      )
                    })
                  }
                </Carousel>
              ) : null
            }
          </div>
        </Modal>

        {/* || VITAL MODAL */}
        <Modal
          title={ this.state.vitalEditId && this.state.vitalEditId != null ? "Edit Vitals" : "Add Vitals"}
          visible={this.state.vitalModal}
          onCancel={(e) => this.handleModalPopup(e, 'vitalModal')}
          style={{ top: 40 }}
          footer={[
            <div className="text-center">
              <Button form="vitalForm" type="primary" htmlType="submit" className="btn btn-primary text-uppercase ml-2"
                disabled={this.state.spin_loading}>
                CONFIRM{this.state.spin_loading ? LOADER : ''}
              </Button>
            </div>
          ]}
          width="910px"
          bodyStyle={{ paddingBottom: '10px' }}
          className="emrfrm"
        >

          <Form
            id="vitalForm"
            className="add-vitals"
            ref={this.vitalFormRef}
            validateMessages={HANDLEVALIDANTMESSAGES}
            onFinish={this.handleVitalAddSubmit}
            layout="vertical"
          >
            {/* <div className="row">
              <div className="col-4">
                <Form.Item
                  name="vitalType"
                >
                  <span className="d-none">{this.state.vitalsType}</span>
                  <div className="form-group mb-1 hasdata">
                    <Select name="vitalsType" id="vitalsType" value={this.state.vitalsType} defaultValue={this.state.vitalsType} onChange={this.handleVitalSelect}>
                      {this.state.selectedVitalGroups.map((vitalGroup, index) => (
                        <Option key={index} value={vitalGroup.id} datavitals={vitalGroup.vitalSignIds}>{vitalGroup.long_description}</Option>
                      ))}
                    </Select>
                    <label htmlFor="vitalsType">Vital Group</label>
                  </div>
                </Form.Item>
              </div>
            </div> */}
            <div className="row">
              {this.state.constants && this.state.homecareVitals && this.state.homecareVitals.map((vital, idx) => {
                const tabIndx = idx + 1;
                if (vital.id == 'V006') return (null)
                else if (vital.id == 'V005')
                  return (<div className="col-4 antInputItem multi-col">
                    <Form.Item name="bp" label="Blood Pressure"
                    //  style={!this.state.displayVitals.includes("5") ? { display: 'none' } : ''}
                      rules={[{
                        required: vital.required,
                        type: 'number',
                        min: vital.min,
                        max: vital.max,
                        transform: function (value) {
                          return value === "" || value === undefined ? value : +value
                        }
                      }]}>
                      <div className="multi-input-col-inner">
                        <div className="ant-input-wrapper ant-input-group">
                          <div className="ant-input">
                            <Input name="V006" data-vitalid="6" tabIndex={tabIndx} autoComplete="off" maxLength={vital.maxlength} min={vital.min} max={vital.max}
                              value={this.state.V006} onChange={(e) => { this.handleOnChangeNumbers(e, vital.min, vital.max) }} />
                            <Input name="V005" data-vitalid="5" tabIndex={tabIndx + 1} autoComplete="off" maxLength={vital.maxlength} min={vital.min} max={vital.max}
                              className="multi" value={this.state.V005} onChange={(e) => { this.handleOnChangeNumbers(e, vital.min, vital.max) }} />
                          </div>

                          <span className="ant-input-group-addon">mmHg</span>
                        </div>
                      </div>
                    </Form.Item>
                  </div>)
                else
                  return (
                    <div className="col-4 antInputItem">
                      <Form.Item name={vital.id} label={vital.display_name}
                        // style={!this.state.displayVitals.includes("" + vital.vital_id) ? { display: 'none' } : ''}
                        rules={[{
                          required: vital.required,
                          type: 'number',
                          min: vital.min,
                          max: vital.max,
                          transform: function (value) {
                            return value === "" || value === undefined ? value : +value
                          }
                        }]}>

                        <Input addonAfter={vital.display_value} name={vital.id} autoComplete="off" maxLength={vital.maxlength} data-vitalid={vital.vital_id} tabIndex={tabIndx}
                          value={this.state[vital.id]} onChange={(e) => { this.handleOnChangeNumbers(e, vital.min, vital.max) }} disabled={vital.id == 'V003'} />
                      </Form.Item>
                    </div>
                  )
              })}
            </div>
          </Form>
        </Modal>


        {/* || CHART MODAL */}
        {this.state.chartModal?
        <Modal
          title={this.state.chartHeading}
          visible={this.state.chartModal}
          onCancel={(e) => this.handleModalPopup(e, 'chartModal')}
          style={{ top: '40px', maxWidth: '90%' }}
          width="100%"
          className={`all_vital`}
          bodyStyle={{ maxHeight: '700px', overflowY: 'auto' }}
          footer={false}
        >

          <div className="col wrap_chart_filter d-flex justify-content-around align-items-center">
            <ul className="chart_filter">
              <li>
                <button type="button" className={`btn btn-default chart-day ${this.state.chartsTab === '0' ? 'active' : ''}`}
                  onClick={(e) => this.handleGraphClick(e, '0')}>DAY</button>
              </li>
              <li>
                <button type="button" className={`btn btn-default chart-week ${this.state.chartsTab === '1' ? 'active' : ''}`}
                  onClick={(e) => this.handleGraphClick(e, '1')}>WEEK</button>
              </li>
              <li>
                <button type="button" className={`btn btn-default chart-month ${this.state.chartsTab === '2' ? 'active' : ''}`}
                  onClick={(e) => this.handleGraphClick(e, '2')}>MONTH</button>
              </li>
              <li>
                <button type="button" className={`btn btn-default chart-year ${this.state.chartsTab === '3' ? 'active' : ''}`}
                  onClick={(e) => this.handleGraphClick(e, '3')}>YEAR</button>
              </li>
            </ul>

            <RangePicker
              allowClear={true}
              value={this.state.range}
              onChange={this.handleRangeChange}
              disabledDate={DISABLED_FUTURE_DATES}
              format="DD/MM/YYYY"
            />
          </div>

          {this.state.chartDataLoaded ? (
            <Chart
              chartData={this.state.chartData}
              bloodSugarChartData={this.state.bloodSugarChartData}
              vitalsRangeWithId={this.state.vitalsRangeWithId}
              dataLoaded={this.state.chartDataLoaded}
              chartsType={this.state.chartsType}
              reference_id={this.state.reference_id}
              chartGraphSetting={this.state.chartGraphSettings}
              chartsTab={this.state.chartsTab}
              constants={this.state.constants}
              checkBoxDisable={this.state.appointment_status === 2 || (LS_SERVICE.get('user_type') === USERTYPES.nurse)}
              updateData={this.chartStateUpdate}
              showVitalType={'homecare'}
            />
          ) : null}

        </Modal>
        : null }


        {/* || REASON MODAL */}
        <Modal
          title={`Reason for Change`}
          visible={this.state.reasonModal}
          onCancel={(e) => this.handleModalPopup(e, 'reasonModal')}
          style={{ top: '40px' }}
          footer={[
            <div className="text-center">
              <Button type="secondary" className="btn btn-secondary text-uppercase"
                onClick={e => this.handleModalPopup(e, 'reasonModal')}>
                CANCEL
              </Button>
              <Button form="reasonForm" type="primary" htmlType="submit" className="btn btn-primary text-uppercase ml-2"
                disabled={this.state.spin_loading}>
                SAVE{this.state.spin_loading ? LOADER : ''}
              </Button>
            </div>
          ]}
          className="emrfrm"
        >

          <Form
            id="reasonForm"
            ref={this.reasonFormRef}
            validateMessages={HANDLEVALIDANTMESSAGES}
            onFinish={this.handleReasonFormSubmit}
            layout="vertical"
          >

            <Radio.Group
              className="d-flex flex-column"
              onChange={this.handleAntRadioChange}
              value={this.state.reasonChangeValue}>

              {this.reasonChange.map((rc, i) =>
                <Radio value={rc.value} className="mb-2" key={rc.value}>
                  {rc.reason}
                </Radio>
              )}

            </Radio.Group>

            {this.state.reasonChangeValue === 2 && (
              <Form.Item name="reason"
                rules={[{
                  required: true,
                }]}>
                <TextArea placeholder="Enter Reason" className="w-100 mt-2" rows={3} />
              </Form.Item>
            )}

          </Form>

        </Modal>

      </div >
    )
  }
}

export default HomecareVitals