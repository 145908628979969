import React, { Component } from 'react'
import AsideLeft from '../../components/aside/asideleft';
import { DOCTOR_LIST, REFERAL_INFORMATION, SAVE_REFERRAL, DELETE_REFERRAL, SPECIALITY_LIST, LOADER_BLACK, LOADER, MYHC_APPTYPE, WOODLANDS, REFERRAL_LIST,TOGGLE_PRACTICE } from '../../utils/constant'
import { Form, Modal, Input, Radio, Button, Select, notification } from 'antd';
import qs from 'qs';
import Axios from 'axios';
import { jQueryLabels } from '../../utils/jQueryLabels';
import LS_SERVICE from '../../utils/localStorage';
import API_SERVICE from '../../utils/apiService';
import { headerService$, practiceService, speechService, speechTextService } from '../../utils/rxjs-sharing';
import MyHcSpeechRecognition from '../speech-to-text/speech-recognition';
import { Link } from 'react-router-dom';

const { Option } = Select;


export class Referral extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patient: null,
            data_selected: "",
            speciality_selected_name: "",
            doctor_selected_name: "",
            doc_note: '',
            stage_radio: '1',
            isDocLIstFetching: false,
            isDoctorDataLoaded: false,
            docList: [],
            isDoc: MYHC_APPTYPE === WOODLANDS,
            tableListing: [],
            specialityList: [],
            isSpecLIstFetching: false,
            isSpecLIstLoaded: false,
            spec_selected: '',
            formSaving: false,
            speech: {
                identifier: null,
                state: false
            },
            isPracticing: false,
            isPracticingModal: false
        }
    }

    componentDidMount() {
        Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");

        if (this.props.location.state !== undefined) {
            this.setState({
                //patient: this.props?.location?.state?.patient,
                sourceOfRerral:this.props?.location?.state?.sourceOfRerral,
            }, () => {
                //this.handleSerachSpecList('');
                //this.handleReferralInformation();
            })
         }
          //else {
        //     this.getPatientData();
        // }

        const { match: { params } } = this.props;
        const { hospital_id, patient_id, reference_id } = params;

        const isPracticingHospitalId = LS_SERVICE.get('practicing')?.hospital_id;

        if (+isPracticingHospitalId === +hospital_id) {
            this.setState({
                isPracticing: true
            })
        }

        const patient = {
            hospital_id,
            patient_id,
            qms_token_id: reference_id,
        }
        this.setState({
            hospital_id,
            patient_id,
            reference_id,
            patient
        }, () => {
            if (LS_SERVICE.has('hospitalConfigData')) {
                const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
                let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
                headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' }); // IMPORTANT TO HIT AS PER THE VIEW
                this.handleSerachSpecList('');
                this.handleReferralInformation();
                jQueryLabels();

            }
        });

        this.subscription = speechTextService.status().subscribe(data => {
            if (this.state.speech.identifier === 'doctors-note' && this.state.speech.state) {
                this.setState({ doc_note: data.text });
            }
            // document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
        });
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    goBack = (event) => {
        event.preventDefault();
        this.props.history.goBack();
        LS_SERVICE.set('blockname', 'referral');
    }

    handleOnClick = _ => {
        this.setState({
            isDoctorDataLoaded: false
        })
    }

    handleSerachDoctorList = search => {
        const { match: { params } } = this.props;
        this.setState({ docList: [], isDocLIstFetching: true });
        const { hospital_id } = params;
        const PARAMS = { search, hospital_id }
        Axios.post(DOCTOR_LIST, qs.stringify(PARAMS))
            .then(res => {
                this.setState({
                    docList: res.data.status ? res.data.data.results : [],
                    isDocLIstFetching: false,
                    isDoctorDataLoaded: true
                })
            })
            .catch(err => {
                this.setState({
                    docList: [],
                    isDocLIstFetching: false,
                    isDoctorDataLoaded: true
                })
            })
    }

    handleReferralInformation = _ => {
        const { patient } = this.state;

        const PARAMS = {
            referenceType: LS_SERVICE.get('reference_type'),
            referenceId: patient.qms_token_id
        }

        Axios.post(REFERRAL_LIST, qs.stringify(PARAMS))
            .then(res => {
                this.setState({
                    tableListing: res.data.data?.results,
                })
            })
    }

    handleSerachSpecList = search => {
        this.setState({ specialityList: [], isSpecLIstFetching: true });

        const PARAMS = { search }

        Axios.post(SPECIALITY_LIST, qs.stringify(PARAMS))
            .then(res => {
                this.setState({
                    specialityList: res.data.status ? res.data.data.results : [],
                    isSpecLIstFetching: false,
                    isSpecLIstLoaded: true
                })
            })

        // if (docDataPromise.status) {
        //     let { result } = docDataPromise.data;
        //     this.setState({ docList: result, isDocLIstFetching: !this.state.formIsFetching });
        // }
    }


    handleDocListChange = async (doctor_selected, props) => {
        const allergy_selected_data = {};
        Object.assign(allergy_selected_data, props.dataid);

        await this.setState({
            data_selected: allergy_selected_data,
            speciality_selected_name: doctor_selected,
            doctor_selected_name: doctor_selected
            // allergy_selected_data
        });
    }


    handleRadio = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    handleReferralSave = () => {

        const { stage_radio, isDoc, doc_note, data_selected, patient, speech, isPracticing } = this.state;

        if (!isPracticing) {
            this.handleModalPopup(null, 'isPracticingModal');
            return;
        }

        if (speech.state) {
            this.initSpeech({ identifier: 'doctors-note' })
        }

        this.setState({
            formSaving: true
        })

        const PARAMS = {
            type: (data_selected.speciality_id == null) ? "doctor" : "speciality",
            priority: stage_radio,
            instructions: doc_note,
            referenceType: LS_SERVICE.get('reference_type'),
            referenceId: patient.qms_token_id,
            referalDoctorId: null,
            referid: null,
        }

        if (PARAMS.type === 'speciality') {
            PARAMS.speciality = data_selected.speciality_id
        } else {
            PARAMS.doctor = data_selected.staff_id;
        }

        Axios.post(SAVE_REFERRAL, qs.stringify(PARAMS))
            .then(res => {
                // this.setState({
                //     tableListing: res.data.data,
                // })
                // LS_SERVICE.set('referralTable', res.data.data);
                this.setState({
                    formSaving: false
                }, this.handleReset)
                this.handleReferralInformation();
            })
            .catch(err => {
                this.setState({
                    tableListing: [],
                }, this.handleReset)
            })
    }


    handleReset = () => {
        this.setState({
            doc_note: '',
            priority: 0,
            stage_radio: '1',
            data_selected: null,
            speciality_selected_name: '',
            doctor_selected_name: ''

        })
    }

    handleConfirm = () => {
        const { patient, sourceOfRerral, isPracticing } = this.state;
        const { hospital_id, patient_id, qms_token_id } = patient;
        const REFERENCE_TYPE = LS_SERVICE.get('reference_type')
        if (!isPracticing) {
            this.handleModalPopup(null, 'isPracticingModal');
            return;
        }
        
        let backpath;
        if (sourceOfRerral === 'viewPrescription') {
          if(LS_SERVICE.get('soap_type') == 'single'){
            backpath = `/patient/todays-visit/view-prescriptionss/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`
          }
          else{
            backpath = `/patient/todays-visit/view-prescription/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`
          }
        } else {
          if(LS_SERVICE.get('soap_type') == 'single'){
            backpath = `/patient/soapss/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`
          }
          else{
            backpath = `/patient/todays-visit/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`
          }
        }

        this.props.history.push({
            pathname: backpath,
            state: { patient }
        })
        LS_SERVICE.set('blockname', 'referral');
    }

    handleEditDelete = (e, isDelete, data) => {
        e.preventDefault();
        // const { id } = e.target.parentElement.parentElement.dataset;
        const { tableListing } = this.state;
        // const data = tableListing.filter(item => id == item.id)[0];

        if (!isDelete) {
            const stage_radio = data.priorityname == "Urgent" ? '0' : '1';
            this.setState({
                doc_note: data.instructions,
                stage_radio
            })

            if (data.doctor.doctor_id != null) {
                this.setState({
                    data_selected: { ...data.doctor },
                    isDoc: true,
                    doctor_selected_name: data.doctor.doctor_name + ' (' + data.speciality.name + ')'
                })
            } else {
                this.setState({
                    data_selected: { ...data.speciality },
                    isDoc: false,
                    speciality_selected_name: data.speciality.name
                })
            }
        } else {
            Axios.post(DELETE_REFERRAL, qs.stringify({ id: data.id }))
                .then(res => {
                    this.handleReferralInformation();
                    // const tableData = tableListing.filter(item => data.id != item.id)
                    // LS_SERVICE.set('referralTable', tableData)
                    // this.setState({
                    //     tableListing: tableData
                    // })
                })
        }
    }

    handleTabChange = e => {
        e.preventDefault();

        this.setState({
            isDoc: !this.state.isDoc,
        }, this.handleReset)
    }

    handleSaveButtonDisabled = _ => {
        const { doc_note, speciality_selected_name, doctor_selected_name, formSaving, isDoc } = this.state;

        if (formSaving) return true;

        if (isDoc) {
            if (doc_note.length === 0 || doctor_selected_name.length === 0) return true;
        } else {
            if (doc_note.length === 0 || speciality_selected_name.length === 0) return true;
        }

        return false;
    }

    initSpeech(info) {
        let initialText = '';
        if (info.identifier === 'doctors-note') {
            initialText = this.state.doc_note != undefined ? this.state.doc_note : '';
        }

        if (info.identifier === this.state.speech.identifier) {
            this.setState({
                speech: {
                    identifier: info.identifier,
                    state: !this.state.speech.state,
                    initialText: initialText
                }
            }, () => {
                speechService.init(this.state.speech);
            });
        } else {
            this.setState({
                speech: {
                    identifier: this.state.speech.identifier,
                    state: false,
                    initialText: ''
                }
            }, () => {
                speechService.init(this.state.speech);
                setTimeout(() => {
                    this.setState({
                        speech: {
                            identifier: info.identifier,
                            state: true,
                            initialText: initialText
                        }
                    }, () => {
                        speechService.init(this.state.speech);
                    });

                }, 1000);
            });
        }
    }

    handleModalPopup = (e, popup) => {

        if (e !== null) e.preventDefault();

        this.setState({
            [popup]: !this.state[popup]
        })
    }

    enablePractice = async e => {
        e.preventDefault();
        practiceService.init({ initiater: 'SOAP Pages', status: 'start' });
        const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
          hospital_id: this.state.hospital_id,
          practice_session: 'start'
        });
    
        let is_practicing;
        if (practice.data && practice.data.is_practicing === 'Y') {
          is_practicing = true;
          LS_SERVICE.set('practicing', {
            status: true,
            hospital_id: this.state.hospital_id
          });
        } else {
            LS_SERVICE.delete('practicing');
          is_practicing = false;
        }
        this.setState({
            isPracticing: is_practicing,
        }, () => {
            this.handleModalPopup(null, 'isPracticingModal');
        });
    }

    render() {
        const { patient, docList, isDocLIstFetching, stage_radio, tableListing, doc_note, isDoc, specialityList,
            isDoctorDataLoaded, speciality_selected_name, doctor_selected_name } = this.state;

        return (
            <>
                <div>
                    {(patient != undefined && patient.patient_id != undefined) ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}

                    {/* <MyHcSpeechRecognition /> */}

                    <div className="content-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="float-left">
                                        <h1 className="page-title">Referral</h1>
                                    </div>
                                    <div className="float-right">
                                        <a href="#" className="btn btn-outline-secondary px-4 small text-uppercase" onClick={this.goBack}>BACK</a>
                                    </div>
                                </div>
                                <div className="col-12">
                                        <ul className="nav nav-pills justify-content-center myhealthcare-tabs">
                                            <li className="nav-item">
                                                <a href="#" className={`nav-link ${!isDoc ? 'active' : ''}`} onClick={this.handleTabChange}>
                                                    By Speciality
                                            </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className={`nav-link ${isDoc ? 'active' : ''}`} onClick={this.handleTabChange}>
                                                    By Doctor
                                            </a>
                                            </li>
                                        </ul>
                                    <div className="tab-content" id="pills-tabContent">

                                        <div className={`tab-pane fade ${!isDoc ? 'show active' : ""} `} id="pills-speciality" role="tabpanel" aria-labelledby="pills-Speciality-tab">
                                            <Form
                                                className="emrfrm"
                                                ref={this.specialityRef}
                                            >
                                                <div className="row mt-4">
                                                    <div className="col-md-6">

                                                        <Form.Item
                                                            id="speciality_selected"
                                                            name="speciality_selected"
                                                            label="Speciality"
                                                            rules={[{ required: true }]}
                                                        >
                                                            <span className="d-none">{speciality_selected_name}</span>
                                                            <Select
                                                                showSearch
                                                                autoFocus
                                                                value={speciality_selected_name}
                                                                optionFilterProp="children"
                                                                notFoundContent={null}
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                onChange={this.handleDocListChange}
                                                                style={{ width: '100%' }}
                                                            >
                                                                {specialityList !== undefined && specialityList.length > 0 ? specialityList.map(d => (
                                                                    <Option key={d.speciality_id} dataid={d} label={d.name}>{d.name}</Option>
                                                                )) : null}
                                                            </Select>

                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group mt-1">
                                                            <div className="custom-control custom-radio custom-control-inline mr-5">
                                                                <input type="radio" id="stage_urgent" name="stage_radio" className="custom-control-input float-right"
                                                                    value='0' checked={0 == stage_radio} onChange={this.handleRadio} />
                                                                <label className="custom-control-label small" htmlFor="stage_urgent">Urgent</label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="stage_routine" name="stage_radio" className="custom-control-input float-right"
                                                                    value='1' checked={1 == stage_radio} onChange={this.handleRadio} />
                                                                <label className="custom-control-label small" htmlFor="stage_routine">Routine</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className={`form-group micwrap ${doc_note !== "" ? "hasdata" : ""}`}>
                                                            <Link
                                                                onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'doctors-note' }) }}
                                                                className={(this.state.speech.identifier === 'doctors-note' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                                                            {/* <label htmlFor="doctors-note" className="control-label">Chief Complaints <span className="text-danger">*</span></label> */}
                                                            <textarea className="form-control" style={{ minHeight: "85px" }} rows={1} placeholder="Doctor's Note"
                                                                value={doc_note} onChange={(e) => this.setState({ doc_note: e.target.value })} />
                                                            <label htmlFor="doctor-note" className="required">
                                                                Doctor's Note<span>*</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <div className="float-right form-group">
                                                            <button type="button" className="btn btn-outline-secondary  mr-3" onClick={this.handleReset}>CLEAR</button>
                                                            <button type="button" className="btn btn-primary text-uppercase" disabled={this.handleSaveButtonDisabled()}
                                                                onClick={this.handleReferralSave}>
                                                                Save{this.state.formSaving ? LOADER : null}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Form>
                                        </div>

                                        
                                        <div className={`tab-pane fade ${isDoc ? 'show active' : ""} `} id="pills-doctor" role="tabpanel" aria-labelledby="pills-Doctor-tab">
                                            <form className="emrfrm">
                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <Form.Item
                                                            id="doctor_selected"
                                                            name="doctor_selected"
                                                            label="Doctor's Name"
                                                            rules={[{ required: true }]}
                                                        >
                                                            <span className="d-none">{doctor_selected_name}</span>
                                                            <Select
                                                                showSearch
                                                                autoFocus
                                                                value={doctor_selected_name}
                                                                notFoundContent={isDocLIstFetching ? LOADER_BLACK : (isDoctorDataLoaded ? 'No Results' : null)}
                                                                filterOption={false}
                                                                loading={isDocLIstFetching}
                                                                onSearch={this.handleSerachDoctorList}
                                                                onChange={this.handleDocListChange}
                                                                onClick={this.handleOnClick}
                                                                style={{ width: '100%' }}
                                                                disabled={false}
                                                            >
                                                                {docList !== undefined && docList.length > 0 ? docList.map(d => (
                                                                    <Option key={d.staff_id} dataid={d} label={d.first_name + (d.last_name != null ? ' '+d.last_name : '') + '(' + d.speciality_name + ')'}>{'Dr. '+d.first_name + (d.last_name != null ? ' '+d.last_name : '')} ({d.speciality_name})</Option>
                                                                )) : null}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group mt-1">
                                                            <div className="custom-control custom-radio custom-control-inline mr-5">
                                                                <input type="radio" id="stage_urgent" name="stage_radio" className="custom-control-input float-right"
                                                                    value='0' checked={0 == stage_radio} onChange={this.handleRadio} />
                                                                <label className="custom-control-label small" htmlFor="stage_urgent">Urgent</label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="stage_routine" name="stage_radio" className="custom-control-input float-right"
                                                                    value='1' checked={1 == stage_radio} onChange={this.handleRadio} />
                                                                <label className="custom-control-label small" htmlFor="stage_routine">Routine</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className={`form-group micwrap ${doc_note != "" ? "hasdata" : ""}`}>
                                                            <Link
                                                                onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'doctors-note' }) }}
                                                                className={(this.state.speech.identifier === 'doctors-note' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                                                            <textarea className="form-control" style={{ minHeight: "85px" }} rows={1} placeholder="Doctor's Note"
                                                                value={doc_note} onChange={(e) => this.setState({ doc_note: e.target.value })} />
                                                            <label htmlFor="doctor-note2" className="required">
                                                                Doctor's Note <span>*</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <div className="float-right form-group">
                                                            <button type="button" className="btn btn-outline-secondary  mr-3" onClick={this.handleReset}>CLEAR</button>
                                                            <button type="button" className="btn btn-primary text-uppercase" disabled={this.handleSaveButtonDisabled()}
                                                                onClick={this.handleReferralSave}>
                                                                Save{this.state.formSaving ? LOADER : null}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                        <div className={`row ${tableListing?.length > 0 ? "" : "d-none"}`}>
                                            <div className="col-md-12 mt-4">
                                                <div className="table-responsive">
                                                    <table className="table table-default">
                                                        <thead>
                                                            <tr>
                                                                <th width={200}>Speciality</th>
                                                                <th width={200}>Doctor name</th>
                                                                <th>Doctor Note</th>
                                                                <th width={100}>Priority</th>
                                                                <th width={100}>Edit/Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {tableListing?.map(item => (
                                                                <tr key={item.id}>
                                                                    <td>{item.speciality.name == "" ? "-" : item.speciality.name}</td>
                                                                    <td>{item.doctor.doctor_name == "" ? "-" : item.doctor.doctor_name.replace("null", "")}</td>
                                                                    <td>{item.instructions}</td>
                                                                    <td>{item.priorityname}</td>
                                                                    <td className="action" data-id={item.id} >
                                                                        <a href="#" onClick={(e) => this.handleEditDelete(e, false, item)}><i className="icon-edit" /></a>
                                                                        <a href="#" onClick={(e) => this.handleEditDelete(e, true, item)}><i className="icon_delete" /></a>
                                                                    </td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fixed-cta">
                                            <button className="btn btn-primary px-5 text-uppercase" onClick={this.handleConfirm}>Confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/** || isPracticing Modal */}
                <Modal
                    title={false}
                    closable={false}
                    visible={this.state.isPracticingModal}
                    footer={false}
                >
                    <div className="row">
                        <div className="col-12 my-3 text-center">
                            <h6>Do you want to Start Practice?</h6>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <div className="btn btn-outline-secondary px-5" onClick={e => this.setState({ isPracticingModal: false })}>No</div>
                        <div className="btn btn-primary px-5" onClick={this.enablePractice}>Yes</div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default Referral
