
import React, { Component } from 'react';
import AsideLeft from '../../components/aside/asideleft';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { NavLink, withRouter } from "react-router-dom";
//import subheader from "../../components/subheader/subheader";
import { DatePicker, Radio, Modal, Select, Button, Form, Checkbox, Input, Tooltip, AutoComplete } from 'antd';
import icImagereference from '../../assets/images/ic-imagereference.png';
import lactatingImage from '../../assets/images/mother-with-baby.svg'
import SearchImg from '../../assets/images/ic-search.svg';
import $ from 'jquery';

const { Option } = Select;

class soapsingle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            copyPreviousModal: false,
            showAssessmentModal: false,
            planFavMedicineModal: false,
            planFavTestsModal: false,
            addDiagnosisModal: false,
            addMedicineModal: false,

            menstrualViewPastModal: false,
            sexualViewPastModal: false,
            obstetricsPrevPregModal: false,
            obstetricsViewPastModal: false,
            physicalViewPastModal: false,

            vitalViewModal: false,
            vitalKeyFindModal: false,
            keyFindingsModal: false,

            addAllergiesModal: false,
            addConditionsModal: false,
            addSurgicalModal: false,
            addSocialModal: false,
            addFamilyModal: false,

            addInstructionsModal: false,
            addReferralModal: false,

            showSuggestedCareModal: false,
            careProtocolparticularModal: false,
            diagnosisparticularModal: false,
            supportingUploadModal: false,
        }
        this.myRef = React.createRef()
    }

    autoHeight = (e) => {
        if (e)
            e.target.style.height = e.target.scrollHeight + "px";
    }
    handleCancel = () => {
        this.setState({ copyPreviousModal: false });
        this.setState({ showAssessmentModal: false });
        this.setState({ planFavMedicineModal: false });
        this.setState({ planFavTestsModal: false });
        this.setState({ addDiagnosisModal: false });
        this.setState({ addMedicineModal: false });

        this.setState({ menstrualViewPastModal: false });
        this.setState({ sexualViewPastModal: false });
        this.setState({ obstetricsPrevPregModal: false });
        this.setState({ obstetricsViewPastModal: false });
        this.setState({ physicalViewPastModal: false });

        this.setState({ vitalViewModal: false });
        this.setState({ vitalKeyFindModal: false })
        this.setState({ keyFindingsModal: false })

        this.setState({ addAllergiesModal: false });
        this.setState({ addConditionsModal: false });
        this.setState({ addSurgicalModal: false });
        this.setState({ addSocialModal: false });
        this.setState({ addFamilyModal: false });

        this.setState({ addInstructionsModal: false });
        this.setState({ addReferralModal: false });

        this.setState({ showSuggestedCareModal: false });
        this.setState({ careProtocolparticularModal: false });
        this.setState({ diagnosisparticularModal: false });
        this.setState({ supportingUploadModal: false });
    }

    render() {
        const refferaltext =
            <>
                <div>REFERRAL DOCTOR</div>
                <div>Dr, Vijay Kumar Sharma,Gasteroenterology</div>
                <div className='mt-3'>DOCTOR'S NOTES</div>
                <div>Please advice diet for high fibre calorie</div>
            </>
        return (
            <>
                <AsideLeft />

                <div className="content-wrapper">
                    <div className="container-fluid emrfrm ">

                        {/* <div className="row soapv2_fixed_top">
                            <div className="col-12 mb-3">
                                <ul className="nav nav-pills keyfind_nav" id="ulCategory">
                                    <li className="nav-item">
                                        <a data-toggle="collapse" data-target="#collapseSubjective" aria-expanded="true" aria-controls="collapseSubjective" className="nav-link active" onClick={() => this.scrollSubjective()} >Subjective</a>
                                    </li>
                                    <li className="nav-item">
                                        <a data-toggle="collapse" data-target="#collapseObjective" aria-expanded="false" aria-controls="collapseObjective" className="nav-link" onClick={() => this.scrollObjective()}>Objective</a>
                                    </li>
                                    <li className="nav-item">
                                        <a data-toggle="collapse" data-target="#collapseAssessment" aria-expanded="false" aria-controls="collapseAssessment" className="nav-link" onClick={() => this.scrollAssessment()}>Assessment</a>
                                    </li>
                                    <li className="nav-item">
                                        <a data-toggle="collapse" data-target="#collapsePlan" aria-expanded="false" aria-controls="collapsePlan" className="nav-link " onClick={() => this.scrollPlan()}>Plan</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#"
                                            activeClassName='active' className="nav-link gohome"><i className="icon-home"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item goback">
                                        <a href="#" className="btn btn-outline-secondary px-4 py-1 small text-uppercase">
                                            BACK
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div> */}

                        <div className="row single_page">
                            <div className="col-12">
                                <div className="card card-bg-lightblue mb-3 ">
                                    <div className="card-body">
                                        {/* <div class="here middle">Content</div> */}
                                        <div className='container-fluid'>
                                            <div className='row'>
                                                <div className='col-4 '>
                                                    <h6 className='mb-0'>PATIENT NOTES FOR DOCTOR</h6>
                                                </div>
                                                <div className='col-8'>
                                                    <div className='row'>
                                                        <div className='col-xl-7 col-lg-6 text-right'>
                                                            <Tooltip placement="bottomLeft" overlayClassName='soap_tooltip' title={refferaltext} overlayStyle={{ maxWidth: '500px' }}>
                                                                <span class="referral_counter">!</span>
                                                                <span className='text-secondary' style={{ cursor: 'pointer' }}><u>REFERRAL</u></span>
                                                            </Tooltip>

                                                        </div>
                                                        <div className='col-xl-5 col-lg-6 text-right'>
                                                            <a href="#"><span className='text-secondary'><u>UPLOADED DOCUMENTS</u></span></a>
                                                            <span class="upload_counter">12</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-12'>
                                                    <span>I Have been running fever for the last 6 days.

                                                    </span>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div className='container-fluid  mt-0 mb-3'>
                                    <div className='row d-flex align-items-center'>
                                        <div className='col-auto'>
                                            <span className='text-secondary mb-0' style={{ fontSize: '1rem', fontWeight: '500' }}>TODAY'S VITALS</span>
                                            <span className='text-muted pl-3' style={{ fontSize: '12px' }}>5 Apr,2022 | 12:50 PM</span>
                                        </div>
                                        <div className='col-auto pl-3'>
                                            <button type="button" class="btn top-chart-btn p-0 mr-2"><i class="icon-chart"></i></button>
                                            <a className='text-secondary ml-2' onClick={() => { this.setState({ vitalViewModal: true }) }}><u>View All</u></a>
                                        </div>
                                        <div className='col d-flex justify-content-end align-items-center'>

                                            <div className='col-auto'>
                                                <h6 className='text-dark mb-0'>Key Findings</h6>
                                            </div>
                                            <div className='col-auto pr-3'>
                                                <button type="button" class="btn top-chart-btn p-0 mr-2"><i class="icon-chart"></i></button>
                                                <a className='text-secondary ml-2' onClick={() => { this.setState({ vitalKeyFindModal: true }) }}><u>View All</u></a>
                                            </div>
                                            <div className='col-auto'>
                                                <div class="float-right">
                                                    <a class="btn btn-outline-primary px-2" id="key_find" onClick={() => { this.setState({ keyFindingsModal: true }) }}>+ KEY FINDINGS</a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <Form
                                        layout='vertical'
                                        className='emrfrm'
                                    >
                                        <div className='row mt-3'>
                                            <div className='col-2'>
                                                <div className='antInputItem hasdata'>
                                                    <Form.Item
                                                        label="Height"
                                                        name="height"
                                                    >
                                                        <Input
                                                            addonAfter="cm"
                                                            id="height"
                                                            name="height"
                                                            type="text"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-2'>
                                                <div className='antInputItem hasdata'>
                                                    <Form.Item
                                                        label="weight"
                                                        name="weight"
                                                    >
                                                        <Input
                                                            addonAfter="Kg"
                                                            id="weight"
                                                            name="weight"
                                                            type="text"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-2'>
                                                <div className='antInputItem hasdata'>
                                                    <Form.Item
                                                        label="BMI"
                                                        name="bmi"
                                                        className='vitals_bmi'
                                                    >
                                                        <Input
                                                            addonAfter="㎡"
                                                            id="bmi"
                                                            name="bmi"
                                                            type="text"

                                                            defaultValue={"88"}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='col-2'>
                                                <div className='antInputItem hasdata'>
                                                    <Form.Item
                                                        label="Pulse"
                                                        name="pulse"
                                                    >
                                                        <Input
                                                            addonAfter="BPM"
                                                            id="pulse"
                                                            name="pulse"
                                                            type="text"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-2'>
                                                <div className='antInputItem hasdata'>
                                                    <Form.Item
                                                        label="Heart Rate"
                                                        name="heartrate"
                                                    >
                                                        <Input
                                                            addonAfter="BPM"
                                                            id="heartrate"
                                                            name="heartrate"
                                                            type="text"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-2'>
                                                <div className='antInputItem hasdata'>
                                                    <Form.Item
                                                        label="SpO2"
                                                        name="spo2"
                                                    >
                                                        <Input
                                                            addonAfter="&#37;"
                                                            id="spo2"
                                                            name="spo2"
                                                            type="text"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-2'>
                                                <div className='antInputItem hasdata'>
                                                    <Form.Item
                                                        label="Temp"
                                                        name="temp"
                                                    >
                                                        <Input
                                                            addonAfter="F"
                                                            id="temp"
                                                            name="temp"
                                                            type="text"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-2 antInputItem hasdata multi-col vital_bp_color">
                                                <Form.Item
                                                    name="bp"
                                                    label="BP"
                                                    className='vital_bp_color'
                                                >
                                                    <div className="multi-input-col-inner">
                                                        <div className="ant-input-wrapper ant-input-group">
                                                            <div className="ant-input">
                                                                <Input


                                                                />
                                                                <Input

                                                                />
                                                            </div>

                                                            <span className="ant-input-group-addon">mmHg</span>
                                                        </div>
                                                    </div>
                                                </Form.Item>
                                            </div>
                                            <div className='col-2'>
                                                <div className='antInputItem hasdata'>
                                                    <Form.Item
                                                        label="FBS"
                                                        name="fbs"
                                                    >
                                                        <Input
                                                            addonAfter="mg/dL"
                                                            id="fbs"
                                                            name="fbs"
                                                            type="text"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-2'>
                                                <div className='antInputItem hasdata'>
                                                    <Form.Item
                                                        label="PPBS"
                                                        name="ppbs"
                                                    >
                                                        <Input
                                                            addonAfter="mg/dL"
                                                            id="ppbs"
                                                            name="ppbs"
                                                            type="text"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-2'>
                                                <div className='antInputItem hasdata'>
                                                    <Form.Item
                                                        label="RBS"
                                                        name="rbs"
                                                    >
                                                        <Input
                                                            addonAfter="mg/dL"
                                                            id="rbs"
                                                            name="rbs"
                                                            type="text"
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                    </Form>

                                </div>


                                <div className="accordion soap_accordion" id="accordionExample">

                                    <div className="card pathistory-card">
                                        <div className="card-header pathistory-card-head p-0" id="headingTwo">
                                            <button className="btn  btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" type="button" data-toggle="collapse" data-target="#collapsePatientHistory" aria-expanded="false" aria-controls="collapsePatientHistory">
                                                <div className='row'>
                                                    <div className='col-2'>
                                                        <h6>PATIENT HISTORY</h6>
                                                    </div>
                                                    <div className='col-2'>
                                                        <span className='checkmark'></span>
                                                        <span className='pl-2'>Allergies</span>
                                                    </div>
                                                    <div className='col-2'>
                                                        <span className='checkmark'></span>
                                                        <span className='pl-2'>Existing Conditions</span>
                                                    </div>
                                                    <div className='col-2'>
                                                        <span className='pl-2'>Surgeries</span>
                                                    </div>
                                                    <div className='col-2'>
                                                        <span className='pl-2'>Social Hx</span>
                                                    </div>
                                                    <div className='col-2'>
                                                        <span className='checkmark'></span>
                                                        <span className='pl-2'>Family Hx</span>
                                                    </div>
                                                </div>


                                            </button>
                                        </div>
                                        <div id="collapsePatientHistory" className="collapse" aria-labelledby="headingTwo" >
                                            <div className="card-body pathistory_body p-0">
                                                <div className='container-fluid emrfrm pathistory_container mt-3'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <h6 className='text-secondary mb-0'>BLOOD GROUP</h6>
                                                        </div>
                                                    </div>
                                                    <div className='row my-2'>
                                                        <div className='col-4 my-1'>
                                                            <div className='card pathistory_card p-3'>
                                                                <Form
                                                                    layout='vertical'
                                                                    className='emrfrm'
                                                                >
                                                                    <div className='row d-flex align-items-center'>
                                                                        <div className='col-5'>
                                                                            <div className="antSelectItem hasdata">
                                                                                <Form.Item
                                                                                    label="Blood Group"
                                                                                    name="blood_group"
                                                                                >
                                                                                    <Select
                                                                                        style={{ width: '100%' }}
                                                                                        name="blood_group"
                                                                                    >
                                                                                        <Option value="reg1">
                                                                                            A+
                                                                                        </Option>
                                                                                        <Option value="reg2">
                                                                                            B+
                                                                                        </Option>
                                                                                    </Select>
                                                                                </Form.Item>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-7'>
                                                                            <span className='text-secondary'>Informed by patient</span>
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row align-items-center'>
                                                        <div className='col-4'>
                                                            <h6 className='text-secondary mb-0'>ALLERGIES</h6>
                                                        </div>
                                                        <div className='col-4'>
                                                            <span class="text-dark mr-2" style={{ fontWeight: '500' }}>Severity</span>
                                                            <ul class="severity_list">
                                                                <li><i class="icon-severity-low"></i> Low</li>
                                                                <li><i class="icon-severity-medium"></i> Moderate</li>
                                                                <li><i class="icon-severity-high"></i> High</li>
                                                            </ul>
                                                        </div>
                                                        <div className='col-2 d-flex justify-content-center'>
                                                            <Checkbox
                                                                name="no_allergies"
                                                            >
                                                                <span>No Known Allergies</span>
                                                            </Checkbox>
                                                        </div>
                                                        <div className='col-2'>
                                                            <div class="float-right">
                                                                <a class="btn btn-outline-primary" id="add_allergies" onClick={() => { this.setState({ addAllergiesModal: true }) }}>+ ALLERGIES</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row my-2'>
                                                        <div className='col-4 my-1'>
                                                            <div className='card pathistory_card p-3'>
                                                                <div className='row'>
                                                                    <div className='col-1 align-self-center'>
                                                                        <i class="icon-severity-low"></i>
                                                                    </div>
                                                                    <div className='col-8'>
                                                                        <span className='obj_card_text'>Asthma (Seretide 250mg,Budecort 0.5)</span><br />
                                                                        <span className='obj_duration_text'>Duration: 20 Years</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span class="float-right">
                                                                            <a class="nostyle-link edit-allergy pr-1"><i class="icon-edit"></i></a>
                                                                            <a class="nostyle-link remove-list remove-allergy pl-1"><i class="icon_delete"></i></a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-4 my-1'>
                                                            <div className='card pathistory_card p-3'>
                                                                <div className='row'>
                                                                    <div className='col-1 align-self-center'>
                                                                        <i class="icon-severity-medium"></i>
                                                                    </div>
                                                                    <div className='col-8'>
                                                                        <span className='obj_card_text'>Asthma (Seretide 250mg,Budecort 0.5)</span><br />
                                                                        <span className='obj_duration_text'>Duration: 20 Years</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span class="float-right">
                                                                            <a class="nostyle-link edit-allergy pr-1"><i class="icon-edit"></i></a>
                                                                            <a class="nostyle-link remove-list remove-allergy pl-1"><i class="icon_delete"></i></a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-4 my-1'>
                                                            <div className='card pathistory_card p-3'>
                                                                <div className='row'>
                                                                    <div className='col-1 align-self-center'>
                                                                        <i class="icon-severity-high"></i>
                                                                    </div>
                                                                    <div className='col-8'>
                                                                        <span className='obj_card_text'>Asthma (Seretide 250mg,Budecort 0.5)</span><br />
                                                                        <span className='obj_duration_text'>Duration: 20 Years</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span class="float-right">
                                                                            <a class="nostyle-link edit-allergy pr-1"><i class="icon-edit"></i></a>
                                                                            <a class="nostyle-link remove-list remove-allergy pl-1"><i class="icon_delete"></i></a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row align-items-center'>
                                                        <div className='col-4'>
                                                            <h6 className='text-secondary mb-0'>EXISTING CONDITIONS</h6>
                                                        </div>
                                                        <div className='col-6 d-flex justify-content-end'>
                                                            <Checkbox
                                                                name="no_surgical"
                                                            >
                                                                <span>No Known Existing Condition</span>
                                                            </Checkbox>
                                                        </div>
                                                        <div className='col-2'>
                                                            <div class="float-right">
                                                                <a class="btn btn-outline-primary" id="add_allergies" onClick={() => { this.setState({ addConditionsModal: true }) }}>+ CONDITIONS</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row my-2'>
                                                        <div className='col-4 my-1'>
                                                            <div className='card pathistory_card p-3'>
                                                                <div className='row'>
                                                                    <div className='col-9'>
                                                                        <span className='obj_card_text'>Asthma (Seretide 250mg,Budecort 0.5)</span><br />
                                                                        <span className='obj_duration_text'>Duration: 20 Years</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span class="float-right">
                                                                            <a class="nostyle-link edit-allergy pr-1"><i class="icon-edit"></i></a>
                                                                            <a class="nostyle-link remove-list remove-allergy pl-1"><i class="icon_delete"></i></a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-4 my-1'>
                                                            <div className='card pathistory_card p-3'>
                                                                <div className='row'>
                                                                    <div className='col-9'>
                                                                        <span className='obj_card_text'>Asthma (Seretide 250mg,Budecort 0.5)</span><br />
                                                                        <span className='obj_duration_text'>Duration: 20 Years</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span class="float-right">
                                                                            <a class="nostyle-link edit-allergy pr-1"><i class="icon-edit"></i></a>
                                                                            <a class="nostyle-link remove-list remove-allergy pl-1"><i class="icon_delete"></i></a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-4 my-1'>
                                                            <div className='card pathistory_card p-3'>
                                                                <div className='row'>
                                                                    <div className='col-9'>
                                                                        <span className='obj_card_text'>Asthma (Seretide 250mg,Budecort 0.5)</span><br />
                                                                        <span className='obj_duration_text'>Duration: 20 Years</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span class="float-right">
                                                                            <a class="nostyle-link edit-allergy pr-1"><i class="icon-edit"></i></a>
                                                                            <a class="nostyle-link remove-list remove-allergy pl-1"><i class="icon_delete"></i></a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-4 my-1'>
                                                            <div className='card pathistory_card p-3'>
                                                                <div className='row'>
                                                                    <div className='col-9'>
                                                                        <span className='obj_card_text'>Asthma (Seretide 250mg,Budecort 0.5)</span><br />
                                                                        <span className='obj_duration_text'>Duration: 20 Years</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span class="float-right">
                                                                            <a class="nostyle-link edit-allergy pr-1"><i class="icon-edit"></i></a>
                                                                            <a class="nostyle-link remove-list remove-allergy pl-1"><i class="icon_delete"></i></a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row align-items-center'>
                                                        <div className='col-4'>
                                                            <h6 className='text-secondary mb-0'>SURGICAL HX</h6>
                                                        </div>
                                                        <div className='col-6 d-flex justify-content-end'>
                                                            <Checkbox
                                                                name="no_surgical"
                                                            >
                                                                <span>No Known Surgical Hx</span>
                                                            </Checkbox>
                                                        </div>
                                                        <div className='col-2'>
                                                            <div class="float-right">
                                                                <a class="btn btn-outline-primary" id="add_allergies" onClick={() => { this.setState({ addSurgicalModal: true }) }}>+ SURGICAL HX</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row my-2'>
                                                        <div className='col-4 my-1'>
                                                            <div className='card pathistory_card p-3'>
                                                                <div className='row'>
                                                                    <div className='col-9'>
                                                                        <span className='obj_card_text'>Asthma (Seretide 250mg,Budecort 0.5)</span><br />
                                                                        <span className='obj_duration_text'>Duration: 20 Years</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span class="float-right">
                                                                            <a class="nostyle-link edit-allergy pr-1"><i class="icon-edit"></i></a>
                                                                            <a class="nostyle-link remove-list remove-allergy pl-1"><i class="icon_delete"></i></a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-4 my-1'>
                                                            <div className='card pathistory_card p-3'>
                                                                <div className='row'>
                                                                    <div className='col-9'>
                                                                        <span className='obj_card_text'>Asthma (Seretide 250mg,Budecort 0.5)</span><br />
                                                                        <span className='obj_duration_text'>Duration: 20 Years</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span class="float-right">
                                                                            <a class="nostyle-link edit-allergy pr-1"><i class="icon-edit"></i></a>
                                                                            <a class="nostyle-link remove-list remove-allergy pl-1"><i class="icon_delete"></i></a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-4 my-1'>
                                                            <div className='card pathistory_card p-3'>
                                                                <div className='row'>
                                                                    <div className='col-9'>
                                                                        <span className='obj_card_text'>Asthma (Seretide 250mg,Budecort 0.5)</span><br />
                                                                        <span className='obj_duration_text'>Duration: 20 Years</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span class="float-right">
                                                                            <a class="nostyle-link edit-allergy pr-1"><i class="icon-edit"></i></a>
                                                                            <a class="nostyle-link remove-list remove-allergy pl-1"><i class="icon_delete"></i></a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row align-items-center'>
                                                        <div className='col-4'>
                                                            <h6 className='text-secondary mb-0'>SOCIAL HX</h6>
                                                        </div>
                                                        <div className='col-6 d-flex justify-content-end'>
                                                            <Checkbox
                                                                name="no_surgical"
                                                            >
                                                                <span>No Known Social Hx</span>
                                                            </Checkbox>
                                                        </div>
                                                        <div className='col-2'>
                                                            <div class="float-right">
                                                                <a class="btn btn-outline-primary" id="add_social" onClick={() => { this.setState({ addSocialModal: true }) }}>+ SOCIAL HX</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row my-2'>
                                                        <div className='col-4 my-1'>
                                                            <div className='card pathistory_card p-3'>
                                                                <div className='row'>
                                                                    <div className='col-9'>
                                                                        <span className='obj_card_text'>Asthma (Seretide 250mg,Budecort 0.5)</span><br />
                                                                        <span className='obj_duration_text'>Duration: 20 Years</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span class="float-right">
                                                                            <a class="nostyle-link edit-allergy pr-1"><i class="icon-edit"></i></a>
                                                                            <a class="nostyle-link remove-list remove-allergy pl-1"><i class="icon_delete"></i></a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row d-flex align-items-center'>
                                                        <div className='col-4'>
                                                            <h6 className='text-secondary mb-0'>FAMILY HX</h6>
                                                        </div>
                                                        <div className='col-6 d-flex justify-content-end'>
                                                            <Checkbox
                                                                name="no_surgical"
                                                            >
                                                                <span>No Family Hx</span>
                                                            </Checkbox>
                                                        </div>
                                                        <div className='col-2'>
                                                            <div class="float-right">
                                                                <a class="btn btn-outline-primary" id="add_social" onClick={() => { this.setState({ addFamilyModal: true }) }}>+ FAMILY HX</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row my-2'>
                                                        <div className='col-4 my-1'>
                                                            <div className='card pathistory_card p-3'>
                                                                <div className='row'>
                                                                    <div className='col-9'>
                                                                        <span className='obj_card_text'>Asthma (Seretide 250mg,Budecort 0.5)</span><br />
                                                                        <span className='obj_duration_text'>Duration: 20 Years</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span class="float-right">
                                                                            <a class="nostyle-link edit-allergy pr-1"><i class="icon-edit"></i></a>
                                                                            <a class="nostyle-link remove-list remove-allergy pl-1"><i class="icon_delete"></i></a>
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ############ Chief Complaints section  ########## */}

                                    <div className="card card-bg-lightblue sub-card mb-3 ">
                                        <div className="card-body p-0">
                                            <div className='container-fluid p-3'>
                                                <div className='row'>
                                                    <div className='col-xl-5 col-lg-4'>
                                                        <div className="form-group micwrap ant-mic-wrap hasdata">
                                                            <a
                                                                className='mic subjective-mic '
                                                            ></a>
                                                            <textarea
                                                                className="form-control diagnosis_notes_class speech-field"
                                                                id="chief_complaints"
                                                                name="chief_complaints"
                                                                placeholder="Chief Complaints & HPI"
                                                                rows="1"
                                                                onInput={(e) => this.autoHeight(e)}
                                                            ></textarea>
                                                            <label htmlFor="chief_complaints">Chief Complaints & HPI <span className='text-secondary'>*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className='col-xl-5 col-lg-4'>
                                                        <div className="form-group micwrap ant-mic-wrap hasdata">
                                                            <a
                                                                className='mic subjective-mic '
                                                            ></a>
                                                            <textarea
                                                                className="form-control diagnosis_notes_class speech-field"
                                                                id="physical_examination"
                                                                name="physical_examination"
                                                                placeholder="Physical Examination"
                                                                rows="1"
                                                                onInput={(e) => this.autoHeight(e)}
                                                            ></textarea>
                                                            <label htmlFor="physical_examination">Physical Examination</label>
                                                        </div>
                                                    </div>
                                                    <div className='col-xl-2 col-lg-4'>
                                                        <div class="float-right">
                                                            <a class="btn btn-outline-primary px-2" id="copy_prev_hx" onClick={() => { this.setState({ copyPreviousModal: true }) }}>COPY PREVIOUS HX</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-2'>
                                                    <div className='col-xl-12 col-lg-12'>
                                                        <div className="form-group micwrap ant-mic-wrap hasdata">
                                                            <a
                                                                className='mic subjective-mic '
                                                            ></a>
                                                            <textarea
                                                                className="form-control diagnosis_notes_class speech-field"
                                                                id="clinical_notes"
                                                                name="clinical_notes"
                                                                placeholder="Clinical Notes"
                                                                rows="1"
                                                                onInput={(e) => this.autoHeight(e)}
                                                            ></textarea>
                                                            <label htmlFor="clinical_notes">Clinical Notes</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-2'>
                                                    <div className='col-xl-12 col-lg-12'>
                                                        <div className="form-group micwrap ant-mic-wrap hasdata">
                                                            <a
                                                                className='mic subjective-mic '
                                                            ></a>
                                                            <textarea
                                                                className="form-control diagnosis_notes_class speech-field"
                                                                id="internal_notes"
                                                                name="internal_notes"
                                                                placeholder="Internal Notes"
                                                                rows="1"
                                                                onInput={(e) => this.autoHeight(e)}
                                                            ></textarea>
                                                            <label htmlFor="internal_notes">Internal Notes</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card bg-white-clinical mb-3 mt-0">
                                                    <label className='text-secondary label-pos'>Clinical Notes</label>
                                                    <div className="card-body">
                                                        <div className='row mt-1'>
                                                            <div className='col-11 text-left'>
                                                                <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                                    <input type="checkbox" className="custom-control-input" name="print_today_note" id="print_today_note"
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="print_today_note">
                                                                        {/* <a href="#" className='text-secondary text-underline pl-1'><u>Select to Print</u></a> */}
                                                                        <span className='text-secondary'>Select to Print</span>
                                                                    </label>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='row mt-1'>
                                                            <div className='col-auto d-flex align-items-center'>
                                                                <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                                    <input type="checkbox" className="custom-control-input" name="clin_no" id="clin_no"
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="clin_no"><span className='text-secondary'>15 Mar 2022</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col'>
                                                                <div className="form-group micwrap normal mb-0">
                                                                    <a
                                                                        className='mic subjective-mic '>
                                                                    </a>
                                                                    <div className='d-flex'>
                                                                    </div>
                                                                    <textarea className="form-control subjective-field" placeholder='Type Here' id={"ss"}
                                                                        rows="1" onInput={(e) => this.autoHeight(e)}></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-2'>
                                                            <div className='col-auto d-flex align-items-center'>
                                                                <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                                    <input type="checkbox" className="custom-control-input" name="clin_no" id="clin_no"
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="clin_no"><span className=''>1 Mar 2022</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className='col'>
                                                                <span className='text-dark'>Better then before.Activity reduced.Dietary changes emphasised again</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card bg-white-clinical mt-4 mb-0">
                                                    <label className='text-dark label-pos bold-500'>INTERNAL NOTES (NOT TO BE PRINTED)</label>
                                                    <div className="card-body">
                                                        {/* <div className='row'>
                                                                <div className='col-11 d-flex justify-content-end text-right'>
                                                                    <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                                        <input type="checkbox" className="custom-control-input" name="print_today_note" id="print_today_note"
                                                                        />
                                                                        <label className="custom-control-label" htmlFor="print_today_note">
                                                                        </label>
                                                                        <a href="#" className='text-secondary text-underline'><u>Print Today's Note</u></a>
                                                                    </div>
                                                                    <a href="#" className='text-secondary text-underline mx-3'><u>Select to Print</u></a>
                                                                    <a href="#" className='text-secondary text-underline mx-3'><u>Print All</u></a>
                                                                </div>
                                                            </div> */}
                                                        <div className='row mt-2'>
                                                            <div className='col-auto d-flex align-items-center'>
                                                                <span className='text-secondary'>15 Mar 2022</span>
                                                            </div>
                                                            <div className='col'>
                                                                <div className="form-group micwrap normal mb-0">
                                                                    <a
                                                                        className='mic subjective-mic '>
                                                                    </a>
                                                                    <div className='d-flex'>
                                                                    </div>
                                                                    <textarea className="form-control subjective-field" placeholder='Type Here' id={"ss"}
                                                                        rows="1" onInput={(e) => this.autoHeight(e)}></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-2'>
                                                            <div className='col-auto d-flex align-items-center'>
                                                                <span className='text-dark'>1 Mar 2022</span>
                                                            </div>
                                                            <div className='col'>
                                                                <span className='text-dark'>Better then before.Activity reduced.Dietary changes emphasised again</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* ####################### +Diagnosis Card ####################### */}
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className="card card-bg-lightblue diagnosis-card mb-3 ">
                                                <div className="card-body p-0">
                                                    <div className='container-fluid p-0'>
                                                        <div className='row d-flex align-items-center'>
                                                            <div className='col-7'>
                                                                <h6 className='text-dark text-uppercase mb-0'>DIAGNOSIS</h6>
                                                            </div>
                                                            <div className='col-5 text-right'>
                                                                <a class="btn btn-outline-primary px-2 py-1" id="diagnosis" href="#">+ DIAGNOSIS</a>
                                                            </div>
                                                        </div>


                                                        {/*##### Suggested Care protocols card  and Diagnosis Card #### */}
                                                        <div className='row mt-3'>
                                                            <div className='col-6'>
                                                                <div className="card card-bg-lightblue suggest-protocol-card mb-3 ">
                                                                    <div className="card-body">
                                                                        <div className='container-fluid'>
                                                                            <div className='row d-flex align-items-center'>
                                                                                <div className='col-auto'>
                                                                                    <h6 className='text-secondary text-uppercase mb-0'>RELATED CARE PROTOCOL</h6>
                                                                                </div>
                                                                                <div className='col text-right'>
                                                                                    <a className='text-secondary' onClick={() => { this.setState({ showSuggestedCareModal: true }) }}><u>View All</u></a>
                                                                                </div>
                                                                            </div>

                                                                            <div className='row mt-2'>
                                                                                <div className='col-12 d-flex align-items-center flex-wrap'>
                                                                                    <ul className='list-unstyled d-flex flex-wrap'>
                                                                                        <li className='pr-3 pb-1'>
                                                                                            <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                                <input type="checkbox" className="custom-control-input" name="fav_list" id="fav"
                                                                                                />
                                                                                                <label className="custom-control-label" htmlFor="fav">
                                                                                                </label>
                                                                                                <a className='text-dark' onClick={() => { this.setState({ careProtocolparticularModal: true }) }}><span><u>Fever,unspecified R50.9</u></span></a>

                                                                                                <span className="pl-2">
                                                                                                    <i className="icon_unique icon_star favourite align-star"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </li>
                                                                                        <li className='pr-3 pb-1'>
                                                                                            <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                                <input type="checkbox" className="custom-control-input" name="fav_list" id="fav"
                                                                                                />
                                                                                                <label className="custom-control-label" htmlFor="fav">
                                                                                                </label>
                                                                                                <a className='text-dark' onClick={() => { this.setState({ careProtocolparticularModal: true }) }}><span><u>Dengue Fever</u></span></a>

                                                                                                <span className="pl-2">
                                                                                                    <i className="icon_unique icon_star favourite align-star"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </li>
                                                                                        <li className='pr-3 pb-1'>
                                                                                            <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                                <input type="checkbox" className="custom-control-input" name="fav_list" id="fav"
                                                                                                />
                                                                                                <label className="custom-control-label" htmlFor="fav">
                                                                                                </label>
                                                                                                <a className='text-dark' onClick={() => { this.setState({ careProtocolparticularModal: true }) }}><span><u>Covid 19</u></span></a>

                                                                                                <span className="pl-2">
                                                                                                    <i className="icon_unique icon_star favourite align-star"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </li>
                                                                                        <li className='pr-3 pb-1'>
                                                                                            <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                                <input type="checkbox" className="custom-control-input" name="fav_list" id="fav"
                                                                                                />
                                                                                                <label className="custom-control-label" htmlFor="fav">
                                                                                                </label>
                                                                                                <a className='text-dark' onClick={() => { this.setState({ careProtocolparticularModal: true }) }}><span><u>RTPCR</u></span></a>

                                                                                                <span className="pl-2">
                                                                                                    <i className="icon_unique icon_star favourite align-star"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </li>

                                                                                        {/* <li>
                                                                        <a style={{ position: 'absolute', right: '0' }} class="btn btn-outline-secondary mr-2" id="care_protocol" href="#">ADD</a>
                                                                    </li> */}
                                                                                    </ul>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* Diagnosis Card */}

                                                            <div className='col-6'>
                                                                <div className="card card-bg-lightblue suggest-protocol-card mb-3 ">
                                                                    <div className="card-body">
                                                                        <div className='container-fluid'>
                                                                            <div className='row d-flex align-items-center'>
                                                                                <div className='col-auto'>
                                                                                    <h6 className='text-secondary text-uppercase mb-0'>DIAGNOSIS</h6>
                                                                                </div>
                                                                                <div className='col text-right'>
                                                                                    <a className='text-secondary' onClick={() => { this.setState({ showSuggestedCareModal: true }) }}><u>View All</u></a>
                                                                                </div>
                                                                                {/* <div className='col text-right'>
                                                                                    <span className='pr-2'><img style={{ width: "18px", height: '18px' }} src={SearchImg} alt="search" className="icon" /></span>
                                                                                    <a className='text-secondary' onClick={() => { this.setState({ addDiagnosisModal: true }) }}><u>Search Diagnosis</u></a>
                                                                                </div> */}
                                                                            </div>

                                                                            <div className='row mt-2'>
                                                                                <div className='col-12 d-flex align-items-center flex-wrap'>
                                                                                    <ul className='list-unstyled d-flex flex-wrap'>
                                                                                        <li className='pr-3 pb-1'>
                                                                                            <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                                <input type="checkbox" className="custom-control-input" name="fav_list" id="fav"
                                                                                                />
                                                                                                <label className="custom-control-label" htmlFor="fav">
                                                                                                </label>
                                                                                                <a className='text-dark' onClick={() => { this.setState({ diagnosisparticularModal: true }) }}><span><u>Fever,unspecified R50.9</u></span></a>

                                                                                                <span className="pl-2">
                                                                                                    <i className="icon_unique icon_star favourite align-star"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </li>
                                                                                        <li className='pr-3 pb-1'>
                                                                                            <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                                <input type="checkbox" className="custom-control-input" name="fav_list" id="fav"
                                                                                                />
                                                                                                <label className="custom-control-label" htmlFor="fav">
                                                                                                </label>
                                                                                                <a className='text-dark' onClick={() => { this.setState({ diagnosisparticularModal: true }) }}><span><u>Dengue Fever</u></span></a>

                                                                                                <span className="pl-2">
                                                                                                    <i className="icon_unique icon_star favourite align-star"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </li>
                                                                                        <li className='pr-3 pb-1'>
                                                                                            <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                                <input type="checkbox" className="custom-control-input" name="fav_list" id="fav"
                                                                                                />
                                                                                                <label className="custom-control-label" htmlFor="fav">
                                                                                                </label>
                                                                                                <a className='text-dark' onClick={() => { this.setState({ diagnosisparticularModal: true }) }}><span><u>Covid 19</u></span></a>

                                                                                                <span className="pl-2">
                                                                                                    <i className="icon_unique icon_star favourite align-star"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </li>
                                                                                        <li className='pr-3 pb-1'>
                                                                                            <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                                <input type="checkbox" className="custom-control-input" name="fav_list" id="fav"
                                                                                                />
                                                                                                <label className="custom-control-label" htmlFor="fav">
                                                                                                </label>
                                                                                                <a className='text-dark' onClick={() => { this.setState({ diagnosisparticularModal: true }) }}><span><u>RTPCR</u></span></a>

                                                                                                <span className="pl-2">
                                                                                                    <i className="icon_unique icon_star favourite align-star"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                        </li>

                                                                                        <li>
                                                                                            <a style={{ position: 'absolute', right: '0' }} class="btn btn-outline-secondary mr-2" id="care_protocol" href="#">ADD</a>
                                                                                        </li>
                                                                                    </ul>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <table className="table table-bordered suggested-protocol-table mt-1" id="diagnosis-list">
                                                            <thead>
                                                                <tr>
                                                                    <td width="17%">DIAGNOSIS</td>
                                                                    <td width="10%">ICD 10</td>
                                                                    <td width="10%">STAGE</td>
                                                                    <td width="10%">TYPE</td>
                                                                    <td width="10%">STATUS</td>
                                                                    <td width="20%">NOTES</td>
                                                                    <td width="15%">IMAGE REFERENCE</td>
                                                                    <td width="8%">ACTION</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Diabetes Insipedus</td>
                                                                    <td>E23.4</td>
                                                                    <td>Provisional</td>
                                                                    <td>Primary</td>
                                                                    <td>
                                                                        <span className='ant_switch_button_sm'>
                                                                            <BootstrapSwitchButton
                                                                                width={150} height={24}
                                                                                size="xs"

                                                                                offlabel='Inctive'
                                                                                onlabel='Active'
                                                                                offstyle="success"
                                                                                onstyle="alert"

                                                                            />
                                                                        </span>

                                                                    </td>
                                                                    <td>Consistently high blood sugar reading</td>
                                                                    <td><i className="mr-2 icon-pdf"></i></td>
                                                                    <td className='text-center'>
                                                                        <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                                            <i className="icon-edit" />
                                                                        </a>
                                                                        <a className='btn nostyle-link p-0 ml-1' href="#">
                                                                            <i className="icon_delete" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Diabetes Insipedus</td>
                                                                    <td>E23.4</td>
                                                                    <td>Provisional</td>
                                                                    <td>Primary</td>
                                                                    <td>
                                                                        <span className='ant_switch_button_sm'>
                                                                            <BootstrapSwitchButton
                                                                                width={150} height={24}
                                                                                size="xs"

                                                                                offlabel='Inctive'
                                                                                onlabel='Active'
                                                                                offstyle="success"
                                                                                onstyle="alert"

                                                                            />
                                                                        </span>

                                                                    </td>
                                                                    <td>Consistently high blood sugar reading</td>
                                                                    <td><i className="mr-2 icon-pdf"></i></td>
                                                                    <td className='text-center'>
                                                                        <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                                            <i className="icon-edit" />
                                                                        </a>
                                                                        <a className='btn nostyle-link p-0 ml-1' href="#">
                                                                            <i className="icon_delete" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>






                                    {/*  ########## Paediatrics accordion ################## */}
                                    <div className="card paediatrics-card" id="paed_scroll">
                                        <div className="card-header paed-card-head p-0" id="headingFive">
                                            <button className="btn  btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" type="button" data-toggle="collapse" data-target="#collapsePaediatrics" aria-expanded="false" aria-controls="collapsePaediatrics">
                                                <h6>PAEDIATRICS</h6>
                                            </button>
                                        </div>
                                        <div id="collapsePaediatrics" className="collapse" aria-labelledby="headingFive" >
                                            <div className="card-body">
                                                Paediatrics component to be placed here
                                            </div>
                                        </div>
                                    </div>

                                    {/* ########## Obs & Gynae Accordion ################# */}

                                    <div className="card paediatrics-card" id="obs_scroll">
                                        <div className="card-header paed-card-head p-0" id="headingSix">
                                            <button className="btn  btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" type="button" data-toggle="collapse" data-target="#collapseObsGynae" aria-expanded="false" aria-controls="collapseObsGynae">
                                                <h6>OBS & GYNAE</h6>
                                            </button>
                                        </div>
                                        <div id="collapseObsGynae" className="collapse" aria-labelledby="headingSix" >
                                            <div className="card-body pt-0">
                                                <div className='container-fluid emrfrm'>
                                                    <Form
                                                        className='emrfrm'
                                                        layout="vertical"
                                                    >
                                                        <div className='row'>
                                                            <div className='col-6 '>
                                                                <div className='row d-flex align-items-center'>
                                                                    <div className="col-3 pr-2">
                                                                        <Form.Item
                                                                            name="lmp_date"
                                                                            className='mb-0'
                                                                        >
                                                                            <DatePicker
                                                                                name="lmp_date"
                                                                                format="DD MMM, YYYY"
                                                                                placeholder='LMP'
                                                                                className='mb-0'
                                                                                style={{
                                                                                    width: 100 + "%",
                                                                                    height: 44 + "px",

                                                                                }}
                                                                            />
                                                                        </Form.Item>
                                                                    </div>
                                                                    <div className='col-2 '>
                                                                        <span><a href="#" className='text-secondary'><u>LMP</u></a></span>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <label className='text-dark'>EDD by LMP :&nbsp;</label>
                                                                        <label className='text-dark font-weight-bold'>07 Sep,2022</label>
                                                                    </div>
                                                                    <div className="col-3 ">
                                                                        <div className='antInputItem hasdata'>
                                                                            {/* <Form.Item name="inputCorrectedEddDate" id="inputCorrectedEddDate" label="Corrected EDD"
                                                                            >
                                                                                <DatePicker
                                                                                    format="DD MMM, YYYY"
                                                                                    showNow={true}                                                                    
                                                                                    className="form-control datetimepicker"
                                                                                    allowClear={false}
                                                                                />
                                                                            </Form.Item> */}
                                                                            <Form.Item
                                                                                name="corrected_edd"
                                                                                className='mb-0'
                                                                            >
                                                                                <DatePicker
                                                                                    name="corrected_edd"
                                                                                    format="DD MMM, YYYY"
                                                                                    placeholder='Corrected EDD'
                                                                                    className='mb-0'
                                                                                    style={{
                                                                                        width: 100 + "%",
                                                                                        height: 44 + "px",

                                                                                    }}
                                                                                />
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 '>
                                                                <div className='row '>
                                                                    <div className='col-6 text-center'>
                                                                        <label className='text-dark'>POG (D) :&nbsp;</label>
                                                                        <label className='text-dark font-weight-bold'>12 Weeks 2 Days</label>
                                                                    </div>
                                                                    <div className='col-6 text-center'>
                                                                        <label className='text-dark'>POG (USG) :&nbsp;</label>
                                                                        <label className='text-dark font-weight-bold'>12 Weeks 2 Days</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-4 d-flex align-items-center'>
                                                            <div className='col-2'>
                                                                <h6 className='text-secondary mb-0'> MENSTRUAL HX</h6>
                                                            </div>
                                                            <div className='col-2'>
                                                                <Checkbox
                                                                    name="confidential"
                                                                >
                                                                    Confidential
                                                                </Checkbox>
                                                            </div>
                                                            <div className='col-2'>
                                                                <Checkbox
                                                                    name="no_menstrual_hx"
                                                                >
                                                                    No Menstrual Hx
                                                                </Checkbox>

                                                            </div>
                                                            <div className='col text-right'>
                                                                <a class="btn btn-outline-secondary " id="view_past_hx" onClick={() => { this.setState({ menstrualViewPastModal: true }) }}>VIEW PAST HX</a>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3 d-flex align-items-center'>
                                                            <div className='col-2'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Menopause Age"
                                                                        name="menopause_age"

                                                                    >
                                                                        <Input
                                                                            addonAfter="Years"
                                                                            id="menopause_age"
                                                                            name="menopause_age"
                                                                            type="text"


                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-auto'>
                                                                <span className='color_text_default'>If Applicable</span>
                                                            </div>

                                                        </div>
                                                        <div className='row  mt-4'>
                                                            <div className='col-2'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Cycle Length"
                                                                        name="cycle_length"

                                                                    >
                                                                        <Input
                                                                            addonAfter="Days"
                                                                            id="cycle_length"
                                                                            name="cycle_length"
                                                                            type="text"


                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-2'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Duration of Period"
                                                                        name="duration_of_period"

                                                                    >
                                                                        <Input
                                                                            addonAfter="Days"
                                                                            id="duration_of_period"
                                                                            name="duration_of_period"
                                                                            type="text"


                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-8'>
                                                                <div className='row'>
                                                                    <div className='col-4'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Flow"
                                                                                name="flow"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="flow"
                                                                                >
                                                                                    <Option value="flow1">
                                                                                        Flow 1
                                                                                    </Option>
                                                                                    <Option value="flow2">
                                                                                        Flow 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Regularity"
                                                                                name="regularity"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="regularity"
                                                                                >
                                                                                    <Option value="reg1">
                                                                                        Regularity 1
                                                                                    </Option>
                                                                                    <Option value="reg2">
                                                                                        Regularity 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-4'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Pain Score"
                                                                                name="pain_score"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="pain_score"
                                                                                >
                                                                                    <Option value="reg1">
                                                                                        Pain 1
                                                                                    </Option>
                                                                                    <Option value="reg2">
                                                                                        Pain 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-4'>
                                                            <div className='col-xl-12 col-lg-12'>
                                                                <div className="form-group micwrap ant-mic-wrap hasdata">
                                                                    <a
                                                                        className='mic subjective-mic '
                                                                    ></a>
                                                                    <textarea
                                                                        className="form-control diagnosis_notes_class speech-field"
                                                                        id="remarks"
                                                                        name="remarks"
                                                                        placeholder="Remarks"
                                                                        rows="1"
                                                                        onInput={(e) => this.autoHeight(e)}
                                                                    ></textarea>
                                                                    <label htmlFor="remarks">Remarks</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row mt-4 d-flex align-items-center'>
                                                            <div className='col-2'>
                                                                <h6 className='text-secondary mb-0'> SEXUAL HX</h6>
                                                            </div>
                                                            <div className='col-auto pr-3'>
                                                                <Checkbox
                                                                    name="confidential"
                                                                >
                                                                    Confidential
                                                                </Checkbox>
                                                            </div>
                                                            <div className='col-auto pr-3'>
                                                                <Checkbox
                                                                    name="no_menstrual_hx"
                                                                >
                                                                    No Sexual Hx
                                                                </Checkbox>
                                                            </div>
                                                            <div className='col-auto'>
                                                                <Checkbox
                                                                    name="patient_disclose"
                                                                >
                                                                    Patient not willing to disclose
                                                                </Checkbox>

                                                            </div>
                                                            <div className='col text-right'>
                                                                <a class="btn btn-outline-secondary " id="view_past_hx_sexual" onClick={() => { this.setState({ sexualViewPastModal: true }) }}>VIEW PAST HX</a>
                                                            </div>
                                                        </div>

                                                        <div className='row mt-3'>
                                                            <div className="col-3 ">
                                                                <div className="row ant-input-select antInputItem ">
                                                                    <div className="col pr-0">
                                                                        <Form.Item
                                                                            name="since"
                                                                            label="Sexual Active Since"

                                                                        >
                                                                            <Input placeholder="Sexual Active Since" className="onlynumbers" maxLength="2" />
                                                                        </Form.Item>
                                                                    </div>
                                                                    <div className="col-auto pl-0">
                                                                        <Form.Item name="time">
                                                                            <Select defaultValue="0" style={{ width: 120 }}>
                                                                                <Option value="0">Days</Option>
                                                                                <Option value="1">Weeks</Option>
                                                                                <Option value="2">Months</Option>
                                                                                <Option value="3">Years</Option>
                                                                            </Select>
                                                                        </Form.Item>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-3 ">
                                                                <div className="row ant-input-select antInputItem hasdata">
                                                                    <div className="col pr-0">
                                                                        <Form.Item
                                                                            name="coital_frequency"
                                                                            label="Coital Frequency"

                                                                        >
                                                                            <Input placeholder="Sexual Active Since" className="onlynumbers" maxLength="2" />
                                                                        </Form.Item>
                                                                    </div>
                                                                    <div className="col-auto pl-0">
                                                                        <Form.Item name="time">
                                                                            <Select defaultValue="0" style={{ width: 120 }}>
                                                                                <Option value="0">Per Week</Option>
                                                                                <Option value="1">Per Month</Option>
                                                                                <Option value="2">Per Year</Option>

                                                                            </Select>
                                                                        </Form.Item>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6 col-lg-6'>
                                                                <div className="form-group micwrap ant-mic-wrap hasdata">
                                                                    <a
                                                                        className='mic subjective-mic '
                                                                    ></a>
                                                                    <textarea
                                                                        className="form-control diagnosis_notes_class speech-field"
                                                                        id="coital_details"
                                                                        name="coital_details"
                                                                        placeholder="Coital Difficulty Details"
                                                                        rows="1"
                                                                        onInput={(e) => this.autoHeight(e)}
                                                                    ></textarea>
                                                                    <label htmlFor="coital_details">Coital Difficulty Details</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-2'>
                                                                <label>Contraceptives</label>
                                                            </div>
                                                            <div className='col-6'>
                                                                <Checkbox
                                                                    name="confidential"
                                                                    className='pr-4'
                                                                >
                                                                    None
                                                                </Checkbox>
                                                                <Checkbox
                                                                    name="condom"
                                                                    className='pr-4'
                                                                >
                                                                    Condom
                                                                </Checkbox>
                                                                <Checkbox
                                                                    name="iud"
                                                                    className='pr-4'
                                                                >
                                                                    IUD
                                                                </Checkbox>
                                                                <Checkbox
                                                                    name="ocp"
                                                                    className='pr-4'
                                                                >
                                                                    OCP
                                                                </Checkbox>
                                                                <Checkbox
                                                                    name="hormonal"
                                                                    className='pr-0'
                                                                >
                                                                    Hormonal
                                                                </Checkbox>
                                                            </div>
                                                            <div className='col-4'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Others"
                                                                        name="others_Sexual"
                                                                    >
                                                                        <Input
                                                                            id="others_Sexual"
                                                                            name="others_Sexual"
                                                                            type="text"
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-xl-12 col-lg-12'>
                                                                <div className="form-group micwrap ant-mic-wrap hasdata">
                                                                    <a
                                                                        className='mic subjective-mic '
                                                                    ></a>
                                                                    <textarea
                                                                        className="form-control diagnosis_notes_class speech-field"
                                                                        id="remarks"
                                                                        name="remarks"
                                                                        placeholder="Remarks"
                                                                        rows="1"
                                                                        onInput={(e) => this.autoHeight(e)}
                                                                    ></textarea>
                                                                    <label htmlFor="remarks">Remarks</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-4 d-flex align-items-center'>
                                                            <div className='col-2'>
                                                                <h6 className='text-secondary mb-0'> OBSTETRIC HX</h6>
                                                            </div>
                                                            <div className='col-auto pr-3'>
                                                                <Checkbox
                                                                    name="confidential_obs"
                                                                >
                                                                    Confidential
                                                                </Checkbox>
                                                            </div>
                                                            <div className='col-auto pr-3'>
                                                                <Checkbox
                                                                    name="no_obs_hx"
                                                                >
                                                                    No Obstetric Hx
                                                                </Checkbox>
                                                            </div>
                                                            <div className='col-auto'>
                                                                <Checkbox
                                                                    name="lactating"
                                                                >
                                                                    Lactating

                                                                </Checkbox>
                                                                <span className='pl-2'><img style={{ width: "18px", height: '18px' }} src={lactatingImage} alt="lactating" className="icon" /></span>

                                                            </div>
                                                            <div className='col text-right'>
                                                                <a class="btn btn-outline-secondary mr-3" id="prev_preg" onClick={() => { this.setState({ obstetricsPrevPregModal: true }) }}>+ PREVIOUS RREGNANCIES</a>
                                                                <a class="btn btn-outline-secondary ml-3" id="view_past_hx_obs" onClick={() => { this.setState({ obstetricsViewPastModal: true }) }}>VIEW PAST HX</a>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-auto d-flex align-items-center'>
                                                                <label className="d-md-inline mr-3">Pregnant</label>
                                                                <div className="mr-2 d-md-inline">
                                                                    <Radio.Group>
                                                                        <Radio value={0} name="yes">Yes</Radio>
                                                                        <Radio value={1} name="no">No</Radio>

                                                                    </Radio.Group>
                                                                </div>
                                                            </div>
                                                            <div className='col-2 d-flex align-items-center'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="No.of Fetus"
                                                                        name="nofetus"
                                                                    >
                                                                        <Input
                                                                            id="nofetus"
                                                                            name="nofetus"
                                                                            type="text"
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-2'></div>
                                                            <div className='col card bg-light-pink' style={{ height: '70px' }}>
                                                                <div className='row' style={{ paddingTop: '12px' }}>
                                                                    <div className='col-2'>
                                                                        <div className='antInputItem hasdata'>
                                                                            <Form.Item
                                                                                label="Gravidity"
                                                                                name="gravidity"
                                                                            >
                                                                                <Input
                                                                                    id="gravidity"
                                                                                    name="gravidity"
                                                                                    type="text"
                                                                                />
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-2'>
                                                                        <div className='antInputItem hasdata'>
                                                                            <Form.Item
                                                                                label="Parity"
                                                                                name="parity"
                                                                            >
                                                                                <Input
                                                                                    id="parity"
                                                                                    name="parity"
                                                                                    type="text"
                                                                                />
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-2'>
                                                                        <div className='antInputItem hasdata'>
                                                                            <Form.Item
                                                                                label="Abortion"
                                                                                name="abortion"
                                                                            >
                                                                                <Input
                                                                                    id="abortion"
                                                                                    name="abortion"
                                                                                    type="text"
                                                                                />
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-2'>
                                                                        <div className='antInputItem hasdata'>
                                                                            <Form.Item
                                                                                label="Live Birth"
                                                                                name="livebirth"
                                                                            >
                                                                                <Input
                                                                                    id="livebirth"
                                                                                    name="livebirth"
                                                                                    type="text"
                                                                                />
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <div className='antInputItem hasdata'>
                                                                            <Form.Item
                                                                                label="Ectopic"
                                                                                name="ectopic"
                                                                            >
                                                                                <Input
                                                                                    id="ectopic"
                                                                                    name="ectopic"
                                                                                    type="text"
                                                                                />
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row mt-2 d-flex align-items-center'>
                                                            <div className='col-auto'>
                                                                <label>Gastational Age</label>
                                                            </div>
                                                            <div className='col-auto pl-4'>
                                                                <label>By LMP</label>
                                                            </div>
                                                            <div className='col-1'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Weeks"
                                                                        name="weeks"
                                                                    >
                                                                        <Input
                                                                            id="weeks"
                                                                            name="weeks"
                                                                            type="text"
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-1'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Days"
                                                                        name="days"
                                                                    >
                                                                        <Input
                                                                            id="days"
                                                                            name="days"
                                                                            type="text"
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-auto pl-4'>
                                                                <label>By USG</label>
                                                            </div>
                                                            <div className='col-1'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Weeks"
                                                                        name="weeks"
                                                                    >
                                                                        <Input
                                                                            id="weeks"
                                                                            name="weeks"
                                                                            type="text"
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-1'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Days"
                                                                        name="days"
                                                                    >
                                                                        <Input
                                                                            id="days"
                                                                            name="days"
                                                                            type="text"
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-xl-5 col-lg-5'>
                                                                <div className="form-group micwrap ant-mic-wrap hasdata">
                                                                    <a
                                                                        className='mic subjective-mic '
                                                                    ></a>
                                                                    <textarea
                                                                        className="form-control diagnosis_notes_class speech-field"
                                                                        id="remarks_curent_preg"
                                                                        name="remarks"
                                                                        placeholder="Remarks for Current Pregnancy"
                                                                        rows="1"
                                                                        onInput={(e) => this.autoHeight(e)}
                                                                    ></textarea>
                                                                    <label htmlFor="remarks_curent_preg">Remarks for Current Pregnancy</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-6'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Risk Factors"
                                                                        name="risk_factors"
                                                                    >
                                                                        <Input
                                                                            id="risk_factors"
                                                                            name="risk_factors"
                                                                            type="text"
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6 col-lg-6'>
                                                                <div className="form-group micwrap ant-mic-wrap hasdata">
                                                                    <a
                                                                        className='mic subjective-mic '
                                                                    ></a>
                                                                    <textarea
                                                                        className="form-control diagnosis_notes_class speech-field"
                                                                        id="risk_factor_remarks"
                                                                        name="remarks"
                                                                        placeholder="Risk Factor Remarks"
                                                                        rows="1"
                                                                        onInput={(e) => this.autoHeight(e)}
                                                                    ></textarea>
                                                                    <label htmlFor="risk_factor_remarks">Remarks for Current Pregnancy</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-4 d-flex align-items-center'>
                                                            <div className='col-auto'>
                                                                <h6 className='text-secondary mb-0'>PHYSICAL EXAMINATION</h6>
                                                            </div>
                                                            <div className='col-auto pl-4'>
                                                                <Checkbox
                                                                    name="phy_conf"
                                                                >
                                                                    Confidential
                                                                </Checkbox>
                                                            </div>
                                                            <div className='col text-right'>
                                                                <a class="btn btn-outline-secondary " id="view_past_phy" onClick={() => { this.setState({ physicalViewPastModal: true }) }}>VIEW PAST HX</a>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3 d-flex align-items-center'>
                                                            <div className='col-auto'>
                                                                <label style={{ color: '666666', fontWeight: '500' }}>Mother's Parameters</label>
                                                            </div>
                                                            <div className='col-auto '>
                                                                <button type="button" class="btn top-chart-btn p-0 ml-3"><i class="icon-chart"></i></button>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-3'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Edema"
                                                                        name="edema"
                                                                    >
                                                                        <Input
                                                                            id="edema"
                                                                            name="edema"
                                                                            type="text"
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-3'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Pallor"
                                                                        name="pallor"
                                                                    >
                                                                        <Input
                                                                            id="pallor"
                                                                            name="pallor"
                                                                            type="text"
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className='col-3'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="Fundal Height"
                                                                        name="fundal_height"
                                                                    >
                                                                        <Input
                                                                            id="fundal_height"
                                                                            name="fundal_height"
                                                                            type="text"
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-4 d-flex align-items-center'>
                                                            <div className='col-auto'>
                                                                <label style={{ color: '666666', fontWeight: '500' }}>Fetal Parameters</label>
                                                            </div>
                                                            <div className='col-1 text-center '>
                                                                <button type="button" class="btn top-chart-btn p-0 ml-3"><i class="icon-chart"></i></button>
                                                            </div>
                                                            <div className='col-2'>
                                                                <div className='antInputItem hasdata'>
                                                                    <Form.Item
                                                                        label="No. of Fetus"
                                                                        name="no.fetus"
                                                                    >
                                                                        <Input
                                                                            id="no.fetus"
                                                                            name="no.fetus"
                                                                            type="text"
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-1'>
                                                                <label>F1</label>
                                                            </div>
                                                            <div className='col-11'>
                                                                <div className='row'>
                                                                    <div className='col-3'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Fetal Movement"
                                                                                name="fetal_movement"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="fetal_movement"
                                                                                >
                                                                                    <Option value="0">
                                                                                        Fetal 1
                                                                                    </Option>
                                                                                    <Option value="1">
                                                                                        Fetal 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Fetal Heart Rate"
                                                                                name="fetal_heart"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="fetal_heart"
                                                                                >
                                                                                    <Option value="0">
                                                                                        Fetal 1
                                                                                    </Option>
                                                                                    <Option value="1">
                                                                                        Fetal 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Presentation"
                                                                                name="presentation"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="presentation"
                                                                                >
                                                                                    <Option value="0">
                                                                                        Fetal 1
                                                                                    </Option>
                                                                                    <Option value="1">
                                                                                        Fetal 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Relation of PP to Brim"
                                                                                name="realationpp"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="realationpp"
                                                                                >
                                                                                    <Option value="0">
                                                                                        Fetal 1
                                                                                    </Option>
                                                                                    <Option value="1">
                                                                                        Fetal 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-1'>
                                                            </div>
                                                            <div className='col-11'>
                                                                <div className='row'>
                                                                    <div className='col-3'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Head CC (cm)"
                                                                                name="head"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="fetal_movement"
                                                                                >
                                                                                    <Option value="0">
                                                                                        Fetal 1
                                                                                    </Option>
                                                                                    <Option value="1">
                                                                                        Fetal 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Abdominal CC (cm)"
                                                                                name="abdominal_cc"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="abdominal_cc"
                                                                                >
                                                                                    <Option value="0">
                                                                                        Fetal 1
                                                                                    </Option>
                                                                                    <Option value="1">
                                                                                        Fetal 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Femur Length(cm)"
                                                                                name="presentation"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="presentation"
                                                                                >
                                                                                    <Option value="0">
                                                                                        Fetal 1
                                                                                    </Option>
                                                                                    <Option value="1">
                                                                                        Fetal 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-1'>
                                                                <label>F2</label>
                                                            </div>
                                                            <div className='col-11'>
                                                                <div className='row'>
                                                                    <div className='col-3'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Fetal Movement"
                                                                                name="fetal_movement"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="fetal_movement"
                                                                                >
                                                                                    <Option value="0">
                                                                                        Fetal 1
                                                                                    </Option>
                                                                                    <Option value="1">
                                                                                        Fetal 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Fetal Heart Rate"
                                                                                name="fetal_heart"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="fetal_heart"
                                                                                >
                                                                                    <Option value="0">
                                                                                        Fetal 1
                                                                                    </Option>
                                                                                    <Option value="1">
                                                                                        Fetal 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Presentation"
                                                                                name="presentation"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="presentation"
                                                                                >
                                                                                    <Option value="0">
                                                                                        Fetal 1
                                                                                    </Option>
                                                                                    <Option value="1">
                                                                                        Fetal 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Relation of PP to Brim"
                                                                                name="realationpp"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="realationpp"
                                                                                >
                                                                                    <Option value="0">
                                                                                        Fetal 1
                                                                                    </Option>
                                                                                    <Option value="1">
                                                                                        Fetal 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-1'>
                                                            </div>
                                                            <div className='col-11'>
                                                                <div className='row'>
                                                                    <div className='col-3'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Head CC (cm)"
                                                                                name="head"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="fetal_movement"
                                                                                >
                                                                                    <Option value="0">
                                                                                        Fetal 1
                                                                                    </Option>
                                                                                    <Option value="1">
                                                                                        Fetal 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Abdominal CC (cm)"
                                                                                name="abdominal_cc"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="abdominal_cc"
                                                                                >
                                                                                    <Option value="0">
                                                                                        Fetal 1
                                                                                    </Option>
                                                                                    <Option value="1">
                                                                                        Fetal 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <div className="antSelectItem hasdata">
                                                                            <Form.Item
                                                                                label="Femur Length(cm)"
                                                                                name="presentation"

                                                                            >
                                                                                <Select
                                                                                    style={{ width: '100%' }}
                                                                                    name="presentation"
                                                                                >
                                                                                    <Option value="0">
                                                                                        Fetal 1
                                                                                    </Option>
                                                                                    <Option value="1">
                                                                                        Fetal 2
                                                                                    </Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-xl-12 col-lg-12'>
                                                                <div className="form-group micwrap ant-mic-wrap hasdata">
                                                                    <a
                                                                        className='mic subjective-mic '
                                                                    ></a>
                                                                    <textarea
                                                                        className="form-control diagnosis_notes_class speech-field"
                                                                        id="remarks_physical"
                                                                        name="remarks"
                                                                        placeholder="Remarks"
                                                                        rows="1"
                                                                        onInput={(e) => this.autoHeight(e)}
                                                                    ></textarea>
                                                                    <label htmlFor="remarks_physical">Remarks</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/*  ########## Medicines accordion ################## */}
                                    <div className="card medicines-card" id="med_scroll">
                                        <div className="card-header  med-card-head p-0" id="medAccordion">
                                            <button className="btn  btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" type="button" data-toggle="collapse" data-target="#collapseMedicines" aria-expanded="false" aria-controls="collapseMedicines">
                                                <h6>MEDICINES</h6>
                                            </button>
                                        </div>
                                        <div id="collapseMedicines" className="collapse" aria-labelledby="medAccordion" >
                                            <div className="card-body">
                                                <div className='container-fluid medicines'>
                                                    <div className='row'>
                                                        <div className='col-xl-6 col-lg-5 align-self-end'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <span><b>Height 175 cm | Weight 65 kg | BMI 28 | BSA 121</b></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-xl-3 col-lg-3'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    Target Blood Sugar(mg/dL)
                                                                </div>
                                                            </div>
                                                            <div className='row mt-2'>
                                                                <div className='col-6 pr-0'>
                                                                    <div className="form-group hasdata mb-0">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control drug-input mandatory-field onlynumbers border-right-plan"
                                                                            maxLength="4"
                                                                            name="target_blood_sugar_min"
                                                                            id="target_blood_sugar_min"
                                                                        />
                                                                        <label htmlFor="target_blood_sugar_min">From</label>
                                                                    </div>
                                                                </div>
                                                                <div className='col-6 pl-0'>
                                                                    <div className="form-group hasdata mb-0">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control drug-input mandatory-field onlynumbers border-left-plan"
                                                                            maxLength="4"
                                                                            name="target_blood_sugar_max"
                                                                            id="target_blood_sugar_max"
                                                                        />
                                                                        <label htmlFor="To">To</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-xl-3 col-lg-4 align-self-end'>
                                                            <div className='row'>
                                                                <div className='col-12 text-right'>
                                                                    <a class="btn btn-outline-secondary mr-2 px-2" id="care_protocol" href="#">ORDER SET</a>
                                                                    <a class="btn btn-outline-primary ml-2 px-2" id="diagnosis" onClick={() => { this.setState({ addMedicineModal: true }) }}>+ MEDICINES</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card bg-light-pink my-3">
                                                        <div className="card-body">
                                                            <div className='row mt-3'>
                                                                <div className='col-1'>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <span className='text-dark font-weight-bold'>ssss</span>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <a className='text-secondary' onClick={() => { this.setState({ planFavMedicineModal: true }) }}><u>View All</u></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-11'>
                                                                    <div className='row'>
                                                                        <div className='col-12 d-flex align-items-center flex-wrap'>
                                                                            <ul className='list-unstyled d-flex flex-wrap'>
                                                                                <li className='pr-3 pb-2'>
                                                                                    <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                        <input type="checkbox" className="custom-control-input" name="fav_list" id="fav_list2"
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="fav_list2">
                                                                                            <span>Seretide 250mg</span>
                                                                                            <span className="pl-2">
                                                                                                <i className="icon_unique icon_star favourite align-star"></i>
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className='pr-3 pb-2'>
                                                                                    <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                        <input type="checkbox" className="custom-control-input" name="fav_list" id="fav_list2"
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="fav_list2">
                                                                                            <span>Crocin Tablet 500mg</span>
                                                                                            <span className="pl-2">
                                                                                                <i className="icon_unique icon_star favourite align-star"></i>
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className='pr-3 pb-2'>
                                                                                    <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                        <input type="checkbox" className="custom-control-input" name="fav_list" id="fav_list2"
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="fav_list2">
                                                                                            <span>Paracetamol Tablet 500mg</span>
                                                                                            <span className="pl-2">
                                                                                                <i className="icon_unique icon_star favourite align-star"></i>
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className='pr-3 pb-2'>
                                                                                    <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                        <input type="checkbox" className="custom-control-input" name="fav_list" id="fav_list2"
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="fav_list2">
                                                                                            <span>Ecosprin Tablet</span>
                                                                                            <span className="pl-2">
                                                                                                <i className="icon_unique icon_star favourite align-star"></i>
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className='pr-3 pb-2'>
                                                                                    <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                        <input type="checkbox" className="custom-control-input" name="fav_list" id="fav_list2"
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="fav_list2">
                                                                                            <span>Azithral 500mg</span>
                                                                                            <span className="pl-2">
                                                                                                <i className="icon_unique icon_star favourite align-star"></i>
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className='pr-3 pb-2'>
                                                                                    <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                        <input type="checkbox" className="custom-control-input" name="fav_list" id="fav_list2"
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="fav_list2">
                                                                                            <span>Azithral Tablet 500mg</span>
                                                                                            <span className="pl-2">
                                                                                                <i className="icon_unique icon_star favourite align-star"></i>
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li>
                                                                                    <a style={{ position: 'absolute', right: '0' }} class="btn btn-outline-secondary mr-2" id="care_protocol" href="#">ADD</a>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <table className="table table-bordered custom-plan-header mt-3" id="diagnosis-list">
                                                        <thead>
                                                            <tr>
                                                                <td width="26%">NAME</td>
                                                                <td width="8%">ROUTE</td>
                                                                <td width="8%">DOSAGE</td>
                                                                <td width="8%">FREQUENCY</td>
                                                                <td width="8%">SCHEDULE</td>
                                                                <td width="9%">DURATION</td>
                                                                <td width="18%">INSTRUCTIONS</td>
                                                                <td width="9%">DUE FROM</td>
                                                                <td width="6%">ACTION</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className='remarks-included'><span className='text-dark bold-500 '>Ecosprin Tablet 75mg</span></td>
                                                                <td>Oral</td>
                                                                <td>1 Tab</td>
                                                                <td>Thrice a day</td>
                                                                <td>Morning-Afternoon-Evening</td>
                                                                <td>2 Days</td>
                                                                <td>As Directed</td>
                                                                <td><div>15 Mar,2022</div><div>Urgent</div></td>
                                                                <td className='text-center'>
                                                                    <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                                        <i className="icon-edit" />
                                                                    </a>
                                                                    <a className='btn nostyle-link p-0 ml-1' href="#">
                                                                        <i className="icon_delete" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr><td colspan={9} className="remarks-column">
                                                                <span className='bold-500'>Remarks :&nbsp;</span><span>Apply a thin layer of some cotton Apply a thin layer of some cotton</span>
                                                            </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className='text-dark bold-500'>Ecosprin Tablet 75mg</span></td>
                                                                <td>Oral</td>
                                                                <td>1 Tab</td>
                                                                <td>Thrice a day</td>
                                                                <td>Morning-Afternoon-Evening</td>
                                                                <td>2 Days</td>
                                                                <td>As Directed</td>
                                                                <td><div>15 Mar,2022</div><div className='text-secondary'>STAT</div></td>
                                                                <td className='text-center'>
                                                                    <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                                        <i className="icon-edit" />
                                                                    </a>
                                                                    <a className='btn nostyle-link p-0 ml-1' href="#">
                                                                        <i className="icon_delete" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='remarks-included'><span className='text-dark bold-500 '>Ecosprin Tablet 75mg</span></td>
                                                                <td>Oral</td>
                                                                <td>1 Tab</td>
                                                                <td>Thrice a day</td>
                                                                <td>Morning-Afternoon-Evening</td>
                                                                <td>2 Days</td>
                                                                <td>As Directed</td>
                                                                <td><div>15 Mar,2022</div><div>Urgent</div></td>
                                                                <td className='text-center'>
                                                                    <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                                        <i className="icon-edit" />
                                                                    </a>
                                                                    <a className='btn nostyle-link p-0 ml-1' href="#">
                                                                        <i className="icon_delete" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr><td colspan={9} className="remarks-column">
                                                                <span className='bold-500'>Remarks :&nbsp;</span><span>Apply a thin layer</span>
                                                            </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*  ########## Tests accordion ################## */}
                                    <div className="card tests-card" id="med_scroll">
                                        <div className="card-header  tests-card-head p-0" id="testAccordion">
                                            <button className="btn  btn-block text-left collapsed arrow float-right mb-0 custom-card-pink" type="button" data-toggle="collapse" data-target="#collapseTests" aria-expanded="false" aria-controls="collapseTests">
                                                <h6>TESTS</h6>
                                            </button>
                                        </div>
                                        <div id="collapseTests" className="collapse" aria-labelledby="testAccordion" >
                                            <div className="card-body">
                                                <div className='container-fluid tests mt-0'>
                                                    <div className='row'>
                                                        <div className='col-12 text-right'>
                                                            <a class="btn btn-outline-secondary mr-2 px-2" id="diagnosis" href="#">ORDER SET</a>
                                                            <a class="btn btn-outline-primary ml-2 px-2" id="diagnosis" href="#">+ TESTS/PROCEDURES</a>
                                                        </div>
                                                    </div>

                                                    <div className="card bg-light-pink my-3">
                                                        <div className="card-body">
                                                            <div className='row mt-3'>
                                                                <div className='col-1'>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <span className='text-dark font-weight-bold'>Favourites</span>
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <a className='text-secondary' onClick={() => { this.setState({ planFavTestsModal: true }) }}><u>View All</u></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-11'>
                                                                    <div className='row'>
                                                                        <div className='col-12 d-flex align-items-center flex-wrap'>
                                                                            <ul className='list-unstyled d-flex flex-wrap'>
                                                                                <li className='pr-3 pb-2'>
                                                                                    <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                        <input type="checkbox" className="custom-control-input" name="fav_list" id="fav_list2"
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="fav_list2">
                                                                                            <span>CBC</span>
                                                                                            <span className="pl-2">
                                                                                                <i className="icon_unique icon_star favourite align-star"></i>
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className='pr-3 pb-2'>
                                                                                    <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                        <input type="checkbox" className="custom-control-input" name="fav_list" id="fav_list2"
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="fav_list2">
                                                                                            <span>CBC(Complete Blood Count)with ESR</span>
                                                                                            <span className="pl-2">
                                                                                                <i className="icon_unique icon_star favourite align-star"></i>
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className='pr-3 pb-2'>
                                                                                    <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                        <input type="checkbox" className="custom-control-input" name="fav_list" id="fav_list2"
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="fav_list2">
                                                                                            <span>Covid RTPCR</span>
                                                                                            <span className="pl-2">
                                                                                                <i className="icon_unique icon_star favourite align-star"></i>
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className='pr-3 pb-2'>
                                                                                    <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                        <input type="checkbox" className="custom-control-input" name="fav_list" id="fav_list2"
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="fav_list2">
                                                                                            <span>D-Dimer</span>
                                                                                            <span className="pl-2">
                                                                                                <i className="icon_unique icon_star favourite align-star"></i>
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </li>

                                                                                <li className='pr-3 pb-2'>
                                                                                    <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                        <input type="checkbox" className="custom-control-input" name="fav_list" id="fav_list2"
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="fav_list2">
                                                                                            <span>X-ray abdomen-erect</span>
                                                                                            <span className="pl-2">
                                                                                                <i className="icon_unique icon_star favourite align-star"></i>
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li className='pr-3 pb-2'>
                                                                                    <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                                                        <input type="checkbox" className="custom-control-input" name="fav_list" id="fav_list2"
                                                                                        />
                                                                                        <label className="custom-control-label" htmlFor="fav_list2">
                                                                                            <span>X-ray Chest-AP</span>
                                                                                            <span className="pl-2">
                                                                                                <i className="icon_unique icon_star favourite align-star"></i>
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                                <li>
                                                                                    <a style={{ position: 'absolute', right: '0' }} class="btn btn-outline-secondary mr-2" id="care_protocol" href="#">ADD</a>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <table className="table table-bordered custom-plan-header mt-3" id="diagnosis-list">
                                                        <thead>
                                                            <tr>
                                                                <td width="40%">TEST NAME</td>
                                                                <td width="12%">PRIORITY</td>
                                                                <td width="40%">INSTRUCTIONS</td>
                                                                <td width="8%">ACTION</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><span className='text-dark bold-500'>CBC (Complete Blood Care with ESR)</span></td>
                                                                <td>Routine</td>
                                                                <td>Fill instruction here</td>

                                                                <td className='text-center'>
                                                                    {/* <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                                        <i className="icon-edit" />
                                                                    </a> */}
                                                                    <a className='btn nostyle-link p-0 ml-1' href="#">
                                                                        <i className="icon_delete" />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className='text-dark bold-500'>CBC (Complete Blood Care with ESR)</span></td>
                                                                <td>Routine</td>
                                                                <td>Fill instruction here</td>

                                                                <td className='text-center'>
                                                                    {/* <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                                        <i className="icon-edit" />
                                                                    </a> */}
                                                                    <a className='btn nostyle-link p-0 ml-1' href="#">
                                                                        <i className="icon_delete" />
                                                                    </a>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>





                                {/* Instruction Container */}

                                <div className='container-fluid mt-4' >
                                    <div className='row'>
                                        <div className='col-7 d-flex align-items-center'>
                                            <h6 className='text-secondary'>INSTRUCTIONS</h6>
                                        </div>
                                        <div className='col-5 text-right'>
                                            <a class="btn btn-outline-primary px-2" id="instructions" onClick={() => { this.setState({ addInstructionsModal: true }) }}>+ INSTRUCTIONS</a>
                                        </div>
                                    </div>

                                    <div className='row mt-2'>
                                        <div className='col-12'>
                                            <div className="all_instructions">
                                                <div className="row instructions_rows">
                                                    <div className=" instructions_list2 col-md-6 d-flex align-items-center pl-3">
                                                        <div className='dot-bullet'></div>
                                                        <div className="instructions_text p-0"><p className='p-0'>Exercise 30 mins Daily</p></div>
                                                        <div className="col">
                                                            <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                                <i className="icon-edit" />
                                                            </a>
                                                            <a className='btn nostyle-link p-0 ml-1' href="#">
                                                                <i className="icon_delete" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="instructions_list2 col-md-6 d-flex align-items-center pl-3">
                                                        <div className='dot-bullet'></div>
                                                        <div className="instructions_text p-0"><p className='p-0'>Exercise 30 mins Daily</p></div>
                                                        <div className="col">
                                                            <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                                <i className="icon-edit" />
                                                            </a>
                                                            <a className='btn nostyle-link p-0 ml-1' href="#">
                                                                <i className="icon_delete" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row instructions_rows">
                                                    <div className=" instructions_list2 col-md-6 d-flex align-items-center pl-3">
                                                        <div className='dot-bullet'></div>
                                                        <div className="instructions_text p-0"><p className='p-0'>Increase water intake</p></div>
                                                        <div className="col">
                                                            <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                                <i className="icon-edit" />
                                                            </a>
                                                            <a className='btn nostyle-link p-0 ml-1' href="#">
                                                                <i className="icon_delete" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="instructions_list2 col-md-6 d-flex align-items-center pl-3">
                                                        <div className='dot-bullet'></div>
                                                        <div className="instructions_text p-0"><p className='p-0'>Increase water intake</p></div>
                                                        <div className="col">
                                                            <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                                <i className="icon-edit" />
                                                            </a>
                                                            <a className='btn nostyle-link p-0 ml-1' href="#">
                                                                <i className="icon_delete" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>

                                {/* Supporting Material Container */}

                                <div className='container-fluid mt-4' >
                                    <div className='row'>
                                        <div className='col-7 d-flex align-items-center'>
                                            <h6 className='text-secondary'>SUPPORTING MATERIAL</h6>
                                        </div>
                                        <div className='col-5 text-right'>
                                            <a class="btn btn-outline-primary px-2" id="instructions" onClick={() => { this.setState({ supportingUploadModal: true }) }}>+ UPLOAD</a>
                                        </div>
                                    </div>

                                    <div className='row mt-2'>
                                        <div className='col-12'>
                                            <div className="all_instructions">
                                                <div className="row instructions_rows">
                                                    <div className=" instructions_list2 col-md-6 d-flex align-items-center pl-3">
                                                        <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                            <input type="checkbox" className="custom-control-input" name="upload_doc" id="upload_doc"
                                                            />
                                                            <label className="custom-control-label" htmlFor="upload_doc"></label>
                                                        </div>
                                                        <div className="instructions_text p-0"><a href="#" className='text-secondary'><u>upload1.pdf</u></a></div>
                                                        <div className="col text-left">
                                                            <a className='btn nostyle-link p-0 ml-1' href="#">
                                                                <i className="icon_delete" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className=" instructions_list2 col-md-6 d-flex align-items-center pl-3">
                                                        <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                            <input type="checkbox" className="custom-control-input" name="upload_doc" id="upload_doc"
                                                            />
                                                            <label className="custom-control-label" htmlFor="upload_doc"></label>
                                                        </div>
                                                        <div className="instructions_text p-0"><a href="#" className='text-secondary'><u>upload2.pdf</u></a></div>
                                                        <div className="col text-center">
                                                            <a className='btn nostyle-link p-0 ml-1' href="#">
                                                                <i className="icon_delete" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>

                                        </div>
                                    </div>

                                </div>

                                {/* Referral */}
                                <div className='container-fluid referral mt-4' >
                                    <div className='row'>
                                        <div className='col-7 d-flex align-items-center'>
                                            <h6 className='text-secondary'>REFERRAL</h6>
                                        </div>
                                        <div className='col-5 text-right'>
                                            <a class="btn btn-outline-primary px-2" id="referral" onClick={() => { this.setState({ addReferralModal: true }) }}>+ REFERRAL</a>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mt-3'>
                                    <div className='col-12 mb-3'>
                                        <div class="card rounded-0">
                                            <div class="card-body py-3">
                                                <a href="#" class="nostyle-link text-dark">
                                                    <div class="row">
                                                        <div class="col-10 col-md-11">
                                                            <span class="font-weight-semibold">REFERRAL 1</span>
                                                            <span class="d-block d-lg-inline pl-0 pl-lg-5">Speciality : <strong>Endocrinologist</strong></span>
                                                            <span class="d-block d-lg-inline pl-0 pl-lg-5">Doctor's Name : <strong>Sarfaraz</strong> </span>
                                                            <span class="d-block d-lg-inline pl-0 pl-lg-5">Priority : <span class="text-danger">Routine</span></span>
                                                            <span class="d-block d-lg-inline pl-0 pl-lg-5">Doctor's Note : <strong>Please take water</strong></span>
                                                        </div>
                                                        <div class="col-2 col-md-1 align-self-center text-right">
                                                            <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                                <i className="icon-edit" />
                                                            </a>
                                                            <a className='btn nostyle-link p-0 ml-1' href="#">
                                                                <i className="icon_delete" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-12 mb-3'>
                                        <div class="card rounded-0">
                                            <div class="card-body py-3">
                                                <a href="#" class="nostyle-link text-dark">
                                                    <div class="row">
                                                        <div class="col-10 col-md-11">
                                                            <span class="font-weight-semibold">REFERRAL 1</span>
                                                            <span class="d-block d-lg-inline pl-0 pl-lg-5">Speciality : <strong>Endocrinologist</strong></span>
                                                            <span class="d-block d-lg-inline pl-0 pl-lg-5">Doctor's Name : <strong>Sarfaraz</strong> </span>
                                                            <span class="d-block d-lg-inline pl-0 pl-lg-5">Priority : <span class="text-danger">Routine</span></span>
                                                            <span class="d-block d-lg-inline pl-0 pl-lg-5">Doctor's Note : <strong>Please take water</strong></span>
                                                        </div>
                                                        <div class="col-2 col-md-1 align-self-center text-right">
                                                            <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                                <i className="icon-edit" />
                                                            </a>
                                                            <a className='btn nostyle-link p-0 ml-1' href="#">
                                                                <i className="icon_delete" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className="row emrfrm  mt-3 mb-5" id="followup">
                                    <div className="col-12 mb-4">
                                        <div className="card followup_card rounded-0">
                                            <div className="card-body py-3">
                                                <div className='row mb-3'>
                                                    <div className='col-4'>
                                                        <label className="d-inline font-weight-semibold text-dark mr-3">ADMISSION ADVISED </label>
                                                        <div className="mr-2 d-md-inline">
                                                            <Radio.Group>
                                                                <Radio value={0} name="followUpRadio">No</Radio>
                                                                <Radio value={1} name="followUpRadio">Yes</Radio>
                                                            </Radio.Group>

                                                        </div>
                                                    </div>
                                                    <div className='col-2'>
                                                        <span className='ant_switch_button_sm'>
                                                            <BootstrapSwitchButton
                                                                width={150} height={24}
                                                                size="xs"

                                                                offlabel='Inctive'
                                                                onlabel='Active'
                                                                offstyle="success"
                                                                onstyle="alert"

                                                            />
                                                        </span>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className="form-group micwrap ant-mic-wrap hasdata">
                                                            <a
                                                                className='mic subjective-mic '
                                                            ></a>
                                                            <textarea
                                                                className="form-control diagnosis_notes_class speech-field"
                                                                id="chief_complaints"
                                                                name="chief_complaints"
                                                                placeholder="Admission Advised Remark"
                                                                rows="1"
                                                                onInput={(e) => this.autoHeight(e)}
                                                            ></textarea>
                                                            <label htmlFor="chief_complaints">Admission Advised Remark</label>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="row" >
                                                    <div className="col-12 col-xl">
                                                        <label className="d-md-inline font-weight-semibold text-dark mr-3">FOLLOW UP (NUMBER OF DAYS)</label>
                                                        <div className="mr-2 d-md-inline">
                                                            <Radio.Group>
                                                                <Radio value={3} name="followUpRadio" title={'0'}>3</Radio>
                                                                <Radio value={5} name="followUpRadio" title={'0'}>5</Radio>
                                                                <Radio value={7} name="followUpRadio" title={'0'}>7</Radio>
                                                                <Radio value={15} name="followUpRadio" title={'0'}>15</Radio>
                                                                <Radio value={30} name="followUpRadio" title={'0'}>30</Radio>
                                                                <Radio value={60} name="followUpRadio" title={'0'}>60</Radio>
                                                                <Radio value={-1} name="followUpRadio" title={'-1'}>Other</Radio>
                                                            </Radio.Group>

                                                        </div>
                                                        <div className="d-md-inline inline-input-other">
                                                            <div className="form-label-group input-group" id="followup_other_block" >
                                                                <input type="text" id="followup_other_value" className="form-control " name="otherVal" maxLength="3"
                                                                />
                                                                <span className="input-group-addon bg-white">
                                                                    <select className="custom-select"
                                                                        name="follow_up_Others" >
                                                                        <option value={'days'}>Days</option>
                                                                        <option value={'weeks'}>Weeks</option>
                                                                        <option value={'months'}>Months</option>
                                                                        <option value={'years'}>Years</option>
                                                                    </select>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-xl-auto">
                                                        <button type="button" className="float-right btn btn-outline-secondary text-uppercase"
                                                        >BOOK NOW</button>
                                                    </div>
                                                </div>
                                                <div className="fixed-cta">
                                                    <button className="btn btn-outline-secondary text-uppercase px-5 mr-3">PREVIEW RX</button>
                                                    <button className="btn btn-primary text-uppercase px-5 ml-3">PRINT & COMPLETE RX</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>

                    </div>
                </div>

                {/* Subjective- Copy Previous Modal */}

                <Modal
                    title='Copy Previous Modal'
                    visible={this.state.copyPreviousModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >
                    <div className='container copy_prev_modal'>
                        <div className='col-12'>
                            copy prev modal
                        </div>
                    </div>

                </Modal>



                {/* Assessment Favourites Modal */}

                <Modal
                    title='Favourite Assessments'
                    visible={this.state.showAssessmentModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >

                    <div className='row fav_modal'>
                        <div className='col-12'>
                            <table className="table table-bordered fav_modal_table mt-3" id="diagnosis-list">
                                <thead>
                                    <tr>
                                        <td width="10%"></td>
                                        <td width="75%">DRUG NAME</td>
                                        <td width="15%">FAVOURITE</td>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-center'>
                                            <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                <input type="checkbox" className="custom-control-input" name="fav_check" id="fav_check"
                                                />
                                                <label className="custom-control-label" htmlFor="fav_check"></label>
                                            </div>
                                        </td>
                                        <td>Drug Name 1</td>
                                        <td className='text-center'>
                                            <span className="">
                                                <i className="icon_unique icon_star favourite align-star"></i>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center'>
                                            <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                <input type="checkbox" className="custom-control-input" name="fav_check1" id="fav_check1"
                                                />
                                                <label className="custom-control-label" htmlFor="fav_check1"></label>
                                            </div>
                                        </td>
                                        <td>Drug Name 2</td>
                                        <td className='text-center'>
                                            <span className="">
                                                <i className="icon_unique icon_star favourite align-star"></i>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center'>
                                            <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                <input type="checkbox" className="custom-control-input" name="fav_check2" id="fav_check2"
                                                />
                                                <label className="custom-control-label" htmlFor="fav_check2"></label>
                                            </div>
                                        </td>
                                        <td>Drug Name 3</td>
                                        <td className='text-center'>
                                            <span className="">
                                                <i className="icon_unique icon_star favourite align-star"></i>
                                            </span>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                </Modal>

                {/* Plan - Medicine favourite Modal  */}
                <Modal
                    title='Favourite Medicines'
                    visible={this.state.planFavMedicineModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >

                    <div className='row fav_modal'>
                        <div className='col-12'>
                            <table className="table table-bordered fav_modal_table mt-3" id="diagnosis-list">
                                <thead>
                                    <tr>
                                        <td width="10%"></td>
                                        <td width="75%">DRUG NAME</td>
                                        <td width="15%">FAVOURITE</td>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-center'>
                                            <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                <input type="checkbox" className="custom-control-input" name="fav_check" id="fav_check"
                                                />
                                                <label className="custom-control-label" htmlFor="fav_check"></label>
                                            </div>
                                        </td>
                                        <td>Drug Name 1</td>
                                        <td className='text-center'>
                                            <span className="">
                                                <i className="icon_unique icon_star favourite align-star"></i>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center'>
                                            <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                <input type="checkbox" className="custom-control-input" name="fav_check1" id="fav_check1"
                                                />
                                                <label className="custom-control-label" htmlFor="fav_check1"></label>
                                            </div>
                                        </td>
                                        <td>Drug Name 2</td>
                                        <td className='text-center'>
                                            <span className="">
                                                <i className="icon_unique icon_star favourite align-star"></i>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center'>
                                            <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                <input type="checkbox" className="custom-control-input" name="fav_check2" id="fav_check2"
                                                />
                                                <label className="custom-control-label" htmlFor="fav_check2"></label>
                                            </div>
                                        </td>
                                        <td>Drug Name 3</td>
                                        <td className='text-center'>
                                            <span className="">
                                                <i className="icon_unique icon_star favourite align-star"></i>
                                            </span>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">

                            <div className="form-group text-center">
                                <button className="btn btn-outline-secondary px-4  text-uppercase mr-2" >
                                    CLEAR
                                </button>
                                <button className="btn btn-primary px-4 text-uppercase ml-2">
                                    ADD
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-0 '>
                        <div className='col-12 text-center'>
                            <span style={{ color: '#548f3a' }}>Favourite drugs added Successfully</span>
                        </div>
                    </div>

                </Modal>

                {/* Plan - Tests Favourite Modal */}

                <Modal
                    title='Favourite Tests'
                    visible={this.state.planFavTestsModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >

                    <div className='row fav_modal'>
                        <div className='col-12'>
                            <table className="table table-bordered fav_modal_table mt-3" id="diagnosis-list">
                                <thead>
                                    <tr>
                                        <td width="10%"></td>
                                        <td width="75%">TEST NAME</td>
                                        <td width="15%">FAVOURITE</td>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-center'>
                                            <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                <input type="checkbox" className="custom-control-input" name="fav_check" id="fav_check"
                                                />
                                                <label className="custom-control-label" htmlFor="fav_check"></label>
                                            </div>
                                        </td>
                                        <td>Drug Name 1</td>
                                        <td className='text-center'>
                                            <span className="">
                                                <i className="icon_unique icon_star favourite align-star"></i>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center'>
                                            <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                <input type="checkbox" className="custom-control-input" name="fav_check1" id="fav_check1"
                                                />
                                                <label className="custom-control-label" htmlFor="fav_check1"></label>
                                            </div>
                                        </td>
                                        <td>Drug Name 2</td>
                                        <td className='text-center'>
                                            <span className="">
                                                <i className="icon_unique icon_star favourite align-star"></i>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center'>
                                            <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                                <input type="checkbox" className="custom-control-input" name="fav_check2" id="fav_check2"
                                                />
                                                <label className="custom-control-label" htmlFor="fav_check2"></label>
                                            </div>
                                        </td>
                                        <td>Drug Name 3</td>
                                        <td className='text-center'>
                                            <span className="">
                                                <i className="icon_unique icon_star favourite align-star"></i>
                                            </span>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-12">

                            <div className="form-group text-center">
                                <button className="btn btn-outline-secondary px-4  text-uppercase mr-2" >
                                    CLEAR
                                </button>
                                <button className="btn btn-primary px-4 text-uppercase ml-2">
                                    ADD
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-0 '>
                        <div className='col-12 text-center'>
                            <span style={{ color: '#548f3a' }}>Favourite tests added Successfully</span>
                        </div>
                    </div>

                </Modal>

                {/* Assessment - Add Diagnosis Modal */}

                <Modal
                    title='Add Diagnosis'
                    visible={this.state.addDiagnosisModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="1000px"
                >

                    <div className='container-fluid add_diagnosis emrfrm' id="add_diagnosis_modal">
                        <div className='row mt-2'>
                            <div className='col-12'>
                                <div className="form-group">
                                    <Select
                                        id="diagnosis"
                                        name="diagnosis"
                                        style={{ width: 100 + '%' }}
                                        placeholder=" "
                                        showSearch
                                        className="select-diagnosis"
                                    >
                                        <Option value={1}>First Option</Option>
                                        <Option value={2}>Second Option</Option>
                                    </Select>
                                    <label htmlFor="diagnosis">Diagnosis<span className="text-secondary">*</span></label>
                                </div>
                            </div>

                        </div>
                        <div className='row my-2'>
                            <div className="col-12">
                                <div className="form-group micwrap ant-mic-wrap hasdata">
                                    <a
                                        className='mic subjective-mic'
                                    ></a>
                                    <textarea
                                        className="form-control diagnosis_notes_class speech-field"
                                        id="assessmentNotes"
                                        name="diagnosis_notes"
                                        placeholder="Diagnosis Notes"
                                        rows="1"
                                        onInput={(e) => this.autoHeight(e)}
                                    ></textarea>
                                    <label htmlFor="diagnosis_notes">Diagnosis Notes</label>
                                </div>
                            </div>
                        </div>
                        <div className='row ' style={{ backgroundColor: '#e6e6e6', borderRadius: '6px' }}>
                            <div className='col-4 '>
                                <label className="text-grey mb-0" style={{ width: '20%' }}>Stage</label>
                                <div className="form-group custom-control custom-radio custom-control-inline pl-0 mb-0">
                                    <Radio.Group >
                                        <Radio value={0} name="stage" title={'Provisional'}>Provisional</Radio>
                                        <Radio value={1} name="stage" title={'Final'}>Final</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            <div className='col-4 '>
                                <label className="text-grey mb-0" style={{ width: '20%' }}>Type</label>
                                <div className="form-group custom-control custom-radio custom-control-inline pl-0 mb-0">
                                    <Radio.Group >
                                        <Radio value={0} name="stage" title={'Primary'}>Primary</Radio>
                                        <Radio value={1} name="stage" title={'Secondary'}>Secondary</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            <div className='col-4'>
                                <label className="text-grey mb-0" style={{ width: '20%' }}>Status</label>
                                <div className="form-group custom-control custom-radio custom-control-inline pl-0 mb-0">
                                    <Radio.Group>
                                        <Radio value={0} name="stage" title={'Active'}>Active</Radio>
                                        <Radio value={1} name="stage" title={'Cured'}>Cured</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col align-self-start">
                                        <label>Image Reference</label>
                                        <Button className="image-reference-btn nostyle-link h-auto p-0" >
                                            <img src={icImagereference} alt="Image Reference" className="img-fluid" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12">

                                <div className="form-group text-center">
                                    <button className="btn btn-outline-secondary px-4  text-uppercase mr-2" >
                                        CLEAR
                                    </button>
                                    <button className="btn btn-primary px-4 text-uppercase ml-2">
                                        ADD
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>

                </Modal>

                {/* Plan- Add Medicine Modal */}

                <Modal
                    title='Add Medicines'
                    visible={this.state.addMedicineModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="992px"
                    className='add_med_modal'
                >
                    <Form
                        className='emrfrm'
                        layout='vertical'
                    >
                        <div className='container-fluid add_medicines emrfrm' id="add_diagnosis_modal">

                            <div className='container-fluid mt-2 color_rows'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="antSelectItem hasdata">
                                            <Form.Item
                                                label="Medicine Name With Strength"
                                                name="med_name"
                                                rules={[{ required: true }]}
                                                className="mar-bottom"
                                            >
                                                <Select
                                                    style={{ width: '100%' }}
                                                    name="med_name"
                                                    showSearch
                                                >
                                                    <Option value="med1">
                                                        Crocin
                                                    </Option>
                                                    <Option value="med2">
                                                        Dolo 650
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='col-6 d-flex align-items-center'>
                                        <Radio.Group>
                                            <Radio value={1} name="drug_frequency" title={'Daily'}>Daily</Radio>
                                            <Radio value={2} name="drug_frequency" title={'Specific Days'}>Specific Days</Radio>
                                            <Radio value={3} name="drug_frequency" title={'PRN'}>PRN/SOS</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div className='row mt-1'>
                                    <div className='col-5'></div>
                                    <div className='col-7'>
                                        <div className='card add_med_daycard'>

                                            <Checkbox
                                                name="confidential"
                                                className='pr-0'
                                            >
                                                Mon
                                            </Checkbox>
                                            <Checkbox
                                                name="condom"
                                                className='pr-0'
                                            >
                                                Tue
                                            </Checkbox>
                                            <Checkbox
                                                name="iud"
                                                className='pr-0'
                                            >
                                                Wed
                                            </Checkbox>
                                            <Checkbox
                                                name="ocp"
                                                className='pr-0'
                                            >
                                                Thu
                                            </Checkbox>
                                            <Checkbox
                                                name="hormonal"
                                                className='pr-0'
                                            >
                                                Fri
                                            </Checkbox>
                                            <Checkbox
                                                name="hormonal"
                                                className='pr-0'
                                            >
                                                Sat
                                            </Checkbox>
                                            <Checkbox
                                                name="hormonal"
                                                className='pr-0'
                                            >
                                                Sun
                                            </Checkbox>

                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className="col-2 col-md-2">
                                        <div className='antInputItem hasdata'>
                                            <Form.Item
                                                label="Dosage"
                                                name="dosage"
                                            >
                                                <Input
                                                    addonAfter="Tab"
                                                    id="dosage"
                                                    name="dosage"
                                                    type="number"
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='antInputItem hasdata'>
                                            <Form.Item
                                                label="Frequency"
                                                name="frequency"
                                                rules={[{ required: true }]}
                                            >
                                                <Input.Group compact>
                                                    <Select defaultValue="" style={{ width: '60%' }}>
                                                        <Option value="Once">Once</Option>
                                                        <Option value="Twice">Twice</Option>
                                                    </Select>
                                                    <Select defaultValue="" style={{ width: '40%' }}
                                                        className="second_dropdown"
                                                    >
                                                        <Option value="day">Day</Option>
                                                        <Option value="week">Week</Option>
                                                    </Select>
                                                </Input.Group>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className='antSelectItem hasdata'>
                                            <Form.Item
                                                label="Schedule"
                                                name="schedule"
                                            >
                                                <Select
                                                    mode="multiple"
                                                    style={{ width: '100%' }}
                                                    allowClear
                                                >
                                                    <Option value={"mor"}>Morning</Option>
                                                    <Option value={"aft"}>Afternoon</Option>
                                                    <Option value={"eve"}>Evening</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="col-3 pr-0">
                                        <div className='antInputItem hasdata'>
                                            <div className="ant-row ant-input-select">
                                                <div className="col pr-0">
                                                    <Form.Item
                                                        name="duration"
                                                        label='Duration'
                                                        id="duration"

                                                    >
                                                        <Input className="onlynumbers" name="duration" id="duration" maxLength="2" />
                                                    </Form.Item>
                                                </div>
                                                <div className="col-auto pl-0">
                                                    <Form.Item name="time">
                                                        <Select style={{ width: 90 }} defaultValue={"Days"}>
                                                            <Option value="0">Days</Option>
                                                            <Option value="1">Weeks</Option>
                                                            <Option value="2">Months</Option>
                                                            <Option value="3">Years</Option>
                                                        </Select>
                                                    </Form.Item>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-0'>
                                    <div className='col-2'>
                                        <a href="#" className='text-secondary'><u>Clark's Rule</u></a>
                                    </div>
                                    <div className="col-10 text-right">
                                        <div className="custom-control custom-checkbox custom-control-inline">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="continue_till_review"
                                                name="continue_till_review"
                                            />
                                            <label style={{ fontSize: "16px" }} className='custom-control-label cursor-pointer' htmlFor="continue_till_review">
                                                Continue till Review
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='container-fluid p-3 mt-3'>
                                <div className='row'>
                                    <div className='col-8'>
                                        <div className="antSelectItem hasdata">
                                            <Form.Item
                                                label="Instrutions"
                                                name="instructions"
                                            >
                                                <Select
                                                    style={{ width: '100%' }}
                                                    name="instructions"

                                                >
                                                    <Option value="ins1">
                                                        Instruction 1
                                                    </Option>
                                                    <Option value="ins2">
                                                        Instruction 2
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="antSelectItem hasdata">
                                            <Form.Item
                                                label="Route"
                                                name="route"
                                            >
                                                <Select
                                                    style={{ width: '100%' }}
                                                    name="route"

                                                >
                                                    <Option value="ins1">
                                                        Route 1
                                                    </Option>
                                                    <Option value="ins2">
                                                        Route 2
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="antSelectItem hasdata">
                                            <Form.Item
                                                label="Priority"
                                                name="priority"
                                            >
                                                <Select
                                                    style={{ width: '100%' }}
                                                    name="priority"

                                                >
                                                    <Option value="ins1">
                                                        Priority 1
                                                    </Option>
                                                    <Option value="ins2">
                                                        Priority 2
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-10'>
                                        <div className="form-group mb-0 hasdata">
                                            <textarea
                                                className="form-control diagnosis_notes_class speech-field"
                                                id="remarks"
                                                name="remarks"
                                                placeholder="Remarks"
                                                rows="1"
                                                onInput={(e) => this.autoHeight(e)}
                                            ></textarea>
                                            <label htmlFor="remarks">Remarks</label>
                                        </div>
                                    </div>
                                    <div className='col-2 d-flex align-items-center'>
                                        <div className="custom-control custom-checkbox-favourite fav_drug custom-control-inline pl-0">
                                            <input type="checkbox" className="custom-control-input" id="favourite" name="favourite" drug_id="" />
                                            <label className='custom-control-label text-secondary text-underline cursor-pointer favorite label-star' htmlFor="favourite">
                                                <Button style={{ width: 106 + 'px', textAlign: 'right' }} className="nostyle-link text-secondary text-underline p-0">FAVOURITE</Button>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-2'>
                                        <Form.Item
                                            name="corrected_edd"
                                            className='mar-bottom'
                                        >
                                            <DatePicker
                                                name="start_date"
                                                format="DD MMM, YYYY"
                                                placeholder='Start Date'
                                                className='mb-0'
                                                style={{
                                                    width: 100 + "%",
                                                    height: 44 + "px",
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className='col d-flex align-items-center'>
                                        <div className=" custom-control custom-checkbox custom-control-inline" style={{ display: 'inline-flex' }}>
                                            <input type="checkbox" className="custom-control-input" id="taper_dose" name="taper_dose"
                                            />
                                            <label style={{ textDecoration: 'underline', fontSize: "16px" }} className='custom-control-label text-secondary cursor-pointer taper' htmlFor="taper_dose">
                                                TAPER DOSE
                                            </label>
                                        </div>
                                        <div className="custom-control custom-checkbox custom-control-inline padding_lef_corr">
                                            <label style={{ textDecoration: 'underline', paddingLeft: 0, fontSize: "16px" }} className="text-secondary cursor-pointer taper">
                                                CORRECTION DOSE
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mt-5'>
                                    <div className='col-12 text-center'>
                                        <a class="btn btn-outline-secondary mr-2" href="#">CLEAR</a>
                                        <a class="btn btn-primary ml-2" href="#">ADD</a>
                                    </div>
                                </div>
                                <div className='row mt-3 '>
                                    <div className='col-12 text-center'>
                                        <span style={{ color: '#548f3a' }}>Crocin 500 Tablet added Successfully</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Form>
                </Modal>


                {/* Today Vitals- View All modal */}

                <Modal
                    title='View Vitals'
                    visible={this.state.vitalViewModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="1080px"
                >

                    <div className='container-fluid view_vital_ emrfrm' id="view_vital_modal">
                        <div className='row'>
                            <div className='col-6'>

                            </div>
                        </div>
                    </div>
                </Modal>

                {/* Today Vitals- View All Key Findings Modal */}

                <Modal
                    title='Key Findings'
                    visible={this.state.vitalKeyFindModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="1080px"
                >

                    <div className='container-fluid view_vital_ emrfrm' id="view_vital_modal">
                        <div className='row'>
                            <div className='col-6'>

                            </div>
                        </div>
                    </div>
                </Modal>

                {/* Today Vitals- + Key Findings Modal */}

                <Modal
                    title='Add Key Findings'
                    visible={this.state.keyFindingsModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >

                    <div className='container-fluid key_find_modal emrfrm' id="key_find_modal">
                        <Form
                            layout='vertical'
                            className='emrfrm'
                        >
                            <div className='row'>
                                <div className="col-4">
                                    <Form.Item
                                        name="select_date"
                                        className='mb-0'
                                    >
                                        <DatePicker
                                            name="select_date"
                                            format="DD MMM, YYYY"
                                            placeholder='Select Date Time'
                                            className='mb-0'
                                            style={{
                                                width: 100 + "%",
                                                height: 44 + "px",

                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='container keyfind_modal_scroll'>

                                <div className="row mt-2">
                                    <div className='col-12'>
                                        <h6 className='text-dark mb-0'>Glycemic Indicators</h6>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-12'>
                                        <div className='card card_key_modal'>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <div className='antInputItem hasdata'>
                                                        <Form.Item
                                                            label="HbA1c"
                                                            name="hba1"
                                                        >
                                                            <Input
                                                                addonAfter="&#37;"
                                                                id="hba1"
                                                                name="hba1"
                                                                type="text"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className="row mt-2">
                                    <div className='col-12'>
                                        <h6 className='text-dark mb-0'>Thyroid Profile</h6>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-12'>
                                        <div className='card card_key_modal'>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <div className='antInputItem hasdata'>
                                                        <Form.Item
                                                            label="TSH"
                                                            name="tsh"
                                                        >
                                                            <Input
                                                                addonAfter="mlU/L"
                                                                id="tsh"
                                                                name="tsh"
                                                                type="text"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='antInputItem'>
                                                        <Form.Item
                                                            label="T3"
                                                            name="t3"
                                                        >
                                                            <Input
                                                                addonAfter="ng/dl"
                                                                id="t3"
                                                                name="t3"
                                                                type="text"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='antInputItem'>
                                                        <Form.Item
                                                            label="T4"
                                                            name="t4"
                                                        >
                                                            <Input
                                                                addonAfter="mcg/dl"
                                                                id="t4"
                                                                name="t4"
                                                                type="text"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className="row mt-2">
                                    <div className='col-12'>
                                        <h6 className='text-dark mb-0'>Lipid Profile</h6>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-12'>
                                        <div className='card card_key_modal'>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <div className='antInputItem hasdata'>
                                                        <Form.Item
                                                            label="Total Cholesterol"
                                                            name="total_choles"
                                                        >
                                                            <Input
                                                                addonAfter="mg/dL"
                                                                id="total_choles"
                                                                name="total_choles"
                                                                type="text"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='antInputItem'>
                                                        <Form.Item
                                                            label="Triglycerides"
                                                            name="trigly"
                                                        >
                                                            <Input
                                                                addonAfter="mg/dL"
                                                                id="trigly"
                                                                name="trigly"
                                                                type="text"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='antInputItem'>
                                                        <Form.Item
                                                            label="HDL"
                                                            name="hdl"
                                                        >
                                                            <Input
                                                                addonAfter="mg/dL"
                                                                id="hdl"
                                                                name="hdl"
                                                                type="text"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-4'>
                                                    <div className='antInputItem'>
                                                        <Form.Item
                                                            label="LDL"
                                                            name="ldl"
                                                        >
                                                            <Input
                                                                addonAfter="mg/dL"
                                                                id="ldl"
                                                                name="ldl"
                                                                type="text"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className='col-12'>
                                        <h6 className='text-dark mb-0'>Liver Profile</h6>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-12'>
                                        <div className='card card_key_modal'>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    <div className='antInputItem hasdata'>
                                                        <Form.Item
                                                            label="SGOT"
                                                            name="sgot"
                                                        >
                                                            <Input
                                                                addonAfter="U/L"
                                                                id="sgot"
                                                                name="sgot"
                                                                type="text"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='antInputItem'>
                                                        <Form.Item
                                                            label="SGPT"
                                                            name="sgpt"
                                                        >
                                                            <Input
                                                                addonAfter="U/L"
                                                                id="sgpt"
                                                                name="sgpt"
                                                                type="text"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='antInputItem'>
                                                        <Form.Item
                                                            label="GGT"
                                                            name="ggt"
                                                        >
                                                            <Input
                                                                addonAfter="IU/L"
                                                                id="ggt"
                                                                name="ggt"
                                                                type="text"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-5'>
                                <div className='col-12 text-center'>
                                    <a class="btn btn-outline-secondary mr-2" href="#">CLEAR</a>
                                    <a class="btn btn-primary ml-2" href="#">ADD</a>
                                </div>
                            </div>
                            <div className='row mt-3 '>
                                <div className='col-12 text-center'>
                                    <span style={{ color: '#548f3a' }}>Key Findings added Successfully</span>
                                </div>
                            </div>

                        </Form>

                    </div>

                </Modal>

                {/* Objective- Add Alllergies Modal */}

                <Modal
                    title='Add Allergies'
                    visible={this.state.addAllergiesModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >

                    <div className='container-fluid view_vital_ emrfrm' id="view_vital_modal">
                        <div className='row'>
                            <div className='col-6'>
                                place allergies component here
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* Objective- Add Conditions Modal */}

                <Modal
                    title='Add Conditions'
                    visible={this.state.addConditionsModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >

                    <div className='container-fluid view_vital_ emrfrm' id="">
                        <div className='row'>
                            <div className='col-6'>
                                place conditions component here
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* Objective- Add Surgical Modal */}

                <Modal
                    title='Add Allergies'
                    visible={this.state.addSurgicalModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >

                    <div className='container-fluid view_vital_ emrfrm' id="">
                        <div className='row'>
                            <div className='col-6'>
                                place surgical component here
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* Objective- Add Social Modal */}

                <Modal
                    title='Add Social Hx'
                    visible={this.state.addSocialModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >

                    <div className='container-fluid view_vital_ emrfrm' id="">
                        <div className='row'>
                            <div className='col-6'>
                                place social component here
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* Objective- Add Family Modal */}

                <Modal
                    title='Add Family Hx'
                    visible={this.state.addFamilyModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >

                    <div className='container-fluid view_vital_ emrfrm' id="">
                        <div className='row'>
                            <div className='col-6'>
                                place family component here
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* Obs&gynae- Menstrual View Past Modal */}

                <Modal
                    title='Menstrual View Past Modal'
                    visible={this.state.menstrualViewPastModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >
                    <div className='container copy_prev_modal'>
                        <div className='col-12'>
                            copy prev modal
                        </div>
                    </div>

                </Modal>

                {/* Obs&gynae- Sexual View Past Modal */}

                <Modal
                    title='Sexual View Past Modal'
                    visible={this.state.sexualViewPastModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >
                    <div className='container copy_prev_modal'>
                        <div className='col-12'>
                            sexual view past modal
                        </div>
                    </div>
                </Modal>

                {/* Obs&gynae- Obs Prev Preg Modal */}

                <Modal
                    title='Obstetrics Prev Preg Modal'
                    visible={this.state.obstetricsPrevPregModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >
                    <div className='container copy_prev_modal'>
                        <div className='col-12'>
                            copy prev modal
                        </div>
                    </div>
                </Modal>

                {/* Obs&gynae- Obs View Past Modal */}

                <Modal
                    title='Obstetrics View Past Modal'
                    visible={this.state.obstetricsViewPastModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >
                    <div className='container copy_prev_modal'>
                        <div className='col-12'>
                            Obs View Past modal
                        </div>
                    </div>
                </Modal>

                {/* Obs&gynae- Obs Physical Examination Modal */}

                <Modal
                    title='Obstetrics Physical Examination Modal'
                    visible={this.state.physicalViewPastModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >
                    <div className='container copy_prev_modal'>
                        <div className='col-12'>
                            Physical View Past modal
                        </div>
                    </div>
                </Modal>

                {/* Intsructions- Add Instructions Modal */}

                <Modal
                    title='Add Instructions Modal'
                    visible={this.state.addInstructionsModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >
                    <div className='container copy_prev_modal'>
                        <div className='col-12'>
                            Add Instructions modal
                        </div>
                    </div>
                </Modal>

                {/* Referral- Add Referral Modal */}

                <Modal
                    title='Add Refferal Modal'
                    visible={this.state.addReferralModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >
                    <div className='container copy_prev_modal'>
                        <div className='col-12'>
                            Add Referral modal
                        </div>
                    </div>
                </Modal>


                {/* Suggested care protocol- View all modal */}

                <Modal
                    title='Care Protocols'
                    visible={this.state.showSuggestedCareModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >
                    <div className='container copy_prev_modal'>
                        <div className='col-12'>
                            Add Referral modal
                        </div>
                    </div>
                </Modal>

                {/* Suggested care protocol- Partcular care modal */}

                <Modal
                    title='Care Protocols- Fever'
                    visible={this.state.careProtocolparticularModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="992px"
                >
                    <div className='container p-0 suggest_care_modal'>
                        <div className='row'>
                            <div className='col-12'>
                                <h6 className='text-dark mb-0'>Assessment</h6>
                            </div>
                        </div>

                        <table className="table table-bordered suggested-modal-table mt-3" id="diagnosis-list">
                            <thead>
                                <tr>
                                    <td width="15%">DIAGNOSIS</td>
                                    <td width="10%">ICD 10</td>
                                    <td width="10%">STAGE</td>
                                    <td width="10%">TYPE</td>
                                    <td width="10%">STATUS</td>
                                    <td width="20%">NOTES</td>
                                    <td width="15%">IMAGE REFERENCE</td>
                                    <td width="10%">ACTION</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Diabetes Insipedus</td>
                                    <td>E23.4</td>
                                    <td>Provisional</td>
                                    <td>Primary</td>
                                    <td>
                                        <span className='ant_switch_button_sm'>
                                            <BootstrapSwitchButton
                                                width={150} height={24}
                                                size="xs"

                                                offlabel='Inctive'
                                                onlabel='Active'
                                                offstyle="success"
                                                onstyle="alert"

                                            />
                                        </span>

                                    </td>
                                    <td>Consistently high blood sugar reading</td>
                                    <td><i className="mr-2 icon-pdf"></i></td>
                                    <td className='text-center'>
                                        <a className='btn nostyle-link p-0 mr-1' href="#" >
                                            <i className="icon-edit" />
                                        </a>
                                        <a className='btn nostyle-link p-0 ml-1' href="#">
                                            <i className="icon_delete" />
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Diabetes Insipedus</td>
                                    <td>E23.4</td>
                                    <td>Provisional</td>
                                    <td>Primary</td>
                                    <td>
                                        <span className='ant_switch_button_sm'>
                                            <BootstrapSwitchButton
                                                width={150} height={24}
                                                size="xs"

                                                offlabel='Inctive'
                                                onlabel='Active'
                                                offstyle="success"
                                                onstyle="alert"

                                            />
                                        </span>

                                    </td>
                                    <td>Consistently high blood sugar reading</td>
                                    <td><i className="mr-2 icon-pdf"></i></td>
                                    <td className='text-center'>
                                        <a className='btn nostyle-link p-0 mr-1' href="#" >
                                            <i className="icon-edit" />
                                        </a>
                                        <a className='btn nostyle-link p-0 ml-1' href="#">
                                            <i className="icon_delete" />
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className='row mt-3'>
                            <div className='col-5'>
                                <h6 className='text-dark mb-0'>Medicines</h6>
                            </div>
                            <div className='col-7 text-right'>
                                <a class="btn btn-outline-primary px-2 py-0" id="diagnosis" onClick={() => { this.setState({ addMedicineModal: true }) }}>+ MEDICINES</a>
                            </div>
                        </div>

                        <table className="table table-bordered suggested-modal-table mt-2" >
                            <thead>
                                <tr>
                                    <td width="26%">NAME</td>
                                    <td width="8%">ROUTE</td>
                                    <td width="8%">DOSAGE</td>
                                    <td width="8%">FREQUENCY</td>
                                    <td width="8%">SCHEDULE</td>
                                    <td width="8%">DURATION</td>
                                    <td width="17%">INSTRUCTIONS</td>
                                    <td width="9%">DUE FROM</td>
                                    <td width="8%">ACTION</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='remarks-included'><span className='text-dark bold-500 '>Ecosprin Tablet 75mg</span></td>
                                    <td>Oral</td>
                                    <td>1 Tab</td>
                                    <td>Thrice a day</td>
                                    <td>Morning-Afternoon-Evening</td>
                                    <td>2 Days</td>
                                    <td>As Directed</td>
                                    <td><div>15 Mar,2022</div><div>Urgent</div></td>
                                    <td className='text-center'>
                                        <a className='btn nostyle-link p-0 mr-1' href="#" >
                                            <i className="icon-edit" />
                                        </a>
                                        <a className='btn nostyle-link p-0 ml-1' href="#">
                                            <i className="icon_delete" />
                                        </a>
                                    </td>
                                </tr>
                                <tr><td colspan={9} className="remarks-column">
                                    <span className='bold-500'>Remarks :&nbsp;</span><span>Apply a thin layer of some cotton Apply a thin layer of some cotton</span>
                                </td>
                                </tr>
                                <tr>
                                    <td><span className='text-dark bold-500'>Ecosprin Tablet 75mg</span></td>
                                    <td>Oral</td>
                                    <td>1 Tab</td>
                                    <td>Thrice a day</td>
                                    <td>Morning-Afternoon-Evening</td>
                                    <td>2 Days</td>
                                    <td>As Directed</td>
                                    <td><div>15 Mar,2022</div><div className='text-secondary'>STAT</div></td>
                                    <td className='text-center'>
                                        <a className='btn nostyle-link p-0 mr-1' href="#" >
                                            <i className="icon-edit" />
                                        </a>
                                        <a className='btn nostyle-link p-0 ml-1' href="#">
                                            <i className="icon_delete" />
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='remarks-included'><span className='text-dark bold-500 '>Ecosprin Tablet 75mg</span></td>
                                    <td>Oral</td>
                                    <td>1 Tab</td>
                                    <td>Thrice a day</td>
                                    <td>Morning-Afternoon-Evening</td>
                                    <td>2 Days</td>
                                    <td>As Directed</td>
                                    <td><div>15 Mar,2022</div><div>Urgent</div></td>
                                    <td className='text-center'>
                                        <a className='btn nostyle-link p-0 mr-1' href="#" >
                                            <i className="icon-edit" />
                                        </a>
                                        <a className='btn nostyle-link p-0 ml-1' href="#">
                                            <i className="icon_delete" />
                                        </a>
                                    </td>
                                </tr>
                                <tr><td colspan={9} className="remarks-column">
                                    <span className='bold-500'>Remarks :&nbsp;</span><span>Apply a thin layer</span>
                                </td>
                                </tr>

                            </tbody>
                        </table>

                        <div className='row mt-3'>
                            <div className='col-5'>
                                <h6 className='text-dark mb-0'>Tests</h6>
                            </div>
                            <div className='col-7 text-right'>
                                <a class="btn btn-outline-primary px-2 py-0" id="diagnosis" href="#">+ TESTS/PROCEDURES</a>
                            </div>
                        </div>

                        <table className="table table-bordered suggested-modal-table mt-2" >
                            <thead>
                                <tr>
                                    <td width="40%">TEST NAME</td>
                                    <td width="12%">PRIORITY</td>
                                    <td width="40%">INSTRUCTIONS</td>
                                    <td width="8%">ACTION</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><span className='text-dark bold-500'>CBC (Complete Blood Care with ESR)</span></td>
                                    <td>Routine</td>
                                    <td>Fill instruction here</td>

                                    <td className='text-center'>
                                        {/* <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                                        <i className="icon-edit" />
                                                                    </a> */}
                                        <a className='btn nostyle-link p-0 ml-1' href="#">
                                            <i className="icon_delete" />
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span className='text-dark bold-500'>CBC (Complete Blood Care with ESR)</span></td>
                                    <td>Routine</td>
                                    <td>Fill instruction here</td>

                                    <td className='text-center'>
                                        {/* <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                                        <i className="icon-edit" />
                                                                    </a> */}
                                        <a className='btn nostyle-link p-0 ml-1' href="#">
                                            <i className="icon_delete" />
                                        </a>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                        <div className='row'>
                            <div className='col-7 d-flex align-items-center'>
                                <h6 className='text-dark mb-0'>Instructions</h6>
                            </div>
                            <div className='col-5 text-right'>
                                <a class="btn btn-outline-primary px-2 py-0" id="instructions" onClick={() => { this.setState({ addInstructionsModal: true }) }}>+ INSTRUCTIONS</a>
                            </div>
                        </div>

                        <div className='row mt-2'>
                            <div className='col-12'>
                                <div className="all_instructions">
                                    <div className="row instructions_rows">
                                        <div className=" instructions_list2 col-md-6 d-flex align-items-center pl-3">
                                            <div className='dot-bullet'></div>
                                            <div className="instructions_text p-0"><p className='p-0'>Exercise 30 mins Daily</p></div>
                                            <div className="col">
                                                <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                    <i className="icon-edit" />
                                                </a>
                                                <a className='btn nostyle-link p-0 ml-1' href="#">
                                                    <i className="icon_delete" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="instructions_list2 col-md-6 d-flex align-items-center pl-3">
                                            <div className='dot-bullet'></div>
                                            <div className="instructions_text p-0"><p className='p-0'>Exercise 30 mins Daily</p></div>
                                            <div className="col">
                                                <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                    <i className="icon-edit" />
                                                </a>
                                                <a className='btn nostyle-link p-0 ml-1' href="#">
                                                    <i className="icon_delete" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row instructions_rows">
                                        <div className=" instructions_list2 col-md-6 d-flex align-items-center pl-3">
                                            <div className='dot-bullet'></div>
                                            <div className="instructions_text p-0"><p className='p-0'>Increase water intake</p></div>
                                            <div className="col">
                                                <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                    <i className="icon-edit" />
                                                </a>
                                                <a className='btn nostyle-link p-0 ml-1' href="#">
                                                    <i className="icon_delete" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="instructions_list2 col-md-6 d-flex align-items-center pl-3">
                                            <div className='dot-bullet'></div>
                                            <div className="instructions_text p-0"><p className='p-0'>Increase water intake</p></div>
                                            <div className="col">
                                                <a className='btn nostyle-link p-0 mr-1' href="#" >
                                                    <i className="icon-edit" />
                                                </a>
                                                <a className='btn nostyle-link p-0 ml-1' href="#">
                                                    <i className="icon_delete" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="form-group text-center">
                                    <button className="btn btn-primary px-4 text-uppercase ml-2">
                                        CONFIRM
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal>

                {/* Suggested care protocol- Diagnosis modal */}

                <Modal
                    title='Diagnosis'
                    visible={this.state.diagnosisparticularModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >
                    <div className='container copy_prev_modal'>
                        <div className='col-12'>
                            Diagnosis Individual
                        </div>
                    </div>
                </Modal>

                {/* Supporting Documents- +Upload Modal*/}

                <Modal
                    title='Upload Documents'
                    visible={this.state.supportingUploadModal}
                    onCancel={() => this.handleCancel()}
                    footer={false}
                    width="850px"
                >
                    <div className='container copy_prev_modal'>
                        <div className='col-12'>
                            Upload Support docs modal
                        </div>
                    </div>
                </Modal>


            </>
        )
    }
}

export default soapsingle;

