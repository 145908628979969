import React, { Component, Fragment } from 'react';
import { INSTRUCTION_SEARCH } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import { Modal, Button } from 'antd';
import axios from 'axios';
import icsearch from '../../assets/images/ic-search.svg'
import { jQueryLabels, AntSelectLabels, FixedCta } from '../../utils/jQueryLabels';
class InstructionTable extends Component {
    constructor(props) {
        super(props)
        this.state = ({
            instructionsData: [],
            viewType:'full', //template, full
            constants:[]
        })
    }
    showInstModal = () => { this.setState({ isInstModalVisible: true }) };
    handleInstOk = () => { this.setState({ isInstModalVisible: false, listingEnabled: true }); };
    handleInstCancel = () => { this.setState({ isInstModalVisible: false }) };

    componentDidMount() {
        jQueryLabels();
        FixedCta();
    }
    componentDidUpdate(prevProps, prevState) {

    }
    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            instructionsData: nextProps.instructionsData,
            viewType: nextProps.viewType,
            constants:nextProps.constants
        }
    }
    render() {

        return (
            <Fragment>
                <ul className="listed-success round-bullet-success">
                    {this.state.instructionsData.map((obj, i) => {
                        return (
                            <li key={i}>{obj.instruction}</li>
                        )
                    })
                    }
                </ul>
            </Fragment>
        );
    }
}

export default InstructionTable;