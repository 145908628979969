import React, { Component } from 'react';
import patientImg from '../../assets/images/sagar-sachdeva.jpg';
import API_SERVICE from '../../utils/apiService';
import qs from 'qs';
import { USERTYPES, SYNC_TOKEN, SYNC_TOKEN_MHEA, SEARCH_PATIENT, SOAP_TYPE_FETCH, SINGLE_SCREEN_CONFIGURATION_FETCH, SCHEDULE_IMMEDIATE_APPOINTEMENT_PATIENT, SOAP_CONFIGURATION_LIST, TODAYS_VISIT, HOSPITAL_CONFIG, LOADER, LOADER_RED, MYHC_APPTYPE, MHVCMARKETPLACE, WOODLANDS, MHEA, REFERENCE_TYPE, PROFILE, BASIC } from '../../utils/constant';
import { notification } from 'antd';
import Moment from 'react-moment';
import moment from "moment";
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { jQueryLabels, AntSelectLabels } from '../../utils/jQueryLabels';
import Axios from 'axios';
import LS_SERVICE from '../../utils/localStorage';
import NavHistory from '../../utils/nav-history';
import AVATAR from '../../assets/images/user.png';
import { headerService$, hospitalModalService, practiceService } from '../../utils/rxjs-sharing';
import Hospitalsmodal from './hospitalsmodal';

class SearchPatient extends Component {

  state = {
    name: '',
    number: '',
    mhid: '',
    isdCode: '91',
    loading: false,
    patientsDataLoaded: false,
    patients: [],
    countOfhospitals : true,
    hospitalsExist : true,
    hospitalModal : false,
    spin_loading: false,
    start_consult_id: ''
  }

  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
      jQueryLabels();

    this.loadSearchedResults();
    this.getHospitals();
  }

  componentDidUpdate(){
    jQueryLabels();
    AntSelectLabels();
  }

  loadSearchedResults() {
    const referrer = NavHistory.referer();
    if (referrer !== undefined && referrer.includes("/patient/past-visit/")) {
      this.setState({
        name: LS_SERVICE.get('searched_name'),
        number: LS_SERVICE.get('searched_number'),
        mhid: LS_SERVICE.get('searched_mhid'),
        isdCode: LS_SERVICE.get('searched_isdcode')
      }, () => {
        AntSelectLabels();
        this.getPatients();
      });
    } else {
      LS_SERVICE.delete('searched_name');
      LS_SERVICE.delete('searched_number');
      LS_SERVICE.delete('searched_isdcode');
      LS_SERVICE.delete('searched_mhid');
    }
    return;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let inputVal = value.trim()

    if (event.target.name === 'name') {
      // inputVal = event.target.value.trim().replace(/[^A-Za-z]/ig, '');
      inputVal = event.target.value.replace(/[^a-zA-Z ]/g, '');
    }
    if (event.target.name === 'number') {
      inputVal = event.target.value.replace(/[^0-9]/g, '');
    }
    if (event.target.name === 'isdCode') {
      inputVal = event.target.value.substring(0, 4);
    }
    if (event.target.name === 'mhid') {
      inputVal = event.target.value.replace(/[^a-zA-Z0-9\.\-]/g, '');
    }

    this.setState({
      [name]: inputVal
    });
  }

  search(event) {
    event.preventDefault();
    if (this.state.isdCode === '') {
      notification.destroy();
      notification['error']({
        description: "ISD Code cannot be empty"
      });
      return;
    }
    if (this.state.name === '' && this.state.number === '' && this.state.mhid === '') {
      return;
    }
    LS_SERVICE.set('searched_name', this.state.name);
    LS_SERVICE.set('searched_number', this.state.number);
    LS_SERVICE.set('searched_isdcode', this.state.isdCode);
    LS_SERVICE.set('searched_mhid', this.state.mhid);
    this.getPatients();
  }

  async getPatients() {
    this.setState({ loading: true, patients: [] });

    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    const postdata = {
      patientName: this.state.name,
      patientNumber: this.state.number,
      patientMHID: this.state.mhid
    };
    if (MYHC_APPTYPE === MHVCMARKETPLACE || MYHC_APPTYPE === MHEA) {
      postdata.isdCode = this.state.isdCode;
    }

    const patients = await Axios.post(SEARCH_PATIENT, qs.stringify(postdata)).catch(err => {
      const res = err.response;

      if (res.status === 500) {
        this.setState({
          loading: false,
          patients: [],
          patientsDataLoaded: true
        });
      }
    });

    // debugger;
    notification.destroy();
    if (
      typeof patients?.data.data != 'undefined' &&
      typeof patients?.data.data.patients != 'undefined' &&
      patients?.data.data.patients.length === 0) {

      this.setState({ loading: false });
      // if (this.state.name != '' && this.state.number != '') {
      //   let msg = (MYHC_APPTYPE === WOODLANDS) ? "Patient's name and/or phone number or UHID combination does not exist." : "Patient's name and/or phone number combination does not exist.";
      //   notification['error']({
      //     description: msg
      //   });
      // } else if (this.state.name != '' && this.state.number == '') {
      //   notification['error']({
      //     description: "Patient's name does not exist."
      //   });
      // } else if (this.state.name == '' && this.state.number != '') {
      //   let msg = (MYHC_APPTYPE === WOODLANDS) ? "Patient's phone number or UHID does not exist." : "Patient's phone number does not exist.";
      //   notification['error']({
      //     description: msg
      //   });
      // }

      var arrayMessage = [];
      if(this.state.name != ''){
        arrayMessage.push('name')
      }
      if(this.state.number != ''){
        if(MYHC_APPTYPE === WOODLANDS){
          arrayMessage.push('phone number or UHID')
        }
        else{
          arrayMessage.push('phone number')
        }
      }
      if(this.state.mhid != ''){
        if(MYHC_APPTYPE != WOODLANDS){
          arrayMessage.push('MHID/UHID')
        }
      }
      if(arrayMessage.length > 0){
        var msg = '';
        if(arrayMessage.length == 1){
          msg = "Patient's "+arrayMessage[0]+" does not exist."
        }
        else{
          msg = "Patient's "+arrayMessage.join(" and/or ")+" combination does not exist."
        }
        notification['error']({
          description: msg
        });
      }
    }
    if (
      typeof patients?.data.data != 'undefined' &&
      typeof patients?.data.data.patients != 'undefined' &&
      patients?.data.data.patients?.length > 0) {
      this.setState({
        loading: false,
        patients: patients?.data.data.patients,
        patientsDataLoaded: true
      });
    }
  }

  loader() {
    return (
      <div className="col-md-12 text-center">{LOADER_RED}</div>
    )
  }

  getAge(dob) {
    if (dob) {
      let dateParts = dob.split('-');
      let dateObject = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);
      let newdob = new Date(dateObject.toString());
      let today = new Date();
      let age = Math.floor(
        (today - newdob) / (365.25 * 24 * 60 * 60 * 1000)
      );
      return age + ' year(s)';
    }
    return '';
  }

  profiling(patient) {
    let info = [];
    if (patient.gender) {
      info.push(patient.gender);
    }
    if (patient.dob) {
      info.push(this.getAge(patient.dob));
    }
    if (patient.blood_group) {
      info.push('Blood Group: ' + patient.blood_group);
    }
    return info.join(' | ');
  }

  patientCards() {
    const { patients, patientsDataLoaded } = this.state;
    return (
      <>
        {patientsDataLoaded ? (
          patients.length > 0 ? (
            patients.map((patient, index) => (
              <div className="col-md-6" key={index}>
                <div className="card search-card mb-3">
                  <div className="row">
                    <figure className="col-md-2 mb-0"><img className="img-circle img-fluid" src={patient.profile_picture != null ? patient.profile_picture : AVATAR} alt="" /></figure>
                    <div className="col-md-4 col-lg-5 col-xl-4">
                      <h5 className="text-heading">{patient.first_name && patient.first_name != "null" ? patient.first_name : ""} {patient.middle_name && patient.middle_name != "null" ? patient.middle_name : ""} {patient.last_name && patient.last_name != "null" ? patient.last_name : ""}</h5>
                      <p className="text-primary mb-1">{MYHC_APPTYPE === WOODLANDS ? <>UHID :</> : null} {patient.uhid}</p>
                      <p className="mb-1 text-muted">{this.profiling(patient)}</p>
                      <p className="mb-0 text-muted"><i className="icon_phone"></i><strong>+{patient.isd_code}-{patient.mobile_no}</strong></p>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-6 text-center">
                      <div className="row justify-content-end">
                      {/* <div className="col-12 col-lg-12 col-xl-6  pr-xl-0">
                        {
                          this.state.hospitalsExist && MYHC_APPTYPE === MHVCMARKETPLACE
                        ?
                          <>
                            {
                              this.state.countOfhospitals
                            ?
                              <button onClick={() => this.scheduleImmediateAppointment(this.state.hospital_id, patient.patient_id, index, patient)} type='button' className="btn  btn-primary btn-block text-uppercase px-1  mb-3 font-14" disabled={(this.state.start_consult_id === index)? this.state.spin_loading : null}>START CONSULT {(this.state.start_consult_id === index)? this.state.spin_loading ? LOADER : null : ''}</button>
                            : 
                              <button onClick={() => this.handleModalPopup(patient.patient_id, index)} type='button' className="btn  btn-primary btn-block text-uppercase px-1 mb-3 font-14" disabled={(this.state.start_consult_id === index)? this.state.spin_loading : null}>START CONSULT {(this.state.start_consult_id === index)? this.state.spin_loading ? LOADER : null : ''} </button>
                            }
                          </>
                        :
                          null
                        }
                      </div> */}
                      </div>
                        
                          <div className="row justify-content-end flex-column flex-xl-row">
                            <div className="col-12 col-lg-12 col-xl-6  pr-xl-0">
                          
                          <span>
                        {patient.lastVisitToMe ? (<><Link id={`last-visit-to-me-${patient.patient_id}`}
                        to={{
                          pathname: `/patient/past-visit/${patient.lastVisitToMe.hospitalId}/${patient.patient_id}/${patient.lastVisitToMe.referenceType}/${patient.lastVisitToMe.referenceId}/self`,
                          searchPatientData: patient,
                        }} className="btn btn-outline-secondary btn-block px-1  mr-0 mr-xl-2 font-14">LAST VISIT TO ME</Link>
                        <small><Moment date={patient.lastVisitToMe.createdAt} format="D MMM, YYYY" /></small></>) : null}
                        </span>
                        </div>

                        <div className="col-12 col-lg-12 col-xl-6  pr-xl-0">
                      <span>
                      {patient.lastVisit ? (<><Link id={`last-visit-${patient.patient_id}`}
                        to={{
                          pathname: `/patient/past-visit/${patient.lastVisit.hospitalId}/${patient.patient_id}/${patient.lastVisit.referenceType}/${patient.lastVisit.referenceId}/any`,
                          searchPatientData: patient,
                        }} className="btn btn-outline-secondary btn-block px-1 ml-0  mt-2 mt-xl-0 font-14">LAST VISIT</Link>
                        <small><Moment date={patient.lastVisit.createdAt} format="D MMM, YYYY" /></small></>) : null}
                      </span>
                      </div>
                          </div>
                        
                      
                       
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
              'No Patients Found'
            )) : null}
      </>
    )
  }

  getHospitals = async () => {
    let hospitals = LS_SERVICE.get('slot_hospitals').filter( (hosp) => hosp.doctor_start_consult == true);
    let hospitalId= hospitals.map((data, i) => { return data.id  });
    this.setState({
      hospital_id : (hospitals.length === 1) ? hospitalId[0] : null,
      countOfhospitals : (hospitals.length === 1) ? true : false,
      hospitalsExist : (hospitals.length > 0) ? true : false,
    });
  }

  handleModalPopup (patient_id=null, start_consult_id=null){
    hospitalModalService.init({
      hospitalModal : true,
      patient_id : patient_id,
      start_consult_id : start_consult_id
    });
  }

  handleBasicOrSoap = async (hospital_id) => {
    this.setState({
      hospital_id: hospital_id
    })

    const PARAMS = {
      hospital_id: hospital_id,
      speciality_id: LS_SERVICE.get('speciality_id'),
      staff_id: LS_SERVICE.get('staff_id')
    }
    var success;
    if(LS_SERVICE.get("hospitalConfigDataResponse")?.data){
        success = LS_SERVICE.get("hospitalConfigDataResponse");
    }
    else{
        success = await Axios.post(HOSPITAL_CONFIG, qs.stringify(PARAMS));
        LS_SERVICE.set("hospitalConfigDataResponse", {data: success.data})
    }
      // .then(success => {
        let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
        let hospitalConfiguration = hospitalConfigData.find(item => item.hospital_id == hospital_id);
        if (hospitalConfiguration) {
          hospitalConfigData.forEach((hcd, idx) => {
            if (+hcd.hospital_id == +hospital_id) {
              hospitalConfigData[idx].opd_layout = success.data.data.opd_layout;
              hospitalConfigData[idx].pres_modification = success.data.data.pres_modification;
              hospitalConfigData[idx].allow_hours = success.data.data.allow_hours;
              hospitalConfigData[idx].duplicate_order = success.data.data.duplicate_order;
            }
          })
        } else {
          hospitalConfigData.push({
            hospital_id: hospital_id,
            opd_layout: success.data.data.opd_layout,
            pres_modification: success.data.data.pres_modification,
            allow_hours: success.data.data.allow_hours,
            duplicate_order: success.data.data.duplicate_order
          })
        }
        this.setState({
          pres_modification: success.data.data.pres_modification,
          allow_hours: success.data.data.allow_hours
        })

        LS_SERVICE.set('hospitalConfigData', hospitalConfigData);
        headerService$.soapConfig({ soapConfig: success.data.data.opd_layout });
        this.setState({
          soapConfig: success.data.data.opd_layout
        })
      // })
      // .catch(error => console.log(error))

      const is_doctor = LS_SERVICE.get('user_type') == USERTYPES.doctor;
      if(LS_SERVICE.get("profile")?.data){
        LS_SERVICE.set('isPaediatrician', LS_SERVICE.get("profile")?.data?.paediatric || 0)
      }
      else{
        Axios.post(PROFILE({ is_doctor }), qs.stringify())
        .then(response => {
          LS_SERVICE.set("profile",{data: response?.data?.data});
          LS_SERVICE.set('isPaediatrician', response.data.data.paediatric || 0)
        }).catch(error => console.log(error))
      }
  }

  async initSoapList(hospital_id) {
    const soapList = await API_SERVICE.post(SOAP_CONFIGURATION_LIST, {
      hospital_id: hospital_id,
      staff_id: LS_SERVICE.get('staff_id'),
      speciality_id: LS_SERVICE.get('speciality_id') || null
    });

    LS_SERVICE.set('soap_conf_list', soapList);
    LS_SERVICE.set('soap_conf_list_' + hospital_id, soapList);

    const pageList = [];
    const newList = [];

    const newSoapList = Object.values(soapList).map((data) => {
      return data.sort((a, b) => a.display_ordering - b.display_ordering)
    });

    Object.values(newSoapList).flat().forEach((en) => {
      if (en.display === 'Y') {
        if (!pageList.includes((en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name)) {
          pageList.push((en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name);
          newList.push({
            'previous_url': en.alternate_url_format,
            'url': en.alternate_url_format,
            'parent_module': en.parent_module,
            'module': (en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name
          })
        }
      }
    });

    if (newList.length > 0) {
      this.setState({
        goToSoapPatientUrl: newList[0].url
      }, () => {
        LS_SERVICE.set('goToSoapPatientUrl', newList);
        LS_SERVICE.set('goToSoapPatientPageList', pageList);
      });
    }
    return;
  }

  scheduleImmediateAppointment = async (hospital_id, patient_id, start_consult_id=null, patient) => {
    const { spin_loading } = this.state;
  
    let appointmentObj = {
      hospital_id : hospital_id,
      patient_id : patient_id
    }

    this.setState({
      start_consult_id : start_consult_id,
      spin_loading: !spin_loading
    });

    await Axios.post(SCHEDULE_IMMEDIATE_APPOINTEMENT_PATIENT, appointmentObj)
    .then(async success => {
      LS_SERVICE.set('slot_hospital_id', hospital_id);
      const speciality_id = LS_SERVICE.get('speciality_id');
      const staff_id = LS_SERVICE.get('staff_id');

      const soapType = await Axios.post(SOAP_TYPE_FETCH,{ 
        staff_id: staff_id && staff_id != null ? parseInt(staff_id) : null,
        speciality_id: speciality_id && speciality_id != null ? parseInt(speciality_id) : null,
        hospital_id: hospital_id && hospital_id != null ? parseInt(hospital_id) : null,
        source: 'doctor',
        setting_for: 'doctor',
      });
      if(soapType.data && soapType.data.selected_soap && soapType.data.selected_soap.type){
        LS_SERVICE.set('soap_type', soapType.data.selected_soap.type);
      }
      else{
        LS_SERVICE.set('soap_type', 'single');
      }

      var ss_configuration_ls = LS_SERVICE.get('ss_configuration');
      if(!ss_configuration_ls){
        const ss_configuration = await Axios.post(SINGLE_SCREEN_CONFIGURATION_FETCH,{ 
          staff_id: staff_id && staff_id != null ? parseInt(staff_id) : null,
          speciality_id: speciality_id && speciality_id != null ? parseInt(speciality_id) : null,
          hospital_id: hospital_id && hospital_id != null ? parseInt(hospital_id) : null,
          source: "doctor",
          setting_for: "doctor"
        });
        if(ss_configuration.data && ss_configuration.data.ss_configuration){
          LS_SERVICE.set('ss_configuration', ss_configuration.data.ss_configuration);
        }
      }

      let hospital = LS_SERVICE.get('slot_hospitals').filter( (hosp) => hosp.id == hospital_id);
      LS_SERVICE.set('slot_hospital_master', hospital?.[0]?.['own_master'] ?? 'N');
      headerService$.setHospital({ setHospital: hospital_id });
      await this.handleBasicOrSoap(hospital_id)
      this.initSoapList(hospital_id)

      const isTodayVisitArr = LS_SERVICE.has('isTodayVisit') ? LS_SERVICE.get('isTodayVisit') : [];

      if(success.data.status === true && success.data?.data){
        if(success.data.data?.booking_id !== undefined && success.data.data?.booking_id !== null && success.data?.data?.booking_id !== ""){
          LS_SERVICE.set('call_patient_detail', patient);
          LS_SERVICE.set('call_patient_id', patient_id);
          LS_SERVICE.set('call_patient_qms_token_id', success.data.data?.booking_id);

          var url;
          var config = LS_SERVICE.get('emr_server_config');
          var app_env = config?.app_env;
          var HOSPITAL_PORTAL_INDEPENDENT = config?.hospital_portal_independent || [];
          if((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All')))){
            url = SYNC_TOKEN_MHEA;
          }
          else{
            url = SYNC_TOKEN
          }
          await API_SERVICE.post(url, { staff_id: LS_SERVICE.get('staff_id'), hospital_id: String(LS_SERVICE.get('slot_hospital_id')) });

          const visit = await API_SERVICE.post(TODAYS_VISIT, {
            reference_id: success.data.data.booking_id,
            reference_type: LS_SERVICE.get('reference_type'),
            patient_id: patient_id,
            hospital_id: hospital_id
          });

          var appointmentCompletedTime = LS_SERVICE.get('appointmentCompletedTime') ?? {};
          appointmentCompletedTime[visit?.data?.detail?.qms_details?.qms_token_id] = visit?.data?.detail?.qms_details?.updatedAt;
          LS_SERVICE.set('appointmentCompletedTime', appointmentCompletedTime);

          var todaysVisitUrl;
          if(LS_SERVICE.get('soapConfig') == 'soap'){
            if(LS_SERVICE.get('soap_type') == 'soap'){
              todaysVisitUrl = [
                '/patient', 'todays-visit/subjective',
                hospital_id,
                patient_id,
                LS_SERVICE.get('reference_type'),
                success.data.data.booking_id
              ].join('/');
            } else {
              let soapurl = '/patient/soapss/HOSPITALID/PATID/REFTYPE/REFID';
              soapurl = soapurl.replace("HOSPITALID", hospital_id);
              soapurl = soapurl.replace("REFID", success.data.data.booking_id);
              soapurl = soapurl.replace("REFTYPE", LS_SERVICE.get('reference_type'));
              soapurl = soapurl.replace("PATID", patient_id);
              todaysVisitUrl = soapurl;
            }
          }
          else{
            todaysVisitUrl = [
              '/patient', 'todays-visit',
              hospital_id,
              patient_id,
              LS_SERVICE.get('reference_type'),
              success.data.data.booking_id
            ].join('/');
          }


          if (visit.status) {
            let patient = {
              hospital_id: hospital_id,
              patient_id: patient_id,
              reference_type: LS_SERVICE.get('reference_type'),
              reference_id: success.data.data.booking_id,
            }

            this.setState({
              spin_loading: false
            });
            
            this.props.history.push({
              pathname: todaysVisitUrl,
              state: { patient }
            });
            
            if (isTodayVisitArr.includes(JSON.stringify(success.data.data.booking_id))) {
              LS_SERVICE.set('isTodayVisit', isTodayVisitArr.filter(e => e != success.data.data.booking_id))
            }
          }

        }else{
          this.setState({
            spin_loading: false
          });

          notification.error({
            message:  success.data.data.message,
            placement: 'topRight'
          });
        }
      }else{
        this.setState({
          spin_loading: false
        });

        if (success?.data?.data?.message) {
          notification.error({
            message: success.data.data.message,
            placement: "topRight"
          })
        }
      }
      
    });
      
  }

  render() {
    
    return (
      <>
      <div className="container-fluid">
        <form className="emrfrm search-form" autoComplete="off" onSubmit={this.search}>
          <div className="row mt-3">
            {/* <div className="co-12 col-sm-3 my-2 px-3">
              <div className="form-group mb-0">
                <input onChange={this.handleInputChange} defaultValue={this.state.name} value={this.state.name} name="name" type="text" id="patient_name" className="form-control" placeholder="Patient Name" maxLength="100" autoComplete="off" />
                <label className="required" htmlFor="patient_name">Patient Name</label>
              </div>
            </div>

            <div className="col-12 col-sm-1 mb-0 my-2 pl-0 pr-0 text-center orText">
              <div className="pt-2">AND / OR</div>
            </div> */}

            {MYHC_APPTYPE === MHVCMARKETPLACE || MYHC_APPTYPE === MHEA ? <div className="col-3 col-sm-1 my-2">
              <div className="form-group mb-0 hasdata">
                <input onChange={this.handleInputChange} defaultValue={this.state.isdCode}  value={this.state.isdCode} name="isdCode" type="number" id="isdcode" className="form-control" placeholder="ISD Code" maxLength="4" autoComplete="off" />
                <label className="required" htmlFor="isdcode">ISD</label>
              </div>
            </div> : null}

            <div className="col-12 col-sm-2 my-2">
              <div className="form-group mb-0">
                <input onChange={this.handleInputChange} defaultValue={this.state.number} value={this.state.number} name="number" type="text" id="mobile" className="form-control" placeholder="Phone/UHID" maxLength="16" autoComplete="off" />
                <label className="required" htmlFor="mobile">{MYHC_APPTYPE === WOODLANDS ? <>Phone/UHID</> : <>Phone</>}</label>
              </div>
            </div>

            {MYHC_APPTYPE === MHVCMARKETPLACE || MYHC_APPTYPE === MHEA  ?
            <>
              <div className="col-12 col-sm-1 mb-0 my-2 pl-0 pr-0 text-center orText">
                <div className="pt-2">AND / OR</div>
              </div>
              <div className="col-12 col-sm-2 my-2">
                <div className="form-group mb-0">
                  <input onChange={this.handleInputChange} defaultValue={this.state.mhid} value={this.state.mhid} name="mhid" type="text" id="mhid" className="form-control" placeholder="UHID / MHID" maxLength="16" autoComplete="off" />
                  <label className="required" htmlFor="mhid">UHID</label>
                </div>
              </div>
            </>
            : null}

              {MYHC_APPTYPE === MHVCMARKETPLACE || MYHC_APPTYPE === MHEA ?
                <>
                  <div className="col-12 col-sm-1 mb-0 my-2 pl-0 pr-0 text-center orText">
                    <div className="pt-2">AND / OR</div>
                  </div>
                  <div className="col-12 col-sm-2 my-2">
                    <div className="form-group mb-0">
                      <input onChange={this.handleInputChange} defaultValue={this.state.name} value={this.state.name} name="name" type="text" id="patient_name" className="form-control" placeholder="PATIENT NAME" maxLength="100" autoComplete="off" />
                      <label className="required" htmlFor="patient_name">PATIENT NAME</label>
                    </div>
                  </div>
                </>
                : null}

            <div className="col-12 col-sm-2 my-2">
              <input type="hidden" value="1" id="role_id" autoComplete="off" />
              <button disabled={this.state.loading} type="submit" className="btn btn-primary px-5 btn-fit-height" id="search-submit">SEARCH</button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 px-3">
              <hr />
            </div>
          </div>
        </form>

        {/* Modal For hospitals */}
        <Hospitalsmodal
        scheduleImmediateAppointment ={this.scheduleImmediateAppointment}
        />

        <div className="my-3 search-results row px-2" id="search-results">
          {this.state.loading ? this.loader() : this.patientCards()}
        </div>

        {/* ############### Modal #################### */}
        <div className="modal" id="searchError" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 my-3 text-center">
                    <h6>Please enter patient's name, phone number or UHID.</h6>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <div className="btn btn-outline-primary px-5" data-dismiss="modal">
                  OK
            </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal" id="searchStatus" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 my-3 text-center">
                    <h6>Patient's name or phone number or UHID does not exist.</h6>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <div className="btn btn-outline-primary px-5" data-dismiss="modal">
                  OK
            </div>
              </div>
            </div>
          </div>
        </div>
        {/* ############### Modal End ################### */}
      </div>
      </>
    )
  }
}

export default SearchPatient;