import React, { Component } from 'react';
import Axios from 'axios';
import moment from "moment";
import { Modal, notification } from 'antd';

import AsideLeft from '../../components/aside/asideleft';
import LS_SERVICE from '../../utils/localStorage';

import {
  headerService$
} from '../../utils/rxjs-sharing';
import {
  GOBACK,
  LOADER_RED,
  MHC_PAST_PRESCRIPTIONS,
  COPY_FROM_MHC_PRESCRIPTION,
  ENABLE_MHC_PRESCRIPTION,
  EMR_APP_ENV
} from '../../utils/constant';

class MHCPastPrescriptions extends Component {

  state = {
    patient: null
  };

  async componentDidMount() {
    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_type, reference_id, patient_uhid } = params;

    await this.setState({
      hospital_id,
      patient_id,
      reference_type,
      reference_id,
      patient_uhid,
      prescriptionDetails: [],
      no_data_message_empty: '',
      data_loaded: false,
      rxPdfPath: null,
      showRxModal: false
    }, () => {
      if (LS_SERVICE.has('hospitalConfigData')) {
        const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
        let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
        headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' }); // IMPORTANT TO HIT AS PER THE VIEW
      }
    });

    await this.fetchMHCPastPrescriptions(patient_uhid, hospital_id)
  }

  fetchMHCPastPrescriptions = async (patient_uhid, hospital_id) => {
    const PARAMS = {
      patient_uhid: patient_uhid,
      hospital_id: hospital_id
    }

    await Axios.post(MHC_PAST_PRESCRIPTIONS, PARAMS)
    .then((response) => {
      if (response?.data?.data?.length > 0) {
        this.setState({
          prescriptionDetails: response?.data?.data,
          no_data_message_empty: '',
          data_loaded: true
        })
      }
      else{
        var past_pres_till;
        if(ENABLE_MHC_PRESCRIPTION?.[EMR_APP_ENV]?.includes(parseInt(hospital_id)) && EMR_APP_ENV == "mhea_paras"){
          past_pres_till = 'till 30 Sept 2023'
        }
        else if(ENABLE_MHC_PRESCRIPTION?.[EMR_APP_ENV]?.includes('All') && EMR_APP_ENV == "mhea_sakra"){
          past_pres_till = 'till 30 June 2024'
        }

        this.setState({
          prescriptionDetails: [],
          no_data_message_empty: 'No visit details for this patient '+past_pres_till,
          data_loaded: true
        })
      }
    })
    .catch((error) => {
      this.setState({
        prescriptionDetails: [],
        no_data_message_empty: 'Something went wrong! Unable to fetch prescriptions',
        data_loaded: true
      })
    });
  }

  copyThisPrescription = async (Obj) => {
    const { reference_type, reference_id, hospital_id, patient_id } = this.state;
    const PARAMS = {
      reference_type: reference_type,
      reference_id: reference_id,
      patient_id: patient_id,
      subjective: Obj?.subjective,
      subjective_custom: Obj?.subjective_custom,
      parentCustom: Obj?.parentCustom
    }
    

    await Axios.post(COPY_FROM_MHC_PRESCRIPTION, PARAMS)
    .then((response) => {
      if(LS_SERVICE.get('soap_type') == 'single'){
        this.props.history.push({
          pathname: `/patient/soapss/${hospital_id}/${patient_id}/${reference_type}/${reference_id}`
        });
      }
      else{
        this.props.history.push({
          pathname: `/patient/todays-visit/subjective/${hospital_id}/${patient_id}/${reference_type}/${reference_id}`
        });
      }
    })
    .catch((error) => {
      notification.error({
        message: 'Something went wrong! Unable to copy prescriptions',
        placement: 'topRight'
      });
    });
  }

  render() {
    const { patient_id, reference_type, reference_id, hospital_id, prescriptionDetails, data_loaded } = this.state;
    var past_pres_till
    if(ENABLE_MHC_PRESCRIPTION?.[EMR_APP_ENV]?.includes(parseInt(hospital_id)) && EMR_APP_ENV == "mhea_paras"){
      past_pres_till = 'Till 30 Sept 2023'
    }
    else if(ENABLE_MHC_PRESCRIPTION?.[EMR_APP_ENV]?.includes('All') && EMR_APP_ENV == "mhea_sakra"){
      past_pres_till = 'Till 30 June 2024'
    }
    return (
      <>
        {(patient_id != undefined) ? <AsideLeft patient_id={patient_id} reference_id={reference_id} /> : null}
        <div className="content-wrapper">
          <div className="container-fluid emrfrm">
            <div className="row">
              <div className="col-12 mb-3">
                <div className="float-left">
                  <h1 className="page-title mb-0">Prescriptions</h1>
                  <span>({past_pres_till})</span>
                </div>
                <div className="float-right">
                  <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={e => GOBACK(e, this.props)}>BACK</button>
                </div>
              </div>
            </div>
            {
              data_loaded == true
            ?
              <div className="row">
                <div className="col-lg-12" id="PastVisitContainerFluid">
                  <div className="accordion" id="accordionPastVisit">
                    {
                      (prescriptionDetails?.length > 0) 
                    ?
                      (prescriptionDetails.map((Obj, index) =>
                        <>
                          {
                            Obj.completion_method != 'Checked Out' && (Obj?.subjective?.length > 0 || (Obj?.subjective_custom && Object.keys(Obj?.subjective_custom)?.length > 0) || (Obj?.parentCustom && Object.keys(Obj?.parentCustom)?.length > 0))
                          ?
                            <div className="card border-0 mb-4" id={'pastvisitcard'} key={index}>
                              <div className="row">
                                <div className="col-md-6">
                                  <button
                                    role="button"
                                    data-toggle="collapse"
                                    data-target={'#collapse_' + index}
                                    aria-controls={'collapse_' + index}
                                    className={`btn text-left page-title btn-bg-accordion-red past-visit-container-button w-100 collapsed`}
                                  >
                                    <div className="float-left">
                                      <strong className="font-weight-normal">{Obj.doctor_name}</strong>
                                      <br />
                                      <small className="mt-1">{Obj?.appointment_date_time && Obj.appointment_date_time != null ? moment(Obj.appointment_date_time).format('D MMM, YYYY | hh:mm A') : null}</small>
                                    </div>
                                    <div className="float-right mt-2">
                                      <button className="btn btn-sm float-right btn-primary text-uppercase show-rx-modal">VIEW RX</button>
                                    </div>
                                  </button>
                                </div>
                                <div className="col-md-4 text-right">
                                  <button className="btn btn-outline-primary text-uppercase mt-3 small d-inline-flex align-items-center icon-hover-white" onClick={() => this.copyThisPrescription(Obj)}>
                                    <i className="icon-copy"></i>
                                    &nbsp;COPY FROM THIS PRESCRIPTION
                                  </button>
                                </div>
                                <div className="col-md-2 text-right">
                                  {
                                    Obj?.prescription_url && Obj?.prescription_url != null && Obj?.prescription_url != ''
                                  ?
                                    <button onClick={() => this.setState({ rxPdfPath: Obj.prescription_url, showRxModal: true })} className="btn btn-outline-primary text-uppercase mt-3 ml-3 small d-inline-flex align-items-center icon-hover-white">
                                      <i className={`icon-pdf-small`}></i>
                                      &nbsp;VIEW PRESCRIPTION
                                    </button>
                                  :
                                    null
                                  }
                                </div>
                              </div>

                              {/* For Card Details */}
                              <div id={'collapse_' + index} data-parent="#accordionPastVisit" className="collapse row">
                                <div className="col-lg-12 mt-3">
                                  <div className="row">
                                    {
                                      Obj?.subjective?.map((subjectiveData, index) =>
                                      <>
                                        {
                                          subjectiveData?.description && subjectiveData?.description != null && subjectiveData?.description != '' 
                                        ?
                                          <div className="col-12 mt-2">
                                            <div className="form-group normal subjective_info">
                                              <label className="control-label mb-0">{subjectiveData?.subjective_title}</label>
                                              <pre>{subjectiveData?.description}</pre>
                                            </div>
                                          </div>
                                        :
                                          null
                                        }
                                      </>
                                    )}
                                    {
                                      Obj?.subjective_custom
                                    ?
                                      Object.keys(Obj?.subjective_custom).map(key => 
                                        <div className="col-12 mt-2">
                                          <div className="form-group normal subjective_info">
                                            <label className="control-label mb-0">{Obj?.subjective_custom?.[key]?.subjective_title}</label>
                                            <pre>{Obj?.subjective_custom?.[key]?.description}</pre>
                                          </div>
                                        </div>
                                      )
                                    :
                                      null
                                    }
                                    {
                                      Obj?.parentCustom
                                    ?
                                      Object.keys(Obj?.parentCustom).map(key => 
                                        <div className="col-12 mt-2">
                                          <div className="form-group normal subjective_info">
                                            <label className="control-label mb-0">{Obj?.parentCustom?.[key]?.title}</label>
                                            <pre>{Obj?.parentCustom?.[key]?.description}</pre>
                                          </div>
                                        </div>
                                      )
                                    :
                                      null
                                    }
                                  </div>
                                </div>
                              </div>

                            </div>
                          :
                            null
                          }
                        </>
                      ))
                    :
                      <div className="card bg-light5">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12 text-center">{this.state.no_data_message_empty}</div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            :
              <div className="row">
                <div className="col-12 text-center mt-2">{LOADER_RED}</div>
              </div>
            }

            <Modal
              title="View Prescription"
              visible={this.state.showRxModal}
              footer={false}
              width={850}
              onCancel={() => { this.setState({ showRxModal: false, rxPdfPath: null }) }}
              style={{ top: 20 }}
            >
              <div className="row">
                <div className="col-12 my-3 text-center">
                  {this.state.rxPdfPath != null ? <iframe src={this.state.rxPdfPath + "#toolbar=0"} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                    {LOADER_RED}
                  </iframe> : null}
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </>
    );
  }
}

export default MHCPastPrescriptions;