import React from "react";
import constants from '../../config/constants';
import QbHelpers from '../../utils/QbHelpers';
import PropTypes from 'prop-types';
import axios from "axios";
import {
    HOMECARE_CALL_RECORD_RATING,
    CALL_RATING,
    USERTYPES
} from "../../utils/constant";
import { withRouter } from "react-router-dom";
import { callService, callDriverService } from '../../utils/rxjs-sharing';
import LS_SERVICE from "../../utils/localStorage";

var _ = require('lodash');

class QbVideoConferencing extends React.Component {

    state = {
        users: {
            current_page: 0,
            per_page: 100,
            total_entries: 0,
            items: []
        },
        audioMute: false,
        callPatientBtn: true,
        callNotificationMsg: '',
        callModalOpen: false,
        isListenersBinded: false,
        canEndCall: false,
        vcSetupDone: false,
        booking_id: null,
        showVideoFrame: false
    };

    static propTypes = {
        onChange: PropTypes.func
    };

    constructor(props) {
        super(props);
        // This should be in constructor only
        callDriverService.init({ driver: 'quickblox' });
        this.callUser = this.callUser.bind(this);
        this.endCall = this.endCall.bind(this);
        this.resetCallAudio = this.resetCallAudio.bind(this);
    }

    receiveInParent(data) {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange({
                callPatientBtn: data.callPatientBtn,
                callNotificationMsg: data.callNotificationMsg,
                canEndCall: data.canEndCall,
                hungupExistingCall: data.hungupExistingCall
            });
        }
    }

    resetCallAudio() {
        this.setState({
            audioMute: false
        });
        setTimeout(() => {
            // console.log('>> Salman, 1 this.state.vcSetupDone=', this.state.vcSetupDone)
            if (!this.state.vcSetupDone) {
                // console.log('>> Salman, 2 this.state.vcSetupDone=', this.state.vcSetupDone)
                this.makeDoctorLogin();
            }
        }, 1000);
    }

    componentDidMount() {
        this.setState({
            booking_id: this.props.booking_id
        });
        // Load QuickBlox scripts
        this.loadQBScripts();

        this.subscription = callService.status().subscribe(result => {
            if (result && result.start && result.video_call_driver != 3) {
                LS_SERVICE.set('is_homecarecall', result.is_homecarecall);
                this.callUser();
            }
            if (result && result.end && result.video_call_driver != 3) {
                this.endCall();
            }
            if (result && result.message && result.video_call_driver != 3) {
                this.updateVideoCallMsg({
                    message: result.message
                });
            }
        });
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    // setDoctorQbId() {
    //     const doctorInfo = localStorage.getItem('doctor');
    //     if (doctorInfo === null) {
    //         console.log('>> doctor', doctorInfo)
    //         return;
    //     }
    //     const doctor = JSON.parse(doctorInfo);
    //     console.log('>> doctor', doctor.code)
    //     const docQBInfo = QbHelpers.getUserInfo(doctor.code);
    //     console.log('>> doctor docQBInfo', docQBInfo);
    // }

    updateVideoCallMsg(data) {
        // console.log('>> Salman 2');
        let hungupExistingCall = false;
        if (data.tag && data.tag === 'already_in_call_hungup') {
            hungupExistingCall = true;
        }
        // if (data.tag && data.tag === 'call_disconnected_by_patient') {
        //     this.setState({ showVideoFrame: false });
        // }
        this.setState({
            callPatientBtn: data.btnStatus,
            callNotificationMsg: data.message,
            canEndCall: data.canEndCall,
        });
        // console.log('>> Salman 3');
        this.receiveInParent({
            callPatientBtn: data.btnStatus,
            callNotificationMsg: data.message,
            canEndCall: data.canEndCall,
            hungupExistingCall: hungupExistingCall
        });
        // console.log('>> Salman 4');
    }

    /**
     * Make doctor login
     */
    // async makeDoctorLogin() {
    //     if (window.InncVonage.data.call_started) {
    //         window.InncVonage.rebindUiComponents();
    //         return;
    //     }
    //     this.updateVideoCallMsg({
    //         btnStatus: false
    //     });
    //     return false;
    // }

    /**
     * Make doctor login
     */
    async makeDoctorLogin() {
        /**
         * Booking status is 5
         * So call cannot be initiated
        */
        // if (this.props.location.state.booking_status_code === 5) {
        //     this.updateVideoCallMsg({
        //         btnStatus: true,
        //         message: 'Appointment is already completed, cannot initiate call.',
        //         canEndCall: false
        //     });
        //     return;
        // }

        /**
         * Check if already a call is going on
         * Bind all video and audio elements for doctor again
         * Show control panel if video call is going on
         * Also check if audio was muted or not and do the same again
         */
        const connections = QbHelpers.getPeerConnections();
        // console.log('>> Salman connections', connections, QbHelpers.patientInfo, this.props.patient);
        if (typeof connections !== 'undefined'
            && QbHelpers.isCallActive === true
            && !_.isEmpty(QbHelpers.patientInfo)
            && QbHelpers.patientInfo.number === this.props.patient.number) {

            // document.getElementById('localVideoModal').muted = true;
            // document.getElementById('localVideoFloating').muted = true;
            // document.getElementById('localVideoModal').setAttribute('muted', true);
            // document.getElementById('localVideoFloating').setAttribute('muted', true);
            // document.getElementById('localVideo').setAttribute('muted', false);
            this.setState({ showVideoFrame: true });

            if (document.getElementById('completed_consult_btn') !== null) {
                document.getElementById('completed_consult_btn').setAttribute("disabled", "disabled");
            }
            QbHelpers.alterVcStateAgain(this);
            QbHelpers.unbindFloatingMedia();
            await QbHelpers.bindPatientVc();
            QbHelpers.bindLocalMedia();
            document.getElementById('js-call-controls').classList.remove("hidden");
            this.setState({
                audioMute: QbHelpers.audioMuted
            });
            QbHelpers.changeMuteStatus(QbHelpers.audioMuted);
            QbHelpers.isCallActive = true;
            this.updateVideoCallMsg({
                btnStatus: true,
                message: 'In call with Patient.',
                canEndCall: true
            });
            return;
        }

        /**
         * Operations to perform if ongoing call patient doesn't match current patient
         */
        // if (typeof connections !== 'undefined'
        //     && !_.isEmpty(QbHelpers.patientInfo)
        //     && QbHelpers.patientInfo.number !== this.props.patient.number) {
        //     document.getElementById('completed_consult_btn').setAttribute("disabled", "disabled");
        // }

        /**
         * Initiate video call setup
         */
        // console.log('>>> Salman it works!');
        this.updateVideoCallMsg({
            btnStatus: true,
            message: "Initiating VC setup.",
            canEndCall: false
        });
        const qbsession = await QbHelpers.qbSessionCreate();
        // console.log('>> qbsession', qbsession);
        if (!qbsession.status || !qbsession.session) {
            this.updateVideoCallMsg({
                btnStatus: true,
                message: "Not able to start VC setup, try refreshing page.",
                canEndCall: false
            });
            return;
        }

        const doctorInfo = localStorage.getItem('doctor');
        if (doctorInfo === null) {
            this.updateVideoCallMsg({
                btnStatus: true,
                message: "Not able to start VC setup.",
                canEndCall: false
            });
            return;
        }
        const doctor = JSON.parse(doctorInfo);
        // console.log('>> doctor', doctor);
        const credentials = {
            username: doctor.name.trim(),
            login: doctor.code,
            password: constants.quickbloxPwd
        };

        this.updateVideoCallMsg({
            btnStatus: true,
            message: "Initiating video call setup.",
            canEndCall: false
        });
        const response = await QbHelpers.login(this, credentials);
        if (response.status) {
            this.updateVideoCallMsg({
                btnStatus: false,
                message: "Video call setup initiated, try calling patient.",
                canEndCall: false
            });
        } else {
            this.updateVideoCallMsg({
                btnStatus: true,
                message: response.error || 'Something went wrong, try refreshing page.',
                canEndCall: false
            });
            return;
        }

        const ccresponse = await QbHelpers.chatConnection(response, constants);
        if (ccresponse.status) {
            this.updateVideoCallMsg({
                btnStatus: false,
                message: "Video call setup completed, try calling patient.",
                canEndCall: false
            });
        } else {
            this.updateVideoCallMsg({
                btnStatus: true,
                message: ccresponse.error || 'Something went wrong, try refreshing page.',
                canEndCall: false
            });
            return;
        }

        // Flag to show if VC setup has been done
        this.setState({
            vcSetupDone: true
        });
        // var $this=this;
        // setTimeout(function(){
        //     console.log('>> Salman, 5 this.state.vcSetupDone=', $this.state.vcSetupDone)
        // }, 500);

        // Bind all video call listeners
        // QbHelpers.bindCallListeners();
    }

    /**
     * Load QuickBlox assets
     */
    loadQBScripts() {
        // constants.videoCallScripts.map(item => {
        //     const script = document.createElement("script");
        //     script.src = item.src;
        //     script.tag = item.tag;
        //     script.async = true;
        //     document.getElementById('scripts').appendChild(script);
        //     script.onload = () => {
        //         switch (script.tag) {
        //             case 'quickblox':
        //                 // Make doctor login via quickblox account
        //                 this.makeDoctorLogin();
        //                 break;
        //             default:
        //                 break;
        //         }
        //     }
        //     return true;
        // });
        this.makeDoctorLogin();
    }

    /**
     * Start Vonage Video Call
     * @param {*} item 
     */
    // async callUser() {
    //     if (window.InncVonage.data.call_started) {
    //         return;
    //     }
    //     this.updateVideoCallMsg({
    //         btnStatus: true
    //     });
    //     window.InncVonage.start();
    // }

    /**
     * Start webrtc session and initiate call
     * @param {*} item 
     */
    async callUser(item) {
        if (this.props.patient.number === null) {
            return;
        }
        if (!window.navigator.onLine) {
            this.updateVideoCallMsg({
                btnStatus: false,
                message: "Internet connection not available.",
                canEndCall: false
            });
            return;
        }
        if (QbHelpers.isCallActive) {
            this.updateVideoCallMsg({
                btnStatus: false,
                message: "You're already in call with other patient, hungup to start new call.",
                canEndCall: false,
                tag: 'already_in_call_hungup'
            });
            return;
        }

        const calleesIds = [];

        // Check patient login credentials
        this.updateVideoCallMsg({
            btnStatus: true,
            message: "Checking patient availability.",
            canEndCall: false
        });
        let mobile_no = this.props.patient.number || this.props.patient.mobile_no;
        const patient = await QbHelpers.getUserInfo(mobile_no, constants);
        if (patient.status && patient.data.id) {
            calleesIds.push(patient.data.id);
            QbHelpers.patientInfo = this.props.patient;
            // const calleesIds = ["106159845"];
        } else {
            this.updateVideoCallMsg({
                btnStatus: false,
                message: "The patient you are calling has not downloaded or registered via the app",
                canEndCall: false
            });
            return;
        }

        this.setState({ showVideoFrame: true });

        // Bind all video call listeners
        if (!this.state.isListenersBinded) {
            this.setState({
                isListenersBinded: true
            });
            QbHelpers.bindCallListeners();
        }

        // Start webrtc session
        let webrtcSession;
        // if (!QbHelpers.QbWebrtcSession.ID) {
        this.updateVideoCallMsg({
            btnStatus: true,
            message: "Starting video call session.",
            canEndCall: false
        });
        webrtcSession = await QbHelpers.startWebrtcSession(calleesIds);
        if (!webrtcSession.ID) {
            this.updateVideoCallMsg({
                btnStatus: false,
                message: "Not able to start webrtc session.",
                canEndCall: false
            });
            return;
        }
        // } else {
        //     webrtcSession = QbHelpers.QbWebrtcSession;
        // }

        // Permission for webcam and mic
        this.updateVideoCallMsg({
            btnStatus: true,
            message: "Please allow media(Webcam and Microphone) permissions.",
            canEndCall: false
        });
        const permissions = await QbHelpers.deviceMediaStream(webrtcSession);
        if (!permissions.status) {
            this.updateVideoCallMsg({
                btnStatus: false,
                message: permissions.message,
                canEndCall: false
            });
            return;
        }

        // Start call
        this.updateVideoCallMsg({
            btnStatus: true,
            message: "Setting up call and sending notification to patient for joining call.",
            canEndCall: false
        });
        const callResponse = await QbHelpers.webrtcCall(webrtcSession, constants);
        this.updateVideoCallMsg({
            btnStatus: true,
            message: "Calling...",
            canEndCall: true
        });

        this.callLog({
            source: "web",
            booking_id: this.state.booking_id,
            state: 0
        })
    }

    /**
     * End call with patient
     */
    endCall() {
        if (_.isEmpty(QbHelpers.QbWebrtcSession)) {
            return;
        }
        this.setState({ showVideoFrame: false });
        QbHelpers.endCall();
        setTimeout(() => {
            // this.setState({
            //     callPatientBtn: false,
            //     callNotificationMsg: 'Call ended with Patient.',
            //     canEndCall: false
            // });
            this.receiveInParent({
                callPatientBtn: false,
                callNotificationMsg: 'Call ended with Patient.',
                canEndCall: false
            });
            this.resetCallAudio();
        }, 1000);

        this.callLog({
            source: "web",
            booking_id: this.state.booking_id,
            state: 1
        })

        LS_SERVICE.delete('is_homecarecall');
    }

    /**
     * Mute or unmute audio during call
     */
    muteOrUnmute() {
        if (_.isEmpty(QbHelpers.QbWebrtcSession)) {
            return;
        }
        const newMuteState = QbHelpers.muteOrUnmute(this.state.audioMute);
        this.setState({
            audioMute: newMuteState
        });
    }

    expandVideoScreen() {
        if (_.isEmpty(QbHelpers.QbWebrtcSession)) {
            return;
        }
        this.setState({
            callModalOpen: this.state.callModalOpen ? false : true
        }, () => {
            if (this.state.callModalOpen) {
                document.getElementById('localVideoModal').setAttribute('muted', false);
                document.getElementById('localVideoFloating').setAttribute('muted', true);
                document.getElementById('localVideo').setAttribute('muted', true);
            } else {
                document.getElementById('localVideoModal').setAttribute('muted', true);
                document.getElementById('localVideoFloating').setAttribute('muted', true);
                document.getElementById('localVideo').setAttribute('muted', false);
            }
        });
    }

    hideModalCall() {
        this.setState({
            callModalOpen: false
        });
    }

    createCallLog(data) {
        // :Previous - API_BASE_URL3
        axios.post(CALL_RATING, data)
            .then(function (response) {
            })
            .catch(function (error) {
            });
    }

    callLog = (data) => {
        if (data.booking_id == null) {
            return;
        }

        const is_homecarecall = LS_SERVICE.get('is_homecarecall');
        // const is_doctor = LS_SERVICE.get('is_doctor');
        // const is_doctor = LS_SERVICE.get('user_type') == USERTYPES.doctor ? true : false;

        axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");

        // :Fix
        // axios.post(CALL_RATING, {
        //     booking_id: data.booking_id,
        //     state: data.state // 2 for feedback Submit 1 for Call end and 0 for call start
        // }).then((Response) => { }).catch((error) => { });

        axios.post(is_homecarecall ? HOMECARE_CALL_RECORD_RATING : CALL_RATING, {
            source: "web",
            booking_id: data.booking_id,
            state: data.state // 2 for feedback Submit 1 for Call end and 0 for call start
        })
        // .then((Response) => { })
        // .catch((error) => { });
    }

    // componentDidUpdate() {
    //     this.setState({
    //         patient: this.props.patient
    //     });
    //     console.log('>>> props componentDidUpdate', this.state.patient);
    // }

    /**
     * Component response view 
     */
    render() {
        // console.log('>> Salman this.props.location', this.props.location.state.booking_status_code);
        // console.log('>> Salman, 3 this.state.vcSetupDone=', this.state.vcSetupDone)
        const audioState = this.state.audioMute ? 'Unmute' : 'Mute';
        const audioClass = this.state.audioMute ? 'active' : '';
        const muteImg = this.state.audioMute ? 'mic-muted.png' : 'mic.png';
        const callModalClass = this.state.callModalOpen ? '' : 'hidden';
        return (
            <div className={"col-12 doctor-video " + (this.state.showVideoFrame ? '' : 'hidden')}>

                <div className="video-call-widget">

                    {/* Video Interface for puclisher and subscribers */}
                    <div className='position-relative full-wh-elem'>

                        {/* Recording icon */}
                        {/* <span id="recording-icon-1" className="video-recording hidden" title="Chat video is being recorded">
                            <i className="red-icon"></i>
                            REC
                        </span> */}

                        {/* Video Screen(Subscriber/Patient) will be displayed here */}
                        <video title="Patient video screen" id="main_video" className="full-wh-elem" autoPlay playsInline></video>
                        {/* <div id="main_video"></div> */}

                        {/* Video Screen(Publisher/Doctor) will be displayed here */}
                        <div className="publisher-elem position-absolute" title="Doctor video screen" id="localVideoElem">
                            <video className="full-wh-elem" id="localVideo" autoPlay playsInline></video>
                        </div>

                        {/* Call Controls */}
                        <div className="call-controls hidden" id="js-call-controls">

                            {/* For Mute / Unmute */}
                            <button onClick={() => this.muteOrUnmute()} type="button" className={audioClass + " control-btn"} title={audioState + " Microphone"}>
                                <img src={"/assets/images/" + muteImg} alt="mic-icon" />
                            </button>

                            {/* For Ending call */}
                            <button onClick={() => this.endCall()} type="button" className="control-btn" title="End Call">
                                <img src="/assets/images/endcall.png" alt="mic-icon" />
                            </button>

                            {/* For Exapanding video screen */}
                            <button onClick={() => this.expandVideoScreen()} type="button" className="control-btn float-right" title="Expand screen">
                                <img src="/assets/images/expand.png" alt="mic-icon" />
                            </button>
                            <div className={callModalClass + " modal-main-container"} id="call-modal">
                                <div className="modal-container">
                                    {/* Video Screen(Subscriber/Patient) will be displayed here */}
                                    <video title="Patient video screen" id="main_video_modal" className="main_video_modal" autoPlay playsInline></video>
                                    {/* Video Screen(Publisher/Doctor) will be displayed here */}
                                    <div className="publisher-elem-modal position-absolute" title="Doctor video screen" id="localVideoModalElem">
                                        <video className="full-wh-elem" id="localVideoModal" autoPlay playsInline></video>
                                    </div>
                                    <div className="modal-call-controls">
                                        {/* For Mute / Unmute */}
                                        <button onClick={() => this.muteOrUnmute()} type="button" className={audioClass + " control-btn"} title={audioState + " Microphone"}>
                                            <img src={"/assets/images/" + muteImg} alt="mic-icon" />
                                        </button>
                                        {/* For Ending call */}
                                        <button onClick={() => this.endCall()} type="button" className="control-btn" title="End Call">
                                            <img src="/assets/images/endcall.png" alt="icon" />
                                        </button>
                                        {/* For Closing modal */}
                                        <button onClick={() => this.expandVideoScreen()} type="button" className="control-btn" title="Return to small screen">
                                            <img src="/assets/images/miniplayer.png" alt="icon" />
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                {/* QuickBlox Scripts */}
                <div id="scripts"></div>

                {/* <video id="BlobVideo" controls></video> */}

            </div>
        )
    }
}

export default withRouter(QbVideoConferencing);