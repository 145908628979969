import React, { Component } from 'react';
import qs from 'qs';
import Axios from 'axios';
import Asideleft from '../../components/aside/asideleft';
import LS_SERVICE from '../../utils/localStorage';
import {
    INSTRUCTION_DELETE,
    INSTRUCTION_SEARCH,
    INSTRUCTION_STORE,
    ISALLOWEDITSOAP,
    LOADER_RED,
    LOADER,
    PATIENT_DATA,
    SOAP_CONFIGURATION_LIST,
    TOGGLE_PRACTICE
} from '../../utils/constant';
import { notification, Modal, Upload, Button } from 'antd';
import SubHeader from '../../components/subheader/subheader';
import API_SERVICE from '../../utils/apiService';
import { jQueryLabels, AntSelectLabels } from '../../utils/jQueryLabels';
import icsearch from '../../assets/images/ic-search.svg'
import { headerService$, practiceService } from '../../utils/rxjs-sharing';
import SupportingMaterial from '../../components/supporting-material/supporting-material-form';

class PlanInstruction extends Component {

    constructor(props) {
        super(props)

        // if (props.location.state === undefined) {
        //     props.history.push("/dashboard");
        //     return;
        // }

        // const { patient } = props.history.location.state;
        this.state = {
            patient: null,
            instructionList: [],
            instructionListAdded: [],
            instructionsLoaded: false,
            dummy: false,
            search_instruction: '',
            isPracticing: false,
            isPracticingModal: false,
            allowEdit: true,
            allowEdit: true,
            materialList: [],
            reference_id: props
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.deleteButton = React.createRef();
        this.getMaterialData = this.getMaterialData.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { match: { params } } = nextProps;
        const { hospital_id, reference_id } = params;

        Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
        return {
            reference_id,
            hospital_id
        }
    }

    componentDidMount() {

        const { match: { params } } = this.props;
        const { hospital_id, patient_id, reference_id } = params;
        const isPracticingHospitalId = LS_SERVICE.get('practicing')?.hospital_id;

        if (+isPracticingHospitalId === +hospital_id) {
            this.setState({
                isPracticing: true
            })
        }

        const patient = {
            hospital_id,
            patient_id,
            qms_token_id: reference_id
        }
        let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
        hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);

        let allowEdit = hospitalConfigData ? ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'button') : true;

        this.setState({
            hospital_id,
            patient_id,
            reference_id,
            patient,
            allowEdit
        }, () => {
            if (LS_SERVICE.has('hospitalConfigData')) {
                const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
                let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
                headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' }); // IMPORTANT TO HIT AS PER THE VIEW
                this.loadInstructionData();
                this.handleSoapFlow();
                jQueryLabels();
            }
        });

        // if (this.props.location.state !== undefined) {
        //     this.setState({
        //         patient: this.props?.location?.state?.patient
        //     }, () => {
        //         this.loadInstructionData();
        //         // this.handleInstructionSearch('');
        //         jQueryLabels();
        //     })
        // } else {
        //     this.getPatientData()
        //     jQueryLabels();
        // }
    }

    // getPatientData = _ => {
    //     const { match: { params } } = this.props;
    //     const { reference_id } = params;
    //     var reference_type = await LS_SERVICE.get('reference_type');
    //     Axios.get(PATIENT_DATA({ reference_id, reference_type }))
    //         .then(success => {
    //             const patient = {
    //                 ...success.data.data.results,
    //                 qms_token_id: success.data.data.results.qmsToken.qms_token_id
    //             }
    //             this.setState({
    //                 patient
    //             }, () => {
    //                 this.loadInstructionData();
    //                 // this.handleInstructionSearch('');
    //             })
    //         })
    //         .catch(err => console.log(err))
    // }

    handleSoapFlow = _ => {
        if (!LS_SERVICE.has('goToSoapPatientPageList') || LS_SERVICE.get('goToSoapPatientPageList').length === 0) {
            this.handleSoapInit()
        }
    }

    handleSoapInit = async _ => {

        const { hospital_id } = this.state.patient;

        const soapList = await API_SERVICE.post(SOAP_CONFIGURATION_LIST, {
            hospital_id,
            staff_id: LS_SERVICE.get('staff_id'),
            speciality_id: LS_SERVICE.get('speciality_id') || null
        });

        const pageList = [];
        const newList = [];

        const newSoapList = Object.values(soapList).map((data) => {
            return data.sort((a, b) => a.display_ordering - b.display_ordering)
        });

        Object.values(newSoapList).flat().forEach((en) => {
            if (en.display === 'Y') {
                if (!pageList.includes((en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name)) {
                    pageList.push((en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name);
                    newList.push({
                        'url': en.alternate_url_format,
                        'parent_module': en.parent_module,
                        'module': (en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name
                    })
                }
            }
        });

        LS_SERVICE.set('goToSoapPatientUrl', newList);
        LS_SERVICE.set('goToSoapPatientPageList', pageList);
    }

    loadInstructionData = () => {
        const { patient } = this.state;
        const { qms_token_id } = patient;
        const doctor_id = LS_SERVICE.get('staff_id');
        const REFERENCE_TYPE = LS_SERVICE.get('reference_type');
        Axios.get(`${INSTRUCTION_SEARCH}?reference_type=${REFERENCE_TYPE}&reference_id=${qms_token_id}&doctor_id=${doctor_id}`
        ).then(success => {
            this.setState({
                instructionList: success.data.data.details,
                instructionListAdded: success.data.data.details,
                instructionsLoaded: true,
            });


        }).catch(err => console.log(err))
    }

    handleInstructionSearch = (search, add = false) => {
        let { instructionListAdded } = this.state;
        // let matches = instructionListAdded.filter(v => v.instruction.toLowerCase().includes(search));
        let matches = instructionListAdded.filter(str => str.instruction.toLowerCase().includes(search.toLowerCase()));

        if (add) {
            instructionListAdded = [...instructionListAdded, {
                id: -1,
                instruction: this.state.search_instruction,
                selected: true,
                can_delete: true
            }]
        }

        this.setState({
            instructionList: search !== '' ? matches : instructionListAdded,
            instructionListAdded,
            search_instruction: add ? '' : this.state.search_instruction
        }, () => {
            if (add) {
                document.getElementById('search-instruction').focus();
                AntSelectLabels();
            }
        })
    }

    handleOnChange = (event) => {
        const { name, value } = event.currentTarget;
        this.setState({
            [name]: value,
        }, () => {
            this.handleInstructionSearch(this.state.search_instruction)
        })
        AntSelectLabels();
    }

    handleAddClick = e => {
        const { isPracticing } = this.state;

        if (!isPracticing) {
            this.handleModalPopup(null, 'isPracticingModal');
            return;
        }

        this.instruction_textarea('instruction_textarea', true);
        this.handleInstructionSearch('', true);
    }

    getMaterialData = (materialData) => {
        this.setState({ materialList: materialData });
    }

    handleInstructionContinue = async _ => {

        const { patient, instructionList, instructionListAdded, materialList, isPracticing } = this.state;
        const { qms_token_id, hospital_id, patient_id } = patient;

        if (!isPracticing) {
            this.handleModalPopup(null, 'isPracticingModal');
            return;
        }

        const obj = instructionListAdded.filter(inst => inst.selected).map(filtered => ({ id: filtered.id, instruction: filtered.instruction }))

        const materialObj = materialList.filter(file => file.selected).map(filtered => ({ id: filtered.id, file_name: filtered.fileName, file_url: filtered.fileUrl }));
        const REFERENCE_TYPE = LS_SERVICE.get('reference_type');
        const PARAMS = {
            reference_type: REFERENCE_TYPE,
            reference_id: qms_token_id,
            instruction: obj,
            supporting_materials: materialObj
        }

        let instructionPromise;
        if (this.state.allowEdit)
            instructionPromise = await Axios.post(INSTRUCTION_STORE, qs.stringify(PARAMS));

        if (!this.state.allowEdit || (instructionPromise && instructionPromise.data.status === "success")) {
            const isTodayVisitArr = LS_SERVICE.has('isTodayVisit') ? LS_SERVICE.get('isTodayVisit') : [];
            const pageListArr = LS_SERVICE.get('goToSoapPatientPageList');
            let index = pageListArr.indexOf('instructions');
            index++;
            if (index < pageListArr.length && !isTodayVisitArr.includes(qms_token_id)) {
                let soapurl = LS_SERVICE.get('goToSoapPatientUrl')[index].url;
                soapurl = soapurl.replace("HOSPITALID", hospital_id);
                soapurl = soapurl.replace("REFID", qms_token_id);
                soapurl = soapurl.replace("REFTYPE", REFERENCE_TYPE);
                soapurl = soapurl.replace("PATID", patient_id);
                this.props.history.push({
                    pathname: soapurl,
                    state: { patient }
                })
            } else {
                this.props.history.push({
                    pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
                    state: { patient }
                })
            }
        }
    }

    handleInstructionDelete = async (e, data, index) => {

        e.preventDefault();

        const { qms_token_id } = this.state.patient;

        const PARAMS = {
            reference_type: LS_SERVICE.get('reference_type'),
            reference_id: qms_token_id,
            instruction_id: data.id
        }
        const instructionDelPromise = await Axios.get(INSTRUCTION_DELETE(PARAMS))

        if (instructionDelPromise.data) {
            this.handleInstructionSearch('');
            notification.success({
                message: instructionDelPromise.data.message,
                placement: 'topRight'
            })
            // console.log(this.state.instructionList.splice(index, 1))

            let removedInstructionList = this.state.instructionList.filter(function (ele, i) {
                return i !== index;
            });

            this.setState({
                instructionList: removedInstructionList,
                instructionListAdded: removedInstructionList,
            })
        }
    }

    handleInstructionClick = (e, index) => {
        // e.preventDefault();
        const { checked } = e.currentTarget;
        const { instructionList } = this.state;

        instructionList[index].selected = checked;

        this.setState(instructionList);
    }

    enablePractice = async e => {
        e.preventDefault();
        practiceService.init({ initiater: 'SOAP Pages', status: 'start' });
        const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
            hospital_id: this.state.hospital_id,
            practice_session: 'start'
        });

        let is_practicing;
        if (practice.data && practice.data.is_practicing === 'Y') {
            is_practicing = true;
            LS_SERVICE.set('practicing', {
              status: true,
              hospital_id: this.state.hospital_id
            });
        } else {
            LS_SERVICE.delete('practicing');
            is_practicing = false;
        }
        this.setState({
            isPracticing: is_practicing,
        }, () => {
            this.handleModalPopup(null, 'isPracticingModal');
        });
    }

    handleModalPopup = (e, popup) => {

        if (e !== null) e.preventDefault();

        this.setState({
            [popup]: !this.state[popup]
        })
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    autoHeight = (e) => {
        if (e)
            e.target.style.height = e.target.scrollHeight + "px";
    }

    instruction_textarea = (textarea, clear = false) => {
        let Elem = document.querySelectorAll('.' + textarea);
        if (!clear) {
            Elem.forEach((el) => { el.style.height = el.scrollHeight + "px"; });
        } else {
            Elem.forEach((el) => { el.style.height = "40px"; });
        }
    }

    render() {
        const { patient } = this.state;
        return (
            <>
                {/* Page sidebar */}
                {(patient != undefined && patient.patient_id != undefined) ? <Asideleft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}

                {/* Page content */}
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row" id="data-preloader" style={{ display: 'none' }}>
                            <div className="col-12">
                                <div className="text-center">
                                    <div className="spinner red"><div className="bounce1" /><div className="bounce2" /><div className="bounce3" /></div>
                                </div>
                            </div>
                        </div>
                        <div className=" emrfrm row" id="main-page-container">
                            {/* SOAP Bar */}

                            {patient != undefined && patient.patient_id != undefined && patient.patient_id != null ?
                                <SubHeader patient={patient} />
                                : null}

                            <div className="col-12">
                                <form className="template-form" autoComplete="off" onSubmit={this.handleSubmit} encType="multipart/form-data">
                                    <div className="row">
                                        <h1 className="col-12 page-title float-left">Instructions</h1>
                                        <div className="col-12 col-md-6 mt-3 mb-4">
                                            <div className={`form-group adv-search ${this.state.instructionsLoaded && this.state.instructionList.length === 0 ? 'hasdata notfound' : ''}`}>
                                                <textarea style={{ minHeight: "calc(1.75em + 0.75rem + 2px)" }} onInput={(e) => this.autoHeight(e)} id="search-instruction" name="search_instruction" className="form-control instruction_textarea" placeholder="Search"
                                                    autoComplete="off" autoFocus value={this.state.search_instruction} onChange={this.handleOnChange} />
                                                <label htmlFor="search-instruction">Search</label>
                                                {(this.state.search_instruction.length > 0) ?
                                                    <div className="btn btn-outline-primary add_instruction" onClick={this.handleAddClick}>ADD</div>
                                                    : <button type="button" className="btn find_instruction"><img src={icsearch} alt="Search" className="icon" /></button>}
                                            </div>
                                        </div>
                                        <div className="col-12 instruction-content">
                                            <div className="added_instructions">
                                                <div className="row" />
                                            </div>
                                            <div className="all_instructions">
                                                <div className="row instructions_rows">
                                                    {this.state.instructionsLoaded ? (
                                                        this.state.instructionList.length > 0 ? (
                                                            this.state.instructionList.map((inst, i) => {
                                                                return (
                                                                    <div className="row instructions_list2 col-md-6 d-flex align-items-center" key={i}>

                                                                        <div className="custom-control custom-checkbox ml-3">
                                                                            <input type="checkbox" className="custom-control-input" onChange={e => this.handleInstructionClick(e, i)} disabled={!this.state.allowEdit}
                                                                                id={`visit_instructions_${inst.id}`} name="instructions" checked={inst.selected} />
                                                                            <label className="custom-control-label" htmlFor={`visit_instructions_${inst.id}`}>&nbsp;</label>
                                                                        </div>

                                                                        <div className="instructions_text">
                                                                            <p>{inst.instruction}</p>
                                                                        </div>
                                                                        {this.state.allowEdit ?
                                                                            <div className="col action text-right pt-1">
                                                                                <a href="!#" className="nostyle-link remove-list remove-instruction"
                                                                                    onClick={e => this.handleInstructionDelete(e, inst, i)}>
                                                                                    <i className="icon_delete"></i>
                                                                                </a>
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                )
                                                            })
                                                        ) : null
                                                    ) : null}

                                                    <div className={`w-100 align-items-center justify-content-center mt-4 ${!this.state.instructionsLoaded ? 'd-flex' : 'd-none'}`}>
                                                        {LOADER_RED}
                                                    </div>
                                                </div>

                                                {/* <SupportingMaterial
                                                    formType='all'
                                                    referenceId = {this.state?.reference_id}
                                                    hospitalId={this.state.hospital_id}
                                                    setMaterialData={(data) => this.getMaterialData(data)}
                                                    allSupportingMaterial={this.state.materialList}
                                                /> */}

                                                <div className="col-12 mt-5">&nbsp;</div>
                                                <div className="fixed-cta">
                                                    <div className="btn btn-primary px-4 text-uppercase confirm-instructions"
                                                        onClick={this.handleInstructionContinue}>CONTINUE</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* For Modal Delete Confirm  */}
                <Modal
                    title={false}
                    visible={this.state.deleteConfirmModal}
                    onCancel={(e) => this.handleDeleteModalPopup(e, "deleteConfirmModal")}
                    footer={false}
                    closable={false}
                >
                    <div className="row mb-3">
                        <h6 className="col-12 text-center">{this.state.deleteMessage}</h6>
                    </div>
                    <div className="modal-footer justify-content-center emrfrm pb-0">
                        <button type="button" className="btn btn-outline-secondary px-5  mr-2" onClick={(e) => this.handleDeleteModalPopup(e, "deleteConfirmModal")}>
                            CANCEL
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary px-5"
                            ref={this.deleteButton}
                            onClick={(e) => this.handleOnItemDelete(e, this.state.deleteActionType, this.state.deleteObjId)}
                        >
                            DELETE
                        </button>
                    </div>
                </Modal>
            </>
        )
    }
}

export default PlanInstruction;