import React from 'react';
import { Line } from "react-chartjs-2";
import { DURATION_TYPE_SINGULAR, HOMECARE_REFERENCE_TYPE, LOADER_RED, PDF_CONFIG_VITALS_GRAPH_UPDATE, VITAL_ARRAY } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import "react-datepicker/dist/react-datepicker.css";

import Axios from 'axios';
import qs from 'qs';
import moment from "moment";
import { notification } from 'antd';

class ChartPastVisit extends React.Component {

  constructor(props) {
    super(props);

    const PDF_PRINT_OBJ = {};
    if (props.chartGraphSetting.length > 0) {
      props.chartGraphSetting.split(',').forEach(ppa => {
        PDF_PRINT_OBJ[ppa] = true
      })
    }

    this.state = {
      chartData: props.chartData,
      data: props.chartData[DURATION_TYPE_SINGULAR[props.chartsTab]],
      chartType: props.chartsType,
      chartsTab: props.chartsTab,
      dataLoaded: props.dataLoaded,
      reference_id: props.reference_id,
      type: props.type,
      constants: props.constants,
      noData: false,
      all_print: PDF_PRINT_OBJ,
      chartChecked: props.chartGraphSetting,
      all_print_checked: false,
      // graphChecked: props.chartGraphSetting.split(",").map(e => {  }),
      // all_print: (PDF_PRINT_ARRAY.includes('V001') && PDF_PRINT_ARRAY.includes('V002') && PDF_PRINT_ARRAY.includes('V003') && PDF_PRINT_ARRAY.includes('V004') &&
      //     PDF_PRINT_ARRAY.includes('V005') && PDF_PRINT_ARRAY.includes('V007') && PDF_PRINT_ARRAY.includes('V008') && PDF_PRINT_ARRAY.includes('V009') &&
      //     PDF_PRINT_ARRAY.includes('V010') && PDF_PRINT_ARRAY.includes('V011')),
      // V001_print: PDF_PRINT_ARRAY.includes('V001'),
      // V002_print: PDF_PRINT_ARRAY.includes('V002'),
      // V003_print: PDF_PRINT_ARRAY.includes('V003'),
      // V004_print: PDF_PRINT_ARRAY.includes('V004'),
      // V005_print: PDF_PRINT_ARRAY.includes('V005'),
      // V007_print: PDF_PRINT_ARRAY.includes('V007'),
      // V008_print: PDF_PRINT_ARRAY.includes('V008'),
      // V009_print: PDF_PRINT_ARRAY.includes('V009'),
      // V010_print: PDF_PRINT_ARRAY.includes('V010'),
      // V011_print: PDF_PRINT_ARRAY.includes('V011'),
      chartDataMapped: {},
      chartBloodSugarData: {},
      showOnlyTicked: props.showOnlyTicked,
      checkBoxDisable: props.checkBoxDisable,
      showVitalType: props.showVitalType || '',
      vitalsConstant: [],
      zoomGraph:null,
      oneChartDataFound: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return ({
      chartData: nextProps.chartData,
      data: nextProps.chartData[DURATION_TYPE_SINGULAR[nextProps.chartsTab]],
      chartType: nextProps.chartsType,
      chartsTab: nextProps.chartsTab,
      dataLoaded: nextProps.dataLoaded,
      showOnlyTicked: nextProps.showOnlyTicked,
      checkBoxDisable: nextProps.checkBoxDisable,
      showVitalType: nextProps.showVitalType,
    })
  }

  componentDidMount() {
    let vitalsConstant = this.state.constants ? (this.state.showVitalType == 'homecare' && this.state.constants.vital_groups['VG003'] ? this.state.constants.vital_groups['VG003'].vital_sign : this.state.constants.vitals_details) : []
    this.setState({
      vitalsConstant
    }, () => {
      this.handleDynamicDataCreation();
      this.handleAllPrintCheckedFn();
    })
  }

  updatedVitalGraphData(dataSet) {
    var finalLabel = [];
    var dataLabel = [];
    var rawLabel = [];
    var finalData = [];
    var diffArr = [];
    var totalDiff = 0;
    var count = 0;
    var valueArray = Object.values(dataSet);
    var labelArray = Object.keys(dataSet);


    valueArray.map((value, i) => {
      if (value !== "" && value !='NaN') {
        if(DURATION_TYPE_SINGULAR[this.state.chartsTab]=='day')
          finalLabel.push(moment(labelArray[i],'YYYY-MM-DD HH:mm:ss').format('h:mm A'));
        else
          finalLabel.push(moment(labelArray[i],'YYYY-MM-DD HH:mm:ss').format('D MMM, YYYY'));
        dataLabel.push(moment(labelArray[i],'YYYY-MM-DD HH:mm:ss').format('D MMM, YYYY | h:mm A'));
        rawLabel.push(labelArray[i]);
        finalData.push(value);
      }
    })
    finalData.map((value, i) => {
      if (i < finalData.length - 1) {
        var diff = parseFloat(finalData[i + 1]) - parseFloat(value);
        totalDiff += Math.abs(diff);
        diffArr.push(Math.abs(diff));
      }
    })
    diffArr = diffArr.filter(e => e !== 0)
    diffArr = diffArr.sort(function (a, b) { return a - b });
    let minY = 0;
    let maxY = 0;
    let stepY = 1;
    if (finalData.length > 0 && diffArr.length > 0) {
      let dataArray = [...finalData];
      let sortedData = dataArray.sort(function (a, b) { return a - b });
      // let minCorrection = parseFloat(diffArr[0])>20?20:parseFloat(diffArr[0]);
      // let maxCorrection = parseFloat(diffArr[diffArr.length - 1])>20?20:parseFloat(diffArr[diffArr.length - 1]);

      stepY = totalDiff / diffArr.length;
      stepY = Math.round(stepY);
      stepY = stepY > 50 ? 50 : stepY
      minY = parseFloat(sortedData[0]) - stepY;
      maxY = parseFloat(sortedData[sortedData.length - 1]) + stepY;
    }
    // minY = minY==0?parseFloat(finalData[0])-stepY:minY;
    minY = minY < 0 ? 0 : minY;
    if (diffArr.length == 0)
      minY = minY == 0 ? parseFloat(finalData[0]) - stepY : minY;
    maxY = maxY == 0 ? parseFloat(finalData[finalData.length - 1]) + stepY : maxY;

    return { label: finalLabel, data: finalData,  rawLabel: rawLabel, dataLabel: dataLabel, minY: minY, maxY: maxY, stepY: stepY };
  }

  handleDynamicDataCreation = _ => {
    let { data, chartDataMapped, chartBloodSugarData, constants } = this.state;
    var vitals = constants.vitals_details;

    let one_chart_data_found = false;

    if (data) {
      vitals.map((vitalDetails, index) => {
        var dataSet = data[vitalDetails.id];
        var vital = vitalDetails.id;

        if (vital !== 'V005' && vital !== 'V006' && vital !== 'V017') {

          /*const tempData = {
              labels: [],
              data: []
          };*/
          var chartData = this.updatedVitalGraphData(dataSet);
          const chartcDotsArr = this.createPointsWithDifferentColors(vital, chartData.data);

          if(chartData.data && chartData.data.length > 0){
            one_chart_data_found = true;
          }

          chartDataMapped[vital] = {
            ['data' + vital]: {
              labels: chartData.label,
              datasets: [
                {
                  label: '',
                  backgroundColor: 'rgb(102 102 102)',
                  borderColor: 'rgb(102 102 102)',
                  pointBackgroundColor: chartcDotsArr,
                  pointBorderColor: chartcDotsArr,
                  borderWidth: 1,
                  fill: false,
                  spanGaps: true,
                  data: chartData.data
                }
              ]
            },
            ['options' + vital]: {
            animation: {
                duration: 0
                },
              responsive: true,
              title: {
                display: false,
                text: vitalDetails.display_name,
                fontSize: 15
              },
              elements: {
                line: {
                  tension: 0,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  font: {
                    size: 0,
                  },
                },
              },
              legend: {
                position: "bottom",
                display: false,
                labels: {
                  fontSize: 11,
                  boxWidth: 6,
                  //fontColor: '#333333',
                  // usePointStyle: true,
                },
              },
              scales: {
                xAxes: [{
                  display: true,
                  scaleLabel: {
                    display: false,
                    labelString: '',
                  },
                  ticks: {
                    fontSize: 11,
                  }
                }],
                yAxes: {
                    position: "left",
                    display: true,
                    scaleLabel: {
                      display: false,
                      labelString: "",
                    },
                    suggestedMin: chartData.minY,
                    suggestedMax: chartData.maxY,
                    ticks: {
                      fontSize: 11,
                      precision: vitalDetails.decimal_value,
                      stepSize: chartData.stepY,
                      afterBuildTicks: function (axis, ticks) {
                        const totalTicks = 10;
                        let density = axis.options.ticks.stepSize;
                        var firstTick = ticks[ticks.length - 1];
                        // var lastTick = ticks[0];
                        var tempA = axis.chart.config.data.datasets[0].data;
                        var dataset = [...tempA];
                        dataset = dataset.sort(function (a, b) {
                          return a - b;
                        });
                        var lastTick = dataset[dataset.length - 1];
                        var tickArr = [];
                        var tempHighest = parseFloat(firstTick) + totalTicks * density;
                        if (lastTick > tempHighest) {
                          density = (lastTick - firstTick) / 10;
                          density = Math.ceil(density);
                        }
                        for (let i = firstTick; i <= lastTick && tickArr.length < totalTicks; i = i + density) {
                          tickArr.push(i);
                        }
                        lastTick = tickArr[tickArr.length - 1] + density;
                        tickArr.push(lastTick);
                        axis.options.ticks.max = lastTick;
                        return tickArr;
                      }
                    }
                }
              },
            }
          };
        } else if (vital === 'V005') {

          var chartData = this.updatedVitalGraphData(dataSet)
          var chartData2 = this.updatedVitalGraphData(data['V006'])

          if((chartData.data && chartData.data.length > 0) || (chartData2.data && chartData2.data.length > 0)){
            one_chart_data_found = true;
          }
          var labels = [...chartData.rawLabel, ...chartData2.rawLabel];
          labels = [...new Set(labels)].sort();
          var dataSystolic=[];
          var dataDiastolic=[];

          labels.map(item=>{
            let indexSystolic = chartData2.rawLabel.indexOf(item)
            dataSystolic.push(indexSystolic==-1?null:chartData2.data[indexSystolic]);

            let indexDiastolic = chartData.rawLabel.indexOf(item)
            dataDiastolic.push(indexDiastolic==-1?null:chartData.data[indexDiastolic]);
          })

          const systolicDotsArr = this.createPointsWithDifferentColors('V006', dataSystolic);
          const diastolicDotsArr = this.createPointsWithDifferentColors('V005', dataDiastolic);

          let minY = chartData.minY < chartData2.minY ? chartData.minY : chartData2.minY;
          let maxY = chartData.maxY > chartData2.maxY ? chartData.maxY : chartData2.maxY;
          let stepY = chartData.stepY > chartData2.stepY ? chartData.stepY : chartData2.stepY;
          var dataLabel=[];
          labels.map((label, index) => {
            dataLabel.push(moment(label,'YYYY-MM-DD HH:mm:ss').format('D MMM, YYYY | h:mm A'))
            if(DURATION_TYPE_SINGULAR[this.state.chartsTab]=='day')
              labels[index]= moment(label,'YYYY-MM-DD HH:mm:ss').format('h:mm A')
            else
              labels[index]= moment(label,'YYYY-MM-DD HH:mm:ss').format('D MMM, YYYY')
          });
          chartDataMapped[vital] = {
            ["data" + vital]: {
              labels: labels,
              datasets: [
                {
                  label: " ",
                  backgroundColor: "rgb(102 102 102)",
                  borderColor: "rgb(102 102 102)",
                  pointBackgroundColor: systolicDotsArr,
                  pointBorderColor: systolicDotsArr,
                  borderWidth: 1,
                  fill: false,
                  spanGaps: true,
                  data: dataSystolic,
                  labelT: "Systolic",
                },
                {
                  label: "",
                  backgroundColor: "rgb(90 201 239)",
                  borderColor: "rgb(90 201 239)",
                  pointBackgroundColor: diastolicDotsArr,
                  pointBorderColor: diastolicDotsArr,
                  borderWidth: 1,
                  fill: false,
                  spanGaps: true,
                  data: dataDiastolic,
                  labelT: "Diastolic",
                },
              ],
            },
            ["options" + vital]: {
              animation: {
                duration: 0
              },
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  font: {
                    size: 0,
                  },
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      let label = context.dataset.labelT + ": " + context.raw;
                      return label;
                    }
                  }
                }
              },
              title: {
                display: false,
                text: "Blood Pressure (mmHg)",
                fontSize: 15,
              },
              elements: {
                line: {
                  tension: 0,
                },
                point: {
                  radius: 3,
                },
              },
              layout: {
                padding: {
                  left: 0,
                  right: 0,
                  top: 10,
                  bottom: 0,
                },
              },
              legend: {
                position: "bottom",
                align: "center",
                display: true,
                labels: {
                  boxWidth: 0,

                  // fontColor: '#333333',
                  fontSize: 0,
                  // usePointStyle: true,
                },
              },
              scales: {
                xAxes: [
                  {
                    display: true,
                    scaleLabel: {
                      display: false,
                      labelString: "",
                    },
                    ticks: {
                      fontSize: 11,
                    },
                  },
                ],
                yAxes: {
                  position: "left",
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: "",
                  },
                  suggestedMin: minY,
                  suggestedMax: maxY,
                  ticks: {
                    fontSize: 11,
                    precision: vitalDetails.decimal_value,
                    reverse: false,
                    stepSize: stepY,
                    afterBuildTicks: function (axis, ticks) {
                      const totalTicks = 10;
                      let density = axis.options.ticks.stepSize;
                      var firstTick = ticks[ticks.length - 1];
                      // var lastTick = ticks[0];
                      var tempA = axis.chart.config.data.datasets[0].data;
                      var dataset = [...tempA];
                      dataset = dataset.sort(function (a, b) {
                        return a - b;
                      });
                      var tempB = axis.chart.config.data.datasets[1].data;
                      var dataset2 = [...tempB];
                      dataset2 = dataset2.sort(function (a, b) {
                        return a - b;
                      });
                      var lastTick =
                        parseFloat(dataset[dataset.length - 1]) > parseFloat(dataset2[dataset2.length - 1])
                          ? dataset[dataset.length - 1]
                          : dataset2[dataset2.length - 1];
                      var tickArr = [];
                      var tempHighest = parseFloat(firstTick) + totalTicks * density;
                      if (lastTick > tempHighest) {
                        density = (lastTick - firstTick) / 10;
                        density = Math.ceil(density);
                      }

                      for (let i = firstTick; i < lastTick && tickArr.length < totalTicks; i = i + density) {
                        tickArr.push(i);
                      }
                      lastTick = tickArr[tickArr.length - 1] + density;
                      tickArr.push(lastTick);
                      axis.options.ticks.max = lastTick;
                      return tickArr;
                    },
                  },
                },
              },
            },
          };
        }
        else if (vital === 'V017') {
          var dataSet = data[vitalDetails.id];
          var vital = vitalDetails.id;

          var chartData = this.updatedVitalGraphData(dataSet);
          const chartcDotsArr = this.createPointsWithDifferentColors(vital, chartData.data);

          chartDataMapped[vital] = {
            ['data' + vital]: {
              labels: chartData.label,
              datasets: [
                {
                  label: 'bsa',
                  backgroundColor: 'rgb(102 102 102)',
                  borderColor: 'rgb(102 102 102)',
                  pointBackgroundColor: chartcDotsArr,
                  pointBorderColor: chartcDotsArr,
                  borderWidth: 1,
                  fill: false,
                  spanGaps: true,
                  data: chartData.data
                }
              ]
            },
            ['options' + vital]: {
                animation: {
                    duration: 0
                  },
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    datalabels: {
                      font: {
                        size: 0,
                      },
                    },
                  },
                  title: {
                    display: false,
                    text: vitalDetails.display_name,
                    fontSize: 15,
                  },
                  elements: {
                    line: {
                      tension: 0,
                    },
                  },
                  legend: {
                    position: "bottom",
                    display: false,
                    labels: {
                      fontSize: 11,
                      boxWidth: 6,
                      //fontColor: '#333333',
                      // usePointStyle: true,
                    },
                  },
                  scales: {
                    xAxes: [
                      {
                        display: true,
                        scaleLabel: {
                          display: false,
                          labelString: "",
                        },
                        ticks: {
                          fontSize: 11,
                        },
                      },
                    ],
                    yAxes: {
                      position: "left",
                      display: true,
                      scaleLabel: {
                        display: true,
                        labelString: "",
                      },
                      ticks: {
                        beginAtZero: true,
                        fontSize: 11,
                        precision: vitalDetails.decimal_value,
                      },
                    },
                  }
            }
          };
        }
      })

      vitals.map((vitalDetails, index) => {
        let vital = vitalDetails.id;

        if (vital === 'V010') {
          const chartDataBSFasting = this.updatedVitalGraphData(data['V010']);
          // const fastingDotsArr = this.createPointsWithDifferentColors('V010', chartDataBSFasting.data);

          const chartDataBSPPrandial = this.updatedVitalGraphData(data['V011']);
          // const ppDotsArr = this.createPointsWithDifferentColors('V011', chartDataBSPPrandial.data);

          const chartDataBSRandom = this.updatedVitalGraphData(data['V013']);
          // const randomDotsArr = this.createPointsWithDifferentColors('V013', chartDataBSRandom.data);

          const chartDataInsulin = this.updatedVitalGraphData(data['V014']);
          // const insulinDotsArr = this.createPointsWithDifferentColors('V014', chartDataInsulin.data);

          if((chartDataBSFasting.data && chartDataBSFasting.data.length > 0) || (chartDataBSPPrandial.data && chartDataBSPPrandial.data.length > 0) || (chartDataBSRandom.data && chartDataBSRandom.data.length > 0)){
            one_chart_data_found = true;
          }

          /*const bloodSugarLabels = ([...chartDataBSFasting.label, ...chartDataBSPPrandial.label, ...chartDataBSRandom.label, ...chartDataInsulin.label]).filter((value, index, arr) => {
            return arr.indexOf(value) === index;
          });*/

          let minY = Math.min(isNaN(chartDataBSFasting.minY)?Infinity:chartDataBSFasting.minY, isNaN(chartDataBSPPrandial.minY)?Infinity:chartDataBSPPrandial.minY, isNaN(chartDataBSRandom.minY)?Infinity:chartDataBSRandom.minY/* , isNaN(chartDataInsulin.minY)?Infinity:chartDataInsulin.minY */);

          let maxY = Math.max(isNaN(chartDataBSFasting.maxY)?-Infinity:chartDataBSFasting.maxY, isNaN(chartDataBSPPrandial.maxY)?-Infinity:chartDataBSPPrandial.maxY, isNaN(chartDataBSRandom.maxY)?-Infinity:chartDataBSRandom.maxY/* , isNaN(chartDataInsulin.maxY)?-Infinity:chartDataInsulin.maxY */);

          let stepY = Math.max(chartDataBSFasting.stepY, chartDataBSPPrandial.stepY, chartDataBSRandom.stepY/* , chartDataInsulin.stepY */);


          var bloodSugarLabels = [...chartDataBSFasting.rawLabel, ...chartDataBSPPrandial.rawLabel,...chartDataBSRandom.rawLabel/* , ...chartDataInsulin.rawLabel */];
          bloodSugarLabels = [...new Set(bloodSugarLabels)].sort();

          var dataBSFasting=[];
          var dataBSPPrandial=[];
          var dataBSRandom=[];
          var dataInsulin=[];

          var dataBSFastingLabel=[];
          var dataBSPPrandialLabel=[];
          var dataBSRandomLabel=[];
          var dataInsulinLabel=[];

          bloodSugarLabels.map(item=>{
            let indexFasting = chartDataBSFasting.rawLabel.indexOf(item)
            dataBSFasting.push(indexFasting==-1?null:chartDataBSFasting.data[indexFasting]);
            dataBSFastingLabel.push(indexFasting==-1?null:item);

            let indexPP = chartDataBSPPrandial.rawLabel.indexOf(item)
            dataBSPPrandial.push(indexPP==-1?null:chartDataBSPPrandial.data[indexPP]);
            dataBSPPrandialLabel.push(indexPP==-1?null:item);

            let indexRandom = chartDataBSRandom.rawLabel.indexOf(item)
            dataBSRandom.push(indexRandom==-1?null:chartDataBSRandom.data[indexRandom]);
            dataBSRandomLabel.push(indexRandom==-1?null:item);

            let indexInsulin = chartDataInsulin.rawLabel.indexOf(item)
            dataInsulin.push(indexInsulin==-1?null:chartDataInsulin.data[indexInsulin]);
            dataInsulinLabel.push(indexInsulin==-1?null:item);
          })
          /* bloodSugarLabels.map(item=>{
            let indexFasting = chartDataBSFasting.rawLabel.indexOf(item)
            if(indexFasting!=-1){
              dataBSFasting.push(chartDataBSFasting.data[indexFasting]);
              dataBSFastingLabel.push(item);
            }

            let indexPP = chartDataBSPPrandial.rawLabel.indexOf(item)
            if(indexPP!=-1){
              dataBSPPrandial.push(chartDataBSPPrandial.data[indexPP]);
              dataBSPPrandialLabel.push(item);
            }            

            let indexRandom = chartDataBSRandom.rawLabel.indexOf(item)
            if(indexRandom!=-1){
              dataBSRandom.push(chartDataBSRandom.data[indexRandom]);
              dataBSRandomLabel.push(item);
            }         
            
            let indexInsulin = chartDataInsulin.rawLabel.indexOf(item)
            if(indexInsulin!=-1){
              dataInsulin.push(chartDataInsulin.data[indexInsulin]);
              dataInsulinLabel.push(item);
            }            
          }) */
          
          let fastingDotsArr = this.createPointsWithDifferentColors('V010', dataBSFasting);
          let ppDotsArr = this.createPointsWithDifferentColors('V011', dataBSPPrandial);
          let randomDotsArr = this.createPointsWithDifferentColors('V013', dataBSRandom);
          //let insulinDotsArr = this.createPointsWithDifferentColors('V014', dataInsulin);

          let fastingDotsArrBackground = [];
          let ppDotsArrBackground = [];
          let randomDotsArrBackground = [];

          dataBSFasting.forEach((item,key) => {
            if(item != null){
              let label = dataBSFastingLabel[key];
              let insulinRecorded = dataInsulinLabel.indexOf(label);
              if(insulinRecorded == -1){
                fastingDotsArrBackground.push(fastingDotsArr[key])
              } else {
                fastingDotsArrBackground.push('#fff')
              }
            } else {
              fastingDotsArrBackground.push(fastingDotsArr[key])
            }
          });
          dataBSPPrandial.forEach((item,key) => {
            if(item != null){
              let label = dataBSPPrandialLabel[key];
              let insulinRecorded = dataInsulinLabel.indexOf(label);
              if(insulinRecorded == -1){
                ppDotsArrBackground.push(ppDotsArr[key])
              } else {
                ppDotsArrBackground.push('#fff')
              }
            } else {
              ppDotsArrBackground.push(ppDotsArr[key])
            }
          });
          dataBSRandom.forEach((item,key) => {
            if(item != null){
              let label = dataBSRandomLabel[key];
              let insulinRecorded = dataInsulinLabel.indexOf(label);
              if(insulinRecorded == -1){
                randomDotsArrBackground.push(randomDotsArr[key])
              } else {
                randomDotsArrBackground.push('#fff')
              }
            } else {
              randomDotsArrBackground.push(randomDotsArr[key])
            }
          });

          var dataLabel=[];
          bloodSugarLabels.map((label, index) => {
            dataLabel.push(moment(label,'YYYY-MM-DD HH:mm:ss').format('D MMM, YYYY | h:mm A'))
            if(DURATION_TYPE_SINGULAR[this.state.chartsTab]=='day')
              bloodSugarLabels[index]= moment(label,'YYYY-MM-DD HH:mm:ss').format('h:mm A')
            else
              bloodSugarLabels[index]= moment(label,'YYYY-MM-DD HH:mm:ss').format('D MMM, YYYY')
          });

          chartBloodSugarData[vital] = {
            ['data' + vital]: {
              labels: bloodSugarLabels,
              datasets: [
                {
                    label: bloodSugarLabels,
                    backgroundColor: "rgb(102 102 102)",
                    borderColor: "rgb(102 102 102)",
                    pointBackgroundColor: ppDotsArrBackground,
                    pointBorderColor: ppDotsArr,
                    borderWidth: 1,
                    pointBorderWidth: 2,
                    pointRadius: 3,
                    fill: false,
                    spanGaps: true,
                    data: dataBSPPrandial,
                    insulinData: dataInsulin,
                    labelT: "PP",
                },
                {
                    label: bloodSugarLabels,
                    backgroundColor: "rgb(255 82 235)",
                    borderColor: "rgb(255 82 235)",
                    pointBackgroundColor: fastingDotsArrBackground,
                    pointBorderColor: fastingDotsArr,
                    borderWidth: 1,
                    pointBorderWidth: 2,
                    pointRadius: 3,
                    fill: false,
                    spanGaps: true,
                    data: dataBSFasting,
                    insulinData: dataInsulin,
                    labelT: "Fasting",
                }, {
                    label: bloodSugarLabels,
                    backgroundColor: "rgb(90 201 239)",
                    borderColor: "rgb(90 201 239)",
                    pointBackgroundColor: randomDotsArrBackground,
                    pointBorderColor: randomDotsArr,
                    borderWidth: 1,
                    pointBorderWidth: 2,
                    pointRadius: 3,
                    fill: false,
                    spanGaps: true,
                    data: dataBSRandom,
                    insulinData: dataInsulin,
                    labelT: "Random",
                }
                /* , {
                  label: 'Insulin',
                  backgroundColor: '#fff',
                  borderColor: '#fff',
                  pointBackgroundColor: [],
                  pointBorderColor: [],
                  borderWidth: 0,
                  fill: false,
                  spanGaps: true,
                  data: [],
                } */
              ]
            },
            ['options' + vital]: {
                animation: {
                    duration: 0
                  },
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    datalabels: {
                      font: {
                        size: 0,
                      },
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          let label = context.dataset.labelT + ": " + context.raw + " mg/dL";
                          return label;
                        },
                        afterLabel: function (context) {
                          if (context.dataset.insulinData[context.dataIndex]) {
                            let label = "Insulin: " + context.dataset.insulinData[context.dataIndex] + " Unit";
                            return label;
                          } else {
                            return null;
                          }
                        }
    
                      }
                    },
                  },
                  title: {
                    display: false,
                    text: "Blood Sugar",
                    fontSize: 15,
                  },
                  elements: {
                    line: {
                      tension: 0,
                    },
                  },
                  /* tooltips: {
                    displayColors: true,
                    callbacks: {
                      label: function (tooltipItem, data) {
                        let label = data.datasets[tooltipItem.datasetIndex].labelT + ": " + tooltipItem.yLabel + " mg/dL";
    
                        return label;
                      },
                      afterLabel: function (tooltipItem, data) {
                        if (data.datasets[tooltipItem.datasetIndex].insulinData[tooltipItem.index] != null) {
                          let label = "Insulin: " + data.datasets[tooltipItem.datasetIndex].insulinData[tooltipItem.index] + " Unit";
                          return label;
                        } else {
                          return null;
                        }
                      },
                    },
                  }, */
                  layout: {
                    padding: {
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                    },
                  },
                  legend: {
                    position: "bottom",
                    align: "center",
                    display: true,
                    labels: {
                      boxWidth: 0,
                      //fontColor: '#333333',
                      fontSize: 0,
                      // usePointStyle: true,
                    },
                  },
                  scales: {
                    xAxes: [
                      {
                        display: true,
                        scaleLabel: {
                          display: false,
                          labelString: "",
                        },
                        ticks: {
                          fontSize: 11,
                        },
                      },
                    ],
                    yAxes: {
                      position: "left",
                      display: true,
                      scaleLabel: {
                        display: true,
                        labelString: "",
                      },
                      suggestedMin: minY,
                      suggestedMax: maxY,
                      ticks: {
                        fontSize: 11,
                        precision: vitalDetails.decimal_value,
                        reverse: false,
                        stepSize: stepY,
                        afterBuildTicks: function (axis, ticks) {
                          const totalTicks = 10;
                          let density = axis.options.ticks.stepSize;
    
                          var firstTick = ticks[ticks.length - 1];
                          var lastTick = ticks[0];
    
                          var tempA = axis.chart.config.data.datasets[0].data;
                          var dataset = [...tempA];
                          dataset = dataset.sort(function (a, b) {
                            return a - b;
                          });
                          var tempB = axis.chart.config.data.datasets[1].data;
                          var dataset2 = [...tempB];
                          dataset2 = dataset2.sort(function (a, b) {
                            return a - b;
                          });
                          var tempC = axis.chart.config.data.datasets[2].data;
                          var dataset3 = [...tempC];
                          dataset3 = dataset3.sort(function (a, b) {
                            return a - b;
                          });
                          /* var tempD = axis.chart.config.data.datasets[3].data;
                          var dataset4 = [...tempD];
                          dataset4 = dataset4.sort(function (a, b) { return a - b }); */
    
                          lastTick = Math.max(
                            parseFloat(dataset[dataset.length - 1]),
                            parseFloat(dataset2[dataset2.length - 1]),
                            parseFloat(dataset3[dataset3.length - 1]) /* , parseFloat(dataset4[dataset4.length - 1]) */
                          );
    
                          var tickArr = [];
                          var tempHighest = parseFloat(firstTick) + totalTicks * density;
    
                          if (lastTick > tempHighest) {
                            density = (lastTick - firstTick) / 10;
                            density = Math.ceil(density);
                          }
    
                          for (let i = firstTick; i < lastTick && tickArr.length < totalTicks; i = i + density) {
                            tickArr.push(i);
                          }
                          lastTick = tickArr[tickArr.length - 1] + density;
                          tickArr.push(lastTick);
                          axis.options.ticks.max = lastTick;
                          return tickArr;
                        }
                      }
                    }
                  }
            }
          };
        }
      })
    }

    if(this.state.showOnlyTicked != undefined && this.state.showOnlyTicked == true && Object.entries(this.state.all_print).length == 0){
      one_chart_data_found = false;
    }

    this.setState({
      chartDataMapped,
      chartBloodSugarData,
      oneChartDataFound: one_chart_data_found
    })

  }

  handleAllPrintCheckedFn = _ => {
    const { chartChecked, constants } = this.state;

    const chartCheckLength = chartChecked.split(",").length;
    const totalVitalLength = constants.vitals_details.length;
    this.setState({
      all_print_checked: chartCheckLength === totalVitalLength
    })
  }

  handleAllPrintCheck = _ => {
    let { all_print_checked, constants } = this.state;

    const PDF_PRINT_OBJ = {};
    constants.vitals_details.forEach(vd => {
      PDF_PRINT_OBJ[vd.id] = all_print_checked
    })
    this.setState({
      all_print: PDF_PRINT_OBJ
    }, () => console.log(this.state.all_print))
  }

  handleVitalGroupAPI = async (e, vital) => {

    const { reference_id, all_print, all_print_checked, constants, showVitalType } = this.state;

    let selected_vital_graphs = '';

    if (vital === 'all_print') {
      selected_vital_graphs = !all_print_checked ? constants.vitals_details.map(e => e.vital_code).toString() : '';
      this.setState({
        all_print_checked: !this.state.all_print_checked
      }, this.handleAllPrintCheck);
    } else {
      if (Object.keys(all_print).length > 0) {
        all_print[vital] = !all_print[vital];
      } else {
        all_print[vital] = true;
      }
      selected_vital_graphs = Object.keys(all_print).filter(ap => all_print[ap] === true).toString();
    }

    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id,
      selected_vital_graphs
    }

    const vitalGraphPromise = await Axios.post(PDF_CONFIG_VITALS_GRAPH_UPDATE, qs.stringify(PARAMS))

    if (vitalGraphPromise.data.status) {

      this.setState({
        chartChecked: selected_vital_graphs
      }, () => {
        this.props.updateData(selected_vital_graphs);
        this.handleAllPrintCheckedFn();
      })

      this.setState({
        dummy: !this.state.dummy
      })
    }
  }

  handleVitalGrahpCheck = (e, vital) => {
    // e.preventDefault();
    // const { name, value } = e.currentTarget;

    // this.setState({
    //     [name]: !JSON.parse(value)
    // }, () => this.handleVitalGroupAPI(vital))
    this.handleVitalGroupAPI(vital)

  }

  getVitalRangeColor = (value, range) => {
    if (value == '-') return '';

    if (range.critical.min == null && range.critical.max == null)
    
        //return '#008000';
      return 'rgb(0 168 77)';

    let vitalRange = '';
    //normal range
    if (value >= range.normal.min && value <= range.normal.max)
        //vitalRange = '#008000'; //green
      vitalRange = 'rgb(0 168 77)'; //green

    //abnormal range
    if (
        (value > range.critical.min && value < range.normal.min) ||
        (value > range.normal.max && value < range.critical.max)
    )
        //vitalRange = '#ffbf00'; //amber
      vitalRange = 'rgb(245 153 28)'; //amber

    //critical range
    if ((range.critical.min != null && value <= range.critical.min) || (range.critical.max != null && value >= range.critical.max))
        //vitalRange = '#d24350'; //red
      vitalRange = 'rgb(255 0 0)'; //red

    return vitalRange;
  }

  createPointsWithDifferentColors = (__id, data) => {
    let colorDots = []
    this.state.vitalsConstant.map((vital) => {
      if (vital.id === __id) {
        data.map(val => {
          colorDots.push(this.getVitalRangeColor(val, this.state.constants.vitals_range[vital.id]));
        });

      }
    });
    return colorDots;
  }

  render() {
    const { oneChartDataFound } = this.state;

    const datasetKeyProvider = () => { 
      return btoa(Math.random()).substring(0,12)
    } 
    return (
        <>
          {(this.state.dataLoaded) ? (
              oneChartDataFound ? (
                  <div className="row">
                    {this.state.chartType === 'all' && !this.state.showOnlyTicked && !this.state.checkBoxDisable ? (
                        <>
                          <div className="col-8 pl-2 check-primary">
                            <div className="custom-control custom-checkbox">
                              <input type="checkbox" onChange={e => this.handleVitalGroupAPI(e, 'all_print')} value={this.state.all_print_checked} checked={this.state.all_print_checked}
                                     className="custom-control-input" id="all_print" name="all_print" autocomplete="off" />
                              <label className="custom-control-label text-basic" htmlFor="all_print">Select All to Print with Prescription</label>
                            </div>
                          </div>
                          {/* <div className="col-6 pr-2 text-right check-primary">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" onChange={e => this.handleVitalGroupAPI(e, 'all_print')} value={this.state.all_print} checked={this.state.all_print}
                            className="custom-control-input" id="all_print" name="all_print" autocomplete="off" />
                        <label className="custom-control-label text-basic" for="all_print">SELECT ALL</label>
                    </div>
                </div> */}
                        </>
                    ) : null}
                    <div className={this.state.chartType === 'all' && !this.state.showOnlyTicked && !this.state.checkBoxDisable ? `col-4 pl-3 vital-graph-legends` : `col-12 pl-3 vital-graph-legends`}>
                      <ul className="legend_unit_label">
                        <li><span className="normal"></span>Normal</li>
                        <li><span className="abnormal"></span>Abnormal</li>
                        <li><span className="critical"></span>Critical</li>
                      </ul>
                    </div>
                    <div className={`chartCanvasWrapper w-100 ${this.state.chartType !== 'all' ? 'chartCanvasWrapperSmall' : ''}`}>
                      {Object.keys(this.state.chartDataMapped).length > 0 ? (
                          this.state.vitalsConstant.map((vd, i) => {
                            if (this.state.chartType !== 'all') {
                              if (this.state.chartType === vd.id) {
                                return (
                                    <div className="chartCanvas" key={i}>
                                      <h4>{vd.id == 'V005' ?  'Blood Pressure' :(vd.id=='V010' || vd.id === 'V011' || vd.id === 'V013' || vd.id === 'V014'?'Blood Sugar':vd.display_name)}</h4>
                                      {!this.state.checkBoxDisable ? (
                                          <div className="check_for_print">
                                            <div className="custom-control custom-checkbox">
                                              <input type="checkbox" onChange={e => this.handleVitalGroupAPI(e, vd.id)} value={this.state.all_print[vd.id]} checked={this.state.all_print[vd.id]}
                                                     className="custom-control-input pdf_print" name={vd.id + ' _print'} autoComplete="off" />
                                              <label className="custom-control-label text-basic" htmlFor={vd.id + ' _print'}>&nbsp;</label>
                                            </div>
                                          </div>
                                      ) : null}
                                      {(vd.id !== 'V010' && vd.id !== 'V011' && vd.id !== 'V013' && vd.id !== 'V014') ? <>
                                        <Line
                                            data={this.state.chartDataMapped[vd.id]['data' + vd.id]}
                                            options={this.state.chartDataMapped[vd.id]['options' + vd.id]} datasetKeyProvider={datasetKeyProvider}
                                        />
                                        {vd.id == 'V005' ? <ul className="legend_unit_label">
                                          <li><span className="gray"></span>Systolic</li>
                                          <li><span className="blue"></span>Diastolic</li>
                                        </ul> : null} </> : null }

                                      {(vd.id === 'V010' || vd.id === 'V011' || vd.id === 'V013' || vd.id === 'V014') && <>
                                        <Line
                                            data={this.state.chartBloodSugarData['V010']['data' + 'V010']}
                                            options={this.state.chartBloodSugarData['V010']['options' + 'V010']} datasetKeyProvider={datasetKeyProvider}
                                        />
                                        <ul className="legend_unit_label">
                                          <li><span className="gray"></span>PP</li>
                                          <li><span className="pink"></span>Fasting</li>
                                          <li><span className="blue"></span>Random</li>
                                          <li><span className="white round"></span>Insulin</li>
                                        </ul>
                                      </>}
                                    </div>
                                )
                              }
                            } else {
                              if (!this.state.showOnlyTicked) {
                                if (vd.id !== 'V006') {
                                  return (
                                      <>
                                        {vd.id !== 'V014' && vd.id !== 'V011' && vd.id !== 'V010' && vd.id !== 'V013' && this.state.chartDataMapped[vd.id]['data' + vd.id].datasets[0].data.length > 0 && <div className="chartCanvas" key={i}>
                                          <h4>{vd.id !== 'V005' ? vd.display_name : 'Blood Pressure'}</h4>

                                          {!this.state.showOnlyTicked && !this.state.checkBoxDisable ? (
                                              <div className="check_for_print">
                                                <div className="custom-control custom-checkbox">
                                                  <input type="checkbox" onChange={e => this.handleVitalGroupAPI(e, vd.id)} value={this.state.all_print[vd.id]} checked={this.state.all_print[vd.id]}
                                                         className="custom-control-input pdf_print" name={vd.id + ' _print'} autocomplete="off" />
                                                  <label className="custom-control-label text-basic" for={vd.id + ' _print'}>&nbsp;</label>
                                                </div>
                                              </div>
                                          ) : null}
                                          <Line
                                              data={this.state.chartDataMapped[vd.id]['data' + vd.id]}
                                              options={this.state.chartDataMapped[vd.id]['options' + vd.id]} datasetKeyProvider={datasetKeyProvider}
                                          />
                                          {vd.id == 'V005' ? <ul className="legend_unit_label chart-Chang">
                                            <li><span className="gray"></span>Systolic</li>
                                            <li><span className="blue"></span>Diastolic</li>
                                          </ul> : null}
                                        </div>
                                        }
                                        {(vd.id === 'V010' && this.state.chartBloodSugarData[vd.id]['data' + vd.id].datasets[0].data.length > 0) && <div className="chartCanvas" key={i}>
                                          <h4>Blood Sugar</h4>
                                          {!this.state.showOnlyTicked && !this.state.checkBoxDisable ? (
                                              <div className="check_for_print">
                                                <div className="custom-control custom-checkbox">
                                                  <input type="checkbox" onChange={e => this.handleVitalGroupAPI(e, vd.id)} value={this.state.all_print[vd.id]} checked={this.state.all_print[vd.id]}
                                                         className="custom-control-input pdf_print" name={vd.id + ' _print'} autocomplete="off" />
                                                  <label className="custom-control-label text-basic" for={vd.id + ' _print'}>&nbsp;</label>
                                                </div>
                                              </div>
                                          ) : null}
                                          <Line
                                              data={this.state.chartBloodSugarData[vd.id]['data' + vd.id]}
                                              options={this.state.chartBloodSugarData[vd.id]['options' + vd.id]} datasetKeyProvider={datasetKeyProvider}
                                          />
                                          <ul className="legend_unit_label chart-Chang1">
                                            <li><span className="gray"></span>PP</li>
                                            <li><span className="pink"></span>Fasting</li>
                                            <li><span className="blue"></span>Random</li>
                                            <li><span className="white round"></span>Insulin</li>
                                          </ul>
                                        </div>
                                        }
                                      </>
                                  )
                                }
                              } else {
                                // SHOW ONLY TICKED GRAPHS FOR VIEW INFORMATION PAGE
                                if (this.state.all_print[vd.id]) {
                                  if ((vd.id !== 'V006' && vd.id!='V011' && vd.id!='V013' && vd.id!='V014' && this.state.chartDataMapped[vd.id]['data' + vd.id].datasets[0].data.length > 0) || (vd.id == 'V010' && this.state.chartBloodSugarData[vd.id]['data' + vd.id].datasets[0].data.length > 0)) {
                                    return (
                                        <div className="chartCanvas" key={i}>
                                          <h4>{vd.id == 'V005' ?  'Blood Pressure' :(vd.id=='V010' || vd.id === 'V011' || vd.id === 'V013' || vd.id === 'V014'?'Blood Sugar':vd.display_name)}</h4>

                                          {!this.state.showOnlyTicked ? (
                                              <div className="check_for_print">
                                                <div className="custom-control custom-checkbox">
                                                  <input type="checkbox" onChange={e => this.handleVitalGroupAPI(e, vd.id)} value={this.state.all_print[vd.id]} checked={this.state.all_print[vd.id]}
                                                         className="custom-control-input pdf_print" name={vd.id + ' _print'} autocomplete="off" />
                                                  <label className="custom-control-label text-basic" for={vd.id + ' _print'}>&nbsp;</label>
                                                </div>
                                              </div>
                                          ) : null}
                                          {/*<Line
                                data={this.state.chartDataMapped[vd.id]['data' + vd.id]}
                                options={this.state.chartDataMapped[vd.id]['options' + vd.id]}
                              />*/}
                                          {(vd.id != 'V010') ? <>
                                            <Line
                                                data={this.state.chartDataMapped[vd.id]['data' + vd.id]}
                                                options={this.state.chartDataMapped[vd.id]['options' + vd.id]} datasetKeyProvider={datasetKeyProvider}
                                            />
                                            {vd.id == 'V005' ? <ul className="legend_unit_label chart-Chang">
                                              <li><span className="gray"></span>Systolic</li>
                                              <li><span className="blue"></span>Diastolic</li>
                                            </ul> : null} </> : null}

                                          {(vd.id == 'V010') && <>
                                            <Line
                                                data={this.state.chartBloodSugarData['V010']['data' + 'V010']}
                                                options={this.state.chartBloodSugarData['V010']['options' + 'V010']} datasetKeyProvider={datasetKeyProvider}
                                            />

                                            <ul className="legend_unit_label chart-Chang1">
                                              <li><span className="gray"></span>PP</li>
                                              <li><span className="pink"></span>Fasting</li>
                                              <li><span className="blue"></span>Random</li>
                                              <li><span className="white round"></span>Insulin</li>
                                            </ul>
                                          </>}
                                        </div>
                                    )
                                  }
                                }
                              }
                            }
                          })) : null
                      }
                    </div>
                  </div>
              ) : <div className="col d-flex justify-content-center align-self-center mt-2" style={{paddingTop: '50px'}}></div>

          ) : this.state.dataLoaded && this.state.data.date.length === 0 ? (
              <div
                  className="col d-flex justify-content-center align-self-center mt-2"
                  // style={{
                  //   visibility: `${!this.state.charts_while_filter ? "visible" : "hidden"}`,
                  // }}
              >
                No Data
              </div>
          ) : ""}
        </>
    );
  }
}

export default ChartPastVisit;
