import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import qs from 'qs';
import {
  PATIENT_INFO,
  PAUSEOPD,
  USERTYPES,
  AUDIO_CALL,
  AUDIO_VIDEO_CALL_STARTED,
  DEFAULT_VC_DRIVER,
  APPS_FEATURES_LIST,
  MYHC_APPTYPE,
  MHVCMARKETPLACE,
  MHEA,
  APPS_VARIANTS,
  HOMECARE_DETAIL,
  HOMECARE_CONSULT_ALERT,
  GETGENDER,
  HOMECARE_SAVE_ALERT,
  HOSPITAL_CONFIG, GENDER, BLOOD_GROUP, HOMECARE_REFERENCE_TYPE, SOAP_CONFIGURATION_LIST, TEMPLATE_SEARCH,
  CHECKOUT_HOSPITALS_ENABLED,
  PATIENT_CHECKOUT,
  EMRD_PDH,
  ISALLOWEDITSOAP,
  HOSPITAL_PREFIX,
  EMR_APP_ENV,
  ENABLE_MHC_PRESCRIPTION,
  HOSPITAL_PORTAL_INDEPENDENT_VOICE_CALL,
  FIND_CALLING_TYPE
} from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import moment from "moment";
import { withRouter } from 'react-router';
import { Modal, Skeleton, notification } from 'antd';
import { messageService, callService, headerService$, MultiPartyCallService } from '../../utils/rxjs-sharing';
import VideoConferencing from "../../pages/video-conferencing/VideoConferencing";
import QbVideoConferencing from "../../pages/video-conferencing/QbVideoConferencing";
import API_SERVICE from "../../utils/apiService";
import axios from "axios";
import SoapFlow from "../../utils/soap";

class AsideLeft extends Component {
  state = {
    patientInfo: {},
    patient_reference_id: '',
    homecare_appointment_status: null,
    patient: {
      mobile_no: null,
      patient_id: null,
      booking_id: null,
      patient_name: null
    },
    canEndCall: false,
    hungupExistingCall: false,
    callPatientBtn: true, // true means call btn disabled
    callNotificationMsg: "",
    video_call_driver: null,
    isHomecare: false,
    alerts: [],
    notes: [],
    alertModal: false,
    alert_status: false,
    dataLoaded: false,
    isPaediatrics: false,
    isObsGynae: false,
    isObsGynaePatient: false,
    isSoapVaccine: {},
    isSoapPaediat: {},
    soapConfig: [],
    paediatric: false,
    vaccination_chart: false,
    isPaediatrician: 0,
    isObsGynaeDoctor: 0,
    soap_mensural_hx: false,
    soap_sexual_hx: false,
    soap_obstetrics_hx: false,
    soap_physical_examination: false,
    checkoutInProgress: false,
    is_recall_doctor: 0,
    multiPartyCalling: false,
    totalNumberOfParticipants: 2,
    allowEdit: true,
    zIndex: 810,
    configuartion_call_driver: 2
  }

  constructor(props) {
    super(props);
    this.receiveInParent = this.receiveInParent.bind(this);
  }

  async componentDidMount() {
    const { match: { params } } = this.props;
    let { hospital_id, patient_id, reference_id } = params;
    reference_id = reference_id.split('?')[0];

    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
    let soapConfig = [];

    let staff_id_is = (LS_SERVICE.get('user_type') === 2) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : LS_SERVICE.get('staff_id');
    if(LS_SERVICE.get('soap_conf_list') && LS_SERVICE.get('soap_conf_list_'+hospital_id)){
      soapConfig = LS_SERVICE.get('soap_conf_list');
    }
    else{
      await axios.post(SOAP_CONFIGURATION_LIST, {
        hospital_id: hospital_id,
        staff_id: staff_id_is,
        speciality_id: LS_SERVICE.get('speciality_id') || null
      }).then(success => {
        this.is_favoriteArr = [];
        if (success.data) {
          soapConfig = success.data;
          LS_SERVICE.set('soap_conf_list', soapConfig);
          LS_SERVICE.set('soap_conf_list_' + hospital_id, soapConfig);
        }
      });
    }
    SoapFlow.hospital(hospital_id)
    const paediatric = SoapFlow.showElement('paediatric', 'child_development');
    const vaccination_chart = SoapFlow.showElement('paediatric', 'vaccination_chart');

    const mensural_hx = SoapFlow.showElement('obsgynae', 'mensural_hx');
    const sexual_hx = SoapFlow.showElement('obsgynae', 'sexual_hx');
    const obstetrics_hx = SoapFlow.showElement('obsgynae', 'obstetrics_hx');
    const physical_examination = SoapFlow.showElement('obsgynae', 'physical_examination');
    this.setState({
      paediatric,
      vaccination_chart,
      soap_mensural_hx: mensural_hx,
      soap_sexual_hx: sexual_hx,
      soap_obstetrics_hx: obstetrics_hx,
      soap_physical_examination: physical_examination
    });
    // }


    /* const checkSoapData = LS_SERVICE.get("soap_conf_list_4");
     this.setState({isSoapVaccine: checkSoapData[2][4],isSoapPaediat: checkSoapData[2][1]});*/


    if (this.props.isHomecare) {
      this.setState({
        hospital_id,
        patient_id,
        reference_id,
        isHomecare: this.props.isHomecare
      }, () => {
        // if (!this.props.isHomecare) {
          this.loadData()
        // }
      })

      if (LS_SERVICE.get('user_type') == USERTYPES.nurse) return;
    }

    let PARAMS = {
      hospital_id: hospital_id,
      speciality_id: LS_SERVICE.get('speciality_id'),
      staff_id: LS_SERVICE.get('staff_id')
    }

    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');

    let is_junior_doctor = LS_SERVICE.get('is_junior_doctor');
    let detail = LS_SERVICE.get('call_patient_detail')
    if (is_junior_doctor != undefined && is_junior_doctor == true && detail != null) {
      PARAMS.staff_id = detail.doctor_id;
      PARAMS.speciality_id=detail.speciality_id;
    }

    var success;
    if(LS_SERVICE.get("hospitalConfigDataResponse")?.data){
      success = LS_SERVICE.get("hospitalConfigDataResponse");
    }
    else{
      success = await Axios.post(HOSPITAL_CONFIG, qs.stringify(PARAMS));
      LS_SERVICE.set("hospitalConfigDataResponse", {data: success.data})
    }
      // .then(success => {
        let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
        let configExit = false;
        hospitalConfigData.forEach((hcd, idx) => {
          if (+hcd.hospital_id == +hospital_id) {
            configExit = true;
            hospitalConfigData[idx].opd_layout = success.data.data.opd_layout;
            hospitalConfigData[idx].pres_modification = success.data.data.pres_modification;
            hospitalConfigData[idx].allow_hours = success.data.data.allow_hours;
            hospitalConfigData[idx].duplicate_order = success.data.data.duplicate_order;

          }
        })
        if (!configExit) {
          const { hospital_id, opd_layout, pres_modification, allow_hours, duplicate_order } = success.data.data;
          const HOS_DATA = {
            hospital_id,
            opd_layout,
            pres_modification,
            allow_hours,
            duplicate_order
          }
          if (LS_SERVICE.has('hospitalConfigData')) {
            hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
            hospitalConfigData = [...hospitalConfigData, HOS_DATA]
          } else {
            hospitalConfigData = [...hospitalConfigData, HOS_DATA];
          }
        }
        LS_SERVICE.set('hospitalConfigData', hospitalConfigData);
        headerService$.soapConfig({ soapConfig: success.data.data.opd_layout });
        this.setState({
          soapConfig: success.data.data.opd_layout,
          pres_modification: success.data.data.pres_modification,
          allow_hours: success.data.data.allow_hours,
          duplicate_order: success.data.data.duplicate_order,
          hospital_id,
          patient_id,
          reference_id,
          isHomecare: this.props.isHomecare || false
        }, () => {
          // if ((!this.state.isHomecare && LS_SERVICE.get('user_type') == USERTYPES.nurse) || LS_SERVICE.get('user_type') == USERTYPES.doctor) {
          const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
          let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
          headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' }); // IMPORTANT TO HIT AS PER THE VIEW
          // }
          this.loadData();
        });
      // })
      // .catch(error => console.log(error))
    // } else {
    //   this.setState({
    //     hospital_id,
    //     patient_id,
    //     reference_id,
    //     isHomecare: this.props.isHomecare || false
    //   }, () => {       
    //     this.loadData();
    //   });
    // }

    const is_mpc_enabled = LS_SERVICE.get('is_mpc_enabled_' + reference_id);
    if (is_mpc_enabled != null && is_mpc_enabled) {
      this.setState({
        multiPartyCalling: true
      });
    }
    this.alllowPatientEdit(hospital_id,reference_id)

    var config = LS_SERVICE.get('emr_server_config');
    var HOSPITAL_PORTAL_INDEPENDENT = config?.hospital_portal_independent || [];
    if ((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(hospital_id)) || HOSPITAL_PORTAL_INDEPENDENT.includes('All')))) {
      this.setState({
        callPatientBtn: false
      })
    }else{
      this.findCallingType(hospital_id ,staff_id_is)
    }

    this.p2pSubscription = callService.status().subscribe(result => {
      if (result && result.video_call_driver == 3 && result?.canEndCall) {
        this.setState({
          canEndCall: result.status,
        });
      }
    });    
  }

  componentWillUnmount() {
    if (this.p2pSubscription) {
        this.p2pSubscription.unsubscribe();
    }
}

  alllowPatientEdit(hospital_id,reference_id){
    let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
    hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);
    let allowEdit = hospitalConfigData ? ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'button') : true;
    this.setState({allowEdit,})
    
  }

  findCallingType = (hospital_id, staff_id) => {
    const PARAMS = {
      facility_id: hospital_id,
      doctor_id: staff_id
    };
    Axios.post(FIND_CALLING_TYPE, PARAMS)
      .then(response => {
        if (response?.data?.data?.call_driver) {
          this.setState({
            configuartion_call_driver: response?.data?.data?.call_driver,
            callPatientBtn:  response?.data?.data?.call_driver == 3 ? false : true
          });
        }
      })
      .catch(error => {
        console.error('Error fetching calling type:', error);
      });
  };

  receiveInParent(callInfo) {
    if (typeof callInfo !== "undefined") {
      this.setState({
        callPatientBtn: callInfo.callPatientBtn,
        callNotificationMsg: callInfo.callNotificationMsg,
        canEndCall: callInfo.canEndCall,
        hungupExistingCall: callInfo.hungupExistingCall || false
      });
    }
  }

  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return null;
  }

  loadData = () => {

    const { patient_id, reference_id, isHomecare } = this.state;

    const is_paediatrician = LS_SERVICE.get('isPaediatrician');

    if (is_paediatrician == 1) {
      this.setState({ isPaediatrician: 1 });
    }

    if (LS_SERVICE.get('isObsGynaeDoctor') == 1) {
      this.setState({ isObsGynaeDoctor: 1 });
    }

    if (isHomecare) {
      this.handleAlertDataLoad();
      Axios.get(HOMECARE_DETAIL({ booking_id: reference_id, is_called_patient: 0 }))
        .then(success => {
          const { appointment } = success.data;
          const { patient } = appointment;
          patient.age = moment().diff(patient.dob, 'years');
          patient.booking_id = reference_id;
          patient.patient_id = patient.id;
          patient.gender = patient.gender ? GENDER[patient.gender] : null;
          // patient.blood_group=patient.blood_group?BLOOD_GROUP[patient.blood_group]:null;

          if (this.props?.getHomecareDetails !== undefined)
            this.props.getHomecareDetails(success.data);

          this.setState({
            patientInfo: patient,
            homecare_appointment_status: appointment.booking_status_code,
            patient_reference_id: reference_id,
            patient: patient,
            video_call_driver: appointment?.is_vc_call || DEFAULT_VC_DRIVER,
            // video_call_driver: 2,
            alert_status: appointment?.patient_alert.status,
            dataLoaded: true
          })
        })
    } else {
      let params ={
        patient_id,
        reference_id: reference_id || '',
        reference_type: LS_SERVICE.get('reference_type') || 0
      }
      // let details= LS_SERVICE.get('call_patient_detail');
      // let is_junior_doctor =LS_SERVICE.get('is_junior_doctor');
      // if(is_junior_doctor == true &&  is_junior_doctor != null && is_junior_doctor !=undefined){
      //   params.doctor_id=details.doctor_id;
      // }
      Axios.get(PATIENT_INFO(params))
        .then(response => {
          const results = response.data.data.results || null;

          const patientInfo = {
            ...response.data.data.results,
            qms_token_id: response.data.data.results.qmsToken ? response.data.data.results.qmsToken.qms_token_id : null,
          }

          if (parseInt(patientInfo.age) <= 17) {
            this.setState({ isPaediatrics: true });
            LS_SERVICE.set('isPaediatrics', true);
          } else {
            LS_SERVICE.set('isPaediatrics', false);
          }

          if (results.gender == "Female") {
            this.setState({ isObsGynaePatient: true });
            LS_SERVICE.set("isObsGynaePatient", true);
          } else {
            LS_SERVICE.set("isObsGynaePatient", false);
          }

          LS_SERVICE.set('gender', results.gender);
          LS_SERVICE.set('dob', results.dob);

          const is_recall_doctor = response.data.data.results?.qmsToken?.is_recall_doctor ? response.data.data.results?.qmsToken?.is_recall_doctor : 0
          this.setState({
            dataLoaded: true,
            patientInfo,
            patient_reference_id: response.data.data.results.qmsToken != undefined ? response.data.data.results.qmsToken.qms_token_id : null,
            // patient_reference_id: LS_SERVICE.get('call_patient_qms_token_id')
            patient: {
              mobile_no: results.mobile_no != null ? results.mobile_no : null,
              patient_id: results.patient_id != null ? results.patient_id : null,
              booking_id: results.qmsToken != null ? results.qmsToken.Booking.booking_id : null,
              patient_name: results.first_name + (results.last_name != null ? ' ' + results.last_name : ''),
              // below info is for quickblox
              full_name: results.first_name + (results.last_name != null ? ' ' + results.last_name : ''),
              // id: results.qmsToken != null ? results.qmsToken.Booking.booking_id : null,
              id: results.patient_id != null ? results.patient_id : null,
              number: results.mobile_no != null ? results.mobile_no : null,
            },
            video_call_driver: response.data.data.results.qmsToken ? response.data.data.results.qmsToken.call_driver : DEFAULT_VC_DRIVER,
            // video_call_driver: 2,
            is_recall_doctor
          });
        })
     }
  }


  pauseOPD = () => {
    const PARAMS = {
      reference_id: this.state.patient_reference_id,
      reference_type: LS_SERVICE.get('reference_type')
    }
    Axios.post(PAUSEOPD, qs.stringify(PARAMS))
      .then(response => {
        this.props.history.push('/patient-queue/' + LS_SERVICE.get('slot_hospital_id'));
      });
  }

  async openAudioCallPopup() {
    this.setState({ audioCallModal: true });
  }

   async callHasStarted() {

      const PARAMS = {
        reference_id: this.state.patient_reference_id,
       // patient_id: this.state.patient.patient_id
      }
      return await Axios.post(
        AUDIO_VIDEO_CALL_STARTED,
        PARAMS,
        { 'Content-Type': 'application/json' }
      )
      .then((result) => {
        return result.data.status;
      })
      .catch((error) => {
        return false;
      });
    }

  async audioCall() {

    const { isHomecare, reference_id, hospital_id } = this.state

    const PARAMS = {
      booking_id: reference_id,
      is_homecare: isHomecare
    }

    let callRes = await this.callHasStarted()
    if (callRes) {
      var config = LS_SERVICE.get('emr_server_config');
      var HOSPITAL_PORTAL_INDEPENDENT = config?.hospital_portal_independent || [];
      //console.log("Audo-call-response", callRes)
      if ((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(hospital_id)) || HOSPITAL_PORTAL_INDEPENDENT.includes('All')))) {
        await Axios.post(
          HOSPITAL_PORTAL_INDEPENDENT_VOICE_CALL,
          PARAMS,
          { 'Content-Type': 'application/json' }
        )
      } else {
        await Axios.post(
          AUDIO_CALL,
          PARAMS,
          { 'Content-Type': 'application/json' }
        )
      }

      this.setState({ audioCallModal: false });
    } else {
      console.log("Call Start flag has not been updated, Please try again")
    }
  }

  videoCall() {
    const {multiPartyCalling, hospital_id, totalNumberOfParticipants, configuartion_call_driver} = this.state;
    const url = this.props.location.pathname;
    const floatingDialog = document.getElementById('floating_vc_dialog');
    if (floatingDialog != null) {
      floatingDialog.setAttribute('data-host', url);
    }
    //commented for  p2p 100ms

    var config = LS_SERVICE.get('emr_server_config');
    var HOSPITAL_PORTAL_INDEPENDENT = config?.hospital_portal_independent || [];
    if ((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(hospital_id)) || HOSPITAL_PORTAL_INDEPENDENT.includes('All')))) {
      //only for Sukoon
      callService.init({ video_call_driver: 3, start: true, is_homecarecall: this.state.isHomecare, booking_id: this.state.reference_id });
    } else {
      if(configuartion_call_driver == 3){
        callService.init({ video_call_driver: 3, start: true, is_homecarecall: this.state.isHomecare, booking_id: this.state.reference_id });
      }
      else if (multiPartyCalling) {
        MultiPartyCallService.init({ start: true, is_homecarecall: this.state.isHomecare, booking_id: this.state.reference_id, hospital_id: hospital_id, type: 'call_initiated_by_doctor', totalParticipants: totalNumberOfParticipants });
      } else {
        callService.init({ start: true, is_homecarecall: this.state.isHomecare, booking_id: this.state.reference_id });
      }
    }
  }

  endCall() {
    const {multiPartyCalling,hospital_id} = this.state;

    var config = LS_SERVICE.get('emr_server_config');
    var HOSPITAL_PORTAL_INDEPENDENT = config?.hospital_portal_independent || [];
    if ((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(hospital_id)) || HOSPITAL_PORTAL_INDEPENDENT.includes('All')))) {
      //only for Sukoon
      callService.init({ video_call_driver: 3, end: true, is_homecarecall: this.state.isHomecare, booking_id: this.state.reference_id });
    } else {
      if (multiPartyCalling) {
        MultiPartyCallService.init({ end: true, is_homecarecall: this.state.isHomecare, booking_id: this.state.reference_id });
      } else {
        callService.init({ end: true, is_homecarecall: this.state.isHomecare, booking_id: this.state.reference_id });
      }
    }
  }

  getSeparator(value) {
    if (value && value != null) {
      return ' | ';
    } else {
      return null;
    }
  }

  handleDate = (data) => {
    if (Object.keys(data?.todaysVisit).length > 0) {
      var a = moment().format('YYYY-MM-DD');
      var b = moment(data.todaysVisit.created_at).format('YYYY-MM-DD');
      if (a == b) return true;
      // if (b.diff(a) > 0) return true;
      return false;
    } else return false;
  }

  handleAlertDataLoad = (alertClicked = false) => {

    const { is_doctor, reference_id } = this.state;

    Axios.get(HOMECARE_CONSULT_ALERT({ is_doctor, booking_id: reference_id }))
      .then((Response) => {
        const data = Response.data;
        const { alerts, notes } = data;
        this.setState({
          alerts, notes
        }, () => {
          if (alertClicked) {
            this.setState({
              alertModal: !this.state.alertModal
            })
          }
        });
      })
  }

  handlePatientAlertPopup = e => {
    e !== undefined && e.preventDefault();
    const { alertModal } = this.state;
    if (!alertModal) {
      this.handleAlertDataLoad(true);
    } else {
      this.setState({ alertModal: !alertModal })
    }
  }

  handleOnChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  clearAlertDisabled = (data, idx) => {
    if ((this.state[`alert_data${data.alert_id}${idx}`] === undefined) || (this.state[`alert_data${data.alert_id}${idx}`]?.length === 0)) return true;
    if ((this.state[`alert_data${data.alert_id}${idx}`])?.length > 0) return false;
  }

  handleAlertSubmit = (e, data, idx) => {
    e.preventDefault();
    // const target = e.target;
    const { is_doctor } = this.state;
    const notes_data = this.state[`alert_data${data.alert_id}${idx}`];

    const PARAMS = {
      clear_alert: 1, // 1 means save and clear 0 means save only
      alert_id: data.alert_id,
      booking_id: data.test_booking_id,
      notes: notes_data
    }

    Axios.post(HOMECARE_SAVE_ALERT({ is_doctor }), PARAMS)
      .then((Response) => {

        let count = LS_SERVICE.get('patient_alert_count');
        count--;
        LS_SERVICE.set('patient_alert_count', count);

        this.setState({
          [`alert_data${data.alert_id}${idx}`]: ""
        }, () => {
          this.loadData();
          this.handlePatientAlertPopup();
        })

        let audio = document.getElementById('patient_alarm');
        audio.pause();
      })

  }

  // getPatientAge(dob) {
  //     var ageM = moment().diff(moment(dob, 'YYYY-MM-DD'), 'M');
  //     var ageY = ageM / 12;
  //     ageY = Math.floor(ageY)
  //     var ageText = '';
  //     if (ageY < 1)
  //         ageText = ageM + ' Month' + (ageM == 1 ? '' : 's');
  //     else if (ageY >= 1)
  //         ageText = ageY + ' Year' + (ageY == 1 ? '' : 's');
  //     return ageText;
  // }

  getPatientAge(dob) {
    var ageD = Math.floor(moment().diff(moment(dob, 'YYYY-MM-DD'), 'D') / (1000 * 3600 * 24));
    var ageM = moment().diff(moment(dob, 'YYYY-MM-DD'), 'M');
    var ageY = ageM / 12;
    var remainM = ageM % 12;
    ageY = Math.floor(ageY);
    var ageText = '';
    if (ageM == 0)
      ageText = ageD + ' Day' + (ageD == 1 ? '' : 's');
    else if (ageY < 1)
      ageText = ageM + ' Month' + (ageM == 1 ? '' : 's');
    else if (ageY <= 5) {
      if (remainM != 0) {
        ageText = ageY + ' Year' + (ageY == 1 ? '' : 's ') + remainM + ' Month' + (remainM == 1 ? '' : 's');
      } else {
        ageText = ageY + ' Year' + (ageY == 1 ? '' : 's');
      }
    }
    else
      ageText = ageY + ' Years';
    return ageText;
  }

  async checkOutAppointment(patientInfo) {
    try {
      this.setState({ checkoutInProgress: true });
      await Axios.post(PATIENT_CHECKOUT, {
        booking_id: patientInfo.qms_token_id
      });
      this.setState({ checkoutInProgress: false });
      this.props.history.push('/patient-queue/' + patientInfo.qmsToken.hospital_id);
    } catch (error) {
      this.setState({ checkoutInProgress: false });
      notification.destroy();
      notification.error({
        description: 'Something went wrong, checkout failed, try again!'
      });
    }
  }

  getPatientUhid(patientInfo) {
    const hid = LS_SERVICE.get('slot_hospital_id');
    const hp = HOSPITAL_PREFIX?.hospital_prefix;
    const uhid = (typeof hp[hid] != 'undefined') ? (hp[hid] + patientInfo.uhid) : patientInfo.uhid;
    const his_doctor_id = patientInfo.qmsToken?.his_doctor_id;
    // const uhidLabel = (his_doctor_id !== undefined && his_doctor_id && his_doctor_id !== null) ? <a target="_blank" href={`${EMRD_PDH}?DOCTORID=${his_doctor_id}&HHNO=${patientInfo.uhid}`}>{uhid}</a> : <span className='highlight'>{uhid}</span>;
    return (
      <div className='col-12 text-left'>
        <span className='highlight'>{uhid}</span>
      </div>
    );
  }

    handleMouseEnter = () => {
      this.setState({ zIndex: 812 }); // Set higher z-index on hover
    };
   
    handleMouseLeave = () => {
      this.setState({ zIndex: 810 });
    };

  render() {
    const { patientInfo, patient_reference_id, dataLoaded, patient_id, reference_id, hospital_id, patient, isHomecare, alerts, isPaediatrics, isObsGynaePatient, isPaediatrician, isObsGynaeDoctor, soap_mensural_hx, soap_sexual_hx, soap_obstetrics_hx, soap_physical_examination, checkoutInProgress ,zIndex } = this.state;
    const isSoap = APPS_VARIANTS[LS_SERVICE.get('soapConfig')];
    const REFERENCE_TYPE = LS_SERVICE.get('reference_type') || 0;
    let showCheckout = false;
    const todaysDate = moment().format('YYYY-MM-DD');
    if (typeof patientInfo.visit != 'undefined' && patientInfo?.visit?.is_doctor_checkout == null && LS_SERVICE.get('user_type') == USERTYPES.doctor && patientInfo?.visit?.status != 1) {
      showCheckout = true;
    }
    var called_homecare = LS_SERVICE.get('last_called_homecare');
    if(!patientInfo.uhid && REFERENCE_TYPE == 2 && called_homecare){
      patientInfo.uhid = called_homecare?.patient?.uhid
    }
    var hc_package_name = called_homecare?.test?.title || "";
    var config = LS_SERVICE.get('emr_server_config');
    var HOSPITAL_PORTAL_INDEPENDENT = config?.hospital_portal_independent || [];
    return (
      <>
        <aside id="page_sidebar" 
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={{zIndex: zIndex}}
        >
          <Skeleton loading={!dataLoaded} active size="large" paragraph={{ rows: 10 }}>
            <div id="patient_call_widget" data-pid={patient.patient_id != null ? patient.patient_id : ''}>
              <section className="patient-section p-2">
                {/* <h1>
                  {patientInfo.first_name !== undefined ? patientInfo.first_name : null}&nbsp;{patientInfo.middle_name !== undefined ? patientInfo.middle_name : null}&nbsp;{patientInfo.last_name !== undefined ? patientInfo.last_name : null}
                </h1>
                <p className="small text-muted">
                  {(MYHC_APPTYPE === MHVCMARKETPLACE || MYHC_APPTYPE === MHEA) ? "" : "UHID"}
                  {patientInfo.uhid !== undefined && patientInfo.uhid !== null ? patientInfo.uhid + ' | ' : null}
                  {patientInfo.dob && patientInfo.age !== null ? this.getPatientAge(patientInfo.dob) : null}

                </p>
                <p className="small text-muted">
                  {patientInfo.gender !== undefined && patientInfo.gender !== null ? patientInfo.gender : null}
                  {patientInfo.blood_group !== undefined && patientInfo.blood_group !== null ? this.getSeparator(patientInfo.gender) + 'Blood Group: ' + patientInfo.blood_group : null}
                </p>
                <p className="small text-muted">
                  {patientInfo.mobile_no != undefined && patientInfo.mobile_no?
                   "Mobile No: " + patientInfo.mobile_no
                  : null}
                </p>
              

                {(patientInfo?.qmsToken?.consult_type == 1 || isHomecare) && (
                  <p className="text-primary">
                    {this.state.homecare_appointment_status !== 2 ? 'Appointment Time : ' : null}
                    {!isHomecare ? (
                      patientInfo.qmsToken !== undefined ? (moment(patientInfo.qmsToken.Booking.start_time, 'hh:mm:ss').format('h:mm A')) : null
                    ) : (this.state.homecare_appointment_status !== 2 ? (
                      moment(patient.booking_date).format('h:mm A')
                    ) : null)}
                  </p>
                )}
            

                {showCheckout && typeof patientInfo.qmsToken != 'undefined' && patientInfo?.qmsToken?.consult_type !== 1 && (patientInfo?.qmsToken?.source == 'qms' || CHECKOUT_HOSPITALS_ENABLED.includes(patientInfo?.qmsToken?.hospital_id)) ? <div className="text-center">
                  <button title={checkoutInProgress ? 'Checkout in progress' : ''} onClick={() => this.checkOutAppointment(patientInfo)} disabled={checkoutInProgress} className="checkout-token-card mr-2">CHECK OUT</button>
                </div> : null} */}

                <div className='row new_patient_section'>
                  <div className='col-12 text-left'>
                    <div>
                      <span className='highlight'>{patientInfo.title_name != undefined ? patientInfo.title_name: null}&nbsp;
                        {patientInfo.first_name !== undefined ? patientInfo.first_name : null}&nbsp;{patientInfo.middle_name !== undefined ? patientInfo.middle_name : null}&nbsp;{patientInfo.last_name !== undefined ? patientInfo.last_name : null}
                      </span>
                    </div>
                    <span>
                      {patientInfo.dob && patientInfo.age !== null ? this.getPatientAge(patientInfo.dob) : null}
                      {patientInfo.gender !== undefined && patientInfo.gender !== null ? (patientInfo.gender == 'Male' ? ' (M)' : ' (F)') : null}
                      {patientInfo.blood_group !== undefined && patientInfo.blood_group !== null ? ', ' + patientInfo.blood_group : null}
                    </span>
                  </div>
                  {
                    patientInfo.uhid !== undefined && patientInfo.uhid && patientInfo.uhid !== null
                      ?
                      <>
                        {this.getPatientUhid(patientInfo)}
                      </>
                      :
                      null
                  }
                  {
                    patientInfo.mobile_no != undefined && patientInfo.mobile_no && patientInfo.mobile_no !== null
                      ?
                      <div className='col-12 text-left mt-2'>
                        <span>{patientInfo.isd_code != undefined && patientInfo.isd_code && patientInfo.isd_code !== null ? '+' + patientInfo.isd_code + ' ' : null}{patientInfo.mobile_no}</span>
                      </div>
                      :
                      null
                  }
                  {
                    patientInfo.email != undefined && patientInfo.email && patientInfo.email != null
                      ?
                      <div className='col-12 text-left'>
                        <span>{patientInfo.email}</span>
                      </div>
                      :
                      null
                  }
                  {
                    patientInfo.qmsToken !== undefined 
                  ?
                    <div className='col-5 text-left mt-2'>
                      <div className='row'>
                        <div className='col-12'>
                          <span>Appointment</span>
                        </div>
                        <div className='col-12'>
                          <span className='highlight'>{patientInfo.qmsToken !== undefined ? (moment(patientInfo.qmsToken.Booking.start_time, 'hh:mm:ss').format('h:mm A')) : null}</span>
                        </div>
                      </div>
                    </div>
                  :
                    null
                  }
      
                  {
                    patientInfo?.visit?.status == 1 || patientInfo?.visit?.status == undefined
                  ?
                    null
                  :
                    (
                      showCheckout && typeof patientInfo.qmsToken != 'undefined' && ((CHECKOUT_HOSPITALS_ENABLED.includes(patientInfo?.qmsToken?.hospital_id.toString()) && patientInfo?.qmsToken?.source == 'default') || patientInfo?.qmsToken?.source == 'qms')|| this.state.is_recall_doctor == 1 )
                    ?
                      <div className="col-7 mt-2 d-flex align-self-center float-right">
                        <button title={checkoutInProgress ? 'Checkout in progress' : ''} onClick={() => this.checkOutAppointment(patientInfo)} disabled={checkoutInProgress} className="checkout-token-card">CHECK OUT</button>
                      </div>
                    :
                      null
                  }

                  {
                    LS_SERVICE.get('reference_type') == 2
                  ?
                    <div className='col-12 mt-2 highlight text-left'>
                      {hc_package_name}
                    </div>
                  :
                    null
                  }
                </div>
              </section>

              {/*{patient_reference_id != null && patientInfo.todaysVisit != null ? patientInfo.todaysVisit.type === 'todays_visit' && patientInfo.qmsToken.consult_type == 1 ?*/}
              {
                (patient_reference_id != null && patientInfo.todaysVisit != null) || isHomecare ?
                  (patientInfo?.qmsToken?.consult_type == 1) || isHomecare ?
                    <>
                      {((this.state.homecare_appointment_status !== 2 && isHomecare) || (!isHomecare && patientInfo?.qmsToken?.consult_type == 1) ) ? (
                        <div className="text-center patient-section">
                          <button onClick={() => this.openAudioCallPopup()} title="Call Patient" type="button" id="js-audio-call-to-patient" className="btn btn-sm btn-outline-secondary mt-2">
                            <i style={{ position: 'relative', top: '2px', height: '12px' }} className="icon_phone"></i> AUDIO CALL TO PATIENT
                          </button>

                          {this.state.canEndCall ? <button onClick={() => this.endCall()} title="End Call" type="button" className="btn btn-sm btn-primary mt-2 end-video-call-to-patient">
                            <i style={{ position: 'relative', top: '2px', height: '12px' }} className="icon_video"></i> END CALL
                          </button> : <button disabled={this.state.callPatientBtn} onClick={() => this.videoCall()} title="Call Patient" type="button" id="js-video-call-to-patient" className="btn btn-sm btn-primary mt-2 ">
                            <i style={{ position: 'relative', top: '2px', height: '12px' }} className="icon_video"></i> VIDEO CALL TO PATIENT
                          </button>}

                          <div className="clearfix"></div>

                          {this.state.callNotificationMsg != null ? <span style={{ lineHeight: '20px' }} className="call-message mt-4">{this.state.callNotificationMsg}</span> : null}

                          <div className="mb-2 mt-2">
                            {patient.mobile_no != null && this.state.video_call_driver == 2 ? (
                              <VideoConferencing
                                patient={this.state.patient}
                                booking_id={patient.booking_id}
                                ishomecare={isHomecare}
                                location={this.props.location}
                                onChange={this.receiveInParent}
                              />
                            ) : null}

                            {patient.mobile_no != null && this.state.video_call_driver == 1 ? (
                              <>
                                <QbVideoConferencing
                                  patient={this.state.patient}
                                  booking_id={patient.booking_id}
                                  ishomecare={isHomecare}
                                  // location={this.props.location}
                                  onChange={this.receiveInParent}
                                />
                              </>
                            ) : null}
                          </div>

                          <Modal
                            title={false}
                            closable={false}
                            visible={this.state.audioCallModal}
                            footer={false}
                          >
                            <div className="row">
                              <div className="col-12 my-3 text-center">
                                <h6 id="pending-patients-count">You will receive a call on your mobile phone, please answer to connect with the patient.</h6>
                              </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                              <div className="btn btn-outline-secondary px-4" onClick={() => this.audioCall()}>CONNECT WITH PATIENT</div>
                              <div className="btn btn-primary px-4" onClick={() => this.setState({ audioCallModal: false })}>CANCEL</div>
                            </div>
                          </Modal>

                        </div>
                      ) : null}
                    </>
                    :
                    // <section className="appointment-section">
                    //   {
                    //     <p className="text-primary">
                    //       Appointment Time: {patientInfo.qmsToken !== undefined ? (moment(patientInfo.qmsToken.Booking.start_time, 'hh:mm:ss').format('h:mm A')) : null}
                    //     </p>
                    //   }
                    //   <p className="text-primary">
                    //     Arrived: {patientInfo.qmsToken !== undefined ? (moment(this.state.patientInfo.qmsToken.token_time, 'hh:mm:ss').format('h:mm A')) : null}
                    //   </p>
                    //   {patientInfo.qmsToken !== undefined ? (
                    //     <div className="my-2">
                    //       <label className="qms-token">{patientInfo.qmsToken.token}</label>
                    //     </div>
                    //   ) : ''}

                    //   {LS_SERVICE.get('user_type') == USERTYPES.doctor && patientInfo.todaysVisit.status != 1 ? <div className="mt-3 pause-opd-button">
                    //     <a className="btn btn-sm btn-outline-primary text-danger" onClick={this.pauseOPD}>
                    //       PAUSE OPD
                    //     </a>
                    //   </div> : null}
                    // </section> 
                    null
                  :
                  null
              }

              <section className="menu-section">
                {!isHomecare ? (
                  <ul className="sidemenu">

                    {
                      LS_SERVICE.get('user_type') !== USERTYPES.nurse && dataLoaded && patientInfo != undefined && typeof patientInfo.todaysVisit != undefined && this.handleDate(patientInfo) ? (
                        <>
                          {
                            LS_SERVICE.get('soapConfig') != 'soap' || (LS_SERVICE.get('soapConfig') == 'soap' && LS_SERVICE.get('soap_type') == 'soap')
                              ?
                              <li className="sidemenu-item ">
                                <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                                  pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                                  state: { patient: patientInfo }
                                }}
                                  isActive={(match, location) => {
                                    if (match) return true;
                                    const { pathname } = location;
                                    if (pathname.includes('subjective') || pathname.includes('objective') || pathname.includes('copy-visit') ||
                                      pathname.includes('view-prescription') || pathname.includes('select') || pathname.includes('plan') ||
                                      pathname.includes('assessment') || pathname.includes('referral')) return true;
                                  }}
                                >
                                  {patientInfo.todaysVisit.type == "todays_visit" && moment(patientInfo?.qmsToken?.Booking?.booking_date).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') ? "Today's Visit" : "Visit Details"}
                                </NavLink>
                              </li>
                              :
                              <li className="sidemenu-item ">
                                <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                                  pathname: `/patient/soapss/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                                  state: { patient: patientInfo }

                                }}>
                                  {patientInfo.todaysVisit.type == "todays_visit" && moment(patientInfo?.qmsToken?.Booking?.booking_date).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') ? "Today's Visit" : "Visit Details"}
                                </NavLink>
                              </li>
                          }
                        </>
                      ) : null}

                    {
                      LS_SERVICE.get('user_type') == USERTYPES.nurse ? (
                        <li className="sidemenu-item ">
                          <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                            pathname: `/patient/todays-visit/objective/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                            state: { patient: patientInfo }
                          }}
                            isActive={(match, location) => {
                              if (match) return true;
                              const { pathname } = location;
                              if (pathname.includes('objective')) return true;
                            }}
                          >
                            Today's Visit
                          </NavLink>
                        </li>
                      ) : null
                    }

                    {LS_SERVICE.get('user_type') == USERTYPES.nurse ? (
                      <li className="sidemenu-item">
                        <a disabled className="sidemenu-link" style={{ backgroundColor: "#b3afaf", color: "#fff" }}>Past Visit</a>
                      </li>
                    ) : (
                      <li className="sidemenu-item">
                        <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                          pathname: `/patient/past-visit/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                          state: { patient: patientInfo }
                        }}
                          isActive={(match, location) => {
                            if (match) return true;
                            const { pathname } = location;
                            if (pathname.includes('past-visit/')) return true;
                          }}
                        >Past Visit</NavLink>
                      </li>)}

                    {APPS_FEATURES_LIST.aside_all_prescriptions ? (

                      LS_SERVICE.get('user_type') == USERTYPES.nurse ? (
                        <li className="sidemenu-item">
                          <a disabled className="sidemenu-link" style={{ backgroundColor: "#b3afaf", color: "#fff" }}>All Prescriptions</a>
                        </li>
                      ) : (
                        <>
                        <li className="sidemenu-item">
                          <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                            pathname: `/patient/past-visit-other/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                            state: { patient: patientInfo }
                          }}>Past Prescriptions</NavLink>
                        </li>
                        </>)
                    ) : null}
                    {
                      ENABLE_MHC_PRESCRIPTION?.[EMR_APP_ENV]?.includes(parseInt(hospital_id)) && EMR_APP_ENV == "mhea_paras"
                    ?
                      <li className="sidemenu-item">
                        <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                          pathname: `/patient/past-prescriptions/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}/${patientInfo.uhid}`,
                          state: { patient: patientInfo }
                        }}>Prescriptions<span style={{ fontSize: "12px" }}><br/>(Till 30 Sept 2023)</span></NavLink>
                      </li>
                    :
                      null
                    }
                    {
                      ENABLE_MHC_PRESCRIPTION?.[EMR_APP_ENV]?.includes('All') && EMR_APP_ENV == "mhea_sakra"
                    ?
                      <li className="sidemenu-item">
                        <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                          pathname: `/patient/past-prescriptions/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}/${patientInfo.uhid}`,
                          state: { patient: patientInfo }
                        }}>Prescriptions<span style={{ fontSize: "12px" }}><br/>(Till 30 June 2024)</span></NavLink>
                      </li>
                    :
                      null
                    }

                    {/* {APPS_FEATURES_LIST.aside_all_prescriptions ? (
                      <li className="sidemenu-item">
                        <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                          pathname: `/patient/past-visit-other/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                          state: { patient: patientInfo }
                        }}>All Prescriptions</NavLink>
                      </li>
                    ) : null} */}
                    {
                      ((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All'))))
                    ?
                      null
                    :
                      <>
                        {APPS_FEATURES_LIST.aside_test_result ? (
                          <li className="sidemenu-item">
                            <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                              pathname: `/patient/test-results/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                              state: { patient: patientInfo }
                            }}>Test Results</NavLink>
                          </li>
                        ) : null}
                      </>
                    }

                    <li className="sidemenu-item">
                      <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                        pathname: `/patient/history/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                        state: { patient: patientInfo }
                      }}>History</NavLink>
                    </li>

                    {
                      ((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All'))))
                    ?
                      null
                    :
                      <>
                        <li className="sidemenu-item ">
                          <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                            pathname: `/ecg/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                            state: { patient: patientInfo }
                          }}>ECG</NavLink>
                        </li>
                      </>
                    }

                    {isSoap?.allergies ? (
                      <li className="sidemenu-item">
                        <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                          pathname: `/allergies/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                          state: { patient: patientInfo }
                        }}>Allergies &amp; Conditions</NavLink>
                      </li>
                    ) : null}

                    {
                      ((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All'))))
                    ?
                      null
                    :
                      <>
                        {APPS_FEATURES_LIST.phr ? <li className="sidemenu-item">
                          <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                            pathname: `/patient/health-records//${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                            state: { patient: patientInfo }
                          }}>PHR</NavLink>
                        </li> : null}
                      </>
                    }

                    {isSoap?.paediatrics ?
                      <>
                        {isPaediatrics ?
                          <li className="sidemenu-item ">
                            <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                              pathname: `/patient/paediatrics/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                              state: { patient: patientInfo }
                            }}>Paediatrics</NavLink>
                          </li> : null}
                      </>
                      : null}

                    {isSoap?.obsgynae ? (
                      <>
                        {(isObsGynaePatient == true && isObsGynaeDoctor === 1 &&
                          (soap_mensural_hx || soap_sexual_hx || soap_obstetrics_hx || soap_physical_examination)
                        ) ? (
                          <li className="sidemenu-item ">
                            <NavLink
                              exact={true}
                              activeClassName="active"
                              className="sidemenu-link"
                              to={{
                                pathname: `/patient/obs-gynae/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id ||
                                  reference_id
                                  }`,
                                state: {
                                  patient: patientInfo
                                },
                              }}
                            >
                              Obstetrics &amp; Gynae Hx
                            </NavLink>
                          </li>
                        ) : null}
                      </>
                    ) : null}

                    {isSoap?.vaccination || LS_SERVICE.get('user_type') == USERTYPES.nurse ?
                      <>
                        { <li className="sidemenu-item ">
                          <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                            pathname: `/patient/vaccination-record/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                            state: { patient: patientInfo }
                          }}>Vaccination Chart</NavLink>
                        </li> }
                      </>
                    : null}
                <li className="sidemenu-item ">
                  <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                  pathname: `/patient/diagnosis-report/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                  state: { patient: patientInfo, diagnosisReport: true }
                  }}>Reports</NavLink>
                </li>
                <li className="sidemenu-item">
                  <NavLink exact={true} activeClassName='active' className="sidemenu-link"
                    to={{
                      pathname: `/patient/mrd/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                      state: { patient: patientInfo, diagnosisType: true }
                    }}>Patient Medical Record</NavLink>
                </li>
                <li className="sidemenu-item ">
                  <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                  pathname: `/patient/discharge-summary/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                  state: { patient: patientInfo, dischargeReport: true }
                  }}>Discharge Summary</NavLink>
                </li>
                {
                    LS_SERVICE.get('user_type') == USERTYPES.nurse
                ?
                  null
                :
                  <>
                    {
                      ((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All'))))
                    ?
                      <li className="sidemenu-item">
                        <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                              pathname: `/patient/case-summary/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                          }}>Case Summary</NavLink>
                      </li>
                    :
                      <li className="sidemenu-item">
                        <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                              pathname: `/patient/case-summary/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                          }}>Case Summary</NavLink>
                      </li>
                    }
                  </>
                }
                <li className="sidemenu-item">
                  <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                        pathname: `/patient/shared-internal-notes/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                    }}>Shared Internal Notes</NavLink>
                      {/* <a href="/patient/shared-internal-notes" className="sidemenu-link">Shared Internal Notes</a> */}
                </li>
                <li className="sidemenu-item">
                  <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                        pathname: `/patient/form-builder/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                    }}>Clinical Forms</NavLink>
                      {/* <a href="/patient/shared-internal-notes" className="sidemenu-link">Shared Internal Notes</a> */}
                </li>
                    {/* <!-- <li className="sidemenu-item ">
                        <a href="/patient/immunization" className="sidemenu-link">Immunization</a>
                        </li>

                        <li className="sidemenu-item ">
                        <a href="/patient/insurance" className="sidemenu-link">Insurance</a>
                      </li> --> */}
                    {/* <li className="sidemenu-item ">
                      <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                        pathname: `/patient/internal-notes-new/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                        state: { patient: patientInfo }
                      }}>Internal Notes</NavLink>
                    </li> */}
                    {isSoap?.obsgynae ? (
                      <>
                        {(
                          isObsGynaePatient == true
                          // && isObsGynaeDoctor === 1
                          // && (soap_mensural_hx || soap_sexual_hx || soap_obstetrics_hx || soap_physical_examination)
                          )
                          ? (
                            <li className="sidemenu-item">
                              <NavLink exact={true} activeClassName='active' className="sidemenu-link"
                                to={{
                                  pathname: `/patient/anc-card/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                                  state: { patient: patientInfo, diagnosisType: true }
                                }}>ANC Card</NavLink>
                            </li>
                          ) : null}
                      </>
                    ) : null}
                  </ul>
                ) : (
                  <ul className="sidemenu">
                    <li className="sidemenu-item">
                      <a href="#!"
                        className={`sidemenu-link sidemenu-alert ${dataLoaded ? (this.state.alert_status ? "active" : "") : ""}`}
                        onClick={this.handlePatientAlertPopup}
                      >
                        Alerts
                      </a>
                    </li>
                    <li className="sidemenu-item">
                      <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                        pathname: `/patient/homecare/details/${hospital_id}/${patient_id}/${HOMECARE_REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                        state: { isCallToday: 0 }
                      }}
                        isActive={(match, location) => {
                          if (match) return true;
                          const { pathname } = location;
                          if (pathname.includes('details')) return true;
                        }}
                      >Patient Details</NavLink>
                    </li>
                    <li className="sidemenu-item">
                      <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                        pathname: `/patient/homecare/progress-notes/${hospital_id}/${patient_id}/${HOMECARE_REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                        state: { patient: patientInfo }
                      }}>Progress Notes</NavLink>
                    </li>
                    <li className="sidemenu-item">
                      <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                        pathname: `/patient/homecare/allergies/${hospital_id}/${patient_id}/${HOMECARE_REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                        state: { patient: patientInfo }
                      }}>Allergies</NavLink>
                    </li>
                    {/* <li className="sidemenu-item">
                        <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                            pathname: `/patient/homecare/allergies/${hospital_id}/${patient_id}/${HOMECARE_REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                            state: { patient: patientInfo }
                        }}>Existing Conditions</NavLink>
                    </li> */}
                    <li className="sidemenu-item">
                      <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                        pathname: `/patient/homecare/vitals/${hospital_id}/${patient_id}/${HOMECARE_REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                        state: { patient: patientInfo }
                      }}
                        isActive={(match, location) => {
                          if (match) return true;
                          const { pathname } = location;
                          if (pathname.includes('homecare/vitals')) return true;
                        }}
                      >Patient Vitals</NavLink>
                    </li>

                    <li className="sidemenu-item">
                      <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                        pathname: `/patient/homecare/pastvisit/${hospital_id}/${patient_id}/${HOMECARE_REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                        state: { patient: patientInfo }
                      }}>Past Prescriptions</NavLink>

                    </li>

                    {/* <li className="sidemenu-item">
                      <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                        pathname: `/patient/homecare/internal-notes/${hospital_id}/${patient_id}/${HOMECARE_REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                        state: { patient: patientInfo }
                      }}>Internal Notes</NavLink>

                    </li> */}
                    {(!LS_SERVICE.has('nurse_homecare') && !LS_SERVICE.get('nurse_homecare')) && (
                      <li className="sidemenu-item ">
                        <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                          pathname: `/patient/homecare/ecg/${hospital_id}/${patient_id}/${HOMECARE_REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                          state: { patient: patientInfo, ecgType: true }
                        }}>ECG</NavLink>
                      </li>
                    )}
                    {(!LS_SERVICE.has('nurse_homecare') && !LS_SERVICE.get('nurse_homecare') && LS_SERVICE.get('user_type') == USERTYPES.doctor) && (
                      <li className="sidemenu-item">
                        <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                          pathname: `/patient/homecare/case-summary/${hospital_id}/${patient_id}/${HOMECARE_REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                          state: { patient: patientInfo }
                        }}>Homecare Case Summary</NavLink>
                      </li>
                    )}
                    <li className="sidemenu-item">
                      <NavLink exact={true} activeClassName='active' className="sidemenu-link"
                        to={{
                          pathname: `/patient/mrd/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                          state: { patient: patientInfo, diagnosisType: true }
                        }}>Patient Medical Record</NavLink>
                    </li>
                    <li className="sidemenu-item ">
                      <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                      pathname: `/patient/discharge-summary/${hospital_id}/${patient_id}/${HOMECARE_REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                      state: { patient: patientInfo, dischargeReport: true }
                      }}>Discharge Summary</NavLink>
                    </li>
                    {(!LS_SERVICE.has('nurse_homecare') && !LS_SERVICE.get('nurse_homecare')) && (
                      <li className="sidemenu-item">
                        <NavLink exact={true} activeClassName='active' className="sidemenu-link" to={{
                          pathname: `/patient/homecare/diagnosis-report/${hospital_id}/${patient_id}/${HOMECARE_REFERENCE_TYPE}/${patient_reference_id || reference_id}`,
                          state: { patient: patientInfo, diagnosisType: true }
                        }}>Reports</NavLink>
                      </li>
                    )}

                  </ul>

                )}
              </section>
            </div>
          </Skeleton>
        </aside>

        {/* || ALERT MODAL */}
        <Modal
          title="Patient Alerts"
          visible={this.state.alertModal}
          onCancel={(e) => this.setState({ alertModal: false })}
          className="alert-modal"
          style={{ top: 40 }}
          width={890}
          footer={false}
        >
          <div className="row">
            <div className="container">
              <div className="alert-modal-subheader radius row">
                <div className="col-md-9 col-6">
                  <p className="pb-0 m-0">
                    <span className="font-weight-bold">
                      {patient.first_name}{" "}{patient.last_name !== null && patient.last_name}
                    </span> {patient.age}, {GETGENDER(patient.gender)} {patient.uhid && ('| ' + patient.uhid)}</p>
                </div>
              </div>
              <div className="alert-modal-notes-wrapper">
                {this.state.alerts.length > 0 && (
                  this.state.alerts.map((al, i) => (
                    <div key={i}>
                      <div className="my-2 row">
                        <div className="col-md-12 textis-primary">
                          {moment(al.alert_date + 'T' + al.alert_time).format('DD MMM YYYY | h:mm A')}
                        </div>
                      </div>
                      <div className="my-2 row">
                        <div className="pr-0 col-md-9 col-9">
                          <div className="patient-textwrapper border radius">
                            <textarea className="border-0 w-100 p-2" rows="2" cols="10"
                              onChange={this.handleOnChange} value={this.state[`alert_data${al.alert_id}${i}`]}
                              placeholder="Type here" name={`alert_data${al.alert_id}${i}`} />
                          </div>
                        </div>
                        <div className="text-center p-0 mt-2 col-md-3 col-3">
                          <button className="btn btn-outline-primary"
                            disabled={this.clearAlertDisabled(al, i) || this.state.homecare_appointment_status === 2}
                            onClick={e => this.handleAlertSubmit(e, al, i)} >
                            SAVE &amp; CLEAR ALERT
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
                {this.state.notes.length > 0 && (
                  this.state.notes.map((no, i) => (
                    <div key={i}>
                      <div className="my-2 row">
                        <div className="col-md-3 textis-primary">
                          {moment(no.alert_date + 'T' + no.alert_time).format('DD MMM YYYY | h:mm A')}
                        </div>
                        <div className="text-right p-0 ml-2 col-md-6">
                          <div className="row">
                            <div className="col">
                              <b className="text-grey">{no.created_by}
                                <span>
                                  <time className="ml-2 font-weight-normal m-0 pr-2"> {moment(no.created_at).format('DD MMM YYYY | h:mm A')}</time>
                                </span>
                              </b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="my-2 row">
                        <div className="pr-0 col-md-9 col-6">
                          <div className="patient-textwrapper border radius">
                            <p className="text-feild1 patient-vitaltextfield p-3 mb-0">{no.notes}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}

                {(this.state.alerts.length === 0 && this.state.notes.length === 0) && (
                  <div className="my-2 row">
                    <div className="col-12">
                      <div className="patient-textwrapper border radius">
                        <p className="text-feild1 patient-vitaltextfield p-3 mb-0">
                          No Data
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

export default withRouter(AsideLeft);
