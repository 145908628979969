import React, { Component } from "react";
import Axios from "axios";
import SubHeader from "../../components/subheader/subheader";
import MenstrualHx from "./gynae-menstrual-hx";
import SexualHx from "./gynae-sexual-hx";
import ObstetricHx from "./gynae-obstetric-hx";
import PhysicalExam from "./gynae-physical-exam";
import GynaeTabs from "./gynae-tabs";
import { PATIENT_DATA, ISALLOWEDITSOAP, MENSTRUAL_HISTORY_LIST, GPALE_CURRENT_PREGNANCY_DATA,TOGGLE_PRACTICE } from "../../utils/constant";
import { Modal } from 'antd';
import "antd/dist/antd.css";
import {
    jQueryLabels,
    FixedCta,
    AntSelectLabels,
    AntDisabledLabels,
    onlynumbers,
} from "../../utils/jQueryLabels";
import AsideLeft from "../../components/aside/asideleft";
import LS_SERVICE from "../../utils/localStorage";
import API_SERVICE from '../../utils/apiService';
import MyHcSpeechRecognition from "../../pages/speech-to-text/speech-recognition";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "react-datepicker/dist/react-datepicker.css";
import { practiceService, gpaleDataService, lactatingService, lmpDataService } from '../../utils/rxjs-sharing';

class Gyanecology extends Component {
    constructor(props) {
        super(props);
        
        if(LS_SERVICE.get('user_type') === 3){
            var menstrual_tab_available = true;
            var sexual_tab_available = true;
            var obs_tab_available = true;
            var physical_tab_available = true;
        }else{
            const pageListArr = LS_SERVICE.get("goToSoapPatientPageList");
            let mensuralHxIndex = pageListArr.indexOf('mensural_hx');
            let sexualHxIndex = pageListArr.indexOf('sexual_hx');
            let obstetricsHxIndex = pageListArr.indexOf('obstetrics_hx');
            let physicalExaminationIndex = pageListArr.indexOf('physical_examination');

            var menstrual_tab_available = mensuralHxIndex != -1 ? true : false;
            var sexual_tab_available = sexualHxIndex != -1 ? true : false;
            var obs_tab_available = obstetricsHxIndex != -1 ? true : false;
            var physical_tab_available = physicalExaminationIndex != -1 ? true : false;
        }
        
        let active_tab = menstrual_tab_available ? '1' : (sexual_tab_available ? '2' : (obs_tab_available ? '3' : (physical_tab_available ? '4' : '1')));
        
        this.state = {
            isPracticing: false,
            isPracticingModal: false,
            allowEdit: true,
            patient: null,
            patientData: null,
            gpale_values: {gravidity: null, parity: null, abortion: null, live_birth: null, ectopic: null},
            current_pregnancy_data: {pregnant: null, testsDone: [], lmpWeeks: null, lmpDays: null, usgWeeks: null, usgDays: null, usgDate: null, lactating: false, remarksForCurrentPregnancy : null, riskFactors : [], riskFactorsRemarks : null, no_of_fetus: null},
            no_obs_history: false,
            gpale_loaded: false,
            tabs_available: {
                menstrual_tab_available: menstrual_tab_available, 
                sexual_tab_available: sexual_tab_available, 
                obs_tab_available: obs_tab_available, 
                physical_tab_available: physical_tab_available
            },
            activeTab: active_tab,
            menstrualHistoryList : []
        };

        this.childTabs = React.createRef();
        this.childPhysicalExam = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        const {
            match: { params },
        } = props;
        
        return {
            patient: {
                patient_id: params.patient_id,
                reference_id: params.reference_id,
                reference_type: params.reference_type,
                doctor_id: (LS_SERVICE.get('user_type') === 2)? LS_SERVICE.get('selectedDoctorIdAppoinement') : LS_SERVICE.get('staff_id') ,
            },
        };
    }

    componentDidMount = async() => {
        this.menstrualList();
        
        const { match: { params }, location: { pathname } } = this.props;

        const { hospital_id, patient_id, reference_id } = params;

        const isPracticingHospitalId =
            LS_SERVICE.get("practicing")?.hospital_id;

        if (+isPracticingHospitalId === +hospital_id) {
            this.setState({
                isPracticing: true,
            });
        }

        const patientUrlData = {
            hospital_id,
            patient_id,
            qms_token_id: reference_id,
        };
        let hospitalConfigData = LS_SERVICE.get("hospitalConfigData") ?? [];
        hospitalConfigData = hospitalConfigData.find((item) => item.hospital_id == hospital_id);

        let allowEdit = hospitalConfigData ? ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'button') : true;

        if (!pathname.includes('objective')) {
            allowEdit = false;
        }

        if(LS_SERVICE.get('user_type') === 2){
            allowEdit = true; 
        }
        
        this.setState(
            {
                hospital_id,
                patient_id,
                patientUrlData,
                allowEdit,
                pathname
            },
            () => {
                if (LS_SERVICE.has("hospitalConfigData")) {
                    const hospitalConfigData =
                        LS_SERVICE.get("hospitalConfigData");
                    let hospitalConfigObj = hospitalConfigData.find(
                        (obj) => obj.hospital_id == hospital_id
                    );
                    this.getPatientData();
                }
                this.getGpaleValues();
            }
        );

        jQueryLabels();
        FixedCta();
        onlynumbers();
        AntSelectLabels();
        AntDisabledLabels();

        this.subscription = gpaleDataService.status().subscribe(data => {
            this.setState({ gpale_values: data });
        });
        this.subscription_lactating = lactatingService.status().subscribe(data => {
            this.setState(prevState => {
                const prevItems = prevState.current_pregnancy_data;
                prevItems.lactating = data;
                return {current_pregnancy_data: prevItems};
              });
        });
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.subscription_lactating) {
            this.subscription_lactating.unsubscribe();
        }
    }

    getPatientData = (_) => {
        const {
            match: { params },
        } = this.props;
        const { patient_id } = params;
        Axios.get(PATIENT_DATA({ patient_id }))
            .then((success) => {
                const patientData = {
                    ...success.data.data.results,
                    qms_token_id: success.data.data.results.qmsToken
                        ? success.data.data.results.qmsToken.qms_token_id
                        : null,
                };
                this.setState({
                    patientData,
                });
            })
            .catch((err) => console.log(err));
    };

    //MENSTRUAL_HISTORY_LIST
    menstrualList = async () => {
        const { patient_id, doctor_id, hospital_id, reference_id } = this.state.patient;
        
        const PARAMS = {
            patient_id: patient_id,
            doctor_id: (LS_SERVICE.get('user_type') === 2 && LS_SERVICE.get('speciality_id')) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
            hospital_id: hospital_id,
            reference_id:reference_id,
            reference_type:0,
        }

        const result = await Axios.get(MENSTRUAL_HISTORY_LIST(PARAMS));
        if (result?.data?.data) {
            this.data = result?.data?.data;
            this.list = result?.data?.data.menstrualHistoryList;
            this.patientDetails = result?.data?.data.patientDetails;

            if(this.list.length > 0){
                this.setState({ menstrualHistoryList: this.list });
            }
            if(this.list.length > 0 || this.patientDetails){
                this.setState({ disabled_no_menstrual_hx : true });
            }
            this.setState({
                menstrualHistoryLmp: this.data,
                patientDetails : this.patientDetails,
                menstrualHistoryListStatus: true,
                is_confidential : this.patientDetails.is_confidential
            })

            lmpDataService.init({
                menstrualHistoryLmp : this.data,
                
            });
        }
    }

    updatePOGEDD = (data) => {
        this.setState(prevState => {
            const prevItems = prevState.current_pregnancy_data;
            prevItems.lmpWeeks = data.lmpWeeks;
            prevItems.lmpDays = data.lmpDays;
            prevItems.usgWeeks = data.usgWeeks;
            prevItems.usgDays = data.usgDays;
            prevItems.eddDate = data.eddDate;
            prevItems.correctedEddDate = data.correctedEddDate;
            return {current_pregnancy_data: prevItems};
          });

          this.childPhysicalExam.current.physicalExamGraph();
    }
    
    getGpaleValues = () => {
        const { patient_id, doctor_id } = this.state.patient;
        const PARAMS = {
            patient_id: patient_id,
            doctor_id: (LS_SERVICE.get('user_type') === 2 && LS_SERVICE.get('speciality_id')) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
        }

        Axios.post(GPALE_CURRENT_PREGNANCY_DATA, PARAMS)
            .then(async success => {
                if (success?.data?.status == true) {
                    let gpale_data = success.data.data.gpaleData;
                    if(gpale_data){
                        let obj = {gravidity: gpale_data.gravidity, parity: gpale_data.parity, abortion: gpale_data.abortion, live_birth: gpale_data.liveBirth, ectopic: gpale_data.ectopic};
                        this.setState({
                            gpale_values: obj
                        })
                    }
                    let current_pregnancy_data = success.data.data.currentPregnancyData;
                    let fetus_data = success.data.data.phyExamFetusData;
                    if(current_pregnancy_data){
                        let obj = {id:current_pregnancy_data.id, pregnant: current_pregnancy_data.pregnant, testsDone: current_pregnancy_data.testDone ? current_pregnancy_data.testDone.split(',') : [], lmpWeeks: current_pregnancy_data.gestationalAgeLmpWeeks, lmpDays: current_pregnancy_data.gestationalAgeLmpDays, usgWeeks: current_pregnancy_data.gestationalAgeUsgWeeks, usgDays: current_pregnancy_data.gestationalAgeUsgDays, usgDate: current_pregnancy_data.usgDoneOn, eddDate: current_pregnancy_data.eddDate, correctedEddDate: current_pregnancy_data.correctedEddDate, lactating: current_pregnancy_data.lactating == '1' ? true : false, remarksForCurrentPregnancy : current_pregnancy_data.remarksForCurrentPregnancy, riskFactors : current_pregnancy_data.riskFactors ? current_pregnancy_data.riskFactors.split(',') : [], riskFactorsRemarks : current_pregnancy_data.riskFactorsRemarks};
                        if(fetus_data){
                            obj.no_of_fetus = fetus_data.noOfFetus
                        }
                        this.setState({
                            current_pregnancy_data: obj
                        })
                    } else {
                        if(fetus_data){
                            this.setState(prevState => {
                                const prevItems = prevState.current_pregnancy_data;
                                prevItems.no_of_fetus = fetus_data.noOfFetus;
                                return { current_pregnancy_data: prevItems };
                            });
                        }
                    }
                    let no_obs_history_data = success.data.data.noObsHistory;
                    if(no_obs_history_data){
                        this.setState({
                            no_obs_history: no_obs_history_data.isChecked == '1' ? true : false 
                        })
                    }
                    await this.setState({gpale_loaded: true});
                } else {
                    await this.setState({gpale_loaded: true});
                }
                lmpDataService.init({
                    menstrualHistoryLmp : this.state.menstrualHistoryLmp
                });
            }).catch(err => console.log(err))
    }

    // For Modal popup
    handleModalPopup = (e, popup, data = null) => {
        if (e !== null) e.preventDefault();

        this.setState(
            {
                [popup]: !this.state[popup],
            }
        );
    };

    handleContinue = async (tab) => {
        await this.setState({
            activeTab: tab
        });
        this.childTabs.current.ChangeTabHandler(tab);
    }

    handleChangeParentTab = async (tab) => {
        await this.setState({
            activeTab: tab
        });
    }

    enablePractice = async e => {
        e.preventDefault();
        practiceService.init({ initiater: 'SOAP Pages', status: 'start' });
        const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
          hospital_id: this.state.hospital_id,
          practice_session: 'start'
        });
    
        let is_practicing;
        if (practice.data && practice.data.is_practicing === 'Y') {
          is_practicing = true;
          LS_SERVICE.set('practicing', {
            status: true,
            hospital_id: this.state.hospital_id
          });
        } else {
          LS_SERVICE.delete('practicing');
          is_practicing = false;
        }
        this.setState({
            isPracticing: is_practicing,
        }, () => {
            this.handleModalPopup(null, 'isPracticingModal');
        });
      }

    render() {
        const { patient, patientDetails, tabs_available } = this.state;

        return (
            <>
                {/* <!-- Page sidebar--> */}
                {patient != undefined &&
                patient.patient_id != undefined &&
                patient.patient_id != null ? (
                    <AsideLeft
                        patient_id={patient.patient_id}
                        reference_id={patient.reference_id}
                    />
                ) : null}

                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row emrfrm">
                            <div className="col-12">
                                {/* SOAP Bar */}

                                {patient != undefined &&
                                patient.patient_id != undefined &&
                                patient.patient_id != null && this.state.pathname && this.state.pathname.includes('objective') ? (
                                    <SubHeader patient={patient} />
                                ) : null}

                                <GynaeTabs 
                                    pathname={this.state.pathname} 
                                    allowEdit={this.state.allowEdit} 
                                    isPracticing={this.state.isPracticing} 
                                    parentHandleModalPopup={this.handleModalPopup} 
                                    tabs_available={tabs_available}
                                    ref={this.childTabs} 
                                    activeTab={this.state.activeTab}
                                    patient={patient}
                                    patientDetails={patientDetails} 
                                    changeParentTab={this.handleChangeParentTab}
                                />

                                <div className="tab-content">
                                    {tabs_available.menstrual_tab_available ? 
                                    <div
                                        className={`tab-pane fade ${this.state.activeTab == '1' ? 'show active' : ''}`}
                                        id="menstrual-history"
                                        role="tabpanel"
                                    >
                                        <MenstrualHx
                                            pathname={this.state.pathname}
                                            allowEdit={this.state.allowEdit}
                                            isPracticing={this.state.isPracticing}
                                            parentHandleModalPopup={this.handleModalPopup}
                                            patientData={this.state.patientData} 
                                            history={this.props.history} 
                                            tabs_available={tabs_available} 
                                            showTrendChart={this.handleChartModalPopup}
                                            patient={patient}
                                            menstrualList={this.menstrualList}
                                            patientDetails={this.state.patientDetails}
                                            menstrualHistoryListStatus={this.state.menstrualHistoryListStatus}
                                            menstrualHistoryList={this.state.menstrualHistoryList}
                                            disabled_no_menstrual_hx={this.state.disabled_no_menstrual_hx}
                                            lactating={this.state.current_pregnancy_data.lactating}
                                            handleContinue={this.handleContinue}
                                        />
                                    </div> : null}

                                    {tabs_available.sexual_tab_available ? 
                                    <div
                                        className={`tab-pane fade ${this.state.activeTab == '2' ? 'show active' : ''}`}
                                        id="sexual-history"
                                        role="tabpanel"
                                    >
                                        <SexualHx
                                            pathname={this.state.pathname}
                                            allowEdit={this.state.allowEdit} 
                                            isPracticing={this.state.isPracticing}
                                            parentHandleModalPopup={this.handleModalPopup}
                                            patientData={this.state.patientData} 
                                            history={this.props.history} 
                                            tabs_available={tabs_available} 
                                            patient={patient} 
                                            handleContinue={this.handleContinue} 
                                        />
                                    </div> : null}

                                    {tabs_available.obs_tab_available ? 
                                    <div
                                        className={`tab-pane fade ${this.state.activeTab == '3' ? 'show active' : ''}`}
                                        id="obstetric-history"
                                        role="tabpanel"
                                    >
                                        {this.state.gpale_loaded ? 
                                            <ObstetricHx 
                                                pathname={this.state.pathname} 
                                                allowEdit={this.state.allowEdit} 
                                                isPracticing={this.state.isPracticing} 
                                                parentHandleModalPopup={this.handleModalPopup} 
                                                patientData={this.state.patientData} 
                                                history={this.props.history} 
                                                tabs_available={tabs_available} 
                                                patient={patient} 
                                                patientDetails={this.state.patientDetails} 
                                                gpale_values={this.state.gpale_values} 
                                                current_pregnancy_data={this.state.current_pregnancy_data} 
                                                no_obs_history={this.state.no_obs_history} 
                                                updatePOGEDD={this.updatePOGEDD} 
                                                handleContinue={this.handleContinue}
                                            /> 
                                        : null}
                                    </div> : null}
                                    {tabs_available.physical_tab_available ? 
                                    <div
                                        className={`tab-pane fade ${this.state.activeTab == '4' ? 'show active' : ''}`}
                                        id="physical-examination"
                                        role="tabpanel"
                                    >
                                        {this.state.gpale_loaded ? 
                                            <PhysicalExam 
                                                pathname={this.state.pathname} 
                                                allowEdit={this.state.allowEdit} 
                                                isPracticing={this.state.isPracticing} 
                                                parentHandleModalPopup={this.handleModalPopup} 
                                                patientData={this.state.patientData} 
                                                history={this.props.history} 
                                                patient={patient} 
                                                patientDetails={this.state.patientDetails} 
                                                gpale_values={this.state.gpale_values} 
                                                current_pregnancy_data={this.state.current_pregnancy_data}
                                                ref={this.childPhysicalExam} 
                                            />
                                        : null}
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/** || isPracticing Modal */}
                <Modal
                    title={false}
                    closable={false}
                    visible={this.state.isPracticingModal}
                    footer={false}
                >
                    <div className="row">
                        <div className="col-12 my-3 text-center">
                            <h6>Do you want to Start Practice?</h6>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <div className="btn btn-outline-secondary px-5" onClick={e => this.setState({ isPracticingModal: false })}>No</div>
                        <div className="btn btn-primary px-5" onClick={this.enablePractice}>Yes</div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default Gyanecology;
