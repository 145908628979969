import React, { Component, Fragment } from 'react';
import {
  SEARCH_DIAGNOSIS,
  FAVOURITE_DIAGNOSIS,
  LOADER_BLACK,
  IMAGE_LIBRARY,
  HANDLEVALIDANTMESSAGES, ASSESSMENT_LIST, IS_URL,DIAGNOSIS_DO_NOT_PRINT,
  USERTYPES,
  CIMS_DIAGNOSIS_INTERACTION_EXISTS,
  CIMS_DIAGNOSIS_INTERACTION
} from '../../utils/constant';
import { speechService, speechTextService } from '../../utils/rxjs-sharing';
import MyHcSpeechRecognition from "../../pages/speech-to-text/speech-recognition";
import LS_SERVICE from '../../utils/localStorage';
import { Select, Modal, Button, Tabs, Radio, Form } from 'antd';
import 'antd/dist/antd.css';
import Sketchpad from '../../utils/sketchpad';
import axios from 'axios';
import qs from 'qs';
import icImagereference from '../../assets/images/ic-imagereference.png';
import icDrawing from '../../assets/images/ic-drawing.png';
import icEraser from '../../assets/images/ic-eraser.png';
import icundo from '../../assets/images/ic-undo.png';
import icredo from '../../assets/images/ic-redo.png';
import icbrush from '../../assets/images/ic-brush.png';
import { jQueryLabels, ClearAntList, AntClearLabels, AntSelectLabels } from '../../utils/jQueryLabels';
// import './forms.scss';
import { Link } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
const { Option } = Select;
const { TabPane } = Tabs;

class AssessmentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formType: 'full',
      patient: this.props.patient,
      editMode: false,
      searchDiagnosis: [],
      assessmentLibraryImages: [],
      isModalAddImageVisible: false,
      isModalImageAnnotation: false,
      searchResultLoaded: false,
      assessTemplateListModal: false,
      assess_template_list: [],
      lineWidth: 1,
      lineColor: 'black',
      undo: 15,
      imageUploadedval: '',
      imageFreehandComment: '',
      selectedImg: '',
      saveBtnDisable: true,
      diagnosis_notes: '',
      // assessmentObject: {},
      isAlertModalVisible: false,
      sketch2background: '',
      assessmentId: null,
      assessmentDescription: '',
      assessmentIcdCode: '',
      assessmentStage: 0,
      assessmentType: 0,
      assessmentStatus: 0,
      assessmentImages: [],
      assessmentComment: '',
      assessmentNotes: '',
      editData: null,
      drug_id_Interaction_Modalurl:[],
      careProtoColId:0,
			referenceType:0,
			patientId: LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
			referenceId: LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
			StoreId: LS_SERVICE.get("staff_id") != undefined ? LS_SERVICE.get("staff_id") : 0,
			FacilityId: LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0,
			enterpriseId: LS_SERVICE.get("ENTERPRISE_ID") != undefined ? LS_SERVICE.get("ENTERPRISE_ID") : 0,
      is_diagnosis_required: (this.props.formType !== "template" ? true : false),
      is_notes_required: (this.props.formType !== "template" ? true : false),
      allowEdit: this.props.allowEdit ?? true,
      assessmentExistModal: false,
      speech: {
        identifier: null,
        state: false
      },
      cimsallow: typeof LS_SERVICE.get('cimsEnabled') != 'undefined' && LS_SERVICE.get('cimsEnabled') === 'Y' ? true : false,
    };
    
    this.sketchpad1 = null;
    this.sketchpad2 = null;
    this._sketch1 = React.createRef();
    this._sketch2 = React.createRef();
    this.LibraryImages = this.LibraryImages.bind(this);
    this.urlSketch1 = '';
    this.urlSketch2 = '';
    this.diagnosisSelectRef = React.createRef();
    this.setState({ selectValue: '' });
  }

  componentDidMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
    jQueryLabels();

    this.fetchImages();

    setTimeout(() => this.SearchDiagnoseApi(''), 500);

    this.subscription = speechTextService.status().subscribe((data) => {
      if (this.state.speech.state) {
          if (this.state.speech.identifier === "assessmentNotes") {
              if(this.props.formType !== "template"){
                this.setState({ assessmentNotes: data.text });
              }else{
                this.setState({ assessmentNotes: data.text });
              }
          }
          document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
      }
    });
  }

  //Speech
  initSpeech(info) {
    let initialText = '';
    if (info.identifier === 'assessmentNotes') {
      initialText = this.state.assessmentNotes != undefined ? this.state.assessmentNotes : '';
    }

    if (info.identifier === this.state.speech.identifier) {
        this.setState({
            speech: {
                identifier: null,
                state: !this.state.speech.state,
                initialText: initialText
            }
        }, () => {
            speechService.init(this.state.speech);
        });
    } else {
        this.setState({
            speech: {
                identifier: 'assessmentNotes',
                state: false,
                initialText: ''
            }
        }, () => {
            speechService.init(this.state.speech);
            setTimeout(() => {
                this.setState({
                    speech: {
                        identifier: info.identifier,
                        state: true,
                        initialText: initialText
                    }
                }, () => {
                    speechService.init(this.state.speech);
                });

            }, 1000);
        });
    }

    if (this.props.formType !== "template" && this.state.assessmentNotes !== "") {
      var is_notes_required = true;
      var is_diagnosis_required = true;
      var saveBtnDisable= true;

      if(this.state.selectValue){
        is_notes_required= false;
        saveBtnDisable= false;
      }

      if(this.state.assessmentNotes !== ""){
        is_diagnosis_required= false;
        saveBtnDisable= false;
      }
      if((this.state.selectValue && this.state.assessmentNotes !== "") || (this.state.selectValue && this.state.assessmentNotes == "")){
        is_diagnosis_required= true;
      }
      if(!this.state.selectValue && this.state.assessmentNotes !== ""){
        is_notes_required= true;
      }

      if (this.state.assessmentNotes !== "") {
        this.setState({
          assessmentNotes: this.state.assessmentNotes,
          is_diagnosis_required: is_diagnosis_required,
          is_notes_required: is_notes_required,
          saveBtnDisable: saveBtnDisable
        });
      } else {
        this.setState({
          is_diagnosis_required: is_diagnosis_required,
          is_notes_required: is_notes_required,
          assessmentNotes: "",
          saveBtnDisable: saveBtnDisable
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    setTimeout(() => {
        let Elem = document.querySelectorAll('.speech-field');
        Elem.forEach(function (el) {
            el.style.height = el.scrollHeight + "px";
        })
    }, 100);
  }

  autoHeight = (e) => {
    if (e)
      e.target.style.height = e.target.scrollHeight + "px";
  }

  fetchImages = _ => {
    axios.get(IMAGE_LIBRARY)
      .then(success => {
        const { data } = success.data;
        this.setState({
          assessmentLibraryImages: data.assessmentImages
        })
      })
      .catch(err => console.log(err))

  }

  onRadioStageChange = (e, props) => {
    this.setState({ assessmentStage: e.target.value, })
  };
  onRadioTypeChange = (e, props) => {

    this.setState({ assessmentType: e.target.value, })
  };
  onRadioStatusChange = (e, props) => {

    this.setState({ assessmentStatus: e.target.value, })
  };

  showModal = () => {

    if (this.props.isPracticing === false) {
      this.props.practiceModal('isPracticingModal');
      return;
    }

    this.setState({
      isModalAddImageVisible: true
    }, () => {

    })
  };

  handleTabSwitch = key => {
    if (key === "1") {
      setTimeout(() => {
        this.sketchpad1 = new Sketchpad({
          element: '#' + this._sketch1.current.id,
          width: 960,
          height: 320,
        });
      }, 0);
    }
  }

  handleOk = () => {
    this.setState({ isModalAddImageVisible: false })
  };

  handleCancel = () => {
    this.setState({ isModalAddImageVisible: false, PropEditFlag: 1, isAssesmentEdit: false, PropAddFlag: 1 })
  };
  assessmentFavourite = (id) => {

    // if (this.props.isPracticing === false) {
    //   this.props.practiceModal('isPracticingModal');
    //   return;
    // }

    const elem = document.querySelector('#diagnosis').parentElement?.nextSibling?.querySelector('.desc');
    let searchKeys = elem !== undefined && elem !== null ? elem.innerText : document.querySelector('#diagnosis').value;
    searchKeys = searchKeys.replace(/[^\w\s]/gi, ' ');

    axios.post(FAVOURITE_DIAGNOSIS, qs.stringify({
      id: id,
    })).then(success => {
      if (success.data.status) {
        this.SearchDiagnoseApi(searchKeys);
      }
    }).catch(err => {
      console.log(err);
    })

  }
  ImageshowModal = () => {
    this.setState({ isModalImageAnnotation: true }, () => {
      this.sketchpad2 = new Sketchpad({
        element: '#sketch2',
        width: 960,
        height: 500,
      });
      this.sketchpad2.penSize = 2;
      this.drawBackgroundCanvas(this._sketch2, this.state.imageUrl)
    })
  };
  ImagehandleOk = () => {
    this.setState({ isModalImageAnnotation: false })
  };

  ImagehandleCancel = () => {
    this.setState({ isModalImageAnnotation: false, PropEditFlag: 1, PropAddFlag: 1 })
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.editData != null && this.state.editData != prevState.editData) {
      this.setState({ searchDiagnosis: [{ id: this.state.editData.diagnosis_id, description: this.state.editData.description, icd_code: this.state.editData.icd_code }] }, () => this.handleEditData())
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.editData !== prevState.editData) {
      return { editData: nextProps.editData }
    }
    if (nextProps.messageType !== prevState.messageType) {
      return { messageType: nextProps.messageType }
    }
    return null;
  }

  handleEditData() {
    if (this.state.editData != null) {
      var is_notes_required = true;
      var is_diagnosis_required = true;
      if(this.state.editData.diagnosis_id){
        is_notes_required= false;
      }
      if(this.state.editData.diagnosis_notes){
        is_diagnosis_required= false
      }
      if((this.state.editData.diagnosis_id && this.state.editData.diagnosis_notes) || (this.state.editData.diagnosis_id && !this.state.editData.diagnosis_notes)){
        is_diagnosis_required= true
      }
      if(!this.state.editData.diagnosis_id && this.state.editData.diagnosis_notes){
        is_notes_required= true
      }
      this.setState({
        selectValue: this.state.editData.diagnosis_id,
        assessmentId: this.state.editData.diagnosis_id,
        assessmentDescription: this.state.editData.description,
        assessmentIcdCode: this.state.editData.icd_code,
        assessmentStage: this.state.editData.stage,
        assessmentType: this.state.editData.type,
        assessmentStatus: this.state.editData.status,
        assessmentImages: typeof this.state.editData.image != 'undefined' ? this.state.editData.image : this.state.editData.image_uploaded,
        assessmentComment: this.state.editData.image_comment,
        assessmentNotes: this.state.editData.diagnosis_notes,
        saveBtnDisable: false,
        is_notes_required: is_notes_required,
        is_diagnosis_required: is_diagnosis_required
      }, () => this.handleTextareaAutoHeight('diagnosis_notes_class'));
    }
  }

  /*handleFormReset = _ => {
    this.setState({
      resetForm: false
    }, () => this.Formclear())
  } */

  FormClear = () => {
    if (this.props.formType !== "template") {
      this.setState({ is_diagnosis_required: true, is_notes_required: false });
    } else {
      this.setState({ is_notes_required: false });
    }

    this.setState({
      selectValue: '',
      imageUploadedval: '',
      imageFreehandComment: '',
      selectedImg: '',
      is_diagnosis_required: true,
      is_notes_required: true,
      saveBtnDisable: true,
      diagnosis_notes: '',
      isAlertModalVisible: false,
      sketch2background: '',
      assessmentId: null,
      assessmentDescription: '',
      assessmentIcdCode: '',
      assessmentStage: 0,
      assessmentType: 0,
      assessmentStatus: 0,
      assessmentImages: [],
      assessmentComment: '',
      assessmentNotes: '',
      editData: null
    }, () => { this.props.clearEdit(); AntSelectLabels(); this.SearchDiagnoseApi(''); this.handleTextareaAutoHeight('diagnosis_notes_class', true); })

  }

  

	handleInteractionOk     = () => { this.setState({ isModalInteractionVisible: false }) };
  handleInteractionCancel = () => { this.setState({ isModalInteractionVisible: false,assessTemplateListModal:false }, () => { this.FormClear();  }); };

  onAssessmentSelect = (value, props) => {

    let {referenceId,StoreId,FacilityId,enterpriseId,patientId,referenceType} = this.state;
    let {addedDrugsList}  = this.props;
        let careProtoColId             = 0;
        if(addedDrugsList && addedDrugsList.length > 0){
          careProtoColId = addedDrugsList.join('-');
        }
        let orederSetId         = 0;
        let addedAssessmentList = value;
        let medicineScreen      = this.props.formType;
        let showInteractionModal = false;  
        let drugIds              = 0;

        if(this.state.cimsallow){
          Axios.get(CIMS_DIAGNOSIS_INTERACTION_EXISTS({ drugIds,referenceType,referenceId,orederSetId,patientId,enterpriseId,FacilityId,StoreId,careProtoColId,medicineScreen,addedAssessmentList }))
            .then(success => {
            if (success.data.interaction) {
               showInteractionModal = true;
              this.setState({ drug_id_Interaction_Modalurl: drugIds,referenceType:referenceType,referenceId:referenceId,orederSetId:orederSetId,enterpriseId:enterpriseId,FacilityId:FacilityId,StoreId:StoreId,patientId:patientId,addedAssessmentList:addedAssessmentList,medicineScreen:medicineScreen,isModalInteractionVisible: showInteractionModal,careProtoColId:careProtoColId});
            }else{
              this.setState({ isModalInteractionVisible: false })
            }
          })
        .catch(err => console.log(err))
        }else{
          this.setState({ isModalInteractionVisible: false })
        }

     
    if (this.props.formType !== "template") {
      this.setState({ is_diagnosis_required: true, is_notes_required: false });
    } else {
      this.setState({ is_notes_required: false });
    }
    this.setState({ saveBtnDisable: false, assessmentId: value, assessmentDescription: props.title, assessmentIcdCode: props.icdcode, selectValue: value },
      () => {
        if (this.props.formType == "soap-full" && this.props.previousDiagnosis && this.props.previousDiagnosis.length > 0 && this.props.previousDiagnosis.includes(value)){
          this.setState({ assessmentExistModal: true })
        }else if (this.props.formType == "soap-full" && this.state.allowEdit){
          this.handleAssessmentTemplateList();
        }
          

      });
  };
  TemplateCopyVisit = (templateId) => {
    axios.post(SEARCH_DIAGNOSIS, qs.stringify({
      search: templateId
    })).then(success => {
      let favfilter = success.data.filter(data => data.fav === 1)
      this.setState({ searchDiagnosis: favfilter });
      this.diagnosisSelectRef.current.focus()
    }).catch(err => console.log(err))
  }
  SearchDiagnoseApi = (query) => {
    // if (templateId !== '' && templateId !== undefined) {
    this.setState({
      searchResultLoaded: true
    })
    axios.post(SEARCH_DIAGNOSIS, qs.stringify({
      search: query
    })).then(success => {

      this.diagnosisSelectRef.current.focus()
      this.setState({
        searchDiagnosis: success.data,
        searchResultLoaded: false
      });
    }).catch(err => console.log(err))
    // }
    // else {
    //   ClearAntList();
    //   this.setState({ searchDiagnosis: [] })
    // }
  };
  optionLoad() {
    if (!this.state.searchDiagnosis || this.state.searchDiagnosis.length === 0) {
      return (null)
    }
    return this.state.searchDiagnosis.map((obj, i) => {
      return (<Option key={i} value={obj.id} title={obj.description} icdcode={obj.icd_code} disabled={this.props.addedAssessmentList && this.props.addedAssessmentList.includes(obj.id)}>
        <span className="desc">
          {obj.description}
        </span>
        {
          (LS_SERVICE.get('user_type') == USERTYPES.doctor)
            ?
            <span className="float-right text-primary fav_diagnosis">
              <i onClick={e => { e.preventDefault(); e.stopPropagation(); this.assessmentFavourite(obj.id) }} className={obj.fav ? 'icon_unique icon_star favourite mr-2' : obj.id ? 'icon_unique icon_star mr-2' : 'icon_unique mr-2'}></i>
              {/* <i className="icon_info"></i> */}
            </span>
            :
            null
        }
        <span className="float-right mr-2">{obj.icd_code}</span></Option>
      )
    })
  }
  drawBackgroundCanvas = (sketchRef, imgSrc) => {
    var canvasWidth = sketchRef.current.width;
    var canvasHeight = sketchRef.current.height;

    // Draw Image on the Canvas
    var canvas = sketchRef.current;
    var ctx = canvas.getContext('2d');
    var img = new Image();
    img.src = imgSrc;
    var imageWidth;
    var imageHeight;
    img.onload = function () {
      if (this.width > canvasWidth && this.height > canvasHeight) {
        imageHeight = canvasHeight;
        imageWidth = this.width * (imageHeight / this.height);
      }
      else if (this.width > canvasWidth && canvasHeight >= this.height) {
        imageWidth = canvasWidth;
        imageHeight = this.height * (canvasWidth / this.width);
      }
      else if (canvasWidth >= this.width && this.height > canvasHeight) {
        imageHeight = canvasHeight;
        imageWidth = this.width * (imageHeight / this.height);
      }
      else if (canvasWidth >= this.width && canvasHeight >= this.height) {
        imageWidth = this.width;
        imageHeight = this.height;
      }
      var posX = (canvasWidth - imageWidth) / 2;
      img.setAttribute('crossorigin', 'anonymous');
      ctx.drawImage(this, posX, 0, imageWidth, imageHeight);
    };
  }

  onPen = () => {
    this.sketchpad1.penSize = 2;
    this.sketchpad1.color = '#000'
  }

  onEraser = () => {
    this.sketchpad1.penSize = 10;
    this.sketchpad1.color = '#fff';
  }

  onUndo = () => {
    this.sketchpad1.undo();
  }

  onRedo = () => {
    this.sketchpad1.redo();
  }

  onBrush = () => {
    this.sketchpad1.penSize = 8;
    this.sketchpad1.color = '#000';
  }
  imageComment = (e) => {
    this.setState({ assessmentComment: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) })
  }
  onSaveFreehandImage() {
    this.setState({
      assessmentImages: this._sketch1.current.toDataURL(), isModalAddImageVisible: false,
    })
  }
  LibraryImages = (e) => {
    this.setState({
      isModalAddImageVisible: false,
      imageUrl: e.target.attributes.src.nodeValue,
      selectedImg: e.target.id,
      sketch2background: e.target.src
    },
      () => {
        this.ImageshowModal()
      }
    );
  }
  AddLibraryImage = () => {
    this.setState({ assessmentImages: this._sketch2.current.toDataURL(), isModalImageAnnotation: false })
  }

  saveAssessment = () => {
    if (this.props.isPracticing === false) {
      this.props.practiceModal('isPracticingModal');
      return;
    }

    if (this.state.speech.identifier != null) {
      this.initSpeech({ identifier: this.state.speech.identifier });
    }

    var isURL = this.state.assessmentImages ? IS_URL(this.state.assessmentImages) : true
    let assessmentObj = {
      description: this.state.assessmentDescription,
      diagnosis_id: this.state.assessmentId,
      icd_code: this.state.assessmentIcdCode,
      id: this.state.editData != null ? this.state.editData.id : null,
      i: this.props.editIndex,
      image_comment: this.state.assessmentComment,
      image_uploaded: isURL ? -1 : this.state.assessmentImages,
      stage: this.state.assessmentStage,
      type: this.state.assessmentType,
      status: this.state.assessmentStatus,
      diagnosis_notes: this.state.assessmentNotes,
      hospital_id: LS_SERVICE.get('slot_hospital_id'),
      patient_id: this.state.patient ? this.state.patient.patient_id : null,
      speciality_id: LS_SERVICE.get("speciality_id")
    }
    this.props.updateData(assessmentObj);
    this.FormClear();

  }

  onAssessmentSearch = (value) => {
    this.SearchDiagnoseApi(value)
  }
  onDiagnosisNotesChange = (e) => {
    if (this.props.formType !== "template") {
      var is_notes_required = true;
      var is_diagnosis_required = true;
      var saveBtnDisable= true;

      if(this.state.selectValue){
        is_notes_required= false;
        saveBtnDisable= false;
      }

      if(e.target.value !== ""){
        is_diagnosis_required= false;
        saveBtnDisable= false;
      }
      if((this.state.selectValue && e.target.value !== "") || (this.state.selectValue && e.target.value == "")){
        is_diagnosis_required= true;
      }
      if(!this.state.selectValue && e.target.value !== ""){
        is_notes_required= true;
      }

      if (e.target.value !== "") {
        this.setState({
          assessmentNotes: e.target.value,
          is_diagnosis_required: is_diagnosis_required,
          is_notes_required: is_notes_required,
          saveBtnDisable: saveBtnDisable
        });
      } else {
        this.setState({
          is_diagnosis_required: is_diagnosis_required,
          is_notes_required: is_notes_required,
          assessmentNotes: "",
          saveBtnDisable: saveBtnDisable
        });
      }
    } else {
      this.setState({
        assessmentNotes: e.target.value,
      })
    }
  }

  handleAssessmentTemplateList = async _ => {
    if(this.state.assessmentId && DIAGNOSIS_DO_NOT_PRINT.includes(this.state.assessmentId) != true){
      const PARAMS = {
        diagnosis: this.state.assessmentId,
        hospital_id: LS_SERVICE.get('slot_hospital_id'),
        speciality_id: LS_SERVICE.get("speciality_id")
      }

      const assessListPromise = await Axios.post(ASSESSMENT_LIST, qs.stringify(PARAMS))

      if (assessListPromise.data.status) {
        this.setState({
          assess_template_list: assessListPromise.data.data.templates,
        }, () => {
          const { assess_template_list, formModeEdit } = this.state;
          if (assess_template_list.length > 0 && !formModeEdit) {
            this.handleModalPopup(null, 'assessTemplateListModal')
            if (assess_template_list.length == 1) {
              this.setState({
                radioTemplateSelectedId: assess_template_list[0].template_id
              })
            }
          }
        })
      }
    }
  }

  handleTemplateRadioClick = (e, templateid) => {
    // const { patient } = this.state;
    this.setState({
      radioTemplateSelectedId: templateid
    })
  }


  handleModalPopup = (e, popup, image = null, data = null) => {
    if (e !== null)
      e.preventDefault();

    this.setState({
      [popup]: !this.state[popup]
    }, () => {
      const { imageReferenceModal, deleteModal } = this.state;

      // if (imageReferenceModal) {
      //     this.sketchpad1 = new Sketchpad({
      //         element: '#' + this._sketch1.current.id,
      //         width: 960,
      //         height: 320,
      //     });
      // }

      if (deleteModal) {
        if (data !== null) {
          this.setState({
            delete_text: data.diagnosisInfo.diagnose_description,
            delete_data: data
          })
        }
      }

      if (image !== null) {
        this.sketchpad2 = new Sketchpad({
          element: '#' + this._sketch2.current.id,
          width: 960,
          height: 500,
        });
        this.sketchpad2.penSize = 2;
        const img = Object.entries(image)[0];
        this.setState({
          selectedImg: img[0]
        }, () => {
          this.drawBackgroundCanvas(this._sketch2, img[1])
        })
      }
    })
  }

  handleTextareaAutoHeight = (textarea, clear = false) => {
    let Elem = document.querySelectorAll('.' + textarea);
    if (!clear) {
      Elem.forEach((el) => { el.style.height = el.scrollHeight + "px"; });
    } else {
      Elem.forEach((el) => { el.style.height = "40px"; });
    }
  }

  handleReAssessmentApproval = e => {
    this.handleModalPopup(null, 'assessmentExistModal');

    if (this.props.formType == "soap-full" && this.state.allowEdit)
      this.handleAssessmentTemplateList();

  }

  render() {
    const { assessmentLibraryImages, is_diagnosis_required, is_notes_required } = this.state;
    return (
      <Fragment>
        {/* <MyHcSpeechRecognition /> */}
        {this.props.formType != 'soap-full' ?
          <h2 className="col-12 text-heading">Assessment</h2>
          : null
        }
        <div className='container-fluid add_diagnosis emrfrm' id="create-assessment-details">
          {/*<Form*/}
          {/*    ref={this.assessmentFormRef}*/}
          {/*    validateMessages={HANDLEVALIDANTMESSAGES}*/}
          {/*    onFinish={this.handleaddAssessmentAddSubmit}*/}
          {/*    // layout="vertical"*/}
          {/*    className='emrfrm'*/}

          {/*>*/}
          <div className='row mt-2'>
            <div className="col-12">
              <div className="form-group hasdata">
                <Select
                  id="diagnosis"
                  name="diagnosis"
                  style={{ width: 100 + '%' }}
                  placeholder=" "
                  showSearch
                  notFoundContent={this.state.searchResultLoaded ? LOADER_BLACK : null}
                  loading={this.state.searchResultLoaded}
                  //onChange={this.onAssessmentSelect}
                  onSelect={this.onAssessmentSelect}
                  onSearch={this.onAssessmentSearch}
                  className="select-diagnosis"
                  value={this.state.selectValue}
                  defaultOpen={!this.state.editMode}
                  ref={this.diagnosisSelectRef}
                  filterOption={false}
                  autoFocus={this.state.selectValue != null && this.state.selectValue != '' ? false : true}
                  showAction={this.state.selectValue != null && this.state.selectValue != '' ? [] : ['focus', 'click']}
                  disabled={this.state.editData != null && this.state.selectValue != null}
                // optionFilterProp="children"
                // filterOption={(input, option) => {
                //   return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                // }}
                >
                  {this.optionLoad()}
                </Select>
                <label htmlFor="diagnosis">Diagnosis<span className="text-danger">*</span></label>
              </div>
            </div>
          </div>
          <div className='row my-2'>
            <div className="col-12">
              <div className={this.state.assessmentNotes ? 'form-group micwrap ant-mic-wrap hasdata':'form-group micwrap ant-mic-wrap'}>
                  <a
                    onClick={ (e) => { e.preventDefault(); this.initSpeech({ identifier: 'assessmentNotes' }) } }
                    className={(this.state.speech.identifier === 'assessmentNotes' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'} 
                ></a>
                <textarea 
                  className="form-control diagnosis_notes_class speech-field" 
                  id="assessmentNotes" 
                  name="diagnosis_notes" 
                  placeholder="Diagnosis Notes"
                  rows="1"
                  onInput={(e) => this.autoHeight(e)}
                  onChange={this.onDiagnosisNotesChange}
                  value={this.state.assessmentNotes}
                ></textarea>
                <label htmlFor="diagnosis_notes">
                  Diagnosis Notes
                  {/* {is_notes_required ? <span className="text-danger">*</span> : null} */}
                </label>
              </div>
            </div>
          </div>
          <div className='row ' style={{ backgroundColor: '#e6e6e6', borderRadius: '6px', display: this.props.formType != 'template' ? 'flex' : 'none' }}>
            {/* <div className="col-12 mb-3" style={{ display: this.props.formType != 'template' ? 'block' : 'none' }}>
              <div className="row"> */}
                <div className='col-4 '>
                  <label className="text-grey mb-0" style={{ width: '20%' }}>
                    Stage
                    {this.props.formType == "template" ? <span className="text-danger">*</span> : null}
                  </label>
                  <div className="form-group custom-control custom-radio custom-control-inline pl-0 mb-0">
                    <Radio.Group onChange={this.onRadioStageChange} value={this.state.assessmentStage}>
                      <Radio value={0} name="stage" title={'Provisional'}>Provisional</Radio>
                      <Radio value={1} name="stage" title={'Final'}>Final</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className='col-4 '>
                  <label className="text-grey mb-0" style={{ width: '20%' }}>
                    Type
                    {this.props.formType == "template" ? <span className="text-danger">*</span> : null}
                  </label>
                  <div className="form-group custom-control custom-radio custom-control-inline pl-0 mb-0">
                    <Radio.Group onChange={this.onRadioTypeChange} value={this.state.assessmentType}>
                      <Radio value={0} name="stage" title={'Primary'}>Primary</Radio>
                      <Radio value={1} name="stage" title={'Secondary'}>Secondary</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className='col-4 '>
                  <label className="text-grey mb-0" style={{ width: '20%' }}>
                    Status
                    {this.props.formType == "template" ? <span className="text-danger">*</span> : null}
                  </label>
                  <div className="form-group custom-control custom-radio custom-control-inline pl-0 mb-0">
                    <Radio.Group onChange={this.onRadioStatusChange} value={this.state.assessmentStatus}>
                      <Radio value={0} name="stage" title={'Active'}>Active</Radio>
                      <Radio value={1} name="stage" title={'Cured'}>Cured</Radio>
                    </Radio.Group>
                  </div>
                </div>
              {/* </div>
            </div> */}
          </div>
          <div className='row mt-2'>
            <div className="col-12">
              <div className="row">
                <div className="col align-self-start">
                  <label>Image Reference</label>
                  <Button className="image-reference-btn nostyle-link h-auto p-0" onClick={() => this.showModal()}>
                    <img src={icImagereference} alt="Image Reference" className="img-fluid" />
                  </Button>
                  <div className="row">
                    <div className="col-12 text-muted image-ref-comment">{this.state.assessmentComment}</div>
                  </div>
                </div>
                <div className="image-references">
                  <img src={this.state.assessmentImages} alt="" className="img-fluid" id="selected-image" />
                </div>
                {/* <Button className="image-reference-btn nostyle-link h-auto p-0" onClick={() => this.showModal()}>
                  <img src={icImagereference} alt="Image Reference" className="img-fluid" />
                </Button> */}
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <div className={`form-group mb-0 ${this.props.showMessage ? 'text-center' : 'text-right'}`}>
                <button className="btn btn-outline-secondary px-4 small mr-3" id="assessment-clear" onClick={() => this.FormClear()} >CLEAR</button>
                <button type="button" className="btn btn-primary px-4 small diagnosis-save text-uppercase mandatory-save"
                  onClick={() => this.saveAssessment()} disabled={!this.state.allowEdit ? true : this.state.saveBtnDisable}>{this.state.editData != null ? 'Update' : 'Add'}</button>
              </div>
            </div>
          </div>
          {this.props.showMessage ? 
            <div class="text-center mt-2">
              {this.state.messageType == 'Add' ? <span style={{color: 'rgb(84, 143, 58)'}}>Diagnosis Added Successfully</span> : null }
              {this.state.messageType == 'Update' ? <span style={{color: 'rgb(84, 143, 58)'}}>Diagnosis Updated Successfully</span> : null }
            </div>
          : null}
          {/*</Form>*/}
        </div>
        <Modal id="image_reference_dialog"
          className="image_reference_dialog"
          title="Image Reference"
          visible={this.state.isModalAddImageVisible}
          onOk={() => this.handleOk()}
          onCancel={() => this.handleCancel()}
          footer={false}
        >
          <Tabs defaultActiveKey="2" onChange={this.handleTabSwitch}>
            <TabPane tab="Free Hand" key="1" className="pills-freehand">
              <div className="position-relative">
                <canvas id="sketch1" ref={this._sketch1}></canvas>
                <div className="freehand-toolbar">
                  <ul>
                    <li>
                      <Button className="nostyle-link" id="pen" onClick={() => this.onPen()}>
                        <img src={icDrawing} alt="drawing" className="img-fluid" />
                      </Button>
                    </li>
                    <li>
                      <Button className="nostyle-link" id="eraser" onClick={() => this.onEraser()}>
                        <img src={icEraser} alt="drawing" className="img-fluid" />
                      </Button>
                    </li>
                    <li>
                      <Button className="nostyle-link" id="undo" onClick={() => this.onUndo()}>
                        <img src={icundo} alt="drawing" className="img-fluid" />
                      </Button>
                    </li>
                    <li>
                      <Button className="nostyle-link" id="redo" onClick={() => this.onRedo()}>
                        <img src={icredo} alt="drawing" className="img-fluid" />
                      </Button>
                    </li>
                    <li>
                      <Button className="nostyle-link" id="brush" onClick={() => this.onBrush()}>
                        <img src={icbrush} alt="drawing" className="img-fluid" />
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="library-comments" className="sr-only">Comments</label>
                    <input type="text" className="form-control image-freehand-comment" maxLength="50" value={this.state.assessmentComment} placeholder="Comments" onChange={this.imageComment} />
                  </div>
                  <div className="form-group text-center">
                    <button className="btn btn-primary px-5 save-freehand-image text-uppercase" onClick={() => this.onSaveFreehandImage()}>
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Library" key="2" className="pills-library">
              <div className="row no-gutters library-images mx-0 cursor">
                {assessmentLibraryImages?.length > 0 ? (
                  assessmentLibraryImages.map((aI, i) => {
                    return (
                      <div className="col-md-4 border text-center" key={i}>
                        <Button onClick={this.LibraryImages}
                          className={this.state.selectedImg === 'img' + i ? 'nostyle-link selected' : 'nostyle-link'}>
                          <img src={aI} className="img-fluid" alt="Assessment Images" crossorigin="anonymous" />
                        </Button>
                      </div>
                    )
                  })
                ) : null}
                {/* <div className="col-md-4 border text-center">
                  <Button onClick={this.LibraryImages} className={this.state.selectedImg === 'ear01' ? 'nostyle-link selected' : 'nostyle-link'}>
                    <img src={ear01} id="ear01" className="img-fluid" alt="Ear 01" />
                  </Button>
                </div> */}
              </div>
            </TabPane>
          </Tabs>

        </Modal>
        <Modal id="image_annotation_dialog"
          className="image_annotation_dialog"
          title={false}
          visible={this.state.isModalImageAnnotation}
          onOk={() => this.ImagehandleOk()}
          onCancel={() => this.ImagehandleCancel()}
          footer={false}
        >
          <form>
            <div className="form-group">
              <canvas id="sketch2" ref={this._sketch2}></canvas>
            </div>
            <div className="form-group">
              <label htmlFor="library-comments" className="sr-only">Comments</label>
              <input type="text" className="form-control image-library-comment" maxLength="50" value={this.state.assessmentComment} placeholder="Comments" onChange={this.imageComment} />
            </div>
            <div className="form-group text-center">
              <Button typle="primary" className="btn btn-primary px-5 save-library-image text-uppercase" onClick={() => this.AddLibraryImage()}>Add</Button>
            </div>
          </form>
        </Modal>

        {/** ASSESSMENT LIST MODAL */}
        <Modal
          // className="image_annotation_dialog"
          title={false}
          visible={this.state.assessTemplateListModal}
          closeIcon={false}
          onCancel={(e) => this.handleModalPopup(e, 'assessTemplateListModal')}
          closable={false}
          width="600px"
          footer={
            <div className="col-12 text-center">
              <button type="button" className="btn btn-outline-secondary mr-3"
                onClick={e => this.handleModalPopup(e, 'assessTemplateListModal')}>CANCEL</button>
              <button type="button" className="btn btn-primary px-4" onClick={this.props.handleTemplateRadioSubmit != null ? e => this.props.handleTemplateRadioSubmit(this.state.radioTemplateSelectedId) : null}>CONFIRM</button>
            </div>
          }
        >
          <div className="row">
            <div className="col-12">There are protocols associated with the diagnosis:</div>
            <div className="col-10"><strong>{this.state.assessmentDescription}</strong></div>
            <div className="col-2 text-right text-secondary">{this.state.assessmentIcdCode}</div>

            <div className="col-12 px-3 pt-2 mt-10">

              <div className="row emrfrm">
                <p className="mb-2"><strong>Please select the one you like to use.</strong></p>
                {this.state.assess_template_list.length > 0 ? (
                  this.state.assess_template_list.map((atl, i) => {
                    return (
                      <div className="col-12 border-bottom pl-0 pb-2 mb-3" key={i}>
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input type="radio" className="custom-control-input" name="template_id" id={`template_id_${atl.template_id}`}
                            defaultValue={atl.template_id} checked={atl.template_id === this.state.radioTemplateSelectedId}
                            onChange={e => this.handleTemplateRadioClick(e, atl.template_id)} />
                          <label className="custom-control-label" htmlFor={`template_id_${atl.template_id}`}>{atl.name}</label>
                        </div>
                        {
                          (atl.time_used_by_hospital && atl.time_used_by_hospital > 0)
                            ?
                            (
                              <>
                                {
                                  (atl.time_used_by_hospital > 1)
                                    ?
                                    (<div className="float-right"><strong>Used {atl.time_used_by_hospital} times</strong></div>)
                                    :
                                    (<div className="float-right"><strong>Used {atl.time_used_by_hospital} time</strong></div>)
                                }
                              </>
                            )
                            :
                            <div className="float-right"><strong>Never used</strong></div>
                        }

                        {/* <a href="#" className="nostyle-link"> */}
                        {/* <i className={atl.is_favorite ? 'icon_star favourite' : 'icon_star'}></i> */}
                        {/* </a> */}

                      </div>

                    )
                  })) : null}

              </div>
            </div>
          </div>
        </Modal>

        {/* Assessment Exist Modal */}
        <Modal
          title=""
          visible={this.state.assessmentExistModal}
          onCancel={(e) => this.handleModalPopup(e, 'assessmentExistModal')}
          style={{ top: 40 }}
          closable={false}
          closeIcon={false}
          width={400}
          footer={[
            <div className="justify-content-center text-center">
              <div tabindex="20" className="btn btn-outline-secondary cursor-pointer"
                onClick={e => { this.handleModalPopup(e, 'assessmentExistModal'); this.FormClear(); }}>No</div>
              <button type="submit" className="btn btn-primary text-uppercase ml-2 mandatory-save"
                onClick={this.handleReAssessmentApproval}>Yes</button>
            </div>
          ]}
        >
          <div className="row">
            <div className="col-12 text-center">
              {this.state.assessmentDescription} is already active.<br />Do you still want to continue?
            </div>

          </div>


        </Modal>

        {/* ************************* ALERGY MODEL POPUP FOR CIMS * ************************* */}
					<Modal className="myDrugsInteraction_modal" id="myDrugsInteraction"
					title=" Diagnosis Interaction"
					visible={this.state.isModalInteractionVisible}
					onOk={() => this.handleInteractionOk()}
					onCancel={() => this.handleInteractionCancel()}
					footer={false}
					style={{width:1000}}
          zIndex={9999}
					>
					<iframe id="drug_interaction_widget_iframe2" title="interaction" className="cimsiframe" src={`${CIMS_DIAGNOSIS_INTERACTION}?drugIds=${this.state.drug_id_Interaction_Modalurl}&referenceId=${this.state.referenceId}&referenceType=${this.state.referenceType}&enterpriseId=${this.state.enterpriseId}&FacilityId=${this.state.FacilityId}&StoreId=${this.state.StoreId}&patientId=${this.state.patientId}&orederSetId=${this.state.orederSetId}&careProtoColId=${this.state.careProtoColId}&medicineScreen=${this.state.medicineScreen}&addedAssessmentList=${this.state.addedAssessmentList}`}></iframe>
					<div className="modal-footer justify-content-center emrfrm pb-0">
						<button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleInteractionCancel(e)}>DON’T PROCEED</button>
						<button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleInteractionOk(e)}>PROCEED TO ADD DIAGNOSIS</button>
					</div>
					</Modal>

      </Fragment>
    );
  }
}

export default AssessmentForm;