import React, { Component, Fragment } from 'react';
import {
    USERTYPES, LOADER_BLACK, SERVICE_TEST_SEARCH, SURGERY_STORE, GET_EXISTING_SURGERIES, SURGERY_DELETE, LOADER
} from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import 'antd/dist/antd.css';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { jQueryLabels, AntSelectLabels, ClearAntList } from '../../utils/jQueryLabels';
import { Form, Modal, Input, Button, Select, AutoComplete, Tooltip, notification } from 'antd';
import debounce from 'lodash/debounce';
const { Option } = Select;
class SurgeryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patient : {
                hospital_id   : LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0,
                patient_id    : LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
                qms_token_id  : LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
            },
            searchSurgery: [],
            addedSurgeryList: [],
            searchResultLoaded: false,
            saveBtnDisable: true,
            constants: this.props.constants ?? [],
            serviceId: null,
            serviceCode: null,
            selectSurgeryValue: '',
            surgeryName: '',
            allowEdit:this.props.allowEdit??true,
            existingSurgeriesList: [],
            viewMode: this.props.admissionAdvisedDisabled,
            deleteSurgeryModal: false,
            deleteId : null,
        }
        this.surgerySelectRef = React.createRef();
        
        this.SurgerySearch = debounce(this.SurgerySearch, 800);
        this.handleSurgeryFormCancel = this.handleSurgeryFormCancel.bind(this);
        this.setState({ selectSurgeryValue: '' });
    }

    async componentDidMount() {
        axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
        jQueryLabels();
        await this.getExisitingSurgeries();
        setTimeout(() => this.SurgerySearch(''), 500);
    }

    handleSurgeryFormCancel(e) {
        this.props.handleSurgeryFormCancel();
    }

    async getExisitingSurgeries() {
        const { patient } = this.state;
        const { qms_token_id } = patient;
        const surgery_list_data = await axios.get(GET_EXISTING_SURGERIES({ reference_type: LS_SERVICE.get('reference_type'), reference_id: qms_token_id, surgery_request_type : 2 }));
        if (typeof surgery_list_data.data != 'undefined' && typeof surgery_list_data.data.data != 'undefined') {
            await this.setState({
                existingSurgeriesList: surgery_list_data.data.data,
            });
            var addedSurgeryList = [];
            if (this.state.existingSurgeriesList && this.state.existingSurgeriesList.length > 0) {
                this.state.existingSurgeriesList.map((v, k) => {
                  addedSurgeryList.push(v.surgery_id);
                });
            }
            await this.setState({
                addedSurgeryList
            })
        }
        else
        {
            await this.setState({
                existingSurgeriesList: [],
                addedSurgeryList: [],
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
      if(this.state.eventBased != prevState.eventBased){
        this.checkSurgerySaveBtn();
      }
    }

    handleFormReset = _ => {
        this.Formclear();
    }

    optionSurgeryLoad() {
        if (this.state.searchSurgery !== undefined && this.state.searchSurgery.length > 0) {
            return this.state.searchSurgery.map((obj, i) => {
                const isRequisition = !(obj.isRequistion === null || obj.isRequistion === false || obj.isRequistion === undefined || obj.isRequisition === 'N');
                return (<Option key={i} serviceId={obj.serviceId} value={obj.serviceId} disabled={this.state.addedSurgeryList && this.state.addedSurgeryList.includes(obj.serviceId)} title={obj.serviceLabel} surgery_code={obj.testCode}>
                    <span className="desc">
                        {obj.serviceLabel}
                    </span>
                </Option>
                )
            })
        } else return null;
    }

    SurgerySearch = (search) => {
        this.setState({
            searchResultLoaded: true,
            saveBtnDisable: true
        })
        let params = {
            active: 1,
            service_name: search,
            facility_id: parseInt(LS_SERVICE.get("slot_hospital_id")),
            filter_service_type: "S,IPP",
            doctor_id: LS_SERVICE.get("staff_id")
        };
        axios.post(SERVICE_TEST_SEARCH, qs.stringify(params)).then(success => {
            var surgeryList = success.data?.data || [];
            this.setState({
                searchSurgery: surgeryList,
                searchResultLoaded: false,
            }, () => { AntSelectLabels(); });
        }).catch(err => console.log(err))
    }

    Formclear = () => {
        this.setState({
            saveBtnDisable: true,
            selectSurgeryValue: '',
            surgeryName: '',
            serviceId: null,
            serviceCode: null,
            deleteId: null,
        }, () => { AntSelectLabels(); this.SurgerySearch('') })
    }

    checkSurgerySaveBtn = () => {
      if(this.props.formType == 'template'){
        this.setState({saveBtnDisable: false})
      }
      else {
        //   this.setState({saveBtnDisable: false});
        }
    }

    checkEmptyValue(val) {
      return typeof val == 'undefined' || val == null || val == '' ? true : false;
    }

    onSurgerySelect = (value, props) => {
        var result;
        if(props.children.length > 0){
        if(props.children[0].props.children != undefined){
            var propsValue = props.children[0].props.children;
            result = propsValue.substring(0,5);
        }
        }else{
        result = "";
        }
        this.setState({
            serviceId: value,
            surgeryName: props.title,
            selectSurgeryValue: props.title,
            serviceCode: props.surgery_code,
            saveBtnDisable: false,
            
        }, () => {
            AntSelectLabels();
            this.checkSurgerySaveBtn();
        });
    };

    onSurgerySearch = (value) => {
        this.SurgerySearch(value);
        this.setState({ selectSurgeryValue: value });
    }

    addSurgeryList = async (val) => {



        if (this.props.isPracticing === false) {
            this.props.practiceModal('isPracticingModal');
            return;
        }

        let surgeryObj = {
            surgery_id: this.state.serviceId,
            surgery_name: this.state.surgeryName,
            serviceCode: this.state.serviceCode,
            priority_desc: 'Routine',
            surgery_surgeon_name: LS_SERVICE.get('staff_name'),
        }
        
        this.storeSurgeryData(surgeryObj);
        this.Formclear();

        document.getElementById('surgery-name').focus();
    }

    storeSurgeryData = async (surgeryObj) => {
        const { patient } = this.state;

        const { qms_token_id } = patient;

        var encounter_id = parseInt(LS_SERVICE.get('PATIENT_ENCOUNTER_ID'))
        if(LS_SERVICE.get('reference_type') == 2){
            var called_homecare = LS_SERVICE.get('last_called_homecare')
            encounter_id = parseInt(called_homecare?.enocunter_id)
        }

        let PARAMS = {
            enteredBy: parseInt(LS_SERVICE.get('staff_id')),
            facilityId: parseInt(LS_SERVICE.get('FACILITY_ID')),
            encounterId: encounter_id,
            patientId: parseInt(LS_SERVICE.get("PATIENT_REG_DATA").registrationid),
            specialityId: parseInt(LS_SERVICE.get('speciality_id')),
            enterpriseId: LS_SERVICE.get('ENTERPRISE_ID'),
            facilityName: LS_SERVICE.get('call_patient_detail').hospital_name || LS_SERVICE.get('FACILITY_NAME'),
            entered_by_name: LS_SERVICE.get('doctor').name,
            reference_id: qms_token_id,
            surgery_data : surgeryObj,
            requestFrom : 'OP',
            otId: 0,
            otName: '',
            surgery_date: null,
            isPACRequired: false,
            anesthesia_type_id: 0,
            ot_type: null,
            anesthesia_type_desc: '',
            anesthesia_doctor_id: 0,
            anesthesia_doctor_name: '',
            equipments: '',
            implant_items: '',
            remarks: '',
            otStartTime: null,
            otEndTime: null,
        }

        const submitPromise = await axios.post(SURGERY_STORE, PARAMS);
        if (submitPromise?.data?.status === true && (submitPromise?.data?.errorMsg === '' || submitPromise?.data?.errorMsg === null)) {

            notification.success({
                message: 'Surgery added Successfully.',
                placement: 'topRight'
            });
            this.handleSurgeryFormCancel();
            this.Formclear();
        } else if (submitPromise?.data?.errorMsg !== '' || submitPromise?.data?.errorMsg !== null) {
            notification.error({
              message: submitPromise?.data?.errorMsg,
              placement: 'topRight'
            });
        } else {
            notification.error({
              message: submitPromise?.data?.message,
              placement: 'topRight'
            });
        }
    }

    deleteSurgeryAdvised(element) {
        this.setState({
          deleteSurgeryModal: true,
          deleteId: element.id,
        })
    }

    closeModalPopup() {
        this.setState({
          deleteSurgeryModal: false,
          deleteId: null
        })
    }

    async deleteSurgery() {
        let PARAMS = {
          surgery_request_id: this.state.deleteId
        }
        await axios.post(SURGERY_DELETE, PARAMS).then(async success => {
          await this.getExisitingSurgeries();
          await this.closeModalPopup();
          notification.success({
            message: 'Surgery deleted successfully',
            placement: 'topRight'
          });
        });
    }

    surgeryListLoad () {
        var SpanData = true;
        var surgery_length = this.state.existingSurgeriesList.length;
        if(surgery_length > 0)
        {
            return this.state.existingSurgeriesList.map((result, i) => {
                let htmlData = (
                  <tr>
                    <td>{i+1}</td>
                    <td>{result.surgery_name}</td>
                    { this.state.viewMode == false ? 
                        <td className="text-center"><a className="nostyle-link mr-2" onClick={() => this.deleteSurgeryAdvised(result)} ><i className="icon_delete"></i></a></td>
                        :
                        null
                    }
                  </tr>
                );
                return htmlData;
            });
        }
        else
        {
            let colSpanData = (this.state.viewMode == false) ? 3 : 2;
            let htmlData = (
                <tr>
                    <td className="text-center" colSpan={colSpanData}>No Data Found</td>
                </tr>
            );
            return htmlData;
        }
      } catch (err) {
        console.log('surgeryListLoad: ', err);
    }

    render() {
        return (
            <Fragment>
                <div className="col-12 px-0">
                    <div className="emrfrm">
                        <div className="row">
                            { this.state.viewMode == false ? 
                                <>
                                    <div className="col-lg-12">
                                        <div className="row mt-2">
                                            <div className="col-lg-12 medicine_star">
                                                <div className="form-group add_buttons">
                                                    <AutoComplete
                                                        id="surgery-name"
                                                        name="surgery_id"
                                                        style={{ width: 100 + '%' }}
                                                        placeholder=" "
                                                        showSearch
                                                        ref={this.surgerySelectRef}
                                                        notFoundContent={this.state.searchResultLoaded ? LOADER_BLACK : null}
                                                        loading={this.state.searchResultLoaded}
                                                        onSelect={this.onSurgerySelect}
                                                        onSearch={this.onSurgerySearch}
                                                        className="select-drugs add_button"
                                                        value={this.state.selectSurgeryValue}
                                                        filterOption={false}
                                                        autoFocus={this.state.selectSurgeryValue != null && this.state.selectSurgeryValue != '' ? false : true}
                                                        showAction={this.state.selectSurgeryValue != null && this.state.selectSurgeryValue != '' ? [] : ['focus', 'click']}
                                                    >
                                                        {this.optionSurgeryLoad()}
                                                    </AutoComplete>
                                                   
                                                    <label htmlFor="surgery-name">Surgery / Procedure / Package<span className="text-danger">*</span></label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3 mb-0">
                                      
                                        <div className="text-right">
                                            <Button className="btn-outline-secondary px-4 small text-uppercase mr-3"
                                                id="drug-clear" onClick={() => this.Formclear()}
                                            >Clear</Button>
                                            <Button
                                                type="button"
                                                className="btn btn-primary px-4 small text-uppercase mandatory-save"
                                                id="drug-save"
                                                disabled={!this.state.allowEdit?true:this.state.saveBtnDisable}
                                                onClick={() => this.addSurgeryList()}
                                            >Add</Button>
                                        </div>
                                    </div>
                                </>
                                : null
                            }
                            <div className='col-12 mt-3'>
                                <div className="table-responsive">
                                    <table className="table table-bordered rounded-0 surgerytable">
                                        <thead>
                                            <tr>
                                                <th>S.NO</th>
                                                <th>SURGERY NAME</th>
                                                { this.state.viewMode == false ? 
                                                    <th className="text-center">Action</th>
                                                    :
                                                    null
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this.surgeryListLoad() }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    visible={this.state.deleteSurgeryModal}
                    onCancel={() => this.closeModalPopup()}
                    style={{ top: 40 }}
                    title="Are you sure you want to delete surgery?"
                    closable={false}
                    footer={[
                    <div className="text-center">
                    <button
                    className="btn btn-outline-secondary px-5"
                    onClick={() => this.closeModalPopup()}
                    >
                    CANCEL
                    </button>
                    <button
                    className="btn btn-primary px-5 ml-1"
                    disabled={this.state.spin_loading}
                    id='deleteButton'
                    onClick={() => this.deleteSurgery()}
                    >
                    DELETE{this.state.spin_loading ? LOADER : ""}
                    </button>
                    </div>,
                    ]}>
                </Modal>
            </Fragment>
        );
    }
}

export default SurgeryForm;