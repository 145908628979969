import * as React from 'react';
import { withRouter } from "react-router-dom";
import { Dropdown, Menu, Drawer, List, Radio, Avatar, Form, Input } from 'antd';
import './multi-party-calling.css';

import { SettingOutlined, LayoutOutlined, AppstoreOutlined, UserAddOutlined, 
        ArrowLeftOutlined, UserOutlined, ZoomInOutlined, ZoomOutOutlined, MenuOutlined,
        FullscreenExitOutlined, UsergroupAddOutlined, ExpandOutlined
} from '@ant-design/icons';
import { MultiPartyCallService } from '../../../utils/rxjs-sharing';
import VC_HELPER from '../../../utils/vc-helpers';
import LS_SERVICE from '../../../utils/localStorage';
import { notification } from 'antd';
// import initLayoutContainer from 'opentok-layout-js';
import axios from "axios";
import {MPC_DOCTORS, MPC_DOCTOR_JOIN_CALL} from '../../../utils/constant';
import USER_AVATAR from '../../../assets/images/user2.png';

const { Search } = Input;

class VonageMultiPartyCalling extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showMpcWindow: false,
            showDoctorList: false,
            layout: 'draggable_layout', // draggable_layout, compact_layout
            focusedAt: null,
            frameRate: 7,
            resolution: '320x240',
            isMuted: false,
            isVideoDisabled: false,
            callInfo: {},
            opentokInfo: {},
            addOnDoctors: [],
            selectedAddonDoctor: {
                id: null,
                name: null
            },
            searchingDoctor: false,
            patientSlug: 'patient',
            reconnecting: false,
            reconnected: false,
            reconnecting_msg: null
        }
        this.mainSubscribers = [];
        this.handleVonageError = this.handleVonageError.bind(this);
    }

    componentDidMount() {
        axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
        this.bindToOutgoingCalls();
        // this.getAddOnDoctors('a'); // :Fix
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    bindToOutgoingCalls() {
        this.subscription = MultiPartyCallService.status().subscribe(result => {
            // console.log('>> salman mpcc result', result);
            if (result && result.start) {
                LS_SERVICE.set('is_homecarecall', result.is_homecarecall);
                if (result.is_homecarecall) {
                    LS_SERVICE.set('homecare_booking_id', result.booking_id);
                }
                // // window.InncVonage.setCallRatingApi(CALL_RATING);
                // window.InncVonage.setCallRatingApi(result.is_homecarecall ? HOMECARE_CALL_RECORD_RATING : CALL_RATING);
                if (result.type === 'call_initiated_by_doctor') {
                    this.callUser(result);
                }
                if (result.type === 'incoming_call_received_by_doctor') {
                    const is_junior_doctor = LS_SERVICE.get('is_junior_doctor');
                    const doctor = LS_SERVICE.get('doctor');
                    const doctor_slug = (is_junior_doctor ? ' (JDR)' : ' (DR)')
                    const streamData = {
                        ...result,
                        is_junior_doctor: is_junior_doctor,
                        doctor_name: (doctor.name + doctor_slug),
                        totalParticipants: result.number_of_participants
                    };
                    this.startStreaming(streamData);
                }
                this.setState({
                    callInfo: result
                }, () => {
                    this.getAddOnDoctors('');
                });
            }
            if (result && result.end) {
                if (result.type === 'call_declined_by_patient') {
                    this.endCall({ notify: true });
                    return;
                }
                // this.endCall({
                //     notify: (typeof result.notify != 'undefined') ? result.notify : true
                // });
            }
            // if (result && result.message) {
            //     this.updateVideoCallMsg({
            //         message: result.message
            //     });
            // }
        });
    }

    async callUser(data) {
        notification.destroy();
        const opentok = await VC_HELPER.getOtSessionToken(data);
        // console.log('>> Salman opentok', opentok)
        if (typeof opentok.data == 'undefined') {
            return;
        }
        if (typeof opentok.data.message != 'undefined') {
            notification.open({
                message: 'Video Calling',
                description: opentok.data.message
            });
            return;
        }
        this.setState({
            opentokInfo: opentok.data
        }, () => {
            const is_junior_doctor = LS_SERVICE.get('is_junior_doctor');
            const doctor = LS_SERVICE.get('doctor');
            const doctor_slug = (is_junior_doctor ? ' (JDR)' : ' (DR)')
            const streamData = {
                ...opentok.data,
                is_junior_doctor: is_junior_doctor,
                doctor_name: (doctor.name + doctor_slug),
                totalParticipants: data.totalParticipants
            };
            this.startStreaming(streamData);
        });
    }

    startStreaming(vcdata) {
        const {frameRate, resolution} = this.state;
        // const OTOBJ = window.OT;
        console.log('>>> salman vcdata', vcdata)

        this.setState({
            layout: 'compact_layout',
            showMpcWindow: true,
            isMuted: false,
            isVideoDisabled: false
        });

        const $this = this;
        const apiKey = vcdata.key;
        const sessionId = vcdata.session_id;
        const token = vcdata.token;

        const room = new window.VideoExpress.Room({
            apiKey: apiKey, // add your OpenTok API key
            sessionId: sessionId, // add your OpenTok Session ID
            token: token, // add your OpenTok token
            roomContainer: 'vc_multiparty_subscribers',
            participantName: vcdata.doctor_name,
            managedLayoutOptions: {
                speakerHighlightEnabled: true,
                speakerHighlightColor: '#d24350',
                layoutMode: "active-speaker", // grid, active-speaker
                deviceLayoutMode: "desktop", // desktop, mobile
            }
        });
        // console.log('>>> salman room', room)
        this.room = room;
        room.join({
            publisherProperties: {
                resolution: resolution,
                frameRate: frameRate,
                mirror: false,
                // audioBitrate: 15,
                // audioFallbackEnabled: true,
            }
        });

        room.on('connected', (participant, reason) => {
            // console.log('connected: ', participant);
            // console.log('Salman OOOOOOO participant left: ', participant.name);
            // console.log('Salman OOOOOOO participant reason: ', reason);
            room.camera.setDisabledImageURI(USER_AVATAR);

            setTimeout(() => {
                $this.checkForTotalParticipants(vcdata.totalParticipants);
            }, 1000);
        });

        room.on('participantJoined', (participant) => {
            // console.log('>> salman participantJoined', participant?.name, participant)
            // console.log('>> salman participantJoined', participant.connectionData)
            // console.log('>> salman participantJoined', JSON.parse(participant.connectionData))
            participant.on('cameraCreated', (cameraSubscriber) => {
                cameraSubscriber.setDisabledImageURI(USER_AVATAR);
            });
        });

        room.on('participantLeft', async (participant, reason) => {
            // console.log('>> salman participant left: ', participant?.name, participant);
            $this.checkInCallParticipants();
        });

        room.on("reconnecting", () => {
            $this.reconnecting();
        });
        room.on("reconnected", () => {
            $this.reconnected();
            // $this.checkInCallParticipants();
            setTimeout(() => $this.rejoined(), 1500);
        });
        room.on('disconnected', () => {
            notification.destroy();
            notification.info({
                message: `Call Disconnected`,
                description: "Call has been disconnected!"
            });
            $this.rejoined();
            $this.endCall();
        });
    }

    reconnecting() {
        this.setState({
            reconnecting: true,
            reconnected: false,
            reconnecting_msg: 'Reconnecting...'
        });
    }

    reconnected() {
        this.setState({
            reconnecting: false,
            reconnected: true,
            reconnecting_msg: 'Reconnected!'
        });
    }

    rejoined() {
        this.setState({
            reconnecting: false,
            reconnected: false,
            reconnecting_msg: null
        })
    }

    checkInCallParticipants() {
        const {patientSlug} = this.state;
        const allParticipants = {...this.room.participants};
        console.log('>>> MPCC allParticipants', allParticipants)
        let patientAvailable = [];

        for (const [vonageParticipantId, participant] of Object.entries(allParticipants)) {
            // if (typeof participant.isMe != 'undefined' && participant.isMe) {
            //     continue;
            // }
            // console.log('>> salman checkInCallParticipants', participant.connectionData)
            // console.log('>> salman checkInCallParticipants', JSON.parse(participant.connectionData))
            let participantType = null;
            if (participant.connectionData != undefined && participant.connectionData != null) {
                const connectionData = JSON.parse(participant.connectionData);
                participantType = connectionData.type;
            }
            // console.log('>> MPCC participant.name', participant?.name)
            // if (typeof participant.name != 'undefined' && participant.name.indexOf(patientSlug) !== -1) {
            if (participantType == "patient") {
                patientAvailable.push(patientSlug);
            }
        }

        // console.log('>>> MPCC patientAvailable', patientAvailable)

        if (patientAvailable.length == 0) {
            notification.info({
                message: `Call Disconnected`,
                description: "Call has been disconnected as there is no patient available in the video call!"
            });
            this.endCall();
        }
    }

    checkForTotalParticipants(maxParticipants) {
        const allParticipants = {...this.room.participants};
        if (Object.values(allParticipants).length > maxParticipants) {
            notification.info({
                message: `Call Disconnected`,
                description: "Maximum number of users have already joined this call!"
            });
            this.endCall();
        }
    }

    async endCall(data) {
        // if (this.mainSubscribers.length > 0) {
        //     for (const subscriber of this.mainSubscribers) {
        //         this.session.unsubscribe(subscriber);
        //     }
        // }
        // if (this.mainPublisher != null) {
        //     this.mainPublisher.destroy();
        // }
        // this.session.disconnect();

        this.room.leave();
        this.setState({
            isMuted: false,
            isVideoDisabled: false,
            layout: 'draggable_layout',
            showMpcWindow: false
        });
        document.getElementById('vc_multiparty_subscribers').innerHTML = '';
    }

    toggleMicrophone() {
        if (typeof this.room == 'undefined') {
            return;
        }
        const {isMuted} = this.state;
        if (isMuted) {
            this.room.camera.enableAudio();
        } else {
            this.room.camera.disableAudio();
        }
        this.setState({isMuted: !isMuted});
    }

    toggleSelfVideo() {
        if (typeof this.room == 'undefined') {
            return;
        }
        const {isVideoDisabled} = this.state;
        if (isVideoDisabled) {
            this.room.camera.enableVideo();
        } else {
            this.room.camera.disableVideo();
        }
        this.setState({isVideoDisabled: !isVideoDisabled});
    }

    handleVonageError(err) {
        console.log('>> Vonage Error', err);
    }

    callSettings(key) {
        if (key === 'compact_layout' || key === 'draggable_layout') {
            if (this.room != undefined) {
                this.room.setLayoutMode((key === 'draggable_layout') ? 'grid' : 'active-speaker');
            }
            this.setState({
                layout: key
            });
            document.getElementById('vmp_vc_dialog').removeAttribute('style');
        }
        if (key === 'add_doctor') {
            this.setState({
                showDoctorList: true,
                selectedAddonDoctor: {
                    id: null,
                    name: null
                }
            });
        }
    }

    videoOptions(key, frameIndex) {
        this.setState({
            focusedAt: (key == 'focus_in') ? frameIndex : null
        });
    }

    async getAddOnDoctors(keyword) {
        this.setState({
            searchingDoctor: true
        });
        try {
            const {callInfo} = this.state;
            const hospitalId = callInfo.hospital_id;
            // const hospitalId = 2; // :Fix
            const response = await axios.get(MPC_DOCTORS + '?hospital_id='+ hospitalId +'&keyword='+ keyword);
            if (typeof response.data != 'undefined' && typeof response.data.doctors != 'undefined') {
                this.setState({
                    addOnDoctors: response.data.doctors,
                    searchingDoctor: false
                });
            } else {
                this.setState({
                    addOnDoctors: [],
                    searchingDoctor: false
                });
            }
        } catch (error) {
            this.setState({
                addOnDoctors: [],
                searchingDoctor: false
            });
        }
    }

    async inviteDoctorToJoinCall() {
        notification.destroy();
        const {selectedAddonDoctor, callInfo, opentokInfo} = this.state;
        try {
            const response = await axios.post(MPC_DOCTOR_JOIN_CALL, {
                booking_id: callInfo.booking_id,
                doctor_id: selectedAddonDoctor.id,
                session_id: opentokInfo.session_id
            });
            if (typeof response.data != 'undefined' && typeof response.data.token != 'undefined') {
                this.setState({
                    showDoctorList: false
                }, () => {
                    notification.open({
                        message: 'Invite Doctor',
                        description: 'Invite has been sent to doctor for joining the call.'
                    });
                });
            } else {
                notification.error({
                    message: 'Invite Doctor',
                    description: response?.data?.message
                });
            }
        } catch (error) {
            notification.error({
                message: 'Invite Doctor',
                description: JSON.stringify(error)
            });
        }
    }

    render() {
        setTimeout(() => {
            VC_HELPER.dragElement();
        }, 500);
        const { callInfo, layout, reconnecting, reconnected, reconnecting_msg, showDoctorList, isMuted, isVideoDisabled, showMpcWindow, addOnDoctors, selectedAddonDoctor, searchingDoctor } = this.state;
        const mpcWindowClass = (showMpcWindow) ? '' : 'hidden';

        return (
            <React.Fragment>
                <div data-booking_id={callInfo?.booking_id} id="vmp_vc_dialog" className={mpcWindowClass + " multi_party_calling " + layout}>
                    <div className="multi_party_calling_header">
                        {/* {(layout === 'draggable_layout') ? <div className="row">
                            <div className="col-2 py-2 px-3">
                                {(layout === 'compact_layout') ? <strong>Video Conferencing</strong> :
                                    <button id="vmp_vc_dialog_drag" type="button" className="control-btn vc-back-btn" title="Hold this icon and drag this whole element">
                                        <img src="/assets/images/drag.png" alt="Drag" style={{ width: '20px' }} />
                                    </button>}
                            </div>
                            <div className="col-10 py-2 px-3 text-right">
                                {vcOpts}
                            </div>
                        </div> : null} */}
                        {(layout === 'draggable_layout') ? <div id="vmp_vc_dialog_drag" title="Hold and drag this element" className="drag-item-panel">:::</div> : null}

                        {/* {(layout === 'compact_layout') ? <div className="topLeftOpts">
                            <button type="button" onClick={() => this.callSettings('draggable_layout')} className="btn">
                                <ArrowLeftOutlined />
                            </button>
                        </div> : null} */}

                        {/* {(layout === 'compact_layout') ? <div className="topRightOpts">
                            {vcOpts}
                        </div> : null} */}
                    </div>
                    <div className="multi_party_calling_body">
                        {/* <div className="w_100pcent">
                            <div id="vc_doctor_screen_container" className="frames">
                                <div id="vc_doctor_screen" className="frames"></div>
                            </div>
                        </div> */}
                        {/* className={focusOutWidget} */}
                        <div id="vc_multiparty_subscribers"></div>
                        {/* Here were subscribers */}
                    </div>
                    <div className="multi_party_calling_footer text-center">
                        <button onClick={() => this.toggleSelfVideo()} type="button" className={"active control-btn"} title={isVideoDisabled ? "Enable Video" : "Disable Video"}>
                            <img src={isVideoDisabled ? "/assets/images/video_off.png" : "/assets/images/video_on.png"} alt="mic-icon" />
                        </button>
                        <button onClick={() => this.toggleMicrophone()} type="button" className={"active control-btn"} title={isMuted ? "Unmute Microphone" : "Mute Microphone"}>
                            <img src={isMuted ? "/assets/images/mic-muted.png" : "/assets/images/mic.png"} alt="mic-icon" />
                        </button>
                        <button onClick={() => this.endCall({ notify: true })} type="button" className="control-btn" title="End Call">
                            <img src="/assets/images/endcall.png" alt="icon" />
                        </button>
                        {(layout === 'compact_layout') ? <button onClick={() => this.callSettings('draggable_layout')} type="button" className="control-btn minimize-btn" title="Minimize">
                            <FullscreenExitOutlined />
                        </button> : <button onClick={() => this.callSettings('compact_layout')} type="button" className="control-btn minimize-btn" title="Enlarge Screen">
                            <ExpandOutlined />
                        </button>}
                        <button onClick={() => this.callSettings('add_doctor')} type="button" className="control-btn adddoc-btn" title="Add Doctor">
                            <UsergroupAddOutlined />
                        </button>

                        {reconnecting || reconnected ? <div className={reconnecting ? "alert alert-danger container" : "alert alert-success container"}>{reconnecting_msg}</div> : null}

                    </div>
                </div>

                <Drawer 
                    title="Add Doctor to Call" 
                    placement="right" 
                    visible={showDoctorList} 
                    onClose={() => this.setState({showDoctorList: false})}
                    footer={(
                        <button onClick={() => this.inviteDoctorToJoinCall()} disabled={selectedAddonDoctor.id == null} className={"btn btn-block " + (selectedAddonDoctor.id == null ? 'btn-primary' : 'btn-success')}>
                            {selectedAddonDoctor.id != null ? <React.Fragment>
                                Call <strong>{selectedAddonDoctor.name}</strong>
                            </React.Fragment> : 'Add to Call'}
                        </button>
                    )}
                >
                    <div className="row pb-2">
                        <div className="col-12">
                            <Search size="large" placeholder="Search by name" prefix={<UserOutlined />} loading={searchingDoctor} onSearch={(e) => this.getAddOnDoctors(e)} enterButton />
                        </div>
                    </div>

                    <List
                        dataSource={addOnDoctors}
                        renderItem={item => (
                            <List.Item 
                                key={Math.random()}
                                onClick={(e) => this.setState({selectedAddonDoctor: {
                                    // id: e.target.value,
                                    // name: e.target.label
                                    id: item.doctor_id,
                                    name: item.doctor_name
                                }})}
                                style={{cursor: 'pointer'}}
                            >
                                <List.Item.Meta
                                    avatar={<Avatar src={item.doctor_profile_picture} />}
                                    title={<strong style={{textTransform: 'capitalize'}}>{item.doctor_name}</strong>}
                                    description={item.doctor_speciality}
                                />
                                <div>
                                    <Form.Item initialValue={null}>
                                        {/* <Radio.Group value={null}> */}
                                        <Radio 
                                            // onChange={(e) => this.setState({selectedAddonDoctor: {
                                            //     id: e.target.value,
                                            //     name: e.target.label
                                            // }})}
                                            checked={item.doctor_id == selectedAddonDoctor.id}
                                            value={item.doctor_id}
                                            label={item.doctor_name}
                                        ></Radio>
                                        {/* </Radio.Group> */}
                                    </Form.Item>
                                </div>
                            </List.Item>
                        )}
                    />
                </Drawer>
            </React.Fragment>
        );
    }

}

export default withRouter(VonageMultiPartyCalling);
