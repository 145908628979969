import React, { Component } from "react";
import Axios from 'axios';
import { notification } from 'antd';
import LS_SERVICE from '../../utils/localStorage';
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import {
  LOADER,
  LOADER_RED,
  VITALS_FILTER_SEQUENCING_LIST,
  VITALS_FILTER_SEQUENCING_SAVE,
  VISITS_BY_DOCTOR,
  NEW_VITAL_ORDER
} from '../../utils/constant';

const DragHandle = sortableHandle(() => <div class="btn_sequence"></div>);

const SortableContainer = sortableContainer(({children}) => {
  return <td colSpan='4' style={{padding: '0', borderTop: '0'}}>{children}</td>;
});

const SortableItem = sortableElement(({ value, index1, checkBoxChange }) => {
  return (
    <table className="table table_border table_key_findings_sequence emrfrm" style={{marginBottom: '0px'}}>
      <tr>
        <td style={{width: '61px', fontWeight: '400', borderBottom: '0', borderTop: '0', borderLeft: '0'}}> {value.display_order != 0 ? value.display_order : ''}</td>
        <td style={{fontWeight: '400', borderBottom: '0', borderTop: '0'}}> {value.display_name} {value.display_value ? '(' + value.display_value + ')' : ''}</td>
        <td style={{width: '88px', borderBottom: '0', borderTop: '0'}}>
          <DragHandle />
        </td>
        <td style={{width: '125px', borderBottom: '0', borderTop: '0', borderRight: '0'}}>
          <div className="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" className="custom-control-input" id={`select_${index1}`}
              name={`select_${index1}`}
              /* disabled={this.renderDisabled(dt)} */
              /* value={dt.id} */
              checked={value.display_order != 0}
              onChange={e => checkBoxChange(e, value)} />
            <label className="custom-control-label" htmlFor={`select_${index1}`}></label>
          </div>
        </td>
      </tr>
    </table>
  )
});

class VitalsFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vitalsConstants: Object.values(props.vitalsConstants),
      vitalsChecked: [],
      vitalsLoaded: false,
      spin_loading: false
    };
  }

  async componentDidMount() {
    await this.loadVitalFilterData();
    await this.reArrangeVitals();
    await this.reOrderList();
  }

  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return true;
  }

  loadVitalFilterData = async () => {

    const vitalFilterPromise = await Axios.get(
      VITALS_FILTER_SEQUENCING_LIST
    );
    let data = vitalFilterPromise.data.data;
    if (vitalFilterPromise.data.status) {
      this.setState({
        vitalsChecked: data,
        vitalsLoaded: true
      });
    }
  };

  // To rerrange the sequence if a vital is made a key finding in the masters
  reArrangeVitals = async () => {
    let newList = [];
    let display_order = 1;
    this.state.vitalsChecked.forEach((val, j) => {
      let findex = this.state.vitalsConstants.findIndex(x => x.vital_id === val.vital_id);
      if (findex !== -1){
        newList.push({
          vital_id: val.vital_id,
          display_order: display_order
        });
        display_order++;
      }
    });
    await this.setState({vitalsChecked: newList});
  }

  reOrderList = async () => {
    let newList = [];

    this.state.vitalsConstants.forEach((val, j) => {
      if(val.vital_code != 'V006'){
        if(val.vital_code == 'V005'){
          val.display_name = 'Blood Pressure'
        }
        let findex = this.state.vitalsChecked.findIndex(x => x.vital_id === val.vital_id);
        if (findex === -1){
          newList.push({...val, 'display_order': 0});
        } else {
          newList.push({...val, 'display_order': this.state.vitalsChecked[findex].display_order});
        }
      }
    });
    newList = newList.sort((a, b) => {
      if (a.display_order == 0)
        return 1;
      if (b.display_order == 0)
        return -1;
      return a.display_order - b.display_order
    });
    await this.setState({vitalsConstants: newList});
  }

  onSortEnd = async ({oldIndex, newIndex}) => {
    await this.setState(prevState => {
      let prevItems = [...prevState.vitalsConstants];
      let prevItemsChecked = [...prevState.vitalsChecked];
      prevItemsChecked = arrayMove(
        prevState.vitalsChecked,
        oldIndex,
        newIndex,
      );
      prevItems = arrayMove(
        prevState.vitalsConstants,
        oldIndex,
        newIndex,
      );
      return {vitalsConstants: prevItems, vitalsChecked: prevItemsChecked}
    });
    await this.displayOrderList();
    this.reOrderList();
  };

  handleOnCheckBoxChange = async (e, value) => {
    let findex = this.state.vitalsChecked.findIndex(x => x.vital_id === value.vital_id);
    if (findex === -1){
      await this.setState(prevState => {
        let obj = {
          vital_id: value.vital_id
        };
        const newItems = [...prevState.vitalsChecked, obj];
        return {vitalsChecked: newItems}
      });
    } else {
      await this.setState(prevState => ({
        vitalsChecked: prevState.vitalsChecked.filter((d, i) => i !== findex)
      }));
    }
    await this.displayOrderList();
    this.reOrderList();
  }

  displayOrderList = async () => {
    let newList = [];
    let display_order = 1;
    this.state.vitalsChecked.forEach((val, j) => {
      newList.push({...val, 'display_order': display_order});
      display_order++;
    });
    await this.setState({vitalsChecked: newList});
  }

  handleOnCheckBoxChangeSelectAll = async (select_all) => {
    let newList = [];
    if(!select_all){
      this.state.vitalsConstants.forEach((val, i) => {
        let obj = {
          vital_id: val.vital_id
        };
        newList.push(obj)
      });
    }
    await this.setState({vitalsChecked: newList});
    await this.displayOrderList();
    this.reOrderList();
  }

  handleModalSubmit = async ( e ) => {

    const { spin_loading } = this.state;
    this.setState({
      spin_loading: !spin_loading
    });
    const PARAMS = {
      vitals: this.state.vitalsChecked
    };

    await Axios.post(VITALS_FILTER_SEQUENCING_SAVE, PARAMS)
    .then(async success => {
      if (success?.data?.status == true) {
        const { spin_loading } = this.state;
        this.setState({
          spin_loading: !spin_loading
        });
        this.props.handleModalPopup(e, "chartParamerModelVital");
        this.props.updateParentState(this.state.vitalsChecked);
      } else {
        notification.error({
          message: success?.data?.message,
          placement: 'topRight'
        });
      }
    }).catch(err => console.log(err));
  };

  render() {

    let select_all = true;

    this.state.vitalsConstants.map((g, index) => {
      if(g.display_order == 0){
        select_all = false;
      }
    })
    return (
      <React.Fragment>
        { !this.state.vitalsLoaded ? <div className={`w-100 align-items-center justify-content-center mt-4 d-flex`}>{LOADER_RED}</div> : 
        <>
          <table className="table table_border table_key_findings_sequence emrfrm">
            <tr>
              <td className="text-center">S NO.</td>
              <td>Parameters</td>
              <td className="text-center">Sequence</td>
              <td style={{width: '126px'}}>
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input type="checkbox" className="custom-control-input" id='select_all'
                    name='select_all'
                    /* disabled={this.renderDisabled(dt)} */
                    /* value={dt.id} */
                    checked={select_all}
                    onChange={e => this.handleOnCheckBoxChangeSelectAll(select_all)} />
                  <label className="custom-control-label" htmlFor='select_all'> Select All</label>
                </div>
              </td>
            </tr>
            <tr>
                <SortableContainer helperClass='sortableHelper' onSortEnd={this.onSortEnd} lockAxis={'y'} lockToContainerEdges={false} disableAutoscroll={true} useDragHandle>
                  {this.state.vitalsConstants.map((c, j) => {
                    return <SortableItem
                      key={j}
                      value={c}
                      index={j}
                      index1={j}
                      checkBoxChange={this.handleOnCheckBoxChange}
                      /* collection={parent_index} */
                    />
                  }
                  )}
                </SortableContainer>
            </tr>
          </table>
          <div className="text-center justify-content-center">
            <button
              type="submit"
              className="btn btn-primary text-uppercase ml-2"
              disabled={this.state.spin_loading}
              onClick={(e) => this.handleModalSubmit(e)}
            >
              APPLY{this.state.spin_loading ? LOADER : ''}
            </button>
          </div> 
        </> }
      </React.Fragment>
    )
  }
}

export default VitalsFilters;