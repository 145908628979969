import React, { Component } from "react";
import { Form, Modal, Input, Radio, Select, Checkbox, DatePicker, notification, Popover } from "antd";
import { speechService, speechTextService } from '../../utils/rxjs-sharing';

import { Link } from "react-router-dom";
import moment from "moment";

const { Option } = Select;

class MultiplePregnancies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      no_of_fetus: 2,
      same_for_all: false,
      indication_for_lscs: false,
      abnormality_impressions: {},
      indication_reason: null,
      multiplePregnancies: [
        { delivery_type: null, outcome: null, birth_weight: null, place_of_delivery: null, birth_date_time: null, abnormality: null },
        { delivery_type: null, outcome: null, birth_weight: null, place_of_delivery: null, birth_date_time: null, abnormality: null }
      ],
      delivery_options: [
        { val: '1', label: 'Normal' },
        { val: '2', label: 'LSCS' },
        { val: '3', label: 'Forceps' },
        { val: '4', label: 'Water Birthing' }
      ],
      gender_options: [
        { val: '1', label: 'Male' },
        { val: '2', label: 'Female' },
        { val: '3', label: 'Transgender' }
      ],
      outcome_options: [
        { val: '1', label: 'Live Term' },
        { val: '2', label: 'Live Pre-Term' },
        { val: '3', label: 'Still born Term' },
        { val: '4', label: 'Still born Pre-Term' },
        { val: '5', label: 'Spontaneous Abortion' },
        { val: '6', label: 'MTP' }
      ],
      place_of_delivery_options: [
        { val: '1', label: 'Hospital' },
        { val: '2', label: 'Home' },
        { val: '3', label: 'PHC' },
        { val: '4', label: 'Others' }
      ],
      speech: {
        identifier: null,
        state: false
      }
    }
    this.FormRef = React.createRef();
    /* this.handleModalSave = this.handleModalSave.bind(this); */
  }

  async componentDidMount() {
    this.subscription = speechTextService.status().subscribe(data => {
      if (this.state.speech.state) {
        if (this.state.speech.identifier === 'indication_reason') {
          this.setState({ indication_reason: data.text });
          this.FormRef.current.setFieldsValue({
            indication_reason: data.text
          });
        }
      }
    });
    if (this.props.pregnancies != undefined && this.props.pregnancies.length > 0) {
      let pregnancies_data = [];
      let pregnancies = JSON.parse(JSON.stringify(this.props.pregnancies));
      pregnancies.map(ele => {
        pregnancies_data.push({
          birth_date_time: moment(ele.birth_date_time),
          birth_weight: ele.birth_weight,
          delivery_type: ele.delivery_type,
          gender: ele.gender,
          outcome: ele.outcome,
          place_of_delivery: ele.place_of_delivery,
          abnormality: ele?.abnormality ? true : false || false,
          abnormality_remark: ele?.abnormality_remark || ""
        })
      })
      this.FormRef.current.setFieldsValue({
        no_of_fetus: this.props.pregnancies.length,
        multiple_pregnancies: pregnancies_data,
        indication_reason: this.props.indication_reason
      });
      await this.setState({
        no_of_fetus: this.props.pregnancies.length,
        multiplePregnancies: pregnancies_data,
        indication_reason: this.props.indication_reason
      });
      this.checkLSCSDelivery();
    }
  }

  /* static getDerivedStateFromProps(props, state) {
    console.log(props)
  } */

  async handleCheckboxChange(e, field) {
    const { abnormality_impressions } = this.state;
    await this.setState({
      [field]: !this.state.same_for_all
    })
    if (this.state.same_for_all) {
      let length = this.state.multiplePregnancies.length;
      let first_obj_values = this.state.multiplePregnancies[0];

      if (first_obj_values.abnormality == true) {
        for (let j = 0; j < length; j++) {
          abnormality_impressions[j] = true;
        }
        this.setState({
          abnormality_impressions: abnormality_impressions
        });
      }

      let pregnancies = [];
      for (let i = 0; i < length; i++) {
        let obj = { ...first_obj_values };
        obj.birth_date_time = obj.birth_date_time? moment(obj.birth_date_time) : "";
        await this.setState(prevState => {
          const prevItems = [...prevState.multiplePregnancies];
          prevItems[i] = obj;
          return { multiplePregnancies: prevItems };
        });
        pregnancies.push(obj);
      }
      await this.FormRef.current.setFieldsValue({
        multiple_pregnancies: pregnancies
      });
      this.checkLSCSDelivery();
    }
  }

  async handleChange(event, field) {
    field ? this.setState({
      [field]: event.target.value
    }) : this.setState({
      [event.target.name]: event.target.value
    });

    if (field == 'no_of_fetus' && event.target.value > 1 && event.target.value <= 9) {
      let new_length = event.target.value;
      let prev_length = this.state.multiplePregnancies.length;
      if (new_length > prev_length) {
        let adds = new_length - prev_length;
        for (let i = 0; i < adds; i++) {
          const obj = { delivery_type: null, outcome: null, birth_weight: null, place_of_delivery: null, birth_date_time: null, abnormality: null, abnormality_remark: "", gender: null }
          await this.setState(prevState => {
            const newItems = [...prevState.multiplePregnancies, obj];
            return { multiplePregnancies: newItems };
          });
        }
      } else if (prev_length > new_length) {
        let removes = prev_length - new_length;
        let index = prev_length - 1;
        for (let i = 0; i < removes; i++) {
          let prevValues = await this.FormRef.current.getFieldsValue();
          await this.setState(prevState => ({
            multiplePregnancies: prevState.multiplePregnancies.filter((data, j) => j !== index)
          }));
          await prevValues['multiple_pregnancies'].splice(index, 1);
          await this.FormRef.current.setFieldsValue({
            ...prevValues
          })
          index--;
        }
        this.checkLSCSDelivery();
      }
    }
  }

  onSelectChangeListMultiple = async (value, props, field, index) => {
    if (field == "birth_date_time") {
      await this.setState(prevState => {
        const prevItems = [...prevState.multiplePregnancies];
        prevItems[index][field] = props;
        return { multiplePregnancies: prevItems };
      });
    }
    else {
      await this.setState(prevState => {
        const prevItems = [...prevState.multiplePregnancies];
        prevItems[index][field] = value;
        return { multiplePregnancies: prevItems };
      });
      if (field == 'delivery_type') {
        this.checkLSCSDelivery();
      }
    }
  }

  onSelectCheckChangeListMultiple = async (e, field, index) => {
    const { abnormality_impressions } = this.state;
    abnormality_impressions[index] = e.target.checked;

    this.setState({
      abnormality_impressions: abnormality_impressions
    });

    await this.setState(prevState => {
      const prevItems = [...prevState.multiplePregnancies];
      prevItems[index][field] = e.target.checked;
      return { multiplePregnancies: prevItems };
    })
  }

  checkLSCSDelivery = async () => {
    let lscs_count = 0;
    this.state.multiplePregnancies.forEach((val, key) => {
      if (val.delivery_type == '2') {
        lscs_count++;
      }
    })
    if (lscs_count > 0) {
      this.setState({
        indication_for_lscs: true
      })
    } else {
      this.FormRef.current.setFieldsValue({
        indication_reason: null
      });
      this.setState({
        indication_reason: null,
        indication_for_lscs: false
      })
    }
  }

  handleChangeMultiple = async (event, field, index) => {
    event.persist();
    await this.setState(prevState => {
      const prevItems = [...prevState.multiplePregnancies];
      prevItems[index][field] = event.target?.value;
      return { multiplePregnancies: prevItems };
    });
  }

  handleFormSubmit = async (values) => {
    this.props.handleModalSave(values);
  }

  initSpeech = async (info) => {
    let initialText = '';
    if (info.identifier === 'indication_reason') {
      initialText = this.state.indication_reason != undefined ? this.state.indication_reason : '';
    }

    if (info.identifier === this.state.speech.identifier) {
      this.setState({
        speech: {
          identifier: info.identifier,
          state: !this.state.speech.state,
          initialText: initialText
        }
      }, () => {
        speechService.init(this.state.speech);
      });
    } else {
      this.setState({
        speech: {
          identifier: this.state.speech.identifier,
          state: false,
          initialText: ''
        }
      }, () => {
        speechService.init(this.state.speech);
        setTimeout(() => {
          this.setState({
            speech: {
              identifier: info.identifier,
              state: true,
              initialText: initialText
            }
          }, () => {
            speechService.init(this.state.speech);
          });

        }, 1000);
      });
    }
  }

  validateNumber = (rule, value, callback) => {
    const isValidInput = /^(\d{1,2}(\.\d{0,2})?)?$/.test(value) && parseFloat(value) <= 10;

    if (isValidInput || value === '') {
      callback();
    } else {
      callback('Only allowed (maximum 10 and upto two decimals).');
    }
  };

  normalizeValue = (value) => {        
    return value === "" || value === undefined ? "" : +value
  }

  render() {
    const { multiplePregnancies, abnormality_impressions } = this.state;
    return (
      <Form
        ref={this.FormRef}
        onFinish={this.handleFormSubmit}
        layout="vertical">
        <div className="row">
          <div className="col-2">
            <div className="form-group pl-0 hasdata">
              <input type="text" id="pregnancy-type-2" value="Multiple" disabled className="form-control" placeholder="Pregnancy Type" />
              <label htmlFor="pregnancy-type-2">Pregnancy Type</label>
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <div className={this.state.no_of_fetus ? 'antInputItem hasdata' : 'antInputItem'} >
                <Form.Item name="no_of_fetus" label="No. Of Fetus" id="no_of_fetus"
                  onChange={(e) => this.handleChange(e, 'no_of_fetus')}
                  initialValue={this.state.no_of_fetus}
                  rules={[
                    { required: true, message: 'Please fill No. Of Fetus.' },
                    { pattern: /^[2-9]{1,1}$/, message: 'Not allowed!' },
                    /* {max: 8, message: 'Max 8 chars.'} */
                  ]}
                >
                  <Input className="" /* disabled={!this.state.allowEdit} */ defaultValue={this.state.no_of_fetus} />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>

        {(!multiplePregnancies || multiplePregnancies.length === 0) ? null :
          <Form.List name="multiple_pregnancies">
            {() => (
              multiplePregnancies.map((data, i) => (
                <div key={i} className="row">
                  <div className='col'>
                    <div className={data.birth_date_time ? 'antSelectItem hasdata' : 'antSelectItem'}>
                      <Form.Item
                        initialValue={data.birth_date_time}
                        name={[i, 'birth_date_time']}
                        fieldKey={[i, 'birth_date_time']}
                        label="Birth Date & Time"
                        rules={[
                          { required: data.outcome == 1 || data.outcome == 2, message: 'Birth Date & Time Required' },
                        ]}
                      >
                        <DatePicker showTime
                          format="DD MMM, YYYY HH:mm"
                          placeholder=''
                          style={{
                            width: 100 + "%",
                            height: 41 + "px",
                          }}
                          onChange={(val, props) => this.onSelectChangeListMultiple(val, props, 'birth_date_time', i)}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="col">
                    <div className={data.outcome ? 'antSelectItem hasdata' : 'antSelectItem'}>
                      <Form.Item initialValue={data.outcome} name={[i, 'outcome']} fieldKey={[i, 'outcome']} label="Outcome"
                        rules={[
                          {
                            required: true,
                            message: "Select Outcome",
                          },
                        ]}
                      >
                        <Select showSearch style={{ width: 100 + '%' }}
                          optionFilterProp="children"
                          onChange={(val, props) => this.onSelectChangeListMultiple(val, props, 'outcome', i)}
                          value={data.outcome}
                          filterOption={(input, option) => {
                            return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                          }}
                        >
                          {this.state.outcome_options !== undefined && this.state.outcome_options.length > 0 ? this.state.outcome_options.map((d, i) => (
                            <Option key={i} value={d.val} label={d.label}>{d.label}</Option>
                          )) : null}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col">
                    <div className={data.delivery_type ? 'antSelectItem hasdata' : 'antSelectItem'}>
                      <Form.Item initialValue={data.delivery_type} name={[i, 'delivery_type']} fieldKey={[i, 'delivery_type']} label="Delivery"
                        rules={[
                          {
                            required: data.outcome == 1 || data.outcome == 2,
                            message: "Select Delivery Type",
                          },
                        ]}
                      >
                        <Select showSearch style={{ width: 100 + '%' }}
                          optionFilterProp="children"
                          onChange={(val, props) => this.onSelectChangeListMultiple(val, props, 'delivery_type', i)}
                          value={data.delivery_type}
                          filterOption={(input, option) => {
                            return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                          }}
                        >
                          {this.state.delivery_options !== undefined && this.state.delivery_options.length > 0 ? this.state.delivery_options.map((d, i) => (
                            <Option key={i} value={d.val} label={d.label}>{d.label}</Option>
                          )) : null}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <div className={data.birth_weight ? 'antInputItem hasdata' : 'antInputItem'}>
                        <Form.Item initialValue={data.birth_weight} name={[i, 'birth_weight']} fieldKey={[i, 'birth_weight']} label="Birth Weight (kg)"
                          onChange={(e) => this.handleChangeMultiple(e, 'birth_weight', i)}
                          rules={[
                            { required: data.outcome == 1 || data.outcome == 2, message: 'Please fill Birth Weight.' },
                            // { pattern: /^\d{1,2}(\.\d{1,2})?$/, message: 'Not allowed!' },
                            {
                              validator: this.validateNumber,
                              transform: this.normalizeValue
                          }
                          ]}
                        >
                          <Input initialvalues={data.birth_weight} type="number"/>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className={data.gender ? 'antSelectItem hasdata' : 'antSelectItem'} >
                      <Form.Item initialValue={data.gender} name={[i, 'gender']} fieldKey={[i, 'gender']} label="Gender"
                        rules={[
                          {
                            required: data.outcome == 1 || data.outcome == 2,
                            message: "Select Gender",
                          },
                        ]}
                      >
                        <Select showSearch style={{ width: 100 + '%' }}
                          optionFilterProp="children"
                          onChange={(val, props) => this.onSelectChangeListMultiple(val, props, 'gender', i)}
                          value={data.gender}
                          filterOption={(input, option) => {
                            return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                          }}
                        >
                          {this.state.gender_options !== undefined && this.state.gender_options.length > 0 ? this.state.gender_options.map((d, i) => (
                            <Option key={i} value={d.val} label={d.label}>{d.label}</Option>
                          )) : null}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col">
                    <div className={data.place_of_delivery ? 'antSelectItem hasdata' : 'antSelectItem'}>
                      <Form.Item initialValue={data.place_of_delivery} name={[i, 'place_of_delivery']} fieldKey={[i, 'place_of_delivery']} label="Place of delivery"
                        rules={[
                          {
                            required: data.outcome == 1 || data.outcome == 2,
                            message: "Select Place of Delivery",
                          },
                        ]}
                      >
                        <Select showSearch style={{ width: 100 + '%' }}
                          optionFilterProp="children"
                          onChange={(val, props) => this.onSelectChangeListMultiple(val, props, 'place_of_delivery', i)}
                          value={data.place_of_delivery}
                          filterOption={(input, option) => {
                            return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                          }}
                        >
                          {this.state.place_of_delivery_options !== undefined && this.state.place_of_delivery_options.length > 0 ? this.state.place_of_delivery_options.map((d, i) => (
                            <Option key={i} value={d.val} label={d.label}>{d.label}</Option>
                          )) : null}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col">
                    <Form.Item
                      name={[i, 'abnormality']}
                      valuePropName="checked"
                      onChange={(e) => this.onSelectCheckChangeListMultiple(e, 'abnormality', i)}
                    >
                      <Checkbox>
                        Congenital Abnormality
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {abnormality_impressions[i] == true || data.abnormality == true ?
                    <div className="col">
                      <div className="form-group">
                        <div className={data.abnormality_remark ? 'antInputItem hasdata' : 'antInputItem'}>
                          <Form.Item initialValue={data.abnormality_remark} name={[i, 'abnormality_remark']} fieldKey={[i, 'abnormality_remark']} label="Remark"
                            onChange={(e) => this.handleChangeMultiple(e, 'abnormality_remark', i)}
                            rules={[
                              {
                                required: true,
                                message: "Remark Required",
                              },
                            ]}
                          >
                            <Input initialvalues={data.abnormality_remark} />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    : null}

                </div>
              ))
            )}
          </Form.List>
        }
        <div className="row">
          <div className="col-2">
            <div className="custom-control custom-checkbox custom-control-inline">
              <input type="checkbox" className="custom-control-input" id={`same_for_all`} name={`same_for_all`} checked={this.state.same_for_all} onChange={e => this.handleCheckboxChange(e, 'same_for_all')} />
              <label class="custom-control-label" htmlFor={`same_for_all`}>Same for all</label>
            </div>
          </div>
        </div>

        {this.state.indication_for_lscs ?
          <div className="row mt-3">
            <div className="col-10">
              <div className={`form-group antInputItem micwrap ${this.state.indication_reason !== "" ? "hasdata" : ""
                }`}>
                <Link onClick={(e) => { e.preventDefault(); this.initSpeech({ identifier: 'indication_reason' }) }} className={(this.state.speech.identifier === 'indication_reason' && this.state.speech.state) ? 'mic subjective-mic on mute' : 'mic subjective-mic'}
                ></Link>

                <Form.Item
                  label="Indication for LSCS"
                  name="indication_reason"
                  rules={[{
                    required: true,
                    message: "Indication is required"
                  }]}
                  onChange={(e) => this.handleChange(e, 'indication_reason')}
                >

                  <Input
                    name="indication_reason"
                    value={this.state.indication_reason}
                    defaultValue={this.state.indication_reason}
                    maxLength={60}
                    autoComplete="off"
                  /* disabled={
                    this.state
                      .changes_in_cycle_status ||
                    this.state.is_missed_period
                  } */
                  />
                </Form.Item>
              </div>
            </div>
          </div> : null}
        <div className="ant-col-24 mt-3 mb-2 text-center">
          <button
            type="submit"
            className="btn btn-primary text-uppercase px-5"
            data-target=""
          >
            Save
          </button>
        </div>
      </Form>
    )
  }
}

export default MultiplePregnancies;