import React, { Component } from 'react'
import Axios from 'axios';
import LS_SERVICE from '../../../utils/localStorage';
import { Link } from 'react-router-dom'
import { Form, Select, Modal } from 'antd';
import PDFImg from '../../../assets/images/ic-pdf.png';
import {
  LOADER_RED,
  PATIENT_CASE_SHEET_LIST
} from '../../../utils/constant';
import moment from 'moment';
import qs from 'qs';

const { Option } = Select;

export default class PatientCertificates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEncounterData: props.selectedEncounterData,
      caseSheetListData : null,
      loader: true,
      showRxModal: false,
      rxPdfPath: null,
    }
  }

  async componentDidMount() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    await this.getPatientCaseSheetData();
    
  }

  async getPatientCaseSheetData() {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      patient_Id: selectedEncounterData.registrationId,
      encounter_Id: selectedEncounterData.encounter_id,
      enterprise_Id: selectedEncounterData.enterpriseId,
      facility_Id: selectedEncounterData.facilityId,
    }
    await Axios.post(PATIENT_CASE_SHEET_LIST, qs.stringify(PARAMS))
    .then(async res => {
      let caseSheetListData =  res.status ? res.data.data : null;
      await this.setState({
        caseSheetListData: caseSheetListData,
        loader: false
      });
    })
  }

  PatientCaseListLoad() {
    try {
      if ((!this.state.caseSheetListData || this.state.caseSheetListData.length === 0)) {
        let htmlData = (
          <tr>
            <td className="inner_table text-center" colSpan={3}>No Records Found.</td>
          </tr>
        )
        return htmlData;
      }
      const { caseSheetListData } = this.state;
      let htmlData = (
        <tr id={caseSheetListData.encounter_Id}>
          <td>{caseSheetListData.form_name}</td>
          <td>{moment(caseSheetListData.createdAt).format('DD MMM YYYY | hh:mm A ')}</td>
          <td><a onClick={() => { this.setState({ rxPdfPath: caseSheetListData.filepath, showRxModal: true }); }}><img src={PDFImg} alt="view" width={15} /></a></td>
        </tr>
      )
      return htmlData;
    } catch (err) {
      console.log('PatientEncounterListLoad: ', err);
    }
  }

  
  render() {
    const { loader } = this.state;
    return (
      <>
        { loader ? 
          <div className='row mt-3'>
            <div className="col-12  text-center mt-4">{LOADER_RED}
            </div>
          </div>
          :
          <div className='row mt-3'>
            <div className='col-12'>
              <table className='table table-bordered'>
                  <thead>
                      <tr>
                          <th width="40%" className='px-1 py-2' style={{verticalAlign:'top'}}>Form Name</th>
                          <th width="40%" className='px-1 py-2' style={{verticalAlign:'top'}}>Created At</th>
                          <th width="20%" className='px-1 py-2' style={{verticalAlign:'top'}}>File</th>
                      </tr>
                  </thead>
                  <tbody>
                    {this.PatientCaseListLoad()}
                  </tbody>
              </table>
            </div>
          </div>
        }

        <Modal
          title="View Case Sheet"
          visible={this.state.showRxModal}
          footer={false}
          width={850}
          onCancel={() => { this.setState({ showRxModal: false, rxPdfPath: null }) }}
          style={{ top: 20 }}
        >
          <div className="row">
              <div className="col-12 my-3 text-center">
                  {this.state.rxPdfPath != null ? <iframe src={this.state.rxPdfPath} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                      {LOADER_RED}
                  </iframe> : null}
              </div>
          </div>
        </Modal>
      </>
    )
  }
}
