import React from 'react';
import { Line, Scatter, Chart } from 'react-chartjs-2';
import { DURATION_TYPE_SINGULAR, LOADER_RED, PDF_CONFIG_KEYFINDINGS_GRAPH_UPDATE, VITAL_ARRAY } from '../../utils/constant';
import "react-datepicker/dist/react-datepicker.css";
import LS_SERVICE from '../../utils/localStorage';
import Axios from 'axios';
import qs from 'qs';
import moment from "moment";

class KeyFindingsChart extends React.Component {
  constructor(props) {
    super(props);
    const PDF_PRINT_OBJ = {};
    if (props.chartGraphSetting.length > 0) {
      props.chartGraphSetting.split(',').forEach(ppa => {
        PDF_PRINT_OBJ[ppa] = true
      })
    }
    this.state = {
      chartData: props.chartData,
      data: props.chartData[DURATION_TYPE_SINGULAR[props.chartsTab]],
      chartType: props.chartsType,
      chartsId: props.chartsId,
      chartsTab: props.chartsTab,
      dataLoaded: props.dataLoaded,
      reference_id: props.reference_id,
      constants: props.constants,
      KeyFindingsConstants: Object.values(props.constants.findingGroupData),
      keyFindingsOrder: props.keyFindingsOrder,
      newKeyFindingsOrder: [],
      chartDataMapped: {},
      showOnlyTicked: props.showOnlyTicked,
      checkBoxDisable: props.checkBoxDisable,
      isNurseLoggined: props.isNurseLoggined,
      all_print: PDF_PRINT_OBJ,
      all_print_checked: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return ({
      chartData: nextProps.chartData,
      data: nextProps.chartData[DURATION_TYPE_SINGULAR[nextProps.chartsTab]],
      chartType: nextProps.chartsType,
      chartsId: nextProps.chartsId,
      chartsTab: nextProps.chartsTab,
      dataLoaded: nextProps.dataLoaded,
      showOnlyTicked: nextProps.showOnlyTicked,
      checkBoxDisable: nextProps.checkBoxDisable,
      keyFindingsOrder: nextProps.keyFindingsOrder,
    })
  }

  async componentDidMount() {
    await this.updateKeyFindingsOrder();
    this.handleDynamicDataCreation();
    this.handleAllPrintCheckedFn();
  }

  updateKeyFindingsOrder = async () => {
    let new_key_findings_order = [];
    this.state.keyFindingsOrder.map((fo, k2) => {
      let obj = { display_order: fo.display_order, finding_group_id: fo.finding_group_id, vital_sign: [] };
      let at_least_one_av = false;
      let findex = this.state.KeyFindingsConstants.findIndex((x) => x.id == fo.finding_group_id);
      if (findex !== -1) {
        obj.name = this.state.KeyFindingsConstants[findex].name;
        fo.vital_sign.map((v, k) => {
          let obj_vital_sign = { ...v };
          let findex2 = this.state.KeyFindingsConstants[findex].vital_sign.findIndex((x) => x.vital_id == v.vital_id);
          if (findex2 !== -1) {
            obj_vital_sign.display_name = this.state.KeyFindingsConstants[findex].vital_sign[findex2].display_name;
            obj_vital_sign.id = this.state.KeyFindingsConstants[findex].vital_sign[findex2].id;
            obj_vital_sign.decimal_value = this.state.KeyFindingsConstants[findex].vital_sign[findex2].decimal_value;
            obj_vital_sign.display_value = this.state.KeyFindingsConstants[findex].vital_sign[findex2].display_value;

            let vital = this.state.KeyFindingsConstants[findex].vital_sign[findex2];
            let keyfindingsavailable = false;
            let valueArray = Object.values(this.state.data[vital.id] ?? {});
            valueArray.map((val, i) => {
              if (val && val !== "" && val != 'NaN') {
                keyfindingsavailable = true;
                at_least_one_av = true;
              }
            })
            obj_vital_sign.value_available = keyfindingsavailable;
            obj.vital_sign.push(obj_vital_sign);
          }
        });
      }
      obj.one_value_available = at_least_one_av;
      new_key_findings_order.push(obj);
    });
    await this.setState({
      newKeyFindingsOrder: new_key_findings_order
    })
  }

  handleDynamicDataCreation = _ => {
    let { data, chartDataMapped, newKeyFindingsOrder } = this.state;
    let one_chart_data_found = false;
    if (data) {
      newKeyFindingsOrder.map((fo, index) => {
        if (fo.one_value_available) {
          one_chart_data_found = true;
          fo.vital_sign.map((vitalDetails, index2) => {
            if (vitalDetails.value_available) {
              var dataSet = data[vitalDetails.id];
              var vital = vitalDetails.id;
              var chartData = this.updatedVitalGraphData(dataSet);
              const chartcDotsArr = this.createPointsWithDifferentColors(vital, chartData.data);

              chartDataMapped[vital] = {
                ['data' + vital]: {
                  labels: chartData.label,
                  datasets: [
                    {
                      label: '',
                      backgroundColor: 'rgb(102 102 102)',
                      borderColor: 'rgb(102 102 102)',
                      pointBackgroundColor: chartcDotsArr,
                      pointBorderColor: chartcDotsArr,
                      borderWidth: 1,
                      fill: false,
                      spanGaps: true,
                      data: chartData.data
                    }
                  ]
                },
                ['options' + vital]: {
                  responsive: true,
                  animation: {
                    duration: 0
                  },
                  plugins: {
                    legend: {
                      display: false
                    },
                    datalabels: {
                      font: {
                        size: 0
                      }
                    }
                  },
                  title: {
                    display: false,
                    text: vitalDetails.display_name,
                    fontSize: 15
                  },
                  elements: {
                    line: {
                      tension: 0,
                    },
                  },
                  legend: {
                    position: "bottom",
                    display: false,
                    labels: {
                      fontSize: 11,
                      boxWidth: 6,
                      //fontColor: '#333333',
                      // usePointStyle: true,
                    },
                  },

                  scales: {
                    x: {
                      display: true,
                      /* scaleLabel: {
                        display: false,
                        labelString: '',
                      }, */
                      ticks: {
                        font: {
                          size: 11
                        }
                      }
                    },
                    y: {
                      position: 'left',
                      display: true,
                      /* scaleLabel: {
                        display: false,
                        labelString: '',
                      }, */
                      suggestedMin: chartData.minY,
                      suggestedMax: chartData.maxY,
                      ticks: {
                        font: {
                          size: 11
                        },
                        precision: vitalDetails.decimal_value,
                        stepSize: chartData.stepY,
                      },
                      /* afterBuildTicks: function (axis) {
                        const totalTicks = 10;
                        let density = chartData.stepY;
                        var firstTick = axis.ticks[0].value;
                        // var lastTick = ticks[0];
                        var tempA = axis.chart.config._config.data.datasets[0].data;
                        var dataset = [...tempA];
                        dataset = dataset.sort(function (a, b) { return a - b });
                        var lastTick = dataset[dataset.length - 1];
                        var tickArr = [];
                        var tempHighest = parseFloat(firstTick) + (totalTicks * density);
                        if (lastTick > tempHighest) {
                          density = (lastTick - firstTick) / 10;
                          density = Math.ceil(density)
                        }
                        for (let i = firstTick; i <= lastTick && tickArr.length < totalTicks; i = i + density) {
                          tickArr.push(i)
                        }
                        lastTick = tickArr[tickArr.length - 1] + density;
                        tickArr.push(lastTick);
                        axis.options.max = lastTick;
                        return tickArr;
                      } */
                    }
                  },
                }
              };
            }
          });
        }
      });
    }
    if (this.state.showOnlyTicked != undefined && this.state.showOnlyTicked == true && Object.entries(this.state.all_print).length == 0) {
      one_chart_data_found = false;
    }

    this.setState({
      chartDataMapped,
      oneChartDataFound: one_chart_data_found
    });
  }

  updatedVitalGraphData(dataSet) {
    var finalLabel = [];
    var dataLabel = [];
    var rawLabel = [];
    var finalData = [];
    var diffArr = [];
    var totalDiff = 0;
    var count = 0;
    var valueArray = Object.values(dataSet);
    var labelArray = Object.keys(dataSet);


    valueArray.map((value, i) => {
      if (value !== "" && value != 'NaN') {
        if (DURATION_TYPE_SINGULAR[this.state.chartsTab] == 'day')
          finalLabel.push(moment(labelArray[i], 'YYYY-MM-DD HH:mm:ss').format('h:mm A'));
        else
          finalLabel.push(moment(labelArray[i], 'YYYY-MM-DD HH:mm:ss').format('D MMM, YYYY'));
        dataLabel.push(moment(labelArray[i], 'YYYY-MM-DD HH:mm:ss').format('D MMM, YYYY | h:mm A'));
        rawLabel.push(labelArray[i]);
        finalData.push(value);
      }
    })
    finalData.map((value, i) => {
      if (i < finalData.length - 1) {
        var diff = parseFloat(finalData[i + 1]) - parseFloat(value);
        totalDiff += Math.abs(diff);
        diffArr.push(Math.abs(diff));
      }
    })
    diffArr = diffArr.filter(e => e !== 0)
    diffArr = diffArr.sort(function (a, b) { return a - b });
    let minY = 0;
    let maxY = 0;
    let stepY = 1;
    if (finalData.length > 0 && diffArr.length > 0) {
      let dataArray = [...finalData];
      let sortedData = dataArray.sort(function (a, b) { return a - b });
      // let minCorrection = parseFloat(diffArr[0])>20?20:parseFloat(diffArr[0]);
      // let maxCorrection = parseFloat(diffArr[diffArr.length - 1])>20?20:parseFloat(diffArr[diffArr.length - 1]);

      stepY = totalDiff / diffArr.length;
      stepY = Math.round(stepY);
      stepY = stepY > 50 ? 50 : stepY
      minY = parseFloat(sortedData[0]) - stepY;
      maxY = parseFloat(sortedData[sortedData.length - 1]) + stepY;
    }
    // minY = minY==0?parseFloat(finalData[0])-stepY:minY;
    minY = minY < 0 ? 0 : minY;
    if (diffArr.length == 0)
      minY = minY == 0 ? parseFloat(finalData[0]) - stepY : minY;
    maxY = maxY == 0 ? parseFloat(finalData[finalData.length - 1]) + stepY : maxY;

    return { label: finalLabel, data: finalData, rawLabel: rawLabel, dataLabel: dataLabel, minY: minY, maxY: maxY, stepY: stepY };
  }

  createPointsWithDifferentColors = (__id, data) => {
    let colorDots = [];
    data.map(val => {
      colorDots.push(this.getVitalRangeColor(val, this.state.constants.vitals_range[__id]));
    });
    return colorDots;
  }

  getVitalRangeColor = (value, range) => {
    if (value == '-') return '';

    if (range.critical.min == null && range.critical.max == null)

      //return '#008000';
      return 'rgb(0 168 77)';

    let vitalRange = '';
    //normal range
    if (value >= range.normal.min && value <= range.normal.max)
      //vitalRange = '#008000'; //green
      vitalRange = 'rgb(0 168 77)'; //green

    //abnormal range
    if (
      (value > range.critical.min && value < range.normal.min) ||
      (value > range.normal.max && value < range.critical.max)
    )
      //vitalRange = '#ffbf00'; //amber
      vitalRange = 'rgb(245 153 28)'; //amber

    //critical range
    if ((range.critical.min != null && value <= range.critical.min) || (range.critical.max != null && value >= range.critical.max))
      //vitalRange = '#d24350'; //red
      vitalRange = 'rgb(255 0 0)'; //red

    return vitalRange;
  }

  handleAllPrintCheckedFn = _ => {

    let all_checked = true;
    this.state.newKeyFindingsOrder.map((fo, index) => {
      if (fo.one_value_available) {
        fo.vital_sign.map((vd, i) => {
          if (vd.value_available) {
            if (!this.state.all_print[vd.id]) {
              all_checked = false;
            }
          }
        });
      }
    });

    this.setState({
      all_print_checked: all_checked
    })
  }

  handleAllPrintCheck = _ => {
    let { all_print_checked, constants } = this.state;

    const PDF_PRINT_OBJ = {};

    this.state.newKeyFindingsOrder.map((fo, index) => {
      if (fo.one_value_available) {
        fo.vital_sign.map((vd, i) => {
          if (vd.value_available) {
            PDF_PRINT_OBJ[vd.id] = all_print_checked
          }
        });
      }
    });
    this.setState({
      all_print: PDF_PRINT_OBJ
    }, () => console.log(this.state.all_print))
  }

  handleVitalGroupAPI = async (e, vital) => {

    const { reference_id, all_print, all_print_checked, constants, showVitalType } = this.state;

    let selected_vital_graphs = '';

    if (vital === 'all_print') {
      if (!all_print_checked) {
        this.state.newKeyFindingsOrder.map((fo, index) => {
          if (fo.one_value_available) {
            fo.vital_sign.map((vd, i) => {
              if (vd.value_available) {
                selected_vital_graphs += selected_vital_graphs != '' ? ("," + vd.id) : (vd.id)
              }
            });
          }
        });
      } else {
        selected_vital_graphs = ''
      }
      this.setState({
        all_print_checked: !this.state.all_print_checked
      }, this.handleAllPrintCheck);
    } else {
      if (Object.keys(all_print).length > 0) {
        all_print[vital] = !all_print[vital];
      } else {
        all_print[vital] = true;
      }
      selected_vital_graphs = Object.keys(all_print).filter(ap => all_print[ap] === true).toString();
    }

    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id,
      selected_vital_graphs
    }

    const vitalGraphPromise = await Axios.post(PDF_CONFIG_KEYFINDINGS_GRAPH_UPDATE, qs.stringify(PARAMS))

    if (vitalGraphPromise.data.status) {
      this.props.updateData(selected_vital_graphs);
      this.handleAllPrintCheckedFn();
    }
  }

  render() {
    const { oneChartDataFound } = this.state;
    return (
      <>
        {(this.state.dataLoaded) ? (
          oneChartDataFound ? (
            <div className="row">
              {(this.state.chartType === 'all') && !this.state.showOnlyTicked && !this.state.checkBoxDisable ? (
                <>
                  <div className="col-8 pl-2 check-primary">
                    {!this.state.isNurseLoggined ? (
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" onChange={e => this.handleVitalGroupAPI(e, 'all_print')} value={this.state.all_print_checked} checked={this.state.all_print_checked}
                          className="custom-control-input" id="all_print" name="all_print" autoComplete="off" />
                        <label className="custom-control-label text-basic" htmlFor="all_print">Select All to Print with Prescription</label>
                      </div>) : null}
                  </div>
                  {/* <div className="col-6 pr-2 text-right check-primary">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" onChange={e => this.handleVitalGroupAPI(e, 'all_print')} value={this.state.all_print} checked={this.state.all_print}
                            className="custom-control-input" id="all_print" name="all_print" autocomplete="off" />
                        <label className="custom-control-label text-basic" htmlFor="all_print">SELECT ALL</label>
                    </div>
                </div> */}
                </>
              ) : null}
              <div className={(this.state.chartType === 'all') && !this.state.showOnlyTicked && !this.state.checkBoxDisable ? `col-4 pl-3 vital-graph-legends` : `col-12 pl-3 vital-graph-legends`}>
                <ul className="legend_unit_label">
                  <li><span className="normal"></span>Normal</li>
                  <li><span className="abnormal"></span>Abnormal</li>
                  <li><span className="critical"></span>Critical</li>
                </ul>
              </div>
              <div className={`chartCanvasWrapper w-100 ${(this.state.chartType !== 'all' && this.state.chartType !== 'finding_group') ? 'chartCanvasWrapperSmall' : ''}`}>
                {Object.keys(this.state.chartDataMapped).length > 0 ? (
                  this.state.newKeyFindingsOrder.map((fo, index) => {
                    if (this.state.chartType == 'finding_group') {
                      if (this.state.chartsId == fo.finding_group_id) {
                        if (fo.one_value_available) {
                          return (
                            <React.Fragment key={index}>
                              <div className="col-12">
                                <h6 style={{ padding: '0.5rem 0rem', marginBottom: '0', fontWeight: '700' }}>{fo.name}</h6>
                              </div>
                              {fo.vital_sign.map((vd, i) => {
                                if (vd.value_available) {
                                  return (
                                    <div className="chartCanvas" key={i}>
                                      <h4>{vd.display_name} {vd.display_value ? '(' + vd.display_value + ')' : ''}</h4>
                                      {!this.state.showOnlyTicked && !this.state.checkBoxDisable ? (
                                        <div className="check_for_print">
                                          {!this.state.isNurseLoggined ? (
                                            <div className="custom-control custom-checkbox">
                                              <input type="checkbox" onChange={e => this.handleVitalGroupAPI(e, vd.id)} value={this.state.all_print[vd.id]} checked={this.state.all_print[vd.id]}
                                                className="custom-control-input pdf_print" name={vd.id + ' _print'} autoComplete="off" />
                                              <label className="custom-control-label text-basic" htmlFor={vd.id + ' _print'}>&nbsp;</label>
                                            </div>) : null}
                                        </div>
                                      ) : null}
                                      <Line
                                        data={this.state.chartDataMapped[vd.id]['data' + vd.id]}
                                        options={this.state.chartDataMapped[vd.id]['options' + vd.id]}
                                      />
                                    </div>
                                  )
                                }
                              })}
                            </React.Fragment>
                          )
                        }
                      }
                    } else if (this.state.chartType == 'individual') {
                      if (fo.one_value_available) {
                        return (
                          <React.Fragment key={index}>
                            {/*                           <div className="col-12">
                            <h6 style={{padding: '0.5rem 0rem', marginBottom: '0', fontWeight: '700'}}>{fo.name}</h6>
                          </div> */}
                            {fo.vital_sign.map((vd, i) => {
                              if (this.state.chartsId == vd.id) {
                                if (vd.value_available) {
                                  return (
                                    <div className="chartCanvas" key={i}>
                                      <h4>{vd.display_name} {vd.display_value ? '(' + vd.display_value + ')' : ''}</h4>
                                      {!this.state.showOnlyTicked && !this.state.checkBoxDisable ? (
                                        <div className="check_for_print">
                                          {!this.state.isNurseLoggined ? (
                                            <div className="custom-control custom-checkbox">
                                              <input type="checkbox" onChange={e => this.handleVitalGroupAPI(e, vd.id)} value={this.state.all_print[vd.id]} checked={this.state.all_print[vd.id]}
                                                className="custom-control-input pdf_print" name={vd.id + ' _print'} autoComplete="off" />
                                              <label className="custom-control-label text-basic" htmlFor={vd.id + ' _print'}>&nbsp;</label>
                                            </div>) : null}
                                        </div>
                                      ) : null}
                                      <Line
                                        data={this.state.chartDataMapped[vd.id]['data' + vd.id]}
                                        options={this.state.chartDataMapped[vd.id]['options' + vd.id]}
                                      />
                                    </div>
                                  )
                                }
                              }
                            })}
                          </React.Fragment>
                        )
                      }
                    } else {
                      if (!this.state.showOnlyTicked) {
                        if (fo.one_value_available) {
                          return (
                            <React.Fragment key={index}>
                              <div className="col-12">
                                <h6 style={{ padding: '0.5rem 0rem', marginBottom: '0', fontWeight: '700' }}>{fo.name}</h6>
                              </div>
                              {fo.vital_sign.map((vd, i) => {
                                if (vd.value_available) {
                                  return (
                                    <div className="chartCanvas" key={i}>
                                      <h4>{vd.display_name} {vd.display_value ? '(' + vd.display_value + ')' : ''}</h4>
                                      {!this.state.showOnlyTicked && !this.state.checkBoxDisable ? (
                                        <div className="check_for_print">
                                          {!this.state.isNurseLoggined ? (
                                            <div className="custom-control custom-checkbox">
                                              <input type="checkbox" onChange={e => this.handleVitalGroupAPI(e, vd.id)} value={this.state.all_print[vd.id]} checked={this.state.all_print[vd.id]}
                                                className="custom-control-input pdf_print" name={vd.id + ' _print'} autoComplete="off" />
                                              <label className="custom-control-label text-basic" htmlFor={vd.id + ' _print'}>&nbsp;</label>
                                            </div>) : null}
                                        </div>
                                      ) : null}
                                      <Line
                                        data={this.state.chartDataMapped[vd.id]['data' + vd.id]}
                                        options={this.state.chartDataMapped[vd.id]['options' + vd.id]}
                                      />
                                    </div>
                                  )
                                }
                              })}
                            </React.Fragment>
                          )
                        }
                      } else {
                        // SHOW ONLY TICKED GRAPHS FOR VIEW INFORMATION PAGE
                        if (fo.one_value_available) {
                          let show_group = false;
                          fo.vital_sign.map((vd, i) => {
                            if (vd.value_available && this.state.all_print[vd.id]) {
                              show_group = true;
                            }
                          });
                          if (show_group) {
                            return (
                              <React.Fragment key={index}>
                                <div className="col-12">
                                  <h6 style={{ padding: '0.5rem 0rem', marginBottom: '0', fontWeight: '700' }}>{fo.name}</h6>
                                </div>
                                {fo.vital_sign.map((vd, i) => {
                                  if (vd.value_available && this.state.all_print[vd.id]) {
                                    return (
                                      <div className="chartCanvas" key={i}>
                                        <h4>{vd.display_name} {vd.display_value ? '(' + vd.display_value + ')' : ''}</h4>
                                        {!this.state.showOnlyTicked && !this.state.checkBoxDisable ? (
                                          <div className="check_for_print">
                                            {!this.state.isNurseLoggined ? (
                                              <div className="custom-control custom-checkbox">
                                                <input type="checkbox" onChange={e => this.handleVitalGroupAPI(e, vd.id)} value={this.state.all_print[vd.id]} checked={this.state.all_print[vd.id]}
                                                  className="custom-control-input pdf_print" name={vd.id + ' _print'} autoComplete="off" />
                                                <label className="custom-control-label text-basic" htmlFor={vd.id + ' _print'}>&nbsp;</label>
                                              </div>) : null}
                                          </div>
                                        ) : null}
                                        <Line
                                          data={this.state.chartDataMapped[vd.id]['data' + vd.id]}
                                          options={this.state.chartDataMapped[vd.id]['options' + vd.id]}
                                        />
                                      </div>
                                    )
                                  }
                                })}
                              </React.Fragment>
                            )
                          }
                        }
                      }
                    }
                  })) : null
                }
              </div>
            </div>
          ) : <div className="col d-flex justify-content-center align-self-center mt-2" style={{ paddingTop: '50px' }}></div>
        ) : this.state.dataLoaded && this.state.data.date.length === 0 ? (
          <div className="col d-flex justify-content-center align-self-center mt-2">
            No Data
          </div>
        ) : ""}
      </>
    )
  }

}

export default KeyFindingsChart;