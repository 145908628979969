import React, { Component } from "react";
import { Button, Select, notification, Form, Input, Tooltip } from 'antd';
import { SEXUAL_HISTORY_STORE,HANDLEVALIDANTMESSAGES,SEXUAL_HISTORY_LIST, LOADER } from '../../utils/constant';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { speechService, speechTextService } from '../../utils/rxjs-sharing';
import MyHcSpeechRecognition from "../../pages/speech-to-text/speech-recognition";
import moment from 'moment';
import LS_SERVICE from "../../utils/localStorage";

class SexualHx extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            spin_loading: false,
            buttonText:'Save',
            sexualHsId:'',
            sexual_history:false,
            patient_disclose:false,
            sexually_active:'',
            since:'',
            since_by:1,
            coital_frequency:'',
            frequency_by:1,
            coital_difficulty:'',
            contraceptives:'',
            disableContraceptives:false,
            sinceByList: [
                {id: 1, desc: 'Days'},
                {id: 2, desc: 'Weeks'},
                {id: 3, desc: 'Months'},
                {id: 4, desc: 'Years'}
              ],
            frequencyByList: [
                {id: 1, desc: 'Per week'},
                {id: 2, desc: 'Per month'}
              ],
            contraceptivesList : [
                {id: 1, desc: 'None'},
                {id: 2, desc: 'Condom'},
                {id: 3, desc: 'IUD'},
                {id: 4, desc: 'OCP'},
                {id: 5, desc: 'Hormonal'}
              ], 
              details:'',
              remarks:'' ,
              contraceptive_detail:'' ,
              contrapTypeChecked: [],
              disableSaveButton:true,
              sexual_history_disable:false,
              patient_disclose_disable:false,
              sexually_active_disable:false,
              since_disable:true,
              since_by_disable:true,
              coital_frequency_disable:false,
              frequency_by_disable:false,
              coital_difficulty_disable:false,
              details_disable:true,
              details_required:false,
              sexually_active_required:false,
              remarks_disable:false,
              contraceptive_disable:false,
              contraceptives_disable:false,
              disable_update:false,
              speech: {
                identifier: null,
                state: false
              },
              tabs_available: props.tabs_available
        }
        this.SexualHistoryList = []
        this.FormRef = React.createRef();
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.onChangeRadioCheck = this.onChangeRadioCheck.bind(this);
        this.handleChangeInput=this.handleChangeInput.bind(this);
        this.handleContraceptivesChange=this.handleContraceptivesChange.bind(this);
    }

    componentDidMount() {
      this.FormList();
    //   console.log(this.props);
      this.subscription = speechTextService.status().subscribe((data) => {
        if (this.state.speech.state) {
            if (this.state.speech.identifier === "details") {
                this.setState({ details: data.text });
            }
            if (this.state.speech.identifier === "remarks") {
                this.setState({ remarks: data.text });
            }
            if (this.state.speech.identifier === "contraceptive_detail") {
                this.setState({ contraceptive_detail: data.text });
            }
            document.querySelector(
                "#" + this.state.speech.identifier
            ).style.height =
                document.querySelector("#" + this.state.speech.identifier)
                    .scrollHeight + "px";
        }
      });

    }

    static getDerivedStateFromProps(props, state) {
        const { patient, allowEdit, isPracticing } = props;

        if (props.editData !== state.editData) {
            return { editData: props.editData }
          }

        return {
            patient: {
                patient_id: Number(patient.patient_id),
                reference_id: Number(patient.reference_id),
                reference_type: patient.reference_type,
                doctor_id: Number(LS_SERVICE.get("staff_id")),
                hospital_id:Number(LS_SERVICE.get("slot_hospital_id")),
                editData:(props.editData !== state.editData)?props.editData:null
            },
            patientData: props.patientData,
            allowEdit : allowEdit,
            isPracticing: isPracticing
        };
    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevState.editData == null && this.state.editData != null && this.state.editData != prevState.editData) {
        //     this.setState(() => this.handleEditData())
        // }

        setTimeout(() => {
            let Elem = document.querySelectorAll('.speech-field');
            Elem.forEach(function (el) {
                el.style.height = el.scrollHeight + "px";
            })
        }, 100);
    }

    // handleEditData() {
    //     if (this.state.editData != null) {
    //         this.setState({
    //         saveBtnDisable: false,
    //         }, () => this.handleTextareaAutoHeight('diagnosis_notes_class'));
    //     }
    // }

    handleCheckboxChange= async (event) => { 
        if (this.state.speech.identifier != null) {
            this.initSpeech({ identifier: this.state.speech.identifier });
        }
        
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        await this.setState(prevState => ({
            [target.name]: value,
            speech: {identifier : ''}
        }));
        await this.disabledAllFields();
    }
   //only for disable input basis of selection
    disabledAllFields = async() =>{
        const { sexual_history,patient_disclose}= this.state;
        if(sexual_history || patient_disclose){
            await this.setState({
                sexual_history:sexual_history?sexual_history:false,
                patient_disclose:patient_disclose?patient_disclose:false,
                sexually_active:null,
                since:'',
                since_by:null,
                coital_frequency:'',
                frequency_by:null,
                coital_difficulty:null,
                details:'',
                remarks:'',
                contraceptive_detail:'',
                contraceptives:null,
                contrapTypeChecked:[],
                patient_disclose_disable: patient_disclose?false:true,
                sexual_history_disable:sexual_history?false:true,
                sexually_active_disable:true,
                since_disable:true,
                since_by_disable:true,
                coital_frequency_disable:true,
                frequency_by_disable:true,
                coital_difficulty_disable:true,
                details_disable:true,
                contraceptives_disable:true,
                remarks_disable:true,
                contraceptive_disable:true
            })
        }else{
            await this.setState({
                sexual_history:false,
                patient_disclose:false,
                sexually_active:null,
                since:'',
                since_by:null,
                coital_frequency:'',
                frequency_by:null,
                coital_difficulty:null,
                details:'',
                remarks:'',
                contraceptive_detail:'',
                contraceptives:null,
                contrapTypeChecked:[],
                patient_disclose_disable: false,
                sexual_history_disable:false,
                sexually_active_disable:false,
                since_disable:true,
                since_by_disable:true,
                coital_frequency_disable:false,
                frequency_by_disable:false,
                coital_difficulty_disable:false,
                details_disable:true,
                contraceptives_disable:false,
                remarks_disable:false,
                contraceptive_disable:false
              })
        }
        await this.saveButtonStatus();
      }
    //only for disable input basis of selection in edit
    disabledAllEditFields = async() =>{
        const { sexual_history,patient_disclose}= this.state;
        
        if(sexual_history || patient_disclose){
            await this.setState({
                sexual_history:sexual_history?sexual_history:false,
                patient_disclose:patient_disclose?patient_disclose:false,
                sexually_active:null,
                since:'',
                since_by:null,
                coital_frequency:'',
                frequency_by:null,
                coital_difficulty:null,
                details:'',
                remarks:'',
                contraceptive_detail:'',
                contraceptives:null,
                contrapTypeChecked:[],
                patient_disclose_disable: patient_disclose?false:true,
                sexual_history_disable:sexual_history?false:true,
                sexually_active_disable:true,
                since_disable:true,
                since_by_disable:true,
                coital_frequency_disable:true,
                frequency_by_disable:true,
                coital_difficulty_disable:true,
                details_disable:true,
                contraceptives_disable:true
              })
        }
        await this.saveButtonStatus();
      }
    onChangeRadioCheck= async (event) => { 
        const target = event.target; 
        const name = target.name;
        await this.setState({
          [name]: event.currentTarget.value   
         });
         if(this.state.sexually_active==='Yes'){
            await this.setState({ 
                since_disable:false,
                since_by_disable:false,
                sexually_active_required:true,
            })
        }else{
            await this.setState({ 
                since_disable:true,
                since_by_disable:true,
                since:'',
                sexually_active_required:false,
            })
        }
        if(this.state.coital_difficulty==='Yes'){
            await this.setState({
                details_disable:false,
                details_required:true,
              })
        }else{
            await this.setState({ 
                details_disable:true,
                details_required:false,
                details:''
            })
        }
         this.saveButtonStatus();
      }
     //input method
     handleChangeInput= async(event) =>{
        await this.setState({ 
            [event.target.name] : event.target.value 
          })
          this.saveButtonStatus();
      }

    saveButtonStatus = async() =>{
        const { sexual_history,patient_disclose, sexually_active, since, coital_difficulty, details}= this.state;
        var allowSave=true;
        if( sexual_history || patient_disclose ){
            allowSave=false;
        }
        if((sexually_active==="Yes" && since) || sexually_active==="No"){
            allowSave=false;
            
            if(coital_difficulty=='Yes' && details == ""){
                allowSave=true;
            }
        }
        await this.setState({disableSaveButton:allowSave})
    }
    
    handleFormSubmit = async (values) => {
        const { spin_loading, isPracticing } = this.state;
        const { patient_id, doctor_id, hospital_id, reference_id } = this.state.patient;

        if(LS_SERVICE.get('user_type') !== 2){
            if (!isPracticing) {
                this.props.parentHandleModalPopup(null, 'isPracticingModal');
                return;
            }
        }

        if (!this.state.SexualHistoryList || this.state.SexualHistoryList.length === 0 && this.state.SexualHistoryList[0].doctor_id != doctor_id) {
            var sexualHsId = 0;
        }else{
            let created_at = moment(this.state.SexualHistoryList[0].created_at).format('YYYY-MM-D');
            if(created_at){
                let sexualDate = created_at.split('-');
                let currentdate = new Date();
                var cur_day     = currentdate.getDate();
                let cur_month   = currentdate.getMonth() + 1;
                let cur_year    = currentdate.getFullYear();

                if(cur_year == sexualDate[0] && cur_month == sexualDate[1] && cur_day == sexualDate[2]){
                    var sexualHsId = this.state.SexualHistoryList[0].id;
                }else{
                    var sexualHsId = 0;
                }
            }
        }

        let PARAMS = {
            id:sexualHsId,
            patient_id: patient_id,
            doctor_id: (LS_SERVICE.get('user_type') === 2 && LS_SERVICE.get('speciality_id')) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
            hospital_id: hospital_id,
            reference_id:reference_id,
            reference_type:"0",
            confidential:'',
            sexual_history:this.state.sexual_history,
            patient_disclose:this.state.patient_disclose,
            sexually_active:this.state.sexually_active,
            since:(this.state.since?this.state.since:null),
            since_by:(this.state.since_by)?this.state.since_by:null,
            contraceptives:this.state.contraceptives,
            coital_frequency:this.state.coital_frequency,
            frequency_by:this.state.frequency_by,
            coital_difficulty:this.state.coital_difficulty,
            details:this.state.details,
            remarks:this.state.remarks,
            contraceptive_detail:this.state.contraceptive_detail,
            contrapTypeChecked:this.state.contrapTypeChecked
          }

          this.setState({
            spin_loading: !spin_loading
          });
          
          axios.post(SEXUAL_HISTORY_STORE, PARAMS)
          .then(success => {
              this.FormList();
              const { spin_loading } = this.state;
              this.setState({
                spin_loading: !spin_loading
              });
              notification.success({
                message:  success.data.message,
                placement: 'topRight'
              });
              this.Formclear();
          }); 
        }
    //for contraceptiveChange
    handleContraceptivesChange = async (e, data) => {
        let findex = this.state.contrapTypeChecked.findIndex(x => x.contraceptive_type_id === data.id);
        this.setState({disableContraceptives:false});
        if (findex === -1){
            if(data.id == 1){
                this.setState({disableContraceptives:true, contrapTypeChecked: []});
            }

            await this.setState(prevState => {
                let obj = {
                    contraceptive_type_id: data.id
                }; 
                const newSelection = [...prevState.contrapTypeChecked, obj];
                return {contrapTypeChecked: newSelection}
            });  
        } else {
            await this.setState(prevState => ({ 
                contrapTypeChecked: prevState.contrapTypeChecked.filter((d, i) => i !== findex)
            }));  
        }
        this.saveButtonStatus(); 
      } 

      //SEXUAL_HISTORY_LIST
      FormList = async () => {
        const { patient_id, doctor_id, hospital_id, reference_id } = this.state.patient;

        const PARAMS = {
            patient_id: patient_id,
            doctor_id: (LS_SERVICE.get('user_type') === 2 && LS_SERVICE.get('speciality_id')) ? LS_SERVICE.get('selectedDoctorIdAppoinement') : doctor_id,
            hospital_id: hospital_id,
            reference_id: reference_id,
            reference_type: 0,
        }

        const result = await axios.get(SEXUAL_HISTORY_LIST(PARAMS));
        if (result?.data?.data && result?.data?.data.sexualHistoryList.length > 0) {
            this.setState({ SexualHistoryList: result?.data?.data.sexualHistoryList });

            if(result?.data?.data.sexualHistoryList[0].sexual_history == "0" && result?.data?.data.sexualHistoryList[0].patient_disclose == "0"){
                this.setState({ disabledNoSexualHistory: true });
            }
        }
        if (result?.data?.data && result?.data?.data.patientDetails) {
            if(result?.data?.data.patientDetails.no_sexual_hx_checked){
                this.setState({
                    sexual_history: result?.data?.data.patientDetails.no_sexual_hx_checked,
                    patient_disclose_disable: result?.data?.data.patientDetails.no_sexual_hx_checked
                });
            }
            if(result?.data?.data.patientDetails.patient_not_willing_to_disclose_checked){
                this.setState({
                    patient_disclose: result?.data?.data.patientDetails.patient_not_willing_to_disclose_checked,
                    sexual_history_disable: result?.data?.data.patientDetails.patient_not_willing_to_disclose_checked
                });
            }

            this.disabledAllFields();
        }
      }

     FormListLoad = () => {
        if (!this.state.SexualHistoryList || this.state.SexualHistoryList.length === 0) {
            return (null)
        }
    
        return this.state.SexualHistoryList.map((data, i) => {

            var contraceptivesDes = '';
            var length = Object.keys(data.contrapTypeChecked).length;
            for (const [key, d] of Object.entries(data.contrapTypeChecked)) {
                contraceptivesDes += `${d.desc}${(key < length-1)?', ':''}`;
            }

          return (
          <>
            <tr key={i} id={data.id}>
                <td>{data.created_at}</td>
                {(data.sexual_history == '1' || data.patient_disclose == '1')?
                <td colspan="6" className="text-center py-2">{(data.sexual_history == '1')?'No Sexual History':'Patient Not Willing To Disclose'}</td>
                :<>
                <td>{(data.sexually_active)?data.sexually_active:'-'}</td>
                <td>{(data.since)?data.since+' '+data.sinceDetails.since_label:'-'}</td>
                <td>{(data.contrapTypeChecked && data.contrapTypeChecked.length > 0)?contraceptivesDes:'-'}</td>
                <td>{(data.coital_frequency)?data.coital_frequency+' '+data.frequencyDetails.frequency_label:'-'}</td>
                <td>{(data.details)?data.details:'-'}</td>
                <td title={data.remarks}>{(data.remarks) ? `${data.remarks.substring(0, 40)}${(data.remarks.length>40?' ...':'') }` : '-'}</td>
                <td title={data.contraceptive_detail}>{(data.contraceptive_detail) ? `${data.contraceptive_detail.substring(0, 40)}${(data.contraceptive_detail.length>40?' ...':'') }` : '-'}</td>
                </>}
                <td className="action text-center">
                    <a href="javascript:void(0);" disabled={!this.state.allowEdit} onClick={(!this.state.allowEdit)? 'return false;' : e => this.handleEdit(data, i)}>UPDATE</a>
                </td>
            </tr>
            </>
            )
          });
      }

      handleEdit = (data, i) => {
        var editData = this.state.SexualHistoryList[i];
        if (!this.state.SexualHistoryList || this.state.SexualHistoryList.length === 0 && this.state.SexualHistoryList[0].doctor_id != this.patient.doctor_id) {
            var sexualHsId = 0;
        }else{
            let created_at = moment(this.state.SexualHistoryList[0].created_at).format('YYYY-MM-D');
            if(created_at){
                let sexualDate = created_at.split('-');
                let currentdate = new Date();
                var cur_day     = currentdate.getDate();
                let cur_month   = currentdate.getMonth() + 1;
                let cur_year    = currentdate.getFullYear();

                if(cur_year == sexualDate[0] && cur_month == sexualDate[1] && cur_day == sexualDate[2]){
                    var sexualHsId = editData.id;
                }else{
                    var sexualHsId = 0;
                }
            }
        }

        this.setState({
            sexualHsId: sexualHsId,
            sexual_history: (editData.sexual_history == '1')?true:false,
            patient_disclose: (editData.patient_disclose == '1')?true:false,
            sexually_active: editData.sexually_active,
            sexually_active_required:(editData.sexually_active == 'Yes')?true:false,
            since: (editData.since)?editData.since:'',
            since_disable: (editData.sexually_active == 'Yes')?false:true,
            since_by_disable: (editData.sexually_active == 'Yes')?false:true,
            since_by: (editData.sinceDetails.since_by && editData.sinceDetails.since_by != null)?editData.sinceDetails.since_by:'',
            contrapTypeChecked: editData.contrapTypeChecked,
            coital_frequency: (editData.coital_frequency)?editData.coital_frequency:'',
            frequency_by:1,
            coital_difficulty: editData.coital_difficulty,
            coital_frequency_disable: false,
            frequency_by_disable: false,
            details_disable: (editData.coital_difficulty == 'Yes')?false:true,
            details_required:(editData.coital_difficulty == 'Yes')?true:false,
            details: editData.details,
            remarks: editData.remarks,
            contraceptive_detail: editData.contraceptive_detail
            
        }, () => {
            
            const tabId = document.getElementById('create-sexual-hx');
            window.scrollTo({ top: (tabId.offsetTop - 10), behavior: 'smooth' });
            this.saveButtonStatus();
            if(editData.sexual_history == '1' || editData.patient_disclose == '1'){
                this.disabledAllEditFields();
            }
        })
    }

    //Formclear
    Formclear = async () => {
        if (this.state.speech.identifier != null) {
            this.initSpeech({ identifier: this.state.speech.identifier });
        }
        await this.setState({
            buttonText:'Save',
            sexual_history:false,
            patient_disclose:false,
            sexualHsId:0,
            disableContraceptives:false,
            speech: {identifier : ''}
        }); 
        await this.disabledAllFields();
    }
    autoHeight = (e) => {
        e.target.style.height = e.target.scrollHeight + "px";
    }
    //Speech
    initSpeech(info) {
        let initialText = '';
        if (info.identifier === 'details') {
            initialText = this.state.details != undefined ? this.state.details : '';
        }
        if (info.identifier === 'remarks') {
            initialText = this.state.remarks != undefined ? this.state.remarks : '';
        }

        if (info.identifier === 'contraceptive_detail') {
            initialText = this.state.contraceptive_detail != undefined ? this.state.contraceptive_detail : '';
        }

        
        if (info.identifier === this.state.speech.identifier) {
            this.setState({
                speech: {
                    identifier: info.identifier,
                    state: !this.state.speech.state,
                    initialText: initialText
                }
            }, () => {
                speechService.init(this.state.speech);
            });
        } else {
            this.setState({
                speech: {
                    identifier: this.state.speech.identifier,
                    state: false,
                    initialText: ''
                }
            }, () => {
                speechService.init(this.state.speech);
                setTimeout(() => {
                    this.setState({
                        speech: {
                            identifier: info.identifier,
                            state: true,
                            initialText: initialText
                        }
                    }, () => {
                        speechService.init(this.state.speech);
                    });

                }, 1000);
            });
        }
    }
    handleOnFocus = (e) => {
        var identifierValue =
            this.state.speech.identifier === e.target.id
                ? e.target.id
                : this.state.speech.identifier;
        this.setState(
            (prevState) => ({
                speech: {
                    ...prevState.speech,
                    identifier: identifierValue,
                    state: false,
                    initialText:
                        identifierValue === "details"
                            ? this.state.details
                            : this.state.remarks,
                }
            }),
            () => {
                speechService.init(this.state.speech);
            }
        )
    }

    handleContinue = () => {
        const {tabs_available} = this.state;
        let next_tab = tabs_available.obs_tab_available ? '3' : (tabs_available.physical_tab_available ? '4' : null);
        if(next_tab){
            this.props.handleContinue(next_tab);
        } else {
            const { patient_id, reference_id, reference_type, hospital_id } = this.state.patient;
            const { patientData } = this.state;

            const isTodayVisitArr = LS_SERVICE.has('isTodayVisit') ? LS_SERVICE.get('isTodayVisit') : [];
            const pageListArr = LS_SERVICE.get('goToSoapPatientPageList');
            let index = pageListArr.indexOf('sexual_hx');
            index++;
            if (index < pageListArr.length && !isTodayVisitArr.includes(reference_id.toString())) {
                let soapurl = LS_SERVICE.get('goToSoapPatientUrl')[index].url;
                soapurl = soapurl.replace("HOSPITALID", hospital_id);
                soapurl = soapurl.replace("REFID", reference_id);
                soapurl = soapurl.replace("REFTYPE", reference_type);
                soapurl = soapurl.replace("PATID", patient_id);
                this.props.history.push({
                    pathname: soapurl,
                    state: { patientData }
                })
            } else {
                this.props.history.push({
                    pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${reference_type}/${reference_id}`,
                    state: { patientData }
                })
            }
        }
    }

    render() {
        const { sinceByList,frequencyByList,contraceptivesList,contrapTypeChecked} = this.state;
        return (
            <React.Fragment>
            <div className="row">
            <div className="col-12">
            <Form
                ref={this.FormRef}
                validateMessages={HANDLEVALIDANTMESSAGES}
                onFinish={this.handleFormSubmit}
                layout="vertical" 
                className="emrfrm"
                id="create-sexual-hx">
                     {/* <MyHcSpeechRecognition /> */}
                    <div className="row">
                        <div className="col-2">
                            <div className="custom-control custom-checkbox pr-3">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="no-sexual"
                                    name="sexual_history"
                                    value={this.state.sexual_history}
                                    checked={this.state.sexual_history}
                                    disabled={this.state.sexual_history_disable || this.state.disabledNoSexualHistory || !this.state.allowEdit}
                                    onChange={(e) => this.handleCheckboxChange(e)}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="no-sexual"
                                >
                                    No Sexual History
                                </label>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="custom-control custom-checkbox pr-3">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="patient-will"
                                    name="patient_disclose"
                                    value={this.state.patient_disclose}
                                    checked={this.state.patient_disclose}
                                    disabled={this.state.patient_disclose_disable || !this.state.allowEdit}
                                    onChange={(e) => this.handleCheckboxChange(e)}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="patient-will"
                                >
                                    Patient Not Willing To Disclose
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-auto mr-3 pt-2">
                            <span className="pt-0 d-block font-cus">
                                Sexually Active <span className="text-danger">*</span>
                            </span>
                        </div>
                        <div className="col-auto pt-2">
                            <div className="custom-control custom-radio custom-control-inline mr-5">
                                <input
                                    type="radio"
                                    id="yes-sexually"
                                    name="sexually_active"
                                    className="custom-control-input"
                                    value="Yes"
                                    checked={this.state.sexually_active==='Yes'}
                                    onChange={(e) => this.onChangeRadioCheck(e)}
                                    disabled={this.state.sexually_active_disable || !this.state.allowEdit}
                                />
                                <label
                                    className="custom-control-label pl-2"
                                    htmlFor="yes-sexually"
                                >
                                    Yes
                                </label>
                            </div>

                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    id="no-sexually"
                                    name="sexually_active"
                                    className="custom-control-input"
                                    value="No"
                                    checked={this.state.sexually_active==='No'}
                                    onChange={(e) => this.onChangeRadioCheck(e)}
                                    disabled={this.state.sexually_active_disable || !this.state.allowEdit}
                                />
                                <label
                                    className="custom-control-label pl-2"
                                    htmlFor="no-sexually"
                                >
                                    No
                                </label>
                            </div>
                        </div>

                        <div className="col-auto">
                            <div className={this.state.since?'form-label-group input-group hasdata':'form-label-group input-group'} >
                                <input
                                    /* type="number" */
                                    type="text"
                                    className="form-control onlynumbers"
                                    autoComplete="off"
                                    id="since-sexual"
                                    placeholder="Since"
                                    maxLength="2"
                                    name="since"
                                    value={this.state.since}
                                    onChange={(e) => this.handleChangeInput(e)}
                                    disabled={this.state.since_disable}
                                />

                                <span className="input-group-addon max-120">
                                <select className="custom-select" value={this.state.since_by} name="since_by" onChange={(e) => this.handleChangeInput(e)} disabled={this.state.since_by_disable}>
                                    {sinceByList !== undefined && sinceByList.length > 0 ? sinceByList.map(d => (
                                       <option key={d.id} value={d.id} label={d.desc}>{d.desc}</option>
                                   )) : null}
                                </select>
                                </span>
                                <label
                                    htmlFor="since-sexual"
                                    className="pt-0"
                                >
                                    Since {(this.state.sexually_active_required)?<span className="text-danger">*</span>:''}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-auto font-cus mr-3 d-flex align-items-center">
                            Contraceptives
                        </div>
                        <div className="col-auto d-flex align-items-center">
                            <div className="custom-control custom-checkbox custom-control-inline">

                            {contraceptivesList !== undefined && contraceptivesList.length > 0 ? contraceptivesList.map((obj, i) => (
                        <div key={i} className="custom-control custom-checkbox custom-control-inline mr-5">
                        <input type="checkbox" className="custom-control-input" id={`contraceptives${i}`} data-id={`contraceptives${i}`} name={`contraceptives${i}`} checked={contrapTypeChecked.findIndex(x => x.contraceptive_type_id === obj.id) !== -1}  onChange={(e) => this.handleContraceptivesChange(e, obj)} disabled={(this.state.contraceptives_disable)?this.state.contraceptives_disable : (i != 0)?this.state.disableContraceptives || !this.state.allowEdit : false || !this.state.allowEdit}/>
                        <label class="custom-control-label pr-5 pl-1" htmlFor={`contraceptives${i}`}>{obj.desc}</label>
                        </div>
                    )) : null}
                            </div>
                          
                        </div>
                        <div className ="col">
                        <div className={this.state.contraceptive_detail ? 'form-group micwrap ant-mic-wrap hasdata mb-0':'form-group micwrap ant-mic-wrap mb-0'}>
                                <a
                                    disabled={
                                        this.state.contraceptive_disable || !this.state.allowEdit
                                    }
                                    onClick={(this.state.contraceptive_disable || !this.state.allowEdit)? (e) => { e.preventDefault(); } : (e) => { e.preventDefault(); this.initSpeech({ identifier: 'contraceptive_detail' }) } }
                                    className={(this.state.speech.identifier === 'contraceptive_detail' && this.state.speech.state) ? 'mic r-0 on mute' : 'mic r-0'} 
                                ></a>

                                <textarea
                                    className="form-control speech-field"
                                    rows="1"
                                    placeholder="Contraceptive Remarks"
                                    id="Contraceptive"
                                    // maxlegth="255"
                                    oninput="auto_height(this)"
                                    name="contraceptive_detail"
                                    value={this.state.contraceptive_detail}
                                    onChange={(e) => this.handleChangeInput(e)}
                                    disabled={this.state.contraceptive_disable || !this.state.allowEdit}
                                    onInput={(e) => this.autoHeight(e)}
                                    onFocus={this.handleOnFocus} 
                                ></textarea>
                                <label htmlFor="contraceptive-detail-sexual">
                                  Contraceptive Remarks
                                </label>
                            </div>


                        </div>

                        

                    </div>

                    

                    <div className="row mt-4">
                        <div className="col-3">
                            <div className={this.state.coital_frequency?'form-label-group input-group hasdata':'form-label-group input-group'}>
                                <input
                                     type="text"
                                     className="form-control onlynumbers"
                                     id="coital-frequency"
                                     name="coital_frequency"
                                     maxLength="2"
                                     placeholder="Coital Frequency"
                                     autoComplete="off"
                                     value={this.state.coital_frequency}
                                     onChange={(e) => this.handleChangeInput(e)}
                                     disabled={this.state.coital_frequency_disable || !this.state.allowEdit}
                                />

                                <span className="input-group-addon max-120">
                                <select className="custom-select" value={this.state.frequency_by} name="frequency_by" onChange={(e) => this.handleChangeInput(e)} disabled={this.state.frequency_by_disable}>
                                    {frequencyByList !== undefined && frequencyByList.length > 0 ? frequencyByList.map(d => (
                                       <option key={d.id} value={d.id} label={d.desc}>{d.desc}</option>
                                   )) : null}
                                </select>
                                </span>
                                <label htmlFor="coital-frequency">
                                    Coital Frequency
                                </label>
                            </div>
                        </div>

                        <div className="col-3 pt-2">
                            <span className="font-cus">
                                Coital Difficulty
                            </span>
                            <div className="custom-control custom-radio custom-control-inline mr-3 ml-3">
                                <input
                                    type="radio"
                                    id="yes-coital"
                                    name="coital_difficulty"
                                    className="custom-control-input"
                                    value="Yes"
                                    checked={this.state.coital_difficulty==='Yes'}
                                    onChange={(e) => this.onChangeRadioCheck(e)}
                                    disabled={this.state.coital_difficulty_disable || !this.state.allowEdit}
                                />
                                <label
                                    className="custom-control-label pl-2"
                                    htmlFor="yes-coital"
                                >
                                    Yes
                                </label>
                            </div>

                            <div className="custom-control custom-radio custom-control-inline mr-1">
                                <input
                                    type="radio"
                                    id="no-coital"
                                    name="coital_difficulty"
                                    className="custom-control-input"
                                    value="No"
                                    checked={this.state.coital_difficulty==='No'}
                                    onChange={(e) => this.onChangeRadioCheck(e)}
                                    disabled={this.state.coital_difficulty_disable || !this.state.allowEdit}
                                />
                                <label
                                    className="custom-control-label pl-2"
                                    htmlFor="no-coital"
                                >
                                    No
                                </label>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className={this.state.details?'form-group micwrap ant-mic-wrap hasdata':'form-group micwrap ant-mic-wrap'}>
                              {/*   <a href="#" className="mic r-0"></a> */}
                              
                                <a
                                    disabled={
                                        this.state.details_disable || !this.state.allowEdit
                                    }
                                    onClick={(this.state.details_disable)? (e) => { e.preventDefault(); } : (e) => { e.preventDefault(); this.initSpeech({ identifier: 'details' }) } }
                                    className={(this.state.speech.identifier === 'details' && this.state.speech.state) ? 'mic r-0 on mute' : 'mic r-0'} 
                                ></a>
                                <textarea
                                    className="form-control speech-field"
                                    rows="1"
                                    placeholder="Enter"
                                    id="details"
                                    maxlegth="100"
                                    oninput="auto_height(this) "
                                    name="details"
                                    value={this.state.details}
                                    onChange={(e) => this.handleChangeInput(e)}
                                    disabled={this.state.details_disable || !this.state.allowEdit}
                                    onInput={(e) => this.autoHeight(e)}
                                    onFocus={this.handleOnFocus} 
                                ></textarea>


                                <label htmlFor="change-description required">
                                    Details {(this.state.details_required)?<span className="text-danger">*</span>:''}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-12">
                            <div className={this.state.remarks ? 'form-group micwrap ant-mic-wrap hasdata':'form-group micwrap ant-mic-wrap'}>
                                <a
                                    disabled={
                                        this.state.remarks_disable || !this.state.allowEdit
                                    }
                                    onClick={(this.state.remarks_disable || !this.state.allowEdit)? (e) => { e.preventDefault(); } : (e) => { e.preventDefault(); this.initSpeech({ identifier: 'remarks' }) } }
                                    className={(this.state.speech.identifier === 'remarks' && this.state.speech.state) ? 'mic r-0 on mute' : 'mic r-0'} 
                                ></a>

                                <textarea
                                    className="form-control speech-field"
                                    rows="1"
                                    placeholder="Remarks"
                                    id="remarks"
                                    // maxlegth="255"
                                    oninput="auto_height(this)"
                                    name="remarks"
                                    value={this.state.remarks}
                                    onChange={(e) => this.handleChangeInput(e)}
                                    disabled={this.state.remarks_disable || !this.state.allowEdit}
                                    onInput={(e) => this.autoHeight(e)}
                                    onFocus={this.handleOnFocus} 
                                ></textarea>
                                <label htmlFor="remarks-sexual">
                                    Remarks
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-12 text-right">
                        <button type="button" className="btn btn-outline-secondary mr-3 px-4" disabled={!this.state.allowEdit} onClick={() => this.Formclear()}>CLEAR</button>
                           <button
                                type="submit"
                                href="#"
                                className="float-md-right text-uppercase px-4 btn btn-primary"
                                data-target=""
                                disabled={this.state.disableSaveButton || this.state.spin_loading}
                            >
                               {this.state.buttonText}{this.state.spin_loading ? LOADER : ''}
                            </button>
                        </div>
                    </div>
                </Form>
            
                {(this.state.SexualHistoryList && this.state.SexualHistoryList.length > 0)?
                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table
                                className="table table-bordered"
                                id="sexual-his-table"
                            >
                                <thead>
                                    <tr>
                                        <th
                                            style={{
                                                width: "160px",
                                            }}
                                        >
                                            DATE OF RECORDING
                                        </th>
                                        <th
                                            style={{
                                                width: "150px",
                                            }}
                                        >
                                            SEXUALLY ACTIVE
                                        </th>
                                        <th>SINCE</th>
                                        <th>CONTRACEPTIVE</th>
                                        <th>COITAL FREQUENCY</th>
                                        <th>COITAL DIFFICULTY</th>
                                        <th
                                            style={{
                                                width: "200px",
                                            }}
                                        >
                                            REMARKS
                                        </th>
                                        <th
                                            style={{
                                                width: "200px",
                                            }}
                                        >
                                            CONTRACEPTIVE REMARKS
                                        </th>
                                        <th>ACTIONS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.FormListLoad()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
               :null}
            </div>
            </div>
            <div className="fixed-cta">
                {(this.props.pathname && (this.props.pathname.includes("objective") || LS_SERVICE.get('user_type') === 2)) ? 
                <a href="javascript:void(0)" className="btn btn-primary px-4 py-1 nostyle-link" onClick={this.handleContinue}>CONTINUE</a>  : null}
            </div>
            </React.Fragment>
        );
    }
}

export default SexualHx;
