import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Moment from "react-moment";
import { Popover } from 'antd';
import {
    GET_VITALS_RANGE_CLASS
} from '../../../utils/constant';

class Vitals extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: props.dataLoaded,
            constants: props.constants,
            visitVitalsFormatted: props.visitVitalsFormatted ?? [],
            vitalsConstant: this.props.homecareView && props.constants.vital_groups['VG003'] ? props.constants.vital_groups['VG003'].vital_sign : props.constants.vitals_details,
            homecareView: false
        }
    }
    componentDidMount() {
        this.setState({
            homecareView: this.props.homecareView
        })
    }
    static getDerivedStateFromProps = (nextProps, prevState) => {
        if (nextProps.visitVitalsFormatted !== prevState.visitVitalsFormatted)
            return { visitVitalsFormatted: nextProps.visitVitalsFormatted }
        return null;
    }
    // getVitalRangeClass(value, range) {
    //     if (value == '-') return '';
    //     let vitalRange = '';
    //     //normal range
    //     if (value >= range.normal.min && value <= range.normal.max)
    //         vitalRange = 'text-success';
    //     //abnormal range
    //     if (
    //         (value > range.critical.min && value < range.normal.min) ||
    //         (value > range.normal.max && value < range.critical.max)
    //     )
    //         vitalRange = 'text-warning';
    //     //critical range
    //     if ((range.critical.min != null && value <= range.critical.min) || (range.critical.max != null && value >= range.critical.max))
    //         vitalRange = 'text-danger';
    //     return vitalRange;
    // }
    // handleModalPopupStateLift = (e, popup, data = null, deleteType = '') => {
    // }
    getSourceDisplayName(source, date, update_date) {
        let sourceName = source ? source[0].toUpperCase() + source.substring(1) : '';
        let time = moment(update_date).format('DD MMM, h:mm A');
        return source == 'patient' && date && update_date && date != update_date ? sourceName + ' | ' + time : sourceName;
    }

    emptyFields(actualLength, thisLength){
      var finalLength = actualLength - thisLength;
      let i = 1;
      let text = [];
      while (i <= finalLength) {
        text.push(<td className={`text-center`}>-</td>);
        i++;
      }
      return(text)
    }

    render() {
        var visitVitalsFormattedLength = this.state.visitVitalsFormatted?.V001?.length || 0;
        return (
            <>
                {this.state.dataLoaded && this.state?.visitVitalsFormatted ? (
                    <div className="card-body vitals px-1" id="vital-details">
                        <div className="row">
                            <div style={{ height: this.props.pageFrom === 'ER' ? 'calc(100vh - 470px)' : 'calc(100vh - 340px)', overflow: 'auto', width: '100%' }}>
                                <div className="table-containerr">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Parameter</td>
                                                {this.state?.visitVitalsFormatted?.date.map((v, i) => (
                                                    <td key={i} className={`pl-0 pr-0 ${this.state.visitVitalsFormatted.source[i] === 'patient' && 'bgis-pink'}`} style={{ width: "250px", whiteSpace: 'nowrap' }}>
                                                        <div className="row">
                                                            <div className="col-12 text-center vital-source">
                                                                {this.state.visitVitalsFormatted?.source_name[i] !== "" ? (
                                                                    <Popover className="ml-1" content={this.state.visitVitalsFormatted?.source_name[i]}>
                                                                        {this.getSourceDisplayName(this.state.visitVitalsFormatted.source[i], v, this.state.visitVitalsFormatted.update_date[i])}
                                                                    </Popover>
                                                                ) : (
                                                                    this.getSourceDisplayName(this.state.visitVitalsFormatted.source[i], v, this.state.visitVitalsFormatted.update_date[i])
                                                                )}
                                                                <br />
                                                                {moment(this.state.visitVitalsFormatted.update_date[i]).format('DD MMM, YYYY | hh:mm A') == 'Invalid date' ? this.state.visitVitalsFormatted.update_date[i] : moment(this.state.visitVitalsFormatted.update_date[i]).add(330,'minutes').format('DD MMM, YYYY | hh:mm A')}
                                                            </div>
                                                        </div>
                                                    </td>
                                                ))}
                                                <td className="text-center" style={{ width: "132px" }}>Reference Range</td>
                                                <td className="text-center">Trend Graph</td>
                                            </tr>
                                            {
                                                this.state.vitalsConstant.map((vital) => {
                                                    if (vital.id != 'V005' && vital.id != 'V006' && vital.id != 'V016')
                                                        return (
                                                            <tr key={vital.id}>
                                                                <td>{vital.display_name} {vital.display_value ? '('+vital.display_value+')' : null}</td>
                                                                {this.state.visitVitalsFormatted[vital.id].map((val, i) => {
                                                                    if (val) return (<td key={i} className={`text-center ${vital.id != 'V001' && vital.id != 'V002' && vital.id != 'V019' ? GET_VITALS_RANGE_CLASS(val, this.state.constants.vitals_range[vital.id]) : ''} ${this.state.visitVitalsFormatted.source[i] === 'patient' && 'bgis-pink'}`}>{val}</td>)
                                                                    else return (<td key={i} className={`text-center ${this.state.visitVitalsFormatted.source[i] === 'patient' && 'bgis-pink'}`}>-</td>);
                                                                })}
                                                                {
                                                                  visitVitalsFormattedLength == this.state.visitVitalsFormatted[vital.id].length 
                                                                ?
                                                                  null
                                                                :
                                                                  this.emptyFields(visitVitalsFormattedLength, this.state.visitVitalsFormatted[vital.id].length)
                                                                }
                                                                <td className="text-center">
                                                                  {
                                                                    vital.id == 'V001' || vital.id == 'V002' || vital.id == 'V019' 
                                                                  ?
                                                                    '-' 
                                                                  : 
                                                                    <>
                                                                      {
                                                                        ((this.state.constants.vitals_range[vital.id].normal.min && this.state.constants.vitals_range[vital.id].normal.min != 0 && this.state.constants.vitals_range[vital.id].normal.min != null) || (this.state.constants.vitals_range[vital.id].normal.max && this.state.constants.vitals_range[vital.id].normal.max != 0 && this.state.constants.vitals_range[vital.id].normal.max != null))
                                                                      ?
                                                                        this.state.constants.vitals_range[vital.id].normal.min + '-' + this.state.constants.vitals_range[vital.id].normal.max
                                                                      :
                                                                        '-'
                                                                      }
                                                                    </>
                                                                  }
                                                                </td>
                                                                <td className="text-center">
                                                                    {
                                                                      (vital.vital_type == 'N')
                                                                    ?
                                                                      <button type="button" className="btn chart-btn button-chart-individual"
                                                                          onClick={e => this.props.handleModalPopupStateLift(e, 'chartModal', { type: vital.id, name: 'Trend Graph' })}>
                                                                          <i className="icon-chart" style={{ width: '18px', height: '18px' }}></i>
                                                                      </button>
                                                                    :
                                                                      <>-</>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    else if (vital.id == 'V005')
                                                        return (
                                                            <tr key={vital.id}><td>Blood Pressure (mmHg)</td>
                                                                {this.state.visitVitalsFormatted.V006.map((val, i) => (
                                                                    <td className={`text-center ${this.state.visitVitalsFormatted.source[i] === 'patient' && 'bgis-pink'}`} key={i}>
                                                                        <span className={(val ? GET_VITALS_RANGE_CLASS(this.state.visitVitalsFormatted.V006[i], this.state.constants.vitals_range.V006) : '')}>{this.state.visitVitalsFormatted.V006[i] ? this.state.visitVitalsFormatted.V006[i] : '-'}</span>/
                                                                        <span className={(val ? GET_VITALS_RANGE_CLASS(this.state.visitVitalsFormatted.V005[i], this.state.constants.vitals_range.V005) : '')}>{this.state.visitVitalsFormatted.V005[i] ? this.state.visitVitalsFormatted.V005[i] : '-'}</span>
                                                                    </td>)
                                                                )}
                                                                <td className="text-center">{this.state.constants.vitals_range.V006.normal.min + '-' + this.state.constants.vitals_range.V006.normal.max}/{this.state.constants.vitals_range.V005.normal.min + '-' + this.state.constants.vitals_range.V005.normal.max}</td>
                                                                <td className="text-center">
                                                                    <button type="button" className="btn chart-btn button-chart-individual"
                                                                        onClick={e => this.props.handleModalPopupStateLift(e, 'chartModal', { type: vital.id, name: 'Trend Graph' })}>
                                                                        <i className="icon-chart" style={{ width: '18px', height: '18px' }}></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                })}
                                        </tbody>
                                    </table>
                                </div></div>
                        </div>
                    </div>) : ""}
            </>
        );
    }
}
export default Vitals;