import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Axios from 'axios';
import qs from 'qs';
import LS_SERVICE from '../../utils/localStorage';
import {
  LATE_ALERT,
  USERTYPES,
  PATIENT_QUEUE,
  APPS_FEATURES_LIST,
  SYNC_TOKEN,
  LOADER_RED,
  MYHC_APPTYPE,
  MHVCMARKETPLACE,
  MHEA,
  is_chat_enabled,
  ISALLOWEDITSOAP,
  NURSE_MARK_ARRIVAL,
  LATE_ALERT_PATIENT,
} from '../../utils/constant';
import API_SERVICE from '../../utils/apiService';
import { Modal, Checkbox, Select, Spin, notification } from 'antd';
import 'antd/dist/antd.css';
import $ from 'jquery';
import { jQueryLabels, onlynumbers } from '../../utils/jQueryLabels';
import chat from '../../assets/images/ic-chat-pubnub.svg';
import moment from 'moment';

const { Option } = Select;

class PatientLateALert extends Component {

  state = {
    isModalVisible: false,
    modalResponseMsg: '',
    AlertStatus: true,
    late_time: '15',
    comment: '',
    loadData: false,
    docDropDownList: [],
    informAllPatients: false,
    nurseDoctors: [],
    nurseDoctorId: null,
    patientsList: [],
    slots: [],
    hospital_id: null,
    patientQueueHitAfter10secs: false,
    patientAlertInformList: [],
    loading: false,
    patientBookingList: [],
    checkboxInformAllStatus: true,
  }

  constructor(props) {
    super(props);
  }

  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return null;
  }

  setAlert = (event) => {
    this.setState({ late_time: event.target.value });
    if (event.target.value === "0") {
      ReactDOM.findDOMNode(this.refs.other_mins).classList.remove('d-none');
    } else {
      ReactDOM.findDOMNode(this.refs.other_mins).classList.add('d-none');
    }

  }

  componentDidMount() {
    $(document).ready(function () {
      jQueryLabels();
      onlynumbers();
    })

    // const docsList = LS_SERVICE.get('nurse_doctors')

    if (LS_SERVICE.get('user_type') === USERTYPES.nurse) { //2: nurse, 1: doctor

      this.setState({
        nurseDoctors: LS_SERVICE.get('nurse_doctors'),
        // nurseDoctorId: LS_SERVICE.get('nurse_doctor_id'),
        hospital_id: parseInt(LS_SERVICE.get('slot_hospital_id')),
      }, () => {
        LS_SERVICE.set('nurse_doctor_id', '');
        this.setState({ loading: true });
        this.initQueue();
      });
    }
  }

  async initQueue() {
    if (APPS_FEATURES_LIST.select_doctor_dropdown && LS_SERVICE.get('user_type') == USERTYPES.nurse && LS_SERVICE.get('nurse_doctor_id') == null) {
      //return;
    }

    const hospitalId = parseInt(LS_SERVICE.get('slot_hospital_id'));

    let syncData = null;

    if (LS_SERVICE.get('nurse_doctor_id') != null) {
      this.setState({ practicing: { status: false, hospital_id: hospitalId } });
      syncData = await API_SERVICE.post(SYNC_TOKEN, {
        staff_id: LS_SERVICE.get('staff_id'),
      });
    }

    this.setState({
      slots: syncData?.slots || [],
    }, () => {
      //console.log("slots: ", this.state.slots);
    });

    await this.loadPatientQueue();
    this.setState({ loading: false });

    /**
     * We are calling patient Queue again in order to fetch
     * Prescription PDF, which takes time to get generated after
     * prescription is completed
     */
    if (!this.state.patientQueueHitAfter10secs) {
      setTimeout(() => {
        this.loadPatientQueue();
        this.setState({ patientQueueHitAfter10secs: true });
      }, 10000);
    }
    return;
  }

  async loadPatientQueue() {
    const { hospital_id } = this.state;
    let postdata;
    let userType = '';
    let doctor_id = LS_SERVICE.get('nurse_doctor_id');

    if (!doctor_id || doctor_id.length === 0) {
      return;
    }

    if (LS_SERVICE.get('user_type') == USERTYPES.nurse) {
      postdata = { hospital_id: this.state.hospital_id, doctor_id: [this.state.nurseDoctorId] };
      userType = 'nur';
    }


    const patientQueueUri = userType === 'nur' ? API_SERVICE.post(PATIENT_QUEUE, postdata) : (LS_SERVICE.get('nurse_doctor_id') != null ? API_SERVICE.post(PATIENT_QUEUE, postdata) : null);

    Promise.all([patientQueueUri])
      .then(success => {
        const patientQueue = success[0];

        if (typeof patientQueue === null || typeof patientQueue?.data === 'undefined') {
          this.setState({
            loading: false
          });
          return;
        }


        //Filter array that appointments has been completed
        var tempPateient = patientQueue.data.patients;
        var filteredPatients = tempPateient.filter(function (el) {
          // return el.list != 1 ;
          return el.list=='worklist'
        });

        this.setState({
          patientsList: filteredPatients,
        }, () => {
          this.setState({
            loading: false
          });
        });
        return;
      })

  }


  hideModal = () => {
    this.setState({ isModalVisible: false });
    this.props.history.push("/dashboard");
  }

  hideModalEpt = () => {
    this.setState({ isModalVisible: false });
  }

  loader() {
    return (
      <div className="col-md-12 text-center">{LOADER_RED}</div>
    )
  }

  handleConfirm = (event) => {
    event.preventDefault();
    let late_time = this.state.late_time;
    let patientBookingList = this.state.patientBookingList;

    if (late_time !== '' && patientBookingList.length > 0) {
      const PARAMS = {
        late_time,
        booking_id: patientBookingList,
      }
      this.setState({
        loadData: true
      })

      Axios.post(LATE_ALERT_PATIENT, qs.stringify(PARAMS))
        .then(response => {

          if (response.data.status == true) {
            this.setState({
              // modalResponseMsg: response.data.message,
              loadData: false,
              //isModalVisible: true
              late_time: '15',
              informAllPatients: false,
              patientBookingList: [],
            }, () => {
              this.isAllPatientSelected();
            });

            notification.success({
              message: "Late alert sent successfully.",
              placement: 'topRight'
            });
          }

          //debugger;
        }).catch(error => {
          // if (error && error.response && error.response.status === 401) {
          // 	LS_SERVICE.clear();
          // 	this.props.history.push("/");
          // }
        })
    } else {
      if (late_time === "") {
        let emptyValue = 'Please enter the time (in minutes).';
        // this.setState({
        // 	modalResponseMsg: emptyValue,
        // 	loadData: false,
        // 	isModalVisible: true
        // })

        notification.error({
          message: emptyValue,
          placement: 'topRight'
        });
      }
    }
  }

  informAllPatientsHandler = (e) => {
    const checked = e.target.checked;
    let patientsList = [];

    if (checked) {
      patientsList = this.state.patientsList.map(patient => {
        return patient.booking_id
      });
      this.setState({ informAllPatients: true, patientBookingList: patientsList }, () => {
        this.isAllPatientSelected();
      });
    } else {
      this.setState({ informAllPatients: false, patientBookingList: [] }, () => {
        this.isAllPatientSelected();
      });
    }
  }

  isAllPatientSelected = () => {
    if (this.state.patientsList.length === this.state.patientBookingList.length && this.state.patientBookingList.length !== 0) {
      this.setState({ informAllPatients: true, AlertStatus: false });
    } else if (this.state.patientBookingList.length === 0) {
      this.setState({ informAllPatients: false, AlertStatus: true });
    } else {
      this.setState({ informAllPatients: false, AlertStatus: false, AlertStatus: false });
    }
  }

  handleLateLalert = (e) => {
    const value = JSON.parse(e.target.value)
    //	this.checkLateAlertStatus(value.staff_id)
  }

  async nurseMarkArrivedHandler(e, bookingId) {
    e.stopPropagation();
    const booking_id = bookingId;
    if (bookingId) {
      Axios.post(NURSE_MARK_ARRIVAL, { booking_id })
        .then(success => {
          notification.success({
            message: 'Successfully marked arrived',
            placement: 'topRight'
          });
          this.loadPatientQueue();
        }).catch(err => {
          notification.error({
            message: 'Failed to marke arrived',
            placement: 'topRight'
          });
          console.log(err);
        })
    }
  }

  holdInfo(patient) {
    const currentUserRole = LS_SERVICE.get('user_type');
    const practicing = this.state.practicing;

    if (currentUserRole == USERTYPES.nurse && patient.booking_status == "Pending") {
      // return (
      //   <div className="patient_cta">
      //     <button type="button" onClick={(e) => this.nurseMarkArrivedHandler(e, patient.booking_id)} className="btn btn-sm btn-outline-danger text-uppercase">Mark Arrival</button>
      //   </div>
      // )
    }
  }

  getSeparator(value) {
    if (value && value != null) {
      return ' | ';
    } else {
      return null;
    }
  }

  progressBar(patient, type) {
    const appointment_type = patient.is_walk_in ? patient.is_walk_in : 'Unknown';

    if (type === 'appointment') {
      return (appointment_type === 'Walk In') ? '' : 'completed';
    } else if (type === 'arrived') {
      // return (appointment_type === 'Walk In') ? 'completed first' : 'completed';
      let t1 = (appointment_type === 'Walk In') ? 'first' : '';
      let t2 = t1 + ' ' + (patient.token_time && patient.token_time != null) ? 'completed' : '';
      return t2;
    } else if (type === 'vital') {
      let vital_completed = (patient.vital_set) ? "completed" : '';
      if (patient.is_completed && patient.is_completed_time) {
        if (!vital_completed) {
          vital_completed = "completed escape";
        }
      }
      return vital_completed;
    } else if (type === 'visit') {
      return (patient.is_completed) ? "completed" : '';
    }
    return '';
  }

  viewRx(e, pdf) {
    this.setState({
      showRxModal: true,
      rxPdfPath: pdf
    });
    e.stopPropagation();
  }

  patientInfo(patient) {
    return (
      <>
        {patient.uhid || patient.age || patient.gender || patient.blood_group ? <span> - </span> : null}
        {patient.uhid ? <span>{(MYHC_APPTYPE === MHVCMARKETPLACE || MYHC_APPTYPE === MHEA) ? "" : "UHID"} {patient.uhid}</span> : null}
        {patient.dob ? <span>{this.getSeparator(patient.uhid)} {this.getPatientAge(patient.dob)}</span> : null}
        {patient.gender ? <span>{this.getSeparator(patient.dob)} {patient.gender}</span> : null}
        {patient.blood_group ? <span>{this.getSeparator(patient.gender)} Blood Group: {patient.blood_group}</span> : null}
      </>
    )
  }

  appointmentTime(patient) {
    const appointment_type = patient.is_walk_in ? patient.is_walk_in : 'Unknown';
    return (appointment_type === 'Walk In') ? '' : '(' + patient.start_time + ')';
  }

  onPubNubChatClickStateLife() {

  }

  completedVcAppointment(patient) {
    const currentUserRole = LS_SERVICE.get('user_type');
    const practicing = this.state.practicing;
    if (currentUserRole == USERTYPES.doctor) {
      return <div className="patient_cta mr-2">
        {practicing !== null && practicing.status && ISALLOWEDITSOAP(this.state.pres_modification, this.state.allow_hours, patient.consult_end_at, 'patient-queue') ? <><button type="button" className="btn btn-sm btn-primary btn-rx mb-2 mt-2 text-uppercase">EDIT RX</button><br /></> : null}
        {patient.visit_pdf ? <button onClick={(e) => this.viewRx(e, patient.visit_pdf)} type="button" className="btn btn-sm btn-primary btn-rx text-uppercase show-rx-modal" >VIEW RX</button> : null}
      </div>;
    } else {
      return null;
    }
  }

  onSelectPatientAlert = (e, booking_id) => {
    //e.stopPropagation();
    //console.log("e: ", e, booking_id);
    //const checked = e.target.checked;
    const patientSelectListArr = this.state.patientBookingList;
    if (booking_id) {
      let index = patientSelectListArr.indexOf(booking_id);
      // if(checked) {
      if (index === -1) {
        patientSelectListArr.push(booking_id);
        this.setState({ patientBookingList: patientSelectListArr }, () => {
          this.isAllPatientSelected();
        })
      }
      //} else {
      if (index !== -1) {
        patientSelectListArr.splice(index, 1);
        this.setState({ patientBookingList: patientSelectListArr }, () => {
          this.isAllPatientSelected();
        });
      }
      // }          
    }
  }

  callNext(patient) {
    const currentUserRole = LS_SERVICE.get('user_type');
    LS_SERVICE.set('call_patient_detail', patient);
    LS_SERVICE.set('call_patient_id', patient.patient_id);
    LS_SERVICE.set('call_patient_qms_token_id', patient.qms_token_id);
    const hospital_id = this.state.hospital_id;

    if (!this.state?.practicing?.status && currentUserRole == USERTYPES.doctor) {
      this.setState({
        practiceNotStarted: true,
        goToPatient: patient
      });
      return;
    }
    if (currentUserRole == USERTYPES.doctor) {
      if (this.state.practicing.status && (patient.list === 'pending' || patient.list === 'completed') && currentUserRole == USERTYPES.doctor) {
        return this.completePendingVisit(patient);
      } /* else if (patient.list !== 'worklist' && currentUserRole == USERTYPES.nurse) {
    
          } */ else if (patient.list !== 'pending') {
        return this.callPatient(patient);
      } else {
        return;
      }
    } else {
      if (patient.list != 'completed') {
        //this.props.history.push('/patient/todays-visit/objective/' + patient.patient_id + '/' + LS_SERVICE.get('reference_type') + '/' + patient.qms_token_id);
        this.props.history.push({ pathname: '/patient/todays-visit/objective/' + hospital_id + '/' + patient.patient_id + '/' + LS_SERVICE.get('reference_type') + '/' + patient.qms_token_id, state: { patient } });
      }
      return;
    }
  }



  showPatienListing = () => {
    const currentUserRole = LS_SERVICE.get('user_type');
    const practicing = this.state?.practicing;
    let patients = [];

    var appointmentCompletedTime = LS_SERVICE.get('appointmentCompletedTime') ?? {};

    if (this.state.patientsList.length > 0) {
      //patients = this.state.patientsList.filter((patient) => patient.booking_status !== "Pending");
      patients = this.state.patientsList.filter((patient) => patient);
    }

    return patients.length > 0 ? (
      patients.map((patient, index) => (
        <div key={index} className={"card mb-4 patient-card rounded-0 " + (patient.visit_status === 3 && patient.list === 'worklist' ? ' emeregency' : (patient.consult_type == 1 ? ' virtual_consult' : ''))}>
          <div className="" onClick={(e) => this.onSelectPatientAlert(e, patient.booking_id)} style={{ cursor: "pointer" }}>
            <div className="card-body card-body pt-3 pb-0">
              <div className="row">
                <div className="col-8">
                  <span className="text-dark font-weight-bold">
                    <Checkbox
                      //onClick={e => e.stopPropagation()}
                      value={patient.booking_id}
                      checked={this.state.patientBookingList.includes(patient.booking_id) ? true : false}
                      defaultChecked={this.state.patientBookingList.includes(patient.booking_id) ? true : false}
                    //onChange={(e) => this.onSelectPatientAlert(e, patient.booking_id)}
                    >
                    </Checkbox> <span className="ml-2">{patient.fullname}</span>
                  </span>
                  {this.patientInfo(patient)}
                </div>
                <div className="col-4 text-right">
                  {patient.visit_status === 2 ?
                    <div className="patient_cta"><div className="on-hold-btn mr-2"><span className="on-hold">||</span>ON HOLD</div></div>
                    : patient.list === 'completed' ? null : patient.wait_time != '0 Hrs 0 Mins' ? <div className="wait-time">
                      <span className="font-weight-bold"><span className="text-danger">LATE</span> | WAIT TIME: </span>
                      {patient.wait_time != '0 Hrs 0 Mins' ? <span className="text-danger">{patient.wait_time}</span> : null}
                    </div> : null
                  }
                </div>

                <div className={`col-12 ${patient.is_emergency && patient.is_corporate ? "mt-3" : ""}`}>
                  {(patient.is_emergency && patient.is_emergency == 1) ? <span className="text-danger mr-3">Emeregency</span> : ''}
                  {(patient.is_corporate && patient.is_corporate == 1) ? <span className="mr-3">Corporate</span> : ''}
                </div>

                {/* && patient.list === 'worklist' */}
                {patient.consult_type == 1 ?
                  <>
                    <div className="col mt-2 mb-3">
                      {/* <div className="time-line-container position-relative"> */}

                      <label className="virtual_consult_icon text-secondary"><i className="icon_video"></i>VIRTUAL CONSULT</label>  &nbsp;|
                      <span className="progress-appointment ml-2">Appointment <strong>{this.appointmentTime(patient)}</strong></span>
                    </div>
                    <div className="col mt-2 mb-3" >
                      {patient.list === 'completed' ? this.completedVcAppointment(patient) : <>
                        <div className="patient_cta mr-2">
                          {is_chat_enabled && (
                            <img src={chat} alt="chat_ic" style={{ top: '0px', right: '10px', position: 'relative', height: '25px' }} className={patient.is_chat ? 'd-block mr-2' : 'd-none'} />
                          )}
                          {practicing !== null && practicing?.status ? <button type='button' className="btn btn-sm btn-primary text-uppercase">START CONSULT</button> : null}
                        </div>
                      </>}
                      {/* </div> */}
                    </div>
                  </> :
                  <>
                    <div className={"col-12 " + (patient.list === 'completed' && currentUserRole === USERTYPES.doctor ? 'mt-3' : 'mt-2')}>
                      <div className="time-line-container position-relative">
                        <label className="virtual_consult_icon" style={{ position: 'absolute', top: '-5px' }}><i className="icon_opd"></i>OPD CONSULT</label>
                        <ul className="progress-indicator" >
                          <li className={this.progressBar(patient, 'appointment')}>
                            <span className="bubble"></span>
                            <span>Appointment <b>{this.appointmentTime(patient)}</b></span>
                          </li>
                          <li className={this.progressBar(patient, 'arrived')}>
                            <span className="bubble"></span>
                            <span>Arrived {patient.token_time && patient.token_time != null ? <b>({patient.token_time})</b> : null}</span>
                          </li>
                          <li className={patient.billed_time && patient.billed_time != null ? 'completed' : ''}>
                            <span className="bubble"></span>
                            <span>Billed {patient.billed_time && patient.billed_time != null ? <b>({patient.billed_time})</b> : null}</span>
                          </li>
                          <li className={this.progressBar(patient, 'vital')}>
                            <span className="bubble"></span>
                            <span>Vitals Recorded {patient.vital_set && patient.vital_set_time ? <b>({patient.vital_set_time})</b> : ''}</span>
                          </li>
                          <li className={this.progressBar(patient, 'visit')}>
                            <span className="bubble"></span>
                            <span className="consult">Consult {patient.is_completed && patient.is_completed_time ? <b>({patient.is_completed_time})</b> : ''}</span>
                          </li>
                        </ul>
                        {this.holdInfo(patient)}
                      </div>
                    </div>
                  </>}
              </div>
            </div>
          </div>
        </div>
      )))
      : null;

  }

  getPatientAge(dob) {
    var ageD = Math.floor(moment().diff(moment(dob, 'YYYY-MM-DD'), 'D') / (1000 * 3600 * 24));
    var ageM = moment().diff(moment(dob, 'YYYY-MM-DD'), 'M');
    var ageY = ageM / 12;
    var remainM = ageM % 12;
    ageY = Math.floor(ageY);
    var ageText = '';
    if (ageM == 0)
      ageText = ageD + ' Day' + (ageD == 1 ? '' : 's');
    else if (ageY < 1)
      ageText = ageM + ' Month' + (ageM == 1 ? '' : 's');
    else if (ageY <= 5) {
      if (remainM != 0) {
        ageText = ageY + ' Year' + (ageY == 1 ? '' : 's ') + remainM + ' Month' + (remainM == 1 ? '' : 's');
      } else {
        ageText = ageY + ' Year' + (ageY == 1 ? '' : 's');
      }
    }
    else
      ageText = ageY + ' Years';
    return ageText;
  }

  nurseSelectedDoctor = async (val, props) => {

    const doctorId = val;

    if (!doctorId || doctorId == NaN) {
      notification.error({
        message: 'Please select doctor',
        placement: 'topRight'
      });

      this.setState({
        nurseDoctorId: '',
        patientQueueHitAfter10secs: false,
        patientsList: [],
        checkboxInformAllStatus: true,
        informAllPatients: true,
        patientBookingList: []
      }, () => {
        LS_SERVICE.set('nurse_doctor_id', '');
        this.isAllPatientSelected();
      });
      return;
    }

    if (doctorId) {
      this.setState({ nurseDoctorId: doctorId, loading: true, checkboxInformAllStatus: false }, () => {
        LS_SERVICE.set('nurse_doctor_id', doctorId);
        this.loadPatientQueue();
      });
    }
  }

  render() {

    let isEmptyComment = this.state.comment;
    let late_time = this.state.late_time;
    let button;
    if (this.state.AlertStatus === false && (isEmptyComment === '' || late_time === '')) {
      button = <div className="btn btn-outline-primary px-5" onClick={this.hideModalEpt}>OK</div>;
    } else {
      button = <div className="btn btn-outline-primary px-5" onClick={this.hideModal}>OK</div>;
    }

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <form className="emrfrm">
                {LS_SERVICE.get('user_type') === USERTYPES.nurse ? ( //2: nurse, 1: doctor
                  <div className="row">
                    <div className="col-3 col-md-3" style={{ alignItems: "baseline" }}>
                      <div className="form-group hasdata mt-2">
                        <Select
                          id="select_doctor"
                          style={{
                            width:
                              100 +
                              "%",
                          }}
                          onChange={this.nurseSelectedDoctor}
                          name="select_doctor"
                          className=""
                          defaultValue={this.state.nurseDoctorId}
                          value={this.state.nurseDoctorId}
                          //mode="multiple"
                          allowClear=""
                          filterOption={(input, option) => {
                            return (
                              option.children
                                .toString()
                                .toLowerCase()
                                .indexOf(
                                  input
                                    .toString()
                                    .toLowerCase()
                                ) >=
                              0
                            );
                          }}
                          notFoundContent={
                            this.state
                              .spinner ? (
                              <Spin size="small" />
                            ) : (
                              <div>
                                No
                                Result
                                Found
                              </div>
                            )
                          }
                        >
                          {this.state.nurseDoctors.map((doctor, index) => (
                            <Option key={index} value={doctor.id} selected={this.state.nurseDoctorId == doctor.id}>
                              {doctor.name} | {doctor.speciality.length > 0 ? doctor.speciality[0] : null}
                            </Option>
                          ))}
                        </Select>
                        <label htmlFor="select_doctor">Select Doctor*</label>
                      </div>
                    </div>
                    <div className="col-7 col-md-7 d-flex" style={{ alignItems: "baseline" }}>
                      <h6 className="text-dark mr-2">Late by:</h6>
                      <div className="row">
                        <div className="col-12">
                          <div className="custom-control custom-radio custom-control-inline mt-3 mr-5">
                            <input type="radio" id="alert_15" name="alert" className="custom-control-input pr-5" value="15" defaultChecked checked={this.state.late_time == '15' ? true : false} onChange={this.setAlert} />
                            <label className="custom-control-label pl-2" htmlFor="alert_15">Late by 15 mins</label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline mt-3">
                            <input type="radio" checked={this.state.late_time == '30' ? true : false} id="alert_30" name="alert" className="custom-control-input pr-5" value="30" onChange={this.setAlert} />
                            <label className="custom-control-label pl-2" htmlFor="alert_30">Late by 30 mins</label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline mt-3">
                            <input type="radio" id="alert_45" checked={this.state.late_time == '45' ? true : false} name="alert" className="custom-control-input pr-5" value="45" onChange={this.setAlert} />
                            <label className="custom-control-label pl-2" htmlFor="alert_45">Late by 45 mins</label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline mt-3">
                            <input type="radio" id="alert_60" checked={this.state.late_time == '60' ? true : false} name="alert" className="custom-control-input pr-5" value="60" onChange={this.setAlert} />
                            <label className="custom-control-label pl-2" htmlFor="alert_60">Late by 60 mins</label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline mt-3 d-none" id="other_mins" ref='other_mins'>
                            <input type="text" className="ml-2 form-control onlynumbers" id="late-others-input" style={{ maxWidth: "30%", textAlign: "center" }} maxLength="3" autoComplete="off" ref="othersValue" />
                            &nbsp;&nbsp;&nbsp;<label className="mt-2">Mins</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-2 col-md-2 d-flex" style={{ alignItems: "center", justifyContent: "flex-end" }}>
                      <Checkbox
                        value={this.state.informAllPatients}
                        disabled={this.state.checkboxInformAllStatus}
                        name="inform_all_patients"
                        checked={this.state.informAllPatients}
                        defaultChecked={this.state.informAllPatients}
                        onChange={this.informAllPatientsHandler}>
                        Inform All Patients
                      </Checkbox>
                    </div>
                  </div>
                ) : ""}

              </form>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-4 px-3" id="patients_container">
            {this.state.loading ? this.loader() : this.showPatienListing()}
          </div>
        </div>

        {this.state.patientList?.length === 0 &&
          <div className="row">
            <div className="col-md-12">
              <div className="nostyle-link">
                <div className="card hospital-card shadow-box">
                  <div className="card-body">
                    <div className="text-center">
                      <h4>No Appointment</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}

        <div className="row">
          <div className="col-md-12 text-center mt-5">
            <button className="btn btn-primary px-5 text-uppercase" disabled={this.state.AlertStatus === true ? 'disabled' : ''} onClick={this.handleConfirm}>Alert Patients</button>
          </div>
        </div>

        <Modal
          title={false}
          closable={false}
          visible={this.state.isModalVisible}
          footer={false}
          width="400px"
        >
          <div className="row">
            <div className="col-12 my-3 text-center">
              <h6 id="pending-patients-count" ref="modalMsg">{this.state.modalResponseMsg}</h6>
            </div>
          </div>
          <div className="modal-footer justify-content-center pb-0">
            {button}
            {/* <div className="btn btn-outline-primary px-5"  onClick={this.hideModal}>OK</div> */}
          </div>
        </Modal>

      </>
    );
  }
}

export default PatientLateALert;