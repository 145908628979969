import Axios from 'axios';
import qs from 'qs';
import LS_SERVICE from './localStorage';
import { headerService$ } from './rxjs-sharing';

const API_SERVICE = {
    status(response) {
        const LOGOUT = (res) => {
            if (res.status === 401 || (res.status === 400 && LS_SERVICE.has('loginMobile') === false)) {
                LS_SERVICE.clear();
                headerService$.showHeader({ showHeader: 'false' });
                return;
            }
        }
        if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(response)
        } else {
            LOGOUT(response);
            return Promise.reject(new Error(response.statusText))
        }
    },
    json(response) {
        return response.json()
    },
    error(err) {
        return err;
    },
    get(path) {
      console.log("get",path)
      if(path.includes("/api/ipdemr/")){
        return fetch(`${path}`, {
            headers: {
                'Authorization': LS_SERVICE.get('HIS_TOKEN'),
                'enteredBy': LS_SERVICE.get('hisuserid'),
            }
        })
        .then(this.status)
        .then(this.json)
        .catch(this.error);
      }
      else{
        return fetch(`${path}`, {
            headers: {
                'Authorization': LS_SERVICE.get('token'),
                'hisauthorization': LS_SERVICE.get('HIS_TOKEN'),
                'hisuserid': LS_SERVICE.get('hisuserid'),
            }
        })
        .then(this.status)
        .then(this.json)
        .catch(this.error);
      }
    },
    post(path, body) {
      console.log("post",path)
      if(path.includes("/api/ipdemr/")){
        return fetch(`${path}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': LS_SERVICE.get('HIS_TOKEN'),
                'enteredBy': LS_SERVICE.get('hisuserid'),
            },
            body: qs.stringify(body),
        })
        .then(this.status)
        .then(this.json)
        .catch(this.error);
      }
      else{
        return fetch(`${path}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': LS_SERVICE.get('token'),
                'hisauthorization': LS_SERVICE.get('HIS_TOKEN'),
                'hisuserid': LS_SERVICE.get('hisuserid'),
            },
            body: qs.stringify(body),
        })
        .then(this.status)
        .then(this.json)
        .catch(this.error);
      }
    }
}

Axios.interceptors.request.use(async config => {
  if(config.url.includes("/api/ipdemr/")){
    config.headers.Authorization = LS_SERVICE.get('HIS_TOKEN')
    config.headers["enteredBy"] = LS_SERVICE.get('hisuserid')
  }
  else{
    config.headers.Authorization = LS_SERVICE.get('token')
    config.headers.hisauthorization = LS_SERVICE.get('HIS_TOKEN')
    config.headers["hisuserid"] = LS_SERVICE.get('hisuserid')
  }
  
  return config;
}, error => Promise.reject(error));

Axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (res) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // return Promise.reject(error);
    if (res?.response?.status === 401 || (res.status === 400 && LS_SERVICE.has('loginMobile') === false)) {
        // (res?.response?.status === 400 && LS_SERVICE.has('loginMobile') === false)) {
        LS_SERVICE.clear();
        headerService$.showHeader({ showHeader: 'false' });
        return;
    }

    return Promise.reject(res);
});

export default API_SERVICE;
