import React from 'react'
import { withRouter } from "react-router-dom";
import AsideLeft from '../../components/aside/asideleft';
import Axios from "axios";
import { ALLERGIES_N_CONDITIONS, ALLERGY_INTENSITY_IC, ALLERGY_INFORMED_BY, DURATION_TYPE, MEDICATION_ON, LOADER_RED } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import { headerService$ } from '../../utils/rxjs-sharing';
class Allergies extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      patient: null,
      allergiesData: [],
    }
  }

  componentDidMount() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");

    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_id } = params;
    const patient = {
      hospital_id,
      patient_id,
      qms_token_id: reference_id
    }
    this.setState({
      hospital_id,
      patient_id,
      reference_id,
      patient
    }, () => {
      if (LS_SERVICE.has('hospitalConfigData')) {
        const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
        let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
        headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' }); // IMPORTANT TO HIT AS PER THE VIEW
        this.getAllergies();
      }
    });
  }

  getAllergies = () => {
    const { patient_id } = this.state;

    Axios.get(ALLERGIES_N_CONDITIONS({ patient_id }))
      .then(res => {
        this.setState({
          allergiesData: res.data.data.details,
        })
      })
  }


  goBack = (event) => {
    event.preventDefault();
    this.props.history.goBack();
  }


  render() {

    const { patient, allergiesData, isAllergiesLoading } = this.state

    return (
      <>
        {(patient != undefined && patient.patient_id != undefined) ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}

        <div className="content-wrapper">
          <div className="container-fluid">
            {/*<h5 className="py-2 text-dark"></h5>*/}
            <div className="row">
              <div className="col-12 mb-3">
                <div className="float-left">
                  <h1 className="page-title mb-0">Allergies &amp; Conditions</h1>
                </div>
                <div className="float-right">
                  <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={this.goBack}>BACK</button>
                </div>
              </div>
              <div className="col-6 mb-3">
                <div className="card rounded-0" style={{ height: '65vh' }}>
                  <div className="px-4 pt-4" style={{ overflow: 'auto' }}>
                    <h6 className="text-uppercase text-dark">KNOWN ALLERGIES</h6>
                    {allergiesData?.allergies?.length > 0 ? (
                      <div className="known-allergies mb-5">
                        {allergiesData?.allergies?.map(item => {
                          return (
                            <div className="allergies_list_item mt-4 border-bottom pb-3" key={item.id}>
                              <h5 className="text-secondary">{item.allergyType}</h5>
                              <div className="d-flex justify-content-between">
                                <p className="mb-0">{item.allergy_name}</p>
                                <p className="text-danger mb-0 ">{ALLERGY_INFORMED_BY[item.informedBy]}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p className="text-muted">{item.reaction}</p>
                                <i className={`${ALLERGY_INTENSITY_IC[item.intensity]} float-right`} />
                              </div>
                              <p className={`flex-row font-weight-bold text-grey mb-1 ${item.comments !== null ? 'd-flex' : 'd-none'}`}>
                                <span className="d-block font-weight-light">Comments</span>: {item.comments}</p>
                            </div>
                          )
                        })}
                      </div>
                    ) : (
                      allergiesData.allergies === undefined ? (
                        <div className={`w-100 align-items-center justify-content-center mt-4 d-flex`}>
                          {LOADER_RED}
                        </div>
                      ) : (
                        <h5 className="d-flex justify-content-center">NO KNOWN ALLERGIES</h5>
                      )
                    )}

                  </div>
                  <div className="severity bg-grey border-top d-flex position-absolute w-100 justify-content-around align-items-center py-3 " style={{ bottom: 0 }}>
                    <p className="mb-0 text-dark">Severity</p>
                    <p className="mb-0 d-flex text-dark align-items-baseline">
                      <i className="icon-severity-low" /> Low
                                    </p>
                    <p className="mb-0 d-flex text-dark align-items-baseline">
                      <i className="icon-severity-medium" /> Medium
                                    </p>
                    <p className="mb-0 d-flex text-dark align-items-baseline">
                      <i className="icon-severity-high" /> High
                                    </p>
                  </div>
                </div>
              </div>
              {/* exisiting conditions */}
              <div className="col-6 mb-3">
                <div className="card rounded-0" style={{ height: '65vh' }}>
                  <div className="px-4 pt-4" style={{ overflow: 'auto' }}>
                    <h6 className="text-uppercase text-dark">EXISITING CONDITIONS</h6>
                    {allergiesData?.conditions?.length > 0 ? (
                      <div className="basic-list-allergies">
                        {allergiesData?.conditions?.map(item => (
                          <div className="allergies_list_item mt-4 border-bottom pb-3" key={item.id}>
                            <h5 className="text-secondary">{item.condition_name}</h5>
                            <div className="condition-item">
                              <p className="d-flex flex-row font-weight-bold text-grey mb-1">
                                <span className="d-block font-weight-light" style={{ width: '150px' }}>Duration</span>: {item.duration} {DURATION_TYPE[item.durationType]}</p>
                              <p className="d-flex flex-row font-weight-bold text-grey mb-1">
                                <span className="d-block font-weight-light" style={{ width: '150px' }}>On Medication</span>: {MEDICATION_ON[item.onMedication]}</p>
                              <p className={` flex-row font-weight-bold text-grey mb-1 ${item.onMedication === 1 ? 'd-flex' : 'd-none'}`}>
                                <span className={`   font-weight-light`} style={{ width: '150px' }}>Medication Name</span>: {item.medicineName}</p>
                              <p className={`flex-row font-weight-bold text-grey mb-1 ${item.comments !== null ? 'd-flex' : 'd-none'}`}>
                                <span className="d-block font-weight-light" style={{ width: '150px' }}>Comments</span>: {item.comments}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      allergiesData.conditions === undefined ? (
                        <div className={`w-100 align-items-center justify-content-center mt-4 d-flex`}>
                          {LOADER_RED}
                        </div>
                      ) : (
                        <h5 className="d-flex justify-content-center">NO EXISITING CONDITIONS</h5>
                      )
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

}


export default withRouter(Allergies);

