import React, { Component } from 'react'
import Axios from 'axios';
import LS_SERVICE from '../../utils/localStorage';
import { Link } from 'react-router-dom'
import Asideleft from '../../components/aside/asideleft'
import { Input, Modal, Select, Button, Radio, Form, Checkbox, DatePickerProps, DatePicker, Tooltip, AutoComplete, notification } from 'antd';
import {
  LOADER_RED,
  GET_PATIENT_ENCOUNTER_LIST,
  PATIENT_CASE_SHEET_LIST,
  PATIENT_DIAGNOSIS_LIST,
  PATIENT_BANNER_DATA
} from '../../utils/constant';

import ProfileImg from "../../assets/images/user.png";
import PDFImg from '../../assets/images/ic-pdf.png';
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import icJpg from '../../assets/images/ic-jpg.png';
import { headerService$ } from '../../utils/rxjs-sharing';
import searchIcon from "../../assets/images/ic-search.svg";
import moment from 'moment';
import Moment from 'moment';
import qs from 'qs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt, faBan } from '@fortawesome/free-solid-svg-icons';

// import PatientCaseSheet from '../patient-detail/mrdcomponents/PatientCaseSheet'
// import PatientDiagnosis from '../patient-detail/mrdcomponents/PatientDiagnosis'
import PatientDischargeSummary from '../patient-detail/mrdcomponents/PatientDischargeSummary'
import PatientPrescription from '../patient-detail/mrdcomponents/PatientPrescription'
import PatientSurgery from '../patient-detail/mrdcomponents/PatientSurgery'
import PatientClinicalForms from '../patient-detail/mrdcomponents/PatientClinicalForms'
import PatientCertificates from '../patient-detail/mrdcomponents/PatientCertificates'
import PatientOrders from '../patient-detail/mrdcomponents/PatientOrders'


const { Option } = Select;
const { Search } = Input;

export default class PatientMedicalRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patient: null,
      hospital_id: null,
      patient_id: null,
      reference_id: null,
      tabdisplay: { display: "none" },
      patientEncounterData : [],
      patientEncounterFilterData : [],
    	fromDate: null,
    	toDate: null,
    	calender_open_from: false,
    	calender_open_to: false,
    	currentPageTab: "",
    	selectedRow : null,
      currentEncounter : null,
      police_report_no: "",
      ismlc: false,
      prnRemarks: "",
      patientBannerData : null,
      caseSheetListData : null,
      loader: true,
      diagnosisLoader: true,
      showRxModal: false,
      rxPdfPath: null,
      diagnosisListData : [],
      diagnosisConstants : [],
      isImgModalVisible: false,
      assessments_image: '',
      assessments_image_comment: '',
    }

    this.MedicalRecordFormRef = React.createRef();
  }

  async componentDidMount() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_id } = params;

    const patient = {
      hospital_id,
      patient_id,
      qms_token_id: reference_id
    }

    await this.setState({
      fromDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    });

    await this.MedicalRecordFormRef.current.setFieldsValue({
      fromDate: moment(new Date(this.state.fromDate)),
      toDate: moment(new Date(this.state.toDate)),
    });

    let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
    hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);
    this.setState({
      hospital_id,
      patient_id,
      reference_id,
      patient,
    }, () => {
      if (LS_SERVICE.has('hospitalConfigData')) {
        const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
        let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
        headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' }); // IMPORTANT TO HIT AS PER THE VIEW
        this.getPatientEncounterData();
      }
    });

  }

  handleTab = (tab) => async (e) => {
    e.preventDefault();
    let { currentPageTab } = this.state;

    currentPageTab = tab;

    await this.setState({
      currentPageTab,
    });
  }

  disabledDate = (current, type) => {
    if (type == 'from') {
      if (this.state.toDate) {
        return current && (current > moment().endOf('day') || current > moment(this.state.toDate).endOf('day'));
      } else {
        return current && (current > moment().endOf('day')); 
      }
    } else {
      if (this.state.fromDate) {
        return current && (current > moment().endOf('day') || current < moment(this.state.fromDate).startOf('day'));
      } else {
        return current && (current > moment().endOf('day'));
      }
    }
  }

  datePickerStatus = (status, type) => {
    if (status) {
      if (type == 'from') {
        this.setState({ calender_open_from: true })
      } else {
        this.setState({ calender_open_to: true })
      }
    } else {
      if (type == 'from') {
        this.setState({ calender_open_from: false })
      } else {
        this.setState({ calender_open_to: false })
      }
    }
  };

  handleDatePickerChange = async (date, dateString, field) => {
    if (date) {
      await this.setState({
        [field]: moment(date).format("YYYY-MM-DD")
      });
    } else {
      await this.setState({
        [field]: null
      });
    }
  }

  async getPatientEncounterData() {
  	let PARAMS = {
  		facilityId : LS_SERVICE.get('FACILITY_ID'),
  		RegistrationId: LS_SERVICE.get('PATIENT_REG_DATA').registrationid
  	}
    Axios.post(GET_PATIENT_ENCOUNTER_LIST, qs.stringify(PARAMS))
      .then(res => {
      	let patientEncounterList =  res.data.status ? res.data.data : [];
      	if(patientEncounterList.length > 0)
      	{
	      	var data = patientEncounterList.filter(
	          (item) =>
	            moment(item.encounterDate).format("YYYY/MM/DD") >=
	            moment(this.state.fromDate).format("YYYY/MM/DD") &&
	            moment(item.encounterDate).format("YYYY/MM/DD") <=
	            moment(this.state.toDate).format("YYYY/MM/DD")
	        );

          data.sort((a, b) => {
            var ad = moment(a.encounterDate, 'YYYY/MM/DD');
            var bd = moment(b.encounterDate, 'YYYY/MM/DD');
            if (ad == bd) return 0;
            return ad.isAfter(bd) ? -1 : 1;
          });
	        this.setState({
	          patientEncounterFilterData: data
	        });
	    }
	    this.setState({
          patientEncounterData: patientEncounterList
        });
      })
  }

  async getPatientBannerData(data) {
    let PARAMS = {
      encounterId : data.encounter_id,
      encounterType: data.encounterTypeCode,
      facilityId: data.facilityId,
    }
    Axios.post(PATIENT_BANNER_DATA, qs.stringify(PARAMS))
      .then(async res => {
        let patientBannerData =  res.data.status ? res.data.data : null;
        await this.setState({
          patientBannerData: patientBannerData
        });
        if(patientBannerData)
        {
          await this.setState({
            police_report_no: patientBannerData.police_report_no,
            ismlc: patientBannerData.ismlc,
            prnRemarks: patientBannerData.prnRemarks,
          });
        }
    });
  }

  handleOnFilter = async () => {
    try {
    	const { patientEncounterData, selectedRow } = this.state;
    	var data = patientEncounterData.filter(
          (item) =>
            moment(item.encounterDate).format("YYYY/MM/DD") >=
            moment(this.state.fromDate).format("YYYY/MM/DD") &&
            moment(item.encounterDate).format("YYYY/MM/DD") <=
            moment(this.state.toDate).format("YYYY/MM/DD")
        );
        data.sort((a, b) => {
          var ad = moment(a.encounterDate, 'YYYY/MM/DD');
          var bd = moment(b.encounterDate, 'YYYY/MM/DD');
          if (ad == bd) return 0;
          return ad.isAfter(bd) ? -1 : 1;
        });
        await this.setState({
          patientEncounterFilterData: data,
          tabdisplay: { display: "none" },
          selectedRow: null,
          currentPageTab : "",
          currentEncounter: null
        });
    } catch (err) {
      console.log('handleOnFilter: ', err);
    }
  }

  onSelectionChanged = (result) => async (e) => {
    e.preventDefault();
    let currentPageTab =  (result.encounterTypeCode == 'IP' || result.encounterTypeCode == 'ER') ? "CaseSheet" : "Diagnosis";
    await this.setState({
      selectedRow : result,
      currentEncounter: result.encounter_id,
      tabdisplay: { display: "block" },
      currentPageTab: currentPageTab,
    });
    
    this.handleTab(currentPageTab);
    await this.getPatientBannerData(result);
    await this.getPatientCaseSheetData();
    await this.getPatientDiagnosisData();
  }

  showImageReferenceModal = (assData) => {
    this.setState({
      assessments_image: '',
      assessments_image_comment: ''
    }, async () => {
      if (assData && assData.VisitAssessmentImage.length) {
        this.setState({
          assessments_image: assData.VisitAssessmentImage[0].imagePath,
          assessments_image_comment: assData.VisitAssessmentImage[0].comment
        });
      }
    });
    this.setState({ isImgModalVisible: true });
  }

  handleCancel = () => {
    this.setState({ isImgModalVisible: false, assessments_image: '', assessments_image_comment: '' });
  }

  async getPatientDiagnosisData() {
    const { selectedRow } = this.state;
    await this.setState({
      diagnosisLoader: true
    });
    let PARAMS = {
      patient_id: selectedRow.registrationId,
      encounter_id: selectedRow.encounter_id,
      reference_type: (selectedRow.encounterTypeCode == 'IP' || selectedRow.encounterTypeCode == 'ER') ? 1 : 0
    }
    await Axios.post(PATIENT_DIAGNOSIS_LIST, qs.stringify(PARAMS))
    .then(async res => {
      let diagnosisListData =  res.status ? res.data?.data?.details : null;
      let diagnosisConstants =  res.status ? res.data?.data?.constants : null;
      await this.setState({
        diagnosisListData: diagnosisListData,
        diagnosisConstants: diagnosisConstants,
        diagnosisLoader: false
      });
    })
  }

  PatientDiagnosisListLoad() {
    try {
      const { diagnosisConstants } = this.state;
      if ((!this.state.diagnosisListData || this.state.diagnosisListData.length === 0)) {
        let htmlData = (
          <tr>
            <td className="inner_table text-center" colSpan={6}>No Records Found.</td>
          </tr>
        )
        return htmlData;
      }
      return this.state.diagnosisListData.map((result, i) => {
        let image_path = (result.VisitAssessmentImage.length > 0) ? result.VisitAssessmentImage[0].imagePath : "";
        let htmlData = (
          <tr key={i} id={result.encounter_Id}>
            <td>{(result.diagnosisInfo.diagnose_description) ? result.diagnosisInfo.diagnose_description + ' : ' + result.diagnosisNotes : result.diagnosisNotes}</td>
            <td>{(result.diagnosisInfo.icd_code) ? result.diagnosisInfo.icd_code : ''}</td>
            <td>{diagnosisConstants.assessmentStage[result.stage] ? diagnosisConstants.assessmentStage[result.stage] : '-'}</td>
            <td>{diagnosisConstants.assessmentType[result.type] ? diagnosisConstants.assessmentType[result.type] : '-'}</td>
            {result.VisitAssessmentImage.length ?
              <td>
                <Tooltip placement="top" title='Click to view Image Reference'>
                  <span className="d-flex align-items-center justify-content-center">
                    <Button className="image-reference-btn nostyle-link h-auto p-0"
                      onClick={() => this.showImageReferenceModal(result)}
                    >
                      <img src={icJpg} alt="Image Reference" className="img-fluid" style={{width:'18px',height:'auto'}} />
                    </Button>
                  </span>
                </Tooltip>
              </td>
              : <td>
                <Tooltip placement="top" title='No Image'>
                  <span className="d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faBan} style={{ fontSize: '17px' }} />
                  </span>
                </Tooltip>

              </td>
            }
            <td>
              <span className='ant_switch_button_sm'>
                <BootstrapSwitchButton
                  width={85} height={24}
                  size="xs"
                  checked={result.status === 0 ? true : false}
                  onlabel='Active'
                  offlabel='Cured'
                  onstyle="switch" offstyle="success"
                  disabled={true}
                />
              </span>
            </td>
          </tr>
        )
        return htmlData;
      });
    } catch (err) {
      console.log('PatientDiagnosisListLoad: ', err);
    }
  }

  async getPatientCaseSheetData() {
    const { selectedRow } = this.state;
    await this.setState({
      loader: true
    });
    let PARAMS = {
      patient_Id: selectedRow.registrationId,
      encounter_Id: selectedRow.encounter_id,
      enterprise_Id: selectedRow.enterpriseId,
      facility_Id: selectedRow.facilityId,
    }
    await Axios.post(PATIENT_CASE_SHEET_LIST, qs.stringify(PARAMS))
    .then(async res => {
      let caseSheetListData =  res.status ? res.data.data : null;
      await this.setState({
        caseSheetListData: caseSheetListData,
        loader: false
      });
    })
  }

  PatientCaseListLoad() {
    try {
      if ((!this.state.caseSheetListData || this.state.caseSheetListData.length === 0)) {
        let htmlData = (
          <tr>
            <td className="inner_table text-center" colSpan={3}>No Records Found.</td>
          </tr>
        )
        return htmlData;
      }
      const { caseSheetListData } = this.state;
      let htmlData = (
        <tr id={caseSheetListData.encounter_Id}>
          <td>{caseSheetListData.form_name}</td>
          <td>{moment(caseSheetListData.createdAt).format('DD MMM YYYY | hh:mm A ')}</td>
          <td><a onClick={() => { this.setState({ rxPdfPath: caseSheetListData.filepath, showRxModal: true }); }}><img src={PDFImg} alt="view" width={15} /></a></td>
        </tr>
      )
      return htmlData;
    } catch (err) {
      console.log('PatientEncounterListLoad: ', err);
    }
  }

  PatientEncounterListLoad() {
    try {
      if ((!this.state.patientEncounterFilterData || this.state.patientEncounterFilterData.length === 0)) {
        let htmlData = (
          <tr>
            <td className="inner_table text-center" colSpan={4}>No Records Found.</td>
          </tr>
        )
        return htmlData;
      }
      return this.state.patientEncounterFilterData.map((result, i) => {
        let background_color = (result.encounter_id == this.state.currentEncounter) ? "#00000013" : "#ffffff";
        let htmlData = (
          <tr style={{ cursor: 'pointer', backgroundColor : background_color }} key={i} id={result.encounter_Id} onClick={this.onSelectionChanged(result)}>
            <td>{i+1}</td>
    		    <td>{result.encounterNo}</td>
    		    <td>{moment(result.encounterDate).format('DD MMM YYYY | hh:mm A ')}</td>
    		    <td>{result.encounterTypeCode}</td>
          </tr>
        )
        return htmlData;
      });
    } catch (err) {
      console.log('PatientEncounterListLoad: ', err);
    }
  }

  

  render() {
    const { patient, selectedRow, patientBannerData, loader, diagnosisLoader } = this.state;
    return (
      <>
        <Asideleft />
        <div className="content-wrapper">
            <div className="container-fluid">
                <Form ref={this.MedicalRecordFormRef} layout="vertical" className='emrfrm'>
                    <div className="row emrfrm pt-1">
                        <div className="col-12 ">
                            <div className="float-left">
                                <h1 className="page-title mb-0">Patient Medical Record</h1>
                            </div>
                        </div>
                    </div>
                    { patientBannerData ? 
                      <div className="banner-cardopd patient-card-opd patient-red-opd mt-3">
                        <div className="row">
                          <div className="col-auto">
                            <img src={ProfileImg} className="img-fluid profile-image" style={{ width: '60px', height: '60px' }} />
                          </div>
                          <div className="col">
                            <div className="row">
                              <div className="col-4">
                                <label className="text-muted mb-0 banner-row">UHID</label>
                                <label className="font-weight-bold mb-0">{patientBannerData.registrationNo}</label>
                                <br />
                              </div>
                              <div className="col-3">
                                <label className="text-muted mb-0 banner-row">Name</label>
                                <label className="font-weight-bold mb-0">{patientBannerData.name} {patientBannerData.ageGender}</label>
                              </div>
                              <div className="col-3">
                                <label className="text-muted mb-0 banner-row">Mobile No</label>
                                <label className="font-weight-bold mb-0">{patientBannerData.mobileNo}</label>
                              </div>
                              <div className="col-2">
                                <label className="text-muted mb-0 banner-row">Payor</label>
                                <label className="font-weight-bold mb-0">{patientBannerData.payorName}</label>
                              </div>
                            </div>
                            {patientBannerData.encounterType !== "OP" ?
                              <div className="row py-1">
                                <div className="col-4">
                                  <label className="text-muted mb-0 banner-row">IP No.</label>
                                  <label className="font-weight-bold mb-0">{patientBannerData.encounterNo}</label>
                                </div>
                                <div className="col-3">
                                  <label className="text-muted mb-0 banner-row">Nusing Unit</label>
                                  <label className="font-weight-bold mb-0">{patientBannerData.nursingUnit}</label>
                                </div>
                                <div className="col-3">
                                  <label className="text-muted mb-0 banner-row">Bed No.</label>
                                  <label className="font-weight-bold mb-0">{patientBannerData.bedNo}</label>
                                </div>
                              </div>
                              :
                              null
                            }
                            <div className="row py-1">
                              <div className="col-4">
                                <label className="text-muted mb-0 banner-row">Practitioner</label>
                                <label className="font-weight-bold mb-0">{patientBannerData.doctorName} {patientBannerData.doctorName === '' ? '' : ','} {patientBannerData.specialization}</label>
                              </div>
                              {patientBannerData.encounterType !== "OP" ?
                                <>
                                  <div className="col-3">
                                    <label className="text-muted mb-0 banner-row">Admission On</label>
                                    <label className="font-weight-bold mb-0">{Moment(patientBannerData.admissionOn).add(330,'minutes').format('D MMM YYYY | hh:mm A')}</label>
                                  </div>
                                  <div className="col-3">
                                    <label className="text-muted mb-0 banner-row">Discharge On.</label>
                                    <label className="font-weight-bold mb-0">{patientBannerData.dischargeOn == null ? "--/----/--" : Moment(patientBannerData.dischargeOn).add(330,'minutes').format('D MMM YYYY | hh:mm A')}</label>
                                  </div>
                                  <div className="col-2">
                                    <label className="text-muted mb-0 banner-row">Status</label>
                                    <label className="font-weight-bold mb-0">{patientBannerData.dischargestatus}</label>
                                  </div>
                                </>
                                :
                                null
                              }
                            </div>
                            <div className="row py-1">
                              <div className="col-12 text-right pb-1">
                                {
                                  ((patientBannerData.genderCode == "M" && patientBannerData.age >= 60) || (patientBannerData.genderCode == "F" && patientBannerData.age >= 58))
                                  ?
                                  <Tooltip placement="top" arrowPointAtCenter={true} title="Senior Citizen">
                                      <span className="dark cursor-p">
                                          <FontAwesomeIcon icon={faUserAlt} />
                                      </span>
                                  </Tooltip>
                                  :
                                  null
                                }
                                {/*
                                  {patientBannerData.attention === true ? <span className="red"><i className="his-icon icon-attention"></i></span> : ""}
                                  {patientBannerData.emp ? <span className="blue cursor-p"><i className="his-icon icon-emp" style={{ fontSize: '10px', lineHeight: '15px' }}></i></span> : ""}
                                  {patientBannerData.vipNotes !== "" && patientBannerData.vipNotes !== null ? <span className="purple  cursor-p"><i className="his-icon icon-ic-vip"></i></span> : ""}
                                  {patientBannerData.hwcNotes !== "" && patientBannerData.hwcNotes !== null ? <span className="dark cursor-p"><i className="his-icon icon-ic-hand"></i></span> : ""}
                                  <span className="yellow cursor-p"><i className="his-icon icon-location"></i></span>
                                  */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      null
                    }
                    <div className='row mt-3'>
                        <div className='col-4'>

                            <div className='row '>
                                <div className='col'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className={(this.state.fromDate || this.state.calender_open_from) ? 'antInputItem form-group position-relative hasdata' : 'antInputItem form-group position-relative'}>
						                      <Form.Item name="fromDate" id="fromDate" label="From Date" >
						                        <DatePicker scrollableYearDropdown
						                          yearDropdownItemNumber={100}
						                          disabledDate={(current) => this.disabledDate(current, 'from')}
						                          format="DD MMM, YYYY"
						                          placeholder={''}
						                          className="form-control datetimepicker"
						                          onChange={(date, dateString) => this.handleDatePickerChange(date, dateString, 'fromDate')}
						                          onClick={() => this.datePickerStatus(true, 'from')}
						                          onOpenChange={(status) => this.datePickerStatus(status, 'from')}
						                        />
						                      </Form.Item>
						                    </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className={(this.state.toDate || this.state.calender_open_to) ? 'antInputItem form-group position-relative hasdata' : 'antInputItem form-group position-relative'}>
						                      <Form.Item name="toDate" id="toDate" label="To Date" >
						                        <DatePicker scrollableYearDropdown
						                          yearDropdownItemNumber={100}
						                          format="DD MMM, YYYY"
						                          disabledDate={(current) => this.disabledDate(current, 'to')}
						                          placeholder={''}
						                          className="form-control datetimepicker"
						                          onChange={(date, dateString) => this.handleDatePickerChange(date, dateString, 'toDate')}
						                          onClick={() => this.datePickerStatus(true, 'to')}
						                          onOpenChange={(status) => this.datePickerStatus(status, 'to')}
						                        />
						                      </Form.Item>
						                    </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-auto ml-2'>
                                    <button type="button" className='btn btn-outline-primary px-3 ' onClick={() => this.handleOnFilter()}>SEARCH</button>
                                </div>
                            </div>

                            <div className='row mt-1'>
                                <div className='col-12'>
                                  <div className="scrollable_table">
                                    <table className='table table-bordered table-hover'>
                                        <thead>
                                            <tr>
                                                <th width="12%" className='px-1 py-2' style={{verticalAlign:'top'}}>S NO.</th>
                                                <th width="33%" className='px-1 py-2' style={{ verticalAlign: 'top' }}>ENCOUNTER NO.</th>
                                                <th width="35%" className='px-1 py-2' style={{ verticalAlign: 'top' }}>ENCOUNTER DATE</th>
                                                <th width="35%" className='px-1 py-2' style={{ verticalAlign: 'top' }}>VISIT TYPE</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                        	{this.PatientEncounterListLoad()}
                                        </tbody>
                                    </table>
                                  </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-8' style={this.state.tabdisplay}>

                            <ul className="nav nav-pills myhealthcare-tabs auto-width test-results-tabs mb-0" id="pills-tab" role="tablist">
                            	{ selectedRow && selectedRow.encounterTypeCode != 'OP' ?
	                                <li className="nav-item" role="presentation">
	                                    <a className={`nav-link ${this.state.currentPageTab === 'CaseSheet' ? 'active' : ''}`} id="pills-case-sheet-tab" data-toggle="pill" href="#pills-case-sheet" role="tab" aria-controls="pills-case-sheet" aria-selected="true" onClick={this.handleTab('CaseSheet')}>Case Sheet</a>
	                                </li>
	                                :
	                                null
	                            }
                                <li className="nav-item" role="presentation">
                                    <a className={`nav-link ${this.state.currentPageTab === 'Diagnosis' ? 'active' : ''}`} id="pills-diagnosis-tab" data-toggle="pill" href="#pills-diagnosis" role="tab" aria-controls="pills-diagnosis" aria-selected="false" onClick={this.handleTab('Diagnosis')}>Diagnosis</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className={`nav-link ${this.state.currentPageTab === 'Order' ? 'active' : ''}`} id="pills-order-tab" data-toggle="pill" href="#pills-order" role="tab" aria-controls="pills-order" aria-selected="false" onClick={this.handleTab('Order')}>Order</a>
                                </li>
                              { selectedRow && selectedRow.encounterTypeCode != 'OP' ?
	                                <li className="nav-item" role="presentation">
	                                    <a className={`nav-link ${this.state.currentPageTab === 'Discharge_Summary' ? 'active' : ''}`} id="pills-discharge-summary-tab" data-toggle="pill" href="#pills-discharge-summary" role="tab" aria-controls="pills-discharge-summary" aria-selected="false" onClick={this.handleTab('Discharge_Summary')}>Discharge Summary</a>
	                                </li>
	                                :
	                                null
	                            }
	                            { selectedRow && selectedRow.encounterTypeCode == 'OP' ?
	                                <li className="nav-item" role="presentation">
	                                    <a className={`nav-link ${this.state.currentPageTab === 'Prescription' ? 'active' : ''}`} id="pills-prescription-tab" data-toggle="pill" href="#pills-prescription" role="tab" aria-controls="pills-prescription" aria-selected="false" onClick={this.handleTab('Prescription')}>Prescription</a>
	                                </li>
	                                :
	                                null
	                            }
                              <li className="nav-item" role="presentation">
                                  <a className={`nav-link ${this.state.currentPageTab === 'MLC_Details' ? 'active' : ''}`} id="pills-mlc-details-tab" data-toggle="pill" href="#pills-mlc-details" role="tab" aria-controls="pills-mlc-details" aria-selected="false" onClick={this.handleTab('MLC_Details')}>MLC Details</a>
                              </li>
                              { selectedRow && selectedRow.encounterTypeCode != 'OP' ?
                                <li className="nav-item" role="presentation">
                                    <a className={`nav-link ${this.state.currentPageTab === 'Surgery_Details' ? 'active' : ''}`} id="pills-surgery-details-tab" data-toggle="pill" href="#pills-surgery-details" role="tab" aria-controls="pills-surgery-details" aria-selected="false" onClick={this.handleTab('Surgery_Details')}>Surgery Details</a>
                                </li>
                                :
                                null
                              }
                              <li className="nav-item" role="presentation">
                                  <a className={`nav-link ${this.state.currentPageTab === 'Clinical_Forms' ? 'active' : ''}`} id="pills-clinical-forms-tab" data-toggle="pill" href="#pills-clinical-forms" role="tab" aria-controls="pills-clinical-forms" aria-selected="false" onClick={this.handleTab('Clinical_Forms')}>Clinical Forms</a>
                              </li>
                              {/*
                              <li className="nav-item" role="presentation">
                                  <a className={`nav-link ${this.state.currentPageTab === 'Certificates' ? 'active' : ''}`} id="pills-certificates-tab" data-toggle="pill" href="#pills-certificates" role="tab" aria-controls="pills-certificates" aria-selected="false" onClick={this.handleTab('Certificates')}>Certificates</a>
                              </li>
                              */}
                            </ul>
                            <div className="tab-content p-2" id="pills-tabContent" style={{ border: 'solid 1px #ccc' }}>
                                <div className={`tab-pane fade ${this.state.currentPageTab === 'CaseSheet' ? 'show active' : ''}`} id="pills-case-sheet" role="tabpanel" aria-labelledby="pills-case-sheet-tab">
                                	{this.state.currentPageTab == "CaseSheet" ? (
                                    <>
                                			{ loader ? 
                                        <div className='row mt-3'>
                                          <div className="col-12  text-center mt-4">{LOADER_RED}
                                          </div>
                                        </div>
                                        :
                                        <div className='row mt-3'>
                                          <div className='col-12'>
                                            <table className='table table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <th width="40%" className='px-1 py-2' style={{verticalAlign:'top'}}>Form Name</th>
                                                        <th width="30%" className='px-1 py-2' style={{verticalAlign:'top'}}>Created At</th>
                                                        <th width="12%" className='px-1 py-2' style={{verticalAlign:'top'}}>File</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                  {this.PatientCaseListLoad()}
                                                </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      }
				                  		      </>
					                        ) : 
					                        null
					              	        }
                                </div>

                                {/* ****************** tab content for Diagnosis tab ************************ */}
                                <div className={`tab-pane fade ${this.state.currentPageTab === 'Diagnosis' ? 'show active' : ''}`} id="pills-diagnosis" role="tabpanel" aria-labelledby="pills-diagnosis-tab">
                                  {this.state.currentPageTab == "Diagnosis" ? (
                                    <>
                                      { diagnosisLoader ? 
                                        <div className='row mt-3'>
                                          <div className="col-12  text-center mt-4">{LOADER_RED}
                                          </div>
                                        </div>
                                        :
                                        <div className='row mt-3'>
                                          <div className='col-12'>
                                            <div className="table-responsive scrollable_sub_table">
                                              <table className='table table-bordered'>
                                                  <thead>
                                                      <tr>
                                                          <th width="" className='px-1 py-2' style={{verticalAlign:'top'}}>DIAGNOSIS NAME</th>
                                                          <th width="15%" className='px-1 py-2' style={{verticalAlign:'top'}}>ICD CODE</th>
                                                          <th width="15%" className='px-1 py-2' style={{verticalAlign:'top'}}>STAGE</th>
                                                          <th width="15%" className='px-1 py-2' style={{verticalAlign:'top'}}>TYPE</th>
                                                          <th width="8%" className='px-1 py-2' style={{verticalAlign:'top'}}>IMAGE</th>
                                                          <th width="12%" className='px-1 py-2' style={{verticalAlign:'top'}}>STATUS</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                    {this.PatientDiagnosisListLoad()}
                                                  </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    </>
                                  ) : 
                                  null
                                  }
                                </div>


                                {/* ****************** tab content for Order tab ************************ */}
                                <div className={`tab-pane fade ${this.state.currentPageTab === 'Order' ? 'show active' : ''}`} id="pills-order" role="tabpanel" aria-labelledby="pills-order-tab">
                                  {this.state.currentPageTab == "Order" ? (
                                    <>
                                      <PatientOrders
                                       selectedEncounterData={this.state.selectedRow}
                                      ></PatientOrders>
                                    </>
                                  ) : 
                                  null
                                  }
                                </div>

                                {/* ******************end of  Order tab ************************ */}




                                {/* ****************** tab content for Discharge Summary tab ************************ */}
                                <div className={`tab-pane fade ${this.state.currentPageTab === 'Discharge_Summary' ? 'show active' : ''}`} id="pills-discharge-summary" role="tabpanel" aria-labelledby="pills-discharge-summary-tab">
                                  {this.state.currentPageTab == "Discharge_Summary" ? (
                                    <>
                                      <PatientDischargeSummary
                                       selectedEncounterData={this.state.selectedRow}
                                      ></PatientDischargeSummary>
                                    </>
                                  ) : 
                                  null
                                  }
                                </div>
                                {/* ******************end of  Discharge Summary tab ************************ */}

                                {/* ****************** tab content for Prescription tab ************************ */}
                                <div className={`tab-pane fade ${this.state.currentPageTab === 'Prescription' ? 'show active' : ''}`} id="pills-prescription" role="tabpanel" aria-labelledby="pills-prescription-tab">
                                  {this.state.currentPageTab == "Prescription" ? (
                                    <>
                                      <PatientPrescription
                                       selectedEncounterData={this.state.selectedRow}
                                      ></PatientPrescription>
                                    </>
                                  ) : 
                                  null
                                  }
                                </div>
                                {/* ******************end of  Prescription tab ************************ */}


                                {/* ******************tab content for MLC tab ************************ */}
                                <div className={`tab-pane fade ${this.state.currentPageTab === 'MLC_Details' ? 'show active' : ''}`} id="pills-mlc-details" role="tabpanel" aria-labelledby="pills-mlc-details-tab">
                                  {this.state.currentPageTab == "MLC_Details" ? (
                                    <>
                                      <div className='row mt-3'>
                                          <div className='col-12'>
                                              <table className='table table-bordered'>
                                                  <thead>
                                                      <tr>
                                                          <th width="40%" className='px-1 py-2' style={{verticalAlign:'top'}}>POLICE NO</th>
                                                          <th width="60%" className='px-1 py-2' style={{verticalAlign:'top'}}>REMARKS</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      {this.state.police_report_no != "" ?
                                                        <tr>
                                                            <td>{this.state.police_report_no}</td>
                                                            <td>{this.state.prnRemarks}</td>
                                                        </tr>
                                                        :
                                                        <tr>
                                                          <td className="inner_table text-center" colSpan={2}>No Records Found.</td>
                                                        </tr>
                                                      }
                                                  </tbody>
                                              </table>
                                          </div>
                                      </div>
                                    </>
                                  ) : 
                                  null
                                  }
                                </div>

                                {/* ******************end of  MLC tab ************************ */}


                                {/* ******************tab content for surgery details tab ************************ */}
                                <div className={`tab-pane fade ${this.state.currentPageTab === 'Surgery_Details' ? 'show active' : ''}`} id="pills-surgery-details" role="tabpanel" aria-labelledby="pills-surgery-details-tab">
                                  {this.state.currentPageTab == "Surgery_Details" ? (
                                    <>
                                      <PatientSurgery
                                       selectedEncounterData={this.state.selectedRow}
                                      ></PatientSurgery>
                                    </>
                                  ) : 
                                  null
                                  }
                                </div>
                                {/* ******************end of  surgery details tab ************************ */}

                                {/* ******************tab content for clinical forms tab ************************ */}
                                <div className={`tab-pane fade ${this.state.currentPageTab === 'Clinical_Forms' ? 'show active' : ''}`} id="pills-clinical-forms" role="tabpanel" aria-labelledby="pills-clinical-forms-tab">
                                  {this.state.currentPageTab == "Clinical_Forms" ? (
                                    <>
                                      <PatientClinicalForms
                                       selectedEncounterData={this.state.selectedRow}
                                      ></PatientClinicalForms>
                                    </>
                                  ) : 
                                  null
                                  }
                                </div>
                                {/* ******************end of  surgery details tab ************************ */}

                                {/* ******************tab content for Certficates tab ************************ */}
                                <div className={`tab-pane fade ${this.state.currentPageTab === 'Certificates' ? 'show active' : ''}`} id="pills-certificates" role="tabpanel" aria-labelledby="pills-certificates-tab">
                                  {this.state.currentPageTab == "Certificates" ? (
                                    <>
                                      <PatientCertificates
                                       selectedEncounterData={this.state.selectedRow}
                                      ></PatientCertificates>
                                    </>
                                  ) : 
                                  null
                                  }
                                </div>
                                {/* ******************end of  surgery details tab ************************ */}

                            </div>

                        </div>
                    </div>

                </Form>

            </div>
        </div>

        <Modal
          title="View Case Sheet"
          visible={this.state.showRxModal}
          footer={false}
          width={850}
          onCancel={() => { this.setState({ showRxModal: false, rxPdfPath: null }) }}
          style={{ top: 20 }}
        >
          <div className="row">
              <div className="col-12 my-3 text-center">
                  {this.state.rxPdfPath != null ? <iframe src={this.state.rxPdfPath} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                      {LOADER_RED}
                  </iframe> : null}
              </div>
          </div>
        </Modal>

        <Modal title="Image Reference" visible={this.state.isImgModalVisible} footer={false} width="810px" onCancel={this.handleCancel} >
          <div className="row">
            <div className="col-12 d-flex  justify-content-center">
              <img src={this.state.assessments_image} alt={"No Preview Availaible"} />
            </div>
            <div className="col-12">
              <h6>{this.state.assessments_image_comment}</h6>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}
